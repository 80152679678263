import Vue from 'vue'



import WBase from './parts/WBase'
import WHeader from './parts/WHeader'
import WExpand from './parts/WExpand'
import WExpander from './parts/WExpander'
import WContent from './parts/WContent'
import WChilds from './parts/WChilds'
import WEditor from './parts/WEditor'
import WUploader from './parts/WUploader'
import WResizer from './parts/WResizer'
import WGraffiti from './parts/WGraffiti'
import WGraffitiImg from './parts/WGraffitiImg'
import WScaleResizer from './parts/WScaleResizer'
import WChecklistItem from './parts/WChecklistItem'


import Webjet from './Webjet'
import WebjetCard from './WebjetCard'
import WebjetPicture from './WebjetPicture'
import WebjetAudio from './WebjetAudio'
import WebjetVideo from './WebjetVideo'
import WebjetYoutubeVideo from './WebjetYoutubeVideo'
import WebjetEmbed from './WebjetEmbed'
import WebjetPdf from './WebjetPdf'
import WebjetLink from './WebjetLink'
import WebjetList from './WebjetList'
import WebjetUnknown from './WebjetUnknown'
import WebjetErrorAccess from './WebjetErrorAccess'
import WebjetGraffiti from './WebjetGraffiti'
import WebjetShape from './WebjetShape'
import WebjetAnchor from './WebjetAnchor'
import WebjetBtnLink from './WebjetBtnLink'
import WebjetTradingView from './WebjetTradingView.vue'
import WebjetTable from './WebjetTable.vue'
import WebjetPaint from './WebjetPaint'
import WebjetChecklist from './WebjetChecklist'
import WebjetVoice from './WebjetVoice'
import WebjetParentCard from './WebjetParentCard'
import WebjetDate from './WebjetDate'
import WebjetMention from './WebjetMention'
import WebjetEmbedCode from './WebjetEmbedCode'
import WebjetComments from './WebjetComments'

import MinimapWebjet from './MinimapWebjet'


Vue.component 'WBase', WBase
Vue.component 'WHeader', WHeader
Vue.component 'WExpand', WExpand
Vue.component 'WExpander', WExpander
Vue.component 'WContent', WContent
Vue.component 'WChilds', WChilds
Vue.component 'WEditor', WEditor
Vue.component 'WUploader', WUploader
Vue.component 'WResizer', WResizer
Vue.component 'WGraffiti', WGraffiti
Vue.component 'WGraffitiImg', WGraffiti
Vue.component 'WScaleResizer', WScaleResizer
Vue.component 'WChecklistItem', WChecklistItem

Vue.component 'Webjet', Webjet
Vue.component 'WebjetCard', WebjetCard
Vue.component 'WebjetPicture', WebjetPicture
Vue.component 'WebjetAudio', WebjetAudio
Vue.component 'WebjetVideo', WebjetVideo
Vue.component 'WebjetYoutubeVideo', WebjetYoutubeVideo
Vue.component 'WebjetEmbed', WebjetEmbed
Vue.component 'WebjetPdf', WebjetPdf
Vue.component 'WebjetLink', WebjetLink
Vue.component 'WebjetList', WebjetList
Vue.component 'WebjetUnknown', WebjetUnknown
Vue.component 'WebjetErrorAccess', WebjetErrorAccess
Vue.component 'WebjetGraffiti', WebjetGraffiti
Vue.component 'WebjetShape', WebjetShape
Vue.component 'WebjetAnchor', WebjetAnchor
Vue.component 'WebjetBtnLink', WebjetBtnLink
Vue.component 'WebjetTradingView', WebjetTradingView
Vue.component 'WebjetTable', WebjetTable
Vue.component 'WebjetPaint', WebjetPaint
Vue.component 'WebjetChecklist', WebjetChecklist
Vue.component 'WebjetVoice', WebjetVoice
Vue.component 'WebjetParentCard', WebjetParentCard
Vue.component 'WebjetDate', WebjetDate
Vue.component 'WebjetMention', WebjetMention
Vue.component 'WebjetEmbedCode', WebjetEmbedCode
Vue.component 'WebjetComments', WebjetComments

Vue.component 'MinimapWebjet', MinimapWebjet
