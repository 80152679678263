























import { usualMixinPack } from './mixins'
import { decodeDataTransfer } from '@/utils'
export default
  name: 'WebjetList'
  mixins: [...usualMixinPack]
  styles: ->
    result = {}
    result['min-width'] = "#{@baseWidth}px" if @columns.length > 1 and @isPositionable
    if @droppableActive
      result['--list-placeholder-left'] = @placeholderLeft + 'px'
      result['--list-placeholder-width'] = @placeholderWidth + 'px'
    result
  classes: ->
    result = {}
    result['list-columns'] = true if @columns.length > 1
    result
  data: ->
    column: 0
    placeholderLeft: 0
    placeholderWidth: 0
  computed:
    hasSides: -> true
    isContainer: -> true
    baseWidth: ->
      if !@isPositionable
        320
      else
        330 * @columns.length + 10

    activeColumn: ->
      active = @connection.activeColumn
      if (!active and active != 0) or @columns.indexOf(active)==-1
        @columns[0]
      else
        active
    activeColumnIndex: ->
      @columns.indexOf(@activeColumn)
    activeColumnLast: -> @activeColumnIndex == (@columns.length - 1)
    activeColumnFirst: -> @activeColumnIndex == 0

    columns: ->
      all = []
      for c in @childItems
        all.push c.columnKey if c.columnKey
        all.push 0 unless c.columnKey
      result = [...new Set all]
      result = result.sort((a, b) => parseFloat(a) - parseFloat(b))
      result = [0] if result.length == 0
      result
  methods:
    slideTo: (i)->
      @$store.dispatch 'webjet/setActiveColumn',
        path: @src
        value: @columns[i]
    slideLeft: ()->
      @slideTo(@activeColumnIndex-1) unless @activeColumnFirst
    slideRight: ()->
      @slideTo(@activeColumnIndex+1) unless @activeColumnLast
    columnClasses: (i)->
      if @droppableActive and (@droppableMode == 'left' or @droppableMode == 'right')
        return
          "list-droppable-left": i == 0 and @column == 0 and @droppableMode == 'left'
          "list-droppable-right": (i == @column and @droppableMode == 'right') or
            (i == (@column-1) and @droppableMode == 'left')
      return
    droppableDragover: (e)->
      return if @droppableDragInProgress or @isReadOnly
      return if e.defaultPrevented
      return if e.shiftKey
      type = e.dataTransfer.types[0]
      return unless type

      if type.split('/')[0] == 'webjets' or type in @droppableTypes
        if type.split('/')[0] == 'webjets'
          transferWebjets = decodeDataTransfer type.substring(8)
          return if transferWebjets.canDropOnlyOnCanvas
        { top, left, right, bottom, width, height } = @droppablePoint(e)
        zone = 20
        if @provideWebjet
          columnWidth = width
          @column = @activeColumnIndex
          columnLeft = left
          columnRight = right
          @placeholderLeft = 0
          @placeholderWidth = columnWidth
        else
          columnWidth = (width - 10) / @columns.length
          @column = Math.floor left / columnWidth
          @column = 0 if @column < 0
          columnLeft = left % columnWidth
          columnRight = columnWidth - columnLeft
          @placeholderLeft = @column * columnWidth #+ columnLeft
          @placeholderWidth = columnWidth


        #console.log column, columnWidth, columnLeft, columnRight
        if @hasChilds and (columnLeft < zone or columnRight < zone)
          @droppableMode = 'left' if columnLeft < zone
          @droppableMode = 'right' if columnRight < zone
          if @droppableMode == 'right'
            @placeholderLeft += columnWidth
        else
          zone = 10 unless @isExpanded

          if bottom < zone and @provideWebjet?.canDroppable and !@hasChilds
            if @provideWebjet.provideWebjet and @$el.matches(':last-child')
              return

          if top < zone and @provideWebjet and @provideWebjet.canDroppable
            return

          if @provideWebjet
            if bottom <= zone and @hasChilds and @isExpanded
              @droppableMode = 'append'
            else
              @droppableMode = 'prepend'
          else
            if top <= zone * 2 or !@isExpanded or !@hasChilds
              @droppableMode = 'prepend'
            else
              @droppableMode = 'append'

          childWebjetTarget = @droppableChildWebjetTarget(e)
          if childWebjetTarget and (!@provideWebjet or @droppableMode != 'append')
            @droppableMode = 'before'
            @droppableBeforeWebjet = childWebjetTarget
            for w in @childWebjetComponents
              if w == @droppableBeforeWebjet
                w.$el.classList.add 'droppable-before-target'
              else
                w.$el.classList.remove 'droppable-before-target'

        e.dropInWebjet = true
        e.dropInWebjetWidth = @$el.offsetWidth / @columns.length
        e.preventDefault()
        @droppableDisableOthers()
        @droppableActive = true
        @debouncedDroppableDisable()

    droppableDropConnectionData: ()->
      currentColumnKey = @columns[@column]
      result = null
      if @droppableMode == 'left'
        if @column > 0 and currentColumnKey?
          result = (@columns[@column] + @columns[@column - 1]) / 2
        else if @column == @columns.length
          result = @columns.length + 1
        else
          result = currentColumnKey - 1
      else if @droppableMode == 'right'
        if @column < (@columns.length - 1)
          result = (@columns[@column + 1] + @columns[@column]) / 2
        else
          result = currentColumnKey + 1
      else if currentColumnKey
        result = currentColumnKey
      else
        result = null
      { columnKey: result or null }

