







import { userColor, userDisplayName } from '@/utils'

export default
  name: 'SharedCursor'
  inject: ['provideCanvas']
  props: ['cursor']
  computed:
    show: ->
      !@cursor.drawing
    styles: ->
      return
        left: @cursor.x + 'px'
        top: @cursor.y + 'px'
        '--user-name': '"' + @displayName + '"'
        'color': userColor(@cursor.$uid)
    displayName: -> userDisplayName @cursor.$uid
