import { firebind } from '@/utils'
import store from '@/store'

export preloadWebjet = (src)->
  meta = firebind "#{src}/meta"
  items = firebind "#{src}/connections/items"
  return false unless meta.$sync
  return false unless items.$sync
  all = true
  for k, v of items
    if v.src
      preload = preloadWebjet v.src
      all = false unless preload

  all

export preloadBoard = (boardId)->
  preloadWebjet "/boards/#{boardId}"


export findAllWebjets = (src, board=false)->
  items = firebind "#{src}/connections/items"
  result = []
  if !board
    result.push src.split('/').pop()
  for k, v of items
    if v.src
      r = findAllWebjets v.src
      for w in r
        result.push w
  result


export findFreshWebjets = (src, date, board=false)->
  meta = firebind "#{src}/meta"
  items = firebind "#{src}/connections/items"
  result = []
  if !board and meta.updateUser != store.state.uid
    if new Date(meta.updateDate).getTime() > new Date(date).getTime()
      result.push src.split('/').pop()
  for k, v of items
    if v.src
      r = findFreshWebjets v.src, date
      for w in r
        result.push w
  result
