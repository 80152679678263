import { firebind, gtmRegistration } from '@/utils'
import { getBeforeUnloadHandler } from '@/utils'
import { nowFirebase } from '@/utils'
import i18n from '@/plugins/i18n'
import { sendAnalytics } from '@/utils'

export default

  init: (_, { user }, operation) ->
    userData = await firebind '/users/'+user.uid
    updateData = {}
    needUpdate = false
    if !userData.createDate
      updateData.signupVersion = 2
      updateData.createDate = Math.floor(nowFirebase() / 1000)
      needUpdate = true
      gtmRegistration user.email, user.uid
      sendAnalytics 'registration'
    if !userData.email
      updateData.email = user.email
      needUpdate = true
    if !userData.firstName && !userData.lastName && user.displayName
      names = user.displayName.split ' '
      if names.length>1
        updateData.firstName = names[0]
        updateData.lastName = names[names.length-1]
      else
        updateData.firstName = user.displayName
      needUpdate = true
    if !userData.avatarFile && user.providerData[0].providerId == 'google.com' && user.providerData[0].photoURL && user.providerData[0].photoURL != userData.avatar
      updateData.avatar = user.providerData[0].photoURL
      needUpdate = true
    if needUpdate
      @commit 'user/INIT',
        uid: user.uid
        email: user.email
        data: updateData

  initWelcomeBoard: ({ rootState }, {}, operation) ->
    operation.dispatchWithoutHistory 'webjet/copyBoardDelayed',
      path: '/boards/-NHSXvljnmad6FQc6bmc'
      title: i18n.t('board.quickStartGuideTitle')
      team: "team_#{rootState.uid}"
      project: "project_#{rootState.uid}"
      boardId: "welcome_#{rootState.uid}"

  registerUser: (_, { user }, operation) ->
    @commit 'user/CREATE_REGUSER_TASK',
      uid: user.uid
      email: user.email

  processInviteCode: (_, { user, invitecode }, operation) ->
    @commit 'user/CREATE_INVITEBYCODE_TASK',
      uid: user.uid
      invitecode: invitecode

  initLang: (_, {}, operation) ->
    lang = sessionStorage['langWithoutUser']
    if lang
      operation.dispatchWithoutHistory 'user/setLang', { lang }
      sessionStorage.removeItem 'langWithoutUser'

  setLang: ({ rootState }, { lang }, { undo, redo })->
    if rootState.uid
      settings = await firebind "/users/#{rootState.uid}/settings"
      path = settings.$path
      oldLang = settings.lang
      oldLang = null unless oldLang
      redo 'user/SET_LANG', { path, lang }
      undo 'user/SET_LANG', { path, lang: oldLang }
    else
      sessionStorage['langWithoutUser'] = lang
      i18n.locale = lang

  setGrid: ({ rootState }, { value }, { undo, redo })->
    settings = await firebind "/users/#{rootState.uid}/settings"
    path = settings.$path
    oldValue = settings.grid
    oldValue = null if oldValue == undefined
    newValue = value
    redo 'user/SET_GRID', { path, value: newValue }
    undo 'user/SET_GRID', { path, value: oldValue }

  setGizmo: ({ rootState }, { value }, { undo, redo })->
    settings = await firebind "/users/#{rootState.uid}/settings"
    path = settings.$path
    oldValue = settings.gizmo
    oldValue = null if oldValue == undefined
    newValue = value
    redo 'user/SET_GIZMO', { path, value: newValue }
    undo 'user/SET_GIZMO', { path, value: oldValue }

  setNotifyShare: ({ rootState }, { value }, { undo, redo })->
    settings = await firebind "/users/#{rootState.uid}/settings"
    path = settings.$path
    oldValue = settings.notify_share
    oldValue = null if oldValue == undefined
    newValue = value
    redo 'user/SET_NOTIFY_SHARE', { path, value: newValue }
    undo 'user/SET_NOTIFY_SHARE', { path, value: oldValue }

  setFirstName: ({ rootState }, { value }, { undo, redo })->
    userData = await firebind '/users/'+rootState.uid
    path = userData.$path
    #oldValue = userData.firstName || null
    #newValue = value
    #redo 'user/SET_FIRSTNAME', { path, value: newValue }
    #undo 'user/SET_FIRSTNAME', { path, value: oldValue }
    @commit 'user/SET_FIRSTNAME', { path, value }
    @commit 'user/CREATE_REGUSER_TASK',
      uid: rootState.uid
      email: userData.email

  setLastName: ({ rootState }, { value }, { undo, redo })->
    userData = await firebind '/users/'+rootState.uid
    path = userData.$path
    #oldValue = userData.lastName || null
    #newValue = value
    #redo 'user/SET_LASTNAME', { path, value: newValue }
    #undo 'user/SET_LASTNAME', { path, value: oldValue }
    @commit 'user/SET_LASTNAME', { path, value }
    @commit 'user/CREATE_REGUSER_TASK',
      uid: rootState.uid
      email: userData.email

  setAvatar: ({ rootState }, { file }, operation)->
    userData = await firebind "/users/#{rootState.uid}"
    ext = file.name.split('.')
    ext = ext[ext.length - 1]
    if ext
      filename = 'avatar.' + ext
    else
      filename = 'avatar'
    fileRef = rootState.storage.ref().child(rootState.uid).child(filename)
    #console.log '!', userData.avatarFile, filename, fileRef.fullPath
    beforeunload = getBeforeUnloadHandler()
    window.addEventListener 'beforeunload', beforeunload
    uploadTask = fileRef.put file, { cacheControl: 'private, max-age=8640000' }
    uploadTask.on(
      'state_changed'
      (progressData) =>
        # do nothing
      (error) =>
        console.error error
        window.removeEventListener 'beforeunload', beforeunload
      (complete) =>
        try
          url = await fileRef.getDownloadURL()
          if userData.avatarFile and userData.avatarFile!=filename
            oldFileRef = rootState.storage.ref().child(rootState.uid).child(userData.avatarFile)
            #console.log '!!', oldFileRef.fullPath
            await oldFileRef.delete().catch (e) -> console.error 'WARN: error delete old file', e
          #console.log '!!!', url
          @commit 'user/SET_AVATAR',
            path: userData.$path
            filename: filename
            url: url
        finally
          window.removeEventListener 'beforeunload', beforeunload
    )

  removeAvatar: ({ rootState }, {}, operation)->
    userData = await firebind "/users/#{rootState.uid}"
    #console.log '!', userData.avatar, userData.avatarFile
    if userData.avatarFile
      oldFileRef = rootState.storage.ref().child(rootState.uid).child(userData.avatarFile)
      #console.log '!!', oldFileRef.fullPath
      await oldFileRef.delete()
      userProviderData = rootState.auth().currentUser.providerData[0]
      if userProviderData?.providerId == 'google.com' and userProviderData?.photoURL
        defaultURL = userProviderData.photoURL
      else
        defaultURL = null
      @commit 'user/SET_AVATAR',
        path: userData.$path
        filename: null
        url: defaultURL

  addCoupon: ({ rootState }, { coupon, type }, operation)->
    userData = await firebind "/users/#{rootState.uid}"
    @commit 'user/ADD_COUPON',
      path: userData.$path
      coupon: coupon
      type: type
      uid: rootState.uid
