






































































import { generateSort } from '@/utils'

export default
  name: 'RelationPlusTools'
  inject: ['provideApp', 'provideCanvas']
  props: ['point']
  data: ->
    rect: null
    showShapes: false
  computed:
    boardPath: -> @provideCanvas.data.$path
    selected: -> @provideCanvas.selected
    lastShapeType: -> @provideCanvas.lastShapeType
    lastShapeTitle: ->
      firstLetter = @lastShapeType.charAt(0).toUpperCase()
      shapeType = @lastShapeType.replace(/right|left|bottom|top|-/g,'')
      return @$t('canvas.action.create' + firstLetter + shapeType.slice(1))
    style: ->
      if @rect
        y = 0
        x = 0
        cpy = @provideCanvas.position.y
        czy = @provideCanvas.zoom.dy
        cpx = @provideCanvas.position.x
        czx = @provideCanvas.zoom.dx
        ppx = @point.x
        ppy = @point.y
        scale = @provideCanvas.scale
        dy = cpy + czy + ppy * scale
        dx = cpx + czx + ppx * scale
        offset = 40 * scale
        offset = 30 if offset < 30
        offset = 170 if offset > 170
        # position Y
        y = dy - @rect.height - offset
        if y < 0
          y = dy + offset
        x = dx
        # position X
        if x < (@rect.width + 40) / 2
          x = (@rect.width + 60) / 2
        if x > @$vuetify.breakpoint.width - (@rect.width + 40) /2
          x = @$vuetify.breakpoint.width - (@rect.width + 60) /2
        return
          left: "#{x}px"
          top: "#{y}px"
    contextWebjet: ->
      selected = @$store.getters['webjet/selected']
      selected[0]
    type: ->
      @point.type
    relation: ->
      @point.relation
    side2: ->
      return 'bottom' if @point.side == 'top'
      return 'top' if @point.side == 'bottom'
      return 'left' if @point.side == 'right'
      return 'right' if @point.side == 'left'


  methods:
    selectionCentralize: -> @provideCanvas.selectionCentralize()
    hideRelationPlusTools: ->
      @relation.drag.point = false
      @provideCanvas.relationPlusPoint = false

    createRelation: (webjet) ->
      if webjet?.webjetPath
        @$store.dispatch 'webjet/relation/create',
              boardPath: @provideCanvas.connection.src
              src1: @contextWebjet.connection.src
              src2: webjet.webjetPath
              side1: @point.side
              side2: @side2
      @$store.dispatch 'webjet/resetSelection'
      @relation.drag.point = false
      @provideCanvas.relationPlusPoint = false

    moveRelation: (webjet) ->
      data = @point.data
      if webjet?.webjetPath and data?.relationConnectionPath
        @$store.dispatch 'webjet/relation/move',
          path: data.relationConnectionPath
          dest: webjet.webjetPath
          side: @point.side
      @$store.dispatch 'webjet/resetSelection'
      @relation.drag.point = false
      @provideCanvas.relationPlusPoint = false


    coordinadeBySide: (width = 320, height = 80, type) ->
      side = @side2
      x = @point.x
      y = @point.y
      switch side
        when 'left'
          # x += 160
          if type == 'shape'
            y -= height / 2
          else
            y -= 20

          break
        when 'right'
          x -= width
          if type == 'shape'
            y -= height / 2
          else
            y -= 20
          break
        when 'top'
          x -= width / 2
          # y -= 20
          break
        when 'bottom'
          x -= width / 2
          y -= height
          break
      { x, y }

    createCard: (e)->
      cbs = @coordinadeBySide()
      webjet = await @$store.dispatch 'webjet/create',
        category: '/webjets/content/webjets/note'
        dest: @boardPath
        boardPath: @boardPath
        connection:
          coordinates:
            x: cbs.x
            y: cbs.y
      if @type == 'create'
        @createRelation(webjet)
      else if @type == 'move'
        @moveRelation(webjet)
      @provideCanvas.selectCreatedWebjets [ webjet ]

    createShape: (e, type, canTouch = true)->
      size =
        w: 160
        h: 160
      size.h = 110 if type == 'doc'
      size.h = 100 if type == 'ellipse'
      size.h = 148 if type == 'star'
      size.h = 112 if type == 'trapezoid'
      size.h = 80 if type == 'parallelogram' or type == 'parallelogram-right'

      cbs = @coordinadeBySide(size.w, size.h, 'shape')
      webjet = await @$store.dispatch 'webjet/create',
        category: '/webjets/content/webjets/shape'
        dest: @boardPath
        boardPath: @boardPath
        webjet:
          data:
            type: type
            text: ''
        connection:
          coordinates:
            x: cbs.x
            y: cbs.y
      if @type == 'create'
        @createRelation(webjet)
      else if @type == 'move'
        @moveRelation(webjet)
      @provideCanvas.selectCreatedWebjets [ webjet ]
      @provideCanvas.lastShapeType = type

    createList: (e)->
      cbs = @coordinadeBySide(340,90)
      webjet = await @$store.dispatch 'webjet/create',
        category: '/webjets/containers/webjets/list'
        dest: @boardPath #dest
        boardPath: @boardPath
        connection:
          coordinates:
            x: cbs.x
            y: cbs.y
      if @type == 'create'
        @createRelation(webjet)
      else if @type == 'move'
        @moveRelation(webjet)
      @provideCanvas.selectCreatedWebjets [ webjet ]

    createGraffiti: (e) ->
      cbs = @coordinadeBySide(320,320)
      webjet = await @$store.dispatch 'webjet/create',
        category: '/webjets/content/webjets/graffiti'
        dest: @boardPath
        boardPath: @boardPath
        connection:
          coordinates:
            x: cbs.x
            y: cbs.y
      if @type == 'create'
        @createRelation(webjet)
      else if @type == 'move'
        @moveRelation(webjet)
      @provideCanvas.selectCreatedWebjets [ webjet ]

    openUpload: ->
      input = @$refs.plusToolsUpload
      if input
        input.click()
    processUpload: ->
      cbs = @coordinadeBySide()
      input = @$refs.plusToolsUpload
      if input?.files?.length > 0
        if not (await @provideApp.checkFileSizeLimits input.files, @boardPath)
          input.value = null
          return
        connection = Object.assign {}
        connection.coordinates = {
          x: cbs.x
          y: cbs.y
        }
        # Object passed as dataTransfer parameter should mimic ClipboardEvent.clipboardData and DragEvent.dataTransfer objects
        # in extent enough for webjet/dataTransfer operation to work. So far this operation uses types[0] and files array only.
        webjets = await @$store.dispatch 'webjet/dataTransfer',
          dest: @boardPath
          boardPath: @boardPath
          dataTransfer:
            types: [ 'Files' ]
            files: input.files
          data: connection
        input.value = null
        if Array.isArray(webjets) and webjets.length == 1
          if @type == 'create'
            @createRelation(webjets[0])
          else if @type == 'move'
            @moveRelation(webjets[0])
        else
          @hideRelationPlusTools()
        @provideCanvas.selectCreatedWebjets webjets

    setRect: ->
      @rect = @$refs.relationPlusTools.getBoundingClientRect()

  mounted: ->
    requestAnimationFrame(@setRect)

  # destroyed: ->
  #   @hideRelationPlusTools()
