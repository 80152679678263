










import Logo from '@/components/Logo'
export default
  name: 'Error404'
  components: { Logo }
