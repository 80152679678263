import { getRef, getPath, getDataUserRef } from '@/utils'
import { firebind } from '@/utils'

export default
  create: (_, { src1, src2, side1, side2, data, boardPath }, { dispatch, undo, redo })->
    if FLAG.NEW_DATA and (IS_DEVELOPMENT or IS_STAGE) and not boardPath?
      console.error 'NEW_DATA error: webjet/relation/create is called without boardPath!'
    webjetKey = getDataUserRef(boardPath).push().key
    webjetPath = getPath webjetKey, boardPath
    await dispatch 'webjet/createWebjet',
      path: webjetPath
      category: 'relation'
      data: data
    await dispatch 'webjet/createConnection',
      dest: src1
      group: 'relations'
      src: webjetPath
      data: { side: side1, point: 1 }
    await dispatch 'webjet/createConnection',
      dest: src2
      group: 'relations'
      src: webjetPath
      data: { side: side2, point: 2 }
    return { webjetKey, webjetPath}

  move: (_, { path, dest, side }, { dispatch })->
    dispatch 'webjet/moveConnection',
      path: path
      dest: dest
      group: 'relations'
      data:
        side: side

  setPosition: (_, { path, x, y }, { dispatch, undo, redo })->
    webjet = await firebind path
    oldX = webjet.data?.x || null
    oldY = webjet.data?.y || null
    redo 'webjet/relation/SET_POSITION', { path, x, y }
    undo 'webjet/relation/SET_POSITION', { path, x: oldX, y: oldY }
    await dispatch 'webjet/onUpdated', { path }

  setTheme: (_, { path, theme }, { undo, redo, dispatch })->
    webjet = await firebind path
    oldId = webjet.data?.backgroundId || null
    newId = String(theme)
    redo 'webjet/relation/SET_THEME', { path, id: newId}
    undo 'webjet/relation/SET_THEME', { path, id: oldId}
    await dispatch 'webjet/onUpdated', { path}

  setWidth: (_, { path, width }, { dispatch, undo, redo })->
    webjet = await firebind path
    oldWidth = webjet.data?.width || null
    redo 'webjet/relation/SET_WIDTH', { path, width }
    undo 'webjet/relation/SET_WIDTH', { path, width: oldWidth }
    await dispatch 'webjet/onUpdated', { path }

  setStyle: (_, { path, style }, { dispatch, undo, redo })->
    webjet = await firebind path
    oldStyle = webjet.data?.style || null
    redo 'webjet/relation/SET_STYLE', { path, style }
    undo 'webjet/relation/SET_STYLE', { path, style: oldStyle }
    await dispatch 'webjet/onUpdated', { path }

  setPointStyle: (_, { path, point, style }, { dispatch, undo, redo })->
    webjet = await firebind path
    oldStyle = webjet.data?[point]?.style || null
    redo 'webjet/relation/SET_POINT_STYLE', { path, point, style }
    undo 'webjet/relation/SET_POINT_STYLE', { path, point, style: oldStyle }
    await dispatch 'webjet/onUpdated', { path }

  setTitle: (_, { path, title }, { dispatch, undo, redo })->
    webjet = await firebind path
    oldTitle = webjet.data?.title
    redo 'webjet/relation/SET_TITLE', { path, title }
    undo 'webjet/relation/SET_TITLE', { path, title: oldTitle }
    await dispatch 'webjet/onUpdated', { path }

  remove: (_, { path }, { dispatch, undo, redo })->
    webjet = await firebind path
    parents = webjet.connections?.parents
    if parents
      for k, c of parents
        cid = c.binding.id
        src = c.src
        connection = false
        w = await firebind src
        if w.connections.relations
          for k, r of w.connections.relations
            if r.id == cid
              connection = r
        if connection
          await dispatch 'webjet/removeConnection', { path: connection.$path }
    data = Object.assign {}, webjet
    redo 'webjet/REMOVE_EMPTY_WEBJET', { path }
    undo 'webjet/CREATE_WEBJET', { path, data }

  #setArrow
  #setStyle
  #setText
