








import ContextBtn from './ContextBtn'
export default
  name: 'Publish'
  components: { ContextBtn }
  inject: { provideCanvas: { default: null } }

  if: (selection)->
    return false unless selection.length == 1
    return false if selection[0].webjet?.category == 'anchor'
    return false unless selection[0].provideScene
    return false if selection[0].$options.name == 'WebjetPaint'
    selection[0].wejeSites if selection[0].hasWejeSites # preload weje sites
    true

  computed:
    selection: -> @$store.getters['webjet/selected']

  methods:
    action: ->
      if @selection[0].wejeSites.length
        webjetPath = @selection[0].wejeSites[0]
      else
        { webjetPath } = await @$store.dispatch 'weje/create',
          dest: @provideCanvas.connection.src
          path: @selection[0].connection.$path
      @provideCanvas.dialogSiteShareSrc = webjetPath
