




























import Vue from 'vue'
import fitCurve from 'fit-curve'
import { getDataUserRef, isSafari, isMac, extremPointsCurves } from '@/utils'

graffitiData = Vue.observable
  activeGraffitiEdit: false
  graffitiType: 'pen'

graffitiTypes = Vue.observable
  pen:
    color: 'black'
    width: 5
  marker:
    color: 'black'
    width: 5
  lastic:
    color: null
    width: null

export default
  name: 'WGraffitiImg'
  inject: ['provideWebjet','provideCanvas','provideSidebar']
  inject: { provideWebjet: { default: false }, provideCanvas: { default: false }, provideSidebar: { default: false }}

  data: ->
    listeners:
      # mousedown: @mouseDown
      mouseup: @mouseUp
      wheel: @wheel
      # touchstart: @touchStart
      touchmove: @touchMove
      touchcancel: @touchEnd
      touchend: @touchEnd
      gesturestart: @pinchGesturestart
      gesturechange: @pinchGesturechange
      gestureend: @pinchGestureend
    draw: false
    drag: false
    newLine: false
    line: ''
    points: {}
    startPostition: { x: -250, y: -250, active: false}
    mousePosition: { x: null, y: null, active: false}
    maxSize: 2000 # size of square face
    zoom: {level: 0}
    pinchActive: false
    pinchInitialDistance: 0
    pinchInitialScale: 0
    averageDistanceItems: []
    trackTimeStamp: null
    tmp: 0

  methods:
    activateEditor: (e) ->
      return if !@provideWebjet.isSelected # selection on first click, editor activation on second
      return if e? and (e.shiftKey || e.ctrlKey || e.altKey || e.metaKey)
      return if @provideWebjet.isReadOnly
      return if @provideWebjet.isInTrash
      if FLAG.GRAFFITI_TOOLS_TO_CONTEXT_MENU
        @graffitiData.activeGraffitiEdit = true
      else
        @provideCanvas.activeGraffitiEdit = true if @provideCanvas

    deactivateEditor: (e) ->
      if FLAG.GRAFFITI_TOOLS_TO_CONTEXT_MENU
        @graffitiData.activeGraffitiEdit = false
      else
        if @provideCanvas
          @provideCanvas.activeGraffitiEdit = false

    removeLine: (key) ->
      @$delete @points, key
      @$store.dispatch 'webjet/removeGraffitiLine',
        path: @provideWebjet.webjet.$path
        id: key

    getFitCurve: (dots) ->
      movingAverage = @movingAverage dots, 5
      firstPoint = dots[0]
      curvePoints = fitCurve movingAverage, 5
      result = "M#{firstPoint[0]} #{firstPoint[1]}"
      for p in curvePoints
        result += " C#{p[1][0]} #{p[1][1]} #{p[2][0]} #{p[2][1]} #{p[3][0]} #{p[3][1]}"
      result

    mouseOverLine: (e,key)->
      return unless @draw
      return unless e.buttons == 1
      return unless @type == 'lastic'
      @removeLine key

    wheel: (e)->
      # console.log 'wheel', e
      e.preventDefault()
      e.stopPropagation()
      @trackTimeStamp = 0 unless @trackTimeStamp
      timeDelta = e.timeStamp - @trackTimeStamp
      # console.log {deltaMode: e.deltaMode, deltaX: e.deltaX, deltaY: e.deltaY, ctrlKey: e.ctrlKey, timeDelta: timeDelta}
      @trackTimeStamp = e.timeStamp

      if e.deltaMode == 1
        if e.deltaX || !e.ctrlKey && (timeDelta < 1000 || Math.abs(e.deltaY) < 1 || e.deltaY % 1 != 0)
          @wheelTrackpad(e)
          return
      else
        if e.deltaX || !e.ctrlKey && (timeDelta < 1000 || Math.abs(e.deltaY) < 100 || e.deltaY % 5 != 0)
          @wheelTrackpad(e)
          return
      @trackTimeStamp = 0

      return if e.deltaY == 0
      deltaY = e.deltaY
      scale = @scale

      rect = @$refs.graffiti?.getBoundingClientRect()
      return unless rect
      rectScale = @viewBoxData.width / rect.width
      mouseX = e.clientX - rect.x
      mouseY = e.clientY - rect.y
      x = @viewBoxData.left + mouseX * rectScale
      y = @viewBoxData.top + mouseY * rectScale
      if deltaY > 0
        @zoom.level += 1
        if @zoom.level > @maxZoom
         @zoom.level = @maxZoom

      else if deltaY < 0
        @zoom.level -= 1


      rectScale = @viewBoxData.width / rect.width
      @startPostition.x += x - (@viewBoxData.left + mouseX * rectScale)
      @startPostition.y += y - (@viewBoxData.top + mouseY * rectScale)

    wheelTrackpad: (e) ->
      dx = e.deltaX
      dy = e.deltaY
      if isMac
        @startPostition.x += dx
        @startPostition.y += dy
      else
        if e.deltaMode == 1
          dx = dx * 20
          dy = dy * 20
        @startPostition.x += dx
        @startPostition.y += dy

    drawLine: (e) ->
      if @type == 'lastic'
        for k,v of @lines
          points = v.curve.split ' '
          console.log points
        console.log @lines
      return if @type == 'lastic'
      # scene = @provideCanvas.scene
      scale = @scale
      rect = @$refs.graffiti?.getBoundingClientRect()
      return unless rect

      rectScale = @baseWidth / rect.width
      x = ((e.clientX - rect.left)  * rectScale * Math.pow(1.1, @zoom.level)) + @startPostition.x
      y = ((e.clientY - rect.top)  * rectScale * Math.pow(1.1, @zoom.level)) + @startPostition.y
      x = +x.toFixed(2)
      y = +y.toFixed(2)

      push = true
      if @newLine
        lineId = getDataUserRef().push().key
        @line = lineId
        width = if @type == 'marker' then @width * 10 else @width
        @$set @points, lineId, {points: [], color: @color, width: width, type: @type  }

      else
        length = @points[@line].points.length
        lastPoint = @points[@line].points[length - 1]
        deltaWidth = Math.sqrt(Math.pow(x - lastPoint.x, 2) + Math.pow(y - lastPoint.y, 2))

      @newLine = false
      if push
        @points[@line].points.push [x,y]

    mouseDrag: (e) ->
      unless @mousePosition.active
        @mousePosition.active = true
      else
        rect = @$refs.graffiti?.getBoundingClientRect()
        return unless rect
        rectScale = @viewBoxData.width / rect.width
        x = @mousePosition.x - e.clientX
        y = @mousePosition.y - e.clientY
        @startPostition.x += x * rectScale
        @startPostition.y += y * rectScale
      @mousePosition.x = e.clientX
      @mousePosition.y = e.clientY
      # console.log @maxWidth, @minMax, @mousePosition.x, @viewBoxData.width
      e.preventDefault()
      e.stopPropagation()
    mouseUp: (e) ->
      @draw = false
      @drag = false
      @mousePosition.active = false
      return false if @type == 'lastic'
      line = @points[@line]
      return false unless line and line.points.length
      dots = line.points

      unless dots.length < 7
        curve = @getFitCurve dots

        @$set @points[@line], 'curve', curve
        if @provideWebjet?.webjet?.$path
          @$store.dispatch 'webjet/setGraffitiLine',
            path: @provideWebjet.webjet.$path
            id: @line
            data:
              curve: curve
              color: line.color
              width: line.width
              type: line.type


    mouseMove: (e) ->
      return unless @draw or @drag

      if (@draw and e.buttons != 1) or (@drag and e.buttons != 4)
        @mouseUp e
        return
      if @draw
        @drawLine e
      else
        @mouseDrag e

    mouseDown: (e) ->
      # return true if e.buttons == 4
      if !e.target.closest('.w-graffiti-canvas-active') and !e.target.closest('.fly-context-menu')
        @deactivateEditor()
        return
      if e.buttons == 1
        @newLine = true
        @draw = true
      else if e.buttons == 4
        @drag = true
      e.preventDefault()
      e.stopPropagation()
    pinchGesturestart: (e) ->
      e.preventDefault()
      e.stopPropagation()
    pinchGesturechange: (e) ->
      e.preventDefault()
      e.stopPropagation()
    pinchGestureend: (e) ->
      e.preventDefault()
      e.stopPropagation()
    touchStart: (e) ->
      # console.log 'touchStart', e.target
      # return if isSafari
      if !e.target.closest('.w-graffiti-canvas-active') and !e.target.closest('.fly-context-menu')
        @deactivateEditor()
        return
      pinch = e.touches?.length == 2
      if pinch
        @pinchActive = true
        @pinchInitialDistance = @pinchDistance(e)
        @pinchInitialScale = @zoom.level
      else
        @newLine = true
        @draw = true
      e.preventDefault()
      e.stopPropagation()

    touchEnd: (e) ->
      # return if isSafari
      e.preventDefault()
      e.stopPropagation()
      @draw = false
      @pinchActive = false
      @pinchInitialDistance = 0
      @pinchInitialScale = 0
      @mousePosition.active = false
      @averageDistanceItems = []
      return if @type == 'lastic'
      line = @points[@line]
      # return unless line and line.length
      dots = line.points
      unless dots.length < 7
        curve = @getFitCurve dots

        @$set @points[@line], 'curve', curve

        @$store.dispatch 'webjet/setGraffitiLine',
          path: @provideWebjet.webjet.$path
          id: @line
          data:
            curve: curve
            color: line.color
            width: line.width
            type: line.type
    pinchDistance: (e) ->
      distance = Math.sqrt(
          Math.pow(e.touches[0].pageX - e.touches[1].pageX, 2) + Math.pow(e.touches[0].pageY - e.touches[1].pageY, 2)
        )
      return parseInt(distance, 10)
    touchMove: (e) ->

      # return if isSafari
      if @pinchActive
        @touchDragPinch(e)
        # @zoom.level += 1
      else
        @touchDraw(e)

      e.preventDefault()
      e.stopPropagation()

    touchDragPinch: (e) ->
      rect = @$refs.graffiti?.getBoundingClientRect()
      rectScale = @viewBoxData.width / rect.width
      ex = (e.touches[0].clientX + e.touches[1].clientX) / 2
      ey = (e.touches[0].clientY + e.touches[1].clientY) / 2
      mouseX = ex - rect.x
      mouseY = ey - rect.y
      x = @viewBoxData.left + mouseX * rectScale
      y = @viewBoxData.top + mouseY * rectScale
      if false
        distance = @pinchDistance(e)
        i = Math.floor((distance - @pinchInitialDistance) / 10)
        zoom = @pinchInitialScale - (i/10)
      else
        distance = @pinchDistance(e)
        @averageDistanceItems.splice 0, 0, distance
        if @averageDistanceItems.length < 6
          zoom = @zoom.level
        else

          startDistance = @averageDistanceItems[5]
          averageDistance = 0
          for index in [0..4]
            averageDistance += @averageDistanceItems[index]

          averageDistance = averageDistance / 5
          # @tmp = averageDistance - startDistance
          tmpDistance =  averageDistance - startDistance
          if tmpDistance > 0.5 or tmpDistance < -0.5
            i = (averageDistance - startDistance ) / 10
            zoom = @zoom.level - (i/7)
          else
            zoom = @zoom.level


      zoom = @maxZoom if zoom > @maxZoom
      @zoom.level = zoom

      rectScale = @viewBoxData.width / rect.width


      @startPostition.x += x - (@viewBoxData.left + mouseX * rectScale)
      @startPostition.y += y - (@viewBoxData.top + mouseY * rectScale)
      unless @mousePosition.active
        @mousePosition.active = true
      else if rect and @mousePosition.active

        x = @mousePosition.x - ex
        y = @mousePosition.y - ey
        # @tmp = x
        if x < -0.5 or x > 0.5
          @startPostition.x = @startPostition.x + x * rectScale
        if y < -0.5 or y > 0.5
          @startPostition.y = @startPostition.y + y * rectScale


      @mousePosition.x = ex
      @mousePosition.y = ey
      e.preventDefault()
      e.stopPropagation()
    touchDraw: (e) ->

      return unless @draw
      return unless e.touches?.length == 1 and !e.target.matches('.v-menu__content *') and !e.target.matches('.canvas-graffiti-tools *')

      if @type == 'lastic'
        touch = e.touches[0]
        path = document.elementFromPoint(touch.clientX, touch.clientY)
        return unless path.tagName == 'path' and path.id
        @removeLine path.id

      else
        # scene = @provideCanvas.scene
        scale = @scale

        rect = @$refs.graffiti.getBoundingClientRect()
        rectScale = @baseWidth / rect.width

        x = ((e.touches[0].clientX - rect.left) * rectScale * Math.pow(1.1, @zoom.level)) + @startPostition.x
        y = ((e.touches[0].clientY - rect.top) * rectScale * Math.pow(1.1, @zoom.level)) + @startPostition.y

        push = true

        if @newLine
          lineId = getDataUserRef().push().key
          @line = lineId
          width = if @type == 'marker' then @width * 10 else @width
          @$set @points, lineId, {points: [], color: @color, width: width, type: @type  }

        @newLine = false

        if push
          @points[@line].points.push [x,y]
    editorDragstart: (e)->
      if editMode
        e.preventDefault()
        e.stopPropagation()
      return
    movingAverage: (arr, range) ->

      step = arr.length - range
      result = []
      i = 0
      while i < step
        limit = i + range
        x = 0
        y = 0
        j = i
        while j < limit
          x += arr[j][0]
          y += arr[j][1]
          j += 1
        x = x / range
        y = y / range
        x = +x.toFixed(2)
        y = +y.toFixed(2)
        result.push [x,y]
        i += 1
      result


    quadraticDraw: (arr) ->

      return unless arr.length

      result = "M#{arr[0][0]} #{arr[0][1]}"
      dots = @movingAverage arr, 5

      for p, i in dots

        if i > 2
          lastTwoPoints = dots.slice i-2

          endPoint =
            {
              x: (lastTwoPoints[0][0] + lastTwoPoints[1][0]) / 2
              y: (lastTwoPoints[0][1] + lastTwoPoints[1][1]) / 2
            }

          result += " Q#{lastTwoPoints[0][0]} #{lastTwoPoints[0][1]} #{endPoint.x } #{endPoint.y}"

      result

  computed:
    pointsForIntersection: ->
      console.log @lines
      return true
    graffitiTypes: -> graffitiTypes
    graffitiData: -> graffitiData
    viewBoxData: ->
      minX = @minMax.min.x
      minY = @minMax.min.y
      maxX = @minMax.max.x
      maxY = @minMax.max.y
      if !@editMode

        if @lines
          {x1, y1, x2, y2} = @extremPoints



          x1 = minX if x1 < minX
          y1 = minY if y1 < minY
          x2 = maxX if x2 > maxX
          y2 = maxY if y2 > maxY

          log = (a,b) ->
            return Math.log(a) / Math.log(b)
          w = x2 - x1
          h = y2 - y1
          center = {x: x1 + w / 2, y:  y1 + h / 2  }

          zoom = -10
          while w > @baseWidth * Math.pow(1.1,zoom) or h > @baseHeight * Math.pow(1.1,zoom)
            zoom += 1

          zoom = @maxZoom if zoom > @maxZoom

          offsetX = 0
          offsetY = 0
          center.x = center.x + offsetX
          center.y = center.y + offsetY
          boxWidth = (@baseWidth * Math.pow(1.1,zoom))
          boxHeight = (@baseHeight * Math.pow(1.1,zoom))
          spX = center.x - boxWidth / 2
          spY = center.y - boxHeight / 2

          if spX < minX
            spX = minX
          else if spX + boxWidth > maxX
            offset = spX + boxWidth - maxX
            spX = spX - offset

          if spY < minY
            spY = minY
          else if spY + boxHeight > maxY
            offset = spY + boxHeight - maxY
            spY = spY - offset

          @startPostition.x = spX
          @startPostition.y = spY

          @zoom.level = zoom

        else
          @zoom.level = 0
          @startPostition.x = @baseWidth / 2 * -1
          @startPostition.y = @baseHeight / 2 * -1
      i = @zoom.level

      width = Math.round(@baseWidth * Math.pow 1.1,i)
      height = Math.round(@baseHeight * Math.pow 1.1,i)

      left = @startPostition.x
      top = @startPostition.y
      # console.log {left: left, top: top, width: width, height: height}


      return {left: left, top: top, width: width, height: height}
    viewBox: ->
      return "#{@viewBoxData.left} #{@viewBoxData.top} #{@viewBoxData.width} #{@viewBoxData.height}"
    scale: ->
      if @provideCanvas
        @provideCanvas.scale
      else
        1

    currentZoom: ->
      i = @zoom.level
      scale = Math.pow 1.1,i
      return  Math.round 100 / scale

    extremPoints: ->
      if @lines
        lines = Object.values @lines
        curvs = []
        for l in lines
          if l.curve
            curvs.push l.curve
        { x1, y1, x2, y2 } = extremPointsCurves curvs
        return { x1, x2, y1, y2 }
      else
        return null
    styleCloseEdit: ->
      if @provideSidebar
        scale = 1
      else
        scale = 1 / @scale
        scale = 1 if scale < 1
        scale = 4 if scale > 4
        scale = +scale.toFixed(1)
      return
        transform: "translateX(-50%) scale(#{scale})"
    gridPath: ->
      width = @maxWidth
      height = @maxHeight
      # side width of grid react in px (50)
      rect = 50
      linesX = width / rect
      linesY = height / rect
      iX = 0
      iY = 0
      path = ""
      x = @minMax.min.x
      y = @minMax.min.y
      while iX < linesX - 1
        x += rect
        path += " M #{x} #{@minMax.min.y} L #{x} #{@minMax.max.y}"
        iX += 1

      while iY < linesY - 1
        y += rect
        path += " M #{@minMax.min.x} #{y} L #{@minMax.max.x} #{y} "
        iY += 1
      return path
    maxZoom: ->
      log = (a,b) ->
        return Math.log(a) / Math.log(b)
      ix = log(@maxWidth / @baseWidth, 1.1)
      iy = log(@maxHeight / @baseHeight, 1.1)
      i = if ix <= iy then ix else iy
      i
    grid: ->
      grid = @provideWebjet.webjet?.data?.grid
      return true if grid == undefined
      grid
    editMode: ->
      if FLAG.GRAFFITI_TOOLS_TO_CONTEXT_MENU
        @graffitiData.activeGraffitiEdit and @selected
      else
        @provideCanvas.activeGraffitiEdit and @selected
    lines: ->
      @provideWebjet.webjet.data?.lines
    sizeType: ->
      type = @provideWebjet.webjet.data?.sizeType
      return 0 unless type
      type
    isEmpty: ->
      return true unless @lines
      lines = Object.keys(@lines)
      return false if lines.length
      true
    width: ->
      if FLAG.GRAFFITI_TOOLS_TO_CONTEXT_MENU
        @graffitiTypes[@type].width
      else
        @provideCanvas.graffitiWidth
    type: ->
      if FLAG.GRAFFITI_TOOLS_TO_CONTEXT_MENU
        @graffitiData.graffitiType
      else
        @provideCanvas.graffitiType
    color: ->
      if FLAG.GRAFFITI_TOOLS_TO_CONTEXT_MENU
        @graffitiTypes[@type].color
      else
        @provideCanvas.graffitiColor
    selected: ->
      @provideWebjet.isSelected
    data: ->
      data = @provideWebjet.webjet?.data
      if data
        size = JSON.stringify(@provideWebjet.webjet?.data).length
        size = (size / 1024).toFixed(2) + "kb"
        size
      else "0 kb"

    xLineStyle: ->
      i = @zoom.level
      width = Math.round((@baseWidth * Math.pow 1.1,i) / @maxWidth * 100)
      left = Math.round(@startPostition.x / @maxWidth * 100)
      height = Math.round(5 / @scale)
      height = 10 if height > 10
      return
        width: "#{width - 1}%"
        height: "#{height}px"
        left: "#{left + 50}%"
    yLineStyle: ->
      i = @zoom.level
      height = Math.round((@baseHeight * Math.pow 1.1,i) / @maxHeight * 100)
      top = Math.round(@startPostition.y / @maxHeight * 100)
      width = Math.round(5 / @scale)
      width = 10 if width > 10

      return
        width: "#{width}px"
        height: "#{height - 1}%"
        top: "#{top + 50}%"
    zoomTextStyle: ->
      scale = @scale
      fontSize = 14 / scale
      fontSize = 6 if fontSize < 6
      fontSize = 80 if fontSize > 80
      return
        'font-size': "#{fontSize}px"
    proportion: ->
      switch @sizeType
        when 0 then 1/1
        when 1 then 4/3
        when 2 then 3/4
        when 3 then 16/9
        when 4 then 9/16
        else 1/1
    baseWidth: ->
      switch @sizeType
        when 0 then 500
        when 1 then 500
        when 2 then 375
        when 3 then 500
        when 4 then 281
        else 500

    baseHeight: ->
      return Math.round @baseWidth / @proportion

    maxWidth: ->
      return @baseWidth * 4
    maxHeight: ->
      return @baseHeight * 4

    minMax: ->
      return
        min: { x: @maxWidth / 2 * -1, y: @maxHeight / 2 * -1 }
        max: { x: @maxWidth / 2, y: @maxHeight / 2 }

  watch:
    viewBox: (val) ->
      minX = @minMax.min.x
      minY = @minMax.min.y
      maxX = @minMax.max.x
      maxY = @minMax.max.y
      width = @viewBoxData.width
      height = @viewBoxData.height

      @startPostition.x = minX if @startPostition.x < minX
      @startPostition.y = minY if @startPostition.y < minY

      @startPostition.x = maxX - width if @startPostition.x + width > maxX
      @startPostition.y = maxY - height if @startPostition.y + height > maxY

    lines: (val) ->
      lines = {}
      for key,value of @points
        unless val.hasOwnProperty key
          @$set lines, key, value
       @points = lines


    editMode: (val) ->
      if val
        window.addEventListener 'touchstart', @touchStart, {capture: true}

        window.addEventListener 'mousemove', @mouseMove, {capture: true, passive: false}
        window.addEventListener 'mousedown', @mouseDown, {capture: true, passive: false}

      else
        @deactivateEditor()
        window.removeEventListener 'touchstart', @touchStart, {capture: true}

        window.removeEventListener 'mousemove', @mouseMove, {capture: true, passive: false}
        window.removeEventListener 'mousedown', @mouseDown, {capture: true, passive: false}
  beforeDestroy: ->
    @deactivateEditor()
