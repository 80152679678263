
































































import { date,userDisplayName,getOwnerUID,getPath } from '@/utils'
import DeletePageDialog from '@/components/DeletePageDialog'
import DialogSlide from '@/components/DialogSlide'
export default
  name: 'SitePreview'
  props: ['src']
  #inject: ['provideWejeContent'] for check what to do on click?
  inject:
    provideCanvas: { default: null }
    provideSidebar: { default: null }
  components: { DeletePageDialog, DialogSlide}
  data: ->
    showDeletePageDialog: false
  computed:
    show: ->
      @weje?.$ready
    classes: ->
      return
        isInTrash: @isInTrash
    siteUrl: ->
      "/w/#{@weje.$path.split('/').pop()}"
    title: ->
      @weje.data?.title
    showTrash: ->
      @userCanEdit
    isInTrash: ->
      return unless @weje?.connections?.parents
      Object.values(@weje.connections.parents).every (c)-> c.weak
    board: ->
      for k, v of @weje?.connections?.parents
        if v.src
          boardCategory = @firebind "#{v.src}/category"
          if boardCategory?.$value == '/webjets/containers/webjets/canvas'
            return @firebind "#{v.src}"
      return null
    boardId: ->
      return '' unless @board
      return @board.$path.split('/').pop()
    boardInTrash: ->
      inTrash = false
      inTrash = @board?.boardDeleted if @board?.boardDeleted
      inTrash
    userCanEdit: ->
      if @provideSidebar and @provideCanvas
        return @provideCanvas.userCanEdit
      else
        shared = null
        boardSrc = null
        shared = @board.shared
        boardSrc = @board.$path
        return false unless shared and boardSrc
        return true if getOwnerUID(boardSrc) == @$store.state.uid
        return true if shared?['*']?.includes 'w'
        return true if shared?[@$store.state.uid]?.includes 'w'
        false
    isPublic: ->
      @weje?.public
    onCanvas: ->
      @provideCanvas
    author: ->
      @weje?.meta?.createUser
    authorName: ->
      return unless @author
      if @author == @$store.state.uid
        return @$t('common.me')
      else
        return userDisplayName @author
    initials: ->
      return '' unless @title
      result = ''
      words = @title.replace(/[^\p{L}\p{Z}^$\n]/gu, '').split(' ')
      result += words[0][0] if words[0]
      result += words[1][0] if words[1]
      result.toUpperCase()
    siteImg: ->
      @weje?.data?.url
    created: ->
      date @weje?.meta?.createDate
    isEmpty: ->
      return false unless FLAG.SITE_EMPTY_LABEL
      return false unless @weje
      return true unless @weje.connections?.items
      # TODO: return false if no access

      # find any strong webjet
      for k, v of @weje.connections.items
        page = @firebind v.src
        if page.$ready and page.category == 'weje/page'
          if page.connections?.items
            for wk, wv of page.connections.items
              webjet = @firebind wv.src
              if webjet.$ready
                if webjet.connections?.parents
                  isStrong = Object.values(webjet.connections.parents).some (c)-> !c.weak
                  return false if isStrong
      return true

  firebind:
    connection: -> @src
    weje: -> @connection and @connection.src
  methods:
    openSiteTab: (tab)->
      if @provideSidebar and @provideCanvas
        @provideSidebar.$refs.content.open @connection.src
        setTimeout =>
          @provideSidebar.$refs.content.$refs.site.wejeTabs = tab
      else
        @$router.push { name: 'Board', params: { boardId: @boardId }, query: {s: @connection.src, t: tab}}

    trash: ->
      @$store.dispatch 'weje/trashSiteConnection', { path: @src, boardSrc: @board.$path }

    restore: ->
      wejeSrc = @weje.$path
      await @$store.dispatch 'webjet/move',
        dest: @provideCanvas.connection.src
        items: [{ path: @src }]
        group: 'wejes'
      @provideCanvas.openWejeSite wejeSrc
