@tt = {}
@lastSum = 0
@stack = []
@outTime = 0
@cindex = 0
window.ttf = (n, f)=>
  ot = Date.now()
  #@stack.push 0

  index = @cindex #@stack.length
  @stack[@cindex] = 0 unless @stack[@cindex]
  #console.log @stack.length
  @tt[n] = 0 unless @tt[n]

  #console.log 'midle', n, @cindex, '|', @stack.toString()

  @cindex = @cindex + 1
  t = Date.now()
  #t = performance.now()
  result = f()
  now = Date.now()
  @cindex = @cindex - 1
  #now = performance.now()
  dt = now - t
  #console.log dt
  if @stack.length > (index+1)
    ddd = @stack.pop()
    dt = dt - ddd #@stack.pop()

  #console.log @stack.length
  @tt[n] = @tt[n] + dt
  if @cindex == 0
    sum = 0
    #$.each @tt, (k, v)=>
    for k, v of @tt
      sum = sum + v
    if sum != @lastSum and true
      #console.log @outTime, sum, @tt
      console.group 'Time: '+sum
      for k,v of @tt
        str = k + ": " +v
        if @lastTT and @lastTT[k] and @lastTT[k]!=@tt[k]
          console.log str + ("%c +" + (@tt[k]-@lastTT[k])), "color: red"
        else
          console.log str
        #str += (" +" + (@tt[k]-@lastTT[k])) if @lastTT and @lastTT[k] and @lastTT[k]!=@tt[k]
        #console.log str
      console.groupEnd 'Time: '+sum
      @lastTT = {}
      @lastTT[k]=v for k,v of @tt
      @lastSum = sum

  #console.log 'mid22', n, @cindex, '|', @stack.toString()

  if @cindex == 0
    @outTime = @outTime + Date.now() - ot
    @stack.pop()
  else
    #@stack[@stack.length-1] = Date.now() - ot
    @stack[@cindex] = @stack[@cindex] + Date.now() - ot
  #console.log 'end  ', n, @cindex, '|', @stack.toString()
  result
