





















export default
  name:
    "Public"
  props:
    rights: String
  computed:
    accessRights: ->
      {
        edit: 'Can edit'
        view: 'Can view'
      }
    titleLicense: ->
      @accessRights[@rights]
    projectPublic:
      get: ->
        !!@rights
      set: (val) ->
        @$emit 'publicChange', val
  methods:
    selectRights: (rights) ->
      @$emit 'publicChange', rights
