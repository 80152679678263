







import { bezierQudrMinMax } from '@/utils'
import { webjet, positionable, draggable, selectable } from './mixins'
import { quadBezierAABB } from 'bezier-intersect'

export default
  name: 'WebjetPaint'
  mixins: [ webjet, positionable, draggable, selectable ]
  inject:
    provideScenePaints: { default: null }

  listeners: ->
    return unless @provideScene
    return
      mousemove: @mousemove
      mouseenter: @mouseenter
      mouseleave: @mouseleave
  styles: ->
    return {} unless @provideScene
    x = @coordinatesCurve.x
    y = @coordinatesCurve.y
    return
      transform: "translate(#{x}px, #{y}px)"
  classes: ->
    return
      'path-outline': @isSelected
  computed:
    styleDrag: ->
      return
        width: "#{@elementSceneRect.x2 - @elementSceneRect.x1}"
        height: "#{@elementSceneRect.y2 - @elementSceneRect.y1}"
        overflow: 'visible'
        position: 'absolute'
    canDropOnlyOnCanvas: -> true
    baseWidth: ->
      @elementSceneRect.x2 - @elementSceneRect.x1

    coordinatesCurve: ->
      #x = @connection.coordinates.x
      #y = @connection.coordinates.y
      { @x, @y }
    elementSceneRect: ->
      l = @pointsForIntersection.length
      unless l
        console.warn 'WebjetPaint: elementSceneRect - no points'
        return { x1: 0, x2: 0, y1: 0, y2: 0 }
      x1 = null
      y1 = null
      x2 = null
      y2 = null
      if l > 2
        i = 0
        while i <= l-2
          p1 = @pointsForIntersection[i]
          p2 = @pointsForIntersection[i+1]
          p3 = @pointsForIntersection[i+2]

          { mx1, my1, mx2, my2 } = bezierQudrMinMax(p1.x, p1.y, p2.x, p2.y, p3.x, p3.y)
          # console.log { mx1, my1, mx2, my2 }
          x1 = mx1 if x1 == null
          y1 = my1 if y1 == null
          x2 = mx2 if x2 == null
          y2 = my2 if y2 == null


          x1 = mx1 if mx1 < x1
          y1 = my1 if my1 < y1
          x2 = mx2 if mx2 > x2
          y2 = my2 if my2 > y2

          i = i + 2
      else
        p1 = @pointsForIntersection[0]
        x1 = p1.x
        y1 = p1.y
        x2 = p1.x
        y2 = p1.y

      width = @strokeWidth / 2

      x1 = x1 - width
      y1 = y1 - width
      x2 = x2 + width
      y2 = y2 + width
      return { x1, y1, x2, y2 }

    dataPaint: ->
      return @connection?.data
    points: ->
      @dataPaint?.points
    strokeWidth: ->
      @dataPaint?.width
    color: ->
      @dataPaint?.color
    type: ->
      @dataPaint?.type
    strokeOpacity: ->
      if @type == 'marker'
        return 0.5
      return 1
    relativePoints: ->
      @dataPaint?.relative
    transformPoints: ->
      return null unless @points
      points = @points
      l = points.length

      tmpPoints = []
      i = 0
      while i < l
        tmpPoints.push [points[i] ,points[i+1] ]
        i += 2

      points = tmpPoints
      # if relative numbers transform in absolute
      if @relativePoints
        result = []
        for p,index in points
          if index == 0
            result.push p
          else
            prevPoint = result[index - 1]
            result.push [p[0] + prevPoint[0], p[1] + prevPoint[1]]
        return result
      else
        return points

    pointsForCurve: ->
      points = @transformPoints
      l = points?.length
      result = []
      if l
        p = points.slice 0,1
        result.push {x:p[0][0],y:p[0][1]}
      if l and l > 3
        for p,i in points
          if i+3 <= l
            lastTwoPoints = points.slice i+1, i+3
            endPoint =
              {
                x: (lastTwoPoints[0][0] + lastTwoPoints[1][0]) / 2
                y: (lastTwoPoints[0][1] + lastTwoPoints[1][1]) / 2
              }
            result.push {x:lastTwoPoints[0][0],y:lastTwoPoints[0][1]}
            result.push {x:endPoint.x,y:endPoint.y}

      result
    pointsForIntersection: ->
      points = @transformPoints
      l = points?.length
      result = []
      if l
        p = points.slice 0,1
        result.push {x:p[0][0],y:p[0][1]}
      if l and l > 3
        for p,i in points
          if i+3 <= l
            lastTwoPoints = points.slice i+1, i+3
            endPoint =
              {
                x: (lastTwoPoints[0][0] + lastTwoPoints[1][0]) / 2
                y: (lastTwoPoints[0][1] + lastTwoPoints[1][1]) / 2
              }
            result.push {x:lastTwoPoints[0][0],y:lastTwoPoints[0][1]}
            result.push {x:endPoint.x,y:endPoint.y}
      # console.log points,result
      tmp = []
      for p in result
        tmp.push {x:p.x + @coordinatesCurve.x, y:p.y + @coordinatesCurve.y }
      result = tmp
      result

    curve: ->
      @curveByPoints

    curveByPoints: ->
      l = @pointsForCurve.length
      return unless l
      line = ''
      if l > 0 and l < 3
        p = @pointsForCurve[0]
        line = "M#{p.x} #{p.y} L#{p.x} #{p.y}"
      else
        p = @pointsForCurve[0]
        line += "M#{p.x} #{p.y}"
        i = 1

        while i <= l-1
          p1 = @pointsForCurve[i]
          p2 = @pointsForCurve[i+1]

          line += " Q#{p1.x} #{p1.y} #{p2.x} #{p2.y}"
          i = i + 2

      return line
  methods:
    isIntersectSceneRect: (rect) ->
      { x1, y1, x2, y2 } = rect
      if x1 == x2 and y1 == y2
        x = (x1 - @provideCanvas.scene.x1) * @provideCanvas.scale
        y = (y1 - @provideCanvas.scene.y1) * @provideCanvas.scale
        el = document.elementFromPoint(x, y)
        return true if el.id == 'drag-please-drag'
      pfi = @pointsForIntersection
      l = pfi.length
      return unless l
      result = false
      if l > 1
        esr = @elementSceneRect
        if esr.x1 >= x1 and esr.x2 <= x2 and esr.y1 >= y1 and esr.y2 <= y2
          return true
        i = 0
        while i < pfi.length - 2
          res=[]
          intersection = quadBezierAABB(pfi[i].x, pfi[i].y, pfi[i+1].x,pfi[i+1].y, pfi[i+2].x,pfi[i+2].y,x1,y1,x2,y2,res)
          return true if intersection
          i += 2
      else if l == 1
        p = pfi[0]
        return true if p.x > x1 and p.x < x2 and p.y > y1 and p.y < y2
      false
    mousemove: (e)->
      return if e.target.nodeName != 'path' and e.target.nodeName != 'rect'
      @provideScenePaints.mouseOverLine(e,@connection)

      drag = document.querySelector('#drag-please-drag')
      if drag
        drag.style.top = "#{e.canvasY}px"
        drag.style.left = "#{e.canvasX}px"

    mouseenter: (e) ->
      return if e.target.nodeName != 'path' and e.target.nodeName != 'rect'

      drag = document.querySelector('#drag-please-drag')

      # if drag and e.target.nodeName == 'path'
      #   drag.parentNode.removeChild(drag)


      if !drag

        drag = document.createElement 'div'
        drag.setAttribute 'id', 'drag-please-drag'
        drag.setAttribute 'draggable', 'true'
        drag.style.width = "10px"
        drag.style.height = "10px"
        drag.style.background = "none"
        drag.style.transform = 'translate(-5px, -5px)'
        drag.style.position = "absolute"
        drag.style.cursor = "pointer"
        # console.log @genListeners
        @provideCanvas.$el.appendChild drag
        for k,v of @genListeners
          for event in v
            drag.addEventListener k, event
        # console.log @genListeners
    mouseleave: (e) ->
      # console.log 'mouseleave!!!!!!!s', e.toElement
      # return if e.target.nodeName != 'path'

      drag = document.querySelector('#drag-please-drag')
      rtIsDrag = e.relatedTarget?.id == 'drag-please-drag'
      rtlIsThisPaint = e.relatedTarget == @$el
      # console.log @$el
      if drag and !rtIsDrag and e.relatedTarget != @$el
        drag.parentNode.removeChild(drag)
      # if drag and e.target.nodeName == 'path'
    resizeChekIResizeZone: ->
      return false
  watch:
    isSelected:(val) ->
      if val
        unless this in @provideScenePaints.selectedPaints
          @provideScenePaints.selectedPaints.push(this)
      else
        index = @provideScenePaints.selectedPaints.findIndex (p) => p == this
        @provideScenePaints.selectedPaints.splice(index, 1)
