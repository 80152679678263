import { io } from 'socket.io-client'
import store from '@/store'

if IS_PRODUCTION and !IS_STAGE
  URL = "https://onboard.app.weje.io"
else
  URL = "https://onboard.stage.weje.io"
# if IS_DEVELOPMENT
# URL = "http://localhost:3000"
# else
#   URL = "http://138.68.93.194:8080"
socketUsersOnBoards = io URL, { autoConnect: false, transports: ["websocket"]}

# Прослушка всех евентов
socketUsersOnBoards.onAny (event, ...args) =>
  # console.log(socketUsersOnBoards)

socketUsersOnBoards.on 'boardData', (data) =>
  # console.log 'boardData', data
  store.dispatch 'socketBoards/boardData', data

socketUsersOnBoards.on 'connect', (data) =>
  store.dispatch 'socketBoards/reconnect'

export { socketUsersOnBoards }
