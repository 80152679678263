import { firebind } from '@/utils'

hasWebjetForCopy = (items = {}) ->
  result = false
  for k,v of webjet?.connections?.items
    childWebjet = firebind v.src
    result = true if childWebjet.category == '/webjets/content/webjets/note'
    break if result
    if childWebjet?.connections?.items
      hasWebjetForCopy childWebjet?.connections?.items
  result

export hasDataForCopy = (webjetsPaths = []) ->
  result = false
  for path in webjetsPaths
    webjet = firebind path
    result = true if webjet.category == '/webjets/content/webjets/note'
    break if result
    if webjet?.connections?.items
      result = hasWebjetForCopy webjet?.connections?.items
  result


# getDataByCategory = (webjet)
setData = (wData, result = null) ->
  result = {} unless result
  for k,v of wData
    switch k
      when 'text'
        if result[k]
          result[k] += "\n"
          result[k] += v
        else
          result[k] = v
      when 'html'
        if result[k]
          result[k] += v
        else
          result[k] = v
  result

getNoteData = (webjet) ->
  return {} unless webjet
  html = ''
  if webjet?.data?.title
    html += "<h1>#{webjet?.data?.title}</h1>\n"
  if webjet?.data?.contentHtml
    html += webjet?.data?.contentHtml
  return {} unless html
  result = {}
  htmlString = html
  htmlString = htmlString.replace(/<li>(.*?)<\/li>/g, '$1\n')
  htmlString = htmlString.replace(/<p>(.*?)<\/p>/g, '$1\n')
  htmlString = htmlString.replace(/<h1>(.*?)<\/h1>/g, '$1\n')
  htmlString = htmlString.replace(/<h2>(.*?)<\/h2>/g, '$1\n')
  htmlString = htmlString.replace(/<h3>(.*?)<\/h4>/g, '$1\n')
  htmlString = htmlString.replace(/<h4>(.*?)<\/h4>/g, '$1\n')
  htmlString = htmlString.replace(/<h5>(.*?)<\/h5>/g, '$1\n')
  htmlString = htmlString.replace(/<h6>(.*?)<\/h6>/g, '$1\n')
  text = htmlString.replace(/<[^>]+>/g, "")
  result.html = html
  result.text = text
  result

getPhotoData = (webjet) ->
  return {} unless webjet
  result = {}
  title = if webjet?.data?.title then webjet?.data?.title else webjet?.data?.titleOrigin
  result.text = "[#{title}]\n #{webjet?.data?.url}\n"
  result.html = "<p><img src='#{webjet?.data?.url}' title='#{title}'></p>"
  result

getWebjetDataForCopy = (path = '') ->
  return null unless path
  result = {}
  webjet = firebind path
  switch webjet.category
    when '/webjets/content/webjets/note'
      wData = getNoteData webjet
      result = setData wData, result
    when '/webjets/content/webjets/photo'
      wData = getPhotoData webjet
      result = setData wData, result
  if webjet?.connections?.items
    items = []
    for k,v of webjet?.connections?.items
      items.push v
    sorted = items.sort (a, b) ->
      parseFloat(a.sortKey) - parseFloat(b.sortKey)
    for i in sorted
      wChildData = getWebjetDataForCopy i.src
      result = setData wChildData, result
  result


sortWebjetsForCopy = (webjetsPaths = []) ->
  result = {}
  unsorted = {}
  for w in webjetsPaths
    webjet = firebind w
    if webjet?.connections?.parents
      parentSrc = Object.values(webjet?.connections?.parents)[0]?.src
      unless unsorted[parentSrc]
        unsorted[parentSrc] = []
      unsorted[parentSrc].push w

  for k,v of unsorted
    tmp = []
    if k.indexOf '/boards/' < 0
      parent = firebind k
      connections = parent?.connections?.items
      for path in v
        for c,val of connections
          if val.src.indexOf(path) >= 0
            tmp.push {path: path, sortKey: val.sortKey}
            continue
      if tmp.length > 0

        result[k] = tmp.sort (a, b)->
          parseFloat(a.sortKey) - parseFloat(b.sortKey)
    else
      result[k] = v

  result

export getDataForCopyFromWebjets = (webjetsPaths = []) ->
  l = webjetsPaths.length
  return {} unless l
  result = {}
  sortsWebjets = sortWebjetsForCopy webjetsPaths
  for k,v of sortsWebjets
    for w in v
      data = getWebjetDataForCopy w.path
      result = setData data, result
  result
