








import ContextBtn from './ContextBtn'
export default
  name: 'Duplicate'
  components: { ContextBtn }
  inject: { provideCanvas: { default: null } }

  if: (selection)->
    return false unless selection[0].provideScene
    return false if selection.length == 1 and selection[0].webjet.category == 'anchor'
    selection.every (w)-> w.isCopyable

  computed:
    selection: -> @$store.getters['webjet/selected']
    centerX: ->
      @provideCanvas.sceneX + @provideCanvas.sceneWidth / 2
    centerY: ->
      @provideCanvas.sceneY + @provideCanvas.sceneHeight / 2

  methods:
    action: ->
      items = []
      for item, i in @selection
        items.push
          path: item.src
          data:
            coordinates: @provideCanvas.getEmptyCoordinates
              #x: @centerX - 150 + i * 20
              #y: @centerY - 50 + i * 20
              x: item.elementSceneRect.x1 + 40
              y: item.elementSceneRect.y1 + 40
      result = await @$store.dispatch 'webjet/copy',
        items: items
        dest: @$store.getters.currentBoardSrc
        boardPath: @provideCanvas.connection.src
      @provideCanvas.selectCreatedWebjets result, false, false
