








import ContextBtn from './ContextBtn'
export default
  name: 'AnchorCreate'
  components: { ContextBtn }
  inject: { provideCanvas: { default: null } }

  if: (selection)->
    canShow = selection.every (w) =>
      return true if w.webjet.category != 'anchor' and w.provideScene
    selection.length > 0 and canShow

  computed:
    selection: -> @$store.getters['webjet/selected']

  methods:
    action: ->
      x1 = null
      x2 = null
      y1 = null
      y2 = null
      for w in @selection
        rect = w.elementSceneRect

        x1 = rect.x1 if rect.x1 < x1 or x1 == null
        x2 = rect.x2 if rect.x2 > x2 or x2 == null
        y1 = rect.y1 if rect.y1 < y1 or y1 == null
        y2 = rect.y2 if rect.y2 > y2 or y2 == null

      type = "iframe"


      title = "Frame "
      # if @provideCanvas.anchors.length > 0
        # title += @provideCanvas.anchors.length + 1

      items = @provideCanvas.connections?.items
      if items
        items = Object.values items
        items = items.filter (c)=>
          w = @firebind(c.src)
          category = w.category
          category == 'anchor'
        title += items.length + 1
      data = {
          title: title
          type: type
        }
      connection = {
        coordinates:
          x: x1 - 20
          y: y1 - 20
      }
      size = {
          size:
            width: x2 - x1 + 40
            height: y2 - y1 + 40
        }
      connection = Object.assign connection, size
      result = await @$store.dispatch 'webjet/create',
        category: 'anchor'
        dest: @provideCanvas.data.$path
        boardPath: @provideCanvas.data.$path
        webjet:
          data:
            title: title
            type: type
        connection: connection
      @provideCanvas.selectCreatedWebjets [ result ]
