













































export default
  name: 'Freshbar'
  inject: { provideCanvas: { default: false } }
  computed:
    freshCount: ->
      @provideCanvas.freshCount
  methods:
    next: ()->
      webjetId = @provideCanvas.freshQueue[0]
      @provideCanvas.goToWebjet "#{@provideCanvas.src}/#{webjetId}"
      @$store.dispatch "fresh/unfreshWebjet", { boardId: @provideCanvas.src, webjetId }

    unfreshAll: ()->
      @$store.dispatch "fresh/unfreshBoard", { boardId: @provideCanvas.src }
