























import TeamCollaborations from '@/views/teamAccess/TeamCollaborations'
export default
  name: 'TeamAccess'
  data: ->
    teamTabs: null
  props:
    teamId: { default: false }
  context:
    teamId: -> @teamId
  components:
    {
      TeamCollaborations
    }
  computed:
    roles: ->
      # console.log @firebind "/teamAccess/#{@context.teamId}/roles"
      @firebind "/teamAccess/#{@teamId}/roles"
    boardsUsers: ->
      # console.log @firebind "/teamAccess/#{@context.teamId}/boards"
      @firebind "/teamAccess/#{@teamId}/boards"
    memberInTeam: ->
      result = []
      for k,v of @roles
        result.push k
      return result
    guests: ->
      result = []
      for k,v of @boardsUsers
        if k != '*' and !@roles?[k]
          result.push k
      return result
