import store from '@/store'
connected = false

updateWorker = ->
  console.log('update service worker attempt')
  reg = await navigator.serviceWorker.ready
  reg.update()

store.watch (-> store.state.connected), (value)  =>
  if (!connected and value)
    updateWorker()
  connected = value

setInterval updateWorker, 10800000 # every 3 hours