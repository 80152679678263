


















import BoardHead from './board/BoardHead'
import Canvas from '@/canvas/Canvas'
import ErrorOpen from '@/components/ErrorOpen.vue'

export default
  name: 'Board'
  props:
    boardId: String
  components: { BoardHead, Canvas, ErrorOpen }
  inject: ['provideApp']
  computed:
    currentBoardSrc: -> @$store.getters['currentBoardSrc']
    currentBoardConnectionSrc: -> @$store.getters['currentBoardConnectionSrc']
    boardsReady: -> @$store.getters['boardsReady']
    boardAccess: ->
      @$store.getters.boardAccess @boardId
    loading: ->
      @boardAccess.loading
    boardNotFound: ->
      !@boardAccess.exist and !@boardAccess.loading
    boardDeleted: ->
      @boardAccess.deleted
    title: ->
      @board?.data?.title
    accessDenied: ->
      !@boardAccess.access and @boardAccess.exist and !@boardAccess.loading
    smallScreen: ->
      @$vuetify.breakpoint.width < 500
    techWork: ->
      @boardAccess.techWork

    plan: ->
      return 'legacy' if @nolimits
      plan = @firebind "/teamBilling/#{@teamId}/plan"
      if plan.$value == null
        return 'free'
      else
        plan.$value
    teamId: ->
      teamId = @firebind "/boards/#{@boardId}/team"
      teamId.$value
    nolimits: ->
      val = @firebind("/teamBilling/#{@teamId}/nolimits")
      val.$value
    boardLimit: ->
      #return 3 if @plan == 'free'
      false
    boardCount: ->
      @$store.getters.allTeamBoards(@teamId).length

  methods:
    restoreBoard: ->
      # console.log '!restoreBoard', @teamId
      if @boardLimit and @boardCount >= @boardLimit
        @provideApp.dialogMaxLimitTeamId = @teamId
        @provideApp.dialogMaxLimitTeamBoards = true
        return
      @$store.dispatch 'webjet/restoreMyBoard', { path: @currentBoardSrc }
  firebind:
    board: -> @currentBoardSrc
  mounted: ->
      @$store.dispatch 'socketBoards/joinBoard' , @boardId
  beforeDestroy: ->
      @$store.dispatch 'socketBoards/leaveBoard' , @boardId
