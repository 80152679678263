import { operationActions } from '@/store/operation'
import getters from './webjet-getters'
import mutations from './webjet-mutations'
import operations from './webjet-operations'

import selection from './selection'
import relation from './relation'
import paint from './paint'

export default
  namespaced: true
  getters: getters
  mutations: mutations
  actions: operationActions operations
  modules: { selection, relation, paint }
