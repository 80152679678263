

































































import ContextBtn from './ContextBtn'
export default
  name: 'Shape'
  components: { ContextBtn }
  priority: 10000
  if: (selection)->
    selection.length == 1 and selection[0].$options.name == 'WebjetShape'

  computed:
    selection: -> @$store.getters['webjet/selected']
    shapeType: ->
      @selection[0].webjet.data.type
  methods:
    action: (type) ->
      path = @selection[0].src
      @$store.dispatch 'webjet/setShapeType', { path: path, type: type }
