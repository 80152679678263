
























import ModalImgCropper from '@/components/ImgCropper/ModalImgCropper'
import { getOwnerUID } from '@/utils'
export default
  name:
    "CreateTeamIcon"
  components:
    {
      ModalImgCropper
     }
  props:
    title: { default: false }
  data: ->
    openModalCropper: false
    imgFile: null
    teamImg: null
    availiableThemes: ['theme-board-default', 'theme-board-gray', 'theme-board-red', 'theme-board-yellow', 'theme-board-green', 'theme-board-purple', 'theme-board-blue', 'theme-board-lightblue']
    dragOverImg: false
  computed:
    initials: ->
      return '' unless @title
      result = ''
      words = @title.replace(/[^\p{L}\p{Z}^$\n]/gu, '').split(' ')
      result += words[0][0] if words[0]
      result += words[1][0] if words[1]
      result.toUpperCase()
  methods:
    drop: (e) ->
      e.preventDefault()
      e.stopPropagation()
      @dragOverImg = false
      file = e.dataTransfer?.files?[0]
      imageType = /image.*/
      isImg = file?.type.match imageType
      if file and isImg
        @imgFile = file
        @openModalCrop()
    dragover: (e) ->
      @dragOverImg = true
      e.preventDefault()
    dragleave: (e) ->
      @dragOverImg = false
    openModalCrop: ->
      @openModalCropper = true
    openUpload: ->
      input = @$el.querySelector 'input.boardimg-upload'
      if input
        input.click()
    processCrop: ->
      input = @$el.querySelector 'input.boardimg-upload'
      if input?.files?.length > 0
        @imgFile = input.files[0]
        @openModalCrop()
    uploadCroppedImg: (img) ->
      if (FileReader and img)
        self = this
        fr = new FileReader()
        fr.onload = () ->
          self.imgFile = img
          self.teamImg = fr.result
        fr.readAsDataURL(img)
      else
        @imgFile = null
        @teamImg = null
      # @$store.dispatch 'webjet/updateBoardImg',
      #   path: @connection?.src #@board?.$path
      #   file: img
    deleteCover: ->
      @imgFile = null
      @teamImg = null

  watch:
    openModalCropper: (val)->
      input = @$el.querySelector 'input.boardimg-upload'
      input.value= null
