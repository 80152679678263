positionable =
  data: ->
    positionableDelay: false
    resizeWidth: false
    resizeHeight: false
    resizeX: false
    resizeY: false
    positionableSceneRects: {}
    cachedIsTheoreticallyVisible: true

  created: ->
    return if @provideWebjet
    return if FLAG.CANVAS_WEBJET_INIT
    if @connection and @webjet and !@isTheoreticallyVisible and !@webjet.connections?.relations
      @positionableDelay = true
      setTimeout (=> @positionableDelay = false), 1

  classes: ->
    return false if @provideWebjet
    return false unless @isPositionable
    return
      root: true

  styles: ->
    return false unless @connection
    return false if @provideWebjet
    return false if !@provideScene and @provideBoardSrc
    return false if @isInTrash

    if @dragInProgress == 'move'
      styles =
        left: "#{@x}px"
        top: "#{@y}px"
    else
      styles =
        left: "#{Math.round(@x / 20) * 20}px"
        top: "#{Math.round(@y / 20) * 20}px"

    if @connection?.size?.width and @connection?.size?.width >= @baseWidth
      styles.width = "#{Math.round(@connection.size.width / 20) * 20}px"
    if @resizeWidth
      styles.width = "#{Math.round(@resizeWidth / 20) * 20}px"
      styles.transition = 'none'

    if @connection?.size?.height
        styles.height = "#{Math.round(@connection.size.height / 20) * 20}px"
    if @resizeHeight
      styles.height = "#{Math.round(@resizeHeight / 20) * 20}px"
      styles.transition = 'none'

    if @resizeX != false
      styles.left = "#{Math.round(@resizeX / 20) * 20}px"
    if @resizeY != false
      styles.top = "#{Math.round(@resizeY / 20) * 20}px"

    # return width and height if webjet in drag
    styles = {width: styles.width, height: styles.height} if !@provideScene and FLAG.DIE_GHOST_DIE
    return styles

  if: ->
    !@positionableDelay

  show: ->
    return true unless FLAG.CANVAS_VISIBILITY_OPTIMIZATION
    return true if @isInTrash
    @cachedIsTheoreticallyVisible



  watch:
    isTheoreticallyVisible:
      handler: (val)->
        @cachedIsTheoreticallyVisible = val
      immediate: true

  computed:
    hasPositionable: -> @context.positionable
    isPositionable: -> @hasPositionable
    isResizable: -> true

    parentIsShow: ->
      return true unless @provideWebjet
      @provideWebjet.genShow and @provideWebjet.parentIsShow

    elementsRects: -> #ttf 'elementsRects', =>
      return @provideWebjet.elementsRects if @provideWebjet
      @outerHTML # dependency
      unless @$el
        console.warn 'elementsRects: $el not ready'
        return @positionableSceneRects
      if @$el.nodeType == 8
        console.warn 'elementsRects: comment node'
        return @positionableSceneRects
      if @$el.outerHTML != @outerHTML
        @outerHTML = @$el.outerHTML # set actual outerHTML for optimization
      clone = @$el.cloneNode true
      clone.style.visibility = 'hidden'
      clone.style.display = 'block'
      # maybe connect nodes here? And without querySelector
      @provideCanvas.$refs.scene.$el.appendChild clone
      @positionableParseRects(clone, @positionableSceneRects)
      clone.remove()
      @positionableSceneRects

    elementRect: ->
      return @elementSceneRect unless @provideWebjet
      return
        x1: @elementSceneRect.x1 - @provideWebjet.elementSceneRect.x1
        x2: @elementSceneRect.x2 - @provideWebjet.elementSceneRect.x1
        y1: @elementSceneRect.y1 - @provideWebjet.elementSceneRect.y1
        y2: @elementSceneRect.y2 - @provideWebjet.elementSceneRect.y1

    elementSceneRect: ->
      @elementsRects[@id]


    baseWidth: ->
      return 160 if @webjet.category == 'btnLink'
      return 80 if @webjet.category == 'anchor'
      return 320
    baseHeight: ->
      return 80 if @webjet.category == 'anchor'
      return 320 if @webjet.category == '/webjets/content/webjets/embed'
      return 40

    isTheoreticallyVisible: ->
      return true unless @connection
      return true if @provideWebjet
      return true unless @provideScene
      return true if @isInTrash
      canvas = @provideCanvas
      x = @x
      y = @y
      width = @baseWidth
      # TODO: sometimes we know height maximum?
      if @connection.size?.width and @connection.size.width > width
        width = @connection.size.width
      hide = false

      sectorStart = x // 1000
      sectorEnd = (x + width) // 1000 + 1
      sectorY = y // 1000
      anySectorFull = false
      allSectorsOutside = true
      for sx in [sectorStart...sectorEnd]
        sector = @provideCanvas.getSector sx, sectorY
        anySectorFull = true if sector.inside
        allSectorsOutside = false if sector.intersect
      if allSectorsOutside
        return false
      if anySectorFull
        return true

      # From now we depends on scene(slow)
      if @webjet and (x + width) < canvas.sceneX
        hide = true
      if x > (canvas.sceneX + canvas.sceneWidth)
        hide = true
      if y > (canvas.sceneY + canvas.sceneHeight)
        hide = true
      if !hide and @$el and (y < canvas.sceneY)
        # From now we depends on elementSceneRect(very slow)
        rect = @elementSceneRect
        if rect.y2 < canvas.sceneY
          hide = true
      !hide
  methods:
    positionableParseRects: (node, obj)->
      rect = @positionableCalculateRect(node)
      id = @id
      if !obj[id] or (obj[id].x1 != rect.x1 or obj[id].x2 != rect.x2 or obj[id].y1 != rect.y1 or obj[id].y2 != rect.y2)
        @$set obj, id, rect
      for w in @childWebjetComponents
        subnode = node.querySelector "##{w.src.split('/').pop()}"
        w.positionableParseRects(subnode, obj)
      # cleanup on destroy?
    positionableCalculateRect: (node)->
      unless node.offsetParent
        console.warn 'positionableCalculateRect: no offsetParent'
        return { x1: 0, x2: 0, y1: 0, y2: 0 }
      x1 = node.offsetLeft + node.offsetParent.clientLeft
      y1 = node.offsetTop + node.offsetParent.clientTop
      x2 = x1 + node.offsetWidth
      y2 = y1 + node.offsetHeight
      if node.classList.contains('root')
        return { x1, x2, y1, y2 }
      else
        parentRect = @positionableCalculateRect node.offsetParent
        return
          x1: x1 + parentRect.x1
          x2: x2 + parentRect.x1
          y1: y1 + parentRect.y1
          y2: y2 + parentRect.y1

export default positionable
export {positionable}
