





















import mixin from './mixin'
import { userDisplayName } from '@/utils'
import Avatar from '@/components/Avatar.vue'
import Childs from './Childs'
export default
  name: 'WejeMention'
  mixins: [ mixin ]
  components: { Childs, Avatar }
  props: ['data']
  computed:
    mentioned: ->
      @data.data?.mentioned
    referrer: ->
      @data.data?.referrer
    mentionedName: ->
      userDisplayName(@data.data?.mentioned.uid).split(' ')[0]
    referrerName: ->
      userDisplayName(@data.data?.referrer).split(' ')[0]
    active: ->
      unless @data.data.hasOwnProperty 'active'
        return true

      return @data?.data?.active
    isUserMentioned: ->
      return @mentioned.uid == @$store.state.uid
