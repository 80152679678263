import { firebind, getPath } from '@/utils'
import { userInfo } from '@/utils'
import { getOwnerUID } from '@/utils'
import store from '@/store'

cacheTeamBoardsReady = {}
cacheTeamBoardsSync = {}

export default
  currentBoardConnectionSrc: (state, { boards })->
    # deprecated
    if state.route.name == 'Board'
      state.route.params.boardId
    else
      false

  currentBoardSrc: (state, { currentBoardConnectionSrc })->
    # deprecated
    cbcs = currentBoardConnectionSrc
    if cbcs
      "/boards/#{currentBoardConnectionSrc}"
    else
      false

  currentBoardId: (state, { currentBoardSrc })->
    if currentBoardSrc
      currentBoardSrc.split('/').pop()
    else
      false

  currentTrashSrc: (_, { currentBoardSrc })->
    # deprecated
    if currentBoardSrc
      "#{currentBoardSrc}"
    else
      false

  boards: (state, getters)->
    # Deprecated
    teams = getters.teams
    boards = []
    for t in teams
      bs = getters.teamBoards(t)
      for b in bs
        boards.push b
    # return connection-like objects for compatibility
    boards.map (b)->
      { src: "/boards/#{b}" }

  allBoards: (state, getters)->
    teams = getters.teams
    boards = []
    for t in teams
      bs = getters.teamBoards(t)
      for b in bs
        boards.push b
    boards

  boardsReady: (state, getters)->
    result = getters.teams.every (t)-> getters.teamBoardsReady(t)
    result = false if state.publicBoardLoading
    result

  boardsSync: (state, getters)->
    result = getters.teams.every (t)-> getters.teamBoardsSync(t)
    result = false if state.publicBoardLoading
    result


  unreadBoards: ()->
    []

  #unreadBoards: (state, { boards })->
  #  FLAG.NEW_LOAD
  #  items = boards.filter (v)->
  #    v.unread
  #  items = items.filter (v)->
  #    deletedFlag = firebind "#{v.src}/boardDeleted"
  #    !deletedFlag.$value
  #  items.map (v)-> v.$path

  #inboxBoards: (state)->
  #  # FLAG.NEW_LOAD
  #  recBoards 'inbox/inbox'

  # Use for oppened board
  boardAccess: ({ uid })->
    return (id)->
      return false unless id
      result = {}
      result.loading = true
      team = firebind "/boards/#{id}/team"
      project = firebind "/boards/#{id}/project"
      if team?.$ready and project?.$ready
        if !team.$error and !project.$error and team.$value and project.$value
          result.exist = true
          teamId = team.$value
          projectId = project.$value

          role = firebind "/teamAccess/#{teamId}/roles/#{uid}"
          projectAccess = firebind "/teamAccess/#{teamId}/projects/#{uid}/#{projectId}"
          boardAccess = firebind "/teamAccess/#{teamId}/boards/#{uid}/#{id}"
          publicAccess = firebind "/teamAccess/#{teamId}/boards/*/#{id}"
          openProject = firebind "/teamAccess/#{teamId}/openProjects/#{projectId}"
          boardDeleted = firebind "/boards/#{id}/boardDeleted"
          techWork = firebind "/boards/#{id}/migratingBoard"
          if [role, projectAccess, boardAccess, publicAccess, openProject, boardDeleted, techWork].every (p)-> p.$ready
            result.role = role.$value
            result.project = projectAccess.$value
            result.board = boardAccess.$value
            result.public = publicAccess.$value
            result.openProject = openProject.$value
            result.deleted = boardDeleted.$value
            result.techWork = techWork.$value
            access = undefined
            access = 'view' if result.role and result.openProject
            access = 'view' if result.role and result.project
            access = 'view' if result.board
            access = 'view' if result.public
            access = 'edit' if result.board == 'edit'
            access = 'edit' if result.role and result.project == 'edit'
            access = 'edit' if result.role and result.openProject == 'edit'
            access = undefined if result.deleted
            access = 'manage' if result.role == 'admin' or result.role == 'owner'
            access = 'manage' if result.role and result.project == 'manage'
            access = 'manage' if result.board == 'manage'
            result.access = access
            result.loading = false
            if !result.access or result.techWork or (result.deleted  and access != 'manage')
              result.denied = true
        else
          result.loading = false
          result.denied = true

      if !result.denied and team?.$sync and project?.$sync
        setTimeout =>
          store.dispatch 'firebindTryPermissionDenied', "/boards/#{id}"
          store.dispatch 'firebindTryPermissionDenied', "/boardsData/#{id}"
        , 1000
      result


  boardCollaborators: ()->
    return (id)->
      result = {}
      team = firebind "/boards/#{id}/team"
      project = firebind "/boards/#{id}/project"
      if team.$ready and project.$ready
        access = firebind "/teamAccess/#{team.$value}"
        if access.$ready
          if access.roles
            for k, v of access.roles
              if v == 'owner' or v == 'admin' or access.openProjects?[project.$value]
                result[k] = {} unless result[k]
                result[k].role = v
                result[k].project = access.openProjects[project.$value] if access?.openProjects?[project.$value]
                result[k].project = access.projects[k][project.$value] if access?.projects?[k]?[project.$value]
          if access.boards
            for k, v of access.boards
              if v[id]
                result[k] = {} unless result[k]
                result[k].board = access.boards[k][id] if access?.boards?[k]?[id]
          for k, v of result
            v.access = 'view'
            v.access = 'edit' if v.project == 'edit' or v.board == 'edit'
            v.access = 'edit' if access.openProjects?[project.$value] == 'edit'
            v.access = 'manage' if v.project == 'manage' or v.board == 'manage'
            v.access = 'manage' if v.role == 'owner' or v.role == 'admin'
      result


  collaborators: ({ uid }, getters)->
    users = {}
    boards = getters.allBoards
    for b in boards
      shared = getters.boardCollaborators b
      if shared[uid]
        for k, v of shared
          if k!='*'
            users[k] = 0 unless users[k]
            users[k] += 1
    result = []
    for u, k of users
      info = userInfo(u)
      info['count'] = k
      result.push info
    result

  teams: (state, getters)->
    result = []
    teams = firebind "teams"
    for k, v of teams
      if v.status == 'ok' or v.status == 'pending' # temp pending
        result.push k
    result.sort (a, b)=>
      teams[a].sortKey - teams[b].sortKey
    result


  teamBoardsReady: (state, getters)->
    return (id)->
      if cacheTeamBoardsReady[id]
        true
      else
        ready = getters.teamBoards(id).$ready
        cacheTeamBoardsReady[id] = true if ready
        ready

  teamBoardsSync: (state, getters)->
    return (id)->
      if cacheTeamBoardsSync[id]
        true
      else
        sync = getters.teamBoards(id).$sync
        cacheTeamBoardsSync[id] = true if sync
        sync

  teamBoards: (state, getters)->
    return (id, trash=false)->
      result = []
      fbs = []
      # load teamAccess roles
      projects = []
      boards = []
      role = firebind "/teamAccess/#{id}/roles/#{state.uid}"
      fbs.push role
      if role.$ready
        if role.$value == 'owner' or role.$value == 'admin'
          # load all projects
          teamProjects = firebind "/teams/#{id}/projects"
          fbs.push teamProjects
          for k, v of teamProjects
            projects.push k
        else if role.$value # manager member
          # load user projects and open projects
          userProjects = firebind "/teamAccess/#{id}/projects/#{state.uid}"
          openProjects = firebind "/teamAccess/#{id}/openProjects"
          fbs.push userProjects
          fbs.push openProjects
          for k, v of userProjects
            projects.push k
          for k, v of openProjects
            projects.push(k) unless projects.includes k
        else
          # guest. No projects

        # projects to boards
        for p in projects
          projectBoards = firebind "/projects/#{p}/boards"
          fbs.push projectBoards
          for k, v of projectBoards
            boards.push k

        # load personal shares
        personalShare = firebind "/teamAccess/#{id}/boards/#{state.uid}"
        fbs.push personalShare
        for k, v of personalShare
          boards.push k unless boards.includes k

        # load public boards
        publicBoards = firebind "/teamAccess/#{id}/boards/*"
        fbs.push publicBoards
        for k, v of publicBoards
          boards.push k unless boards.includes k

        # filter deleted
        boards = boards.filter (b)=>
          flag = firebind "/boards/#{b}/boardDeleted"
          fbs.push flag
          if trash
            flag.$value == true
          else
            flag.$ready and !flag.$value


      boards.$ready = fbs.every (f)-> f.$ready
      boards.$sync = fbs.every (f)-> f.$sync
      result = boards
      result

  teamBoardsSorted: (state, getters)->
    return (id, trash=false)->
      fbs = []
      boards = getters.teamBoards(id, trash)
      userPins = firebind "teams/#{id}/pins"
      boards.sort (a, b)=>
        return -1 if userPins[a] and !userPins[b]
        return 1 if !userPins[a] and userPins[b]
        if userPins[a] and userPins[b]
          return userPins[a] - userPins[b]
        adate = firebind "/boards/#{a}/meta/updateDate"
        bdate = firebind "/boards/#{b}/meta/updateDate"
        fbs.push adate
        fbs.push bdate
        if adate.$ready and bdate.$ready
          new Date(bdate.$value).getTime() - new Date(adate.$value).getTime()
        else
          0
      boards

  allTeamBoards: (state, getters)->
    return (id, trash=false)->
      result = []
      fbs = []
      ready = true
      sync = true
      projects = []
      boards = []

      # load all projects
      teamProjects = firebind "/teams/#{id}/projects"
      fbs.push teamProjects
      for k, v of teamProjects
        projects.push k

      # projects to boards
      for p in projects
        projectBoards = firebind "/projects/#{p}/boards"
        fbs.push projectBoards
        for k, v of projectBoards
          boards.push k

      # filter deleted
      boards = boards.filter (b)=>
        flag = firebind "/boards/#{b}/boardDeleted"
        fbs.push flag
        if trash
          flag.$value == true
        else
          flag.$ready and !flag.$value

      boards.$ready = fbs.every (f)-> f.$ready
      result = boards
      result

  currentTeamId: ({ route, uid })->
    # FLAG.NEW_ROUTING
    if route.name == 'Team' # !!
      route.params.teamId
    else if route.name == 'Board'
      boardTeam = firebind("/boards/#{route.params.boardId}/team")
      if boardTeam.$ready
        boardTeam.$value
      else
        false
    else if route.path == '/'
      "team_#{uid}"
    else
      false

  currentTeamBoards: ({}, { currentTeamId, teamBoards })->
    teamBoards currentTeamId



  availableProjects: (state, getters)->
    return (id)->
      projects = {}
      role = firebind "/teamAccess/#{id}/roles/#{state.uid}"
      if role.$ready
        if role.$value == 'owner' or role.$value == 'admin'
          teamProjects = firebind "/teams/#{id}/projects"
          for k, v of teamProjects
            projects[k] = 'manage'
        else if role.$value # manager member
          userProjects = firebind "/teamAccess/#{id}/projects/#{state.uid}"
          openProjects = firebind "/teamAccess/#{id}/openProjects"
          for k, v of openProjects
            projects[k] = v
          for k, v of userProjects
            if !projects[k] or v == 'edit' or v == 'manage'
              projects[k] = v
        else
          # guest. No projects
      projects

  availableViewProjects: (state, getters)->
    return (id)->
      result = []
      projects = getters.availableProjects id
      for k, v of projects
        result.push k
      result

  availableEditProjects: (state, getters)->
    return (id)->
      result = []
      projects = getters.availableProjects id
      for k, v of projects
        result.push(k) if v in ['edit', 'manage']
      result

  availableManageProjects: (state, getters)->
    return (id)->
      result = []
      projects = getters.availableProjects id
      for k, v of projects
        result.push(k) if v == 'manage'
      result

  teamTitle: ()->
    return (id)->
      return unless id
      dataTitle = firebind "/teams/#{id}/title"
      if dataTitle?.$value
        dataTitle.$value
      else if id.startsWith 'team_'
        uid = id.split('_')[1]
        firstUserName = firebind '/users/'+uid+'/firstName'
        lastUserName = firebind '/users/'+uid+'/lastName'
        email = firebind '/users/'+uid+'/email'
        "#{firstUserName.$value || lastUserName.$value || email.$value?.split('@')[0] }'s team"
      else
        "Untitled team"
