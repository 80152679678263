import { firebind } from '@/utils'
import store from '@/store'

hasPublicGizmo = (src)->
  hasWejeSites(src)

hasDateGizmo = (src)->
  webjet = firebind src
  return unless webjet?.category == 'date'
  webjet?.data?.completed != true

hasMentionGizmo = (src)->
  webjet = firebind src
  return unless webjet?.category == 'mention'
  webjet?.data?.active and webjet?.data?.mentioned?.uid == store.state.uid

hasCommentGizmo = (src)->
  webjet = firebind src
  return unless webjet?.category == 'comments'
  return unless webjet?.data?.comments
  hasUserComment = false
  for k, v of webjet.data.comments
    hasUserComment = true if store.state.uid == v.user
  hasUserComment

hasChecklistGizmo = (src)->
  webjet = firebind src
  return unless webjet?.category == 'checklist'
  return unless webjet?.data?.items
  hasUndone = false
  for k, v of webjet.data.items
    hasUndone = true if v.status == 0
  hasUndone

wejeSites = (src)->
  webjet = firebind src
  result = []
  return result unless webjet.connections?.parents
  for k, c of webjet.connections.parents
    if c.weak
      w = firebind c.src
      if w?.category == 'weje/page'
        unless w.connections?.parents
          console.warn 'weje/page without parents'
          continue
        if Object.keys(w.connections.parents)>1
          console.warn 'weje/page with multiple parents'
          continue
        site = firebind Object.values(w.connections.parents)[0].src
        if site?.$ready
          isStrong = Object.values(site.connections.parents).some (sw)-> !sw.weak
          if isStrong
            result.push site.$path
  result


hasWejeSites = (src)->
  webjet = firebind src
  return false unless webjet?.connections?.parents
  # very fast check. Webjet without weak connections
  return false unless Object.values(webjet.connections.parents).some (c)-> c.weak
  # fast check. 'weje/page' in parents
  maybeHasSites = false
  for k, c of webjet.connections.parents
    if c.weak
      w = firebind c.src
      if w?.category == 'weje/page'
        maybeHasSites = true
  return false unless maybeHasSites
  # slow check. Get weje sites
  return wejeSites(src).length > 0



findContainers = (path)->
  connection = firebind path
  return [] unless connection.src
  webjet = firebind connection.src
  result = []
  result.push connection.src
  if webjet.category == '/webjets/containers/webjets/list'
    isOpen = connection.presentation != 'row'
    if isOpen and webjet?.connections?.items
      for k, v of webjet.connections.items
        containers = findContainers v.$path
        result = result.concat containers
  result


findAllContainers = (boardId)->
  items = firebind "/boards/#{boardId}/connections/items"
  result = []
  if items
    for k, v of items
      containers = findContainers v.$path
      result = result.concat containers
  result

findGizmosForWebjet = (src)->
  result = []
  if hasDateGizmo src
    result.push
      type: 'date'
      src: src
  if hasMentionGizmo src
    result.push
      type: 'mention'
      src: src
  if hasCommentGizmo src
    result.push
      type: 'comment'
      src: src
  if hasChecklistGizmo src
    result.push
      type: 'checklist'
      src: src
  result

deepFindGizmosForWebjet = (src)->

findGizmosForContainer = (src)->
  webjet = firebind src
  # if list then find connection and check for collapsed
  result = []
  if hasPublicGizmo src
    result.push
      type: 'public'
      src: src
  gizmos = findGizmosForWebjet src
  result = result.concat gizmos

  if webjet.category == '/webjets/containers/webjets/list'
    # deep find
    # transform to mark with count
  else
    if webjet?.connections?.items
      for k, v of webjet.connections.items
        if v.src
          gizmos = findGizmosForWebjet v.src
          result = result.concat gizmos

  result

findAllContainersWithGizmos = (boardId)->
  # SLOW
  containers = findAllContainers boardId
  result = []
  for c in containers
    result.push c if findGizmosForContainer(c).length
  result

export { findAllContainers, findGizmosForContainer }
