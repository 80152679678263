



















































import ContextBtn from './ContextBtn'
export default
  name: 'Theme'
  components: { ContextBtn }
  inject: ['provideApp']
  priority: 100
  if: (selection)->
    selection.every (w)-> w.isThemeable and w.canTheme
  computed:
    selection: -> @$store.getters['webjet/selected']
    themeClass: ->
      firstTheme = @selection[0].themeableClass
      allSame = @selection.every (w)-> w.themeableClass == firstTheme
      if allSame
        if firstTheme
          { [firstTheme]: true }
        else
          { }
      else
        { 'theme-different': true }
    themeClassName: ->
      Object.keys(@themeClass)[0]
    showTransparentTheme: ->
      canTransparent = @selection.every (w) =>
        w.webjet?.category in [
          '/webjets/content/webjets/photo'
          '/webjets/content/webjets/note'
        ]
      canTransparent
    themeDark: ->
      @provideApp.themeDark
  methods:
    action: (theme)->
      items = @selection.map (w)-> { path: w.src }
      @$store.dispatch 'webjet/theme', { items, theme }
