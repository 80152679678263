






















































import { mapGetters } from 'vuex'
import { getOwnerUID } from '@/utils'
import BoardHeaderBack from '@/views/board/BoardHeaderBack'
import Control from './Control'
import DrawTool from './DrawTool'
import DrawOnCanvasTools from './DrawOnCanvasTools'
export default
  name: 'BoardHeaderMobile'
  inject: ['provideCanvas', 'provideApp']
  components: { BoardHeaderBack, Control, DrawTool, DrawOnCanvasTools}
  data: ->
    boardMenuTools: false
  computed: Object.assign {},
    mapGetters ['myBoards', 'sharedBoards', 'unreadBoards', 'starredBoards']
    mapGetters ['currentBoardSrc', 'currentBoardConnectionSrc', 'currentBoardId']
    title: ->
      @data?.title
    isAnonymousUser: ->
      @$store.state.isAnonymousUser
    userCanEdit: ->
      @provideCanvas.userCanEdit
    drawOnCanvasIsActive: ->
      @provideCanvas.$refs.scene.$refs.DrawOnCanvas.drawIsActive
    sidebarIsActive: ->
      @provideCanvas.$refs.sidebar.mode == 'full'
    isInventory: ->
      @sidebarIsActive and @provideCanvas.$refs.sidebar.activeTab == 'inventory'
    boardAccess: ->
      @$store.getters.boardAccess @currentBoardId
    isPublic: ->
      if FLAG.NEW_LOAD
        @boardAccess.public
      else
        for key, value of @shared
          if key == '*'
            return true
        return false
  firebind:
    data: -> @currentBoardSrc and "#{@currentBoardSrc}/data"
    shared: -> @currentBoardSrc and "#{@currentBoardSrc}/shared"
  methods:
    closeDraw: ->
      @provideCanvas.collaborateDrawMode = false
    sidebarClose: ->
      @provideCanvas.$refs.sidebar.close()
    sidebarOpenInventory: ->
      @provideCanvas.$refs.sidebar.open 'inventory'
    sidebarContentOpen: ->
      @provideCanvas.$refs.sidebarContent.openClose()
    openNavbar: ->
      @provideApp.navbarOpen()
