



































import { userUniqueShared } from '@/utils'
import { mapGetters } from 'vuex'
import { uPlan } from '@/plan'
export default
  name:
    "Settings"
  computed: Object.assign {},
    mapGetters ['myBoards']
    isRegisteredUser: ->
      @$store.state.isRegisteredUser
    isAnonymousUser: ->
      @$store.state.isAnonymousUser
    plan: ->
      return uPlan.getPlan()
    userData: ->
     return uPlan.getUserData()
    limitBoards: ->
      userPlanData = uPlan.getPlanData()
      return userPlanData?.limitBoards
    limitCollaborations: ->
      userPlanData = uPlan.getPlanData()
      return userPlanData?.limitCollaborations
    userCollaborationsSize: ->
      return @userData['collaborations']?.size
    usedOfCollaborations: ->
      return 100 / @limitCollaborations * @userCollaborationsSize
    userBoardsSize: ->
      return @userData.boardsCount
    usedOfBoards: ->
      return 100 / @limitBoards * @userBoardsSize
  methods:
    chekAddBoard: ->
      console.log uPlan.canAddBoard()
    chekAddCollaboration: ->
      console.log uPlan.canAddBoard()
