





import { findGizmosForContainer } from './helpers'
import GizmoPublic from './GizmoPublic'
import GizmoDate from './GizmoDate'
import GizmoMention from './GizmoMention'
import GizmoComment from './GizmoComment'
import GizmoChecklist from './GizmoChecklist'
export default
  name: 'Gizmo'
  props: ['webjet']
  computed:
    gizmos: ->
      findGizmosForContainer @webjet.webjet.$path
    show: ->
      @gizmos.length > 0
    attach: ->
      @webjet.genShow and !@webjet.dragInProgress
    gizmoComponents: ->
      @gizmos.map (g)->
        result = Object.assign {}, g
        result.component = GizmoPublic if g.type == 'public'
        result.component = GizmoDate if g.type == 'date'
        result.component = GizmoMention if g.type == 'mention'
        result.component = GizmoComment if g.type == 'comment'
        result.component = GizmoChecklist if g.type == 'checklist' and FLAG.GIZMO_CHECKLIST
        result
    styles: ->
      canvas = @webjet.provideCanvas
      rect = @webjet.elementSceneRect
      #scene = canvas.sceneNow
      scale = canvas.animate.scale
      #left = -scene.x1 * scale + rect.x2 * scale
      left = rect.x2 * scale
      #top = -scene.y1 * scale + rect.y1 * scale
      top = rect.y1 * scale
      result = {}
      return
        left: "#{left - 38}px"
        top: "#{top - 14}px"


