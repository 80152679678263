import { render, staticRenderFns } from "./ToolVoice.vue?vue&type=template&id=4fe6a37c&lang=pug&"
import script from "./ToolVoice.vue?vue&type=script&lang=coffee&"
export * from "./ToolVoice.vue?vue&type=script&lang=coffee&"
import style0 from "./ToolVoice.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports