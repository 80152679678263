import { getRef, getPath, getDataUserRef } from '@/utils'
import { firebind } from '@/utils'

export default

  create: (_, { dest, group='paints/items', data }, operation)->
    await operation.dispatch 'webjet/create',
      dest: dest
      group: group
      connection: data
      inline: true
