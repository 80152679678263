export default
  data: ->
    wasd: { w: false, a: false, s: false, d: false, timer: false, speed: 10 }
  created: ->
    @wasdKeydownHandler = (e)=> @wasdKeydown(e)
    document.addEventListener 'keydown', @wasdKeydownHandler
    @wasdKeyupHandler = (e)=> @wasdKeyup(e)
    document.addEventListener 'keyup', @wasdKeyupHandler

  destroyed: ->
    document.removeEventListener 'keydown', @wasdKeydownHandler
    document.removeEventListener 'keyup', @wasdKeyupHandler

  methods:
    wasdKeydown: (e)->
      key = 'a' if e.keyCode == 65
      key = 's' if e.keyCode == 83
      key = 'd' if e.keyCode == 68
      key = 'w' if e.keyCode == 87
      if !e.ctrlKey and !e.altKey and !e.shiftKey and !e.metaKey
        if 'wasd'.indexOf(key) >= 0
          @wasd[key] = true
          unless @wasd.timer
            @wasdGo()
      return
    wasdKeyup: (e)->
      key = 'a' if e.keyCode == 65
      key = 's' if e.keyCode == 83
      key = 'd' if e.keyCode == 68
      key = 'w' if e.keyCode == 87
      if 'wasd'.indexOf(key) >= 0
        @wasd[key] = false
      true

    wasdGo: ->
      @position.x += @wasd.speed if @wasd.a
      @position.x -= @wasd.speed if @wasd.d
      @position.y += @wasd.speed if @wasd.w
      @position.y -= @wasd.speed if @wasd.s

      if @wasd.a or @wasd.s or @wasd.w or @wasd.d
        @wasd.timer = setTimeout (=> @wasdGo()), 10
      else
        @wasd.speed = 10
        @wasd.timer = false
