import { firebind } from '@/utils'

export default
  lang: (state, getters, { uid })->
    if uid and firebind("/users/#{uid}/settings")?.lang
      firebind("/users/#{uid}/settings").lang
    else if sessionStorage['langWithoutUser']
      sessionStorage['langWithoutUser']
    else
      null

  grid: (state, getters, { uid })->
    grid = firebind("/users/#{uid}/settings").grid
    if grid
      grid
    else if grid == undefined
      'lines'
    else
      false

  gizmo: (state, getters, { uid })->
    gizmo = firebind("/users/#{uid}/settings").gizmo
    return gizmo if gizmo != undefined
    return true

  plan: (state, getters, { uid })->
    plan = firebind("/users/#{uid}").plan
    if plan
      plan
    else
      'free'
