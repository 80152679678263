









import mixin from './mixin'
import Childs from './Childs'
export default
  name: 'WejeEmbed'
  mixins: [ mixin ]
  components: { Childs }
  props: ['data']
  computed:
    url: ->
      @data.data?.url
