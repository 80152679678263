










































import { userLimits } from '@/utils'

export default
  name: "DialogMaxLimitTeamBoards"
  inject: ['provideApp']
  computed:
    plan: ->
      plan = @firebind "/teamBilling/#{@teamId}/plan"
      if plan.$value == null
        return 'free'
      else
        plan.$value
    teamId: ->
      @provideApp.dialogMaxLimitTeamId
    teamRols: ->
      @firebind "/teamAccess/#{@teamId}/roles"
    userRole: ->
      role = @teamRols?[@$store.state.uid]
      return null unless role
      role
  methods:
    upgradePlan: ->
      @provideApp.dialogMaxLimitTeamBoards = false
      @$router.push { name: 'TeamPlan', params: { teamId: @teamId } }

