



























































import { mdiChevronDown, mdiCheck, mdiClose, mdiContentCopy } from '@mdi/js'
import { userDisplayName } from '@/utils'
import TeamCollaborationsItem from './TeamCollaborationsItem'
import Avatar from '@/components/Avatar.vue'

export default
  name:
    "TeamCollaborations"
  props:
    tab: String
  inject: ['provideApp']
  components:
    {
      TeamCollaborationsItem
      Avatar
    }

  data: ->
    iconArrow: mdiChevronDown
    iconCheck: mdiCheck
    iconRemove: mdiClose
    iconCopy: mdiContentCopy
    mail: ''
    roleForHint: 'admin'
    activeSelectUser: false
    showSelectUsers: false
    uidForInvite: ''
    inviteLoading: false
    errorEmail: false
    errorEmailText: ''
    canSelect: 'edit'
    preselectedPublicLicense: null
    preselectedInviteLinkLicense: null
    snapbar: false
    snapbarText: ''
    sendInviteLinkPopup: false
    upgradePlanDialog: false
  computed:
    plan: ->
      plan = @firebind "/teamAccess/#{@context.teamId}/plan"
      if plan.$value == null
        return 'free'
      else
        plan.$value
    roles: ->
      @firebind "/teamAccess/#{@context.teamId}/roles"
    rolesWithoutOwner: ->
      result = {}
      for k,v of @roles
        result[k] = v if v != 'owner'
      result
    boardsUsers: ->
      # console.log @firebind "/teamAccess/#{@context.teamId}/boards"
      @firebind "/teamAccess/#{@context.teamId}/boards"
    guests: ->
      result = {}
      for k,v of @boardsUsers
        if k != '*' and !@roles?[k]
          result[k] = v
      # console.log 'guests', result
      return result
    guestWithEdit:->
      members = []
      for k,v of @boardsUsers
        if v and k != '*'
          for b,r of v
            if r == 'edit'
              members.push k
              break
      members
    membersInTeam: ->
      memebers = Object.keys @roles
      memebers
    allmembersInTeam: ->
      @membersInTeam.length + @guestWithEdit.length
    teamSize: ->
      teamSizeData = @firebind("/teamBilling/#{@context.teamId}/teamSize")
      teamSize = teamSizeData?.$value
      return 3 if teamSize == null
      teamSize
    teamNoLimits: ->
      val = @firebind("/teamBilling/#{@context.teamId}/nolimits")
      val.$value
    limitIsOver: ->
      #if !@teamNoLimits and @allmembersInTeam >= @teamSize
      #  return true
      false
    collaborators: ->
      result = []
      for k,v of @guests
        email = @firebind "/users/#{k}/email"
        fname = @firebind "/users/#{k}/firstName"
        lname = @firebind "/users/#{k}/lastName"
        result.push { uid: k, email: email.$value, fname: fname.$value, lname: lname.$value }
      result
    ownerId: ->
      for k,v of @roles
        return k if v == 'owner'
    # collaborators: ->
    #   collaborators = @$store.getters.boardCollaborators
    #   myUid = @$store.state.uid
    #   filteredShared = @filteredShared
    #   collaboratorsWithoutMe = collaborators.filter (c) ->
    #     return false if !c.email and !c.fname and !c.lname
    #     for key, value of filteredShared
    #       if key == c.uid
    #         return false
    #     return true if c.uid != myUid
    #   collaboratorsWithoutMe
    filteredCollaborators: ->
      roles = @roles
      collaborators = @collaborators
      text = @mail
      re = new RegExp text, 'i'
      filteredCollaborators = collaborators.filter (c) ->
        return false if roles.uid
        return true if c.email and re.test c.email
        return true if c.fname and re.test c.fname
        return true if c.lname and re.test c.lname

      if text.length < 1
        return collaborators.slice(0, 10)
      filteredCollaborators
    chekUidInvite: ->
      if @uidForInvite and
         @uidForInvite != @ownerId and
         @uidForInvite != @$store.state.uid and
         !Object.keys(@roles).includes @uidForInvite
        return true
      else
        return false
  #   console.log @roles
  #   console.log @firebind "/teamAccess/#{@context.teamId}/boards"
  #   console.log @context.teamId
  #   console.log @tab
  methods:
    chekEmailOnInput: ->
      @mail = @mail.toLowerCase()
      @showSelectUsers = true
      @activeSelectUser = false
      @sendInviteLinkPopup = false
      @errorEmail = false
      @errorEmailText = ''
      if @mail.match /^\S+@\S+[\.][0-9a-z ]+$/g
        uid = (await @firebind "/emails/#{@mail.replace(/\./g, ',')}/uid")?.$value
        if uid
          @uidForInvite = uid
        else
          @uidForInvite = ''
      else
        @uidForInvite = ''
    sendInviteLink: ->
      if !@hasInviteLink
        await @$store.dispatch 'webjet/createInviteLink',
          path: @connection?.src
          mode: @canSelect
      else if @canSelect != @board?.meta?.inviteLink?.mode
        await @$store.dispatch 'webjet/updateInviteLink',
          path: @connection?.src
          mode: @canSelect
      await @$store.dispatch 'webjet/sendInviteLink',
        path: @connection?.src
        email: @mail
      @sendInviteLinkPopup = false
      @errorEmail = false
      @snapbar = true
      @snapbarText = @$t('board.share.linkSent') + @mail
      @mail = ''

    selectUser: (e,email) ->
      @mail = email
      @showSelectUsers = false
      e.preventDefault()
      e.stopPropagation()
    clickMailInput: ->
      @showSelectUsers = true
      @activeSelectUser = false
    downArrow: ->
      if @activeSelectUser == false
        @activeSelectUser = 0
      else if @activeSelectUser < @filteredCollaborators.length - 1
        @activeSelectUser += 1
    upArrow: ->
      if @activeSelectUser != 0
        @activeSelectUser -= 1
    enterInMail: ->
      if @activeSelectUser != false
        @mail = @filteredCollaborators[@activeSelectUser].email
        @showSelectUsers = false
        @activeSelectUser = false
      else
        @showSelectUsers = false
        @invite()
    invite: ->

      return unless @mail
      if not @mail.match /^\S+@\S+[\.][0-9a-z ]+$/g
        @errorEmail = true
        return
      @uidForInvite = (await @firebind "/emails/#{@mail.replace(/\./g, ',')}/uid")?.$value unless @uidForInvite
      # console.log @uidForInvite, @chekUidInvite
      if @uidForInvite and @chekUidInvite
        if @limitIsOver
          unless @allmembersInTeam == @teamSize and @uidForInvite in @guestWithEdit
            @provideApp.dialogMaxLimitTeamId = @context.teamId
            @provideApp.dialogMaxLimitTeam = true
            return
        # if @canSelect in @editLicences and @boardEditorsLimit and @boardEditorsCount >= @boardEditorsLimit
        #   @upgradePlanDialog = true
        #   sendAnalytics 'editors_limit_popup'
        #   return
        @inviteLoading = true
        # console.log 'INVITE', @connection?.src,@uidForInvite,@canSelect
        # console.log @context.teamId, @uidForInvite, @roleForHint
        #roleForHint = if @plan == 'free' then 'member' else @roleForHint
        roleForHint = @roleForHint #if @teamNoLimits
        await @$store.dispatch 'team/updateTeamRole',
          teamId: @context.teamId
          userId: @uidForInvite
          role: roleForHint
        # sendAnalytics 'user_invited_to_board',
        #   invited_user_id : @uidForInvite.substring(0, 15)
        #   access_type: @canSelect
        @mail = ''
        @uidForInvite = ''
        @inviteLoading = false
      else if !@uidForInvite
        @sendInviteLinkPopup = true
        @errorEmail = true
        @errorEmailText = @$t('board.share.errorNotFound')
      else
        @errorEmail = true
        @errorEmailText = @$t('board.share.errorAlreadyHasAccess')
