

















import { Site } from '@/weje'
import wsSite from '@/websockets/ws-site'
import DialogTemplate from '@/components/DialogTemplate'
export default
  name: 'DialogSite'
  props: ['siteId']
  components: { Site, DialogTemplate }
  created: ->
    wsSite.siteId = @siteId
  computed:
    show:
      get: -> true
      set: -> @$router.push '#'
    wsSite: -> wsSite
    siteData: ->
      wsSite.site
