

















































































































































































































































import DialogTemplate from '@/components/DialogTemplate'
import { date } from  '@/utils'
export default
  name: 'TeamPlan'
  components: {DialogTemplate}
  props:
    teamId: { default: false }
  data: ->
    upgradePlan: false
    upgradePlanTabs: "dreamteam"
    upgradePlanError: false
    expandTeam: false
    payTeamSize: 4
    payMonthly: true
    prices:
      'dreamteam':
        true:
          flat: 10
          base: 3
          user: 7
        false:
          flat: 96
          base: 3
          user: 66
      'business':
        true:
          flat: 19
          base: 3
          user: 14
        false:
          flat: 180
          base: 3
          user: 132
    priceIds:
      'live':
        'dreamteam':
          true: 'price_1M04juDbibRhxhzYLSH9MfmH'
          false: 'price_1M5XXMDbibRhxhzYPGABORfd'
        'business':
          true: 'price_1M5Wo6DbibRhxhzY98Gtv7RQ'
          false: 'price_1M5Wo6DbibRhxhzY8iwXfNpr'
      'test':
        'dreamteam':
          true: 'price_1M06QkDbibRhxhzYaNct9BBa'
          false: 'price_1M5WlbDbibRhxhzY9NauFmc3'
        'business':
          true: 'price_1M5WizDbibRhxhzY2vrecdBO'
          false: 'price_1M5WizDbibRhxhzYvsU1dGZI'
    discounts:
      'live':
        true: 'rZlHO9DQ'
        false: 'AYwOMf7K'
      'test':
        true: 'JTPvdRhl'
        false: 'yjo4Vm2B'

  computed:
    plan: ->
      if @nolimits
        return 'legacy'
      @firebind("/teamBilling/#{@teamId}/plan").$value||'free'
    nolimits: ->
      val = @firebind("/teamBilling/#{@teamId}/nolimits")
      val.$value
    planPeriod: ->
      @firebind("/teamBilling/#{@teamId}/stripe_subscription_period").$value||'monthly'
    roles: ->
      @firebind "/teamAccess/#{@teamId}/roles"
    membersInTeam: ->
      memebers = Object.keys @roles
      memebers
    boardsUsers: ->
      @firebind "/teamAccess/#{@teamId}/boards"
    guestWithEdit:->
      members = []
      for k,v of @boardsUsers
        if v and k != '*' and !@roles?[k]
          for b,r of v
            if r == 'edit'
              members.push k
              break
      members
    allmembersInTeam: ->
      @membersInTeam.length + @guestWithEdit.length
    teamSize: ->
      teamSizeData = @firebind("/teamBilling/#{@teamId}/teamSize")
      teamSize = teamSizeData?.$value
      return 3 if teamSize == null
      teamSize
    payTeamPrice: ->
      if @expandTeam
        p = @prices[@plan][@planPeriod=='monthly']
      else
        p = @prices[@upgradePlanTabs][@payMonthly]
      p.flat + Math.max(@payTeamSize - p.base, 0)*p.user
    payTeamPriceWithDiscount: ->
      return @payTeamPrice unless FLAG.NAVBAR_BANNER
      d = if @payMonthly then 0.7 else 0.5
      p = @payTeamPrice*d
      if Number.isInteger(p)
        p
      else
        p.toFixed(2)
    currentTeamPrice: ->
      return 0 unless @prices[@plan]? #if @plan in ['free', 'legacy']
      p = @prices[@plan][@planPeriod=='monthly']
      p.flat + Math.max(@teamSize - p.base, 0)*p.user
    nextPaymentDate: ->
      nextDate = @firebind("/teamBilling/#{@teamId}/stripe_subscription_enddate")?.$value
      if nextDate
        date(nextDate*1000)
      else
        null
    nextPaymentTotal: ->
      (@firebind("/teamBilling/#{@teamId}/stripe_next_invoice/total")?.$value || 0) / 100
    nextPaymentCurrency: ->
      @firebind("/teamBilling/#{@teamId}/stripe_next_invoice/currency")?.$value?.toUpperCase() || ''

    showBanner: ->
      FLAG.NAVBAR_BANNER and @plan=='free'

  methods:
    decTeamSize: ->
      return if @payTeamSize <= Math.max(@allmembersInTeam, @teamSize, 3)
      @payTeamSize--
    incTeamSize: ->
      @payTeamSize++
    doUpgradePlan: ->
      #console.log '!upgradePlan', @teamId, @upgradePlanTabs, @payMonthly
      unless @teamId
        @upgradePlanError = true
        return

      @upgradePlanError = false

      if IS_PRODUCTION and !IS_STAGE
        url = 'https://stripe.app.weje.io/session'
        priceId = @priceIds['live'][@upgradePlanTabs][@payMonthly]
        discount = @discounts['live'][@payMonthly]
      else if FLAG.STRIPE_LOCAL
        url = 'http://localhost:8802/session'
        priceId = @priceIds['test'][@upgradePlanTabs][@payMonthly]
        discount = @discounts['test'][@payMonthly]
      else
        url = 'https://stripe.stage.weje.io/session'
        priceId = @priceIds['test'][@upgradePlanTabs][@payMonthly]
        discount = @discounts['test'][@payMonthly]

      data =
        plan: [
          { price: priceId, quantity: @payTeamSize }
        ]
        token: await @$store.state.auth().currentUser.getIdToken()
        team: @teamId
        subscription_data:
          metadata:
            team: @teamId
      if FLAG.NAVBAR_BANNER and discount
        data.discount = discount

      stripe = $stripe()

      fetch(url,
        method: 'POST'
        headers:
          'Content-Type': 'application/json'
        body: JSON.stringify data
      ).then( (response) =>
        #console.log 'got response:', response
        if response?.status == 200
          return await response.json()
        else
          throw new Error("Response status: #{response?.status}")
      ).then( (session) =>
        #console.log 'got session:', session
        return (await stripe).redirectToCheckout { sessionId: session.id }
      ).then( (result) =>
        # Here we gets only if redirectToCheckout fails because of something
        @upgradePlanError = true
        console.error 'redirectToCheckout failed'
        if result?.error?.message
          console.error result?.error?.message
        else
          console.error result
      ).catch (error) =>
        console.error error
        @upgradePlanError = true

    doExpandTeam: ->
      #console.log '!expandTeam', @teamId, @payTeamSize
      @expandTeam = false
      return if @payTeamSize <= Math.max(@allmembersInTeam, 3)
      @$store.dispatch 'team/expandTeam',
        teamId: @teamId
        size: @payTeamSize

    getBannerDaysLeft: ->
      end = new Date '12/31/2022'
      start = new Date()
      Math.ceil (end - start) / (1000 * 3600 * 24)

  mounted: ->
    if @$route.query?.dialup == 'open'
      #@upgradePlan = true
      #@upgradePlanError=false
      #@payTeamSize = Math.max(@allmembersInTeam, 3)
      @$router.replace path: @$router.path

