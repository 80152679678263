












import mixin from './mixin'
import Childs from './Childs'
export default
  name: 'WejePdf'
  mixins: [ mixin ]
  components: { Childs }
  props: ['data']
