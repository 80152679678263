












export default
  name: 'ContextBtn'
  props:
    name: { default: false }
    title: { default: false }
  data: ->
    menuActive: false
    menuHidden: false
  computed:
    inMore: ->
      el = @$parent
      result = false
      while el
        result = true if el.$options.name == 'ContextMenuMore'
        el = el.$parent
      result
    contentClass: ->
      result = []
      result.push 'context-menu-dialog'
      result.push "context-menu-dialog-#{@name}" if @name
      result.push 'context-menu-dialog-more' if @inMore
      result.join ' '
    btnClasses: ->
      result = {}
      result[@name] = true if @name
      result
  methods:
    action: ->
      el = @$parent
      while el
        if el.$options.name == 'ContextBtn'
          el.menuActive = false
        el = el.$parent
    openMenu: ->
      el = @$parent
      while el
        if el.$options.name == 'ContextBtn'
          el.menuHidden = true
        el = el.$parent
  watch:
    menuActive: ->
      unless @menuActive
        @menuHidden = false
