


















import BoardCover from '@/components/Board/BoardCover.vue'
export default
  name:
    "TeamGuestBoardItem"
  props:
    boardId: String
    user: String
    role: String
  inject: ['provideApp']
  components:
    { BoardCover }
  computed:
    classes: ->
      return
        [@currentTheme] : @currentTheme
    defaultTheme: -> 'theme-board-default'
    boardData: ->
      @firebind "/boards/#{@boardId}/data"
    currentTheme: ->
      @boardData?.boardTheme or @defaultTheme
    plan: ->
      plan = @firebind "/teamBilling/#{@context.teamId}/plan"
      if plan.$value == null
        return 'free'
      else
        plan.$value
    title: ->
      title = @firebind "/boards/#{@boardId}/data/title"
      title.$value
    roles: ->
      @firebind "/teamAccess/#{@context.teamId}/roles"
    membersInTeam: ->
      memebers = Object.keys @roles
      memebers
    boardsUsers: ->
      @firebind "/teamAccess/#{@context.teamId}/boards"
    guestWithEdit:->
      members = []
      for k,v of @boardsUsers
        if v and k != '*'
          for b,r of v
            if r == 'edit'
              members.push k
              break
      members
    allmembersInTeam: ->
      @membersInTeam.length + @guestWithEdit.length
    teamSize: ->
      teamSizeData = @firebind("/teamBilling/#{@context.teamId}/teamSize")
      teamSize = teamSizeData?.$value
      return 3 if teamSize == null
      teamSize
    teamNoLimits: ->
      val = @firebind("/teamBilling/#{@context.teamId}/nolimits")
      val.$value
    limitIsOver: ->
      #if !@teamNoLimits and @allmembersInTeam >= @teamSize
      #  return true
      false
  methods:
    removeFromBoard: ->
      @$store.dispatch 'team/removeUserFromBoard',
        teamId: @context.teamId
        boardId: @boardId
        userId: @user
    changeRole: (role) ->
      return if role == @role
      if @plan == 'free' and role == 'edit' and @limitIsOver
        @provideApp.dialogMaxLimitBoardId = @boardId
        @provideApp.dialogMaxLimitBoardUserId = @user
        @provideApp.dialogMaxLimitBoard = true
        return
      if @limitIsOver and role == 'edit'
        @provideApp.dialogMaxLimitBoardId = @boardId
        @provideApp.dialogMaxLimitBoardUserId = @user
        @provideApp.dialogMaxLimitBoard = true
        return
      @$store.dispatch 'team/updateBoardRole',
        teamId: @context.teamId
        boardId: @boardId
        userId: @user
        role: role
