




import WejeCard from './WejeCard'
import WejeList from './WejeList'
import WejeVideo from './WejeVideo'
import WejePicture from './WejePicture'
import WejeYoutubeVideo from './WejeYoutubeVideo'
import WejeAudio from './WejeAudio'
import WejeEmbed from './WejeEmbed'
import WejePdf from './WejePdf'
import WejeLink from './WejeLink'
import WejeGraffiti from './WejeGraffiti'
import WejeBtnLink from './WejeBtnLink'
import WejeShape from './WejeShape'
import WejeTradingView from './WejeTradingView'
import WejeListOnPage from './WejeListOnSite'
import WejeVoice from './WejeVoice'
import WejeChecklist from './WejeChecklist'
import WejeMention from './WejeMention'
import WejeDate from './WejeDate'

export default
  name: 'WejeWebjet'
  props: ['data']
  components: { WejeCard, WejeList, WejePicture, WejeVideo, WejePicture,
    WejeYoutubeVideo, WejeAudio, WejeEmbed, WejePdf, WejeLink, WejeGraffiti,
    WejeBtnLink, WejeShape, WejeTradingView, WejeListOnPage, WejeVoice,
    WejeChecklist, WejeDate, WejeMention }
  computed:
    component: ->
      return unless @data.category
      switch @data.category
        when '/webjets/content/webjets/note' then 'WejeCard'
        when '/webjets/content/webjets/photo' then 'WejePicture'
        when '/webjets/content/webjets/html5video' then 'WejeVideo'
        when '/services/youtube/webjets/video' then 'WejeYoutubeVideo'
        when '/webjets/content/webjets/audio' then 'WejeAudio'
        when '/webjets/content/webjets/embed' then 'WejeEmbed'
        when '/webjets/content/webjets/pdf' then 'WejePdf'
        when '/webjets/content/webjets/link' then 'WejeLink'
        when '/webjets/containers/webjets/list'
          findParentNode = (self,name) ->
            if self.$parent
              if self.$parent.$options.name == name
                return self.$parent
              else findParentNode(self.$parent,name)
            else
              return false
          parentNode = findParentNode this, 'Site'
          if !parentNode?.provideSidebar and !parentNode.provideCanvas
            'WejeListOnPage'
          else
            'WejeList'
        when '/webjets/content/webjets/graffiti' then 'WejeGraffiti'
        when '/webjets/content/webjets/shape' then 'WejeShape'
        #when 'anchor' then 'WejeAnchor'
        when 'btnLink' then 'WejeBtnLink'
        when 'tradingview' then 'WejeTradingView'
        when 'voice' then 'WejeVoice'
        when 'checklist' then 'WejeChecklist'
        when 'mention' then 'WejeMention'
        when 'date' then 'WejeDate'
        else 'WejeUnknown'
