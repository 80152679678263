































import TeamIcon from '@/components/Team/TeamIcon'
import DialogTemplate from '@/components/DialogTemplate'
export default
  name: 'TeamSettings'
  components: { TeamIcon, DialogTemplate }
  props:
    teamId: { default: false }
  data: ->
    teamDeleteDialog: false
    teamDeleteDialog2: false
  computed:
    teamPath: ->
      return null unless @teamId
      return "/teams/#{@teamId}"
    dataTitle: ->
      return null unless @teamPath
      t = @firebind("/teams/#{@teamId}/title")
      t?.$value
    title:
      get: ->
        return '' unless @teamId
        if @dataTitle
          @dataTitle
      set: (val) ->
        @$store.dispatch 'team/updateTeamTitle',
          path: @teamPath
          title: val
    canDelete: ->
      return false unless @teamId
      return false if @teamId.startsWith("team_")
      return false if @plan != 'free'
      return false if @teamNoLimits
      return true if @teamRole?.$value == 'owner'
      return false
    plan: ->
      plan = @firebind "/teamBilling/#{@teamId}/plan"
      if plan.$value == null
        return 'free'
      else
        plan.$value
    teamNoLimits: ->
      val = @firebind("/teamBilling/#{@teamId}/nolimits")
      val.$value
  firebind:
    teamRole: -> "/teamAccess/#{@teamId}/roles/#{@$store.state.uid}"

  methods:
    deleteTeam: ->
      if @teamId
        await @$store.dispatch 'team/delete',
          teamId: @teamId
        @$router.push { name: 'Home' }
      @teamDeleteDialog = false

