










































import GridButton from './GridButton'
import UndoRedo from './UndoRedo'
import HelpArrow from '@/components/HelpArrow'
import { isMobileOrTablet } from "@/utils"
import { mapState } from 'vuex'
export default
  name: 'Control'
  data: ->
    open: false
  inject: ['provideCanvas']
  computed: Object.assign {},
    currentZoom: ->
      Math.round @provideCanvas.scale * 100
    showResetSelection: ->
      selection = @$store.getters['webjet/selected']
      selection.length > 0 and FLAG.RESET_SELECTION_BTN and @$vuetify.breakpoint.width < 800
    userGizmo: ->
      @$store.getters['user/gizmo']
  methods:
    resetSelection: ->
      @$store.dispatch 'webjet/resetSelection'
    openColse: ->
      @open = !@open
    hoverOn: (e) ->
      return if e.movementX == 0 and e.movementY == 0
      @open = true
    hoverOut: ->
      @open = false
    zoomOut: ->
      @provideCanvas.zoomToLevel @provideCanvas.zoom.level - 1
    zoom: ->
      @provideCanvas.zoomToLevel 0
    zoomIn: ->
      @provideCanvas.zoomToLevel @provideCanvas.zoom.level + 1
    zoomFit: ->
      @provideCanvas.zoomToFit()
    clickOutsideControl: (e) ->
      unless e.target.closest '.canvas-control'
        @open = false
    moveSceneToCenter: ->
      @provideCanvas.zoomToLevel(0)
      @provideCanvas.sceneMoveCenterTo 0, 0
    showGizmo: ->
      @$store.dispatch 'user/setGizmo', { value: true }
    hideGizmo: ->
      @$store.dispatch 'user/setGizmo', { value: false }
  created: ->
    document.addEventListener 'touchstart', @clickOutsideControl
  destroyed: ->
    document.removeEventListener 'touchstart', @clickOutsideControl
  components: { GridButton, HelpArrow, UndoRedo }
