

































import { mdiClose  } from '@mdi/js'
import { firebind, generateSort } from '@/utils'
import { sendAnalytics } from '@/utils'
import CreateBoardImg from './CreateBoardImg.vue'
import CreateBoardCollaborations from './CreateBoardCollaborations.vue'
import DialogTemplate from '@/components/DialogTemplate'

export default
  name:
    "SpaceCreateFromTemplateDialog"
  components: {CreateBoardImg, CreateBoardCollaborations, DialogTemplate}
  props:
    value: Object # template object from ProfileBoardList.templates array
    defaultProject: String
  data: ->
    img: ''
    icon: mdiClose
    name: ''
    nameChanged: false
    loading: false
    selectProject: ''
  computed:
    projectItems: ->
      result = []
      projects = @$store.getters.availableManageProjects @context.teamId
      for p in projects
        projectsData = @firebind "/projects/#{p}"
        if !projectsData.title and p.startsWith('project_')
          title = 'Project 1'
        else
          title = if projectsData.title then projectsData.title else 'Untitled'
        result.push {text: title, value: p}
      result
    teamTitle: -> @$store.getters.teamTitle @context.teamId
    show:
      get: ->
        @value?
      set: ->
        @$emit 'input', null
    title:
      get: ->
        @name||(if @nameChanged then null else @value?.title)
      set: (v) ->
        @name = v
        @nameChanged = true
    defaultTitle: -> @value?.title
  watch:
    show: (val, oldVal) ->
      @$store.dispatch 'undoredo/reset'
  methods:
    createBoard: ->
      if FLAG.PROJECT
        return unless @selectProject
      return unless @value
      @loading = true
      teamId = @context.teamId
      if FLAG.PROJECT
        projectId = @selectProject
      else
        projectId = @$store.getters.availableEditProjects(teamId)[0]
      { webjetKey, webjetPath } = await @$store.dispatch 'webjet/createBoard',
        board:
          data:
            title: @name||@defaultTitle
          team: teamId
          project: projectId
      if @$refs.collaborations?.selectedUser?.length
        for user in @$refs.collaborations.selectedUser
          await @$store.dispatch 'webjet/shareBoard',
            src: webjetPath
            uid: user.uid
            mode: user.license
          sendAnalytics 'user_invited_to_board',
            invited_user_id : user.uid.substring(0, 15)
            access_type: user.license
      img = @$refs?.boardImg?.imgFile
      if img
        uploadTask = await @$store.dispatch 'webjet/updateBoardImg',
          path: webjetPath
          file: img
      else
        uploadTask = true
      if uploadTask
        #console.log { uid, @name, @defaultTitle, @value }
        await @$store.dispatch 'webjet/copyBoard',
          path: "/boards/#{@value.id}"
          title: @name||@defaultTitle
          src: webjetPath
          key: webjetKey
        setTimeout (-> document.querySelector('#board-page > .canvas').__vue__.zoomToFit())
        sendAnalytics 'new_board_created',
          create_mode: 'template'
          template_id: @value.id
          template_type: @value.class
          template_name: @defaultTitle
        @loading = false
        @close()
    close: ->
      @$emit 'input', null

    hotkeyBlocker: (e) ->
      # allow undo/redo
      if e.ctrlKey && e.keyCode in [ 89, 90 ]
        return
      # block other hotkeys
      e.stopPropagation()
      # block ctrl +/-/0
      if e.ctrlKey && e.keyCode in [ 187, 189, 48, 107, 109, 45, 43, 96 ]
        e.preventDefault()

  mounted: ->
    if @value?.img
      @img = @value.img
    if @defaultProject
      @selectProject = @defaultProject
    else if @projectItems[0]?.value
      @selectProject = @projectItems[0]?.value
