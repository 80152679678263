


















































































































































import { debounce } from '@/utils'
import AccountImg from './account/AccountImg'
#import DialogPremium from '@/components/DialogPremium'
import LocaleSwitch from '@/components/LocaleSwitch'
import { userDisplayName } from '@/utils'
import { mdiLoginVariant } from '@mdi/js'

export default
  name:
    "Account"
  #components: { AccountImg, DialogPremium, LocaleSwitch }
  components: { AccountImg, LocaleSwitch }
  inject: ['provideApp']
  firebind:
    userData: ->
      '/users/' + @$store.state.uid

  data: ->
    deleteDialog: false
    deletePassword: null
    deletePasswordFailed: false
    deleteAuthFailed: false
    coupon: null
    couponSuccess: false
    couponError: false
    iconLogin: mdiLoginVariant
    upgradePlanError: false
    unsubscribeDialog: false
    #showPremiumDialog: null

  computed:
    placeholderName: ->
      userDisplayName @$store.state.uid
    firstName:
      get: -> @userData?.firstName
      set: (v) ->
        v = v.replace(/<\/?[^>]+>/gi, '')
        @debouncedSaveFirstName(v)
    lastName:
      get: -> @userData?.lastName
      set: (v) ->
        v = v.replace(/<\/?[^>]+>/gi, '')
        @debouncedSaveLastName(v)
    email: ->
      if @user?.email
        @user?.email
      else if @$store.state.auth?
        @$store.state.auth().currentUser?.email||''
    couponsCount: ->
      count = 0
      for k,v of @userData?.coupons
        count++
      return count
    couponsType: ->
      types = {}
      for k,v of @userData?.coupons
        types[v.type]=true
      return 'ltd' if types.ltd
      return 'dealify' if types.dealify
      return 'mantra' if types.mantra
      return null
    plan: ->
      @userData?.plan||'free'
    isRegisteredUser: ->
      @$store.state.isRegisteredUser
    isAnonymousUser: ->
      @$store.state.isAnonymousUser
    userHasPassword: ->
      return false unless @isRegisteredUser
      for p in @$store.state.auth().currentUser.providerData
        if p.providerId=="password"
          return true
      return false
    userHasGoogle: ->
      return false unless @isRegisteredUser
      for p in @$store.state.auth().currentUser.providerData
        if p.providerId=="google.com"
          return true
      return false
    planSubscriptionEnd: ->
      if @userData.stripe_subscription_enddate
        new Date(@userData.stripe_subscription_enddate*1000).toLocaleDateString()
      else
        null
    notifyShare:
      get: ->
        if @userData.settings?.notify_share?
          return @userData.settings?.notify_share
        else
          return true
      set: (v) ->
        @$store.dispatch 'user/setNotifyShare', { value: v }
    themeDark:
      get: ->
        @provideApp.themeDark
      set: (v) ->
        @provideApp.themeDark = v
  methods:
    saveFirstName: (v) ->
      if v != @userData?.firstName
        @$store.dispatch 'user/setFirstName', { value: v }
    saveLastName: (v) ->
      if v != @userData?.lastName
        @$store.dispatch 'user/setLastName', { value: v }
    onCouponChange: ->
      @couponSuccess = false
      @couponError = false
    addCoupon: ->
      return unless @coupon
      @coupon = @coupon.trim()
      return unless @coupon
      @couponSuccess = false
      @couponError = false
      if @couponsCount >= 3
        @couponError = true
        return
      status = await @firebind "/coupons/#{@coupon}/available"
      if !status.$value
        @couponError = true
        return
      type = await @firebind "/coupons/#{@coupon}/type"
      await @$store.dispatch 'user/addCoupon',
        coupon: @coupon
        type: type.$value
      if @userData.coupons?[@coupon]
        @couponSuccess = true
      else
        @couponError = true
    deleteAccount: ->
      @deletePasswordFailed = false
      @deleteAuthFailed = false
      if @userHasPassword
        if not @deletePassword
          @deletePasswordFailed = true
          return
        @$store.state.auth().signInWithEmailAndPassword(@userData?.email, @deletePassword).then(
          =>
            @deletePassword = null
            await @$store.dispatch 'user/deleteAccount'

          (error) =>
            @deletePasswordFailed = true
        )
      else if @userHasGoogle
        googleAuth = new @$store.state.auth.GoogleAuthProvider()
        googleAuth.addScope("profile")
        googleAuth.addScope("email")
        uid = @$store.state.uid
        @$store.state.auth().signInWithPopup(googleAuth).then(
          (result) =>
            if result?.user?.uid == uid
              await @$store.dispatch 'user/deleteAccount'
            else
              console.error "User changed in reauthentication, force logout"
              await @$store.dispatch 'signOut'

          (error) =>
            console.error error
            @deleteAuthFailed = true
        )
      else
        @deleteAuthFailed = true
    logout: ->
      @$store.dispatch 'signOut'

    upgradePlan: ->
      return # we do not use it now, return is added for safety reasons
      #console.log '!upgradePlan'
      @upgradePlanError = false

      if IS_PRODUCTION and !IS_STAGE
        url = 'https://stripe.app.weje.io/session'
        priceId = 'price_1LcEYNDbibRhxhzYl0Bw9ZAO'
      else if FLAG.STRIPE_LOCAL
        url = 'http://localhost:8802/session'
        priceId = 'price_1KlUgWDbibRhxhzYd1KcyFll'
      else
        url = 'https://stripe.stage.weje.io/session'
        priceId = 'price_1KlUgWDbibRhxhzYd1KcyFll'

      data =
        plan: priceId
        token: await @$store.state.auth().currentUser.getIdToken()

      stripe = $stripe()

      fetch(url,
        method: 'POST'
        headers:
          'Content-Type': 'application/json'
        body: JSON.stringify data
      ).then( (response) =>
        #console.log 'got response:', response
        if response?.status == 200
          return await response.json()
        else
          throw new Error("Response status: #{response?.status}")
      ).then( (session) =>
        #console.log 'got session:', session
        return (await stripe).redirectToCheckout { sessionId: session.id }
      ).then( (result) =>
        # Here we gets only if redirectToCheckout fails because of something
        @upgradePlanError = true
        console.error 'redirectToCheckout failed'
        if result?.error?.message
          console.error result?.error?.message
        else
          console.error result
      ).catch (error) =>
        console.error error
        @upgradePlanError = true

    unsubscribePlan: ->
      @unsubscribeDialog = false
      @$store.dispatch 'user/unsubscribePlan'

    toggleTheme: ->
      @provideApp.toggleTheme()


  created: ->
    @debouncedSaveFirstName = debounce @saveFirstName, 500
    @debouncedSaveLastName = debounce @saveLastName, 500

  #mounted: ->
  #  if @$router.currentRoute.hash == '#up'
  #    setTimeout =>
  #      @upgradePlan()
