




















export default
  name: 'BoardHeaderBack'

  computed: Object.assign {},
    isRegisteredUser: ->
      @$store.state.isRegisteredUser

