










import SidebarContent from '../SidebarContent'
import WebjetInventory from '@/components/WebjetInventory'
import InventoryTools from './InventoryTools'
import inventoryMixin from './inventoryMixin'
import ContextMenu from '@/components/ContextMenu'
export default
  name: 'InventoryContent'
  components: { SidebarContent, WebjetInventory, InventoryTools, ContextMenu }
  mixins: [ inventoryMixin ]
  provide: { provideBoardSrc: 'inventory' }
  inject: ['provideSidebar']
  mounted: ->
    @resetNew()
  computed:
    src: -> "inventory"
    selected: -> @$store.getters['webjet/selected']
    sidebarSelected: -> @selected and @selected[0]?.provideSidebar
    sidebarMode: -> @provideSidebar.mode
  methods:
    cancelSelection: ->
      @$store.dispatch 'webjet/resetSelection'
  watch:
    sidebarMode: (value)->
      if value == 'hide' and @sidebarSelected
        @cancelSelection()
