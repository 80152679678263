






import { decodeDataTransfer } from '@/utils'
export default
  name: 'DragLayer'
  data: ->
    type: false
    data: false
    x: 0
    y: 0
    scale: 1
    dropInWebjet: false
    dropInWebjetWidth: 0
    dragCounter: 0
    lastDataString: false
    dragOverInvetory: false
  context:
    positionable: -> !@dropInWebjet
  created: ->
    window.addEventListener 'dragover', @dragover
    window.addEventListener 'dragenter', @dragenter
    window.addEventListener 'dragleave', @dragleave
    #window.addEventListener 'dragstart', @dragover
    window.addEventListener 'drop', @drop
  beforeDestroy: ->
    window.removeEventListener 'dragover', @dragover
    window.removeEventListener 'dragenter', @dragenter
    window.removeEventListener 'dragleave', @dragleave
    window.removeEventListener 'drop', @drop
  computed:
    show: ->
      @dragCounter != 0
    styles: ->
      result =
        left: @x + 'px'
        top: @y + 'px'
      result.transform = "scale(#{@scale})"
      result['z-index'] = 3 if @dragOverInvetory
      result
  methods:
    webjetStyles: (item, index)->
      if @dropInWebjet #and @data.items.length > 1
        sum = 0
        webjetsIsRender = @$refs?.webjets?.length
        if webjetsIsRender
          for e in @$refs.webjets
            if item.path == e.src
              break
            else
              sum += e.$el.offsetHeight + 10
        return
          transform: "translate(20px, #{index*100}px)"
          transform: "translate(20px, #{sum}px)"
          width: "#{@dropInWebjetWidth}px"
      else
        return
          transform: "translate(#{-item.offsetX}px, #{-item.offsetY}px)!important"

    dragenter: (e)->
      if @dragCounter == 0
        # Show in same frame with webjet hide. And correct scale
        window.addEventListener 'dragover', =>
          @dragCounter += 1
        , { once: true }
      else
        @dragCounter += 1

    dragleave: (e)->
      @dragCounter -= 1
      @dragout() if @dragCounter == 0

    drop: (e)->
      # Don't blink with 3 requestAnimationFrame. Why?
      requestAnimationFrame => requestAnimationFrame => requestAnimationFrame => @dragout()

    dragout: ()->
      @dragCounter = 0
      @type = false
      @data = false
      @lastDataString = false

    dragover: (e)->
      type = undefined
      for t in e.dataTransfer.types
        if t.split('/')[0] == 'webjets' or t in @$store.getters['webjet/dataTransferTypes']
          type = t
          break

      @x = e.x
      @y = e.y

      if e.scale
        @scale = e.scale
      else
        @scale = 1
      cPath = e.composedPath()
      dragOverInvetory = false
      dragOverInvetory = true if e.dragOverInvetory
      @dragOverInvetory = dragOverInvetory
      if e.dropInWebjet
        @dropInWebjet = true
        @dropInWebjetWidth = e.dropInWebjetWidth
      else
        @dropInWebjet = false
      if type
        if type.startsWith('webjets/')
          @type = 'webjets'
          if type.substring(8) != @lastDataString
            @data = decodeDataTransfer type.substring(8)
            @lastDataString = type.substring(8)

