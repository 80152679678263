export default
  data: ->
    reactiveFresh: {}

  created: ->
    @fresh = @reactiveFresh

  computed:
    dbFresh: ->
      @firebind "fresh/#{@src}"

    isFresh: ->
      @freshCount > 0

    freshCount: ->
      result = 0
      for k, v of @reactiveFresh
        result += 1 if @reactiveFresh[k]
      result

    freshQueue: ->
      webjets = []
      for k, v of @reactiveFresh
        webjets.push k if @reactiveFresh[k]
      webjets.sort (a, b)=>
        wa = @firebind "/boardsData/#{@src}/#{a}"
        wb = @firebind "/boardsData/#{@src}/#{b}"
        adate = wa?.meta?.updateDate
        bdate = wb?.meta?.updateDate
        if adate and bdate
          new Date(adate).getTime() - new Date(bdate).getTime()
        else
          0
      webjets

  methods:
    isFreshWebjet: (id)->
      if @fresh[id] == undefined
        @$set @fresh, id, null
      @fresh[id]

  watch:
    dbFresh:
      immediate: true
      handler: (val, oldVal)->
        return unless FLAG.FRESH
        fresh = @reactiveFresh
        newIds = []
        for k, v of val
          newIds.push k if !fresh[k]
        if oldVal?.$sync and @$store.state.visibility and @$store.state.activity
          for wid in newIds
            component = @getWebjetComponentBySrc("/boardsData/#{@src}/#{wid}")
            if component and component.genShow
              @$store.dispatch 'fresh/unfreshWebjet', { boardId: @src, webjetId: wid }
            else
              @$set fresh, wid, true
        else
          for wid in newIds
            @$set fresh, wid, true
        for k, v of fresh
          if fresh[k] and !val[k]
            fresh[k] = false
