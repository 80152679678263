






export default
  name: 'WejeChilds'
  props: ['childs', 'column']
  #components: { WejeWebjet }
  components:
    WejeWebjet: ()=> import('@/weje/wejes/WejeWebjet')
  computed:
    childItems: ->
      if @column
        @childs.filter (c)=> c.columnKey == @column
      else if @column == 0
        @childs.filter (c)=> !c.columnKey
      else
        @childs
