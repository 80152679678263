import { encodeDataTransfer, decodeDataTransfer } from '@/utils'
import { isMobileOrTablet, isMac } from '@/utils'
import { DRAGTOUCH_THRESHOLD, DRAGTOUCH_TIMEOUT } from '@/utils'

import './css/draggable.sass'

draggable =
  classes: ->
    return false if @isReadOnly
    return
      'draggable': true
      'drag-in-progress': @dragInProgress
      'drag-move-in-progress': @dragInProgress == 'move'
      'drag-copy-in-progress': @dragInProgress == 'copy'
      'drag-touch-ready': @dragTouch.ready

  styles: ->
    return false unless @dragInProgress == 'copy' || @dragTouch.ready
    return
      '--scale': @provideCanvas.scale

  attrs: ->
    draggable: @canDraggable

  listeners: ->
    customListeners =
      preventdrag: @preventDrag
    return customListeners if !@canDraggable
    dragListeners =
      dragstart: @draggableDragstart
      dragend: @draggableDragend
      mousedown: @draggableMousedown
      #dragover: @dragover
    touchListeners =
      touchstart: @draggableTouchstart
      touchmove: @draggableTouchmove
      touchend: @draggableTouchend
      touchcancel: @draggableTouchcancel
    listeners = Object.assign {}, customListeners
    if FLAG.DIE_GHOST_DIE_2
      return Object.assign listeners, dragListeners
    else if isMobileOrTablet
      return Object.assign listeners, touchListeners
    else
      listeners = Object.assign listeners, dragListeners
      return Object.assign listeners, touchListeners

  data: ->
    dragInProgress: false
    draggableWebjets: false
    dragTouch: { hold: false, active: false, ready: false }
    preventDraggable: false
    dragWebjetByMovinIcon: null
    dragByMovinIcon: false

  computed:
    canDropOnlyOnCanvas: -> false
    isDraggable: -> true
    canDraggable: -> !@isReadOnly and @connection and not (@isInTrash and @provideWebjet) and !@preventDraggable
    draggableIsInsideSelection: ->
      @provideWebjet?.isSelected or @provideWebjet?.draggableIsInsideSelection

  methods:
    preventDrag: (e)->
      @preventDraggable = e.detail?.prevent||false

    draggableGetGhost: ->
      wrapper = document.createElement 'div'
      cloneNodeDeep = true

      nodeName = @$el.nodeName
      if nodeName == 'path'
        cloneNodeDeep = false

      clone = @$el.cloneNode cloneNodeDeep


      iframes = clone.querySelectorAll('iframe')
      if iframes
        for iframe in iframes
          iframe.remove()

      nodeName = clone.nodeName
      if nodeName == 'path'
        wrapper = document.createElement 'svg'

      clone.classList.add 'drag-clone'
      clone.classList.remove 'drag-touch-ready'
      clone.style.position = 'absolute'
      clone.style.top = "0px"
      clone.style.left = '0px'
      clone.style.width = "#{@$el.offsetWidth}px"
      clone.style.height = "#{@$el.offsetHeight}px"
      clone.style.setProperty 'transform', 'scale('+@provideCanvas?.scale or 1+')', 'important'
      clone.style.transformOrigin = 'left top'
      if nodeName == 'path'
        wrapper.appendChild clone
        wrapper.style.position = 'relative'
        wrapper.style.overflow = 'visible'
        wrapper.style.width = '100%'
        wrapper.style.height = '100%'
        wrapper.style['z-index'] = '1'
        stringWrapper = wrapper.outerHTML
        stringWrapper = stringWrapper.replace('</path>', '')
        overWrapper = document.createElement 'div'
        overWrapper.style.position = 'absolute'
        overWrapper.style.width = '100%'
        overWrapper.style.height = '100%'
        overWrapper.style.zIndex = '999999'
        overWrapper.style.padding = "20px"
        overWrapper.innerHTML = stringWrapper
        wrapper.style.position = 'absolute'
        wrapper.style.zIndex = '999999'
        if @provideCanvas
          document.querySelector('#board-page').appendChild overWrapper
        else
          document.querySelector('.v-main').appendChild overWrapper
      else
        wrapper.appendChild clone
        wrapper.style.position = 'absolute'
        wrapper.style.zIndex = '999999'
        if @provideCanvas
          # board-page
          document.querySelector('#board-page').appendChild wrapper
        else
          document.querySelector('.v-main').appendChild wrapper

    draggableGhostOffset: (e) ->
      x = e.offsetX
      y = e.offsetY
      topOffsetClone = 0
      leftOffsetClone = 0
      if @isWsSelected
        topOffsetClone = -30
      if @webjet.category == 'anchor' and @type == 'iframe'
        anchorHeaderTop = @positionOffsetScale
        leftOffsetClone = 10
        if navigator.userAgent.toLowerCase().indexOf('firefox') > -1
          anchorHeaderTop += 10
          leftOffsetClone = 0
        topOffsetClone = anchorHeaderTop
      x = x + leftOffsetClone
      y = y - topOffsetClone
      # offset for paint
      if @$options.name == 'WebjetPaint'
        mouseX = @provideCanvas.sceneX + e.canvasX / @provideCanvas.scale
        mousey = @provideCanvas.sceneY + e.canvasY / @provideCanvas.scale
        rect = @elementSceneRect
        x = (rect.x1 - mouseX) * -1
        y = (rect.y1 - mousey) * -1
      { x, y }

    draggableCreateGhost: (e)->
      if FLAG.DIE_GHOST_DIE
        img = new Image()
        img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs='
        e.dataTransfer.setDragImage img, 0, 0
        window.addEventListener 'dragover', =>
          for w in @draggableWebjets
            w.dragInProgress = 'move'
            w.dragInProgress = 'copy' if e.ctrlKey or e.altKey
        , { once: true, capture: true }
      else
        wrapper = @draggableGetGhost()
        wrapper.style.zIndex = '-1'
        offset = @draggableGhostOffset(e)

        if navigator.userAgent.toLowerCase().indexOf('firefox') > -1 || isMac
          x = offset.x * (@provideCanvas?.scale or 1)
          y = offset.y * (@provideCanvas?.scale or 1)
        else
          x = offset.x * (@provideCanvas?.scale or 1) * window.devicePixelRatio #* window.devicePixelRatio
          y = offset.y * (@provideCanvas?.scale or 1) * window.devicePixelRatio #* window.devicePixelRatio

        e.dataTransfer.setDragImage wrapper, x, y
        requestAnimationFrame =>
          for w in @draggableWebjets
            w.dragInProgress = 'move'
            w.dragInProgress = 'copy' if e.ctrlKey or e.altKey
          wrapper.remove()

    draggableItemData: (x, y)->
      result = { path: @src }
      if @provideScene

        result.offsetX = @provideCanvas.sceneX + x / @provideCanvas.scale - @elementSceneRect.x1
        result.offsetY = @provideCanvas.sceneY + y / @provideCanvas.scale - @elementSceneRect.y1
      else
        result.offsetX = x - @$el.getBoundingClientRect().left
        result.offsetY = y - @$el.getBoundingClientRect().top

      result

    draggableGetData: (x, y, mode)->
      data = {}
      data.mode = 'move'
      data.mode = mode if mode
      items = []
      hasContainer = false
      canDropOnlyOnCanvas = false
      for w in @draggableWebjets
        hasContainer = true if w.isContainer
        canDropOnlyOnCanvas = true if w.canDropOnlyOnCanvas
        items.push w.draggableItemData(x, y)
      data.items = items
      data.hasContainer = hasContainer
      data.canDropOnlyOnCanvas = canDropOnlyOnCanvas
      data.boardSrc = @provideBoardSrc
      data

    draggableGetSelectedWebjets: ()->
      # override webjets for drag from webjet
      # example Anchor
      @$store.getters['webjet/selected']

    draggableGetWebjet: () ->
      # override webjet for drag from webjet
      [this]

    draggableCreateData: (e)->
      if @$store.getters['webjet/selected'].length > 0 and @isSelected
        toDrag = @draggableGetSelectedWebjets()
      else
        toDrag = @draggableGetWebjet()
      toDrag = toDrag.filter (w)-> w.canDraggable
      @draggableWebjets = toDrag
      mode = 'move'
      mode = 'copy' if e.ctrlKey or e.altKey
      if @provideScene
        x = e.canvasX
        y = e.canvasY
      else
        x = e.x
        y = e.y
      data = @draggableGetData(x, y, mode)
      # console.log data
      e.dataTransfer.clearData()
      e.dataTransfer.setData "webjets/#{encodeDataTransfer(data)}", {}

    draggableCreateDataForTouch: (e)->
      if @$store.getters['webjet/selected'].length > 0 and @isSelected
        toDrag = @draggableGetSelectedWebjets()
      else
        toDrag = @draggableGetWebjet()
      toDrag = toDrag.filter (w)-> w.canDraggable
      @draggableWebjets = toDrag
      data = @draggableGetData(e.touches[0].clientX, e.touches[0].clientY)
      @dragTouch.data = "webjets/"+encodeDataTransfer(data)

    draggableDragstart: (e)->
      dragWebjetByElementInside = false
      if e.webjetUid
        dragWebjetByElementInside = e.webjetUid == this._uid

      return if FLAG.DRAG_INSIDE_SELECTION and @draggableIsInsideSelection and !dragWebjetByElementInside

      unless dragWebjetByElementInside
        if @provideWebjet
          provideWebjetIsInventory = @provideWebjet?.$options.name == 'WebjetInventory'
          provideWebjetIsList = @provideWebjet?.$options.name == 'WebjetList'
          if @provideWebjet and !provideWebjetIsInventory and !provideWebjetIsList
            return unless @isSelected
      # e.shiftKey prevent drag on shift selection webjets
      if e.shiftKey
        e.stopPropagation()
        e.preventDefault()
      else
        # console.log '!drag start', e
        @draggableCreateGhost(e)
        @draggableCreateData(e)
        e.stopPropagation() # Need for nested webjet dragging

    draggableDragend: (e)->
      # console.log '!drag end', e
      for w in @draggableWebjets
        w.dragInProgress = false
      @draggableWebjets = false

    draggableMousedown: (e)->
      # dataTransfer = new DataTransfer
      # console.log 'draggableMousedown', dataTransfer
      # @$el.dispatchEvent(new DragEvent('dragstart', {dataTransfer:dataTransfer}))
      # calc elementSceneRect before drag&drop, because iframe bug in chrome in ghost creation
      if @provideScene
        if @$store.getters['webjet/selected'].length > 0 and @isSelected
          for w in @$store.getters['webjet/selected']
            w.elementSceneRect
        else if @webjet.category == 'anchor' and @type == 'iframe'
          for w in @webjetInAnchor()
            w.elementSceneRect
        else
          @elementSceneRect
      if e.which != 2 and !e.shiftKey
        e.stopPropagation()
      true

    draggableTouchstart: (e)->
      if e.touches?.length == 1 && !e.target.matches('.w-resizer') && !e.dragHold && !@resizeChekIResizeZone(e)
        # console.log '!drag touch start', e
        @dragTouch = {
          hold: true, active: false, ready: false,
          bx: e.touches[0].pageX, by: e.touches[0].pageY,
          timestamp: new Date().getTime()
          rect: @$el.getBoundingClientRect()
        }
        e.dragHold = true
        @dragTouch.readyTimeout = setTimeout (=> @dragTouch.ready = true), DRAGTOUCH_TIMEOUT
        #e.stopPropagation()
        #e.preventDefault()

    draggableTouchmove: (e)->
      clearInterval @dragTouch.dragoverRepeater if @dragTouch.dragoverRepeater
      distance = @draggableTouchDistance(e)
      #console.log '!drag touch move', e, @dragTouch, distance
      if @dragTouch.hold
        if e.touches?.length != 1
          @draggableTouchcancel(e)
          return
        if new Date().getTime() - @dragTouch.timestamp < DRAGTOUCH_TIMEOUT
          if distance > DRAGTOUCH_THRESHOLD
            @draggableTouchcancel(e)
          return
        @dragTouch.active = true
        @dragTouch.hold = false
        @dragTouch.ready = true
        @dragTouch.ghost = @draggableGetGhost() unless FLAG.DIE_GHOST_DIE
        @draggableCreateDataForTouch(e)
        requestAnimationFrame =>
          for w in @draggableWebjets
            w.dragInProgress = 'move'
      if @dragTouch.active
        e.stopPropagation()
        e.preventDefault()
        if distance > DRAGTOUCH_THRESHOLD
          @dragTouch.ready = false
        dx = e.touches[0].pageX - @dragTouch.bx
        dy = e.touches[0].pageY - @dragTouch.by
        unless FLAG.DIE_GHOST_DIE
          @dragTouch.ghost.style.top = @dragTouch.rect.top + dy + 'px'
          @dragTouch.ghost.style.left = @dragTouch.rect.left + dx + 'px'
          @dragTouch.ghost.style.display = 'none'
        target = document.elementFromPoint e.touches[0].clientX, e.touches[0].clientY
        unless FLAG.DIE_GHOST_DIE
          @dragTouch.ghost.style.display = 'block'
        while target && !target.classList.contains('droppable') && !target.classList.contains('canvas') && !target.classList.contains('v-menu__content') && !target.matches('.trash-content *')
          target = target.parentElement
        if target && !target.classList.contains('v-menu__content') && !target.matches('.trash-content *')
          if target != @dragTouch.target
            if @dragTouch.target
              @draggableSimulateDrag 'dragleave'
            @dragTouch.target = target
            @draggableSimulateDrag 'dragenter'
          @dragTouch.lastX = e.touches[0].clientX
          @dragTouch.lastY = e.touches[0].clientY
          @draggableSimulateDrag 'dragover'
          @dragTouch.dragoverRepeater = setInterval (=> @draggableSimulateDrag 'dragover'), 100
        else if @dragTouch.target
          @draggableSimulateDrag 'dragleave'
          @dragTouch.target = null

    draggableTouchcancel: (e)->
      #console.log '!drag touch cancel', e, @dragTouch
      clearTimeout @dragTouch.readyTimeout
      clearInterval @dragTouch.dragoverRepeater if @dragTouch.dragoverRepeater
      @dragTouch.ghost.remove() if @dragTouch.ghost
      requestAnimationFrame =>
        for w in @draggableWebjets
          w.dragInProgress = false
      @dragTouch = { hold: false, active: false, ready: false }

    draggableTouchend: (e)->
      #console.log '!drag touch end', e, @dragTouch
      clearInterval @dragTouch.dragoverRepeater if @dragTouch.dragoverRepeater
      if @dragTouch.ready
        if e.target.matches('.editor *') # skip tap+hold processing in editor to not block copy/paste menu
          @draggableTouchcancel(e)
          return
        # not moved yet = tap+hold
        taphold = new UIEvent 'taphold', { bubbles: true, cancelable: true }
        e.x = @dragTouch.lastX
        e.y = @dragTouch.lastY
        @$el.dispatchEvent(taphold)
        @draggableTouchcancel(e)
        #e.stopPropagation()
        e.preventDefault()
      else if @dragTouch.active
        @draggableSimulateDrag 'drop'
        @draggableTouchcancel(e)
        e.stopPropagation()
        e.preventDefault()
      else if @dragTouch.hold
        @draggableTouchcancel(e)

    draggableTouchDistance: (e)->
      distance = Math.sqrt(
        Math.pow(e.touches[0].pageX - @dragTouch.bx, 2) + Math.pow(e.touches[0].pageY - @dragTouch.by, 2)
      )
      return parseInt(distance, 10)

    draggableSimulateDrag: (type, target = @dragTouch.target)->
      return unless target
      e = new UIEvent type, { bubbles: true, cancelable: true }
      e.x = @dragTouch.lastX
      e.y = @dragTouch.lastY
      e.dataTransfer =
        types: [ @dragTouch.data ]
      target.dispatchEvent(e)

export default draggable
export { draggable }
