






































import { firebind } from "@/utils"
export default
  name: 'Presentation'
  inject: ['provideCanvas']
  data: ->
    hiddenControls: false
    hiddenControlsTimer: null
    sceneMatrix: null
    sceneMatrixDraw: false

  computed:
    showCancel: ->
      return true if @provideCanvas.userCanEdit
      return false if @$route.query.pexit
      true
    stateSum: ->
      "#{@animationActive}#{@provideCanvas.position.x}#{@provideCanvas.position.y}" +
      "#{@$vuetify.breakpoint.width}#{@$vuetify.breakpoint.height}#{@provideCanvas.scale}" +
      "#{@provideCanvas.willChange}#{@provideCanvas.transition}"
    animationActive: ->
      @provideCanvas.animation.active
    activeWebjet: ->
      @anchors[@activeIndexInPresentation]
    activeWebjetComponent: ->
      return null unless @provideCanvas.anchorWebjet
      @provideCanvas.anchorWebjet
    anchors: ->
      result = []
      for a in @provideCanvas.anchors
        webjet = firebind a.src
        result.push webjet
      result
    sceneSize: ->
      w = @$vuetify.breakpoint.width
      h = @$vuetify.breakpoint.height
      {w,h}
    activeIndexInPresentation: ->
      @provideCanvas.activeIndexInPresentation
    countWebjetInPresentation: ->
      @provideCanvas.anchors.length
    leftStyle: ->
      return false unless @activeWebjetComponent and @sceneMatrix
      wRect = @activeWebjetComponent.elementSceneRect
      h = @$vuetify.breakpoint.height
      w = @$vuetify.breakpoint.width
      cpx = @provideCanvas.position.x
      czx = @sceneMatrix.e
      if @activeWebjetComponent.type == 'bookmark'
        ex1 = wRect.x1 + (wRect.x2 - wRect.x1) / 2 - 500
        ex2 = wRect.x1 + (wRect.x2 - wRect.x1) / 2 + 500
      else
        ex1 = wRect.x1
        ex2 = wRect.x2
      scale = @sceneMatrix.a
      # left = cpx + czx + ex1 * scale + (ex2 - ex1)  * scale
      width = cpx + czx + ex1 * scale
      width = 0 if width < 0
      left = 0
      top = 0
      return
        width: "#{width}px"
        height: "#{h}px"
        transform: "translate(#{left}px, #{top}px)"

    topStyle: ->
      return false unless @activeWebjetComponent and @sceneMatrix
      wRect = @activeWebjetComponent.elementSceneRect
      h = @$vuetify.breakpoint.height
      w = @$vuetify.breakpoint.width
      cpy = @provideCanvas.position.y
      czy = @sceneMatrix.f
      if @activeWebjetComponent.type == 'bookmark'
        ey1 = wRect.y1 + (wRect.y2 - wRect.y1) / 2 - 500
        ey2 = wRect.y1 + (wRect.y2 - wRect.y1) / 2 + 500
      else
        ey1 = wRect.y1
        ey2 = wRect.y2
      scale = @sceneMatrix.a

      top = 0
      left = 0
      height = cpy + czy + ey1 * scale
      height = 0 if height < 0
      # console.log @sceneMatrix, scale, height
      return
        width: "#{w}px"
        height: "#{height}px"
        transform: "translate(#{left}px, #{top}px)"

    rightStyle: ->
      return false unless @activeWebjetComponent and @sceneMatrix
      wRect = @activeWebjetComponent.elementSceneRect
      h = @$vuetify.breakpoint.height
      w = @$vuetify.breakpoint.width
      cpx = @provideCanvas.position.x
      czx = @sceneMatrix.e
      if @activeWebjetComponent.type == 'bookmark'
        ex1 = wRect.x1 + (wRect.x2 - wRect.x1) / 2 - 500
        ex2 = wRect.x1 + (wRect.x2 - wRect.x1) / 2 + 500
      else
        ex1 = wRect.x1
        ex2 = wRect.x2
      scale = @sceneMatrix.a
      # left = cpx + czx + ex1 * scale + (ex2 - ex1)  * scale
      width = w
      width = 0 if width < 0
      left = cpx + czx + ex2 * scale
      top = 0
      return
        width: "#{width}px"
        height: "#{h}px"
        transform: "translate(#{left}px, #{top}px)"

    bottomStyle: ->
      return false unless @activeWebjetComponent and @sceneMatrix
      wRect = @activeWebjetComponent.elementSceneRect
      h = @$vuetify.breakpoint.height
      w = @$vuetify.breakpoint.width
      cpy = @provideCanvas.position.y
      czy = @sceneMatrix.f
      if @activeWebjetComponent.type == 'bookmark'
        ey1 = wRect.y1 + (wRect.y2 - wRect.y1) / 2 - 500
        # ey2 = (wRect.y2 - wRect.y1) / 2 + 500
        ey2 = wRect.y1 + (wRect.y2 - wRect.y1) / 2 + 500
      else
        ey1 = wRect.y1
        ey2 = wRect.y2
      # ey2 = ey1 + 1000
      scale = @sceneMatrix.a

      left = 0
      top = cpy + czy + ey2 * scale
      height = h
      height = 0 if height < 0

      # console.log @sceneMatrix, scale, height
      return
        width: "#{w}px"
        height: "#{height}px"
        transform: "translate(#{left}px, #{top}px)"
  watch:
    stateSum: ->
      unless @sceneMatrixDraw
        requestAnimationFrame => requestAnimationFrame => @setSceneMatrix()
    sceneSize: (val) ->
      anchorWebjet = @provideCanvas.anchorWebjet
      @provideCanvas.achorSetChords anchorWebjet
  methods:
    setSceneMatrix: ->
      # console.log 'setSceneMatrix'
      # return unless @provideCanvas.animation.active
      sceneEl = @provideCanvas.$refs.scene.$el
      rect = sceneEl.getBoundingClientRect()
      sceneMatrix = new DOMMatrixReadOnly(getComputedStyle(sceneEl).transform)
      @sceneMatrix = sceneMatrix
      # if @provideCanvas.animation.active
      if @provideCanvas.willChange == ''
        @sceneMatrixDraw = true
        requestAnimationFrame => requestAnimationFrame => @setSceneMatrix()
      else
        @sceneMatrixDraw = false
    goToIndexWebjet: (index) ->
      @provideCanvas.goToIndexWebjet(index)

    prevWebjet: ->
      @provideCanvas.prevWebjet()

    nextWebjet: ->
      @provideCanvas.nextWebjet()

    cancelPresentation: ->
      if document.fullscreenElement
          document.exitFullscreen()
      @$router.push path: @$router.path

    fullScreenActive: ->
      board = document.querySelector "#board-page"
      if !document.fullscreenElement
        board.requestFullscreen()
      else
        if document.exitFullscreen
          document.exitFullscreen()

    hideControls: (e,hide = false) ->
      clearTimeout(@hiddenControlsTimer) if @hiddenControlsTimer
      if hide
        @hiddenControls = true
      else
        @hiddenControls = false
        @hiddenControlsTimer = setTimeout (=> @hideControls(e,true)), 3000

  created: ->
    window.addEventListener 'mousemove', @hideControls
    window.addEventListener 'touchstart', @hideControls
  destroyed: ->
    window.removeEventListener 'mousemove', @hideControls
    window.removeEventListener 'touchstart', @hideControls
  mounted: ->
    requestAnimationFrame => requestAnimationFrame => @setSceneMatrix()

