import { debounce } from '@/utils'
import { decodeDataTransfer } from '@/utils'
import './css/droppable.sass'

droppable =
  classes: ->
    return false unless @webjet
    return
      'droppable': true
      'droppable-active': @droppableActive
      'droppable-shadow': @droppableShadow
      'droppable-before': @droppableActive and @droppableMode == 'before'
      'droppable-prepend': @droppableActive and @droppableMode == 'prepend'
      'droppable-append': @droppableActive and @droppableMode == 'append'
      'droppable-extended': !@provideWebjet and @droppableCounter > 0
      'droppable-dragenter': !@provideWebjet and @droppableCounter > 0
      'droppable-extended-new': @droppableCounter > 0

  styles: ->
    return false unless @droppableShadow
    return
      '--scale': @provideCanvas?.scale or 1


  listeners: ->
    return false if @isReadOnly
    return false if @isInTrash
    return
      dragover: @droppableDragover
      dragenter: @droppableDragenter
      dragleave: @droppableDragleave
      drop: @droppableDrop

  created: ->
    if IS_STAGE or IS_DEVELOPMENT
      @debouncedDroppableDisable = debounce (-> @droppableDisable()), 500
    else
      @debouncedDroppableDisable = debounce (-> @droppableDisable()), 200

  data: ->
    droppableActive: false
    droppableMode: false
    droppableCounter: 0
    droppableBeforeWebjet: false

  computed:
    isDroppable: -> true
    canDroppable: ->
      !@dragInProgress and !@isReadOnly
    droppableScale: ->
      if @provideScene
        @provideCanvas.scale
      else
        1
    droppableDragInProgress: ->
      return true if @dragInProgress
      if @provideWebjet
        @provideWebjet.droppableDragInProgress
      else
        false
    droppableShadow: ->
      result = false
      return @droppableActive
      if @droppableActive and @droppableMode != 'before'
        result = true
      else if @droppableCounter > 0
        for w in @childWebjetComponents
          if w.isDroppable and w.droppableActive and w.droppableMode == 'before'
            result = true
      else
        result = false
      result

    droppableTypes: -> @$store.getters['webjet/dataTransferTypes']

    droppableHasSides: ->
      p = this
      result = false
      while p=p.provideWebjet
        result = true if p.hasSides
      result


  methods:
    droppableDisable: ()->
      @droppableActive = false
      @droppableBeforeWebjet = false

    droppableClientTop: (el=false)->
      # without transform and with border
      el = @$el unless el
      if el.matches '.webjet.root'
        el.getBoundingClientRect().y
      else
        top = (el.offsetTop + el.offsetParent.clientTop) * @droppableScale
        top + @droppableClientTop(el.offsetParent)

    droppableClientLeft: (el=false)->
      # without transform and with border
      el = @$el unless el
      if el.matches '.webjet.root'
        el.getBoundingClientRect().x
      else
        left = (el.offsetLeft + el.offsetParent.clientLeft) * @droppableScale
        left + @droppableClientLeft(el.offsetParent)

    droppableClientRect: ()->
      border = @$el.clientTop * @droppableScale
      height = @$el.offsetHeight * @droppableScale
      width = @$el.offsetWidth * @droppableScale
      #x1 = @$el.getBoundingClientRect().x
      x1 = @droppableClientLeft()
      y1 = @droppableClientTop()
      x2 = x1 + width
      y2 = y1 + height
      { x1, y1, x2, y2, width, height }

    droppablePoint: (e)->
      rect = @droppableClientRect()
      top = (e.y - rect.y1) / @droppableScale
      bottom = rect.height / @droppableScale - top
      left = (e.x - rect.x1) / @droppableScale
      right = rect.width / @droppableScale - left
      width = rect.width / @droppableScale
      height = rect.height / @droppableScale
      { top, left, right, bottom, width, height }

    droppableChildWebjetTarget: (e)->
      childWebjetTarget = false
      lastWebjet = false
      cPath = e.composedPath()
      for node in cPath
        break if node == this.$el
        lastWebjet = node if node.classList.contains 'webjet'
      if lastWebjet
        childWebjetTarget = @childWebjetComponents.find (w)=>
          w.$el == lastWebjet
      childWebjetTarget



    droppableDisableOthers: ->
      # TODO: rewrite to disable all others
      p = this
      while p=p.provideWebjet
        p.droppableDisable() if p.isDroppable
      for w in @childWebjetComponents
        w.droppableDisable() if w.isDroppable


    droppableDragover: (e)->
      e.preventDefault() if @droppableDragInProgress
      return if @droppableDragInProgress or @isReadOnly
      return if e.defaultPrevented
      return if e.shiftKey
      #type = e.dataTransfer.types[0]
      #return unless type
      type = undefined
      for t in e.dataTransfer.types
        if t.split('/')[0] == 'webjets' or t in @$store.getters['webjet/dataTransferTypes']
          type = t
          break
      #if type.split('/')[0] == 'webjets' or type in @droppableTypes
      if type
        if !@isContainer
          return if @provideWebjet and !@provideWebjet.isContainer
          return if type == 'create/list'
          if type.split('/')[0] == 'webjets'
            transferWebjets = decodeDataTransfer type.substring(8)
            return if transferWebjets.canDropOnlyOnCanvas
            return if transferWebjets.hasContainer
        { top, left, right, bottom } = @droppablePoint(e)
        zone = 20
        if (left < zone or right < zone) and @droppableHasSides
          return
        zone = 10 unless @isExpanded

        if bottom < zone and @provideWebjet?.canDroppable and !@hasChilds
          if @provideWebjet.provideWebjet and @$el.matches(':last-child')
            return

        if top < zone and @provideWebjet and @provideWebjet.canDroppable
          return

        else if bottom <= zone and @hasChilds and @isExpanded
          @droppableMode = 'append'
        else
          @droppableMode = 'prepend'

        childWebjetTarget = @droppableChildWebjetTarget(e)
        if childWebjetTarget and @droppableMode != 'append'
          @droppableMode = 'before'
          @droppableBeforeWebjet = childWebjetTarget
          for w in @childWebjetComponents
            if w == @droppableBeforeWebjet
              w.$el.classList.add 'droppable-before-target'
            else
              w.$el.classList.remove 'droppable-before-target'

        e.dropInWebjet = true
        e.dropInWebjetWidth = @$el.offsetWidth
        e.preventDefault()
        @droppableDisableOthers()
        @droppableActive = true
        @debouncedDroppableDisable()

    droppableDropConnectionData: -> {}

    droppableDrop: (e)->
      @droppableResetCounter()
      return if e.defaultPrevented
      e.preventDefault() if @droppableDragInProgress
      return unless @droppableActive
      return if @droppableDragInProgress or @isReadOnly
      #type = e.dataTransfer.types[0]
      type = undefined
      for t in e.dataTransfer.types
        if t.split('/')[0] == 'webjets' or t in @$store.getters['webjet/dataTransferTypes']
          type = t
          break
      return unless type
      if type=='Files' and not (await @provideApp.checkFileSizeLimits e.dataTransfer.files, @provideBoardSrc)
        e.preventDefault()
        return
      dest = @webjet.$path
      #dest = @provideWebjet.webjet.$path if @droppableMode == 'before'
      before = true
      before = false if @droppableMode == 'append'
      #if @droppableMode == 'before' and Number.isFinite @connection.sortKey
      #  before = @connection.sortKey
      if @droppableMode == 'before' and @droppableBeforeWebjet
        if Number.isFinite @droppableBeforeWebjet.connection.sortKey
          before = @droppableBeforeWebjet.connection.sortKey
      data = @droppableDropConnectionData()
      if type.split('/')[0] == 'webjets'
        transferWebjets = decodeDataTransfer type.substring(8)
        return if transferWebjets.canDropOnlyOnCanvas
        e.preventDefault()
        @droppableResetCounter()
        mode = transferWebjets.mode
        items = transferWebjets.items.map (tw)-> { path: tw.path }
        if @droppableMode == 'prepend'
          items = items.reverse()
        flat = !@isContainer
        mode = 'copymove' if mode == 'move' and @provideBoardSrc != transferWebjets.boardSrc
        results = await @$store.dispatch 'webjet/transfer', { dest, items, before, mode, data, flat, boardPath: @provideBoardSrc }
        if mode == 'copymove'
          @$store.dispatch 'undoredo/reset'
        if mode == 'copy'
          @provideCanvas.selectCreatedWebjets results
      else if type in @$store.getters['webjet/dataTransferTypes']
        e.preventDefault()
        @droppableResetCounter()
        results = await @$store.dispatch 'webjet/dataTransfer', { dest, before, data, dataTransfer: e.dataTransfer, boardPath: @provideBoardSrc }
        @provideCanvas.selectCreatedWebjets results
      return

    droppableResetCounter: ->
      @droppableCounter = 0
      if @provideWebjet and @provideWebjet.isDroppable
        @provideWebjet.droppableResetCounter()

    droppableDragenter: (e)->
      @droppableCounter += 1
      e.preventDefault()
      return
    droppableDragleave: (e)->
      @droppableCounter -= 1
      e.preventDefault()
      return

export default droppable
export { droppable }
