import Vue from 'vue'
import store from '@/store'
import { io } from 'socket.io-client'

if IS_PRODUCTION and !IS_STAGE
  host = 'site.app.weje.io'
else
  host = 'site.stage.weje.io'
host = 'http://localhost:3902' if FLAG.WS_SITE_LOCAL

wsSite = new Vue
  data: ->
    siteId: false
    storeLoaded: false
    site: { loading: true }

  created: ->
    setTimeout => @storeLoaded = true

  computed:
    uid: -> (store or @storeLoaded) and store.state.uid
    scale: ->
      return unless @siteId
      result = @firebind('/system/config')?.wsSiteServers
      result = 1 if !result and @firebind('/system/config').$ready
      result
    boardId: ->
      return unless @siteId
      wejeRecord = @firebind "/wejes/#{@siteId}/board"
      if wejeRecord.$ready
        return wejeRecord.$value
      else
        false
    host: ->
      return unless @scale
      return unless @boardId
      if host.startsWith 'http'
        return host
      server = (@hashCode(@boardId) % @scale)
      "https://ws#{server}.#{host}"

  methods:

    hashCode: (str)->
      str = String str
      hash = 0
      return hash if str.length == 0
      for v, i in str
        chr   = str.charCodeAt(i)
        hash  = ((hash << 5) - hash) + chr
        hash |= 0
      Math.abs hash

    connect: ->
      if @ws
        console.log 'disconnect from old site websocket'
        @ws.disconnect()
        @resetData()
      if @uid and @siteId and @host
        console.log 'connecting to site websocket'
        @ws = io @host, { autoConnect: false, transports: ["websocket"] }
        @ws.on 'data', (data)=> @ondata(data)
        @ws.on 'connect', ()=> @onreconnect()
        @ws.auth = { uid: @uid, siteId: @siteId }
        @ws.connect()

    onreconnect: ->
      #console.log 'reconnect'

    ondata: (data)->
      console.log 'data from site websocket', data
      @site = data.site

    resetData: ->
      @ws = false
      @site = { loading: true }

  watch:
    host: ->
      @connect()
    siteId: ->
      @connect()
    uid: ->
      @connect()


window.devWsSite = wsSite
export default wsSite
