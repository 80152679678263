











import SidebarTab from './SidebarTab'
import InventoryTab from './inventory/InventoryTab'
import { DRAGTOUCH_THRESHOLD, DRAGTOUCH_TIMEOUT } from '@/utils'

export default
  name: 'Sidebar'
  inject: ['provideCanvas']
  provide: ->
    provideSidebar: this
  components: { SidebarTab, InventoryTab }
  data: ->
    components: {}
    activeTab: false
    swipe: false
    skipAnimation: false
    swipeStartX: 0
    swipeX: 0
    #x: 0
    mode: 'hide' # icons full
    #mode: 'icons'
    lastX: 0
    animate: { startX: 0, active: false, timestamp: false, x: 0 }
  mounted: ->
    @provideCanvas.$el.addEventListener 'touchstart', @canvasTouchstart, true
    @provideCanvas.$el.addEventListener 'touchmove', @canvasTouchmove, true
    @provideCanvas.$el.addEventListener 'touchend', @canvasTouchend, true
    if sessionStorage['inventory_open']
      @withoutAnimation =>
        @open 'inventory'
  beforeDestroy: ->
    @provideCanvas.$el.removeEventListener 'touchstart', @canvasTouchstart, true
    @provideCanvas.$el.removeEventListener 'touchmove', @canvasTouchmove, true
    @provideCanvas.$el.removeEventListener 'touchend', @canvasTouchend, true
  computed:
    isAnonymousUser: ->
      @$store.state.isAnonymousUser
    userCanEdit: ->
      @provideCanvas.userCanEdit
    showTrash: ->
      return false unless @userCanEdit
      !@provideCanvas.isTrashEmpty
    showSites: ->
      @userCanEdit and @provideCanvas.hasWejeSites
    component: ->
      if @activeTab
        @components[@activeTab]
      else
        false
    allWidth: ->
      @$vuetify.breakpoint.width
    currentMode: ->
      if @mode == 'hide' and @allWidth > 700
        'icons'
      else
        @mode
    x: ->
      # actualize mode
      #if @$vuetify.breakpoint.width >= 500
      #  mode = 'icons' if mode == 'hide'
      #  mode = 'full'
      mode = @currentMode
      if @allWidth >= 500
        if mode == 'hide'
          result = 0
        else if mode == 'icons'
          result = -88
        else if mode == 'full'
          result = -450
      else
        if mode == 'hide'
          result = 0
        else if mode == 'icons'
          result = -88
        else
          result = -@allWidth
      result += @swipeX - @swipeStartX if @swipe
      maxLeft = -450
      maxLeft = 10 - @allWidth if @allWidth < 500
      maxLeft -= 20 #?
      result = maxLeft if result < maxLeft
      result

    currentX: ->
      result = @x
      result = @animate.x if @animate.active
      result

    styles: ->
      return
        transform: "translateX(#{@currentX}px)"

    isInventoryOpen: ->
      @mode == 'full' and @activeTab == 'inventory'

    smallScreen: ->
      @provideCanvas.smallScreen
  watch:
    x: (value, oldValue)->
      if !@activeTab and @x < -80 and @components['inventory']
        @activeTab = 'inventory'
      return if @skipAnimation
      unless @swipe
        unless @animate.active
          requestAnimationFrame @animateTick
        @animate.startX = oldValue
        @animate.active = true
        @animate.timestamp = Date.now()
        @swipeStartX = 0
        @swipeX = 0

    mode: ->
      boardEl = document.querySelector '#board-page'
      if @mode == 'full' or @mode == 'mini' or @mode == 'icons'
        boardEl.classList.add 'sidebar-open'
      else
        boardEl.classList.remove 'sidebar-open'
      if @mode == 'full'
        boardEl.classList.add 'sidebar-open-full'
      else
        boardEl.classList.remove 'sidebar-open-full'
      if @mode == 'icons'
        boardEl.classList.add 'sidebar-open-icons'
      else
        boardEl.classList.remove 'sidebar-open-icons'

    isInventoryOpen: (v)->
      if v and @allWidth > 700
        sessionStorage['inventory_open'] = true
      else
        sessionStorage.removeItem 'inventory_open'

  methods:
    withoutAnimation: (f)->
      @skipAnimation = true
      f()
      @$nextTick => @skipAnimation = false
    dragover: (e)->
      e.scale = 1
      e.dragOverSidebar = true
      unless e.defaultPrevented
        e.preventDefault()
        e.dataTransfer.dropEffect = 'none'
    registerTab: (id, component)->
      @$set @components, id, component
    animateTick: ()->
      #console.log 'animate tick'
      duration = 300
      dt = Date.now() - @animate.timestamp
      dt = duration if dt > duration
      progress = dt / duration
      @animate.x = @animate.startX + (@x - @animate.startX) * progress
      #console.log @animate.x, @animate.startX, @x, progress
      if progress == 1
        @animate.active = false
      else
        requestAnimationFrame @animateTick

    touchstart: (e)->
      # TODO: detect scroll
      if !@swipe and e.touches?.length == 1
        @touchstartTimestamp = new Date().getTime()
        @swipe = true
        @swipeStartX = e.touches[0].clientX
        @swipeStartY = e.touches[0].clientY
        @detectWay = false
        @scroll = false
        #@lastX = @swipeStartX
        @lastY = @swipeStartY
        @swipeX = e.touches[0].clientX
        #e.preventDefault()
        e.stopPropagation()
    canvasTouchstart: (e)->
      #console.log 'touchstart'
      return if e.target.matches('.canvas-sidebar *')
      if e.touches?.length == 1 and e.touches[0].clientX > (@$vuetify.breakpoint.width - 40)
        return # !!! Disable right 40px logic
        @touchstartTimestamp = 0
        @swipe = true
        @swipeStartX = e.touches[0].clientX
        @swipeX = e.touches[0].clientX
        e.preventDefault()
        e.stopPropagation()
    canvasTouchmove: (e)->
      target = e.target
      while target && !target.classList.contains('webjet') && !target.classList.contains('canvas')
        target = target.parentElement
      if target.classList.contains('webjet') && target.classList.contains('drag-in-progress')
        @swipe = false
        @scroll = false
        e.preventDefault() # test fix to disable reload gesture and scroll conflict while dragging webjets
        return
      if @touchstartTimestamp and (new Date().getTime() - @touchstartTimestamp) < DRAGTOUCH_TIMEOUT
        distance = parseInt Math.sqrt(
          Math.pow(e.touches[0].clientX - @swipeStartX, 2) + Math.pow(e.touches[0].clientY - @swipeStartY, 2)
        ), 10
        if distance < DRAGTOUCH_THRESHOLD
          e.preventDefault()
        else
          @touchstartTimestamp = 0
      if !@detectWay
        if @swipe and e.target.matches('.canvas-sidebar .sidebar-content .content, .canvas-sidebar .sidebar-content .content *')
          if (Math.abs(e.touches[0].clientY - @swipeStartY) / Math.abs(e.touches[0].clientX - @swipeStartX)) > Math.tan(Math.PI/180*20)
            @swipe = false
            @scroll = true
        @detectWay = true
      if @swipe and e.touches?.length == 1
        @swipeX = e.touches[0].clientX
        e.preventDefault() # to prevent system/browser' swipes, e.g. swipe right is "back", which we do not allow at all, and we use it to hide sidebar
      if @scroll
        # Just do nothing here! Do not prevent default scroll! Default scroll is "reactive", and manual one is not.
        # FIXME: maybe remove unnecessary code related to scroll completely?
        #e.preventDefault()
        #console.log e
        dy = e.touches[0].clientY - @lastY
        #console.log dy, @$refs.content.$el
        #@$refs.content.$el.querySelector('.content').scrollTop -= dy
        @lastY = e.touches[0].clientY
    canvasTouchend: (e)->
      @scroll = false
      if @swipe and @detectWay
        @swipe = false
        #@x += @swipeX - @swipeStartX
        x = @x + @swipeX - @swipeStartX
        @xToMode(x)
        #@swipe = false
      else
        @swipe = false

    #contentTouchmove: (e)->
    #  if !@detectWay
    #    console.log 'dx', Math.abs(e.touches[0].clientX - @swipeStartX)
    #    console.log 'dy', Math.abs(e.touches[0].clientY - @swipeStartY)
    #    console.log (Math.abs(e.touches[0].clientY - @swipeStartY) / Math.abs(e.touches[0].clientX - @swipeStartX))
    #    #if Math.abs(e.touches[0].clientX - @swipeStartX) < Math.abs(e.touches[0].clientY - @swipeStartY)
    #    if (Math.abs(e.touches[0].clientY - @swipeStartY) / Math.abs(e.touches[0].clientX - @swipeStartX)) > Math.tan(Math.PI/180*10)
    #      @swipe = false
    #    @detectWay = true
    #  unless @swipe
    #    e.stopPropagation()
    xToMode: (x)->
      width = @allWidth
      width = 450 if width > 500
      right = - x
      if @mode == 'hide'
        if right > 0 and right < 100
          @mode = 'icons'
        else if right >= 100
          @mode = 'full'

      else if @mode == 'icons'
        if right > 88
          @mode = 'full'
        else
          @mode = 'hide'

      else
        if right < (width - 50)
          @mode = 'hide'
      #if x < 50
      #  @mode = 'hide'
      #else if x
    open: (tab)->
      @activeTab = tab
      @mode = 'full'
    close: ()->
      @mode = 'hide'
    toggle: (tab)->
      if @mode != 'full' or @activeTab != tab
        @open tab
      else
        @close()

    hotkeyBlocker: (e) ->
      # allow undo/redo
      if e.ctrlKey && e.keyCode in [ 89, 90 ]
        return
      # block other hotkeys
      e.stopPropagation()
      # block ctrl +/-/0
      if e.ctrlKey && e.keyCode in [ 187, 189, 48, 107, 109, 45, 43, 96 ]
        e.preventDefault()

