





























import Anchor from "./Anchor"
import SidebarLeftContent from '../SidebarLeftContent'
import { decodeDataTransfer, encodeDataTransfer, generateSort, firebind, anchorSortKey, generateAnchorSort } from '@/utils'
export default
  name: 'NavigationContent'
  inject: ['provideCanvas','provideSidebarLeft','provideBoardSrc']
  components: { SidebarLeftContent, Anchor }
  data: ->
    dragItemSrc: null
    dragOverAnchorSrc: null
    droppableCounter: 0
  computed:
    anchors: ->
      @provideSidebarLeft.anchors
    activeAnchors: ->
      @provideSidebarLeft.activeAnchors
    dragActive: ->
      @dragOverAnchorSrc and @droppableCounter > 0
    activeDragOverAnchor: ->
      return false unless @dragOverAnchorSrc
      return false unless @droppableCounter > 0
      # console.log 'activeDragOverAnchor', @droppableCounter
      @dragOverAnchorSrc

    noAnchorOnDragOverLastIndex: ->
      if @droppableCounter > 0 and !@dragOverAnchorSrc
        # console.log 'noAnchorOnDragOverLastIndex', @droppableCounter
        return @anchors.length - 1
      else
        return false
  methods:
    # anchorSortKey: (src) ->
    #   w = firebind(src)
    #   console.log w, src
    #   sortKey = w.anchorSortKey
    #   unless sortKey
    #     sortKey = Date.parse(w.meta.createDate)
    #   return sortKey
    getAnchorComponent: (e) ->
      cPath = e.composedPath()
      # console.log cPath
      anchor = null
      for node in cPath
        anchor = node if node?.classList?.contains 'anchor-navigation'
        break if anchor
      return false unless anchor
      anchors = []
      anchorComponent = null
      for a in @anchors
        break if anchorComponent
        # console.log @$refs
        if @$refs[a.$path][0].$el == anchor
          anchorComponent = @$refs[a.$path][0]
      # console.log anchors
      # anchorComponent = anchors.find (a)=>
      #   # console.log a[0]
      #   a.$el == anchor
      anchorComponent


    dragStart: (e)->
      # console.log 'dragStart111111111', @getAnchorComponent(e).src
      # window.addEventListener 'drop', @drop, {capture: true}
      @droppableCounter = 0
      anchor = @getAnchorComponent(e)
      if anchor
        @dragItemSrc = anchor.webjet.src
      else
        @dragItemSrc = null
      data =
        path: @getAnchorComponent(e).src
      e.dataTransfer.clearData()
      e.dataTransfer.setData "presentation/nav/#{encodeDataTransfer(data)}", {}
      # @getAnchorComponent(e)
      # type = undefined
      # for t in e.dataTransfer.types
      #   if t.split('/')[0] == 'presentation' and t.split('/')[1]
      #     type = t
      #     break
      # if type
      #   @dragItem = decodeDataTransfer type.substring(17)
      # console.log @dragItem
    dragOver: (e)->
      # console.log 'dragOver', @droppableCounter
      dragOverComponent = @getAnchorComponent(e)
      if dragOverComponent
        @dragOverAnchorSrc = dragOverComponent.src
      else
       @dragOverAnchorSrc = null

      e.preventDefault()

      # type = undefined
      # for t in e.dataTransfer.types
      #   if t.split('/')[0] == 'presentation' and t.split('/')[1]
      #     type = t
      #     break
      # if type
      #   @dragItem = decodeDataTransfer type.substring(17)
      # else
      #   @dragItem = null
    dragLeave: (e)->
      # console.log 'dragLeave'
      @droppableDragleave(e)
      # console.log @droppableCounter
    dragEnter: (e)->
      # console.log 'dragEnter'
      @droppableDragenter(e)
      # console.log @droppableCounter
    drop: (e)->

      dragPath = null
      type = undefined
      for t in e.dataTransfer.types
        if t.split('/')[0] == 'presentation' and t.split('/')[1]
          type = t
          break
      if type
        data = decodeDataTransfer type.substring(17)
        dragPath = data.path

      dragConnection = @anchors.find (a)->
        dragPath == a.$path
      # dragConnection = @anchors[dragConnectionIndex]
      # console.log 'dragPath',dragConnection
      return unless dragConnection
      prevDragOverConnection = null
      dragOverAnchor = @getAnchorComponent(e)
      beforeIndex = 0
      dragOverAnchorSortKey = null
      if dragOverAnchor
        dragOverAnchorSortKey = anchorSortKey(dragOverAnchor.connection)
        beforeIndex = @anchors.findIndex (a)->
          a.$path == dragOverAnchor.connection.$path
      else
        dragOverAnchorSortKey = true
      # dragAfterConnection = @anchors[beforeIndex]
      if beforeIndex > 0
        prevDragOverConnection = @anchors[beforeIndex - 1]

      drpoAnchorSortKey = anchorSortKey(dragConnection)



      # console.log 'beforeIndex', @anchors, dragOverAnchor.connection
      sort = generateAnchorSort(dragConnection,dragOverAnchorSortKey,prevDragOverConnection)
      # console.log 'drop',  dragConnection,dragOverAnchorSortKey,prevDragOverConnection
      @$store.dispatch 'webjet/setAnchorSortKey', { path: dragPath, anchorSortKey: sort.anchorSortKey}
      @droppableCounter = 0
      @dragItemSrc = null
      @dragOverAnchorSrc = null
      # window.removeEventListener 'drop', @drop
    droppableDragenter: (e)->
      @droppableCounter += 1
      e.preventDefault()
      return
    droppableDragleave: (e)->
      @droppableCounter -= 1
      e.preventDefault()
      return
    copyLink: ->
      if @provideBoardSrc?.includes '/boards/'
        link = "#{window.location.origin}/b/#{@$route.params.boardId}?p=1"
      else
        link = "#{window.location.origin}/b/#{@$route.params.boardId}/#{@provideCanvas.boardOwner}?p=1"
      if window.clipboardData
        # should work in Internet Explorer
        window.clipboardData.setData 'Text', link
      else
        # all other browsers
        navigator.clipboard.writeText link
      @provideCanvas.showCaution
        message: @$t('canvas.message.linkCopied')
        loader: false
    startPresentation: ->
      return unless @provideCanvas.anchors.length
      src = @anchors[0].src.split('/').slice(-2).join('/')
      @$router.push path: @$router.path, query: {p: '1'}
    createAnchor: (e,type)->
      if type == 'iframe'
        title = @$t('common.frame') + " #{@anchors.length + 1}"
        offsetY = 120
        offsetX = 200
      else
        title = @$t('common.bookmark') + " #{@anchors.length + 1}"
        offsetY = 20
        offsetX = 60
      connection = {
        coordinates: @provideCanvas.getEmptyCoordinates
          x: @provideCanvas.sceneX + @provideCanvas.sceneWidth / 2 - offsetX
          y: @provideCanvas.sceneY + @provideCanvas.sceneHeight / 2 - offsetY
      }
      data = {
          title: title
          type: type
        }
      if type == 'iframe'
        size = {
            size:
              width: 400
              height: 240
          }
        connection = Object.assign connection, size

      hideInPresentation = false
      if type == 'bookmark'
        hideInPresentation = true

      result = await @$store.dispatch 'webjet/create',
        category: 'anchor'
        dest: @provideCanvas.data.$path
        boardPath: @provideCanvas.data.$path
        webjet:
          data:
            title: title
            type: type
            hideInPresentation: hideInPresentation
        connection: connection
      if @$vuetify.breakpoint.width < 800
        @provideCanvas.$refs.sidebar.close()
      @provideCanvas.selectCreatedWebjets [ result ], true
