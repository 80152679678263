export default
  computed:
    isNew: ->
      @inventoryNew?.$value

  firebind:
    inventoryNew: -> 'inventory/inventoryNew'

  methods:
    resetNew: ->
      @$store.dispatch 'inventory/resetNew'
