






import { mdiChevronDown } from '@mdi/js'
export default
  name: 'WExpander'
  inject: ['provideWebjet']
  data: ->
    icon: mdiChevronDown
  computed:
    show: ->
      !@provideWebjet.isReadOnly
    isExpanded: ->
      @provideWebjet.isExpanded
  methods:
    toggle: ->
      @provideWebjet.toggleExpand()

