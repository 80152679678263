import { getRef } from '@/utils'
import { firebind } from '@/utils'
import { nowFirebase } from '@/utils'

export default
  CREATE: (_, { team, project, uid, title })->
    getRef('/teamAccess').child(team).child('roles').child(uid).set 'owner'
    getRef('/teamAccess').child(team).child('openProjects').child(project).set 'edit'
    getRef('/projects').child(project).child('team').set team
    getRef('/projects').child(project).child('title').set 'Project 1'
    if title
      getRef('/teams').child(team).child('title').set title
    getRef('/teams').child(team).child('projects').child(project).set
      status: 'ok'
      sortKey: nowFirebase()
    getRef('/data').child(uid).child('teams').child(team).set
      status: 'ok'
      sortKey: nowFirebase()

  DELETE: (_, { team })->
    getRef('/teams').child(team).set null
    getRef('/teamAccess').child(team).set null

  UPDATE_TEAM_ICON: (_, { path, fileName, url })->
    getRef(path).child('icon').set url
    getRef(path).child('iconFileName').set fileName

  UPDATE_TEAM_TITLE: (_, { path, title}) ->
    getRef(path).child('title').set title

  UPDATE_BOARD_ROLE: (_,{ teamId, boardId, userId, role })->
    getRef('/teamAccess').child(teamId).child('boards').child(userId).child(boardId).set role

  INVITE_USER_TO_TEAM: (_,{ teamId, userId })->
    getRef('/data').child(userId).child('teams').child(teamId).set
      status: 'pending'
      sortKey: nowFirebase()

  REMOVE_USER_FROM_BOARD: (_,{ teamId, boardId, userId })->
    getRef('/teamAccess').child(teamId).child('boards').child(userId).child(boardId).set null

  UPDATE_USER_TEAM_ROLE: (_,{ teamId, userId, role  })->
    getRef('/teamAccess').child(teamId).child('roles').child(userId).set role

  REMOVE_USER_FROM_TEAM: (_,{ teamId, userId  })->
    getRef('/teamAccess').child(teamId).child('roles').child(userId).set null

  LEAVE_TEAM: (_,{ teamId, userId })->
    getRef('/teamAccess').child(teamId).child('boards').child(userId).set null
    getRef('/teamAccess').child(teamId).child('roles').child(userId).set null
    getRef('/data').child(userId).child('teams').child(teamId).set null

  WRITE_EXPAND_TEAM_TASK: (_,{ teamId, userId, size })->
    getRef('/queue/tasks').push
      _state: 'stripe_expandteam'
      userId: userId
      subject: teamId
      size: size
      createDate: nowFirebase()
