




















export default
  name: 'RelationArrowTools'
  inject: ['provideCanvas']
  data: ->
    rect: null
  computed:
    relation: ->
      @$store.getters['webjet/relation/selected']
    point: ->
      @provideCanvas.arrowToolsPoint
    reversMenu: ->
      revers = @relation.pointsOrderRevers()
      point = @point
      if @point == 1
        return true unless revers
        return false
      else
        return true if revers
        return false
    relationSrc: ->
      @relation.src
    toolsPosition: ->
      relationCentralPoint = @relation.pointPosition(@point)
      scale = @provideCanvas.scale
      cp = @provideCanvas.position
      cz = @provideCanvas.zoom
      rcpX =  relationCentralPoint.x * scale + cp.x + cz.dx
      rcpY =  relationCentralPoint.y * scale + cp.y + cz.dy - 60
      rcpX = "#{rcpX}px"
      rcpY = "#{rcpY}px"
      return { left: rcpX, top: rcpY, bottom: 'auto' }

  methods:

    setPointStyle: (style) ->
      @$store.dispatch 'webjet/relation/setPointStyle',
        path: @relationSrc
        point: @point
        style: style
      @$set @provideCanvas.lastRelationConfig, '2', {style: style}
