








import ContextBtn from './ContextBtn'
export default
  name: 'Title'
  components: { ContextBtn }
  priority: 100

  if: (selection)->
    selection.length == 1 and selection[0].$options.name != 'WebjetShape' and selection[0].$options.name != 'WebjetPaint'

  computed:
    selection: -> @$store.getters['webjet/selected']

  methods:
    action: ->
      console.log @selection[0].src
      @selection[0].headEditTitle = true
      # focus title edit
