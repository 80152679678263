import state from '@/store/state'

export getBeforeUnloadHandler = ->
  return (e) ->
    if FLAG.BEFORE_UNLOAD and !state.signOut
      e.preventDefault()
      e.returnValue = 'Changes you made may not be saved'
      # text has no meaning, as all current browsers ignore it, but some still require a string, so no i18n here
      # https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
      return e.returnValue
