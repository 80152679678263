import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store"
import routes from './routes'
import { firebind } from '@/utils'

Vue.use VueRouter

router = new VueRouter
  mode: 'history'
  base: process.env.BASE_URL
  routes: routes

router.beforeEach (to, from, next)->
  if !store.state.needAuth && to.name in [ 'Home', 'Boards' ] && to.query.invitecode?
    next { name: 'Wait', query: to.query }
  else if to.meta.requireNoUser and !store.state.needAuth
    next { name: 'Home' }
  else
    next()

# save dashboard tab (category) for show on open main page
router.beforeEach (to, from, next)->
  if to.name == 'Boards'
    if from.name == 'Boards'
      lastCategory = to.params.category
      lastCategory = '' if to.params.category == 'trash'
      localStorage.lastDashboardBoardsCategory = lastCategory
    else
      if to.params.category == ''
        lastCategory = localStorage.getItem 'lastDashboardBoardsCategory'
        lastCategory = '' if lastCategory == 'undefined'
        if lastCategory
          next { name: 'Boards', params: { category: lastCategory } }
  next()

# save last opened board in team
lastTeamBoards = {}
router.beforeEach (to, from, next)->
  if from.name == 'Board'
    # in case when board was deleted
    boardAccess = store.getters.boardAccess from.params.boardId
    teamId = store.getters.currentTeamId
    delete lastTeamBoards[teamId] if boardAccess.deleted

  if to.name == 'Board'
    teamFB = firebind "/boards/#{to.params.boardId}/team"
    boardAccess = store.getters.boardAccess to.params.boardId
    if teamFB?.$ready and !boardAccess.loading and !boardAccess.denied
      team = teamFB.$value
      lastTeamBoards[team] = to.params.boardId
  if to.name == 'Team'
    if lastTeamBoards[to.params.teamId]
      next { name: 'Board', params: { boardId: lastTeamBoards[to.params.teamId] } }

    if FLAG.WEJE_IFRAME_NEW and to.path == '/'
      # redirect when route with name 'Team' does not exist
      next('/Error404')

  next()


window.devRouter = router if IS_DEVELOPMENT
export default router
