















































































import DialogSlide from '@/components/DialogSlide'
export default
  name: 'DrawOnCanvasTools'
  components: { DialogSlide }
  inject: ['provideCanvas']
  data: ->
    erasersHovered: false
    eraserTimeout: null
  computed:
    colorsForPaint: ->
      return [
          '#BBBBBB', '#555555', '#0A0A0A'
          '#FFA1A1', '#f74d40', '#A90808'
          '#FFED4F', '#FFCC00', '#D8930D'
          '#97FA75', '#3BE400', '#37A312'
          '#89F1FF', '#00DDFB', '#06C0DA'
          '#98A2FF', '#6254FF', '#2A1EB2'
          '#E1A3FF', '#B326F5', '#7808AD'
        ]
    drawComponent: ->
      @provideCanvas.$refs.scene.$refs.DrawOnCanvas
    drawIsActive: ->
      @drawComponent.drawIsActive
    activeDrawType: ->
      @drawComponent.selectedUserType
    subSelectedUserType: ->
      @drawComponent.subSelectedUserType[@activeDrawType]
    activeUserDrawConfig: ->
      @drawComponent.userConfig[@activeDrawType][@subSelectedUserType]
    paintsLength: ->
      return false unless @drawComponent.paints
      Object.keys(@drawComponent.paints).length
    userConfig: ->
      @drawComponent.userConfig
    showPalette:
      get: -> @drawComponent.showPalette
      set: (val) -> @drawComponent.showPalette = val
    showAllPaint: ->
      @drawComponent.showAllPaint
    activeUserDrawConfigWidth:
      get: -> @activeUserDrawConfig.width
      set: (val) -> @$set @drawComponent.userConfig[@activeDrawType][@subSelectedUserType], 'width',val

    infoPoints: ->
      points = @drawComponent.drawToolsHelper.points
      size = JSON.stringify(points).length
      size = (size / 1024).toFixed(2)
      return
        points: points.length
        size: size
    infoPointsFiltred: ->
      points = @drawComponent.drawToolsHelper.pointsFiltred
      compressPoints = (points.length / @infoPoints.points * 100).toFixed(2)
      size = JSON.stringify(points).length
      size = (size / 1024).toFixed(2)
      compressPointsData = (+size / +@infoPoints.size *100).toFixed(2)
      return
        points: points.length
        size: size
        compressPoints: compressPoints
        compressPointsData: compressPointsData
    infoPointsFiltredRoundOnScale: ->
      points = @drawComponent.drawToolsHelper.pointsFiltredRoundOnScale
      compressPoints = (points.length / @infoPoints.points * 100).toFixed(2)
      size = JSON.stringify(points).length
      size = (size / 1024).toFixed(2)
      compressPointsData = (+size / +@infoPoints.size *100).toFixed(2)
      return
        points: points.length
        size: size
        compressPoints: compressPoints
        compressPointsData: compressPointsData
    infoPointsFiltredRelative: ->
      points = @drawComponent.drawToolsHelper.relativePoints
      compressPoints = (points.length / @infoPoints.points * 100).toFixed(2)
      size = JSON.stringify(points).length
      size = (size / 1024).toFixed(2)
      compressPointsData = (+size / +@infoPoints.size *100).toFixed(2)
      return
        points: points.length
        size: size
        compressPoints: compressPoints
        compressPointsData: compressPointsData
    infoPointsFlat: ->
      points = @drawComponent.drawToolsHelper.flatPoints
      compressPoints = (points.length / @infoPoints.points * 100).toFixed(2)
      size = JSON.stringify(points).length
      size = (size / 1024).toFixed(2)
      compressPointsData = (+size / +@infoPoints.size *100).toFixed(2)
      return
        points: points.length
        size: size
        compressPoints: compressPoints
        compressPointsData: compressPointsData

    infoShowPoints:
      get: -> @drawComponent.drawToolsHelper.showPoints
      set: (val) -> @$set @drawComponent.drawToolsHelper, 'showPoints',val
    infoShowSpace:
      get: -> @drawComponent.drawToolsHelper.showSpace
      set: (val) -> @$set @drawComponent.drawToolsHelper, 'showSpace',val
    infoFilterPoints:
      get: -> @drawComponent.drawToolsHelper.filterPoints
      set: (val) -> @$set @drawComponent.drawToolsHelper, 'filterPoints',val
    infoFilterPointsRoundOnScale:
      get: -> @drawComponent.drawToolsHelper.filterRoundOnScale
      set: (val) -> @$set @drawComponent.drawToolsHelper, 'filterRoundOnScale',val
    infoFilterRelative:
      get: -> @drawComponent.drawToolsHelper.filterRelativePoints
      set: (val) -> @$set @drawComponent.drawToolsHelper, 'filterRelativePoints',val



  methods:
    hideEreser: ->
      @erasersHovered = false
    mouseoverEreser: ->
      clearTimeout(@eraserTimeout)
      @eraserTimeout = null
      @erasersHovered = true
    mouseleaveEreser: ->
      @eraserTimeout = setTimeout (=> @hideEreser()), 50
    deleteAll: ->
      @drawComponent.deletePaint({},true)
      @selectDrawType('empty')
    showHideAllPaints: (val) ->
      @selectDrawType('empty')
      @drawComponent.showAllPaint = val
    selectDrawColor: (color) ->
      @$set @drawComponent.userConfig[@activeDrawType][@subSelectedUserType], 'color',color
    activeDraw: ->
      @drawComponent.drawIsActive = !@drawIsActive
    selectDrawType: (type,subType) ->
      @drawComponent.selectedUserType = type
      if @activeDrawType == type and @subSelectedUserType == subType
        @showPalette = !@showPalette
      else
        @showPalette = false
      @drawComponent.subSelectedUserType[type] = subType if subType


