








import SidebarObject from '../SidebarObject'
import { userDisplayName } from '@/utils'
export default
  name: 'MentionItem'
  components: {SidebarObject}
  inject: ['provideCanvas']
  props:
    src: String

  computed:
    referrerName: ->
      userDisplayName(@webjet?.data?.referrer).split(' ')[0]
    active: ->
      unless @webjet?.data.hasOwnProperty 'active'
        return true
      return @webjet?.data?.active
    isUserMentioned: ->
      return @webjet?.data?.mentioned.uid == @$store.state.uid

  methods:
    click: ->
      src = @webjet.$path.split('/').slice(-2).join('/')
      path = @$route.path
      @provideCanvas.goToWebjet(src)
      if @provideCanvas.smallScreen
        @provideCanvas.$refs.sidebarContent.openClose()

  firebind:
    connection: -> @src
    webjet: -> @connection.src
