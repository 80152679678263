
















































































































export default
  name: 'CreateWebjet'
  inject: ['provideApp', 'provideCanvas']
  computed:
    boardPath: -> @provideCanvas.data.$path
    selected: -> @provideCanvas.selected
  methods:
    selectionCentralize: -> @provideCanvas.selectionCentralize()

    createGraffiti: (e) ->
      type = 1
      type = 2 if @$vuetify.breakpoint.height > @$vuetify.breakpoint.width
      result = await @$store.dispatch 'webjet/create',
        category: '/webjets/content/webjets/graffiti'
        dest: @boardPath #dest
        boardPath: @boardPath
        webjet:
          data:
            sizeType: type
        connection:
          coordinates: @provideCanvas.getEmptyCoordinates
            x: @provideCanvas.sceneX + @provideCanvas.sceneWidth / 2 - 150
            y: @provideCanvas.sceneY + @provideCanvas.sceneHeight / 2 - 150
      @provideCanvas.selectCreatedWebjets [ result ], true

    createCard: (e)->
      #if @selected?.length == 1 and @selected[0].canDroppable and @selected[0].isExpanded
      #  dest = @selected[0].webjet.$path
      #  @selectionCentralize()
      #else
      #  dest = @boardPath
      result = await @$store.dispatch 'webjet/create',
        category: '/webjets/content/webjets/note'
        dest: @boardPath #dest
        boardPath: @boardPath
        connection:
          coordinates: @provideCanvas.getEmptyCoordinates
            x: @provideCanvas.sceneX + @provideCanvas.sceneWidth / 2 - 150
            y: @provideCanvas.sceneY + @provideCanvas.sceneHeight / 2 - 50
      @provideCanvas.selectCreatedWebjets [ result ], true

    createList: (e)->
      #if @selected?.length == 1 and @selected[0].canDroppable and @selected[0].isExpanded
      #  dest = @selected[0].webjet.$path
      #  @selectionCentralize()
      #else
      #  dest = @boardPath
      result = await @$store.dispatch 'webjet/create',
        category: '/webjets/containers/webjets/list'
        dest: @boardPath #dest
        boardPath: @boardPath
        connection:
          coordinates: @provideCanvas.getEmptyCoordinates
            x: @provideCanvas.sceneX + @provideCanvas.sceneWidth / 2 - 150
            y: @provideCanvas.sceneY + @provideCanvas.sceneHeight / 2 - 50
      @provideCanvas.selectCreatedWebjets [ result ], true
    createShape: (e, type, canTouch = true) ->
      unless canTouch
        return unless e.pointerType == 'mouse'
      result = await @$store.dispatch 'webjet/create',
        category: '/webjets/content/webjets/shape'
        dest: @boardPath #dest
        boardPath: @boardPath
        webjet:
          data:
            type: type
            text: ''
        connection:
          coordinates: @provideCanvas.getEmptyCoordinates
            x: @provideCanvas.sceneX + @provideCanvas.sceneWidth / 2 - 150
            y: @provideCanvas.sceneY + @provideCanvas.sceneHeight / 2 - 50
      @provideCanvas.selectCreatedWebjets [ result ], true
      @provideCanvas.lastShapeType = type

    createChecklist: (e)->
      result = await @$store.dispatch 'webjet/create',
        category: 'checklist'
        dest: @boardPath #dest
        boardPath: @boardPath
        connection:
          coordinates: @provideCanvas.getEmptyCoordinates
            x: @provideCanvas.sceneX + @provideCanvas.sceneWidth / 2 - 150
            y: @provideCanvas.sceneY + @provideCanvas.sceneHeight / 2 - 50
      @provideCanvas.selectCreatedWebjets [ result ], true

    openUpload: ->
      input = @$el.querySelector 'input.input-upload'
      if input
        input.click()
    processUpload: ->
      input = @$el.querySelector 'input.input-upload'
      if input?.files?.length > 0
        if not (await @provideApp.checkFileSizeLimits input.files, @boardPath)
          input.value = null
          return
        #if @selected?.length == 1 and @selected[0].canDroppable and @selected[0].isExpanded
        #  dest = @selected[0].webjet.$path
        #  @selectionCentralize()
        #else
        #  dest = @boardPath
        # Object passed as dataTransfer parameter should mimic ClipboardEvent.clipboardData and DragEvent.dataTransfer objects
        # in extent enough for webjet/dataTransfer operation to work. So far this operation uses types[0] and files array only.
        results = await @$store.dispatch 'webjet/dataTransfer',
          dest: @boardPath #dest
          boardPath: @boardPath
          dataTransfer:
            types: [ 'Files' ]
            files: input.files
          data:
            coordinates: @provideCanvas.getEmptyCoordinates
              x: @provideCanvas.sceneX + @provideCanvas.sceneWidth / 2 - 150
              y: @provideCanvas.sceneY + @provideCanvas.sceneHeight / 2 - 50
        input.value = null
        @provideCanvas.selectCreatedWebjets results, true
