


























































import WebjetInventory from '@/components/WebjetInventory'
import ContextMenu from '@/components/ContextMenu'
import InventoryTools from '@/canvas/sidebar/inventory/InventoryTools'
export default
  name:
    "Unsorted"
  components: { WebjetInventory, ContextMenu, InventoryTools }
  inject: ['provideApp']
  provide: ->
    provideBoardSrc: 'inventory'
  data: ->
    actual: true
    tileView: false

  computed:

    isRegisteredUser: ->
      @$store.state.isRegisteredUser
    isAnonymousUser: ->
      @$store.state.isAnonymousUser

    selection: -> @$store.getters['webjet/selected']
    showContextMenu: ->
      return false unless FLAG.NEW_CONTEXT_MENU
      return false unless @selection.length
      return false unless @selection[0].provideBoardSrc == 'inventory'
      true


