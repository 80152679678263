















import Logo from '@/components/Logo'
import { mdiLoginVariant } from '@mdi/js'

export default
  name: 'AnonymousForbidden'
  components: { Logo }
  data:->
    iconLogin: mdiLoginVariant
  methods:
    logout: ->
      @$store.dispatch 'signOut'
