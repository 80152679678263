# Analytics intergation

import store from '@/store'
import { userPlan } from '@/utils'
import i18n from '@/plugins/i18n'
import { isMobile, isMobileOrTablet } from '@/utils'

export sendAnalytics = (eventType, eventProperties, userProperties) ->
  return if IS_DEVELOPMENT and !FLAG.DEV_ANALYTICS
  setTimeout ->
    if IS_PRODUCTION and !IS_STAGE
      url = 'https://api.amplitude.com/2/httpapi'
      apiKey = 'afe531cebc6e261ec41642bd9e9bc3bb'
    else
      url = 'https://api.amplitude.com/2/httpapi'
      apiKey = 'b6efc2f3bcf45e2476d1d8e8495d7e33'

    if isMobile
      device_type = 'mobile'
    else if isMobileOrTablet
      device_type = 'tablet'
    else
      device_type = 'desktop'

    event =
      event_type: eventType
      user_id: store.state.uid.substring(0, 15)
      event_properties: eventProperties
      user_properties: Object.assign {}, userProperties,
        plan: userPlan(store.state.uid)
        language: i18n.locale
        device_type: device_type
        screen_width: window.outerWidth
        screen_height: window.outerHeight
        browser: navigator.userAgent
      language: i18n.locale
      device_family: device_type
      platform: navigator.userAgent

    data =
      api_key: apiKey
      events: [ event ]

    if FLAG.DEV_ANALYTICS
      console.log 'sending analytics event:', event

    fetch(url,
      method: 'POST'
      headers:
        'Content-Type': 'application/json'
      body: JSON.stringify data
    ).then( (response) =>
      if FLAG.DEV_ANALYTICS
        console.log 'analytics response:', response
        return response.text()
    ).then( (text) ->
      if FLAG.DEV_ANALYTICS
        console.log 'analytics response text:', text
    ).catch( (error) =>
      if IS_DEVELOPMENT or IS_STAGE
        console.error 'analytics error:', error
    )
