import Vue from 'vue'
import store from '@/store'
import { wsBoard } from '@/websockets'
import audioStart from '@/assets/audio/voice-start.mp3'
import audioEnd from '@/assets/audio/voice-end.mp3'

class OpenviduClient
  constructor: (url) ->
    @url = url
    @state = Vue.observable
      session: null
      channel: null
      stream: false
      publisher: null
      subscribers: []
      audioDevices: null
      activeDevice: null
      isMuted: false
      isTalking: false
      isCanceled: false
      audioStart: new Audio audioStart
      audioEnd: new Audio audioEnd

  setKey: (value)->
    return if @state.key == value
    @state.key = value

  joinSession: (channelName) ->
    return if @state.channel or @state.session
    @initSession channelName

    { token } = await @getToken(@state.key + '_' + channelName)

    await @state.session.connect token, userId: store.state.uid
    @initPublisher()
    @state.audioStart.play()

  initSession: (channelName) ->
    { OpenVidu } = await import(### webpackChunkName: "openvidu-browser" ###'openvidu-browser')

    @openvidu = new OpenVidu()
    @state.session = @openvidu.initSession()
    @state.channel = channelName
    @getAudioDevices()

    @state.session.on 'streamCreated', (event) =>
      @initSubscriber event

    @state.session.on 'streamDestroyed', (event) =>
      subscriberId = JSON.parse(event.stream.connection.data).userId
      @state.subscribers = @state.subscribers.filter (s) -> s.userId != subscriberId
      @state.audioEnd.play()

    @state.session.on 'exception', (exception) ->
      console.warn exception

  leaveSession: () ->
    if !@state.stream
      @state.isCanceled = true
    else
      @state.session.disconnect()
      wsBoard.share 'audiochannel', null
      @state.audioEnd.play()
      @resetState()

  initPublisher: () ->
    publisher = @openvidu.initPublisher('video-' + @state.channel,
      audioSource: @state.activeDevice
      videoSource: null
      publishVideo: true
      publishAudio: !@state.isMuted
      insertMode: 'APPEND'
    )

    publisher.on 'videoElementCreated', (event) =>
      wsBoard.share 'audiochannel', user: store.state.uid, channel: @state.channel

    publisher.on 'publisherStartSpeaking', (event) =>
      @state.isTalking = true

    publisher.on 'publisherStopSpeaking', (event) =>
      @state.isTalking = false

    publisher.on 'streamCreated', () =>
      if @state.isCanceled
        @state.stream = true
        @state.isCanceled = false
        @leaveSession()
      else
        @state.stream = true

    publisher.on 'streamDestroyed', () =>
      @state.stream = false

    @state.publisher = publisher
    @state.session.publish publisher

  initSubscriber: (event) ->
    subscriber = @state.session.subscribe event.stream, 'video-' + @state.channel

    subscriber.on 'videoElementCreated', (event) =>
      subscriber.userId = JSON.parse(subscriber.stream.connection.data).userId
      @state.subscribers.push subscriber
      @state.audioStart.play()

    subscriber.on 'publisherStartSpeaking', (event) =>
      subscriber = @state.subscribers.find (s) -> s.id.includes event.connection.connectionId
      subscriber.isTalking = true
      @state.subscribers.splice()

    subscriber.on 'publisherStopSpeaking', (event) =>
      subscriber = @state.subscribers.find (s) -> s.id.includes event.connection.connectionId
      subscriber.isTalking = false
      @state.subscribers.splice()

  resetState: () ->
    @state.channel = null
    @state.session = null
    @state.publisher = null
    @state.subscribers = []

  togglePublisherAudio: () ->
    @state.publisher.publishAudio @state.isMuted
    @state.isMuted = !@state.isMuted

  getAudioDevices: () ->
    devices = await @openvidu.getDevices()
    audioDevices = devices.filter (device) -> device.kind == 'audioinput'
    @state.audioDevices = audioDevices
    @state.activeDevice = audioDevices[0]

  switchAudioDevice: (deviceLabel) ->
    device = @state.audioDevices.find (d) -> d.label == deviceLabel
    @state.activeDevice = device

    await @state.session.unpublish @state.publisher
    @initPublisher()

  getToken: (sessionName) ->
    return fetch("#{@url}/createSession",
      method: 'POST'
      headers:
        'Accept': 'application/json'
        'Content-Type': 'application/json'
      body: JSON.stringify(sessionName: sessionName)
    ).then((res) ->
      res.json()
    ).then((res) ->
      return res
    ).catch (error) ->
      console.warn error

export default OpenviduClient
export OpenviduClient = OpenviduClient
