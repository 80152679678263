















import CreateWebjet from './CreateWebjet'
import DialogTemplate from '@/components/DialogTemplate'
export default
  name: 'ToolsMobile'
  components: { CreateWebjet, DialogTemplate }
  data: ->
    toolsMobile: false
  methods:
    close: ->
      @toolsMobile = false
