














































import { userLimits } from '@/utils'

export default
  name: "DialogMaxLimitBoard"
  inject: ['provideApp']
  computed:
    userIdForAdd: ->
      @provideApp.dialogMaxLimitBoardUserId
    boardAccess: ->
      @$store.getters.boardAccess @boardId
    plan: ->
      plan = @firebind "/teamBilling/#{@teamId}/plan"
      if plan.$value == null
        return 'free'
      else
        plan.$value
    boardId: ->
      @provideApp.dialogMaxLimitBoardId
    teamId: ->
      teamId = @firebind "/boards/#{@boardId}/team"
      teamId.$value
    teamRols: ->

      @firebind "/teamAccess/#{@teamId}/roles"
    userRole: ->
      @boardAccess.role
    roles: ->
      @firebind "/teamAccess/#{@teamId}/roles"
    membersInTeam: ->
      memebers = Object.keys @roles
      memebers
    boardsUsers: ->
      @firebind "/teamAccess/#{@teamId}/boards"
    guestWithEdit:->
      members = []
      for k,v of @boardsUsers
        if v and k != '*'
          for b,r of v
            if r == 'edit'
              members.push k
              break
      members
    allmembersInTeam: ->
      @membersInTeam.length + @guestWithEdit.length
    teamSize: ->
      teamSizeData = @firebind("/teamBilling/#{@teamId}/teamSize")
      teamSize = teamSizeData?.$value
      return 3 if teamSize == null
      teamSize
    teamNoLimits: ->
      val = @firebind("/teamBilling/#{@teamId}/nolimits")
      val.$value
    limitIsOver: ->
      if !@teamNoLimits and @allmembersInTeam >= @teamSize
        return true
      false
  methods:
    addUserToTeam: ->
      if @limitIsOver
        @provideApp.dialogMaxLimitBoard = false
        @provideApp.dialogMaxLimitTeam = true
        @provideApp.dialogMaxLimitTeamId = @teamId
        return
      await @$store.dispatch 'team/updateTeamRole',
        teamId: @teamId
        userId: @userIdForAdd
        role: 'member'
      @provideApp.dialogMaxLimitBoard = false
    upgradePlan: ->
      @provideApp.dialogMaxLimitBoard = false
      @$router.push { name: 'TeamPlan', params: { teamId: @teamId } }

