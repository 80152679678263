
























export default
  name: 'WBase'
  inject: ['provideWebjet']
  updated: -> #ttf 'updated hook', =>
    rootWebjet = @provideWebjet
    while rootWebjet.provideWebjet
      rootWebjet = rootWebjet.provideWebjet
    return if @updateInProgress
    @updateInProgress = true
    requestAnimationFrame => requestAnimationFrame =>
      @updateInProgress = false
      rootWebjet.tryUpdateOuterHTML()

  computed:
    attach: ->
      @provideWebjet.genShow
    inSideBar: -> return true if @provideWebjet.provideSidebar
    elementIs: ->
      return 'path' if @provideWebjet.$options.name == 'WebjetPaint'
      return 'div'
    showDevData: ->
      FLAG.WEBJET_SHOW_DEV_DATA
    showResizer: ->
      return false if FLAG.WEBJET_RESIZER
      return false if @provideWebjet.isReadOnly
      return false if @provideWebjet.provideWebjet
      return false if @provideWebjet.isInTrash
      return false if @provideWebjet.provideSidebar
      return false if @provideWebjet.webjet.category == 'anchor'
      return false if @provideWebjet.webjet.category == 'table'
      @provideWebjet.isResizable
    showScaleResizer: ->
      # return false if FLAG.WEBJET_RESIZER
      return false if @provideWebjet.isReadOnly
      return false if @provideWebjet.provideWebjet
      return false if @provideWebjet.isInTrash
      return false if @provideWebjet.provideSidebar
      # console.log
      @provideWebjet.isResizable and @provideWebjet.isOnlySelected and @provideWebjet.webjet.category == 'anchor' and @provideWebjet.webjet.data?.type != 'bookmark'
    showPublished: ->
      @provideWebjet.hasWejeSites
    publishedCount: -> @provideWebjet.wejeSites.length
  methods:
    publishedClick: ()->
      @provideWebjet.provideCanvas.openWejeSite @provideWebjet.wejeSites[0]
