export default
  data: ->
    #touch: { active: false, x: 0, y: 0, dx: 0, dy: 0 }
    touch: { active: false, x: 0, y: 0, dx: 0, dy: 0 }
  listeners: ->
    mousedown: @touchMousedown
    mouseup: @touchMouseup
    mousemove: @touchMousemove
    touchstart: @touchStart
    touchmove: @touchMove
    touchend: @touchEnd
    touchcancel: @touchEnd

  methods:
    touchMousedown: (e)->
      return if e.shiftKey
      return if @collaborateDrawMode and e.which == 1
      if (e.target.matches('.canvas,.scene,.paste-layer') and e.which == 1) or e.which == 2 or
      (e.target.matches('.presentation-back-layer') and e.which == 1)
        if @animation.active
          @animateStop()
        @touch = { active: true, x: e.x, y: e.y, dx: e.x, dy: e.y}
        e.preventDefault()
      return

    touchMousemove: (e)->
      if @touch.active
        if e.which == 0
          # Mouseup was outside window or similar
          @touchMouseup(e)
          return
        @position.x += e.x - @touch.dx
        @position.y += e.y - @touch.dy
        @touch.dx = e.x
        @touch.dy = e.y
        e.preventDefault()
      return

    touchMouseup: (e)->
      #console.log 'mouseup', e
      if @touch.active
        #@position.x += e.x - @touch.x
        #@position.y += e.y - @touch.y
        @touch = { active: false, x: 0, y: 0, dx: 0, dy: 0 }
      return

    touchStart: (e)->
      return if @collaborateDrawMode and e.touches?.length == 1
      cPath = e.composedPath()
      isLeftSideBar = null
      for node in cPath
        break if isLeftSideBar
        if node?.classList?.contains('sidebar-left-content')
          isLeftSideBar = node
      if !e.preventGrabTouch and e.touches?.length == 1 and !e.target.matches('.v-menu__content *') and
      !e.target.matches('.contextmenu *') and !e.target.matches('.fly-context-menu *') and !e.target.matches('.canvas-graffiti-tools *') and
      !isLeftSideBar
        @touch = {
          active: true,
          x: @position.x, y: @position.y,
          dx: e.touches[0].clientX, dy: e.touches[0].clientY
        }
        #e.preventDefault()

    touchMove: (e)->
      if @touch.active
        if e.touches?.length != 1
          @touch.touchMoved = true
          @touchEnd(e)
          return
        if !@touch.touchMoved
          distance = parseInt Math.sqrt(
            Math.pow(e.touches[0].clientX - @touch.dx, 2) + Math.pow(e.touches[0].clientY - @touch.dy, 2)
          ), 10
          if distance > 5
            @touch.touchMoved = true
        @position.x = @touch.x + e.touches[0].clientX - @touch.dx
        @position.y = @touch.y + e.touches[0].clientY - @touch.dy
      if !e.target.matches('.v-menu__content .scroll *') && !e.target.matches('.sidebar-content *') && e.cancelable
        e.preventDefault()

    touchEnd: (e)->
      if @touch.active
        if !@touch.touchMoved
          tap = new UIEvent 'tap', { bubbles: true, cancelable: true }
          e.x = @touch.dx
          e.y = @touch.dy
          e.target.dispatchEvent(tap)
        @touch = { active: false, x: 0, y: 0, dx: 0, dy: 0 }
        #e.preventDefault()
