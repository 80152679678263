import store from '@/store'
import { getBeforeUnloadHandler } from '@/utils'

operationsCounter = 0

export class Operation
  constructor: (options={})->
    @history = true
    @history = false if options.history == false
    @items = []
  dispatch: (action, payload)=>
    payload = {} unless payload
    store.dispatch action, { payload, operation: this }
  dispatchWithoutHistory: (action, payload)=>
    operation = new Operation({ history: false })
    store.dispatch action, { payload, operation }
  redo: (...args)=>
    store.commit ...args
    @items.push
      redo: args
  undo: (...args)=>
    @items.push
      undo: args
  add: (obj)=>
    store.commit obj.redo... if obj.redo
    @items.push obj
  # delayed
  #_run: ->
  _rollback: ->
    for o in @items.slice(0).reverse()
      store.commit o.undo... if o.undo
  completed: ->
    if @history and @items.length > 0
      store.commit 'undoredo/ADD', [@items...]
  # events onError callbacks


export operationAction = (action)->
  return (context, payload)->
    operationsCounter += 1
    store.state.firebind.syncMode = true
    operation = false
    root = false
    payload = {} unless payload
    if payload.payload and payload.operation
      operation = payload.operation
      payload = payload.payload
    unless operation
      root = true
      operation = new Operation() unless operation

    if root
      beforeunload = getBeforeUnloadHandler()
      try
        window.addEventListener 'beforeunload', beforeunload
        result = await action.call this, context, payload, operation
      catch e
        console.log 'rollback operation', operation
        operation._rollback()
        console.log 'rollback complete, throw original error:'
        throw e
      finally
        setTimeout ( -> window.removeEventListener 'beforeunload', beforeunload ), 100

      operation.completed()
    else
      result = action.call this, context, payload, operation
    operationsCounter -= 1
    if operationsCounter == 0
      store.state.firebind.syncMode = false
    result

export operationActions = (actions)->
  result = {}
  for k, v of actions
    result[k] = operationAction v
  result
