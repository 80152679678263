import router from '@/router'

initialized = false
init = ->
  d = document
  s = d.createElement 'script'
  s.src = 'https://widget.13chats.com/assets/loader.js'
  s.setAttribute 'data-chats-widget-id', '5cf46e7d-edff-494e-b436-01a4cd9cd390'
  s.async = true
  d.getElementsByTagName('head')[0].appendChild(s)

setDisplay = (routeName) ->
  cl = document.body.classList
  if routeName == 'Board' or routeName == 'WejeSite'
    cl.add 'chat13hide'
  else
    cl.remove 'chat13hide'

setDisplay router.currentRoute.name

if router.currentRoute.name!='Board' and router.currentRoute.name!='WejeSite'
  initialized = true
  setTimeout init, 100

router.beforeEach (to, from, next)->
  setDisplay(to.name)
  if !initialized and to.name!='Board' and to.name!='WejeSite'
    initialized = true
    setTimeout init, 100
  next()
