import CollaborateWebsocket from './CollaborateWebsocket'
import store from '@/store'
import { firebind } from '@/utils'


if IS_PRODUCTION and !IS_STAGE
  host = 'board.app.weje.io'
else
  host = 'board.stage.weje.io'

host = 'http://localhost:3901' if FLAG.WS_BOARD_LOCAL

wsBoard = new CollaborateWebsocket(host, {})

setTimeout ->
  store.watch (-> store.getters['currentBoardId']), (value)->
    wsBoard.setKey value
  , { immediate: true }

  store.watch (-> firebind('/system/config')?.wsBoardServers), (value)->
    return unless firebind('/system/config')?.$ready
    value = 1 unless value
    wsBoard.setScale value
  , { immediate: true }



window.devWsBoard = wsBoard if IS_DEVELOPMENT

export default wsBoard
export { wsBoard }
