































import ContextBtn from './ContextBtn'
export default
  name: 'GraffitiColor'
  components: { ContextBtn }
  inject: { provideCanvas: { default: null } }
  priority: 10000

  if: (selection)->
    return false unless FLAG.GRAFFITI_TOOLS_TO_CONTEXT_MENU
    selection.length == 1 and selection[0].$options.name == 'WebjetGraffiti' and
    selection[0].$refs.editor?.editMode and selection[0].$refs.editor?.graffitiData.graffitiType != 'lastic'

  computed:
    selection: -> @$store.getters['webjet/selected']
    title: ->
      ''
    type: ->
      @selection[0].$refs.editor.graffitiData.graffitiType
    color: ->
      @selection[0].$refs.editor.color

  methods:
    action: (color) ->
      @$set @selection[0].$refs.editor.graffitiTypes[@type], 'color', color
