






import SidebarTab from '../SidebarTab'
import InventoryContent from './InventoryContent'
import inventoryMixin from './inventoryMixin'
import HelpArrow from '@/components/HelpArrow'
export default
  name: 'InventoryTab'
  mixins: [ inventoryMixin ]
  components: { SidebarTab, HelpArrow }
  data: ->
    component: InventoryContent
