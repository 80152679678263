import { getRef, getPath, getDataUserRef, getBoardsRef, getBoardPath } from '@/utils'
import { getFileRef, getWebjetStubForFile, getWebjetDataForUpload, getWebjetsImgFromTextData, webjetsFromString } from '@/utils'
import { getWebjetForText,getWebjetForWejeLink } from '@/utils'
import { getBeforeUnloadHandler } from '@/utils'
import { firebind } from '@/utils'
import { generateConnectionId, generateSort, getOwnerUID } from '@/utils'
import { nowFirebase } from '@/utils'
import { scaleImg } from '@/utils'
import { sendAnalytics } from '@/utils'
import { preloadNode } from '@/utils'
import router from "@/router"

export default

  setExpand: (_, { path, value, withoutHistory }, { undo, redo, dispatch })->
    connection = await firebind path
    oldValue = connection.presentation || null
    newValue = if value then 'card' else 'row'
    if withoutHistory
      @commit 'webjet/SET_EXPAND', { path, value: newValue }
    else
      redo 'webjet/SET_EXPAND', { path, value: newValue }
      undo 'webjet/SET_EXPAND', { path, value: oldValue }
      await dispatch 'webjet/onUpdated'

  setCoordinates: (_, { path, x, y }, { undo, redo, dispatch })->
    connection = await firebind path
    oldValue = connection.coordinates || null
    newValue = { x, y }
    redo 'webjet/SET_COORDINATES', { path, value: newValue }
    undo 'webjet/SET_COORDINATES', { path, value: oldValue }
    await dispatch 'webjet/onUpdated'

  setTheme: (_, { path, theme }, { undo, redo, dispatch })->
    connection = await firebind path
    webjet = if connection.src then await firebind connection.src else connection
    webjetPath = webjet.$path
    oldId = webjet.data?.backgroundId || null
    oldClasses = webjet.data?.classes || null
    newId = String(theme)
    newClasses = ["background-#{theme}"]
    redo 'webjet/SET_THEME', { path: webjetPath, id: newId, classes: newClasses}
    undo 'webjet/SET_THEME', { path: webjetPath, id: oldId, classes: oldClasses}
    await dispatch 'webjet/onUpdated', { path: connection.src }

  setShapeType: (_, { path, type }, { undo, redo, dispatch })->
    connection = await firebind path
    webjet = if connection.src then await firebind connection.src else connection
    webjetPath = webjet.$path
    oldType = webjet.data?.type || null
    redo 'webjet/SET_SHAPE_TYPE', {path: webjetPath, type: type}
    undo 'webjet/SET_SHAPE_TYPE', {path: webjetPath, type: oldType}
    await dispatch 'webjet/onUpdated', { path: connection.src }

  setAnchorType: (_, { path, type }, { undo, redo, dispatch })->
    connection = await firebind path
    webjet = if connection.src then await firebind connection.src else connection
    webjetPath = webjet.$path
    oldType = webjet.data?.type || null
    redo 'webjet/SET_ANCHOR_TYPE', {path: webjetPath, type: type}
    undo 'webjet/SET_ANCHOR_TYPE', {path: webjetPath, type: oldType}
    await dispatch 'webjet/onUpdated', { path: connection.src }

  setAnchorHideInPresentation: (_, { path, val }, { undo, redo, dispatch })->
    connection = await firebind path
    webjet = if connection.src then await firebind connection.src else connection
    webjetPath = webjet.$path
    oldVal = webjet.data?.hideInPresentation || null
    redo 'webjet/SET_HIDE_IN_PRESENTATION', {path: webjetPath, val: val}
    undo 'webjet/SET_HIDE_IN_PRESENTATION', {path: webjetPath, val: oldVal}
    await dispatch 'webjet/onUpdated', { path: connection.src }


  # Operation to share/unshare a webjet with user
  # src - firebase path to a webjet
  # uid - user UI to share/unshare with
  # mode - 'r', 'rw' to share or false to unshare
  share: (ctx, { src, uid, mode }, operation)->
    console.warn "webjet/share is called, should be considered obsolete"
    if mode and mode not in [ 'r', 'rw' ]
      console.error 'wrong mode for webjet/share:', mode
      mode = 'r'
    #console.log { src, uid, mode }
    return unless src
    owner = await getOwnerUID src
    if owner==uid || !uid
      return
    currentUid = ctx.rootState.uid
    currentUser = await firebind '/users/'+currentUid
    webjet = await firebind src
    if webjet?.category
      if currentUid != owner and webjet.shared?[currentUid] != 'rw' and webjet.shared?[currentUser?.invite] != 'rw'
        return # no rw access, can not share this
      if mode && webjet.shared?[uid] == mode or not mode && not webjet.shared?[uid]
        return # already shared
      { redo, undo, dispatch } = operation
      await dispatch 'webjet/onUpdated', { path: src }
      redo 'webjet/UPDATE_SHARE', { path: src, uid, mode: mode||null}
      undo 'webjet/UPDATE_SHARE', { path: src, uid, mode: webjet.shared?[uid]||null }
      if src.includes '/boards/' or src.includes '/boardsData/'
        return
      for name, connections of webjet.connections when name!='parents' and name!='count'
        for key, connection of connections
          await operation.dispatch 'webjet/share',
            src: connection.src
            uid: uid
            mode: mode

  shareBoard: (ctx, { src, uid, mode }, { dispatch, redo, undo })->
    if FLAG.NEW_SHARE
      #if uid == '*'
      #  newMode = if mode then 'r' else null
      #  await dispatch 'webjet/share', { src, uid, newMode }
      #  if mode
      #    sendAnalytics 'board_make_public'
      #  return

      board = await firebind src
      boardId = src.split('/').pop()
      if not board?.team?
        console.error 'shareBoard: board has no team!'
        return
      teamAccessPath = "/teamAccess/#{board.team}/boards/#{uid}/#{boardId}"
      oldMode = await firebind(teamAccessPath)?.$value
      if uid == '*'
        newMode = if mode then 'view' else null
      else if mode in ['r', 'rw']
        console.warn 'shareBoard called with old mode:', mode
        newMode = if mode=='rw' then 'edit' else 'view'
      else
        newMode = if mode then mode else null
      redo 'webjet/UPDATE_TEAM_ACCESS', { path: teamAccessPath, mode: newMode }
      undo 'webjet/UPDATE_TEAM_ACCESS', { path: teamAccessPath, mode: oldMode }

      if mode
        if uid != '*'
          userTeamPath = "/data/#{uid}/teams/#{board.team}"
          userTeam = await firebind userTeamPath
          if not userTeam?.status?
            @commit 'webjet/ADD_TEAM_TO_USER', { uid: uid, team: board.team }
      else # for compatibility with old data, remove it later
        await dispatch 'webjet/share', { src, uid, mode }
    else # old share
      if mode in [ 'rw', 'edit', 'manage', 'owner' ]
        mode = 'rw'
      else if mode
        mode = 'r'
      await dispatch 'webjet/share', { src, uid, mode }

    # FIXME: remove old inbox things
    if uid!='*' and mode
      @commit 'webjet/PUSH_INBOX_ITEM', { uid, src }
      @commit 'webjet/WRITE_SEND_NOTIFY_TASK',
        uid: ctx.rootState.uid
        to_uid: uid
        src: src
    if uid == '*' and mode
      sendAnalytics 'board_make_public'

  createConnectionWithoutHistory: (ctx, payload, { dispatchWithoutHistory }) ->
    await dispatchWithoutHistory 'webjet/createConnection', payload

  createConnection: (ctx, { dest, src, group, key, data, weak }, operation)->
    group = "items" if group == undefined or group == null
    { redo, undo } = operation
    targetWebjet = await firebind dest
    if src
      for uid, mode of targetWebjet.shared
        await operation.dispatch 'webjet/share',
          src: src
          uid: uid
          mode: mode
      await operation.dispatch 'webjet/share',
        src: src
        uid: await getOwnerUID dest
        mode: 'rw'
      webjet = await firebind src
      data = {} unless data
      data.id = generateConnectionId() unless data.id
      data.src = "firebase:" + getPath src
      path = "#{dest}/connections"
      path += "/#{group}" if group
      if key
        path += "/#{key}"
      else
        path += "/#{getRef(path).push().key}"
      parentPath = "#{src}/connections/parents"
      parentPath += "/#{getRef(parentPath).push().key}"
      parentData = {}
      parentData.id = generateConnectionId()
      parentData.src = "firebase:" + getPath(dest)
      parentData.weak = true if weak
      parentData.weak = true if ctx.rootGetters.currentTrashSrc == dest and group == 'trash'
      parentData.binding =
        id: data.id
        name: group
      redo 'webjet/CREATE_CONNECTION', { path, data, parentPath, parentData }
      undo 'webjet/REMOVE_CONNECTION', { path, parentPath }
      await operation.dispatch 'webjet/onUpdated', { path: dest }
      return { connectionPath: path }
    else
      data = {} unless data
      data.id = generateConnectionId() unless data.id
      if group and group.includes('/')
        path = "#{dest}/#{group}"
      else
        path = "#{dest}/connections"
        path += "/#{group}" if group
      if key
        path += "/#{key}"
      else
        path += "/#{getRef(path).push().key}"
      redo 'webjet/CREATE_CONNECTION', { path, data }
      undo 'webjet/REMOVE_CONNECTION', { path }
      return { connectionPath: path }


  updateConnection: (_, { path, data }, { undo, redo, dispatch })->
    connection = await firebind path
    oldValue = Object.assign {}, connection
    newValue = Object.assign {}, connection, data
    redo 'webjet/UPDATE_CONNECTION', { path, data: newValue }
    undo 'webjet/UPDATE_CONNECTION', { path, data: oldValue }
    # split connection not work with paints
    if connection.$path.includes '/connections/'
      await dispatch 'webjet/onUpdated', { path: connection.$path.split('/connections/')[0] }
    else
      await dispatch 'webjet/onUpdated', { path: null }

  patchConnection: (_, { path, data }, { undo, redo })->
    # override only changed
    # Logic in mutation? Or split to many updates?
    #await dispatch 'webjet/onUpdated', { path: connection.$path.split('/connections/')[0] }

  removeConnection: (_, { path, force }, { undo, redo, dispatch })->
    connection = await firebind path
    webjet = if connection.src then await firebind connection.src else connection
    parentConnection = false
    for k, v of webjet.connections?.parents
      if v.binding.id == connection.id
        parentConnection = v
    if parentConnection
      data = connection
      parentData = parentConnection
      parentPath = parentConnection.$path
      redo 'webjet/REMOVE_CONNECTION', { path, parentPath, force }
      undo 'webjet/CREATE_CONNECTION', { path, data, parentPath, parentData }
    else
      if connection.src
        console.warn 'removeConnection: connection without parent'
      data = connection
      redo 'webjet/REMOVE_CONNECTION', { path, parentPath: null, force }
      undo 'webjet/CREATE_CONNECTION', { path, data }
    # split connection not work with paints
    if connection.$path.includes '/connections/'
      await dispatch 'webjet/onUpdated', { path: connection.$path.split('/connections/')[0] }
    else
      await dispatch 'webjet/onUpdated', { path: null }


  createWebjet: ({ rootState }, { path, category, data={} }, operation)->
    data.category = category if category
    unless data.category
      console.warn 'createWebjet: without category'
    # set meta
    data.meta = {} unless data.meta
    data.meta.createUser = rootState.uid
    data.meta.createDate = new Date(nowFirebase()).toISOString()
    # create
    operation.redo 'webjet/CREATE_WEBJET', { path, data }
    operation.undo 'webjet/REMOVE_EMPTY_WEBJET', { path }
    await operation.dispatch 'webjet/onUpdated', { path }

  removeEmptyWebjet: (_, { path }, { dispatch, undo, redo })->
    webjet = await firebind path
    webjetData = Object.assign {}, webjet
    redo 'webjet/REMOVE_EMPTY_WEBJET', { path }
    undo 'webjet/CREATE_WEBJET', { path, data: webjetData }
    await dispatch 'webjet/onUpdated'

  _create: (_, { dest, category, group, key, connection, webjet, inline, boardPath }, operation)->
    await firebind dest
    if FLAG.NEW_DATA and (IS_DEVELOPMENT or IS_STAGE) and not boardPath? and category != '/webjets/containers/webjets/canvas'
      console.warn 'NEW_DATA warning: webjet/_create is called without boardPath! Can be ok for cards in inventory, etc.'
    webjetKey = getDataUserRef(boardPath).push().key
    webjetPath = getPath webjetKey, boardPath
    if category != '/webjets/containers/webjets/canvas' and FLAG.CREATE_ALL_WEBJETS_INLINE
      inline = true
    if inline
      newWebjetData = Object.assign {}, webjet, connection
      newWebjetData.category = category if category
      { connectionPath } = await operation.dispatch 'webjet/createConnection',
        dest: dest
        group: group
        key: key
        #src: webjetPath
        src: null
        data: newWebjetData
      webjetPath = connectionPath
      #webjetKey =
    else
      await operation.dispatch 'webjet/createWebjet',
        path: webjetPath
        category: category
        data: webjet
      { connectionPath } = await operation.dispatch 'webjet/createConnection',
        dest: dest
        group: group
        key: key
        src: webjetPath
        data: connection
    return { webjetKey, webjetPath, connectionPath }

  createInTrash: (ctx, { category, connection, webjet, boardPath }, operation)->
    if FLAG.CREATE_IN_INVENTORY_TRASH
      dest = 'inventory'
    else
      dest = ctx.rootGetters.currentTrashSrc
    unless dest
      throw 'createInTrash: without currentTrashSrc'
    await operation.dispatchWithoutHistory 'webjet/_create',
      dest: dest
      boardPath: boardPath
      category: category
      group: 'trash'
      connection: Object.assign {}, connection, { presentation: 'row', trashClear: true }
      webjet: webjet

  create: (_, payload, { dispatch })->
    if FLAG.NEW_DATA and payload.category == '/webjets/containers/webjets/canvas'
      console.error 'NEW_DATA error: board is created by webjet/create instead of webjet/createBoard'
    trash = payload.trash
    if trash == undefined or trash == null
      # auto detect
      meta = payload.webjet?.meta
      if meta and (meta.uploadInProgress or meta.isInStorage)
        trash = true
    if trash
      createResult = await dispatch 'webjet/createInTrash', payload
      { connectionPath } = await dispatch 'webjet/moveConnection',
        path: createResult.connectionPath
        dest: payload.dest
        group: payload.group
        key: payload.key
        data: Object.assign {}, payload.connection, { presentation: null, trashClear: null }
      return Object.assign {}, createResult, { connectionPath }
    else
      await dispatch 'webjet/_create', payload

  splitTextCard: (_, { split, create }, { dispatch, undo, redo })->
    redo 'webjet/UPDATE_EDITOR_CONTENT',
      path: split.path
      contentDelta: split.newDelta
      contentHtml: split.newHtml
    undo 'webjet/UPDATE_EDITOR_CONTENT',
      path: split.path
      contentDelta: split.oldDelta
      contentHtml: split.oldHtml
    await dispatch 'webjet/create', create

  mergeTextCard: (_, { dest, merge, parent }, { dispatch, undo, redo })->
    redo 'webjet/UPDATE_EDITOR_CONTENT',
      path: dest.path
      contentDelta: dest.newDelta
      contentHtml: dest.newHtml
    undo 'webjet/UPDATE_EDITOR_CONTENT',
      path: dest.path
      contentDelta: dest.oldDelta
      contentHtml: dest.oldHtml
    dispatch 'webjet/removeEmptyWebjet', { path: merge.path }
    dispatch 'webjet/removeConnection', { path: parent.connection, force: true }

  createBoard: ({ rootState }, { board, boardId }, { dispatch, undo, redo })->
    desktop = await firebind 'desktop'
    sort = generateSort(desktop, true)

    board.owner = rootState.uid unless board.owner

    if not board.team
      throw new Error 'board.team is not set in webjet/createBoard'
    if not board.project
      throw new Error 'board.project is not set in webjet/createBoard'

    webjetKey = boardId || getBoardsRef().push().key
    webjetPath = getBoardPath webjetKey
    await dispatch 'webjet/createWebjet',
      path: webjetPath
      category: '/webjets/containers/webjets/canvas'
      data: board

    redo 'webjet/ADD_BOARD_TO_PROJECT',
      board: webjetKey
      project: board.project
    undo 'webjet/REMOVE_BOARD_FROM_PROJECT',
      board: webjetKey
      project: board.project

    teamRole = await firebind("/teamAccess/#{board.team}/roles/#{rootState.uid}")?.$value
    projectRole = await firebind("/teamAccess/#{board.team}/projects/#{rootState.uid}/#{board.project}")?.$value

    if teamRole not in ['owner', 'admin'] and projectRole!='manage'
      teamAccessPath = "/teamAccess/#{board.team}/boards/#{rootState.uid}/#{webjetKey}"
      redo 'webjet/UPDATE_TEAM_ACCESS', { path: teamAccessPath, mode: 'manage' }
      undo 'webjet/UPDATE_TEAM_ACCESS', { path: teamAccessPath, mode: null }

    return { webjetKey, webjetPath }

  copyBoard: (_, { path, title, src = null, key = null, team, project }, { dispatchWithoutHistory })->
    srcBoard = await firebind path
    # src indicate that the board has already been created, we need just copy items
    if src
      webjetPath = src
      webjetKey = key
    else
      copy =
        team: team
        project: project
        data: Object.assign({}, srcBoard.data)
      copy.data.title = title
      { webjetKey, webjetPath } = await dispatchWithoutHistory 'webjet/createBoard',
        board: copy
    await router.push { path: "/b/#{webjetKey}" }
    nodes = {}
    nodes[srcBoard.$path] = webjetPath
    preloadNode srcBoard.$path if FLAG.PRELOAD_TEMPLATE_BOARD
    for key, item of srcBoard.connections.items
      #console.log item, item.$path
      await dispatchWithoutHistory 'webjet/copyNode',
        path: item.$path
        dest: webjetPath
        boardPath: webjetPath
        group: 'items'
        nodes: nodes

    if FLAG.REPLACE_RELATIVE_BTN_LINKS
      await dispatchWithoutHistory 'webjet/replaceRelativeBtnLinks', { nodes }

    for key, item of srcBoard.paints?.items
      await dispatchWithoutHistory 'webjet/copyNode',
        path: item.$path
        dest: webjetPath
        boardPath: webjetPath
        nodes: nodes
        group: 'paints/items'

  copyBoardDelayed: (_, { path, title, team, project, boardId }, { dispatchWithoutHistory })->
    srcBoard = await firebind path
    copy =
      team: team
      project: project
      data: Object.assign({}, srcBoard.data)
      meta:
        delayedCopy: true
        copySrc: path
    copy.data.title = title
    await dispatchWithoutHistory 'webjet/createBoard',
      board: copy
      boardId: boardId

  completeDelayedCopy: (_, { path, dest }, { dispatchWithoutHistory })->
    @commit 'webjet/REMOVE_DELAYED_COPY_FLAG',
      path: dest
    srcBoard = await firebind path
    nodes = {}
    nodes[srcBoard.$path] = dest
    preloadNode srcBoard.$path if FLAG.PRELOAD_TEMPLATE_BOARD
    for key, item of srcBoard.connections.items
      #console.log item, item.$path
      await dispatchWithoutHistory 'webjet/copyNode',
        path: item.$path
        dest: dest
        boardPath: dest
        group: 'items'
        nodes: nodes

    if FLAG.REPLACE_RELATIVE_BTN_LINKS
      await dispatchWithoutHistory 'webjet/replaceRelativeBtnLinks', { nodes }

    for key, item of srcBoard.paints?.items
      await dispatchWithoutHistory 'webjet/copyNode',
        path: item.$path
        dest: dest
        boardPath: dest
        nodes: nodes
        group: 'paints/items'

  copyNode: (_, { path, dest, group, key, nodes={}, data, boardPath }, { dispatch })->
    connection = await firebind path
    webjet = if connection.src then await firebind connection.src else connection
    if webjet.meta?.uploadInProgress
      console.warn 'copyNode: skip uploadInProgress webjet'
      return {}
    newConnectionData = Object.assign({}, connection, data)
    newConnectionData.id = undefined
    if nodes[webjet.$path]
      { connectionPath } = await dispatch 'webjet/createConnection',
        dest: dest
        group: group
        key: key
        src: nodes[webjet.$path]
        data: newConnectionData
      return { webjetPath: nodes[webjet.$path], connectionPath }
    newWebjetData = {}
    for k, v of webjet when k != 'connections' and k != 'shared' and k != 'id'
      newWebjetData[k] = if v != null && typeof v == "object" then Object.assign({}, v) else v
    if webjet.meta?.isInStorage
      delete newWebjetData.meta.isInStorage
      delete newWebjetData.meta.uploadedSize
      newWebjetData.meta.uploadInProgress = true
    { webjetKey, webjetPath, connectionPath } = await dispatch 'webjet/create',
      dest: dest
      boardPath: boardPath
      group: group
      key: key
      connection: newConnectionData
      webjet: newWebjetData
      inline: !connection.src
    if webjet.meta?.isInStorage
      # Async reupload
      beforeunload = getBeforeUnloadHandler()
      window.addEventListener 'beforeunload', beforeunload
      setTimeout =>
        xhr = new XMLHttpRequest()
        #console.log webjet.data.url
        xhr.responseType = 'blob'
        xhr.addEventListener 'progress', (e)=>
          if e.lengthComputable
            # write progress counter to webjet
            @commit 'webjet/UPDATE_WEBJET',
              path: webjetPath
              update:
                meta:
                  uploadedSize: e.loaded/2
        writeError = (code, message)=>
          @commit 'webjet/UPDATE_WEBJET',
            path: webjetPath,
            update:
              meta:
                uploadError:
                  copyError: true
                  code: code
                  message: message
        xhr.addEventListener 'error', (e)=>
          console.error e
          writeError('xhr.error', null)
          window.removeEventListener 'beforeunload', beforeunload
        xhr.addEventListener 'load', (e)=>
          if xhr.status != 200
            writeError(xhr.status, xhr.statusText)
            window.removeEventListener 'beforeunload', beforeunload
            return
          file = xhr.response
          ext = webjet.meta.fileName.split('.')
          ext = ext[ext.length - 1]
          fileRef = getFileRef(webjetKey, ext)
          meta = { cacheControl: 'private, max-age=8640000' }
          if webjet.category != '/webjets/content/webjets/pdf'
            meta.contentDisposition = "attachment;filename=#{webjet.data.titleOrigin}"
          uploadTask = fileRef.put file, meta
          uploadTask.on(
            'state_changed'
            (progressData) =>
              # write progress counter to webjet
              @commit 'webjet/UPDATE_WEBJET',
                path: webjetPath
                update:
                  meta:
                    uploadedSize: (webjet.meta.fileSize + progressData.bytesTransferred)/2
            (error) =>
              console.error error
              writeError(error.code, error.message)
              window.removeEventListener 'beforeunload', beforeunload
            (complete) =>
              # update webjet after upload (with url of uploaded file, etc)
              url = await fileRef.getDownloadURL()
              webjetUpdate = getWebjetDataForUpload(file, fileRef, url)
              @commit 'webjet/UPDATE_WEBJET',
                path: webjetPath
                update: webjetUpdate
              window.removeEventListener 'beforeunload', beforeunload
          )
        xhr.open 'GET', webjet.data.storage||webjet.data.url
        xhr.send()
    nodes[webjet.$path] = webjetPath
    if webjet.connections
      for gKey, gValue of webjet.connections
        unless gKey in ['parents', 'count', 'mindmap'] # 'count' is a nasty legacy
          for cPath, cValue of gValue
            await dispatch 'webjet/copyNode',
              path: cValue.$path #"#{gValue.$path}/#{cPath}"
              dest: webjetPath
              boardPath: boardPath
              group: gKey
              nodes: nodes
    return { webjetPath, connectionPath }

  replaceRelativeBtnLinks: (_, { nodes }, { undo, redo })->
    btnLinks = []
    for k, v of nodes
      w = await firebind v
      if w.category == 'btnLink'
        btnLinks.push w
    if btnLinks.length
      parsed = {}
      for k, v of nodes
        [k, v].forEach (p)->
          arr = p.split '/'
          if arr[1] == 'boardsData'
            parsed[p] = { board: arr[2], webjet: arr[3] }
      for btnLink in btnLinks
        url = btnLink.data.url
        for k, v of nodes
          if parsed[k] and url.includes(parsed[k].board) and url.includes(parsed[k].webjet)
            console.log 'REPLACE_RELATIVE_BTN_LINKS: Replace btn link'
            newUrl = url
            newUrl = newUrl.replaceAll parsed[k].board, parsed[v].board
            newUrl = newUrl.replaceAll parsed[k].webjet, parsed[v].webjet
            redo 'webjet/REPLACE_BTN_URL', { path: btnLink.$path, url: newUrl }
            undo 'webjet/REPLACE_BTN_URL', { path: btnLink.$path, url: url }


  eraseNode: (_, { path, nodes={} }, { dispatch, undo, redo })->
    connection = await firebind path
    webjet = if connection.src then await firebind connection.src else connection

    #console.log 'eraseNode', webjet.$path, nodes[webjet.$path], nodes
    if nodes[webjet.$path]
      console.log 'eraseNode: webjets loop detected'
      return
    nodes[webjet.$path] = true

    if webjet.category in ["/webjets/containers/webjets/canvas", "/webjets/containers/webjets/mindmap"]
      throw 'eraseNode: erase board not allowed'

    if webjet?.connections?.items
      for k, v of webjet.connections.items
        await dispatch 'webjet/eraseNode', { path: v.$path, nodes }
    if webjet?.connections?.relations
      for k, v of webjet.connections.relations
        await dispatch 'webjet/relation/remove', { path: v.$path }

    # We do not remove file in storage here, because webjet may be restored with undo.
    # TODO: some async backend to wipe orphaned files after some time (like a few days, or a week, or so).
    await dispatch 'webjet/removeConnection', { path: connection.$path, force: true }
    await dispatch 'webjet/removeEmptyWebjet', { path: webjet.$path }

  moveConnection: (_, { path, dest, group="items", key, data={} }, operation)->
    connection = await firebind path
    targetWebjet = await firebind dest
    webjet = if connection.src then await firebind connection.src else connection
    connectionPath = path
    if targetWebjet.$path == webjet.$path
      throw 'moveConnection: selfmove'
    fullGroup = group
    fullGroup = "connections/#{group}" unless group.includes('/')
    if path.startsWith "#{dest}/#{fullGroup}/#{path.split('/').pop()}"
      await operation.dispatch 'webjet/updateConnection',
        path: path
        data: data
    else
      newConnectionData = Object.assign {}, connection, data
      newConnectionData.id = generateConnectionId()
      { connectionPath } = await operation.dispatch 'webjet/createConnection',
        dest: dest
        group: group
        key: key
        src: connection.src
        data: newConnectionData
      await operation.dispatch 'webjet/removeConnection',
        path: path
    return { connectionPath }


  trashConnection: ({ rootGetters,rootState }, { path, data }, operation)->
    connection = await firebind path
    webjet = if connection.src then await firebind connection.src else connection
    if connection.src
      src = webjet.$path
      if path.startsWith "/data/#{rootState.uid}/inventory/connections/items/"
        dest = "/data/#{rootState.uid}/inventory"
      else
        dest = rootGetters.currentTrashSrc
      unless dest
        throw 'trashConnection: without currentTrashSrc'
      newConnectionData = Object.assign {}, connection, data, { presentation: 'row' }
      newConnectionData.id = generateConnectionId()
      await operation.dispatch 'webjet/createConnection',
        dest: dest
        src: src
        data: newConnectionData
        group: 'trash'
        weak: true
      await operation.dispatch 'webjet/removeConnection',
        path: path
    else
      operation.dispatch 'webjet/eraseNode', { path }

  move: (_, { items, dest, group, data, before }, operation)->
    for v in items
      firebind v.path
    targetWebjet = await firebind dest
    for v in items
      targetWebjet = firebind dest # last data version
      vGroup = group
      if !group or !group.includes('/')
        if v.path.split('/').splice(-3,1)[0] == 'paints' # Maybe != connections ?
          vGroup = v.path.split('/').splice(-3,2).join('/')
      sort = generateSort(targetWebjet, before, group)
      await operation.dispatch 'webjet/moveConnection',
        path: v.path
        dest: dest
        group: vGroup
        data: Object.assign {}, data, v.data, sort

  copy: (_, { items, dest, group, data, before, boardPath }, operation)->
    for v in items
      firebind v.path
    targetWebjet = await firebind dest
    nodes = {}
    result = []
    for v in items
      targetWebjet = firebind dest # last data version
      vGroup = group
      if !group or !group.includes('/')
        if v.path.split('/').splice(-3,1)[0] == 'paints' # Maybe != connections ?
          vGroup = v.path.split('/').splice(-3,2).join('/')
      sort = generateSort(targetWebjet, before, group)
      connection = await firebind v.path
      continue if connection.$value == null
      copy = await operation.dispatch 'webjet/copyNode',
        path: v.path
        dest: dest
        boardPath: boardPath
        group: vGroup
        nodes: nodes
        data: Object.assign {}, data, v.data, sort
      result.push copy
    if FLAG.REPLACE_RELATIVE_BTN_LINKS
      await operation.dispatch 'webjet/replaceRelativeBtnLinks', { nodes }
    return result

   clone: (_, { items, dest, group, data, before }, operation)->
    for v in items
      firebind v.path
    targetWebjet = await firebind dest
    for v in items
      targetWebjet = firebind dest # last data version
      vGroup = group
      if !group or !group.includes('/')
        if v.path.split('/').splice(-3,1)[0] == 'paints' # Maybe != connections ?
          vGroup = v.path.split('/').splice(-3,2).join('/')
      sort = generateSort(targetWebjet, before, group)
      connection = await firebind v.path
      await operation.dispatch 'webjet/createConnection',
        src: connection.src
        dest: dest
        group: vGroup
        weak: true
        data: Object.assign {}, data, v.data, sort

  transfer: (_, { mode, items, dest, group, data, before, flat, boardPath }, { dispatch })->
    if flat
      recFlat = (path)->
        connection = await firebind path
        webjet = if connection.src then await firebind connection.src else connection
        result = [{path: connection.$path}]
        return result unless webjet?.connections?.items
        sorted = Object.values webjet.connections.items
        sorted = sorted.sort (a, b)->
          parseFloat(a.sortKey) - parseFloat(b.sortKey)
        for p in sorted
          result = [...result, ...(await recFlat(p.$path))]
        if before == true
          result.reverse()
        result
      newItems = []
      for item in items
        newItems = [...newItems, ...(await recFlat(item.path))]
      items = newItems#.reverse()

    if mode == 'move'
      await dispatch 'webjet/move', { items, dest, group, data, before }
    else if mode == 'copy'
      await dispatch 'webjet/copy', { items, dest, group, data, before, boardPath }
    else if mode == 'copymove'
      await dispatch 'webjet/copy', { items, dest, group, data, before, boardPath }
      nodes = {}
      for v in items
        await dispatch 'webjet/eraseNode', { path: v.path, nodes }
    else if mode == 'clone'
      await dispatch 'webjet/clone', { items, dest, group, data, before }
    else
      throw 'transer webjets: not supported mode'

  dataTransfer: ({ rootGetters }, { dest, before, dataTransfer, data, boardPath, parentCard = null }, operation)->
    targetWebjet = await firebind dest
    #type = dataTransfer.types[0]
    type = undefined
    # at first find webjet type
    for t in dataTransfer.types
      if t.split('/')[0] == 'webjets'
        type = t
        break
    # if no webjet type find another type
    unless type
      for wdtt in rootGetters['webjet/dataTransferTypes']
        if wdtt in dataTransfer.types
          type = wdtt
          break

    sort = generateSort(targetWebjet, before)
    connection = Object.assign {}, data, sort

    if type == 'text/html'
      # { category, webjet } = getWebjetForText dataTransfer.getData(type)
      # result = await operation.dispatch 'webjet/create',
      #   dest: dest
      #   boardPath: boardPath
      #   category: category
      #   connection: connection
      #   webjet: webjet
      # if result
      #   filesInText = await webjetsFromString dataTransfer.getData(type)
      #   console.log 'filesInText', filesInText, webjet
      #   console.log 'result', result, dest
      { webjets, dataTransfer} = await webjetsFromString dataTransfer.getData(type)
      wPath = null
      # console.log 'targetWebjet', targetWebjet
      console.log 'webjets', webjets
      if webjets.length
        firstWebjet = webjets.splice(0,1)[0]
        result = await operation.dispatch 'webjet/create',
          dest: dest
          boardPath: boardPath
          category: firstWebjet.category
          connection: connection
          webjet: firstWebjet.webjet
        # console.log '!1', sort
        if result
          if targetWebjet.category == '/webjets/containers/webjets/canvas'
            dest = result.webjetPath
            before = false
          before = false if before == true
          for w in webjets
            targetWebjet = await firebind dest
            sort = generateSort(targetWebjet, before)
            connection = Object.assign {}, data, sort
            # console.log '!2', sort,before, targetWebjet
            tmpWebjet = await operation.dispatch 'webjet/create',
              dest: dest
              boardPath: boardPath
              category: w.category
              connection: connection
              webjet: w.webjet
      if dataTransfer
        targetWebjet = await firebind dest
        sort = generateSort(targetWebjet, sort.sortKey)
        connection = Object.assign {}, data, sort
        # console.log '!3', sort
        files = await operation.dispatch 'webjet/dataTransfer',
          dest: dest
          before: before
          data: data
          boardPath: boardPath
          dataTransfer: dataTransfer

      return [ result ]
    else if type == 'text/plain'

      webjetForWejeLink = await getWebjetForWejeLink dataTransfer.getData(type)
      if webjetForWejeLink
        category = webjetForWejeLink.category
        webjet = webjetForWejeLink.webjet
      else
        { category, webjet } = getWebjetForText dataTransfer.getData(type)
      #console.log { category, webjet }
      result = await operation.dispatch 'webjet/create',
        dest: dest
        boardPath: boardPath
        category: category
        connection: connection
        webjet: webjet
      return [ result ]
    else if type == 'create/card'
      result = await operation.dispatch 'webjet/create',
        dest: dest
        boardPath: boardPath
        category: '/webjets/content/webjets/note'
        connection: connection
      return [ result ]
    else if type == 'create/list'
      result = await operation.dispatch 'webjet/create',
        dest: dest
        boardPath: boardPath
        category: '/webjets/containers/webjets/list'
        connection: connection
      return [ result ]
    else if type == 'create/graffiti'
      result = await operation.dispatch 'webjet/create',
        dest: dest
        boardPath: boardPath
        category: '/webjets/content/webjets/graffiti'
        connection: connection
      return [ result ]
    else if type == 'Files'
      results = []
      for file, i in dataTransfer.files
        await do (file, i) =>
          # define webjet's category and initial data by file
          { category, webjet } = getWebjetStubForFile(file)


          if category == '/webjets/content/webjets/photo'
            imgSizeMB = (file.size / (1024*1024)).toFixed(2)
            # if file more then 2 Mb -> compress
            if imgSizeMB > 2 and file.type != 'image/gif'
              loadedImgCancel = await scaleImg file, 1920, 5000
              file = loadedImgCancel
              webjet.meta.fileSize = file.size

          if category == 'voice'
            webjet.data.duration = data.duration
            webjet.data.author = data.author

            delete data.duration
            delete data.author

          # sorting and coordinates
          sort = generateSort(targetWebjet, before)
          connection = Object.assign {}, data, sort
          if data?.coordinates?
            connection.coordinates = {
              x: data.coordinates.x + 20 * i
              y: data.coordinates.y + 20 * i
            }
          # create webjet
          if parentCard
            dest = parentCard.webjetPath if dest == boardPath
          if i == 0 and parentCard
            await operation.dispatch 'webjet/updateWebjetWithCategory',
              path: parentCard.webjetPath
              category: category
              update: webjet
            results.push parentCard
            { webjetKey, webjetPath } = parentCard
          else
            result = await operation.dispatch 'webjet/create',
              dest: dest
              boardPath: boardPath
              category: category
              connection: connection
              webjet: webjet
            { webjetKey, webjetPath } = result
            results.push result
          # upload file
          ext = file.name.split('.')
          ext = ext[ext.length - 1]
          fileRef = getFileRef(webjetKey, ext)
          beforeunload = getBeforeUnloadHandler()
          window.addEventListener 'beforeunload', beforeunload
          meta = { cacheControl: 'private, max-age=8640000' }
          if category != '/webjets/content/webjets/pdf'
            meta.contentDisposition = "attachment;filename=#{file.name}"
          uploadTask = fileRef.put file, meta
          uploadTask.on(
            'state_changed'
            (progressData) =>
              # write progress counter to webjet
              @commit 'webjet/UPDATE_WEBJET',
                path: webjetPath
                update:
                  meta:
                    uploadedSize: progressData.bytesTransferred
            (error) =>
              console.error error
              @commit 'webjet/UPDATE_WEBJET',
                path: webjetPath,
                update:
                  meta:
                    uploadError:
                      code: error.code
                      message: error.message
              window.removeEventListener 'beforeunload', beforeunload
            (complete) =>
              # update webjet after upload (with url of uploaded file, etc)
              url = await fileRef.getDownloadURL()
              webjetUpdate = getWebjetDataForUpload(file, fileRef, url)
              @commit 'webjet/UPDATE_WEBJET',
                path: webjetPath
                update: webjetUpdate
              window.removeEventListener 'beforeunload', beforeunload
          )
      return results
    else
      console.warn 'dataTransfer: type unrecognized', type
      return []

  updateWebjetWithCategory: (_, { path, category, update }, { undo, redo, dispatch })->
    oldUpdate = await firebind path
    oldCategory = oldUpdate.category
    redo 'webjet/UPDATE_WEBJET_WITH_CATEGORY', { path, update, category }
    undo 'webjet/UPDATE_WEBJET_WITH_CATEGORY', { path, update: oldUpdate, category: oldCategory }
    await dispatch 'webjet/onUpdated'

  setBoardTheme: (_, { path, theme }, { undo, redo, dispatch })->
    board = await firebind path
    oldValue = board.theme or null
    newValue = theme
    redo 'webjet/SET_BOARD_THEME', { path, value: newValue }
    undo 'webjet/SET_BOARD_THEME', { path, value: oldValue }
    await dispatch 'webjet/onUpdated'

  updateBoardImg: (_, { path, file})->
    boardData = await firebind path
    ext = file.name.split('.')
    ext = ext[ext.length - 1]
    webjetKey = path.split('/')
    webjetKey = webjetKey.pop()
    fileRef = getFileRef(webjetKey, ext)
    beforeunload = getBeforeUnloadHandler()
    window.addEventListener 'beforeunload', beforeunload
    meta = { cacheControl: 'private, max-age=8640000' }
    uploadTask = fileRef.put file, meta
    uploadTask.on(
      'state_changed'
      (progressData) =>
        # do nothing
      (error) =>
        console.error error
        window.removeEventListener 'beforeunload', beforeunload
      (complete) =>
        try
          url = await fileRef.getDownloadURL()
          if boardData.meta?.fileName and fileRef.name != boardData.meta?.fileName
            oldFileRef = getFileRef(boardData.meta.fileName)
            await oldFileRef.delete().catch (e) -> console.error 'WARN: error delete old file', e
          webjetUpdate = getWebjetDataForUpload(file, fileRef, url)
          @commit 'webjet/UPDATE_WEBJET',
            path: path
            update: webjetUpdate
        finally
          window.removeEventListener 'beforeunload', beforeunload
    )
    return uploadTask

  deleteBoardImg: (_, { path })->
    boardData = await firebind path
    if boardData.meta?.fileName
      fileRef = getFileRef(boardData.meta.fileName)
      await fileRef.delete()
    webjetUpdate =
      data:
        url: null
      meta:
        uploadInProgress: null
        isInStorage: null
        fileName: null
    @commit 'webjet/UPDATE_WEBJET',
      path: path
      update: webjetUpdate

  updateEditorContent: (_, { path, contentDelta, contentHtml }, { dispatchWithoutHistory })->
    # we are not using undo/redo here in favor of internal editor's undo/redo
    @commit 'webjet/UPDATE_EDITOR_CONTENT', { path, contentDelta, contentHtml }
    await dispatchWithoutHistory 'webjet/onUpdated', { path }

  updateCategoryWebjet: (_, {path, category}, { undo, redo, dispatch })->
    webjet = await firebind path
    redo 'webjet/UPDATE_WEBJET_CATEGORY', { path, category }
    undo 'webjet/UPDATE_WEBJET_CATEGORY', { path, category: webjet.category }
    # await operation.dispatch 'webjet/onUpdated', { path }
    await dispatch 'webjet/onUpdated', { path }

  createCheklistItem: (_, {path, id, data}, { undo, redo, dispatch })->
    redo 'webjet/CREATE_CHEKLIST_ITEM', { path, id, data }
    undo 'webjet/REMOVE_CHEKLIST_ITEM', { path, id }
    await dispatch 'webjet/onUpdated', { path }

  removeCkecklistItem: (_, {path, id}, { undo, redo, dispatch })->
    webjet = await firebind path
    redo 'webjet/REMOVE_CHEKLIST_ITEM', { path, id }
    undo 'webjet/CREATE_CHEKLIST_ITEM', { path, id, data: webjet.data?.items?[id] }
    await dispatch 'webjet/onUpdated', { path }

  updateChecklistItem: (_, {path, id, data}, { undo, redo, dispatch })->
    webjet = await firebind path
    redo 'webjet/UPDATE_CHEKLIST_ITEM', { path, id, data }
    undo 'webjet/UPDATE_CHEKLIST_ITEM', { path, id, data: webjet.data?.items?[id], fullUpdate: true }
    await dispatch 'webjet/onUpdated', { path }

  setChecklistType: (_, {path, val}, { undo, redo, dispatch })->
    webjet = await firebind path
    oldVal = webjet.data?.type
    oldVal = 0 if webjet.data?.type == undefined
    redo 'webjet/SET_CHEKLIST_TYPE', { path, val }
    undo 'webjet/SET_CHEKLIST_TYPE', { path, val: webjet.data?.type }
    await dispatch 'webjet/onUpdated', { path }

  setHideCheckedInList: (_, {path, val}, { undo, redo, dispatch })->
    webjet = await firebind path
    oldVal = webjet.data?.hideChecked
    oldVal = 0 if webjet.data?.hideChecked == undefined
    redo 'webjet/SET_HIDE_CHECKED_IN_LIST', { path, val }
    undo 'webjet/SET_HIDE_CHECKED_IN_LIST', { path, val: webjet.data?.type }
    await dispatch 'webjet/onUpdated', { path }

  sendCommentsMassage: (_, {path, data}, { undo, redo, dispatch })->
    massageKey = getDataUserRef(path).push().key
    @commit 'webjet/SEND_COMMENTS_MASSAGE', { path, key: massageKey, data }


  setGraffitiLine: (_, {path, id, data}, { undo, redo, dispatch })->
    # @commit 'webjet/SET_GRAFFITI_LINE', { path, id, data }
    redo 'webjet/SET_GRAFFITI_LINE', { path, id, data }
    undo 'webjet/REMOVE_GRAFFITI_LINE', { path, id }
    await dispatch 'webjet/onUpdated', { path }

  removeGraffitiLine: (_, {path, id}, { undo, redo, dispatch })->
    # @commit 'webjet/REMOVE_GRAFFITI_LINE', { path, id }
    webjet = await firebind path
    redo 'webjet/REMOVE_GRAFFITI_LINE', { path, id }
    undo 'webjet/SET_GRAFFITI_LINE', { path, id, data: webjet.data?.lines?[id]}
    await dispatch 'webjet/onUpdated', { path }

  setGraffitiGrid: (_, {path, value}, { undo, redo, dispatch })->
    webjet = await firebind path
    oldVal = webjet.data?.grid
    oldVal = null if oldVal == undefined
    newVal = value
    redo 'webjet/SET_GRAFFITI_GRID', {path, value: newVal}
    undo 'webjet/SET_GRAFFITI_GRID', {path, value: oldVal}
    await dispatch 'webjet/onUpdated', { path }

  setGraffitiSizeType: (_, {path, value}, { undo, redo, dispatch })->
    webjet = await firebind path
    oldVal = webjet.data?.sizeType
    oldVal = 0 if oldVal == undefined
    newVal = value
    redo 'webjet/SET_GRAFFITI_SIZE_TYPE', {path, value: newVal}
    undo 'webjet/SET_GRAFFITI_SIZE_TYPE', {path, value: oldVal}
    await dispatch 'webjet/onUpdated', { path }

  updateShapeText: (_, {path, value}, { undo, redo, dispatch })->
    webjet = await firebind path
    redo 'webjet/UPDATE_SHAPE_TEXT', { path, value }
    undo 'webjet/UPDATE_SHAPE_TEXT', { path, value: webjet.data?.text }
    await dispatch 'webjet/onUpdated', { path }

  updateTitle: (_, {path, value}, { undo, redo, dispatch })->
    webjet = await firebind path
    redo 'webjet/UPDATE_TITLE', { path, value }
    undo 'webjet/UPDATE_TITLE', { path, value: webjet.data?.title }
    await dispatch 'webjet/onUpdated', { path }

  setActiveColumn: (_, { path, value }, { undo, redo, dispatch })->
    connection = await firebind path
    webjet = if connection.src then await firebind connection.src else connection
    oldValue = connection.activeColumn
    oldValue = null if !oldValue and oldValue != 0
    redo 'webjet/SET_ACTIVE_COLUMN', { path, value }
    undo 'webjet/SET_ACTIVE_COLUMN', { path, value: oldValue }
    await dispatch 'webjet/onUpdated', { path: connection.src }

  setAnchorSortKey: (_, { path, anchorSortKey }, { undo, redo, dispatch })->
    connection = await firebind path
    webjet = if connection.src then await firebind connection.src else connection
    oldValue = connection.anchorSortKey
    oldValue = null if !oldValue
    redo 'webjet/SET_ANCHOR_SORT_KEY', { path, anchorSortKey }
    undo 'webjet/SET_ANCHOR_SORT_KEY', { path, anchorSortKey: oldValue }
    await dispatch 'webjet/onUpdated', { path: connection.src }

  setTradingViewInterval: (_, {path, interval}, {undo, redo, dispatch}) ->
    connection = await firebind path
    webjet = if connection.src then await firebind connection.src else connection
    webjetPath = webjet.$path
    oldInterval = webjet.data?.interval || null
    newInterval = interval || null
    redo 'webjet/SET_TRADINGVIEW_INTERVAL', { path: webjetPath, interval: newInterval}
    undo 'webjet/SET_TRADINGVIEW_INTERVAL', { path: webjetPath, interval: oldInterval}
    await dispatch 'webjet/onUpdated', { path: connection.src }

  theme: (_, { items, theme }, { dispatch })->
    for v in items
      await dispatch 'webjet/setTheme', { path: v.path, theme }

  trash: (_, { items }, { dispatch })->
    for v in items
      await dispatch 'webjet/trashConnection', { path: v.path }

  collapse: (_, { items }, { dispatch })->
    for v in items
      await dispatch 'webjet/setExpand', { path: v.path, value: false }

  expand: (_, { items }, { dispatch })->
    for v in items
      await dispatch 'webjet/setExpand', { path: v.path, value: true }

  clearTrash: ({ rootGetters }, {}, { dispatch })->
    trashPath = rootGetters.currentTrashSrc
    trash = await firebind trashPath
    if trash?.connections?.trash
      for k, connection of trash.connections.trash
        await dispatch 'webjet/updateConnection',
          path: connection.$path
          data:
            trashClear: true

  groupToList: ({ rootGetters }, { dest, items, data, boardPath }, { dispatch })->
    unless dest
      dest = rootGetters.currentBoardSrc
      boardPath = dest
    unless dest
      console.warn 'groupToList: without dest'
    { webjetPath } = await dispatch 'webjet/create',
      dest: dest
      boardPath: boardPath
      category: '/webjets/containers/webjets/list'
      connection: data
    await dispatch 'webjet/move', { items, dest: webjetPath, before: false }

  # Delete boards
  # deleteMyBoard is for own boards: set boardDeleted flag to disable others access
  # deleteSharedBoard is for shared boards (of others): remove connection and unshare (this opeation can not be undone)
  deleteMyBoard: (ctx, { path }, { undo, redo })->
    redo 'webjet/SET_BOARD_DELETED_FLAG', { path, value: true }
    undo 'webjet/SET_BOARD_DELETED_FLAG', { path, value: null }
    sendAnalytics 'board_deleted'

  restoreMyBoard: (ctx, { path }, { undo, redo })->
    redo 'webjet/SET_BOARD_DELETED_FLAG', { path, value: null }
    undo 'webjet/SET_BOARD_DELETED_FLAG', { path, value: true }

  deleteSharedBoard: (ctx, { path }, { dispatchWithoutHistory })->
    # deprecated: NEW_LOAD
    connection = await firebind path
    dispatchWithoutHistory 'webjet/removeConnection', { path: path }
    for k,v of ctx.rootGetters.boards
      if v.src == connection.src && v.$path != path
        dispatchWithoutHistory 'webjet/removeConnection', { path: v.$path }
    await dispatchWithoutHistory 'webjet/share',
      src: connection.src
      uid: ctx.rootState.uid
      mode: false

  leaveBoard: (ctx, { boardId }, { dispatchWithoutHistory })->
    team = await firebind "/boards/#{boardId}/team"
    path = "/teamAccess/#{team.$value}/boards/#{ctx.rootState.uid}/#{boardId}"
    @commit 'webjet/UPDATE_TEAM_ACCESS', { path, mode: null }

  purgeBoard: ({ rootState }, { path }, { dispatchWithoutHistory })->
    connection = await firebind path
    @commit 'webjet/PURGE_BOARD_CONNECTION', { path }
    @commit 'webjet/CREATE_PURGE_BOARD_TASK', { uid: rootState.uid, path: connection.src }

  removeInboxItem: (ctx, { key }, operation)->
    @commit 'webjet/REMOVE_INBOX_ITEM', { key }

  markAsRead: (ctx, { path }, operation)->
    connection = await firebind path
    if connection.unread
      @commit 'webjet/MARK_AS_READ', { path }

  setStarred: (_, { path, value }, { undo, redo })->
    connection = await firebind path
    oldValue = connection.starred || null
    newValue = value
    newValue = null unless newValue
    redo 'webjet/SET_STARRED', { path, value: newValue }
    undo 'webjet/SET_STARRED', { path, value: oldValue }

  setBoardPin: (_, { boardId, value }, { undo, redo })->
    team = await firebind "/boards/#{boardId}/team"
    userPins = await firebind "teams/#{team.$value}/pins"
    value = null unless value
    value = -nowFirebase() if value == true
    oldValue = userPins[boardId] || null
    path = "#{userPins.$path}/#{boardId}"
    redo 'webjet/SET_BOARD_PIN', { path, value }
    undo 'webjet/SET_BOARD_PIN', { path, value: oldValue }

  onUpdated: (ctx, { path }, { undo, redo })->
    uid = ctx.rootState.uid
    if path
      webjet = await firebind path
      unless webjet.id? or webjet.$value == null
        redo 'webjet/ON_UPDATED', { path, uid }
        undo 'webjet/ON_UPDATED', { path, uid }
    currentBoardSrc = ctx.rootGetters.currentBoardSrc
    #console.log { path, currentBoardSrc }
    if currentBoardSrc && currentBoardSrc!=path
      await firebind currentBoardSrc
      redo 'webjet/ON_UPDATED', { path: currentBoardSrc, uid }
      undo 'webjet/ON_UPDATED', { path: currentBoardSrc, uid }

  # refresh webjet's data loaded from external sources (link, youtube, etc)
  refreshData: ({ rootState }, { path, data }, operation)->
    @commit 'webjet/UPDATE_WEBJET',
      path: path
      update:
        meta:
          refreshDate: new Date(nowFirebase()).toISOString()
          refreshUser: rootState.uid
        data: data

  createInviteLink: (_, { path, mode }, { undo, redo, dispatch })->
    board = await firebind path
    if board?.meta?.inviteLink
      console.warn 'Invite link already exists'
      return
    inviteCode = getRef('/inviteLinks').push().key
    if mode in [ 'rw', 'edit', 'manage', 'owner' ]
      mode = 'rw'
    else
      mode = 'r'
    redo 'webjet/CREATE_INVITE_LINK', { code: inviteCode, mode: mode, src: board.$path }
    undo 'webjet/REMOVE_INVITE_LINK', { code: inviteCode, src: board.$path }
    await dispatch 'webjet/onUpdated', { path: path }

  removeInviteLink: (_, { path, mode }, { undo, redo, dispatch })->
    board = await firebind path
    if !board?.meta?.inviteLink
      console.warn 'No invite link to remove'
      return
    redo 'webjet/REMOVE_INVITE_LINK', { code: board.meta.inviteLink.code, src: board.$path }
    undo 'webjet/CREATE_INVITE_LINK', { code: board.meta.inviteLink.code, mode: board.meta.inviteLink.mode, src: board.$path }
    await dispatch 'webjet/onUpdated', { path: path }

  updateInviteLink: (_, { path, mode }, { undo, redo, dispatch })->
    board = await firebind path
    if !board?.meta?.inviteLink
      console.warn 'No invite link to update'
      return
    if mode in [ 'rw', 'edit', 'manage', 'owner' ]
      mode = 'rw'
    else
      mode = 'r'
    redo 'webjet/UPDATE_INVITE_LINK', { mode: mode, src: board.$path }
    undo 'webjet/UPDATE_INVITE_LINK', { mode: board.meta.inviteLink.mode, src: board.$path }
    await dispatch 'webjet/onUpdated', { path: path }

  sendInviteLink: ({ rootState }, { path, email }, operation)->
    board = await firebind path
    if !board?.meta?.inviteLink
      console.warn 'No invite link to send'
      return
    @commit 'webjet/WRITE_SEND_INVITE_LINK_TASK', { uid: rootState.uid, code: board.meta.inviteLink.code, email }

  setVoiceTranscription: (_, { path, transcription }, { dispatch })->
    @commit 'webjet/SET_VOICE_TRANSCRIPTION', { path, transcription }

  setVoiceGraphPeaks: (_, { path, peaks }, { dispatch })->
    @commit 'webjet/SET_VOICE_GRAPH_PEAKS', { path, peaks }

  setDateTime: (_, { path, datetime }, { undo, redo, dispatch })->
    webjet = await firebind path
    oldVal = webjet.data?.datetime
    oldVal = null if oldVal == undefined
    newVal = datetime
    redo 'webjet/SET_DATE_TIME', { path, datetime: newVal }
    undo 'webjet/SET_DATE_TIME', { path, datetime: oldVal }
    await dispatch 'webjet/onUpdated', { path }

  setDateCompleted: (_, { path, completed }, { undo, redo, dispatch })->
    webjet = await firebind path
    oldVal = webjet.data?.completed
    oldVal = null if oldVal == undefined
    newVal = completed
    redo 'webjet/SET_DATE_COMPLETED', { path, completed: newVal }
    undo 'webjet/SET_DATE_COMPLETED', { path, completed: oldVal }
    await dispatch 'webjet/onUpdated', { path }

  setMentionedStatus: (_, { path, active }, { undo, redo, dispatch })->
    webjet = await firebind path
    oldVal = webjet.data?.active
    oldVal = null if oldVal == undefined
    newVal = active
    redo 'webjet/SET_MENTIONED_STATUS', { path, active: newVal }
    undo 'webjet/SET_MENTIONED_STATUS', { path, active: oldVal }
    await dispatch 'webjet/onUpdated', { path }

  deleteBoard: ({ rootGetters, rootState }, { boardId }) ->
    if !boardId
      console.warn 'No board for delete'
      return
    project = await firebind "/boards/#{boardId}/project"
    team = await firebind "/boards/#{boardId}/team"
    uid = rootState?.uid
    boardAccess = rootGetters.boardAccess boardId
    return if boardAccess?.access != 'manage'
    boardPurged = await firebind "/boards/#{boardId}/boardPurged"
    return if boardPurged?.$value == true
    @commit 'webjet/DELETE_BOARD',
      boardId: boardId
      teamId: team?.$value
      projectId: project?.$value
    @commit 'webjet/CREATE_PURGE_BOARD_TASK',
      uid: uid
      path: "/boards/#{boardId}"
    if rootState.route.name == "Board"
      if rootState?.route?.params?.boardId == boardId
        await router.replace { name: 'Home' }

  moveBoardToProject: (_, { boardId, projectId })->
    if !boardId or !projectId
      console.warn 'Cant move board to project'
      return
    @commit 'webjet/MOVE_BOARD_TO_PROJECT',
      boardId: boardId
      projectId: projectId

  alignItems: (_, { items }, { dispatch })->
    for item in items
      await dispatch 'webjet/setCoordinates',
        path: item.path
        x: item.x
        y: item.y
