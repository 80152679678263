












import Logo from '@/components/Logo'
export default
  name: 'Wait'
  components: { Logo }
