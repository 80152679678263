





















import { mdiChevronDown, mdiClose, mdiCheck } from '@mdi/js'
import { getOwnerUID,userDisplayName,userCheсkData,userInfo } from '@/utils'
import { sendAnalytics } from '@/utils'
import Avatar from '@/components/Avatar.vue'

export default
  name:
    "CreateBoardCollabItem"
  inject:
    provideApp: { default: false }
    provideCreateBoardCollaborations: { default: false }
  props:
    src: String
    uid:
      {
        type: String,
        default: ''
      }
    license: String
    owner:
      {
        type: Boolean,
        default: false
      }
    showConfiguration:
      {
        type: Boolean,
        default: false
      }
  components:
    { Avatar }
  data: ->
    iconArrow: mdiChevronDown
    iconRemove: mdiClose
    iconCheck: mdiCheck
  methods:
    changeLicense: (license) ->
      if license == 'edit' and @provideCreateBoardCollaborations.limitIsOver
        @provideApp.dialogMaxLimitTeamId = @provideCreateBoardCollaborations.teamId
        @provideApp.dialogMaxLimitTeam = true
      else
        @provideCreateBoardCollaborations.changeLicenseSelectedUser @uid, license
    remove: ->
      @provideCreateBoardCollaborations.removeFromSelected @uid
  computed:
    showUser: ->
      userCheсkData @userId
    can: ->
      [
        {value: "view", text: @$t('board.share.canView')},
        {value: "edit", text: @$t('board.share.canEdit')}
      ]
    titleLicense: ->
      title = @can.find (c) =>
        c.value == @license
      return title?.text
    canSelect: ->
        # TODO: set new user rights on board on set:->
        @license
    userName: ->
      if @userId == @$store.state.uid
        return @$t('common.you')
      else
        userDisplayName @userId
    userEmail: ->
      mail = @userMail?.$value
      if mail
        return mail
    userFullInfo: ->
      userFullInfo = userInfo @userId
      userFullInfo
    userId: ->
      id = if @owner then @boardOwner else @uid
      return id
