
















































import Avatar from '@/components/Avatar.vue'
import { mapGetters } from 'vuex'
import { getOwnerUID } from '@/utils'
import { boardAudio } from '@/websockets'
import { wsBoard } from '@/websockets'

export default
  name: 'Boardhead'
  inject: ['provideApp']
  components: { Avatar }
  data: ->
    users: []
    channel: 'main'
  computed: Object.assign {},
    mapGetters ['currentBoardSrc', 'currentBoardConnectionSrc', 'currentBoardId']
    title: ->
      @data?.title

    boardOwner: -> getOwnerUID(@currentBoardSrc)
    isReadOnlyBoard: ->
      return null unless @shared
      return false if @boardOwner == @$store.state.uid
      return false if @shared?['*']?.includes 'w'
      return false if @shared?[@$store.state.uid]?.includes 'w'
      true
    navbarIsOpen: ->
      @provideApp.navbarIsOpen
    boardAccess: ->
      @$store.getters.boardAccess @currentBoardId
    isPublic: ->
      if FLAG.NEW_LOAD
        @boardAccess.public
      else
        for key, value of @shared
          if key == '*'
            return true
        return false
    readyChannel: ->
      @activeChannel and @activeSession and @activeStream
    activeStream: ->
      boardAudio.state.stream
    activeChannel: ->
      boardAudio.state.channel
    activeSession: ->
      boardAudio.state.session
    audioDevices: ->
      boardAudio.state.audioDevices
    isMuted: ->
      boardAudio.state.isMuted
    audiochannel: ->
      wsBoard.get 'audiochannel'
    publisher: ->
      boardAudio.state.publisher
    userMoreCount: ->
      if @users?.size >= 6
        return 0
      else
        return @users?.size - 6
  firebind:
    data: -> @currentBoardSrc and "#{@currentBoardSrc}/data"
    shared: -> @currentBoardSrc and "#{@currentBoardSrc}/shared"
  methods:
    navbarOpen: ->
      @provideApp.navbarOpen()
    sidebarOpen: ->
      document.querySelector('#board-page >.canvas >.canvas-sidebar-content').__vue__.openClose()
    leaveSession: () ->
      boardAudio.leaveSession()
    toggleAudio: () ->
      boardAudio.togglePublisherAudio()
    switchAudio: (label) ->
      boardAudio.switchAudioDevice label
    joinSession: () ->
      boardAudio.joinSession @channel unless @publisher
    isTalking: (user) ->
      return false if not @publisher

      if user == @$store.state.uid
        return boardAudio.state.isTalking
      else
        subscriber = boardAudio.state.subscribers.find (s) => s.userId == user
        return subscriber?.isTalking
    addUser: (data) ->
      if data.channel == @channel and !@users.includes data.user
        @users.push data.user
   watch:
    audiochannel:
      immediate: true
      handler: (value) ->
        return unless value
        @users = @users.filter (u) => u == @$store.state.uid
        Object.keys(value).forEach (key) =>
          @addUser value[key]
    publisher:
      immediate: true
      handler: (value) ->
        if value
          @users = @users.filter (u) => u != @$store.state.uid
          @users.push @$store.state.uid
        else
          @users = @users.filter (u) => u != @$store.state.uid
