import router from "@/router"
import store from "@/store"

undoredo =
  namespaced: true

  state:
    undo: []
    redo: []

  mutations:
    ADD: (state, payload)->
      state.undo.push payload
      state.redo = []

    UNDO: (state)->
      state.redo.push state.undo.pop()

    REDO: (state)->
      state.undo.push state.redo.pop()

    RESET: (state)->
      state.undo = []
      state.redo = []

  actions:
    undo: ({commit, state})->
      if state.undo.length > 0
        syncMode = store.state.firebind.syncMode
        store.state.firebind.syncMode = true
        operation = state.undo[state.undo.length - 1]
        operation = [operation] unless Array.isArray operation
        for o in operation.slice(0).reverse()
          @commit o.undo... if o.undo
        commit 'UNDO'
        store.state.firebind.syncMode = syncMode
      else
        console.warn 'undoredo: undo empty'

    redo: ({commit, state})->
      if state.redo.length > 0
        syncMode = store.state.firebind.syncMode
        store.state.firebind.syncMode = true
        operation = state.redo[state.redo.length - 1]
        operation = [operation] unless Array.isArray operation
        for o in operation
          @commit o.redo... if o.redo
        commit 'REDO'
        store.state.firebind.syncMode = syncMode
      else
        console.warn 'undoredo: redo empty'

    reset: ({commit})->
      commit 'RESET'

router.beforeEach (to, from, next)->
  store.dispatch 'undoredo/reset'
  next()


export default undoredo
export { undoredo }
