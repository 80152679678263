isAppended = false
isLoaded = false
script = null

export default $tradingview = ->
  new Promise (rs, rj) ->
    if isAppended
      if !isLoaded
        script.addEventListener 'load', handler = ->
          rs isAppended
          script.removeEventListener 'load', handler
      else
        rs isAppended
    else
      isAppended = true
      script = document.createElement 'script'
      script.src = 'https://s3.tradingview.com/tv.js'
      script.async = true
      script.addEventListener 'load', handler = ->
        isLoaded = true
        rs isAppended
        script.removeEventListener 'load', handler
      document.head.append(script)
