












export default
  name: 'HelpButton'
  methods:
    closeHelp: ->
      @$store.state.help = false
