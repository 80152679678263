import { getRef } from '@/utils'

export default
  SET_POSITION: (_, { path, x, y })->
    getRef(path).child('data').child('x').set x
    getRef(path).child('data').child('y').set y

  SET_WIDTH: (_, { path, width })->
    getRef(path).child('data').child('width').set width

  SET_STYLE: (_, { path, style })->
    getRef(path).child('data').child('style').set style

  SET_POINT_STYLE: (_, { path, point, style })->
    getRef(path).child('data').child(point).child('style').set style

  SET_TITLE: (_, { path, title })->
    getRef(path).child('data').child('title').set title

  SET_THEME: (_, { path, id })->
    getRef(path).child('data').child('backgroundId').set id

  # SET_ARROW
  # SET_STYLE
  # SET_TEXT
