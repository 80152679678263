

























import Avatar from '@/components/Avatar.vue'
import { userDisplayName } from '@/utils'
export default
  name:
    "CollabUser"
  props:
    uid: String
    edit: Boolean
    rights: String
  components:
    { Avatar }

  computed:
    showUser: ->
      true
    userName: ->
      if @uid == @$store.state.uid
        return @$t('common.you')
      else
        userDisplayName @uid
    accessRights: ->
      {
        manager: 'Can manage'
        edit: 'Can edit'
        # comment: 'Can comment' ?
        view: 'Can view'
      }
    rightsTitle: ->
      @accessRights[@rights]
    userEmail: ->
      mail = @firebind "/users/#{@uid}/email"
      mail = mail?.$value
      if mail
        return mail
  methods:
    selectRights: (rights) ->
      @$emit 'selectRights', {userId: @uid, rights: rights }
    deleteFromProject: ->
      @$emit 'deleteFromProject', @uid
