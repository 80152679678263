





export default
  name: 'SceneWebjets'
  inject: ['provideCanvas']
  computed:
    paints: -> @provideCanvas.paintWebjets
    webjets: -> @provideCanvas.webjets
    collaborateDrawMode: -> @provideCanvas.collaborateDrawMode
    drawPaintIsActive: -> @provideCanvas.drawPaintIsActive
    attach: -> !@provideCanvas.showMinimap
