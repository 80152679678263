# class for create scalable websockets like wsBoard

import { io } from 'socket.io-client'
import Vue from 'vue'
import store from '@/store'
import { nowFirebase } from '@/utils'

class CollaborateWebsocket
  constructor: (host, { scale = false, key = false, delay = 1 })->
    @ws = false
    @state = Vue.observable
      key: key
      uid: false
      scale: scale
      host: host
      share: {}
      shareTime: {}
      wsData: {}
      wsShare: {}
      wsStats:
        traffic:
          input: 0
          output: 0
          inputBy: {}
          outputBy: {}
        messages:
          input: 0
          output: 0
          inputBy: {}
          outputBy: {}
      #wsEntity: {}
    setTimeout (=> @watchUser()), delay
  watchUser: ()->
    store.watch (-> store.state.uid), =>
      @state.uid = store.state.uid
      @connect() if @state.key? and @state.scale and @state.uid
    , { immediate: true }
  setScale: (value)->
    return if @state.scale == value
    @state.scale = value
    @connect() if @state.key? and @state.scale and @state.uid
  setKey: (value)->
    return if @state.key == value
    @state.key = value
    @connect() if @state.key? and @state.scale and @state.uid

  hashCode: (str)->
    str = String str
    hash = 0
    return hash if str.length == 0
    for v, i in str
      chr   = str.charCodeAt(i)
      hash  = ((hash << 5) - hash) + chr
      hash |= 0
    Math.abs hash

  url: ()->
    #if @state.host.includes('localhost')
    if @state.host.startsWith 'http'
      return @state.host
    server = (@hashCode(@state.key) % @state.scale)
    "https://ws#{server}.#{@state.host}"

  connect: ()->
    console.warn 'CollaborateWebsocket: no uid' unless store.state.uid
    if @ws
      @ws.disconnect()
      @resetData()
    if @state.uid and @state.key and @state.scale
      @ws = io @url(), { autoConnect: false, transports: ["websocket"] }
      @ws.on 'data', (data)=> @ondata(data)
      @ws.on 'remove', (id)=> @onremove(id)
      @ws.on 'connect', ()=> @onreconnect()
      @ws.on 'enable', (value)=> @onenable(value)
      @ws.auth = { uid: store.state.uid, room: @state.key }
      @ws.connect()

  resetData: () ->
    Vue.set  @state, 'wsData', {}
    Vue.set  @state, 'wsShare', {}

  share: (name, value, force = false)->
    if @state.share[name] == undefined
      Vue.set @state.share, name, null
      Vue.set @state.shareTime, name, null
    if force or (@state.share[name] != value and JSON.stringify(value) != JSON.stringify(@state.share[name]))
      @state.share[name] = value
      now = nowFirebase()
      if value
        Object.defineProperty @state.share[name], '$ts', { value: now }
      @state.shareTime[name] = now
      if @ws?.connected and @enabled
        @ws.emit 'data', [{ name: name, ts: now, value: value }]

  get: (name)->
    unless @state.wsShare[name]
      Vue.set @state.wsShare, name, null
    @state.wsShare[name]

  getSelf: (name)->
    unless @state.share[name]
      Vue.set @state.share, name, null
    @state.share[name]
  onreconnect: ()->
    @resetData()
    return unless @enabled
    toSend = []
    for k, v of @state.share
      toSend.push { name: k, ts: @state.shareTime[k], value: v }
    if toSend.length
      @ws.emit 'data', toSend

  onremove: (id)->
    Vue.delete @state.wsData, id
    for k, v of @state.wsShare
      if v and id of v
        Vue.delete v, id

  onenable: (value)->
    @enabled = value
    if value
      toSend = []
      for k, v of @state.share
        toSend.push { name: k, ts: @state.shareTime[k], value: v }
      if toSend.length
        @ws.emit 'data', toSend

  ondata: (data)->
    if IS_DEVELOPMENT or IS_STAGE
      stats = @state.wsStats
      stats.messages.input += 1
      stats.traffic.input += JSON.stringify(data).length
      for v in data
        Vue.set(stats.messages.inputBy, v.name, 0) unless stats.messages.inputBy[v.name]
        Vue.set(stats.traffic.inputBy, v.name, 0) unless stats.traffic.inputBy[v.name]
        stats.messages.inputBy[v.name] += 1
        stats.traffic.inputBy[v.name] += JSON.stringify(v).length
    for v in data
      if v.value
        Object.defineProperty v.value, '$uid', { value: v.uid }
        Object.defineProperty v.value, '$id', { value: v.id }
        Object.defineProperty v.value, '$ts', { value: v.ts }
        Object.freeze v.value

        Vue.set @state.wsData, v.id, {} unless @state.wsData[v.id]
        connectionState = @state.wsData[v.id]
        Object.defineProperty connectionState, '$uid', { value: v.uid }
        Object.defineProperty connectionState, '$id', { value: v.id }
        Vue.set connectionState, v.name, v.value
        Vue.set @state.wsShare, v.name, {} unless @state.wsShare[v.name]
        byprop = @state.wsShare[v.name]
        Vue.set byprop, v.id, v.value
      else
        Vue.set @state.wsData, v.id, {} unless @state.wsData[v.id]
        Vue.delete @state.wsData[v.id], v.name
        Vue.set @state.wsShare, v.name, {} unless @state.wsShare[v.name]
        Vue.delete @state.wsShare[v.name], v.id



export default CollaborateWebsocket
export CollaborateWebsocket = CollaborateWebsocket
