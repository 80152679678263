












import ContextBtn from './ContextBtn'
export default
  name: 'CheckListType'
  components: { ContextBtn }
  inject: { provideCanvas: { default: null } }

  if: (selection)->
    return selection.length == 1 and selection[0].webjet.category == 'checklist'

  computed:
    hideCompleted: ->
      @type == 0
    selection: -> @$store.getters['webjet/selected']
    type: ->
      type = @selection[0].webjet?.data?.type
      return 0 if type == undefined
      type
  methods:
    action: ->
      if @type
        type = 0
      else
        type = 1
      @$store.dispatch 'webjet/setChecklistType',
        path: @selection[0].webjet.$path
        val: type
