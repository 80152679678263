











export default
  name:
    "BoardCover"
  props:
    boardId: String
  computed:
    url: ->
      url = @firebind "/boards/#{@boardId}/data/url"
      url.$value
    title: ->
      title = @firebind "/boards/#{@boardId}/data/title"
      title.$value
    initials: ->
      return '' unless @title
      result = ''
      words = @title.replace(/[^\p{L}\p{Z}^$\n]/gu, '').split(' ')
      result += words[0][0] if words[0]
      result += words[1][0] if words[1]
      result.toUpperCase()
