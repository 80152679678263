import { getRef } from '@/utils'
export default

  CREATE_WEJE_RECORD: (_, { id, board, user, creator })->
    getRef("/wejes/#{id}").set { board, user, creator }

  REMOVE_WEJE_RECORD: (_, { id })->
    getRef("/wejes/#{id}").set null

  SET_PUBLIC: (_, {path, value})->
    getRef(path).child('public').set value

  SHARE_PRIVATE: (_, {path, uid, mode})->
    getRef(path).child('private').child(uid).set mode
