











































































import { date,getOwnerUID } from '@/utils'
import { mdiClose  } from '@mdi/js'
import BoardCollaborations from './BoardCollaborations'
import BoardSettings from './BoardSettings'
import DialogTemplate from '@/components/DialogTemplate'
import router from '@/router'

export default
  name:
    "BoardConfigModal"
  components:
    {
      BoardCollaborations,
      BoardSettings,
      DialogTemplate
    }
  props:
    value: Boolean
    src: String
  data: ->
    icon: mdiClose
    boardConfigTabs: null
  computed:
    isMobile: ->
      @$vuetify.breakpoint.width < 800
    isRegisteredUser: ->
      @$store.state.isRegisteredUser
    isAnonymousUser: ->
      @$store.state.isAnonymousUser
    userCanEdit: ->
      return false unless @shared
      return true if @boardOwner == @$store.state.uid
      return true if @shared?['*']?.includes 'w'
      return true if @shared?[@$store.state.uid]?.includes 'w'
      false
    boardLicense: ->
      license = null
      if @myBoard
        return license = 'rw'
      else if @shared?[@$store.state.uid]
        return license = @shared?[@$store.state.uid]
      else
        return license
    myBoard: ->
      @boardOwner == @$store.state.uid
    boardOwner: ->
      getOwnerUID @connection?.src
    ownerName: ->
      if @myBoard
        return @$t('common.me')
      else
        name = ''
        name += @ownerFirstName?.$value + ' ' if @ownerFirstName?.$value
        name += @ownerLastName?.$value if @ownerLastName?.$value
        name = @ownerMail?.$value unless name
        return name
    boardModified: ->
      date @boardMeta?.updateDate
    boaerdCreated: ->
      date @boardMeta?.createDate
    boardInfo: ->
      [
        {name: @$t('board.config.modified'), data: @boardModified},
        {name: @$t('board.config.created'), data: @boaerdCreated},
        {name: @$t('board.config.owner'), data: @ownerName}
      ]
    show:
      get: ->
        @value
      set: ->
        @$store.dispatch 'undoredo/reset'
        @$emit 'input', false
    title:
      get: ->
        @board?.data?.title
      set: (value)->
        @$store.dispatch 'webjet/updateTitle',
          path: @board?.$path
          value: value
    connection: ->
      if @src.includes('/')
        @firebind @src
      else
        { src: "/boards/#{@src}" }
  watch:
    show: (val) ->
      @$store.dispatch 'undoredo/reset'
  firebind:
    #connection: -> @src
    board: -> @connection?.src
    boardMeta: ->
      @connection?.src and "#{@connection.src}/meta"
    shared: ->
      @connection?.src and "#{@connection.src}/shared"
    ownerFirstName: ->
      @connection?.src and '/users/'+@boardOwner+'/firstName'
    ownerLastName: ->
      @connection?.src and '/users/'+@boardOwner+'/lastName'
    ownerMail: ->
      @connection?.src and '/users/'+@boardOwner+'/email'
  methods:
    close: ->
      @$emit 'input', false
    hotkeyBlocker: (e) ->
      # allow undo/redo
      if e.ctrlKey && e.keyCode in [ 89, 90 ]
        return
      # block other hotkeys
      e.stopPropagation()
      # block ctrl +/-/0
      if e.ctrlKey && e.keyCode in [ 187, 189, 48, 107, 109, 45, 43, 96 ]
        e.preventDefault()
