import Auth from '../views/Auth.vue'
import Board from '../views/Board.vue'
import Error404 from '../views/Error404'
import Account from '../views/Account'
import Unsorted from '../views/Unsorted'
import Settings from '../views/Settings'
import DeletedAccount from '../views/DeletedAccount'
import Wait from '../views/Wait.vue'
import InactiveLink from '../views/InactiveLink.vue'
import AnonymousForbidden from '../views/AnonymousForbidden.vue'
import WejeSite from '../views/WejeSite'
import TeamHome from '../views/TeamHome'
import TeamSettings from '../views/TeamSettings'
import TeamAccess from '../views/TeamAccess'
import TeamPlan from '../views/TeamPlan'
import TeamTrash from '../views/TeamTrash'
import ShareDataProcessing from '../views/ShareDataProcessing'

import NavbarTeamSpaces from '@/views/navbar/NavbarTeamSpaces'
import NavbarTeamSettings from '@/views/navbar/NavbarTeamSettings'
import NavbarAccount from '@/views/navbar/NavbarAccount'

import { isIframe } from '@/utils'

routes = []
route = (r)-> routes.push r


# Team routes

route
  path: '/'
  name: 'Home'
  props: { default: true, navbar: true }
  components:
    default: TeamHome
    navbar: NavbarTeamSpaces

route
  path: '/t/:teamId'
  name: 'Team'
  props: { default: true, navbar: true }
  components:
    default: TeamHome
    navbar: NavbarTeamSpaces

route
  path: '/b/:boardId/:userId?'
  name: 'Board'
  #props: true
  props: { default: true, navbar: true }
  components:
    default: Board
    navbar: NavbarTeamSpaces

if FLAG.TEAM_SETTINGS
  route
    path: '/t/:teamId/settings'
    name: 'TeamSettings'
    props: { default: true, navbar: true }
    components:
      default: TeamSettings
      navbar: NavbarTeamSettings

  route
    path: '/t/:teamId/access'
    name: 'TeamAccess'
    props: { default: true, navbar: true }
    components:
      default: TeamAccess
      navbar: NavbarTeamSettings

  route
    path: '/t/:teamId/team_plan'
    name: 'TeamPlan'
    props: { default: true, navbar: true }
    components:
      default: TeamPlan
      navbar: NavbarTeamSettings

  route
    path: '/t/:teamId/team_trash'
    name: 'TeamTrash'
    props: { default: true, navbar: true }
    components:
      default: TeamTrash
      navbar: NavbarTeamSettings


# Account routes

route
  path: '/unsorted'
  name: 'Unsorted'
  components:
    default: Unsorted
    navbar: NavbarAccount

route
  path: '/account'
  name: 'Account'
  components:
    default: Account
    navbar: NavbarAccount



# Other

route
  path: '/wait'
  name: 'Wait'
  component: Wait

route
  path: '/inactivelink'
  name: 'InactiveLink'
  component: InactiveLink

route
  path: '/anonymousforbidden'
  name: 'AnonymousForbidden'
  component: AnonymousForbidden

route
  path: '/login'
  name: 'Login'
  component: Auth
  meta:
    requireNoUser: true

route
  path: '/signup'
  name: 'SignUp'
  component: Auth
  meta:
    requireNoUser: true

route
  path: '/resetpassword'
  name: 'ResetPassword'
  component: Auth
  meta:
    requireNoUser: true

route
  path: '/deleted_account'
  name: 'DeletedAccount'
  component: DeletedAccount
  meta:
    requireNoUser: true

route
  path: '/w/:siteId'
  name: 'WejeSite'
  props: true
  component: WejeSite

route
  path: '/share_data_processing'
  name: 'ShareDataProcessing'
  component: ShareDataProcessing



# redirects

route
  path: '/my'
  redirect: '/'

route
  path: '/shared'
  redirect: '/'

route
  path: '/starred'
  redirect: '/'

route
  path: '/trash'
  redirect: '/'



route
  path: '*'
  name: 'Error404'
  component: Error404


if FLAG.WEJE_IFRAME and isIframe
  routes = [
    {
      path: '*'
      name: 'Error404'
      component: Error404
    }
  ]

if FLAG.WEJE_IFRAME_NEW and isIframe
  routes = [
    {
      path: '*'
      name: 'Error404'
      component: Error404
    },
    {
      path: '/b/:boardId/:userId?'
      name: 'Board'
      props: { default: true, navbar: true }
      components:
        default: Board
        navbar: NavbarTeamSpaces
    }
  ]

export default routes
