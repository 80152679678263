








import ContextBtn from './ContextBtn'
export default
  name: 'RefreshLinks'
  components: { ContextBtn }

  if: (selection)->
    return false unless FLAG.REFRESH_LINKS
    return false if selection.length < 1
    canRefresh = selection.every (w) =>
      return true if w.webjet?.category in [
        '/webjets/content/webjets/link'
      ] and w.webjet?.meta?.refreshDate
    return canRefresh

  computed:
    selection: -> @$store.getters['webjet/selected']

  methods:
    action: ->
      for w in @selection
        w.loadData(true)
