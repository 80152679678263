









export default
  name: 'HelpArrow'
  props: ['href']
  computed:
    show: ->
      FLAG.HELP and @$store.state.help
