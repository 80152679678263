import { isMobileOrTablet, isIOS } from '@/utils'
import { debounce, after } from '@/utils'

export default
  data: ->
    zoom: { level: 0, dx: 0, dy: 0 }
    position: { x: 0, y: 0 }
    willChange: 'transform'
    transition: ''
    animation: { active: false, x1: 0, y1: 0, x2: 0, y2: 0 }
    sectors: {}

  created: ->
    @sceneInitPosition()
    @nrSectors = @sectors # for non reactive access
    @debouncedSceneSaveToSessionStorage = debounce (-> @sceneSaveToSessionStorage()), 100

  computed:
    scale: -> Math.pow 1.2, @zoom.level
    x: -> @position.x + @zoom.dx
    y: -> @position.y + @zoom.dy
    left: -> "#{Math.round( @position.x )}px"
    top: -> "#{Math.round( @position.y )}px"
    transform: ->
      if FLAG.SAFARI_BUG
        if @animation.active
          "translateX(#{ @animate.x }px) " +
          "translateY(#{ @animate.y }px) " +
          "scale(#{@animate.scale})"
        else
          "translateX(#{Math.round( @zoom.dx )}px) " +
          "translateY(#{Math.round( @zoom.dy )}px) " +
          "scale(#{@scale})"
      else
        if @animation.active
          "translateX(#{ @animate.x }px) " +
          "translateY(#{ @animate.y }px) " +
          "translateZ(0px)" +
          "scale(#{@animate.scale})"
        else
          "translateX(#{Math.round( @zoom.dx )}px) " +
          "translateY(#{Math.round( @zoom.dy )}px) " +
          "translateZ(0px)" +
          "scale(#{@scale})"

    sceneStyles: -> { @transform, @left, @top, @willChange, @transition }

    scene: ->
      x1 = - @x / @scale
      y1 = - @y / @scale
      x2 = x1 + @screen.width / @scale
      #x2 = x1 + @$vuetify.breakpoint.width / @scale
      y2 = y1 + @screen.height / @scale
      #y2 = y1 + @$vuetify.breakpoint.height / @scale
      if FLAG.CANVAS_VISIBILITY_DEVELOPMENT
        { x1, x2, y1, y2 } = @devSceneRect { x1, x2, y1, y2 }
      if @animation.active
        x1 = @animation.x1 if x1 > @animation.x1
        x2 = @animation.x2 if x2 < @animation.x2
        y1 = @animation.y1 if y1 > @animation.y1
        y2 = @animation.y2 if y2 < @animation.y2
      { x1, y1, x2, y2 }

    sceneX: -> @scene.x1
    sceneY: -> @scene.y1
    sceneWidth: -> @scene.x2 - @scene.x1
    sceneHeight: -> @scene.y2 - @scene.y1


  watch:
    sceneX:(val) ->
      if @presentationIsActive and @anchorWebjet
        rect = @anchorWebjet.elementSceneRect
        if rect.x2 - 20 <= val
          @position.x = (- (rect.x2 - 20) * @scale) - @zoom.dx
        if rect.x1 + 20 >= val + @sceneWidth
          @position.x = (- ((rect.x1 + 20) - @sceneWidth) * @scale) - @zoom.dx

    sceneY: (val) ->
      if @presentationIsActive and @anchorWebjet
        rect = @anchorWebjet.elementSceneRect
        if rect.y2 - 20 <= val
          @position.y = (- (rect.y2 - 20) * @scale) - @zoom.dy
        if rect.y1 + 20 >= val + @sceneHeight
          @position.y = (- ((rect.y1 + 20) - @sceneHeight) * @scale) - @zoom.dy

    'zoom.level': (val)->
      if val < -25
        after 100, => @zoomToLevel -25
      if val > 25
        after 100, => @zoomToLevel 25
      # if @presentationIsActive and val < -6
      #   after 100, => @zoomToLevel -6
    scene: ->
      for k, v of @sectors
        @checkSector(v)
      @debouncedSceneSaveToSessionStorage()

  methods:
    devSceneRect: (rect)->
      { x1, x2, y1, y2 } = rect
      centerX = x1 + (x2 - x1) / 2
      centerY = y1 + (y2 - y1) / 2
      x1 = centerX - (centerX - x1) / 2
      x2 = centerX + (x2 - centerX) / 2
      y1 = centerY - (centerY - y1) / 2
      y2 = centerY + (y2 - centerY) / 2
      return { x1, x2, y1, y2 }

    devSceneStyles: (rect)->
      { x1, x2, y1, y2 } = rect
      return
        left: "#{x1}px"
        top: "#{y1}px"
        width: "#{x2 - x1}px"
        height: "#{y2 - y1}px"

    sceneSaveToSessionStorage: ()->
      x1 = - @x / @scale
      y1 = - @y / @scale
      x2 = x1 + @$vuetify.breakpoint.width / @scale
      y2 = y1 + @$vuetify.breakpoint.height / @scale
      centerX = (x2 + x1) / 2
      centerY = (y2 + y1) / 2
      sessionStorage["#{@src}_scene"] = JSON.stringify { centerX, centerY, level: @zoom.level }

    sceneRestoreFromSessionStorage: ()->
      scene = sessionStorage["#{@src}_scene"]
      if scene
        { centerX, centerY, level } = JSON.parse scene
        @withoutAnimation =>
          @zoom.level = level
          @sceneMoveCenterTo centerX, centerY



    checkSector: (sector)->
      x1 = sector.x * 1000
      x2 = x1 + 1000
      y1 = sector.y * 1000
      y2 = y1 + 20000
      rect = @scene
      sector.intersect = !(rect.x1 > x2 or rect.x2 < x1 or rect.y1 > y2 or rect.y2 < y1)
      y2 = y1 + 1000
      sector.inside = x1 > rect.x1 and x2 < rect.x2 and y1 > rect.y1 and y2 < rect.y2

    getSector: (x, y)->
      key = "#{x}_#{y}"
      if @nrSectors[key] == undefined
        @$set @nrSectors, key, { x, y, intersect: null, inside: null }
        @checkSector @nrSectors[key]
      @nrSectors[key]


    sceneMoveCenterTo: (x, y)->
      nowX = - @x / @scale
      nowY = - @y / @scale
      screenWith = @screen.width
      screenWith = @$vuetify.breakpoint.width if @presentationIsActive
      if @$refs?.sidebar?.currentMode == 'full'
        screenWith -= 360
      # if @$refs?.sidebarContent?.x
        # screenWith += @$refs?.sidebarContent?.x
      width = screenWith / @scale
      height = @screen.height / @scale
      dx = (nowX + width / 2 - x)
      dy = (nowY + height / 2 - y)
      if dx != 0 or dy != 0
        @zoom.dx += dx * @scale
        @zoom.dy += dy * @scale

    scenePositionForEdit: (webjet)->
      return unless isMobileOrTablet

      rect = webjet.elementSceneRect

      width = @$vuetify.breakpoint.width / @scale
      w = rect.x2 - rect.x1
      if isMobileOrTablet && (w > width || window.outerWidth < 1024 &&  w < width / 2)
        newScale = (width / (w + 8)) * @scale
        @zoom.level = Math.log(newScale) / Math.log(1.2)
        width = @$vuetify.breakpoint.width / @scale

      height = @$vuetify.breakpoint.height / @scale
      nowX = - @x / @scale
      nowY = - @y / @scale
      x = (rect.x2 + rect.x1) / 2
      y = (rect.y2 + rect.y1) / 2
      h = rect.y2 - rect.y1

      if isMobileOrTablet || (h > height - 50)
        dx = (nowX + width / 2 - x)
        dy = (nowY + 120 / @scale - rect.y1)
      else
        dx = (nowX + width / 2 - x)
        dy = (nowY + height / 2 - y)

      if Math.abs(dx) > 1 or Math.abs(dy) > 1
        @zoom.dx += dx * @scale
        @zoom.dy += dy * @scale

    sceneInitPosition: ->
      if FLAG.SCENE_CENTER
        @withoutAnimation =>
          @sceneMoveCenterTo 0, 0
      @sceneRestoreFromSessionStorage()
      unless @data?.$ready
        await @firebind @src
        await @firebind @connection.src
      anyInScreen = false    
      return unless @data.connections?.items
      for k, v of @data.connections.items
        if v.coordinates?.x and v.coordinates.y
          if (v.coordinates.x + 300) > @scene.x1 and (v.coordinates.y + 200) > @scene.y1
            if v.coordinates.x < @scene.x2 and v.coordinates.y < @scene.y2
              anyInScreen = true
      unless anyInScreen
        console.log 'no webjets in screen. Try move to nearest'
        nearest = false
        minDistance = false
        @withoutAnimation =>
          @zoomToLevel(0)
        centerX = (@scene.x1 + @scene.x2) / 2
        centerY = (@scene.y1 + @scene.y2) / 2
        for k, v of @data.connections.items
          if v.coordinates?.x and v.coordinates.y
            distanceX = v.coordinates.x - centerX + 150
            distanceY = v.coordinates.y - centerY + 150
            distance = Math.sqrt distanceX * distanceX + distanceY * distanceY
            if !nearest or minDistance > distance
              nearest = { x: v.coordinates.x, y: v.coordinates.y }
              minDistance = distance
        if nearest
          @position.x = - (nearest.x - centerX + 150)
          @position.y = - (nearest.y - centerY + 150)
