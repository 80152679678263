










import ContextBtn from './ContextBtn'
export default
  name: 'GraffitiGrid'
  components: { ContextBtn }
  inject: { provideCanvas: { default: null } }
  priority: 10000

  if: (selection)->
    return false unless FLAG.GRAFFITI_TOOLS_TO_CONTEXT_MENU
    selection.length == 1 and selection[0].$options.name == 'WebjetGraffiti'

  computed:
    selection: -> @$store.getters['webjet/selected']
    title: ->
      if @grid
        @$t('canvas.action.showGrid')
      else
        @$t('canvas.action.hideGrid')
    grid: ->
      grid = @selection[0].webjet?.data?.grid
      return true if grid == undefined
      grid


  methods:
    action: ->
      grid = !@grid
      @$store.dispatch 'webjet/setGraffitiGrid',
        path: @selection[0].webjet.$path
        value: grid
