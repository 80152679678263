







export default
  created: ->
    if sessionStorage['devtools_opened']
      @opened = true
  data: ->
    opened: false
  components:
    DevTools: => import(### webpackChunkName: "devtools" ### '@/dev/DevTools.vue')
    DevFPS: => import(### webpackChunkName: "devtools"### '@/dev/DevFPS.vue')
    DevHMR: => import(### webpackChunkName: "devtools"### '@/dev/DevHMR.vue')
  computed:
    keymap: ->
      'alt+s': @toggle
  methods:
    toggle: ->
      @opened = !@opened
  watch:
    opened: ->
      if @opened
        sessionStorage['devtools_opened'] = true
      else
        sessionStorage.removeItem 'devtools_opened'
  mounted: ->
    # open with touchscreen by triple touch
    body = document.querySelector 'body'
    body.addEventListener 'touchstart', (e) =>
      if e.touches?.length == 3
        @touch3 = true
    body.addEventListener 'touchend', (e) =>
      if @touch3
        @touch3 = false
        @toggle()
