















import { availableLocales } from '@/plugins/i18n'
export default
  name: 'LocaleSwitch'
  computed:
    locale: ->
      @$i18n.locale
    locales: ->
      availableLocales
    needLocaleSwitch: ->
      true #IS_DEVELOPMENT or IS_STAGE
  methods:
    set: (key)->
      @$store.dispatch 'user/setLang', { lang: key }

