import { firebind } from '@/utils'

export userDisplayName = (uid)->
  fname = firebind "/users/#{uid}/firstName"
  lname = firebind "/users/#{uid}/lastName"
  email = firebind "/users/#{uid}/email"
  name = ''
  name += fname.$value + ' ' if fname.$value
  name += lname.$value if lname.$value
  #name = email.$value unless name
  name = "User_#{uid.substring(0,4)}" unless name || !uid
  unless name
    name = 'Unknown user'
  name

export userCheсkData = (uid)->
  fname = firebind "/users/#{uid}/firstName"
  lname = firebind "/users/#{uid}/lastName"
  email = firebind "/users/#{uid}/email"
  if !fname.$value and !lname.$value and !email.$value
    return false
  return true

export userInfo = (uid)->
  fname = firebind("/users/#{uid}/firstName").$value
  lname = firebind("/users/#{uid}/lastName").$value
  email = firebind("/users/#{uid}/email").$value
  return { fname, lname, email, uid }

export userPlan = (uid)->
  plan = firebind("/users/#{uid}/plan").$value
  coupons = firebind("/users/#{uid}/coupons")
  ltdCount = 0
  for k,v of coupons
    ltdCount++
  if ltdCount
    plan = "ltd#{ltdCount}"
  else if not plan
    plan = 'free'
  return plan

export userLimits = (uid)->
  return firebind "/system/plans/#{userPlan(uid)}"
