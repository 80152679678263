











import InventoryTools from '@/canvas/sidebar/inventory/InventoryTools'
import ContextMenuMore from './ContextMenu/ContextMenuMore'
import ResetSelection from './ContextMenu/ResetSelection'

import Duplicate from './ContextMenu/Duplicate'
import Collapse from './ContextMenu/Collapse'
import Expand from './ContextMenu/Expand'
import Trash from './ContextMenu/Trash'
import Title from './ContextMenu/Title'
import Theme from './ContextMenu/Theme'
import Publish from './ContextMenu/Publish'
import TradingViewInterval from './ContextMenu/TradingViewInterval'
import Shape from './ContextMenu/Shape'
import AnchorType from './ContextMenu/AnchorType'
import AnchorCreate from './ContextMenu/AnchorCreate'
import SelectionCount from './ContextMenu/SelectionCount'
import Group from './ContextMenu/Group'
import CopyLink from './ContextMenu/CopyLink'
import RefreshLinks from './ContextMenu/RefreshLinks'
import Download from './ContextMenu/Download'
import CopyText from './ContextMenu/CopyText'
import CheckListType from './ContextMenu/CheckListType'
import TranscribeVoiceGroup from './ContextMenu/TranscribeVoiceGroup'

import GraffitiGrid from './ContextMenu/GraffitiGrid'
import GraffitiSizeType from './ContextMenu/GraffitiSizeType'
import GraffitiLastic from './ContextMenu/GraffitiLastic'
import GraffitiPen from './ContextMenu/GraffitiPen'
import GraffitiMarker from './ContextMenu/GraffitiMarker'
import GraffitiColor from './ContextMenu/GraffitiColor'
import GraffitiWidth from './ContextMenu/GraffitiWidth'
import AlignSelection  from './ContextMenu/AlignSelection'
import Mention from './ContextMenu/Mention'

buttons = [
  GraffitiLastic
  GraffitiPen
  GraffitiMarker
  GraffitiColor
  GraffitiWidth
  GraffitiGrid
  GraffitiSizeType

  SelectionCount
  Title
  Theme
  Duplicate
  Collapse
  Expand
  Publish
  TradingViewInterval
  Shape
  AnchorType
  AnchorCreate
  Group
  CopyLink
  RefreshLinks
  Download
  CopyText
  CheckListType
  TranscribeVoiceGroup
  Mention
  AlignSelection

  Trash
]

export default
  name: 'ContextMenu'
  props:
    size: { default: false }
  inject: ['provideBoardSrc']
  data: ->
    width: 0
  components: { ContextMenuMore, InventoryTools, ResetSelection }
  created: ->
    if @size
      @width = @size
  mounted: ->
    unless @size
      @width = @$el.clientWidth
      @observer = new ResizeObserver (entries)=>
        @width = @$el.clientWidth
      @observer.observe @$el
  computed:
    showInventoryTools: ->
      @provideBoardSrc == 'inventory'
    showButtons: ->
      @selection.length and @selection[0].provideBoardSrc == @provideBoardSrc
    primarySize: ->
      result = @width
      result -= 40 if @showInventoryTools
      result -= 40
      result
    selection: -> @$store.getters['webjet/selected']
    allBtns: ->
      result = []
      for b in buttons
        if @selection.length and b.if(@selection)
          result.push b
      result
    primaryBtns: ->
      all = @allBtns.filter (b)-> b.priority != undefined
      sorted = [...all].sort (a,b)-> b.priority - a.priority
      count = Math.floor @primarySize / 40
      filtered = sorted.slice 0, count
      result = []
      for b in all
        result.push b if b in filtered
      result
    secondaryBtns: ->
      result = @allBtns.filter (b)=> !@primaryBtns.includes(b)
      result
