var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"board-img-wrapper"},[_c('div',{staticClass:"board-img",class:{'can-edit': _vm.boardLicense == 'rw' }},[_c('div',{staticClass:"wrapper board-cover",class:( _obj = {}, _obj[_vm.currentTheme] = true, _obj['wait-drop'] =  _vm.dragOverImg, _obj ),on:{"dragover":_vm.dragover,"drop":_vm.drop}},[(_vm.dragOverImg)?_c('div',{staticClass:"drop-zone-img",on:{"dragleave":_vm.dragleave,"dragend":_vm.dragleave}},[_vm._v(_vm._s(_vm.$t('board.action.drop')))]):_vm._e(),(_vm.boradImg)?[_c('img',{attrs:{"src":_vm.boradImg}})]:[(_vm.title)?_c('div',{staticClass:"text-cover"},[_vm._v(_vm._s(_vm.initials))]):_c('img',{attrs:{"src":require("@/assets/blank-cover.jpg")}})],(_vm.userCanEdit)?_c('div',{staticClass:"wrapper-buttons"},[(_vm.boradImg)?[_c('VBtn',{staticClass:"change-cover-button",attrs:{"text":""},on:{"click":_vm.openUpload}},[_vm._v(_vm._s(_vm.$t('board.action.changeCover')))]),_c('VBtn',{staticClass:"delete-cover-button",attrs:{"text":"","title":_vm.$t('board.action.deleteCover')},on:{"click":_vm.deleteCover}},[_vm._v("×")])]:[_c('VBtn',{staticClass:"upload-cover-button",attrs:{"text":""},on:{"click":_vm.openUpload}},[_vm._v(_vm._s(_vm.$t('board.action.uploadCover')))])]],2):_vm._e()],2),_c('div',{staticClass:"wrapper-configs"},[(_vm.userCanEdit)?_c('div',{staticClass:"wrapper-choose-color"},[_c('VMenu',{attrs:{"offset-y":"","content-class":"choose-color-menu","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var _obj;

var on = ref.on;
var attrs = ref.attrs;return [_c('VBtn',_vm._g(_vm._b({staticClass:"choose-color",attrs:{"text":"","color":"transparent","title":_vm.$t('board.action.chooseColor')}},'VBtn',attrs,false),on),[_c('div',{staticClass:"label",class:( _obj = {}, _obj[_vm.currentTheme] = true, _obj )},[_vm._v(_vm._s(_vm.$t('board.label')))])])]}}],null,false,1086062697)},[_c('VList',_vm._l((_vm.availiableThemes),function(t){
var _obj;
return _c('div',{class:( _obj = {}, _obj[t] = true, _obj.active = _vm.currentTheme == t, _obj ),on:{"click":function($event){return _vm.setTheme(t)}}})}),0)],1)],1):_vm._e(),(true)?_c('div',{staticClass:"star",class:{ starred: _vm.starred }},[_c('VBtn',{attrs:{"icon":"","title":_vm.$t('board.config.starredTitle')},on:{"click":_vm.toggleStarred}},[_c('svg',{attrs:{"height":"21","width":"23"}},[_c('use',{attrs:{"xlink:href":require("@/assets/icons/star.svg") + "#star"}})])])],1):_vm._e()])]),(_vm.openModalCropper)?_c('ModalImgCropper',{attrs:{"maxWidth":1200,"maxHeight":1200,"file":_vm.imgFile,"circle":false},on:{"crop":_vm.uploadCroppedImg},model:{value:(_vm.openModalCropper),callback:function ($$v) {_vm.openModalCropper=$$v},expression:"openModalCropper"}}):_vm._e(),_c('input',{staticClass:"boardimg-upload",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.processCrop}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }