




















import Avatar from '@/components/Avatar.vue'
import AvatarTeam from '@/components/AvatarTeam.vue'
import TeamPreview from './navbar/TeamPreview'
import TeamCreate from './navbar/TeamCreate'
export default
  name: 'Navbar'
  inject: ['provideApp']
  components:
    { Avatar, AvatarTeam, TeamPreview, TeamCreate }
  computed:
    teams: ->
      @$store.getters.teams
    accountOpen: ->
      @$store.state.route.name == 'Account' or @$store.state.route.name == 'Unsorted'
    teamOpen: ->
      @$store.state.route.name == 'TeamSettings' or @$store.state.route.name == 'TeamAccess' or @$store.state.route.name == 'TeamPlan' or @$store.state.route.name == 'TeamTrash'
    boardsOpen: ->
      @$store.state.route.name == 'Boards'
    smallScreen: ->
      @provideApp.smallScreen
    isAnonymousUser: ->
      @$store.state.isAnonymousUser
