































import DialogTemplate from '@/components/DialogTemplate'
import ProjectSettings from '@/components/Project/Settings'
import CollabUser from '@/components/Project/CollabUser.vue'
import Public from '@/components/Project/Public.vue'
import Name from '@/components/Project/Name.vue'

export default
  name: "DialogProjectSettings"
  inject: ['provideApp']
  components: { DialogTemplate, ProjectSettings, CollabUser, Public, Name }
  data: ->
    projectAddUser: false
    projectDeleteDialog: false
  computed:
    show:
      get: ->
        !!@provideApp.dialogProjectSettingsId
      set: (val) ->
        @provideApp.dialogProjectSettingsId = val
    project: ->
      @provideApp.dialogProjectSettingsId
    projectTitle:
      get: ->
        projectsData = @firebind "/projects/#{@project}"
        projectsData.title
      set: (val) ->
        @$store.dispatch 'project/updateProjectTitle',
          id: @project
          title: val
    team: ->
      projectsData = @firebind "/projects/#{@project}"
      projectsData.team
    roles: ->
      @firebind "/teamAccess/#{@team}/roles"
    rolesWithoutOwner: ->
      result = {}
      for k,v of @roles
        result[k] = v if v != 'owner' and !@projectUsers[k]
      result
    projectUsers: ->
      result = {}
      allUserInTeamProjects = @firebind "/teamAccess/#{@team}/projects"
      for k,v of allUserInTeamProjects
        for pId, rights of v
          result[k] = rights if pId == @project
      result
    projectsCount: ->
      teamProjects = @firebind "/teams/#{@team}/projects"
      count = Object.keys(teamProjects).length
      count
    openProjectRights: ->
      rights = @firebind "/teamAccess/#{@team}/openProjects/#{@project}"
      rights.$value
  methods:
    deleteProject: ->
      @$store.dispatch 'project/deleteProject',
        projectId: @project
      @show = false
    publicChange: (val) ->
      rights = if val then val else null
      if rights == true
        rights = 'view'
      @$store.dispatch 'project/updateOpenProjectRights',
        teamId: @team
        projectId: @project
        rights: rights
    selectRights: ({ userId, rights})->
      @$store.dispatch 'project/updateProjectUserRole',
        teamId: @team
        projectId: @project
        userId: userId
        role: rights
    addToProject: (userId) ->
      @$store.dispatch 'project/updateProjectUserRole',
        teamId: @team
        projectId: @project
        userId: userId
        role: 'view'
    deleteFromProject: (userId) ->
      @$store.dispatch 'project/updateProjectUserRole',
        teamId: @team
        projectId: @project
        userId: userId
        role: null
    hotkeyBlocker: (e) ->
      # allow undo/redo
      if e.ctrlKey && e.keyCode in [ 89, 90 ]
        return
      # block other hotkeys
      e.stopPropagation()
      # block ctrl +/-/0
      if e.ctrlKey && e.keyCode in [ 187, 189, 48, 107, 109, 45, 43, 96 ]
        e.preventDefault()

