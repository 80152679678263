








import ContextBtn from './ContextBtn'
export default
  name: 'ResetSelection'
  components: { ContextBtn }
  inject: { provideCanvas: { default: null } }

  computed:
    attach: ->
      @selection.length > 0 and FLAG.RESET_SELECTION_BTN

    selection: -> @$store.getters['webjet/selected']

    isOnlyOneSelection: ->
      return true if @selection.length == 1

    selectionIsGraffiti: ->
      return unless @isOnlyOneSelection
      webjet = @selection[0]
      return webjet if webjet?.webjet?.category == "/webjets/content/webjets/graffiti"

  methods:
    action: ->
      if @selectionIsGraffiti and FLAG.WEBJET_PARENT
        if @selectionIsGraffiti.$refs?.editor?.editMode
          @selectionIsGraffiti.$refs?.editor?.deactivateEditor()
        else
          @$store.dispatch 'webjet/resetSelection'
      else
        @$store.dispatch 'webjet/resetSelection'
