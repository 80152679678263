import { operationActions } from '@/store/operation'
import state from './fresh-state'
import getters from './fresh-getters'
import mutations from './fresh-mutations'
import actions from './fresh-actions'
import operations from './fresh-operations'


export default
  namespaced: true
  state: state
  getters: getters
  mutations: mutations
  actions: Object.assign {}, operationActions(operations), actions
