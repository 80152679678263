Object.defineProperty MouseEvent.prototype, 'canvasX',
  get: ->
    canvas = @target.closest('.canvas')
    @x - canvas.getBoundingClientRect().x

Object.defineProperty MouseEvent.prototype, 'canvasY',
  get: ->
    canvas = @target.closest('.canvas')
    @y - canvas.getBoundingClientRect().y

Object.defineProperty MouseEvent.prototype, 'sceneX',
  get: ->
    canvas = @target.closest('.canvas').__vue__
    canvas.sceneX + @canvasX / canvas.scale

Object.defineProperty MouseEvent.prototype, 'sceneY',
  get: ->
    canvas = @target.closest('.canvas').__vue__
    canvas.sceneY + @canvasY / canvas.scale

if window.TouchEvent
  # Fifrefox does not have TouchEvent
  # This is fix run app on Firefox
  Object.defineProperty TouchEvent.prototype, 'canvasX',
    get: ->
      canvas = @target.closest('.canvas')
      @changedTouches[0].clientX  - canvas.getBoundingClientRect().x

  Object.defineProperty TouchEvent.prototype, 'canvasY',
    get: ->
      canvas = @target.closest('.canvas')
      @changedTouches[0].clientY - canvas.getBoundingClientRect().y

  Object.defineProperty TouchEvent.prototype, 'sceneX',
    get: ->
      canvas = @target.closest('.canvas').__vue__
      canvas.sceneX + @canvasX / canvas.scale

  Object.defineProperty TouchEvent.prototype, 'sceneY',
    get: ->
      canvas = @target.closest('.canvas').__vue__
      canvas.sceneY + @canvasY / canvas.scale

Object.defineProperty UIEvent.prototype, 'canvasX',
  get: ->
    canvas = @target.closest('.canvas')
    @x - canvas.getBoundingClientRect().x

Object.defineProperty UIEvent.prototype, 'canvasY',
  get: ->
    canvas = @target.closest('.canvas')
    @y - canvas.getBoundingClientRect().y

Object.defineProperty UIEvent.prototype, 'sceneX',
  get: ->
    canvas = @target.closest('.canvas').__vue__
    canvas.sceneX + @canvasX / canvas.scale

Object.defineProperty UIEvent.prototype, 'sceneY',
  get: ->
    canvas = @target.closest('.canvas').__vue__
    canvas.sceneY + @canvasY / canvas.scale
