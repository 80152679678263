
























#import { usualMixinPack } from './mixins'
import { webjet, positionable, draggable, selectable, themeable } from './mixins'
mixins = [ webjet, positionable, draggable, selectable, themeable ]
export default
  name: 'WebjetAnchor'
  mixins: mixins
  inject: {provideCanvas: { default: false }}
  data: ->
    dragAllInZone: false
  computed:
    scale: ->
      if @provideCanvas
        return @provideCanvas.scale
      else
        return 1
    canDropOnlyOnCanvas: -> true
    inSideBar: -> return true if @provideSidebar or @provideSidebarContent
    userCanEdit: -> @provideCanvas.userCanEdit
    presIsActive:-> @provideCanvas.presentationIsActive
    activeWebjetInPresentation: ->
      return false unless @provideCanvas
      @provideCanvas.presentationIsActive and !@inSideBar and
      @provideCanvas.webjetAnchorSrc == @wSrc
    wSrc: -> '/data/' + @webjet.$path.split('/').slice(-2).join('/')
    resizeSize: ->
      return {width: @resizeWidth,height: @resizeHeight}
    positionOffsetScale: ->
      # heder offset top on zoom less than 1
      positionOffsetScale = if @scale < 1 then (-20 - (18 / @scale)) else -40
      positionOffsetScale = -400 if positionOffsetScale < -400
      positionOffsetScale = 0 if @type != 'iframe' or @inSideBar
      positionOffsetScale

    styles: ->
      # heder offset top on zoom less than 1
      positionOffsetScale = @positionOffsetScale
      # scale header font on zoom less than 1
      fontScale = if @scale < 1 then 16 / @scale else 16
      fontScale = 300 if fontScale > 300
      fontScale = 16 if @inSideBar or @presIsActive

      # scale header icon size on zoom less than 1
      iconScale = if @scale < 1 then 22 / @scale else 22
      iconScale = 450 if iconScale > 450
      iconScale = 22 if @inSideBar or @presIsActive
      # opacity on zoom less than 0.05
      opacity = 1
      if @scale < 0.05
        opacity = 0
      else if @scale >= 0.05 and @scale < 0.1
        opacityPercent = 100 - (100 / 0.05) * (0.1 - @scale)
        opacity = opacityPercent * 0.01
      if @type != 'iframe' or @inSideBar
        opacity = 1
      return
        opacity: opacity
        top: "#{positionOffsetScale}px"
        '--font-scaled': "#{fontScale}px"
        '--icon-scaled': "#{iconScale}px"

    presentationBackStyle: ->
      return unless @activeWebjetInPresentation
      return unless @type == 'bookmark'
      return unless @elementSceneRect
      rect = @elementSceneRect
      width = rect.x2 - rect.x1
      height = rect.y2 - rect.y1
      backW = 1000
      backH = 1000
      offsetW = (backW - width) / 2
      offsetH = (backH - height) / 2
      return
        width: "#{backW}px"
        height: "#{backH}px"
        transform: "translate(-#{offsetW}px, -#{offsetH}px)"


    showMovingZone: ->
      return true if @isOnlySelected and @type == 'iframe' and !@inSideBar
      return false
    type: ->
      return 'iframe' unless @webjet?.data?.type
      @webjet?.data?.type
    classes: ->
      return
        'anchor-iframe': @type == 'iframe'
        'anchor-bookmark': @type == 'bookmark'
        'in-sidebar': @inSideBar
        'active-presentation-webjet': @activeWebjetInPresentation
    titlePlaceholder: ->
      if @type == 'bookmark'
        @$t("webjet.anchor.titleBookmark")
      else
        @$t("webjet.anchor.titleFrame")
  methods:
    trash: ->
      items = [{ path: @src }]
      @$store.dispatch 'webjet/trash', { items }
    copyLink: ->
      src = @webjet.$path.split('/').slice(-2).join('/')
      link = "#{window.location.origin}/b/#{@$route.params.boardId}/#{@provideCanvas.boardOwner}?w=#{src}"
      if window.clipboardData
        # should work in Internet Explorer
        window.clipboardData.setData 'Text', link
      else
        # all other browsers
        navigator.clipboard.writeText link
      @provideCanvas.showCaution
        message: @$t('canvas.message.linkCopied')
        loader: false
    clickOverlay: ->
      src = @webjet.$path.split('/').slice(-2).join('/')
      path = @$route.path
      @$router.replace path: path, query: {w: src}
    draggableGetWebjet: ->
      toDrag = [this]
      if @type == 'iframe'
        toDrag = [this,...@webjetInAnchor()]
      toDrag
    webjetInAnchor: ->
      webjets = @provideCanvas.childWebjetComponents
      # webjets = webjets.filter (w)-> w != this
      # webjets = webjets.filter (w)-> w.isTheoreticallyVisible
      {x1,x2,y1,y2} = @elementRect
      # console.log webjets
      result = []
      for w in webjets
        if w != this
          rect = w.elementRect
          si = Math.max(0, Math.min(x2, rect.x2) - Math.max(x1, rect.x1)) * Math.max(0, Math.min(y2, rect.y2) - Math.max(y1, rect.y1))
          # s1 = (x2 - x1) * (y2 - y1)
          s2 = (rect.x2 - rect.x1) * (rect.y2 - rect.y1)
          overlap = si / s2
          if overlap >= 0.5
            result.push w
            w.selectByAnchor = true
      result

  watch:
    resizeSize: (val) ->
      if val.height != false or val.width != false
        @provideCanvas.setWebjetsComponentInAnchor @webjetInAnchor()
      else
        @provideCanvas.setWebjetsComponentInAnchor []
