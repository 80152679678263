













export default
  name: 'GridButton'
  computed:
    grid: ->
      @$store.getters['user/grid']
  methods:
    showGridLines: ->
      await @$store.dispatch 'user/setGrid', { value: 'lines' }
    showGridDots: ->
      await @$store.dispatch 'user/setGrid', { value: 'dots' }
    hideGrid: ->
      await @$store.dispatch 'user/setGrid', { value: false }
