







import { mapGetters,mapState } from 'vuex'
import Avatar from '@/components/Avatar.vue'
export default
  name: 'BoardUsers'
  props:
    boardId: String
    src: String
  components:
    { Avatar }
  computed: Object.assign {},
    mapGetters ['boardsReady']
    mapState 'socketBoards', ['boards']
    usersOnBoard: ->
      return @boards[@boardId]
    usersOnBoardArr: ->
      users = Array.from @usersOnBoard
      return users.filter (u) => u != @$store.state.uid
    userMoreCount: ->
      if @usersOnBoard?.size <= 4
        return 0
      else
        return @usersOnBoard?.size - 4
