






export default
  name: 'WExpand'
  inject: ['provideWebjet']
  created: ->
    if !@provideWebjet.isExpandable
      console.warn 'Webjet not expandable'
  computed:
    isExpanded: ->
      @provideWebjet.isExpanded

