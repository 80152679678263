







import HelpArrow from '@/components/HelpArrow'
export default
  name: 'DrawTool'
  inject: ['provideCanvas']
  components: { HelpArrow }
  computed:
    active: ->
      @provideCanvas.collaborateDrawMode
  methods:
    toggle: ->
      @provideCanvas.collaborateToggleDrawMode()
