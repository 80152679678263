










import { Site } from '@/weje'
import wsSite from '@/websockets/ws-site'
export default
  name: 'WejeSite'
  components: { Site}
  props: ['siteId']
  created: ->
    return unless FLAG.WEJE_SITE_BACKEND
    wsSite.siteId = @siteId

  computed:
    wsSite: -> wsSite
    siteData: ->
      if FLAG.WEJE_SITE_BACKEND
        wsSite.site
      else
        result = {}
        wejeRecord = @firebind "/wejes/#{@siteId}"
        unless wejeRecord.$ready
          result.loading = true
          return result
        user = wejeRecord.creator
        user = wejeRecord.user unless user
        src = "/data/#{user}/#{@siteId}"
        result = @$store.getters['weje/getWejeSiteData']({src})
        result

