import { debounce } from '@/utils'

export default
  data: ->
    canGlide: false
    glideActive: false
    glideWasMiddle: false
    glideTimer: null
    glideOffsets: {x: null, y: null}
    glideChords: {x:null,y:null}
    sidebarDelayTime: 0


  listeners: ->
    dragover: @glideDragOver
    dragleave: @glideDragLeave
    touchmove: @glideTouchMove
    touchend: @glideTouchEnd
    mousemove: @glideMouseMove
    mouseout: @glideMouseOut
    mouseup: @glideDrop


  created: ->
    @debouncedGlideDisable = debounce @glideDisable, 300

  computed:
    sidebar: ->
      if @$refs.sidebar
        return @$refs.sidebar
      else
        return false
    sidebarMod: ->
      @sidebar?.currentMode
    sidebarWidth: ->
      if @sidebarMod == 'full'
        return @sidebar?.$refs.content.$el.getBoundingClientRect().width
  methods:
    glideTouchEnd: (e) ->
      @glideOff()
    glideDragLeave: (e) ->
      # console.log 'glideDragLeave'
      if !e.relatedTarget or e.relatedTarget.closest('.canvas-sidebar')

        # console.log 'glideDragLeave OFF'
        @glideOff()
    glideMouseOut: (e) ->
      return if @dndCounter > 0
      # console.log 'glideMouseOut OFF'
      @glideOff()
    glideDrop: (e) ->
      @glideOff()
    glideTouchMove: (e) ->
      return unless e.isTrusted
      if @relationIsActive and document.elementFromPoint(e.changedTouches[0].pageX,e.changedTouches[0].pageY)
        @glideOn(e.changedTouches[0].clientX, e.changedTouches[0].clientY, 'touch')
      else
        # console.log 'glideTouchMove OFF'
        @glideOff()
    glideDragOver: (e) ->
      # console.log 'glideDragOver'
      # console.log document.elementFromPoint(e.x,e.y)
      if @dndCounter > 0
        @glideOn(e.x, e.y, 'drag')
      else
        # console.log 'glideDragOver OFF'
        @glideOff()
    glideMouseMove: (e) ->
      return unless e.isTrusted
      if @relationIsActive or @selection.active
        @glideOn(e.clientX, e.clientY, 'mouse')
      else
        # console.log 'glideMouseMove OFF'
        @glideOff()
    glideGo: (type) ->
      # console.log 'glideGo'
      # if @glideOffsets.x == null and @glideOffsets.y == null
      #     @canGlide = true
      #     console.log 'canGlide'
      # console.log @canGlide
      if @glideOffsets.x != null or @glideOffsets.y != null
        if @canGlide
          @position.y += @glideOffsets.y if @glideOffsets.y != null
          @position.x += @glideOffsets.x if @glideOffsets.x != null
          if type == 'mouse'
            mouseEvent = new MouseEvent 'mousemove', {view: window, bubbles: false,cancelable: true,clientX: @glideChords.x,clientY:@glideChords.y, x: @glideChords.x,y:@glideChords.y}
            @$el.dispatchEvent(mouseEvent)
            window.dispatchEvent(mouseEvent)
          else if type == 'touch'
            touchObj = new Touch {identifier: Date.now(), target: @$el,clientX: @glideChords.x,clientY:@glideChords.y }
            touchEvent = new TouchEvent 'touchmove', {cancelable: true,bubbles: true,changedTouches: [touchObj]}
            window.dispatchEvent(touchEvent)
          else if type = 'drag'
            unless @dndCounter > 0
              # console.log 'glideGo DRAG OFF', @dndCounter
              @glideOff()
              return
          @glideTimer = setTimeout (=> @glideGo(type)), 10
      else

        # unless @canGlide
          # console.log '11111111'
        @glideOff()
        if @glideOffsets.x == null and @glideOffsets.y == null
          # console.log 'no DATA OFF'
          @canGlide = true
    glideOn: (x,y,type) ->

      maxOffset = 50
      screenHeight = @$vuetify.breakpoint.height
      screenWidth = @screen.width
      if @sidebarWidth
        screenWidth -= @sidebarWidth
      x = x - @$el.getBoundingClientRect().x
      if x > screenWidth
        @glideOff()
      # console.log x , screenWidth
      offsetY = null
      offsetX = null
      if x <= maxOffset
        offsetX = 3
        offsetX = 5 if x < maxOffset / 2
      else if x >= screenWidth - maxOffset
        offsetX = -3
        offsetX = -5 if x > screenWidth - (maxOffset / 2)
      @glideOffsets.x = offsetX


      if y <= maxOffset
        offsetY = 3
        offsetY = 5 if y < maxOffset / 2
      else if y >= screenHeight - maxOffset
        offsetY = -3
        offsetY = -5 if y > screenHeight - (maxOffset / 2)

      @glideOffsets.y = offsetY
      @glideChords.x = x
      @glideChords.y = y
      # console.log 'glideTimer ', @glideTimer
      unless @glideTimer and offsetY != null or @glideTimer and offsetX != null
        if @sidebarWidth and offsetX < 0
          # console.log 'sidebarWidth', @sidebarWidth
          # unless @sidebarTimer
          tn = Date.now()
          delay = 300
          if !@sidebarDelayTime
            @sidebarDelayTime = tn
          else
            if (tn - @sidebarDelayTime) > delay
              @sidebarDelayTime = 0
              @glideGo(type)
        else
          @glideGo(type)

    glideOff: ->
      @glideOffsets.y = null
      @glideOffsets.x = null
      clearTimeout(@glideTimer)
      @sidebarDelayTime = 0
      @canGlide = false
      @glideTimer = null
      # console.log '------glideOff'

    glideDisable: ->
      @glideActive = false
      @glideWasMiddle = false
      @glideTimestamp = false

    glideDragover: (e)->
      glideWidth = 50
      speed = 500
      @glideTimestamp = Date.now() unless @glideTimestamp
      @glideTimestamp = Date.now() if (Date.now() - @glideTimestamp) > 200
      left = e.clientX
      right = @$vuetify.breakpoint.width - e.clientX
      top = e.clientY
      bottom = @$vuetify.breakpoint.height - e.clientY
      dt = (Date.now() - @glideTimestamp) / 1000
      @glideTimestamp = Date.now()
      if left < glideWidth or right < glideWidth or top < glideWidth or bottom < glideWidth
        if @glideWasMiddle
          if left < glideWidth
            @zoom.dx += speed * (glideWidth - left) / glideWidth * dt
          else if right < glideWidth
            @zoom.dx -= speed * (glideWidth - right) / glideWidth * dt
          else if top < glideWidth
            @zoom.dy += speed * (glideWidth - top) / glideWidth * dt
          else if bottom < glideWidth
            @zoom.dy -= speed * (glideWidth - bottom) / glideWidth * dt
          @debouncedGlideDisable()
      else
        @glideWasMiddle = true
