




















export default
  name: 'BottomNav'

  computed:
    isSpaces: ->
      @$store.state.route.name == 'Team' or @$store.state.route.name == 'Home'
    isAccount: ->
      @$store.state.route.name == 'Unsorted' or @$store.state.route.name == 'Account'
    isAnonymousUser: ->
      @$store.state.isAnonymousUser
  methods:
    toUnsorted: ->
      @$router.push { name: 'Unsorted' }
    toSpaces: ->
      @$router.push { name: 'Home' }


