





export default
  name: 'Webjet'
  props:
    src: String
  inject:
    provideCanvas: { default: false }
    provideWebjet: { default: false }
  computed:
    component: ->
      return false if FLAG.CANVAS_WEBJET_INIT and !@provideWebjet and @provideCanvas and !@provideCanvas.isAllowWebjetInit(@src)
      return false unless @webjet #?.category
      return 'WebjetErrorAccess' if @webjet.$error?.code == 'PERMISSION_DENIED'
      switch @webjet.category
        when '/webjets/content/webjets/note'
          if FLAG.WEBJET_PARENT
            return 'WebjetParentCard'
          else
            return 'WebjetCard'
        when '/webjets/content/webjets/photo' then 'WebjetPicture'
        when '/webjets/content/webjets/html5video' then 'WebjetVideo'
        when '/services/youtube/webjets/video' then 'WebjetYoutubeVideo'
        when '/webjets/content/webjets/audio' then 'WebjetAudio'
        when '/webjets/content/webjets/embed' then 'WebjetEmbed'
        when '/webjets/content/webjets/pdf' then 'WebjetPdf'
        when '/webjets/content/webjets/link' then 'WebjetLink'
        when '/webjets/containers/webjets/list' then 'WebjetList'
        when '/webjets/content/webjets/graffiti' then 'WebjetGraffiti'
        when '/webjets/content/webjets/shape' then 'WebjetShape'
        when 'tradingview' then 'WebjetTradingView'
        when 'anchor' then 'WebjetAnchor'
        when 'btnLink' then 'WebjetBtnLink'
        when 'table' then 'WebjetTable'
        when 'checklist' then 'WebjetChecklist'
        when 'voice' then 'WebjetVoice'
        when 'date' then 'WebjetDate'
        when 'mention' then 'WebjetMention'
        when 'embedcode' then 'WebjetEmbedCode'
        when 'comments' then 'WebjetComments'
        else
          if @webjet.$path.includes '/paints/'
            'WebjetPaint'
          else
            'WebjetUnknown'

  firebind:
    connection: -> @src
    webjet: ->
      if @connection and @connection.$value != null
        if @connection.src
          @connection.src
        else
          @connection.$path
      else
        false
