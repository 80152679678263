










import { mapState } from 'vuex'
export default
  name: 'UndoRedo'

  computed: Object.assign {},
    mapState 'undoredo', ['undo', 'redo']
  methods:
    undoAction: ->
      @$store.dispatch 'undoredo/undo'
    redoAction: ->
      @$store.dispatch 'undoredo/redo'
