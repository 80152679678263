





import WejeWebjet from '@/weje/wejes/WejeWebjet'

export default
  name: 'WejePage'
  props: ['data']
  inject:
    provideBoardSrc: { default: null }
    provideSidebar: { default: null }
    provideCanvas: { default: null }
  components: { WejeWebjet }
  computed:
    webjets: ->
      if @data.childs.length > 1
        console.warn 'weje page with more than 1 webjet'
        [@data.childs[0]]
      else
        @data.childs
    tooltip: ->
      return false unless @provideSidebar
      @$t('weje.toWebjetOnBoard')

  methods:
    dblclick: ->
      return unless @provideSidebar
      src = @data.childs[0].webjetPath.split('/').slice(-2).join('/')
      @provideCanvas.goToWebjet src, true

