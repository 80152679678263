





import { isMobileOrTablet } from "@/utils"
export default
  name: 'PasteLayer'
  inject: ['provideCanvas']
  computed:
    activeDraw: ->
      @provideCanvas.activeDraw
    drawPaintIsActive: ->
      @provideCanvas.drawPaintIsActive
  methods:
    onFocusPasteLayer: (e) ->
      if isMobileOrTablet
        e.target.blur()
      else
        setTimeout => e.target.blur()

