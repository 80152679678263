










export default
  name: 'TeamTrash'
