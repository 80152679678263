

























import mixin from './mixin'
import Childs from './Childs'

export default
  name: 'WejeListOnSite'
  mixins: [ mixin ]
  components: { Childs }
  props: ['data']
  data: ->
    column: 0
    placeholderLeft: 0
    placeholderWidth: 0
    dataActiveColumn: 0
    activeColumnSlide: 0
    observer: null
    sliderLimitPerView: 3
  computed:
    activeColumn: ->
      active = @dataActiveColumn
      if (!active and active != 0) or @columns.indexOf(active)==-1
        @columns[0]
      else
        active
    activeColumnIndex: ->
      @columns.indexOf(@activeColumn)
    activeColumnLast: -> @activeColumnIndex == (@dotsColumns.length - 1)
    activeColumnFirst: -> @activeColumnIndex == 0
    columns: ->
      all = []
      for c in @childs
        all.push c.columnKey if c.columnKey
        all.push 0 unless c.columnKey
      result = [...new Set all]
      result = result.sort((a, b) => parseFloat(a) - parseFloat(b))
      result = [0] if result.length == 0
      result
    activeColumns: ->
      return @columns if @columns.length <= @sliderLimitPerView
      columns = [...new Set @columns]
      index = columns.indexOf(@dataActiveColumn)
      index = 0 if index == -1
      columns = columns.splice index, @sliderLimitPerView
      columns
    dotsColumns: ->
      return [] if @columns.length <= @sliderLimitPerView
      columns = [...new Set @columns]
      columns = columns.splice 0, @columns.length - @sliderLimitPerView + 1
      columns
  methods:
    slideTo: (i)->
      @dataActiveColumn = @columns[i]
    slideLeft: ()->
      @slideTo(@activeColumnIndex-1) unless @activeColumnFirst
    slideRight: ()->
      @slideTo(@activeColumnIndex+1) unless @activeColumnLast
    sliderInit: (entries) ->
      # break point slider
      sliderWidth = @$refs.slider.clientWidth
      if sliderWidth > 800
        @sliderLimitPerView = 3
      else if sliderWidth > 300
        @sliderLimitPerView = 2
      else
        @sliderLimitPerView = 1
  mounted: ->
    @dataActiveColumn = @data.activeColumn if @data.activeColumn
    @observer = new ResizeObserver @sliderInit
    @observer.observe @$refs.slider
  beforeDestroy: ->
    if @observer
      @observer.disconnect()
