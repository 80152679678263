import { firebind } from '@/utils'
import i18n from '@/plugins/i18n'
import store from '@/store'

# Decode pasted text to a webjet of certain category

REGEX_URL = /^((https?|ftp):)?\/\/.*$/
REGEX_IMAGE_URL = /^((https?|ftp):)?\/\/.*\.(jpeg|jpg|png|gif|bmp|svg)$/
REGEX_IFRAME = /^(<iframe[^>]*)(?:(?:\/>)|(.*?<\/iframe>))$/

isYouTubeUrl = (s) -> s?.indexOf("youtube.com") > -1 or s?.indexOf("youtu.be") > -1
isGoogleDocsUrl = (s) -> s?.indexOf("https://docs.google.com/") == 0
isGoogleDocsDocument = (s) -> s?.indexOf("https://docs.google.com/document") == 0
isGoogleDocsPresentation = (s) -> s?.indexOf("https://docs.google.com/presentation") == 0
isGoogleDocsSpreadsheet = (s) -> s?.indexOf("https://docs.google.com/spreadsheets") == 0
isGoogleDocsForm = (s) -> s?.indexOf("https://docs.google.com/forms") == 0
isGoogleSearchResult = (s) ->  s?.indexOf("https://www.google.") == 0
isFigmaFile = (s) -> s?.indexOf('https://www.figma.com/file/') == 0
isMiroBoard = (s) -> s?.indexOf('https://miro.com/app/board/') == 0
isXMind = (s) -> s?.indexOf('https://www.xmind.net/m/') == 0
isAirTable = (s) -> s?.indexOf('https://airtable.com/shr') == 0
is3DViewer = (s) -> s?.indexOf('https://3dviewer.net/#model') == 0
isSketchfab = (s) -> s?.indexOf('https://sketchfab.com/3d-models/') == 0
isPdfUrl = (s) ->
  s = s.split('?')[0]
  s?.match(/^((https?|ftp):)?\/\/.*\.pdf$/)?.length > 0
isVideoUrl = (s) ->
  s = s.split('?')[0]
  s?.match(/^((https?|ftp):)?\/\/.*\.(mp4|mov|avi|ogv|webm)$/)?.length > 0
isAudioUrl = (s) ->
  s = s.split('?')[0]
  s?.match(/^((https?|ftp):)?\/\/.*\.(wav|mp3|ogg)$/)?.length > 0
isTradingView = (s) -> s?.indexOf('tradingview.com/chart/') > -1 or s?.indexOf('tradingview.com/symbols/') > -1
isDisqusComment = (s) -> s?.indexOf('http://disq.us/') == 0

isWejeLink = (s) ->
  return false unless s
  return true if s.indexOf("https://app.weje.io") == 0
  return true if s.indexOf("https://app.weje.tech") == 0
  return true if s.indexOf("https://dev.weje.io") == 0
  return true if s.indexOf("https://dev.weje.tech") == 0
  return true if s.indexOf("https://stage.weje.io") == 0
  return true if s.indexOf("http://localhost") == 0 and IS_DEVELOPMENT
  return false

isHtml = (s) ->
  stringIsHtml = false
  domparser = new DOMParser
  parseFromString = domparser.parseFromString(s, 'text/html')
  return false unless parseFromString
  childNodes = parseFromString.body.childNodes
  for node in childNodes
    nodeType = node.nodeType
    if nodeType == 1
      stringIsHtml = true
      break
  if stringIsHtml
    return parseFromString?.body?.innerHTML?.replace(/<\!--.*?-->/g, "")?.trim()
  else
    return false
isIframe = (s) ->
  text = s.replace(/\s+/g, ' ').trim()
  return REGEX_IFRAME.test text

getIframeParams = (s) ->
  parser = new DOMParser()
  parsedIframe = parser.parseFromString s, "text/html"
  iframe = parsedIframe.getElementsByTagName "iframe"

  src = iframe[0].src
  scrolling = iframe[0].scrolling

  return { src, scrolling }

webjetTitle = (w) ->

  if w?.data?.titleOrigin
    # initial origin for content webjets
    # if user sets custom title and then removed it, then titleOrigin is restored as title
    w?.data?.titleOrigin
  else if w?.data?.title
    w?.data?.title

  else
    # attempt to evaluate title from content
    # this could be removed when disappearing header is implemented
    content = w?.data?.text
    if w?.data?.contentDelta?
      content = null # do not use legacy text even if editor data is empty
      for op in w.data.contentDelta.ops
        if op.insert?.trim().length>0
          content = op.insert
          break
    if content
      lines = content.split("\n")
      for line in lines
        if line.trim().length > 0
          title = line.trim().substr(0, 50)
          return title
    return ''

getYouTubeId = (url) ->
  regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(shorts\/)|(watch\?))\??v?=?([^#&?]*).*/
  results = regExp.exec(url)
  return if results? then decodeURIComponent(results[8]) else ''

getUrlParameter = (url, name) ->
  name = name.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]")
  regexp = new RegExp( "[\\?&]"+name+"=([^&#]*)" )
  results = regexp.exec(url)
  return if results? then decodeURIComponent(results[1]) else ''

export getWebjetForText = (text) ->

  if REGEX_URL.test(text)
    # process URL

    if isGoogleSearchResult(text)
      tmp = getUrlParameter(decodeURIComponent(text), 'url')
      if tmp
        text = tmp
      else
        tmp = getUrlParameter(decodeURIComponent(text), 'imgurl')
        if tmp
          text = tmp

    plainURL = text.split('?')[0]
    site = text.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i)?[2] || text
    fileName = plainURL.split('/').reverse()[0]
    # console.log text, site
    if REGEX_IMAGE_URL.test(plainURL.toLowerCase())
      # image
      category = '/webjets/content/webjets/photo'
      webjet =
        data:
          url: text
          icon: text
          titleOrigin: fileName

    else if isYouTubeUrl(text)
      # YouTube video
      videoId = getYouTubeId(decodeURIComponent(text))
      if videoId
        category = '/services/youtube/webjets/video'
        webjet =
          data:
            id: videoId
      else
        category = '/webjets/content/webjets/link'
        webjet =
          data:
            titleOrigin: site
            url: text
            site: site

    else if isGoogleDocsUrl(text)
      if isGoogleDocsSpreadsheet(text)
        title = i18n.t('webjet.embed.titleSpreadsheet')
        icon = "spreadsheet"
      else if isGoogleDocsForm(text)
        title = i18n.t('webjet.embed.titleForm')
        icon = "form"
      else if isGoogleDocsPresentation(text)
        title = i18n.t('webjet.embed.titlePresentation')
        icon = "presentation"
      else
        title = i18n.t('webjet.embed.title')
        icon = "document"

      category = '/webjets/content/webjets/embed'
      webjet =
        data:
          titleOrigin: title
          url: text
          icon: icon
          origin: text

    else if isFigmaFile(text)
      url = "https://www.figma.com/embed?embed_host=astra&url=#{text}"
      title = i18n.t('webjet.embed.titleFigma')
      icon = "figma"

      category = '/webjets/content/webjets/embed'
      webjet =
        data:
          titleOrigin: title
          url: url
          icon: icon
          origin: text

    else if isMiroBoard(text)
      url = text.replace('board', 'live-embed')
      title = i18n.t('webjet.embed.titleMiro')
      icon = "miro"

      category = '/webjets/content/webjets/embed'
      webjet =
        data:
          titleOrigin: title
          url: url
          icon: icon
          origin: text

    else if isXMind(text)
      url = text.replace('/m/', '/embed/')
      title = i18n.t('webjet.embed.titleXmind')
      icon = 'xmind'

      category = '/webjets/content/webjets/embed'
      webjet =
        data:
          titleOrigin: title
          url: url
          icon: icon
          origin: text

    else if isAirTable(text)
      url = text.replace('shr', 'embed/shr')
      title = i18n.t('webjet.embed.titleAirtable')
      icon = "airtable"

      category = '/webjets/content/webjets/embed'
      webjet =
        data:
          titleOrigin: title
          url: url
          icon: icon
          origin: text

    else if is3DViewer(text)
      url = text.replace('#', "embed.html#")
      title = i18n.t('webjet.embed.title3dviewer')
      icon = "viewer"

      category = '/webjets/content/webjets/embed'
      webjet =
        data:
          titleOrigin: title
          url: url
          icon: icon
          origin: text

    else if isSketchfab(text)
      url = 'https://sketchfab.com/models/' + text.split('-').pop() + '/embed?dnt=1'
      title = i18n.t('webjet.embed.titleSketchfab')
      icon = 'sketchfab'
      category = '/webjets/content/webjets/embed'
      webjet =
        data:
          titleOrigin: title
          url: url
          icon: icon
          origin: text

    else if isDisqusComment(text)
      url = 'https://embed.disqus.com/p/' + text.split('/p/')[1]
      title = i18n.t('webjet.embed.titleDisqus')
      icon = 'disqus'
      category = '/webjets/content/webjets/embed'
      webjet =
        data:
          titleOrigin: title
          url: url
          icon: icon
          origin: text

    else if isPdfUrl(text)
      category = '/webjets/content/webjets/pdf'
      webjet =
        data:
          titleOrigin: fileName
          url: text
          origin: text

    else if isVideoUrl(text)
      category = '/webjets/content/webjets/html5video'
      webjet =
        data:
          titleOrigin: fileName
          url: text
          origin: text

    else if isAudioUrl(text)
      category = '/webjets/content/webjets/audio'
      webjet =
        data:
          titleOrigin: fileName
          url: text
          origin: text
    else if isTradingView(text) and FLAG.TRAIDING_WEBJET
      # example: https://www.tradingview.com/chart/?symbol=BITSTAMP%3ABTCUSD
      # example: https://www.tradingview.com/symbols/BTCUSD/
      splitted = if text.split('3A').length > 1 then text.split('%3A') else text.split('symbols')
      currency = splitted[1].split('/').join('')
      category = 'tradingview'
      webjet =
        data:
          titleOrigin: currency
          url: text
          origin: text
          icon: "traiding"
          currency: currency

    else
      # any other link
      category = '/webjets/content/webjets/link'
      webjet =
        data:
          titleOrigin: site
          url: text
          site: site

  else if isIframe(text)
    { src, scrolling } = getIframeParams text
    { webjet, category } = getWebjetForText src

    if category != '/webjets/content/webjets/link'
      category = category
      webjet = webjet
    else
      title = 'Embed iFrame'
      category = 'embedcode'
      webjet =
        data:
          url: src
          scrolling: scrolling
          titleOrigin: title

  else
    # if is html
    html = isHtml text
    if html
      category = '/webjets/content/webjets/note'
      webjet =
        data:
          html: html
    else
      # not an URL or html, just a text
      category = '/webjets/content/webjets/note'
      webjet =
        data:
          text: text

  return { category, webjet }


export getWebjetForWejeLink = (text) ->
  if isWejeLink(text)
    # console.log new URL(text).searchParams.get('w')
    result = {}
    url = new URL(text)
    webjet = null
    webjet = url?.searchParams.get('w') if url?.searchParams.get('w')
    linkTitle = ''
    linkTitle = url?.searchParams.get('wtitle') if url?.searchParams.get('wtitle')

    wejeSite = null
    wejeSite = url?.pathname.split('/')[2] if url?.pathname.split('/').indexOf 'w'


    if webjet
      src = "/data/#{webjet}"
      w = await firebind src
      unless linkTitle
        linkTitle = webjetTitle w

    if wejeSite
      src = "/wejes/#{wejeSite}"
      wsr = await firebind src
      user = wsr.creator
      user = wsr.user unless user
      src = "/data/#{user}/#{wejeSite}"
      ws = store.getters['weje/getWejeSiteData']({src})
      wsTitle = ws?.data?.title
      linkTitle = wsTitle if wsTitle

    result.category = 'btnLink'
    result.webjet = {
        data:
            title: linkTitle
            url: text
      }
  else
    result = null

  return result

export webjetsFromString = (text) ->
  stringIsHtml = false
  domparser = new DOMParser
  parseFromString = domparser.parseFromString(text, 'text/html')
  return false unless parseFromString
  childNodes = parseFromString.body.childNodes
  console.log 'parseFromString.body.childNodes', parseFromString
  for node in childNodes
    nodeType = node.nodeType
    if nodeType == 1
      stringIsHtml = true
      break
  availableTags = ['#text','P','SPAN','STRONG','B','H1','H3','H4','H5','H6','H7','A', 'TIME', 'UL', 'LI', 'OL', 'DIV','IMG']
  arr = []
  clearNodeWebjets = (nodeList, arr = []) ->
    clear = true
    for node in nodeList
      # console.log '@@@',node?.nodeName
      parent = node.parentNode
      if !(node?.nodeName in availableTags)

        console.log 'чаилды', node?.childNodes
        if node?.childNodes?.length
          for n in node?.childNodes
            clone = n.cloneNode true
            console.log '!', n,clone, node
            parent.insertBefore clone, node

        clear = false

        console.log '!!!!!!!!!!REMOVE',node
        parent.removeChild(node)
        break
      if node?.childNodes?.length
        clearNodeWebjets node?.childNodes
    if !clear and parent?.childNodes
      clearNodeWebjets parent?.childNodes
    return


  splitOn = (bound, cutElement) ->
    parent = cutElement.parentNode
    while bound != parent
      right = parent.cloneNode(false)
      while cutElement.nextSibling
        right.appendChild cutElement.nextSibling
      grandparent = parent.parentNode
      grandparent.insertBefore right, parent.nextSibling
      grandparent.insertBefore cutElement, right
      parent = grandparent
    return

  # console.log 'childNodes 1', childNodes
  clearNodeWebjets childNodes, arr
  imgs = Array.from(parseFromString.querySelectorAll("img"))
  for img in imgs
    splitOn parseFromString.body, img
  # console.log 'childNodes 2', childNodes
  dataArr = []
  for node in parseFromString.body.childNodes
    if node?.nodeName == 'IMG'
      dataArr.push {type: 'image', data: node.src}
    else
      text = node.textContent
      text = text.trim() if text
      if dataArr.length and dataArr[dataArr.length-1].type == 'html'
        dataArr[dataArr.length-1].data += node.outerHTML if text
      else
        dataArr.push {type: 'html', data: node.outerHTML } if text
  webjets = []
  for w in dataArr
    { category, webjet } = getWebjetForText w.data
    webjets.push { category, webjet }

  return {webjets: webjets, dataTransfer: null}

  # unless stringIsHtml
  #   { category, webjet } = getWebjetForText text
  #   return [{ category, webjet }]

  # imgs = Array.from(parseFromString.querySelectorAll("img"))
  # imgsUrls = []
  # for img in imgs
  #   imgsUrls.push img.src
  # failFetchImgsUrl = []
  # files = await Promise.all imgsUrls.map (url) =>
  #   console.log 'fetch', url
  #   try
  #     resp = await fetch url
  #     blob = await resp.blob()
  #     time = new Date()
  #     ext = blob.type.split('/')[1]
  #     filename = "#{time}.#{ext}"
  #     # console.log 'file', file
  #     return new File([blob], filename, {type: blob.type})


  #   catch
  #     failFetchImgsUrl.push url
  #     console.warn 'Cant download file'
  #     return null
  # dt = new DataTransfer()
  # for file in files
  #   if file
  #     dt.items.add(file)
  # webjets = []
  # for url in failFetchImgsUrl
  #   { category, webjet } = getWebjetForText url
  #   webjets.push { category, webjet }
  # for img in imgs
  #   img.parentNode.removeChild(img)
  # textAfterFilter = parseFromString?.body?.innerHTML?.replace(/<\!--.*?-->/g, "")?.trim()
  # # console.log 'textAfterFilter', textAfterFilter
  # if textAfterFilter
  #   { category, webjet } = getWebjetForText textAfterFilter
  #   webjets.unshift { category, webjet }
  # dataTransfer = if dt.files.length then dt else null
  # # console.log 'webjets', webjets, dt.files.length
  # return { webjets: webjets, dataTransfer: dataTransfer }

