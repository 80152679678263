import { isFirefox } from '@/utils'
import { encodeDataTransfer, decodeDataTransfer } from '@/utils'

export default
  mounted: ->
    document.addEventListener 'paste', @paste
  beforeDestroy: ->
    document.removeEventListener 'paste', @paste

  methods:
    paste: (e)->
      return unless @userCanEdit
      return if @presentationIsActive
      # default paste inside inputs
      if (e.target.localName == 'input' or e.target.localName == 'textarea') and e.target == document.activeElement
        return
      # default paste inside WEditor
      if document.activeElement?.classList?.contains('ql-editor') or document.activeElement?.classList?.contains('ql-clipboard')
        return
      p = e.target
      while p
        #console.log '?', { p }
        if p.classList?.contains('webjet')
          break # it's enough to iterate parents till webjet
        if p.classList?.contains('editor')
          return
        p = p.parentElement
      # define target webjet
      #selection = @$store.getters['webjet/selected']
      #if selection?.length == 1 and selection[0].canDroppable and selection[0].isExpanded
      #  dest = selection[0].webjet.$path
      #  @selectionCentralize()
      #else
      #  dest = @data.$path
      dest = @data.$path
      # process paste
      # console.log 'paste:', e.clipboardData.types, e.clipboardData.files, e.clipboardData.getData('text')
      type = undefined
      # at first find webjet type
      for t in e.clipboardData.types
        if t.split('/')[0] == 'webjets'
          type = t
          break
      # if no webjet type find another type
      unless type
        for t in @$store.getters['webjet/dataTransferTypes']
          if t in e.clipboardData.types
            type = t
            break
      if type=='Files' and not (await @provideApp.checkFileSizeLimits e.clipboardData.files, dest)
        e.preventDefault()
        return
      if type in @$store.getters['webjet/dataTransferTypes']
        if isFirefox
          if type=='Files'
            dt =
              types: [ 'Files' ]
              files: [ ...e.clipboardData.files ]
          else
            dt = new DataTransfer()
            dt.setData type, e.clipboardData.getData(type)
        else
          dt = e.clipboardData
        results = await @$store.dispatch 'webjet/dataTransfer',
          dest: dest
          boardPath: dest
          dataTransfer: dt
          data:
            coordinates: @getEmptyCoordinates
              x: @sceneX + @sceneWidth / 2 - 150
              y: @sceneY + @sceneHeight / 2 - 50
        @selectCreatedWebjets results, false, false
      else if type.split('/')[0] == 'webjets'
        transferWebjets = decodeDataTransfer type.substring(8)
        mode = transferWebjets.mode
        items = transferWebjets.items.map (v)=>
          path: v.path
          data:
            coordinates: @getEmptyCoordinates
              x: @sceneX + @sceneWidth / 2 - 150 - v.offsetX
              y: @sceneY + @sceneHeight / 2 - 50 - v.offsetY
        mode = 'copymove' if mode == 'move' and transferWebjets.boardSrc != @connection.src
        results = await @$store.dispatch 'webjet/transfer', { dest, items, mode, boardPath: @connection.src }
        if mode == 'copymove'
          @$store.dispatch 'undoredo/reset'
        if mode == 'copy'
          @selectCreatedWebjets results, false, false
