import { generateSort } from '@/utils'

export default
  data: ->
    hotKeyEnableDblclick: false
  listeners: ->
    dblclick: @hotkeyMouseCreateCard

  computed:
    keymap: ->
      'ctrl++': @hotkeyZoomIn
      'ctrl+numpad+': @hotkeyZoomIn
      'ctrl+numpadplus': @hotkeyZoomIn
      'numpad+': @hotkeyZoomIn
      'numpadplus': @hotkeyZoomIn
      'ctrl+-': @hotkeyZoomOut
      'ctrl+numpad-': @hotkeyZoomOut
      'numpad-': @hotkeyZoomOut
      'ctrl+0': @hotkeyZoomReset
      'ctrl+numpad0': @hotkeyZoomReset
      'ctrl+numpadzero': @hotkeyZoomReset
      'numpad0': @hotkeyZoomReset
      'numpadzero': @hotkeyZoomReset
      'c': @hotkeyCard
      'l': @hotkeyList
      'delete': @hotkeyTrash
      'backspace': @hotkeyTrash
      'space': @hotkeyToggleExpand
      'enter': @hotkeyEditTitle
      # 'ctrl+enter': @hotkeyActivateEditor
      'ctrl+enter': @hotkeyCntrlEnter
      'ctrl+g': @hotkeyGroupToList
      'u': @hotkeyUpload
      'left': @hotkeyLeftArrow
      'right': @hotkeyRightArrow
      'esc': @hotkeyEsc
      'i': @hotkeyInventory
      'ctrl+|': @hotkeyHideUi
      'shift+enter': @hotkeyCreateParentCard


  methods:
    hotkeyZoomIn: -> @zoomToLevel @zoom.level + 1
    hotkeyZoomOut: -> @zoomToLevel @zoom.level - 1
    hotkeyZoomReset: -> @zoomToLevel 0
    hotkeyCard: ->
      return if @presentationIsActive
      return if @isReadOnly
      #if @selected?.length == 1 and @selected[0].canDroppable and @selected[0].isExpanded
      #  dest = @selected[0].webjet.$path
      #  @selectionCentralize()
      #else
      #  dest = @data.$path
      dest = @data.$path
      result = await @$store.dispatch 'webjet/create',
        category: '/webjets/content/webjets/note'
        dest: dest
        boardPath: dest
        connection:
          coordinates: @getEmptyCoordinates
            x: @sceneX + @sceneWidth / 2 - 150
            y: @sceneY + @sceneHeight / 2 - 50
      @selectCreatedWebjets [ result ], true

    hotkeyList: ->
      return if @presentationIsActive
      return if @isReadOnly
      #if @selected?.length == 1 and @selected[0].canDroppable and @selected[0].isExpanded
      #  dest = @selected[0].webjet.$path
      #  @selectionCentralize()
      #else
      #  dest = @data.$path
      dest = @data.$path
      result = await @$store.dispatch 'webjet/create',
        category: '/webjets/containers/webjets/list'
        dest: dest
        boardPath: dest
        connection:
          coordinates: @getEmptyCoordinates
            x: @sceneX + @sceneWidth / 2 - 150
            y: @sceneY + @sceneHeight / 2 - 50
      @selectCreatedWebjets [ result ], true

    hotkeyMouseCreateCard: (e)->
      return unless @hotKeyEnableDblclick
      return if @presentationIsActive
      return if @isReadOnly
      return unless e.target.matches('.canvas,.scene,.paste-layer')
      result = await @$store.dispatch 'webjet/create',
        category: '/webjets/content/webjets/note'
        dest: @data.$path
        boardPath: @data.$path
        connection:
          coordinates:
            x: e.sceneX - 160
            y: e.sceneY - 40
      @selectCreatedWebjets [ result ]

    hotkeyTrash: (e)->
      return if @presentationIsActive
      return if @isReadOnly
      items = @$store.getters['webjet/selected'].map (w)-> { path: w.src }
      @$store.dispatch 'webjet/trash', { items }

    hotkeyToggleExpand: (e)->
      return if @presentationIsActive
      return if @isReadOnly
      if @$store.getters['webjet/selected'].length == 1
        webjet = @$store.getters['webjet/selected'][0]
        if webjet.isExpandable and webjet.canExpand
          webjet.toggleExpand()
      return

    hotkeyEditTitle: (e)->
      return if @presentationIsActive
      return if @isReadOnly
      if @$store.getters['webjet/selected'].length == 1 and !FLAG.NEW_CONTEXT_MENU
        input = document.querySelectorAll(".contextmenu .cardtitle input")
        if input and input.length == 1
          input[0].focus()
      return
    hotkeyCntrlEnter: (e) ->
      return unless FLAG.WEBJET_PARENT
      return if @presentationIsActive
      return if @isReadOnly
      if @$store.getters['webjet/selected']?.length == 1
        webjetComponent = @$store.getters['webjet/selected'][0]
        boardPath = @data.$path
        return unless webjetComponent?.canDroppable
        provideWebjet = webjetComponent.provideWebjet
        parentsArr = webjetComponent.parentWebjets.reverse()
        parentList = null
        for parent in parentsArr
          parentList = parent if parent.$options.name=='WebjetList'
          break if parentList
        if parentList
          connections = []
          group = 'items'
          if parentList.webjet.connections? and parentList.webjet.connections[group]
            connections.push(c) for k, c of parentList.webjet.connections[group]
          webjetForParams = if provideWebjet.$options.name=='WebjetList' then webjetComponent else provideWebjet
          columnKey = webjetForParams.connection.columnKey
          after = webjetForParams?.connection?.sortKey
          before = false
          for c in connections
            if Number.isFinite(c.sortKey) and c.sortKey > after
              if !before or c.sortKey < before
                before = c.sortKey
          sort = generateSort(parentList.webjet, before)
          connection = Object.assign {}, sort
          connection.columnKey = columnKey if columnKey
          result = await @$store.dispatch 'webjet/create',
            category: '/webjets/content/webjets/note'
            dest: parentList.webjet.$path
            boardPath: boardPath
            connection: connection
          @selectCreatedWebjets [ result ], true

        else
          if provideWebjet
            rect = provideWebjet.elementSceneRect
          else
            rect = webjetComponent.elementSceneRect
          result = await @$store.dispatch 'webjet/create',
            category: '/webjets/content/webjets/note'
            dest: boardPath #dest
            boardPath: boardPath
            connection:
              coordinates:
                x: rect.x2 + 80
                y: rect.y1

          @selectCreatedWebjets [ result ], true
    hotkeyCreateParentCard: (e) ->
      return unless FLAG.WEBJET_PARENT
      return if @presentationIsActive
      return if @isReadOnly
      if @$store.getters['webjet/selected'].length == 0
        @hotkeyCard(e)

    hotkeyActivateEditor: (e)->
      return if @presentationIsActive
      return if @isReadOnly
      if @$store.getters['webjet/selected'].length == 1
        webjet = @$store.getters['webjet/selected'][0]
        activateEditor = ()->
          if webjet.$refs.editor?
            webjet.$refs.editor.activateEditor()
        if webjet.isExpanded
          activateEditor()
        else
          @hotkeyToggleExpand(e)
          setTimeout activateEditor
      return

    hotkeyGroupToList: (e)->
      return if @presentationIsActive
      return if @isReadOnly
      if @$store.getters['webjet/selected'].length > 1
        items = @$store.getters['webjet/selected'].map (w)-> { path: w.src }
        @$store.dispatch 'webjet/groupToList',
          items: items
          data:
            coordinates:
              x: @sceneX + @sceneWidth / 2 - 150
              y: @sceneY + @sceneHeight / 2 - 50

    hotkeyUpload: (e)->
      return if @presentationIsActive
      return if @isReadOnly
      input = @$el.querySelector 'input.input-upload'
      if input
        input.click()

    hotkeyLeftArrow: (e)->
      if @presentationIsActive
        @prevWebjet()

    hotkeyRightArrow: (e)->
      if @presentationIsActive
        @nextWebjet()

    hotkeyEsc: (e)->
      if @presentationIsActive
        if !document.fullscreenElement
          if @userCanEdit or !@$route.query.pexit
            @$router.push path: @$router.path
    hotkeyInventory: (e)->
      @$refs.sidebar.toggle 'inventory'

    hotkeyHideUi: (e)->
      document.querySelector('#board-page').classList.toggle 'hide-ui'
