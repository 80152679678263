import {debounce} from '@/utils'

sockets = []
traffic = 0
messageCounter = 0
newTraffic = 0

addTraffic = =>
  traffic += newTraffic
  console.info "firebase ws traffic +#{newTraffic} bytes | Sum: #{traffic} Mes: #{messageCounter}"
  newTraffic = 0

debouncedAddTraffic = debounce addTraffic, 1000

nativeWebSocket = window.WebSocket
window.WebSocket = (args...)->
  if FLAG.DISABLE_FIREBASE_WEBSOCKET
    return {close: -> true}
  socket = new nativeWebSocket(...args)
  if socket.url.includes 'firebaseio'
    socket.addEventListener 'message', (m)=>
      #console.log 'mes', m.data.length
      newTraffic += m.data.length
      messageCounter += 1
      debouncedAddTraffic()
    sockets.push(socket)
  return socket
