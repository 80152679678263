















































# import { usualMixinPack } from './mixins'
import { webjet, positionable, expandable, childable, draggable, selectable, themeable, relationable, plusable, resizable } from './mixins'
import { isFirefox, isSafari } from '@/utils'
export default
  name: 'WebjetShape'
  mixins: [ webjet, positionable, expandable, childable, draggable, selectable, themeable, relationable, plusable, resizable ]
  data: ->
    observer: null
    fontSize: 22
    fontNormalized: false
    textChanged: true
    editmode: false
    maxTextLength: 150
  computed:
    baseWidth: ->
      if @resizeWidth or @connection?.size?.width
        80
      else
        160
    mozDragBlock: -> isFirefox
    inTrash: ->
      @isInTrash
    showHeader: ->
      return true if @provideWebjet
      return true if @isInTrash
    shapeClass: ->
      return 'star-shape' if @type == 'star'
      return 'circle-shape' if @type == 'circle'
      return 'square-shape' if @type == 'square'
      return 'trapezoid-shape' if @type == 'trapezoid'
      return 'diamond-shape' if @type == 'diamond'
      return 'triangle-top-shape' if @type == 'triangle-top'
      return 'triangle-bottom-shape' if @type == 'triangle-bottom'
      return 'triangle-right-shape' if @type == 'triangle-right'
      return 'triangle-left-shape' if @type == 'triangle-left'
      return 'data-base-shape' if @type == 'data-base'
      return 'parallelogram-shape' if @type == 'parallelogram'
      return 'parallelogram-right-shape' if @type == 'parallelogram-right'
      return 'ellipse-shape' if @type == 'ellipse'
      return 'doc-shape' if @type == 'doc'
    text: ->
      return '' unless @webjet?.data?.text
      @webjet?.data?.text
    type: ->
      @webjet?.data?.type
    selected: ->
      this.isSelected
    textStyle: ->
      fontSize = @fontSize
      return
        'font-size': "#{fontSize}px"
    viewBox: ->
      return '0 0 320 220' if @type == 'doc'
      return '0 0 320 200' if @type == 'ellipse'
      return '0 0 320 296' if @type == 'star'
      return '0 0 320 224' if @type == 'trapezoid'
      return '0 0 320 160' if @type == 'parallelogram' or @type == 'parallelogram-right'
      return '0 0 320 320'
    foreignObjectAttr: ->
      if @type == 'star'
        return
          x: 100
          y: 110
          width: 120
          height: 100
      else if @type == 'circle'
        return
          x: 50
          y: 50
          width: 220
          height: 220
      else if @type == 'square'
        return
          x: 10
          y: 10
          width: 300
          height: 300
      else if @type == 'trapezoid'
        return
          x: 70
          y: 10
          width: 180
          height: 210
      else if @type == 'diamond'
        return
          x: 80
          y: 80
          width: 160
          height: 160
      else if @type == 'triangle-top'
        return
          x: 80
          y: 150
          width: 160
          height: 170
      else if @type == 'triangle-bottom'
        return
          x: 80
          y: 0
          width: 160
          height: 170
      else if @type == 'triangle-right'
        return
          x: 10
          y: 80
          width: 160
          height: 160
      else if @type == 'triangle-left'
        return
          x: 150
          y: 80
          width: 160
          height: 160
      else if @type == 'data-base'
        return
          x: 10
          y: 110
          width: 300
          height: 160
      else if @type == 'parallelogram'
        return
          x: 80
          y: 5
          width: 160
          height: 150
      else if @type == 'parallelogram-right'
        return
          x: 80
          y: 5
          width: 160
          height: 150
      else if @type == 'ellipse'
        return
          x: 80
          y: 5
          width: 160
          height: 190
      else if @type == 'doc'
        return
          x: 5
          y: 5
          width: 310
          height: 170
  methods:
    keyDownEnter: (e)->
      if e.ctrlKey
        @onInput(e)
        e.target.blur()
      else
        document.execCommand 'insertLineBreak'
        e.preventDefault()
    touchStop: (e)->
      if @selected and @editmode
        e.stopPropagation()
    editorDragstart: (e)->
      if @selected and @editmode
        e.preventDefault()
        e.stopPropagation()
    editorWrapMousedown: (e)->
      if @selected and @editmode
        if !e.target.matches('.text-in-svg')
          e.stopPropagation()
          e.preventDefault()
        else
          if e.shiftKey || e.ctrlKey || e.altKey || e.metaKey
            e.stopPropagation()
    editorMouseUp: (e) ->
      if @selected
        e.stopPropagation()
    click: (e) ->
      return if e? and (e.shiftKey || e.ctrlKey || e.altKey || e.metaKey)
      if @editmode
        e.stopPropagation()
      else
        if @selected
          @editmode = true
          @preventDraggableShape(true)
          setTimeout ( =>
            @$refs.text.focus()
            @$refs.text.click()
            # @$refs.text.setSelectionRange(0, 0)
          ), 100
        # @$refs.text.focus()
        # @$refs.text.click()
      #   # e.stopPropagation()
      #   e.preventDefault()
    blur: (e) ->
      # document.activeElement.blur()
      @editmode = false
      @preventDraggableShape(false)
      window.getSelection().removeAllRanges()
    onInput: (e) ->
      value = e.target.innerText
      @$store.dispatch 'webjet/updateShapeText',
        path: @webjet?.$path
        value: value
    onPaste: (e) ->
      text = (e.originalEvent || e).clipboardData.getData 'text/plain'
      maxTextLength = @maxTextLength
      oldTextLength = @$refs.text.innerText.length
      textLength = maxTextLength - oldTextLength
      if textLength > 0
        text = text.substring 0, textLength
        document.execCommand 'insertHTML', false, text
      e.preventDefault()
      e.stopPropagation()
    hotkeyBlocker: (e) ->
      # allow undo/redo
      if e.ctrlKey && e.keyCode in [ 89, 90 ]
        return
      # block other hotkeys
      e.stopPropagation()
      # block ctrl +/-/0
      if e.ctrlKey && e.keyCode in [ 187, 189, 48, 107, 109, 45, 43, 96 ]
        e.preventDefault()

      if e.target.innerText.length >= @maxTextLength
        if e.keyCode in [ 8, 38, 39, 37, 40, 46  ] or e.ctrlKey or e.metaKey
         return
        else
          e.preventDefault()
          e.stopPropagation()

    preventDraggableShape: (v) ->
      if @mozDragBlock
        @$el.dispatchEvent new CustomEvent 'preventdrag', { detail: { prevent: v }, bubbles: true, cancelable: true }

    normalizeFont: ->
      return unless @genShow
      # return if @inTrash
      defaultFont = 22
      oldFont = @fontSize
      @fontSize = defaultFont
      self = this
      initNormalize = () ->
        # console.log 'initNormalize', self.$refs.textWrap.offsetHeight, self.$refs.text.offsetHeight
        # console.log 'initNormalize', self.$refs.textWrap.scrollHeight, self.$refs.text.scrollHeight
        self.$nextTick ->
          if self.$refs.textWrap.offsetHeight <= self.$refs.text.offsetHeight and self.$refs.textWrap.offsetHeight > 0
          # if self.$refs.textWrap.scrollHeight <= self.$refs.text.scrollHeight and self.$refs.textWrap.offsetHeight > 0

            self.fontNormalized = true
            self.textChanged = false
            oldFont = self.fontSize
            self.fontSize  = oldFont - 0.5
            initNormalize()
      initNormalize()

  watch:
    type: (val)->
      @normalizeFont()
    'isExpanded': (val)->
      if val
        @$nextTick ->
          if @text and @$refs.text
            @$refs.text.innerText = @text
            @normalizeFont()
            @observer = new MutationObserver (mutationRecords)=>
              @normalizeFont()
            @observer.observe @$refs.text, {childList: true,characterData: true,subtree:true}
    selected: (val) ->
      if !val
        @editmode = false
        @preventDraggableShape(false)
        @$refs.text.blur()
        document.activeElement.blur()
        window.getSelection().removeAllRanges()
    'genShow': (val,oldVal) ->
      if !oldVal and val and !@fontNormalized and @textChanged
        @normalizeFont()
    'parentIsShow': (val,oldVal) ->
      if !oldVal and val and !@fontNormalized and @textChanged
        @normalizeFont()
    text: (val) ->
      @textChanged = true
      @$refs.text.innerText = val
  mounted: ->
    if @isExpanded
      @observer = new MutationObserver (mutationRecords)=>
        @normalizeFont()
      @observer.observe @$refs.text, {childList: true,characterData: true,subtree:true}

    if @text and @$refs.text
      @$refs.text.innerText = @text
      @normalizeFont()
  beforeDestroy: ->
    if @observer
      @observer.disconnect()
