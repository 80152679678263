export default
  INIT_DB: (state, { db, storage, auth })->
    state.db = db
    state.storage = storage
    state.auth = auth
    #state.needAuth = true
  INIT_USER: (state, user)->
    state.needAuth = false
    state.uid = user.uid
    state.isAnonymousUser = user.isAnonymous
    state.isRegisteredUser = !user.isAnonymous
  INIT_CACHE_USER: (state, user)->
    state.uid = user.uid
    state.isRegisteredUser = user.isRegisteredUser
    state.isAnonymousUser = user.isAnonymousUser
