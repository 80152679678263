






export default
  name: 'GizmoDate'
  props: ['data']
  data: ->
    timeout: false
  mounted: ->
    @tick()
  computed:
    webjet: ->
      @firebind @data.src

    datetime: ->
      @webjet.data.datetime

    timeLeft: ->
      @timeout
      @datetime - Date.now()

    daysLeft: ->
      Math.floor(@timeLeft / (24 * 60 * 60 * 1000))

    minutesLeft: ->
      Math.floor(@timeLeft / (60 * 1000))

    hoursLeft: ->
      Math.floor(@timeLeft / (60 * 60 * 1000))

    tickDelay: ->
      return 1000 unless @datetime
      return 24 * 60 * 60 * 1000 if @timeLeft < 0
      delay = @timeLeft / 100
      delay = 1000 if @minutesLeft < 100
      delay

    completed: ->
      @webjet?.data?.completed || false

    status: () ->
      return 'status-delay' if @daysLeft < 0 or @minutesLeft < 1
      return 'status-today' if @daysLeft == 0

  methods:
    tick: ->
      clearTimeout @timeout
      @timeout = setTimeout @tick, @tickDelay
  watch:
    tickDelay: ->
      @tick()
