














import { usualMixinPack } from './mixins'

export default
  name: 'WebjetAudio'
  mixins: [...usualMixinPack]
  computed:
    url: ->
      @webjet?.data?.url
    icon: ->
      @webjet?.data?.icon

