






















import { usualMixinPack } from './mixins'
import { userDisplayName, selectCreatedWebjetsWithoutCanvas } from '@/utils'
import Avatar from '@/components/Avatar.vue'

export default
  name: 'WebjetMention'
  mixins: [...usualMixinPack]
  components: { Avatar }
  computed:
    mentioned: ->
      @webjet?.data?.mentioned
    referrer: ->
      @webjet?.data?.referrer
    mentionedName: ->
      userDisplayName(@webjet?.data?.mentioned.uid).split(' ')[0]
    referrerName: ->
      userDisplayName(@webjet?.data?.referrer).split(' ')[0]
    active: ->
      @webjet?.data?.active
    isUserMentioned: ->
      @mentioned.uid == @$store.state.uid
  methods:
    toggleStatus: ->
      @$store.dispatch 'webjet/setMentionedStatus',
        path: @webjet?.$path,
        active: !@active
    restoreHints: ->
      await @$store.dispatch 'webjet/updateCategoryWebjet',
        path: @webjet.$path
        category: '/webjets/content/webjets/note'

      selectCreatedWebjetsWithoutCanvas @connection.$path

