import { isMac } from '@/utils'

export default
  listeners: ->
    wheel: @wheel

  methods:
    wheel: (e)->
      # use wheel on presentation control
      cPath = e.composedPath()
      for node in cPath
        if node?.classList?.contains 'presentation-controls'
          return
      e.preventDefault()
      @trackTimeStamp = 0 unless @trackTimeStamp
      timeDelta = e.timeStamp - @trackTimeStamp
      #console.log {deltaMode: e.deltaMode, deltaX: e.deltaX, deltaY: e.deltaY, ctrlKey: e.ctrlKey, timeDelta: timeDelta}
      @trackTimeStamp = e.timeStamp
      if e.deltaMode == 1
        if e.deltaX || !e.ctrlKey && (timeDelta < 1000 || Math.abs(e.deltaY) < 1 || e.deltaY % 1 != 0)
          @wheelTrackpad(e)
          return
      else
        if e.deltaX || !e.ctrlKey && (timeDelta < 1000 || Math.abs(e.deltaY) < 100 || e.deltaY % 5 != 0)
          @wheelTrackpad(e)
          return
      @trackTimeStamp = 0
      return if e.deltaY == 0

      @wheelTimeStamp = 0 unless @wheelTimeStamp
      wheelDelta = e.timeStamp - @wheelTimeStamp
      @wheelTimeStamp = e.timeStamp
      #console.log { wheelDelta, deltaY: e.deltaY }
      if e.deltaMode == 1 and Math.abs(e.deltaY) < 1
        # skip 4 out of 5 events to make trackpad zoom with reasonable speed
        # deltaMode==1 is for FF, and wheelDelta<50 is for very fast trackpads
        @wheelCount = 0 unless @wheelCount
        @wheelCount++
        if @wheelCount > 1 and wheelDelta < 100
          @wheelCount = 0 if @wheelCount > 5
          return
      else if wheelDelta < 50 and Math.abs(e.deltaY) < 100
        @wheelCount = 0 unless @wheelCount
        @wheelCount++
        if @wheelCount > 1
          @wheelCount = 0 if @wheelCount > 3
          return

      scale = @scale
      mouseX = e.canvasX - @x
      mouseY = e.canvasY - @y
      if e.deltaY > 0
        mouseX = @$el.offsetWidth / 2 - @x
        mouseY = @$el.offsetHeight / 2 - @y
      @zoom.level -= e.deltaY / Math.abs(e.deltaY)
      @zoom.dx += (mouseX / @scale - mouseX / scale) * @scale
      @zoom.dy += (mouseY / @scale - mouseY / scale) * @scale

    zoomToLevel: (level)->
      if @zoom.level != level
        scale = @scale
        centerX = @screen.width / 2 - @x
        centerY = @screen.height / 2 - @y
        @zoom.level = level
        @zoom.dx += (centerX / @scale - centerX / scale) * @scale
        @zoom.dy += (centerY / @scale - centerY / scale) * @scale

    zoomToFit: ()->
      return unless @childWebjetComponents.length
      x1 = false
      y1 = false
      x2 = false
      y2 = false
      for w in @childWebjetComponents
        if w.isTheoreticallyVisible
          rect = w.elementRect
        else
          rect = { x1: w.x, y1: w.y}
          rect.x2 = rect.x1
          if w.connection.size?.width
            rect.x2 += w.connection.size.width
          else
            rect.x2 += 300
          rect.y2 = rect.y1 + 300
        x1 = rect.x1 if !x1 or x1 > rect.x1
        y1 = rect.y1 if !y1 or y1 > rect.y1
        x2 = rect.x2 if !x2 or x2 < rect.x2
        y2 = rect.y2 if !y2 or y2 < rect.y2
      width = x2 - x1
      height = y2 - y1
      sWidth = @screen.width
      sHeight = @screen.height
      i = 0
      while (width * Math.pow(1.2, i)) > sWidth or (height * Math.pow(1.2, i)) > sHeight
        i -= 1
      @zoomToLevel i
      @sceneMoveCenterTo x1 + width / 2, y1 + height / 2


    wheelTrackpad: (e)->
      dx = e.deltaX
      dy = e.deltaY
      if isMac
        @position.x -= dx
        @position.y -= dy
      else
        if e.deltaMode == 1
          dx = dx * 20
          dy = dy * 20
        @position.x -= dx
        @position.y -= dy
