





import Gizmo from './gizmos/Gizmo'
import { findAllContainers } from './gizmos/helpers'
export default
  name: 'Gizmos'
  components: { Gizmo }
  computed:
    show: ->
      @webjets.length > 0
    canvas: -> @context.canvas
    scene: -> @canvas.sceneNow
    styles: ->
      transform: "translateX(#{-@scene.x1 * @canvas.animate.scale}px) translateY(#{-@scene.y1 * @canvas.animate.scale}px)"
    webjets: ->
      canvas = @context.canvas
      result = []
      all = findAllContainers canvas.src
      for src in all
        w = canvas.getWebjetComponentBySrc src
        result.push w if w
      result
