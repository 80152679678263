





export default
  name: 'WContent'
  inject: ['provideWebjet']

