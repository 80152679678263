





export default
  name: 'Grid'
  inject: ['provideCanvas']
  mounted: ->
    @draw()
  computed:
    width: ->
      @$vuetify.breakpoint.width
    height: ->
      @$vuetify.breakpoint.height
    animationActive: ->
      @provideCanvas.animation.active
    stateSum: ->
      "#{@animationActive}#{@provideCanvas.position.x}#{@provideCanvas.position.y}" +
      "#{@$vuetify.breakpoint.width}#{@$vuetify.breakpoint.height}#{@provideCanvas.scale}"
    gridType: ->
      @$store.getters['user/grid']
  methods:
    drawLines: (ctx, scale, rect, size, minScale, maxScale, opacityCenter)->
      return unless scale >= minScale and (scale < maxScale or !maxScale)
      cell = size * 20
      if maxScale
        minLevel = Math.log minScale
        maxLevel = Math.log maxScale
        curLevel = Math.log scale
        if opacityCenter
          distance = Math.abs(Math.log(opacityCenter) - curLevel)
          opacity = (1 - distance / ((maxLevel - minLevel))) * 0.06
        else
          distance = Math.abs((minLevel + maxLevel) / 2 - curLevel)
          opacity = (1 - distance / ((maxLevel - minLevel) / 2)) * 0.06
      else
        opacity = 0.06
      ctx.beginPath()
      ctx.strokeStyle = "rgba(130, 130, 130, #{opacity})"
      ctx.lineWidth = 1#0.5
      startX = rect.x % (cell * scale)
      startY = rect.y % (cell * scale)
      i = 0
      x = startX
      while x < @width
        x = startX + i * cell * scale
        x = 0.5 + Math.round x
        ctx.moveTo x, 0
        ctx.lineTo x, @height
        i += 1
      i = 0
      y = startY
      while y < @height
        y = startY + i * cell * scale
        y = 0.5 + Math.round y
        ctx.moveTo 0, y
        ctx.lineTo @width, y
        i += 1
      ctx.stroke()
      ctx.closePath()
    drawDashLine: (ctx, scale, rect, size, minScale, maxScale, opacityCenter)->
      return unless scale >= minScale and (scale < maxScale or !maxScale)
      cell = size * 20
      if maxScale
        minLevel = Math.log minScale
        maxLevel = Math.log maxScale
        curLevel = Math.log scale
        if opacityCenter
          distance = Math.abs(Math.log(opacityCenter) - curLevel)
          opacity = (1 - distance / ((maxLevel - minLevel))) * 0.3
        else
          distance = Math.abs((minLevel + maxLevel) / 2 - curLevel)
          opacity = (1 - distance / ((maxLevel - minLevel) / 2)) * 0.3
      else
        opacity = 0.3
      dashSize = 3
      ctx.strokeStyle = "rgba(144, 144, 144, #{opacity})"
      ctx.lineWidth = dashSize
      startX = rect.x % (cell * scale)
      startY = rect.y % (cell * scale)
      i = 0
      x = startX
      x = startX + i * cell * scale
      x = 0.5 + Math.round x
      ctx.lineCap = "round"
      ctx.setLineDash([0.1, (cell * scale - 0.1) ])
      ctx.lineDashOffset = -x

      # x = startX
      y = startY
      while y < @height

        ctx.beginPath()
        y = startY + i * cell * scale
        y = 0.5 + Math.round y
        ctx.moveTo 0, y
        ctx.lineTo @width, y
        ctx.stroke()


        i += 1


    drawDotsPattern: (ctx, scale, rect, size, minScale, maxScale, opacityCenter) ->
      return unless scale >= minScale and (scale < maxScale or !maxScale)
      pattern = @$refs.pattern
      pattern.width = 200
      pattern.height = 200
      pctx = pattern.getContext '2d'
      if maxScale
        minLevel = Math.log minScale
        maxLevel = Math.log maxScale
        curLevel = Math.log scale
        if opacityCenter
          distance = Math.abs(Math.log(opacityCenter) - curLevel)
          opacity = (1 - distance / ((maxLevel - minLevel))) * 0.6
        else
          distance = Math.abs((minLevel + maxLevel) / 2 - curLevel)
          opacity = (1 - distance / ((maxLevel - minLevel) / 2)) * 0.6
      else
        opacity = 0.1
      cell = size * 20
      startX = rect.x % (cell * scale)
      startY = rect.y % (cell * scale)
      r = 3
      x = startX
      i = 0
      while x < pattern.width
        x = startX + i * cell * scale
        x = 0.5 + Math.round x
        u = 0
        i += 1
        y = startY
        while y < pattern.height
          y = startY + u * cell * scale
          y = 0.5 + Math.round y
          pctx.fillStyle = "rgba(0, 0, 0, #{opacity})"
          pctx.fillRect x-r/2,y-r/2,r,r
          u += 1
      pattern = ctx.createPattern pattern, "repeat"
      ctx.rect 0, 0, @width, @height
      ctx.fillStyle = pattern
      ctx.fill()
    drawDots: (ctx, scale, rect, size, minScale, maxScale, opacityCenter) ->
      return unless scale >= minScale and (scale < maxScale or !maxScale)
      if maxScale
        minLevel = Math.log minScale
        maxLevel = Math.log maxScale
        curLevel = Math.log scale
        if opacityCenter
          distance = Math.abs(Math.log(opacityCenter) - curLevel)
          opacity = (1 - distance / ((maxLevel - minLevel))) * 0.3
        else
          distance = Math.abs((minLevel + maxLevel) / 2 - curLevel)
          opacity = (1 - distance / ((maxLevel - minLevel) / 2)) * 0.3
      else
        opacity = 0.2
      # if scale <= 0.7
      #   size = 4
      cell = size * 20
      cellBold = size * 20 * 4
      startX = rect.x % (cell * scale)
      startY = rect.y % (cell * scale)
      startXBold = rect.x % (cellBold * scale)
      startYBold = rect.y % (cellBold * scale)
      r = 3
      x = startX
      xBold = startXBold
      i = 0
      boldDotsY = new Set
      boldDotsX = new Set
      while x < @width
        x = startX + i * cell * scale
        x = 0.5 + Math.round x
        xBold = startXBold + i * cellBold * scale
        xBold = 0.5 + Math.round xBold
        boldDotsX.add xBold unless size == 17
        bold = false
        u = 0
        i += 1
        y = startY
        yBold = startYBold
        while y < @height
          y = startY + u * cell * scale
          y = 0.5 + Math.round y
          yBold = startYBold + u * cellBold * scale
          yBold = 0.5 + Math.round yBold
          # opacity  = 0.2
          boldDotsY.add yBold unless size == 17
          if boldDotsX.has(x) and boldDotsY.has(y)
            ctx.fillStyle = "rgba(0, 0, 0, 0.4)"
          else
            ctx.fillStyle = "rgba(0, 0, 0, #{opacity})"
          # ctx.fillRect x-r/2,y-r/2,r,r
          u += 1


    draw: ->
      unless @drawTimestamp
        @drawTimestamp = Date.now()
      sceneEl = @provideCanvas.$refs.scene.$el

      rect =
        x: @provideCanvas.position.x + @provideCanvas.animate.x
        y: @provideCanvas.position.y + @provideCanvas.animate.y
      scale = @provideCanvas.animate.scale

      ctx = @$refs.canvas.getContext '2d'
      ctx.clearRect(0, 0, @width, @height)
      if @gridType == 'dots'
        @drawDashLine(ctx, scale, rect, 1, 1)
        @drawDashLine(ctx, scale, rect, 1, 0.5, 1, 1)
        @drawDashLine(ctx, scale, rect, 4, 0.125, 20)
        @drawDashLine(ctx, scale, rect, 16, 0.02, 1)
      else
        @drawLines(ctx, scale, rect, 1, 1)
        @drawLines(ctx, scale, rect, 1, 0.5, 1, 1)
        @drawLines(ctx, scale, rect, 4, 0.125, 20)
        @drawLines(ctx, scale, rect, 4, 0.125, 20)
        @drawLines(ctx, scale, rect, 16, 0.0125, 1)

      if @animationActive
        @drawActive = true
        if (Date.now() - @drawTimestamp) > 5000
          console.warn 'Grid: 5 second draw'
          @drawTimestamp = Date.now()
        requestAnimationFrame @draw
      else
        @drawActive = false
        @drawTimestamp = null
  watch:
    stateSum: ->
      unless @drawActive
        requestAnimationFrame @draw
    gridType: ->
      unless @drawActive
        requestAnimationFrame @draw
