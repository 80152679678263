









import ContextBtn from './ContextBtn'
export default
  name: 'ContextMenuMore'
  components: { ContextBtn }
