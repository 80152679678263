cache = {}
PREFIX = 'FLAG_'
window.RUNTIME_FLAG = (key, value)->
  if cache[key] == undefined
    if localStorage[PREFIX+key] == 'true'
      cache[key] = true
    else if localStorage[PREFIX+key] == 'false'
      cache[key] = false
    else
      cache[key] = null
  if cache[key] == true or cache[key] == false
    cache[key]
  else
    value
