
baseTitle = document.title
components = []
calcTitle = ()->
  title = false
  for c in components
    if c.title and !c.prefix and !c.suffix
      title = c.title
  title = baseTitle unless title
  for c in components
    if c.title and c.prefix
      title = c.title + title
  for c in components
    if c.title and c.suffix
      title = title + c.title
  document.title = title

export default
  name: 'PageTitle'
  props:
    title: String
    prefix: Boolean
    suffix: Boolean
  beforeCreate: ->
    components.push this
  beforeDestroy: ->
    components = components.filter (v)=> v!=this
    calcTitle()
  render: ()-> false
  watch:
    title:
      immediate: true
      handler: calcTitle
