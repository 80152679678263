import { getRef } from '@/utils'
import { firebind } from '@/utils'
import { nowFirebase } from '@/utils'

export default
  CREATE_PROJECT: (_, { key, data }) ->
    getRef('/projects').child(key).child('team').set data?.team
    if data.title
      getRef('/projects').child(key).child('title').set data.title
    getRef('/teams').child(data?.team).child('projects').child(key).set
      status: 'ok'
      sortKey: nowFirebase()
    if data?.public
      getRef('/teamAccess').child(data?.team).child('openProjects').child(key).set data.public
    if Object.keys(data?.users).length
      for k,v of data?.users
        getRef('/teamAccess').child(data?.team).child('projects').child(k).child(key).set v

  DELETE_PROJECT_FROM_PROJECTS: (_, {projectId}) ->
    getRef('/projects').child(projectId).remove()

  DELETE_PROJECT_FROM_TEAM: (_, {projectId, teamId}) ->
    getRef('/teams').child(teamId).child('projects').child(projectId).remove()

  DELETE_PROJECT_FROM_TEAM_OPEN_PROJECT: (_, {projectId, teamId}) ->
    getRef('/teamAccess').child(teamId).child('openProjects').child(projectId).remove()

  DELETE_PROJECT_FROM_TEAM_ACCESS: (_, { teamId, userId, projectId}) ->
    getRef('/teamAccess').child(teamId).child('projects').child(userId).child(projectId).remove()

  UPDATE_PROJECT_TITLE: (_, { id, title}) ->
    getRef('/projects').child(id).child('title').set title

  UPDATE_PROJECT_ROLE: (_,{ teamId, projectId, userId, role })->
    getRef('/teamAccess').child(teamId).child('projects').child(userId).child(projectId).set role

  UPDATE_OPEN_PROJECT_RIGHTS: (_,{ teamId, projectId, rights })->
    getRef('/teamAccess').child(teamId).child('openProjects').child(projectId).set rights