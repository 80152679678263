import Vue from 'vue'
import { firebind, mapFirebind } from '@/utils'

Vue.prototype.firebind = firebind
window.devFirebind = firebind if IS_STAGE or IS_DEVELOPMENT

mergeStrategies = Vue.config.optionMergeStrategies
mergeStrategies.firebind = mergeStrategies.methods

Vue.mixin
  beforeCreate: ->
    if @$options.firebind
      bindings = @$options.firebind
      for k, v of bindings
        if k of this
          console.warn "firebind mixin: already has property #{k}"
        unless typeof v == 'function'
          console.error 'firebind mixin: only functions'
        mapped = mapFirebind(v, k)
        Object.defineProperty this.__proto__, k, {get: mapped}
      delete @$options.__proto__.firebind
