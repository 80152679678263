import Vue from 'vue'

iframeCount = 0

changeIframeCount = (count) ->
  iframeCount += count
  if iframeCount < 1
    removeFocuslessElement()
  else
    createFocuslessElement()

blurChanged = () ->
  if document.activeElement.tagName == 'IFRAME'
    selected = document.activeElement.getAttribute('focusless')
    if selected == 'false'
      removeFocus()


removeFocus = () ->
  el = document.getElementById('focusless')
  if el
    el.focus({preventScroll: true})
    setTimeout => el.blur()

createFocuslessElement = () ->
  el = document.getElementById('focusless')
  unless el
    el = document.createElement('div')
    el.setAttribute('id', 'focusless')
    el.setAttribute('contenteditable', 'true')
    el.style.width = '1px'
    el.style.height = '1px'
    el.style.left = '-1000px'
    el.style.position = 'absolute'

    document.body.appendChild(el)
    window.addEventListener 'blur', blurChanged

removeFocuslessElement = () ->
  el = document.getElementById('focusless')
  if el
    el.parentNode.removeChild(el)
    window.removeEventListener 'blur', blurChanged

Vue.directive 'iframe-autofocusless',
  bind: (el, { value, arg }, vnode)->
    el.setAttribute('focusless', value)
    changeIframeCount(1)

  update: (el, { value, oldValue, arg }, vnode)->
    el.setAttribute('focusless', value) if value != oldValue
    if oldValue and !value
      removeFocus() if document.activeElement == el


  unbind: (el)->
    changeIframeCount(-1)
