
















import { usualMixinPack } from './mixins'

export default
  name: 'WebjetPdf'
  mixins: [...usualMixinPack]
  computed:
    url: ->
      @webjet?.data?.url

