




















































































































































import { teamProjectsLimitIsOver } from '@/utils'

import { mapGetters } from 'vuex'
import AvatarTeam from '@/components/AvatarTeam.vue'
import BoardPreview from './TeamSpaces/BoardPreview.vue'
import SpaceCreate from './TeamSpaces/SpaceCreate.vue'
import NavbarContent from './NavbarContent.vue'
import DialogTemplate from '@/components/DialogTemplate'
import ProjectSettings from '@/components/Project/Settings.vue'
import ProjectName from '@/components/Project/Name.vue'
import ProjectPublic from '@/components/Project/Public.vue'
import ProjectCollab from '@/components/Project/Collab.vue'
import BoardConfigModal from '@/components/BoardConfig/BoardConfigModal'

WEEK_IN_MS = 7*24*60*60*1000 # 7d 24h 60m 60s 1000ms

export default
  name: 'NavbarTeamSpaces'
  inject: ['provideApp']
  props:
    boardId: { default: false }
    teamId: { default: false }
  data: ->
    searchDialog: false
    search: ''
    showNavbarBanner: false
    selectedProject: ''
    projectCreateDialog: false
    projectSettingsDialog: false
    boardConfig: false
    boardConfigSrc: null
    showTrashBoards: false
    showLeaveTeamDialog: false
  provide: ->
    provideNavbarSpaces: this
  context:
    teamId: -> @team
  mounted: ->
    @bannerShow()
  components:
    { NavbarContent, AvatarTeam, BoardPreview, SpaceCreate, DialogTemplate, ProjectSettings, ProjectName, ProjectCollab, ProjectPublic, BoardConfigModal }
  watch:
    teamId: (oldVal, val) ->
      if oldVal != val
        @selectedProject = ''
  computed:
    canSeeProjects: ->
      if @team
        @$store.getters.availableEditProjects(@team).length
    canAddProject: ->
      return @userRoleInTeam in ['owner','admin']
    canCreateBoard: ->
      if @team
        @$store.getters.availableEditProjects(@team).length
    userRoleInTeam: ->
      uid = @$store.state.uid
      role = @firebind "/teamAccess/#{@team}/roles/#{uid}"
      role.$value
    canGoToTeamSettings: ->
      @userRoleInTeam == 'owner' or @userRoleInTeam == 'admin'
    boardAccess: ->
      @$store.getters.boardAccess @boardId
    show: ->
      if @$route.name == 'Board'
        if @boardAccess.denied
          @provideApp.navbarOpen()
        !@boardAccess.denied
      else
        true
    boards: ->
      boards = @$store.getters.teamBoardsSorted @team
      if @selectedProject
        filtreBoardsByProject = []
        for b in boards
          project = @firebind "/boards/#{b}/project"
          filtreBoardsByProject.push b if project.$value == @selectedProject
        return filtreBoardsByProject
      boards
      #@$store.getters.currentTeamBoards
    trashBoards: ->
      boards = @$store.getters.teamBoardsSorted @team, true
      if @selectedProject
        filtreBoardsByProject = []
        for b in boards
          project = @firebind "/boards/#{b}/project"
          filtreBoardsByProject.push b if project.$value == @selectedProject
        return filtreBoardsByProject
      boards
    team: ->
      if @teamId
        @teamId
      else if @boardId
        boardTeam = @firebind("/boards/#{@boardId}/team")
        if boardTeam.$ready
          boardTeam.$value
        else
          false
      else if @$route.path == '/'
        "team_#{@$store.state.uid}"
      else
        false
      #@$store.getters.currentTeamId

    teamData: ->
      @firebind "/teams/#{@team}"

    dataTitle: ->
      t = @firebind("/teams/#{@team}/title")
      t?.$value

    title: ->
      return '' unless @team
      if @dataTitle
        @dataTitle
      else if @team.startsWith 'team_'
        uid = @team.split('_')[1]
        firstUserName = @firebind '/users/'+uid+'/firstName'
        lastUserName = @firebind '/users/'+uid+'/lastName'
        email = @firebind '/users/'+uid+'/email'
        "#{firstUserName.$value || lastUserName.$value || email.$value?.split('@')[0] }'s team"


    nolimits: ->
      val = @firebind("/teamBilling/#{@team}/nolimits")
      val.$value
    plan: ->
      if @nolimits
        return 'legacy'
      plan = @firebind "/teamBilling/#{@team}/plan"
      if plan.$value == null
        return 'free'
      else
        plan.$value
    projects: ->
      result = {}
      projects = @$store.getters.availableViewProjects(@team)
      canEditProjects = @$store.getters.availableManageProjects(@team)
      for p in projects
        projectsData = @firebind "/projects/#{p}"
        projectIsPublic = @firebind "/teamAccess/#{@team}/openProjects/#{p}"
        canEdit = p in canEditProjects
        data = Object.assign {projectIsPublic: projectIsPublic?.$value, canEdit: canEdit}, projectsData
        result[p] = data
      if @selectedProject and !result[@selectedProject]
        @selectedProject = ''
      result
    canLeaveTeam: ->
      @userRoleInTeam != 'owner'

    showDonateBanner:
      get: ->
        return false if @nolimits
        return false if @plan != 'free'
        #console.log '?', @donateBannerTime, new Date().getTime(), new Date().getTime() - @donateBannerTime, WEEK_IN_MS, new Date().getTime() - @donateBannerTime < WEEK_IN_MS
        return false if @donateBannerTime && new Date().getTime() - @donateBannerTime < WEEK_IN_MS
        true
      set: (val) ->
        #console.log '!', val
        @$store.dispatch 'closeDonateBanner'

    donateBannerTime: ->
      val = @firebind("/users/#{@$store.state.uid}/settings/donate_banner_time")
      val.$value || 0

  methods:
    createProject: ->
      limitIsOver = teamProjectsLimitIsOver @team
      if limitIsOver
        @provideApp.dialogMaxLimitProjectTeamId = @team
      else
        @provideApp.dialogCreateProjectTeamId = @team
    openProjectSettings: (key) ->
      @provideApp.dialogProjectSettingsId = key
    selectProject: (p) ->
      @selectedProject = p

    leaveTeam: ->
      if @team
        await @$store.dispatch 'team/leaveTeam',
          teamId: @team
        @$router.push { name: 'Home' }
      @showLeaveTeamDialog = false

    navbarClose: ->
      @provideApp.navbarClose()

    bannerShow: ->
      userData = @firebind '/users/'+ @$store.state.uid
      @showNavbarBanner = !userData.settings?.navbar_banner

    bannerClose: ->
      @$store.dispatch 'user/closeFridayBanner'
      @showNavbarBanner = false

    getBannerDaysLeft: ->
      end = new Date '12/31/2022'
      start = new Date()
      Math.ceil (end - start) / (1000 * 3600 * 24)

    showSearch: ->
      @searchDialog = true

    hideSearch: ->
      @searchDialog = false
      @search = ''


    hotkeyBlocker: (e) ->
      # allow undo/redo
      if e.ctrlKey && e.keyCode in [ 89, 90 ]
        return
      # block other hotkeys
      e.stopPropagation()
      # block ctrl +/-/0
      if e.ctrlKey && e.keyCode in [ 187, 189, 48, 107, 109, 45, 43, 96 ]
        e.preventDefault()

