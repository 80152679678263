

































import { date,getOwnerUID, userDisplayName } from '@/utils'
import WejePage from '@/weje/page/WejePage'
import Avatar from '@/components/Avatar.vue'
import ErrorOpen from '@/components/ErrorOpen.vue'
export default
  name: 'Site'
  props: ['data']
  components: { WejePage, Avatar, ErrorOpen }
  inject:
    provideCanvas: { default: null }
    provideSidebar: { default: null }
  methods:
    copySiteUrl: ->
      link = "#{window.location.origin}#{@siteUrl}"
      if window.clipboardData
        # should work in Internet Explorer
        window.clipboardData.setData 'Text', link
      else
        # all other browsers
        navigator.clipboard.writeText link
      # @provideCanvas.showCaution
      #   message: @$t('canvas.message.linkCopied')
      #   loader: false
  computed:
    boardId: ->
      w = @firebind @data.webjetPath
      for k, v of w?.connections?.parents
        board = @firebind v.src
        if board?.category == '/webjets/containers/webjets/canvas'
          return board.$path.split('/').pop()
      return ''
    webjetPath: ->
      @data?.webjetPath
    pages: ->
      @data.childs
    title: ->
      @data.data?.title
    siteUrl: ->
      "/w/#{@webjetPath.split('/').pop()}" if @webjetPath
    editSiteUrl: ->
      ''
    hasContent: ->
      @data.childs?.length > 0 and @data.childs[0].childs?.length > 0
    showDropzone: ->
      return false if @hasContent
      @provideCanvas?.userCanEdit and @provideSidebar
    author: ->
      @data?.meta?.createUser
    authorName: ->
      userDisplayName @author if @author
    initials: ->
      return '' unless @title
      result = ''
      words = @title.replace(/[^\p{L}\p{Z}^$\n]/gu, '').split(' ')
      result += words[0][0] if words[0]
      result += words[1][0] if words[1]
      result.toUpperCase()
    siteImg: ->
      @data?.data?.url
    created: ->
      date @data?.meta?.createDate
    userCanEdit: ->
      if @provideSidebar and @provideCanvas
        return @provideCanvas.userCanEdit
      else
        shared = null
        boardSrc = null
        w = @firebind @data?.webjetPath
        for k, v of w?.connections?.parents
          if v.src
            boardCategory = @firebind "#{v.src}/category"
            if boardCategory.$value == '/webjets/containers/webjets/canvas'
              shared = @firebind "#{v.src}/shared"
              boardSrc = v.src
        return false unless shared and boardSrc
        return true if getOwnerUID(boardSrc) == @$store.state.uid
        return true if shared?['*']?.includes 'w'
        return true if shared?[@$store.state.uid]?.includes 'w'
        false
