









































import { mapGetters } from 'vuex'
import { getOwnerUID } from '@/utils'
import NavigationContent from './navigation/NavigationContent'
import MentionContent from './mention/MentionContent'
import DateContent from './date/DateContent'
import WejesContent from './wejes/WejesContent'
import Navigation from './navigation/Navigation'
import Channel from './channel/Channel'
import Trash from './Trash'
import BoardConfigModal from '@/components/BoardConfig/BoardConfigModal'
import { anchorSortKey } from '@/utils'
export default
  name: 'SidebarContent'
  inject: ['provideCanvas', 'provideApp']
  components: {NavigationContent, Navigation, Channel, BoardConfigModal, WejesContent, Trash, MentionContent, DateContent}
  provide: ->
    provideSidebarContent: this
  data: ->
    mode: 'hide'
    configDialog: false





  computed: Object.assign {},

    mapGetters ['currentBoardSrc', 'currentBoardConnectionSrc', 'currentBoardId']
    title: ->
      @data?.title

    boardOwner: -> getOwnerUID(@currentBoardSrc)
    isReadOnlyBoard: ->
      return null unless @shared
      return false if @boardOwner == @$store.state.uid
      return false if @shared?['*']?.includes 'w'
      return false if @shared?[@$store.state.uid]?.includes 'w'
      true

    allWidth: ->
      @$vuetify.breakpoint.width

    x: ->
      if @mode == 'hide'
        return 0
      else if @mode == 'full'
        if @allWidth <= 500
          return @allWidth
        else
          return 340
    styles: ->
      return
        transform: "translateX(#{@x}px)"

    anchors: ->
      items = @provideCanvas.connections?.items
      return [] unless items
      items = Object.values items
      items = items.filter (c)=>
        w = @firebind(c.src)
        category = w.category if w
        if category == 'anchor'
          d = Date.parse(w.meta.createDate)
        category == 'anchor'
      itemsSort = items.sort (a, b)=>
        navSortKeyA = anchorSortKey(a)
        navSortKeyB = anchorSortKey(b)
        parseFloat(navSortKeyA) - parseFloat(navSortKeyB)
      items
    activeAnchors: ->
      @provideCanvas.anchors
    boardAccess: ->
      @$store.getters.boardAccess @currentBoardId
    isPublic: ->
      if FLAG.NEW_LOAD
        @boardAccess.public
      else
        for key, value of @shared
          if key == '*'
            return true
        return false

    navbarIsOpen: ->
      @provideApp.navbarIsOpen

    userCanEdit: ->
      @provideCanvas.userCanEdit

    showSites: ->
      @userCanEdit and @provideCanvas.hasWejeSites

  watch:
    mode: ->
      boardEl = document.querySelector '#board-page'
      if @mode == 'full'
        boardEl.classList.add 'sidebar-content-open'
      else
        boardEl.classList.remove 'sidebar-content-open'


  methods:
    openClose: ->
      if @mode == 'hide'
        @mode = 'full'
      else
        @mode = 'hide'
    navbarOpen: ->
      @provideApp.navbarOpen()
    openConfigDialog: ->
      @configDialog = true

  firebind:
    data: -> @currentBoardSrc and "#{@currentBoardSrc}/data"
    shared: -> @currentBoardSrc and "#{@currentBoardSrc}/shared"

