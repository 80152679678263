







import Logo from '@/components/Logo'
export default
  name: 'DeletedAccount'
  components: {Logo}

