

























import mixin from './mixin'
import Childs from './Childs'

export default
  name: 'WejeList'
  mixins: [ mixin ]
  components: { Childs }
  props: ['data']
  data: ->
    column: 0
    placeholderLeft: 0
    placeholderWidth: 0
    dataActiveColumn: null
  computed:
    activeColumn: ->
      active = @dataActiveColumn
      if (!active and active != 0) or @columns.indexOf(active)==-1
        @columns[0]
      else
        active
    activeColumnIndex: ->
      @columns.indexOf(@activeColumn)
    activeColumnLast: -> @activeColumnIndex == (@columns.length - 1)
    activeColumnFirst: -> @activeColumnIndex == 0
    columns: ->
      all = []
      for c in @childs
        all.push c.columnKey if c.columnKey
        all.push 0 unless c.columnKey
      result = [...new Set all]
      result = result.sort((a, b) => parseFloat(a) - parseFloat(b))
      result = [0] if result.length == 0
      result
  methods:
    slideTo: (i)->
      @dataActiveColumn = @columns[i]
    slideLeft: ()->
      @slideTo(@activeColumnIndex-1) unless @activeColumnFirst
    slideRight: ()->
      @slideTo(@activeColumnIndex+1) unless @activeColumnLast
  mounted: ->
    @dataActiveColumn = @data.activeColumn if @data.activeColumn
