export getWebjetStubForFile = (file) ->
  webjet =
    data:
      titleOrigin: file.name
    meta:
      uploadInProgress: true
      fileSize: file.size

  if file.type=='application/pdf'
    category = '/webjets/content/webjets/pdf'
  else if file.type?.indexOf('image/')==0
    category = '/webjets/content/webjets/photo'
  else if file.type?.indexOf('video/')==0
    category = '/webjets/content/webjets/html5video'
  else if file.type?.indexOf('audio/')==0
    category = '/webjets/content/webjets/audio'
  else if file.type?.indexOf('voice/')==0
    category = 'voice'
  else if ['obj', '3ds', 'stl', 'ply', 'gltf', 'glb', 'off', '3dm', 'fbx', 'dae', 'wrl', '3mf', 'stp', 'ifc'].includes file.name.split('.')[1]
    category = '/webjets/content/webjets/embed'
  else
    category = '/webjets/content/webjets/link'

  return { category, webjet }

export getWebjetDataForUpload = (file, fileRef, url) ->
  webjet =
    data:
      url: url
    meta:
      uploadInProgress: null
      isInStorage: true
      fileName: fileRef.name

  if file.type?.indexOf('image/')==0
    webjet.data.icon = url
  else if ['obj', '3ds', 'stl', 'ply', 'gltf', 'glb', 'off', '3dm', 'fbx', 'dae', 'wrl', '3mf', 'stp', 'ifc'].includes fileRef.name?.split('.')[1]
    webjet.data.url = "https://3dviewer.net/embed.html#model=#{url}"
    webjet.data.origin = "https://3dviewer.net/#model=#{url}"
    webjet.data.storage = url
    webjet.data.icon = "viewer"

  return webjet

export scaleImg = (file, maxWidth = 0, maxHeight = 0) ->
  return new Promise (resolve) ->
    img = new Image()
    img.src = URL.createObjectURL file
    img.onload = () ->
      width = img.width
      height = img.height
      maxWidthImg =  maxWidth || width
      maxHeightImg = maxHeight || height

      if width > maxWidthImg
        height = Math.round(height *= maxWidthImg / width)
        width = maxWidthImg
      if height > maxHeightImg
        width = Math.round(width *= maxHeightImg / height)
        height = maxHeightImg

      canvas = document.createElement 'canvas'
      canvas.width = width
      canvas.height = height
      ctx = canvas.getContext '2d'
      ctx.drawImage img, 0, 0, width, height
      dataURLImg = canvas.toDataURL()
      imgToFile = null
      fetch(dataURLImg).then (res) =>
        res.blob().then (blob) =>
          imgToFile = new File([blob], file.name, blob)
          resolve imgToFile
