

























































































































































import { mdiChevronDown, mdiCheck, mdiClose} from '@mdi/js'
import BoardCollaborationsUser from './BoardCollaborationsUser'
import { firebind, getOwnerUID, userLimits } from '@/utils'
import { sendAnalytics } from '@/utils'
import Avatar from '@/components/Avatar.vue'
import ModalUpgradePlan from '@/components/ModalUpgradePlan.vue'

export default
  name:
    "BoardCollaborations"
  props:
    src: String
  components:
    {
      BoardCollaborationsUser
      Avatar
      ModalUpgradePlan
    }
  provide: ->
    provideBoardCollaborations: this
  inject: ['provideApp']
  data: ->
    activeSelectUser: false
    showSelectUsers: false
    iconArrow: mdiChevronDown
    iconCheck: mdiCheck
    iconRemove: mdiClose
    mail: ''
    uidForInvite: ''
    inviteLoading: false
    errorEmail: false
    errorEmailText: ''
    canSelect: 'edit'
    preselectedPublicLicense: null
    preselectedInviteLinkLicense: null
    snapbar: false
    snapbarText: ''
    sendInviteLinkPopup: false
    upgradePlanDialog: false
    editLicences: [ 'edit', 'manage', 'owner' ]
    viewLicences: [ 'view', 'comment' ]
  methods:
    chekEmailOnInput: ->
      @mail = @mail.toLowerCase()
      @showSelectUsers = true
      @activeSelectUser = false
      @sendInviteLinkPopup = false
      @errorEmail = false
      @errorEmailText = ''
      if @mail.match /^\S+@\S+[\.][0-9a-z ]+$/g
        uid = (await firebind "/emails/#{@mail.replace(/\./g, ',')}/uid")?.$value
        console.log 'uid', uid
        if uid
          @uidForInvite = uid
        else
          @uidForInvite = ''
      else
        @uidForInvite = ''
    changeLicense: (license) ->
      @canSelect = license
    changePublicLicense: (license) ->
      #console.log '!?', @publicLicense, license
      @publicLicense = license
    changeInviteLinkLicense: (license) ->
      #console.log '!?inviteLink', @inviteLinkLicense, license
      @inviteLinkLicense = license
    showInviteUi: ->
      @hasInviteLink = true
    hideInviteUi: ->
      @hasInviteLink = false
    invite: ->
      return unless @mail
      if not @mail.match /^\S+@\S+[\.][0-9a-z ]+$/g
        @errorEmail = true
        return

      @uidForInvite = (await firebind "/emails/#{@mail.replace(/\./g, ',')}/uid")?.$value unless @uidForInvite
      console.log @uidForInvite, @chekUidInvite
      if @uidForInvite and @chekUidInvite
        if @limitIsOver and @canSelect == 'edit'
          @provideApp.dialogMaxLimitBoardId = @src
          @provideApp.dialogMaxLimitBoardUserId = @uidForInvite
          @provideApp.dialogMaxLimitBoard = true
          sendAnalytics 'editors_limit_popup'
          return
        @inviteLoading = true
        # console.log 'INVITE', @connection?.src,@uidForInvite,@canSelect
        await @$store.dispatch 'webjet/shareBoard',
          src: @connection?.src
          uid: @uidForInvite
          mode: @canSelect
        sendAnalytics 'user_invited_to_board',
          invited_user_id : @uidForInvite.substring(0, 15)
          access_type: @canSelect
        @mail = ''
        @uidForInvite = ''
        @inviteLoading = false
      else if !@uidForInvite
        @sendInviteLinkPopup = true
        @errorEmail = true
        @errorEmailText = @$t('board.share.errorNotFound')
      else
        @errorEmail = true
        @errorEmailText = @$t('board.share.errorAlreadyHasAccess')
    setPublic: (state, mode) ->
      if state
        await @$store.dispatch 'webjet/shareBoard',
          src: @connection?.src
          uid: '*'
          mode: mode
      else
        await @$store.dispatch 'webjet/shareBoard',
          src: @connection?.src
          uid: '*'
          mode: false
    copyUrl: (url) ->
      if window.clipboardData
        # should work in Internet Explorer
        window.clipboardData.setData 'Text', url
      else
        # all other browsers
        navigator.clipboard.writeText url
      @snapbar = true
      @snapbarText = @$t('board.share.linkCopied')

    copyIframeCode: () ->
      link = "#{window.location.origin}/b/#{@$route.params.boardId}"
      iframeString = "<iframe src='#{link}' height='450' width='800' sandbox='allow-scripts allow-popups allow-forms allow-same-origin'></iframe>"

      if window.clipboardData
        # should work in Internet Explorer
        window.clipboardData.setData 'Iframe String', iframeString
      else
        # all other browsers
        navigator.clipboard.writeText iframeString

      @snapbar = true
      @snapbarText = @$t('board.share.linkCopied')

    sendInviteLink: ->
      if !@hasInviteLink
        await @$store.dispatch 'webjet/createInviteLink',
          path: @connection?.src
          mode: @canSelect
      else if @canSelect != @board?.meta?.inviteLink?.mode
        await @$store.dispatch 'webjet/updateInviteLink',
          path: @connection?.src
          mode: @canSelect
      await @$store.dispatch 'webjet/sendInviteLink',
        path: @connection?.src
        email: @mail
      @sendInviteLinkPopup = false
      @errorEmail = false
      @snapbar = true
      @snapbarText = @$t('board.share.linkSent') + @mail
      @mail = ''

    selectUser: (e,email) ->
      @mail = email
      @showSelectUsers = false
      e.preventDefault()
      e.stopPropagation()
    clickMailInput: ->
      @showSelectUsers = true
      @activeSelectUser = false
    downArrow: ->
      if @activeSelectUser == false
        @activeSelectUser = 0
      else if @activeSelectUser < @filteredCollaborators.length - 1
        @activeSelectUser += 1
    upArrow: ->
      if @activeSelectUser != 0
        @activeSelectUser -= 1
    enterInMail: ->
      if @activeSelectUser != false
        @mail = @filteredCollaborators[@activeSelectUser].email
        @showSelectUsers = false
        @activeSelectUser = false
      else
        @showSelectUsers = false
        @invite()
    fbClick: ->
      window.open "https://www.facebook.com/sharer/sharer.php?u=#{@publicUrl}",'popup','width=600,height=600'
  computed:
    boardCollaborators: ->
      @$store.getters.boardCollaborators @src
    groupAdmins: ->
      result = {}
      for k, v of @boardCollaborators
        if v.role in ['admin', 'owner']
          result[k] = 'manage'
      result
    groupProject: ->
      result = {}
      for k, v of @boardCollaborators
        if v.role and v.project and !@groupAdmins[k]
          result[k] = v.project
      result
    groupDirect: ->
      result = {}
      for k, v of @boardCollaborators
        if v.role and v.board
          result[k] = false
      result
    groupGuest: ->
      result = {}
      for k, v of @boardCollaborators
        if k!='*' and !v.role
          result[k] = false
      result
    teamId: ->
      teamId = @firebind "/boards/#{@boardId}/team"
      teamId.$value
    roles: ->
      @firebind "/teamAccess/#{@teamId}/roles"
    boardsUsers: ->
      @firebind "/teamAccess/#{@teamId}/boards"
    guests: ->
      result = {}
      for k,v of @boardsUsers
        if k != '*'
          result[k] = v
      # console.log 'guests', result
      return result
    guestWithEdit:->
      members = []
      for k,v of @boardsUsers
        if v and k != '*'
          for b,r of v
            if r == 'edit'
              members.push k
              break
      members
    membersInTeam: ->
      memebers = Object.keys @roles
      memebers
    allmembersInTeam: ->
      @membersInTeam.length + @guestWithEdit.length
    teamSize: ->
      teamSizeData = @firebind("/teamBilling/#{@teamId}/teamSize")
      teamSize = teamSizeData?.$value
      return 3 if teamSize == null
      teamSize
    teamNoLimits: ->
      val = @firebind("/teamBilling/#{@teamId}/nolimits")
      val.$value
    limitIsOver: ->
      #if !@teamNoLimits and @allmembersInTeam >= @teamSize
      #  return true
      false
    plan: ->
      plan = @firebind "/teamBilling/#{@teamId}/plan"
      if plan.$value == null
        return 'free'
      else
        plan.$value
    collaborators: ->
      collaborators = @$store.getters.collaborators
      myUid = @$store.state.uid
      filteredShared = @filteredShared
      boardCollaborators = @boardCollaborators
      collaboratorsWithoutMe = collaborators.filter (c) ->
        return false if !c.email and !c.fname and !c.lname
        for key, value of filteredShared
          if key == c.uid and boardCollaborators[key] and boardCollaborators[key]?.board
            return false
        return true if c.uid != myUid
      collaboratorsWithoutMe
    filteredCollaborators: ->
      collaborators = @collaborators
      text = @mail
      re = new RegExp text, 'i'
      filteredCollaborators = collaborators.filter (c) ->
        return true if c.email and re.test c.email
        return true if c.fname and re.test c.fname
        return true if c.lname and re.test c.lname

      if text.length < 1
        return collaborators
      filteredCollaborators
    can: ->
      [
        {value: "view", text: @$t('board.share.canView')},
        {value: "edit", text: @$t('board.share.canEdit')}
      ]
    boardAccess: ->
      @$store.getters.boardAccess @src
    boardLicense: ->
      if FLAG.NEW_LOAD
        @boardAccess.access
      else
        if @myBoard
          return 'owner'
        else if @shared?[@$store.state.uid]
          return license = @shared?[@$store.state.uid]
        else
          return license
    isViewBoardLicense: ->
      @boardLicense in @viewLicences
    isEditBoardLicense: ->
      @boardLicense in @editLicences
    myBoard: ->
      @boardOwner == @$store.state.uid
    boardOwner: ->
      getOwnerUID @connection?.src
    titleLicense: ->
      title = @can.find (c) =>
        c.value == @canSelect
      return title?.text
    chekUidInvite: ->
      if FLAG.NEW_LOAD
        if @uidForInvite and
           @uidForInvite != @boardOwner and
           @uidForInvite != @$store.state.uid and
           @boardAccess.access == 'manage'
          return true
        else
          return false
      else
        if @uidForInvite and
           @uidForInvite != @boardOwner and
           @uidForInvite != @$store.state.uid and
           !Object.keys(@shared).includes @uidForInvite
          return true
        else
          return false
    filteredShared: ->
      if @shared
        items = {}
        for k,v of @shared
          if k != '*'
            items[k] = v
        return items

    boardEditorsCount: ->
      count = 0
      if @shared
        for k,v of @shared
          if k != '*' and v in @editLicences
            count++
      return count
    boardEditorsLimit: ->
      userLimits(@boardOwner)?.boardEditors

    isPublic:
      set: (v) ->
        @setPublic v, @publicLicense
      get: ->
        for key, value of @shared
          if key == '*'
            return true
        return false
    publicLicense:
      set: (v) ->
        if @isPublic
          @setPublic true, v
          @preselectedPublicLicense = null
        else
          @preselectedPublicLicense = v
      get: ->
        for key, value of @shared
          if key == '*'
            return value
        return @preselectedPublicLicense||'r'
    publicLicenseTitle: ->
      title = @can.find (c) =>
        c.value == @publicLicense
      return title?.text
    publicUrl: ->
      pathParts = @board?.$path.split('/')
      if pathParts
        prefix = pathParts[1]
        pathParts = pathParts.reverse()
        boardId = pathParts[0]
        if prefix == 'boards'
          return "#{window.origin}/b/#{boardId}"
        else
          return "#{window.origin}/b/#{boardId}/#{encodeURIComponent(@boardOwner)}"

    hasInviteLink:
      set: (v) ->
        if v
          @$store.dispatch 'webjet/createInviteLink',
            path: @connection?.src
            mode: @inviteLinkLicense
        else
          @$store.dispatch 'webjet/removeInviteLink',
            path: @connection?.src
            mode: @inviteLinkLicense
      get: ->
        @board?.meta?.inviteLink
    inviteLinkLicense:
      set: (v) ->
        if @hasInviteLink
          @$store.dispatch 'webjet/updateInviteLink',
            path: @connection?.src
            mode: v
          @preselectedInviteLinkLicense = null
        else
          @preselectedInviteLinkLicense = v
      get: ->
        if @board?.meta?.inviteLink?.mode == 'rw'
          'edit'
        else if @board?.meta?.inviteLink?.mode == 'r'
          'view'
        else
          @board?.meta?.inviteLink?.mode || @preselectedInviteLinkLicense || 'view'
    inviteLinkLicenseTitle: ->
      title = @can.find (c) =>
        c.value == @inviteLinkLicense
      return title?.text
    inviteLinkUrl: ->
      return "#{window.origin}?invitecode=#{@board.meta.inviteLink.code}" if @board?.meta?.inviteLink

    isRegisteredUser: ->
      @$store.state.isRegisteredUser
    isAnonymousUser: ->
      @$store.state.isAnonymousUser

    boardId: ->
      @connection?.src?.split('/').pop()

    shared: ->
      return unless @teamAccess?.$ready
      u = {}
      for uid, mode of @legacyShared when uid!=@boardOwner
        u[uid] = if mode=='rw' then 'edit' else 'view'
      for uid, boards of @teamAccess.boards when uid!=@boardOwner
        if boards[@boardId]?
          u[uid] = boards[@boardId]
      for uid, role of @teamAccess.roles when uid!=@boardOwner
        u[uid] = role
      u

    connection: ->
      if @src.includes('/')
        @firebind @src
      else
        { src: "/boards/#{@src}" }

  firebind:
    #connection: -> @src
    board: -> @connection?.src
    legacyShared: ->
      @connection?.src and "#{@connection.src}/shared"
    teamAccess: ->
      @board?.team? and "/teamAccess/#{@board.team}"
