








import ContextBtn from './ContextBtn'
export default
  name: 'GraffitiPen'
  components: { ContextBtn }
  inject: { provideCanvas: { default: null } }
  priority: 10000

  if: (selection)->
    return false unless FLAG.GRAFFITI_TOOLS_TO_CONTEXT_MENU
    selection.length == 1 and selection[0].$options.name == 'WebjetGraffiti' and selection[0].$refs.editor?.editMode

  computed:
    selection: -> @$store.getters['webjet/selected']
    title: ->
      'Pen'
    isActive: ->
      @selection[0].$refs.editor.graffitiData.graffitiType == 'pen'


  methods:
    action: ->
      @selection[0].$refs.editor.graffitiData.graffitiType = 'pen'
