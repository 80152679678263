














































import { date, getOwnerUID, userDisplayName } from '@/utils'
import { mapGetters,mapState } from 'vuex'
import Avatar from '@/components/Avatar.vue'
import router from '@/router'

export default
  name: 'BoardPreview'
  props: ['src']
  inject: ['provideApp','provideNavbarSpaces']
  components: {Avatar}
  computed: Object.assign {},
    mapGetters ['boardsReady']
    mapState 'socketBoards', ['boards']
    classes: ->
      return
        [@currentTheme] : @currentTheme
        'active-rounded': FLAG.NAVBAR_SELECTED_ROUNDED
        'active': @thisBoardOpen
        'with-settings': FLAG.BOARD_PREVIEW_SETTINGS
    boardInTrash: ->
      @boardDeleted?.$value
    title: ->
      @boardData?.title
    myBoard: ->
      @updateUser == @$store.state.uid
    updateUser: ->
      @boardMeta?.updateUser
    updateName: ->
      if @myBoard
        return @$t('common.me')
      else
        userDisplayName @updateUser
    url: ->
      @boardData?.url
    to: ->
      { name: 'Board', params: { boardId: @connection.src.split('/').pop()} }
    thisBoardOpen: ->
      @$route.params.boardId == @connection.src.split('/').pop()
    starred: ->
      if FLAG.NEW_LOAD
        @userPins?[@src]
      else
        @connection?.starred
    boardDataLoaded: ->
      @boardData?.$ready and @boardMeta?.$ready
    isFresh: ->
      return false unless FLAG.FRESH
      !@firebind("freshDate")?[@src] or @firebind("freshCount")[@src]

    freshCount: ->
      @firebind("freshCount")[@src] or ''

    modified: ->
      date @boardMeta?.updateDate
    boardAccess: ->
      @$store.getters.boardAccess @src
    isPublic: ->
      if FLAG.NEW_LOAD
        @boardAccess.public
      else
        for key, value of @shared
          if key == '*'
            return true
        return false
    initials: ->
      return '' unless @title
      result = ''
      words = @title.replace(/[^\p{L}\p{Z}^$\n]/gu, '').split(' ')
      result += words[0][0] if words[0]
      result += words[1][0] if words[1]
      result.toUpperCase()
    defaultTheme: -> 'theme-board-default'
    currentTheme: ->
      @boardData?.boardTheme or @defaultTheme
    boardDataLoaded: ->
      @boardData?.$ready and @boardMeta?.$ready

    usersOnBoard: ->
      if @boardDataLoaded
        boardId = @connection.src.split('/').pop()
        return @boards[boardId]

    usersOnline: ->
      @usersOnBoard?.size > 0

    userMoreCount: ->
      if @usersOnBoard?.size <= 4
        return 0
      else
        return @usersOnBoard?.size - 4

    search: ->
      @provideNavbarSpaces?.search

    showBySearch: ->
      return true unless @search
      re = new RegExp @search, 'i'
      return true if re.test @title

    titleHighlighted: ->
      re = new RegExp @search, 'gi'
      if @title
        str = @title
        title = str.replace re, (m) =>
          return "<mark>#{m}</mark>"
        return title
      @title

    connection: ->
      if @src.includes('/')
        @firebind @src
      else
        { src: "/boards/#{@src}" }

    plan: ->
      return 'legacy' if @nolimits
      plan = @firebind "/teamBilling/#{@teamId}/plan"
      if plan.$value == null
        return 'free'
      else
        plan.$value
    teamId: ->
      @context.teamId
    nolimits: ->
      val = @firebind("/teamBilling/#{@teamId}/nolimits")
      val.$value
    boardLimit: ->
      #return 3 if @plan == 'free'
      return false
    boardCount: ->
      @$store.getters.allTeamBoards(@teamId).length


  methods:
    restoreBoard: ->
      if @boardLimit and @boardCount >= @boardLimit
        @provideApp.dialogMaxLimitTeamId = @teamId
        @provideApp.dialogMaxLimitTeamBoards = true
        return
      boardId = @connection.src.split('/').pop()
      @$store.dispatch 'webjet/restoreMyBoard', { path: @connection.src }
      router.push { name: 'Board' , params: {boardId: boardId} }
      if @provideNavbarSpaces
        @provideNavbarSpaces.showTrashBoards = false
    openSettings: ->
      @provideNavbarSpaces.boardConfigSrc = @src
      @provideNavbarSpaces.boardConfig = true
    open: ->
      @$router.push @to

    clickOnMiddle: ->
      route = @$router.resolve { name: 'Board' , params: { boardId: @connection.src.split('/').pop()}}
      window.open(route.href, '_blank')

    clickOnBoardRow: ->
      @provideApp.navbarClose() if @$vuetify.breakpoint.width < 500
      unless @thisBoardOpen
        @open()


    toggleStarred: ->
      if FLAG.NEW_LOAD
        @$store.dispatch 'webjet/setBoardPin',
          boardId: @src
          value: !@starred
      else
        @$store.dispatch 'webjet/setStarred',
          path: @connection.$path
          value: !@starred

  firebind:
    boardData: ->
      @connection?.src and "#{@connection.src}/data"
    boardMeta: ->
      @connection?.src and "#{@connection.src}/meta"
    shared: ->
      @connection?.src and "#{@connection.src}/shared"
    boardTeam: ->
      @connection?.src and "#{@connection.src}/team"
    userPins: ->
      @boardTeam?.$ready and "teams/#{@boardTeam.$value}/pins"
    boardDeleted: ->
      @connection?.src and "#{@connection.src}/boardDeleted"
