import Vue from 'vue'
import store from '@/store'

class UserPlan
  constructor: ({delay = 1})->
    @state = Vue.observable
      plan: false
      userData: {}
      plans:
        free:
          title: 'free'
          limitBoards: 6
          limitCollaborations: 1
          price: 0
        edu:
          title: 'edu'
          limitBoards: 15
          limitCollaborations: 10
          price: 5
        pro:
          title: 'pro'
          limitBoards: 15
          limitCollaborations: 10
          price: 15
  setPlan: (plan)->
    return if @state.plan == plan
    @state.plan = plan
  setUserData: (data)->
    if data.value
      Vue.set @state.userData, data.name, data.value
    else
      Vue.delete @state.userData, data.name
  getPlan: ()->
    @state.plan
  getPlanData: ()->
    plan = @state.plan
    @state.plans[plan]
  getUserData: ()->
    @state.userData
  canAddCollaboration: ()->
    udc = @state.userData['collaborations']
    limit = @state.plans[@state.plan]?.limitCollaborations
    if udc? and limit?
      return @output(true) if udc.size < limit
      return @output(false)
    else
      return @output(false)
  canAddBoard: ()->
    udbc = @state.userData['boardsCount']
    limit = @state.plans[@state.plan]?.limitBoards
    if udbc? and limit?
      return @output(true) if udbc < limit
      return @output(false)
    else
      return @output(false)
  output: (status, msg = '')->
    return {status: status, msg: msg}

export default UserPlan
export UserPlan = UserPlan