





export default
  name: 'MinimapWebjets'
  inject: ['provideCanvas']
  computed:
    webjets: -> @provideCanvas.webjets
    collaborateDrawMode: -> @provideCanvas.collaborateDrawMode
    attach: -> @provideCanvas.showMinimap
