


























import ConnectedStatus from '@/components/ConnectedStatus'
import Auth from '@/views/Auth'
import DragLayer from '@/app/DragLayer'
import FileSizeLimitDialog from '@/app/FileSizeLimitDialog'
import DialogMaxLimitTeam from '@/app/DialogMaxLimitTeam'
import DialogMaxLimitTeamBoards from '@/app/DialogMaxLimitTeamBoards'
import DialogMaxLimitBoard from '@/app/DialogMaxLimitBoard'
import DialogProjectSettings from '@/app/DialogProjectSettings'
import DialogCreateProject from '@/app/DialogCreateProject'
import DialogMaxLimitProject from '@/app/DialogMaxLimitProject'
import DialogSite from '@/app/DialogSite'
import Navbar from '@/app/Navbar'
import BottomNav from '@/app/BottomNav'
import { mapState } from 'vuex'
import { userLimits, isIOS, isHoverableDevice } from '@/utils'
import { sendAnalytics } from '@/utils'
import { generateSort } from '@/utils'
import { isIframe } from '@/utils'

export default
  name: 'App'
  components: { Auth, ConnectedStatus, DragLayer, FileSizeLimitDialog,
    DialogSite, Navbar, DialogMaxLimitTeam, DialogMaxLimitBoard, BottomNav,
    DialogMaxLimitTeamBoards, DialogProjectSettings, DialogCreateProject, DialogMaxLimitProject
  }
  provide: ->
    provideApp: this
  data: ->
    dialogMaxLimitTeam: false
    dialogMaxLimitTeamBoards: false
    dialogMaxLimitTeamId: null
    dialogMaxLimitBoard: false
    dialogMaxLimitBoardUserId: null
    dialogMaxLimitBoardId: null
    dialogProjectSettingsId: null
    dialogCreateProjectTeamId: null
    dialogMaxLimitProjectTeamId: false
    fileSizeDialog: false
    fileSizeDialogInventory: false
    fileSizeDialogTeamId: null
    scrollElements: new Map
    open: false
    darkThemeReactiveSet: null
  created: ->
    if FLAG.GOOGLE_DOCUMENTS_SCROLL_BUG
      document.addEventListener 'scroll', @scroll, true
  beforeDestroy: ->
    document.removeEventListener 'scroll', @scroll, true
  computed: Object.assign {},
    mapState ['needAuth', 'showAuth']
    mainLayoutClasses: ->
      return
        'navbar-open' : @navbarIsOpen
        'boards-open' : @$store.state.route.name == 'Board' or @$store.state.route.name == 'Team' or @$store.state.route.name == 'Home'
    keymap: ->
      'ctrl+z': @undo
      'ctrl+y': @redo
      'ctrl+shift+z': @redo
      'delete': @hotkeyTrash
      'backspace': @hotkeyTrash
      'esc': @hotkeyEsc
      'shift+enter': @hotkeyShiftEnter
      'down': @hotkeyDown
      'up': @hotkeyUp
      'enter': @hotkeyEnter
    detectIOS: -> isIOS
    routerPath: ->
      return @$route.path
    routeHash: -> @$store.state.route.hash
    showDialogSite: -> FLAG.DIALOG_SITE and @routeHash and @routeHash.includes('s=')
    dialogSiteId: -> @routeHash.split('#')[1].split('s=')[1]

    navbarIsOpen: ->
      return false if @$store.state.route.name == 'WejeSite'
      if @$store.state.route.name == 'Board'
        @open
      else
        true
    smallScreen: ->
      @$vuetify.breakpoint.width < 500

    isInIframe: ->
      return FLAG.WEJE_IFRAME_NEW and isIframe

    themeDark:
      get: ->
        @darkThemeReactiveSet
        localStorage['darkThemeEnabled'] == 'true'
      set: (val)->
        @darkThemeReactiveSet = val
        if val
          localStorage['darkThemeEnabled'] = 'true'
        else
          localStorage.removeItem 'darkThemeEnabled'

    isHoverableDevice: ->
      isHoverableDevice

  methods:
    goToWebjetInStack: (prev = false, activateEditor = false) ->
      if @$store.getters['webjet/selected']?.length == 1
        webjetComponent = @$store.getters['webjet/selected'][0]
        provideWebjet = webjetComponent.provideWebjet
        webejtSortKey = null
        item = null
        sortKey = null
        components = []
        if provideWebjet
          provideWebjetIsInventory = provideWebjet?.$options.name == 'WebjetInventory'
          provideWebjetIsList = provideWebjet?.$options.name == 'WebjetList'
        if !provideWebjet or provideWebjetIsInventory or provideWebjetIsList
          return if prev
          items = webjetComponent?.webjet?.connections?.items
          components = webjetComponent.childWebjetComponents
          for k,v of items
            if sortKey == null or sortKey > v.sortKey
              sortKey = v.sortKey
              item = k
        else if provideWebjet
          components = provideWebjet.childWebjetComponents
          webejtSortKey = webjetComponent?.connection?.sortKey
          items = provideWebjet?.webjet?.connections?.items
          if !prev
            for k,v of items
              if (sortKey == null and v.sortKey != webejtSortKey and v.sortKey > webejtSortKey) or (v.sortKey > webejtSortKey and v.sortKey < sortKey)
                sortKey = v.sortKey
                item = k
          else
            for k,v of items
              if (sortKey == null and v.sortKey != webejtSortKey and v.sortKey < webejtSortKey)  or (webejtSortKey > v.sortKey and sortKey < v.sortKey)
                sortKey = v.sortKey
                item = k
            item = provideWebjet.id if item == null
        if item
          @selectWebjet item, activateEditor, !prev
    hotkeyEnter: ->
      if @$store.getters['webjet/selected']?.length == 1
        webjetComponent = @$store.getters['webjet/selected'][0]
        setTimeout =>
          element = document.querySelector "##{webjetComponent.id}"
          webjet = element.webjet
          if webjet.$options.name=='WebjetCard'
            webjet.$refs?.editor?.activateEditor()
          if webjet.$options.name=='WebjetGraffiti'
            webjet.$refs?.editor?.activateEditor()
          if webjet.$options.name=='WebjetDate'
            webjet.editmode = true
            webjet.$refs?.picker?.focus()
          if webjet.$options.name=='WebjetComments'
            webjet.active = true
    hotkeyDown: ->
      @goToWebjetInStack()
    hotkeyUp: ->
      @goToWebjetInStack(true)
    selectWebjet: (id, activateEditor = false, editorSelectionToFirst = false) ->
      setTimeout =>
        element = document.querySelector "##{id}"
        webjet = element.webjet
        await @$store.dispatch 'webjet/setNowselected', { webjets: [webjet] }
        await @$store.dispatch 'webjet/setPreselected', { webjets: [] }
        await @$store.dispatch 'webjet/relation/setSelected', { webjet: null }
        if activateEditor
          if webjet.$options.name=='WebjetCard'
            webjet.$refs?.editor?.activateEditor()
            # editor caret to start on focus
            if editorSelectionToFirst
              webjet.$refs?.editor?.caretToStart = true
          if webjet.$options.name=='WebjetGraffiti'
            webjet.$refs?.editor?.activateEditor()
    selectCreatedWebjet: (path)->
      setTimeout =>
        element = document.querySelector "##{path.split('/').pop()}"
        webjet = element.webjet
        await @$store.dispatch 'webjet/setNowselected', { webjets: [webjet] }
        await @$store.dispatch 'webjet/setPreselected', { webjets: [] }
        await @$store.dispatch 'webjet/relation/setSelected', { webjet: null }
        if webjet.$options.name=='WebjetCard'
          webjet.$refs?.editor?.activateEditor()
        if webjet.$options.name=='WebjetGraffiti'
          webjet.$refs?.editor?.activateEditor()
    hotkeyShiftEnter: (e)->
      if @$store.getters['webjet/selected']?.length == 1
        webjetComponent = @$store.getters['webjet/selected'][0]
        return unless FLAG.WEBJET_PARENT
        hasCanvas = webjetComponent?.provideCanvas
        inInventory = webjetComponent?.provideBoardSrc == 'inventory'
        return if !hasCanvas and !inInventory
        if hasCanvas
          return if hasCanvas.presentationIsActive
          return if hasCanvas.isReadOnly
        provideWebjet = webjetComponent.provideWebjet
        boardPath = if inInventory then 'inventory' else hasCanvas.data?.$path
        if provideWebjet
          provideWebjetIsInventory = provideWebjet?.$options.name == 'WebjetInventory'
        if webjetComponent.webjet.category == '/webjets/containers/webjets/list'
          connections = []
          group = 'items'
          if webjetComponent.webjet.connections? and webjetComponent.webjet.connections[group]
            connections.push(c) for k, c of webjetComponent.webjet.connections[group]
          columnKey = null
          for c in connections
            connectionColumnKey = 0
            connectionColumnKey = c.columnKey if c.columnKey
            if columnKey == null or connectionColumnKey < columnKey
              columnKey = connectionColumnKey
          sort = generateSort(webjetComponent.webjet, false)
          connection = Object.assign {}, sort
          connection.columnKey = columnKey if columnKey
          result = await @$store.dispatch 'webjet/create',
            category: '/webjets/content/webjets/note'
            dest: webjetComponent.webjet.$path
            boardPath: boardPath
            connection: connection
          @selectCreatedWebjet result.connectionPath

        else

          if !provideWebjet or provideWebjet.webjet.category == '/webjets/containers/webjets/list' or provideWebjetIsInventory
            before = false
            if webjetComponent?.webjet?.category == '/webjets/content/webjets/note'
              before = true if e.eventByHotKey
            sort = generateSort(webjetComponent.webjet, before)
            connection = Object.assign {}, sort
            result = await @$store.dispatch 'webjet/create',
              category: '/webjets/content/webjets/note'
              dest: webjetComponent.webjet.$path
              boardPath: boardPath
              connection: connection
            @selectCreatedWebjet result.connectionPath

          else if provideWebjet.webjet.category != '/webjets/containers/webjets/list'
            connections = []
            group = 'items'
            if provideWebjet.webjet.connections? and provideWebjet.webjet.connections[group]
              connections.push(c) for k, c of provideWebjet.webjet.connections[group]
            after = webjetComponent?.connection?.sortKey
            before = false
            for c in connections
              if Number.isFinite(c.sortKey) and c.sortKey > after
                if !before or c.sortKey < before
                  before = c.sortKey
            sort = generateSort(provideWebjet.webjet, before)
            connection = Object.assign {}, sort
            result = await @$store.dispatch 'webjet/create',
              category: '/webjets/content/webjets/note'
              dest: provideWebjet.webjet.$path
              boardPath: boardPath
              connection: connection
            @selectCreatedWebjet result.connectionPath

    hotkeyEsc: ->
      selection = @$store.getters['webjet/selected']
      if selection?.length > 0
        if selection?.length == 1
          webjetComponent = selection[0]
          if webjetComponent?.webjet?.category == '/webjets/content/webjets/graffiti' and webjetComponent?.$refs?.editor?.editMode
            webjetComponent.$refs.editor.deactivateEditor()
            return
          else if webjetComponent.provideWebjet
            provideWebjet = webjetComponent.provideWebjet
            provideWebjetIsInventory = provideWebjet?.$options.name == 'WebjetInventory'
            provideWebjetIsList = provideWebjet?.$options.name == 'WebjetList'
            if !provideWebjetIsInventory and !provideWebjetIsList
              setTimeout =>
                @$store.dispatch 'webjet/setNowselected', { webjets: [provideWebjet] }
                @$store.dispatch 'webjet/setPreselected', { webjets: [] }
                @$store.dispatch 'webjet/relation/setSelected', { webjet: null }
        @$store.dispatch 'webjet/resetSelection'
    hotkeyTrash: ->
      # only for dashboard (without canvas)
      selection = @$store.getters['webjet/selected']
      if selection.length and !selection[0].provideCanvas and !selection[0].isReadOnly
        items = selection.map (w)-> { path: w.src }
        @$store.dispatch 'webjet/trash', { items }
    undo: ->
      @$store.dispatch 'undoredo/undo'
    redo: ->
      @$store.dispatch 'undoredo/redo'
    scroll: (e) ->
      target = e.target
      scrollY = 0
      if target == document
        scrollY = window.scrollY
      else
        scrollY = target.scrollTop
      oldScrollY = @scrollElements.get target
      if scrollY == 0 and oldScrollY > 10
        if target == document
          window.scroll({top:oldScrollY})
        else
          target.scroll({top:oldScrollY})
      else
        @scrollElements.set target, scrollY

    checkFileSizeLimits: (files, boardPath) ->
      if boardPath == null
        console.error 'no boardPath in checkFileSizeLimits'
        return false

      if boardPath == 'inventory'
        @fileSizeDialogInventory = true
        @fileSizeDialogTeamId = null
        limit = 10
      else
        team = await @firebind("#{boardPath}/team")?.$value
        if !team
          console.error 'no team by boardPath', boardPath
          return false

        teamBilling = await @firebind "/teamBilling/#{team}"

        #console.log '?', team, teamBilling

        return true if teamBilling?.nolimits
        return true if teamBilling?.plan? and teamBilling?.plan != 'free'

        @fileSizeDialogInventory = false
        @fileSizeDialogTeamId = team
        limit = 10

      #limit = userLimits(@$store.state.uid)?.fileSize
      #console.log '!', limit, files
      return true unless limit
      limit = limit*1024*1024
      for file in files
        #console.log '?', file.type, file.size, limit
        if file.type? and not file.type.startsWith('image/') and file.size >= limit
          @fileSizeDialog = true
          sendAnalytics 'size_limit_popup'
          return false
      return true

    navbarClose: ->
      @open = false

    navbarOpen: ->
      @open = true

    toggleTheme: ->
      if @themeDark == true
        @themeDark = false
      else
        @themeDark = true

  watch:
    routerPath: (val, oldVal) ->
      if oldVal != val
        @scrollElements = new Map
