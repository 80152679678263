

































import i18n from '@/plugins/i18n'
export default
  name: "DialogTemplate"
  props:
    value: Boolean
    dialogClass: String
    hideBottom: Boolean
    title: String
    desc: String
    textConfirm: String
    textCancel: String
  computed:
    hasConfirmListener: ->
      this.$listeners?.confirm
    show:
      get: ->
        @value
      set: ->
        @$emit 'input', null
    contentClass: ->
      if @dialogClass
        @dialogClass  + ' dialog-template'
      else
        'dialog-template'
    transition: ->
      if @$vuetify.breakpoint.width < 500
        'dialog-bottom-transition'
      else
        'fade-transition'
  methods:
    confirm: ->
      @$emit 'confirm'
