import { wsBoard } from '@/websockets'
import { firebind } from '@/utils'
import Vue from 'vue'

filterSelected = (webjets)->
  webjets = [...new Set webjets]
  webjets = webjets.filter (w)-> !w.isDestroyed
  result = []
  for w in webjets
    result = result.filter (r)->
      !r.parentWebjets.includes(w) and !w.parentWebjets.includes(r)
    if result.length > 0 and result[0].provideBoardSrc != w.provideBoardSrc
      result = []
    if result.length > 0 and result[0].webjet?.category == 'anchor' and result[0].webjet?.type == 'iframe'
      result = result.slice 1
    result.push w
  result

export default
  state:
    nowselected: []
    preselected: []
    selectedBySrc: {}
    selectedWebjetWithChildsBySrc: {}
    oneWebjetSelected: false
  getters:
    isOneWebjetSelected: (state)-> state.oneWebjetSelected

    isSelected: (state)->
      state.nrSelectedBySrc = state.selectedBySrc unless state.nrSelectedBySrc
      nrSelectedBySrc = state.nrSelectedBySrc
      return (webjet)->
        src = webjet.src
        Vue.set(nrSelectedBySrc, src, false) if nrSelectedBySrc[src] == undefined
        nrSelectedBySrc[src]
    isSelectedWithChild: (state) ->
      state.nrSelectedWebjetWithChildsBySrc = state.selectedWebjetWithChildsBySrc unless state.nrSelectedWebjetWithChildsBySrc
      nrSelectedWebjetWithChildsBySrc = state.nrSelectedWebjetWithChildsBySrc
      return (webjet) ->
        src = webjet.src
        Vue.set(nrSelectedWebjetWithChildsBySrc, src, false) if nrSelectedWebjetWithChildsBySrc[src] == undefined
        nrSelectedWebjetWithChildsBySrc[src]


    selected: ({nowselected, preselected})->
      result = [...nowselected, ...preselected]
      #result = [...new Set result]
      #result = result.filter (w)-> !w.isDestroyed
      result = filterSelected result
      result

    wsSelected: ()->
      wss = wsBoard.get('selection')
      return {} unless wss
      result = {}
      for k, v of wss
        for w in v
          result[w] = [] unless result[w]
          result[w].push v
      for k, v of result
        v.sort (a, b)-> a.$ts - b.$ts
        result[k] = v.map (el)-> el.$uid
        result[k] = [...new Set(result[k])]
      result

  mutations:
    SET_NOWSELECTED: (state, { webjets })->
      state.nowselected = [...new Set webjets]
    ADD_NOWSELECTED: (state, { webjets })->
      state.nowselected = [...new Set [...state.nowselected, ...webjets]]
    REMOVE_NOWSELECTED: (state, { webjets })->
      set = new Set state.nowselected
      for w in webjets
        set.delete w
      state.nowselected = [...set]
    SET_PRESELECTED: (state, { webjets })->
      state.preselected = [...new Set webjets]
  actions:
    bootstrap:
      root: true
      handler: (context)->
        @watch (=> @getters['webjet/selected']), (value)->
          value = value.filter (v)-> not (v.provideSidebar?.activeTab == 'inventory')
          selection = value.map (v)-> v.src
          wsBoard.share 'selection', selection

        selectedBySrc = context.state.selectedBySrc
        @watch (=> @getters['webjet/selected']), (value)->
          one = value.length == 1
          context.state.oneWebjetSelected = one if context.state.oneWebjetSelected != one
          srcs = value.map (w)-> w.src
          for k, v of selectedBySrc
            selectedBySrc[k] = false if !(k in srcs)
          for k in srcs
            Vue.set(selectedBySrc, k, true) if !selectedBySrc[k]

        selectedWebjetWithChildsBySrc = context.state.selectedWebjetWithChildsBySrc
        @watch (=> @getters['webjet/selected']), (value)->
          srcs = value.map (w)-> w.src
          itemsSrc = []
          for src in srcs
            webjet = firebind src
            itemsSrc.push src


            childItems = []
            if webjet?.connections?.items
              childItems = Object.values webjet?.connections?.items
            for c in childItems
              webjet = firebind c.src
              itemsSrc.push c.src
              items = webjet?.connections?.items
              if items
                for k,v of items
                  itemsSrc.push v.src

          for k, v of selectedWebjetWithChildsBySrc
            selectedWebjetWithChildsBySrc[k] = false if !(k in itemsSrc)
          for k in itemsSrc
            Vue.set(selectedWebjetWithChildsBySrc, k, true) if !selectedWebjetWithChildsBySrc[k]

    setPreselected: ({ state, commit }, { webjets })->
      equals = true
      index = null
      webjetsToPreselected = []
      if webjets.length < 1 and state.preselected.length > 0
        equals = false
      else
        arrI = []
        for v, i in webjets
          inPreselected = false
          for p, n in state.preselected
            inPreselected = true if p == v
          unless inPreselected
            equals = false
            arrI.push i
            # index = i
        if arrI.length
          webjetsToPreselected = [...state.preselected]
          for w in arrI
            webjetsToPreselected.push webjets[w]
        for v, i in state.preselected
          inPreselected = false
          for p, n in webjets
            inWebjets = false
            if p == v
              webjetsToPreselected.push v
              inPreselected = true
          unless inPreselected
            equals = false
      unless equals
        # to websocket?
        commit 'SET_PRESELECTED', { webjets:webjetsToPreselected }

    setNowselected: ({ state, commit }, { webjets })->
      equals = true
      if webjets.length == state.nowselected.length
        for v, i in webjets
          equals = false if v != state.nowselected[i]
      else
        equals = false
      unless equals
        commit 'SET_NOWSELECTED', { webjets }

    addNowselected: ({ commit }, { webjets })->
      commit 'ADD_NOWSELECTED', { webjets }

    removeNowselected: ({ commit }, { webjets })->
      commit 'REMOVE_NOWSELECTED', { webjets }

    resetSelection: ({ commit })->
      @dispatch 'webjet/setNowselected', { webjets: [] }
      @dispatch 'webjet/setPreselected', { webjets: [] }

    preselectedToNowselected: ({ state, commit }, { invert = false })->
      if invert
        webjets = state.nowselected.filter (w)-> !state.preselected.includes w
        for w in state.preselected
          webjets.push w if !state.nowselected.includes(w)
      else
        webjets = [...state.nowselected, ...state.preselected]
      webjets = filterSelected webjets
      commit 'SET_NOWSELECTED', { webjets }
      commit 'SET_PRESELECTED', { webjets: [] }
