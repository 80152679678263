












































import { webjet, positionable, expandable, childable, draggable, selectable, themeable, relationable, plusable, resizable } from './mixins'
import { userDisplayName, nowFirebase, date } from '@/utils'
import Avatar from '@/components/Avatar.vue'

export default
  name: 'WebjetComments'
  mixins: [ webjet, positionable, expandable, childable, draggable, selectable, themeable, relationable, plusable, resizable ]
  components: { Avatar }
  data: ->
    msg: ''
    active: false
  computed:
    canSend: ->
      @active and @isOnlySelected and @provideCanvas?.userCanEdit
    userCanEdit: ->
      @provideCanvas?.userCanEdit
    comments: ->
      comments = @webjet?.data?.comments
      return null unless comments
      result = []
      for k,v of comments
        comment = {
          uid: v.user
          name: if v.user == @$store.state.uid then 'You' else userDisplayName v.user
          massage: v.massage
          date: date v.date
        }
        result.push comment
      result
  watch:
    canSend: (val, oldVal) ->
      if val != oldVal and !val
        @active = false
        @sendComment()

  methods:
    keyDownEsc: ->
      @active = false
    mousedown: (e) ->
      e.stopPropagation()
      # e.preventDefault()
    keyDownEnter: (e) ->
      if e.keyCode == 13 and !e.shiftKey
        @sendComment()
        e.stopPropagation()
        e.preventDefault()
        return false
      return
    sendComment: ->
      msg = @msg.trim()
      return false unless msg
      now = nowFirebase()
      data = {
        data: Date.now()
        user: @$store.state.uid
        massage: @msg
        date: now
      }
      @$store.dispatch 'webjet/sendCommentsMassage',
        path: @webjet.$path
        data: data
      @msg = ''
    dragstart: (e)->
      console.log 'ssss'
      e.preventDefault()
      e.stopPropagation()
    hotkeyBlocker: (e) ->
      # console.log '111'
      if e.ctrlKey && e.keyCode in [ 89, 90 ]
        return
      # so far we just block key events from editor to not interfere with app hotkeys
      # later some logic can be implemented
      e.stopPropagation()
      # block ctrl +/-/0
      if e.ctrlKey && e.keyCode in [ 187, 189, 48, 107, 109, 45, 43, 96 ]
        e.preventDefault()
    activeComment: ->
      if @userCanEdit
        @active = true
