
















export default
  name: 'WScaleResizer'
  inject: ['provideWebjet', 'provideCanvas']
  data: ->
    active: false
    activeData: { x: null, y: null }
    clickWithoutMove: false
    height: null
    width: null
    start: 0
    round: true
    resizeTouch: { hold: false, active: false, ready: false }
  computed:
    scale: -> @provideCanvas.scale
    showLines: ->
      true
    showDots: ->
      true
    show: ->
      !@provideWebjet.provideWebjet and !@provideWebjet.isReadOnly
    classes: ->
      'w-scale-resizer-active': @active || @resizeTouch.active
      'w-scale-resizer-ready': @resizeTouch.ready
    styles: ->
      dotScale = if @scale < 1 then 1 / @scale else 1
      dotScale = 16 if dotScale > 16
      return
        '--dot-scale': dotScale
  methods:
    activeResizeElement: (siideX,sideY) ->
      return true if @activeData.x == siideX and @activeData.y == sideY
    touchstart: (e, x = null, y = null)->
      if e.touches?.length == 1
        @resizeTouch = {
          hold: true, active: false, ready: false,
          bx: e.touches[0].pageX, by: e.touches[0].pageY,
          timestamp: new Date().getTime(),
          readyTimeout: setTimeout (=> @resizeTouch.ready = true), 500
        }
        @activeData.x = x
        @activeData.y = y
        document.addEventListener 'touchmove', @touchmove
        document.addEventListener 'touchend', @touchend
        e.stopPropagation()
    touchmove: (e) ->
      distance = @touchDistance(e)
      if @resizeTouch.hold

        if e.touches?.length != 1
          @touchcancel(e)
          return
        if new Date().getTime() - @resizeTouch.timestamp < 500
          if distance > 20
            @touchcancel(e)
          return

        e.stopPropagation()

        @resizeTouch.active = true
        @resizeTouch.hold = false
        @resizeTouch.ready = true

      else if @resizeTouch.active
        e.stopPropagation()
        # e.preventDefault()
        rect = @provideCanvas.screen
        if distance > 20
          @resizeTouch.ready = false
        touchX = @provideCanvas.sceneX + (e.touches[0].pageX - rect.x1) / @provideCanvas.scale
        touchY = @provideCanvas.sceneY + (e.touches[0].pageY- rect.y1) / @provideCanvas.scale
        if @activeData.x
          if @activeData.x == 'right'
            width = touchX - @provideWebjet.elementRect.x1
            @provideWebjet.resizeWidth = width
          else if @activeData.x == 'left'
            @width = @provideWebjet.elementRect.x2 - @provideWebjet.elementRect.x1 unless @width
            width = ( @provideWebjet.x - touchX) + @width
            if @round
              touchX = Math.round(touchX / 20) * 20
              width = ( @provideWebjet.x - touchX) + @width
            baseWidth = @provideWebjet.baseWidth
            if width <= baseWidth
              width = baseWidth
              touchX = (@provideWebjet.x + @width) - baseWidth
            @provideWebjet.resizeX = touchX
            @provideWebjet.resizeWidth = width

        if @activeData.y
          if @activeData.y == 'bottom'
            height = touchY - @provideWebjet.elementRect.y1
            @provideWebjet.resizeHeight = height
          else if @activeData.y == 'top'
            @height = @provideWebjet.elementRect.y2 - @provideWebjet.elementRect.y1 unless @height
            height = (@provideWebjet.y - touchY) + @height
            baseHeight = @provideWebjet.baseHeight
            if height <= baseHeight
              touchY = @provideWebjet.elementRect.y2 - baseHeight
              height = baseHeight
            if @round
              touchY = Math.round(touchY / 20) * 20
              height = @provideWebjet.elementRect.y2 - touchY
            @provideWebjet.resizeHeight = height
            @provideWebjet.resizeY = touchY
    touchend: (e) ->
      if @resizeTouch.hold

        if e.touches?.length != 1
          @touchcancel(e)
          return
        if new Date().getTime() - @resizeTouch.timestamp < 500
          return
      else if @resizeTouch.active
        data = {
            size:
              width: if @provideWebjet.connection?.size?.width then @provideWebjet.connection.size.width else @provideWebjet.baseWidth
            coordinates:
              x: @provideWebjet.x
              y: @provideWebjet.y
          }
        if @provideWebjet.connection?.size?.height
            data.size.height = @provideWebjet.connection?.size?.height

        data.size.width = @provideWebjet.resizeWidth if @provideWebjet.resizeWidth
        data.size.height = @provideWebjet.resizeHeight if @provideWebjet.resizeHeight
        data.coordinates.x = @provideWebjet.resizeX if @provideWebjet.resizeX
        data.coordinates.y = @provideWebjet.resizeY if @provideWebjet.resizeY
        @$store.dispatch 'webjet/updateConnection',
          path: @provideWebjet.src
          data: data
      @touchcancel(e)

    touchcancel: (e)->
      # console.log "!resize touch cancel"
      clearTimeout @resizeTouch.readyTimeout
      document.removeEventListener 'touchmove', @touchmove
      document.removeEventListener 'touchend', @touchend
      @activeData = { x: null, y: null }
      @resizeTouch = { hold: false, active: false, ready: false }
      @provideWebjet.resizeHeight = false
      @provideWebjet.resizeWidth = false
      @provideWebjet.resizeX = false
      @provideWebjet.resizeY = false
      @height = null
      @width = null
    touchDistance: (e)->
      distance = Math.sqrt(
        Math.pow(e.touches[0].pageX - @resizeTouch.bx, 2) + Math.pow(e.touches[0].pageY - @resizeTouch.by, 2)
      )
      return parseInt(distance, 10)
    mousedown: (e, x = null, y = null)->
      # console.log e
      if !e.shiftKey and !e.ctrlKey
        @active = true
        @clickWithoutMove = true
        @activeData.x = x
        @activeData.y = y
        e.stopPropagation()
        document.addEventListener 'mousemove', @mousemove
      return
    mousemove: (e)->
      # console.log e
      if @active
        @clickWithoutMove = false
        rect = @provideCanvas.screen
        if @activeData.x
          mouseX = @provideCanvas.sceneX + (e.x - rect.x1) / @provideCanvas.scale
          if @activeData.x == 'right'
            width = mouseX - @provideWebjet.elementRect.x1
            @provideWebjet.resizeWidth = width
          else if @activeData.x == 'left'
            @width = @provideWebjet.elementRect.x2 - @provideWebjet.elementRect.x1 unless @width
            width = ( @provideWebjet.x - mouseX) + @width
            # console.log 'W 1 -', @provideWebjet.x
            if @round
              mouseX = Math.round(mouseX / 20) * 20
              width = ( @provideWebjet.x - mouseX) + @width
            baseWidth = @provideWebjet.baseWidth
            # console.log 'W 2 -', @provideWebjet.elementRect.x2
            if width <= baseWidth
              width = baseWidth
              mouseX = (@provideWebjet.x + @width) - baseWidth
            @provideWebjet.resizeX = mouseX
            @provideWebjet.resizeWidth = width

        if @activeData.y
          mouseY = @provideCanvas.sceneY + (e.y - rect.y1) / @provideCanvas.scale
          if @activeData.y == 'bottom'
            height = mouseY - @provideWebjet.elementRect.y1
            @provideWebjet.resizeHeight = height
          else if @activeData.y == 'top'
            @height = @provideWebjet.elementRect.y2 - @provideWebjet.elementRect.y1 unless @height
            height = (@provideWebjet.y - mouseY) + @height
            baseHeight = @provideWebjet.baseHeight
            if @round
              mouseY = Math.round(mouseY / 20) * 20
              height = @provideWebjet.elementRect.y2 - mouseY
            if height <= baseHeight
              mouseY = @provideWebjet.elementRect.y2 - baseHeight
              height = baseHeight
            @provideWebjet.resizeHeight = height
            @provideWebjet.resizeY = mouseY
        if e.which != 1
          @mouseup(e)
      return
    mouseup: (e)->
      # console.log e
      if @active
        @active = false
        rect = @provideCanvas.screen
        if !@clickWithoutMove
          document.removeEventListener 'mousemove', @mousemove
          e.stopPropagation()
          @provideWebjet.resizeWidth = false
          @provideWebjet.resizeHeight = false
          data = {
              size:
                width: if @provideWebjet.connection?.size?.width then @provideWebjet.connection.size.width else @provideWebjet.baseWidth
              coordinates:
                x: @provideWebjet.x
                y: @provideWebjet.y
            }
          if @provideWebjet.connection?.size?.height
            data.size.height = @provideWebjet.connection?.size?.height
          # console.log data,@provideWebjet.connection?.size?.width
          mouseX = @provideCanvas.sceneX + (e.x - rect.x1) / @provideCanvas.scale
          mouseY = @provideCanvas.sceneY + (e.y - rect.y1) / @provideCanvas.scale
          if @activeData.x == 'right'

            width = mouseX - @provideWebjet.elementRect.x1
            baseWidth = @provideWebjet.baseWidth
            width = baseWidth if width < baseWidth
            data.size.width = width
          else if @activeData.x == 'left'
            width = ( @provideWebjet.x - mouseX) + @provideWebjet.connection.size.width
            if @round
              mouseX = Math.round(mouseX / 20) * 20
              width = @provideWebjet.elementRect.x2 - mouseX
            baseWidth = @provideWebjet.baseWidth
            if width <= baseWidth
              width = baseWidth
              mouseX = @provideWebjet.elementRect.x2 - baseWidth
            data.size.width = width
            data.coordinates.x = mouseX
          if @activeData.y == 'bottom'
            height = mouseY - @provideWebjet.elementRect.y1
            baseHeight = @provideWebjet.baseHeight
            height = baseHeight if height < baseHeight
            data.size.height = height
          else if @activeData.y == 'top'
            height = (@provideWebjet.y - mouseY) + @height
            if @round
              mouseY = Math.round(mouseY / 20) * 20
              height = @provideWebjet.elementRect.y2 - mouseY
            baseHeight = @provideWebjet.baseHeight
            if height <= baseHeight
              mouseY = @provideWebjet.elementRect.y2 - baseHeight
              height = baseHeight
            data.size.height = height
            data.coordinates.y = mouseY


          @$store.dispatch 'webjet/updateConnection',
            path: @provideWebjet.src
            data: data

        @activeData = { x: null, y: null }
        @provideWebjet.resizeX = false
        @provideWebjet.resizeY = false
        @height = null
        @width = null
      return
