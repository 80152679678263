






























import SidebarObject from '../SidebarObject'
import Avatar from '@/components/Avatar.vue'
import { wsBoard } from '@/websockets'
import { boardAudio } from '@/websockets'

export default
  name: 'Channel'
  components: { SidebarObject, Avatar }
  data: ->
    users: []
    channel: 'main'
  methods:
    joinSession: ->
      return if @activeSession or @activeChannel
      boardAudio.joinSession @channel
    leaveSession: ->
      boardAudio.leaveSession()
      @removeUser()
    addUser: (data) ->
      if data.channel == @channel and !@users.includes data.user
        @users.push data.user
    removeUser: () ->
      @users = @users.filter (u) => u != @userId
    toggleAudio: () ->
      boardAudio.togglePublisherAudio()
    switchAudio: (label) ->
      boardAudio.switchAudioDevice label
    setVolume: (subscriberId, value) ->
      boardAudio.setSubscriberVolume subscriberId, value
    isTalking: (user) ->
      return false if not @publisher

      if user == @userId
        return boardAudio.state.isTalking
      else
        subscriber = boardAudio.state.subscribers.find (s) => s.userId == user
        return subscriber?.isTalking
  computed:
    readyChannel: ->
      @activeChannel and @activeSession and @activeStream
    publisher: ->
      boardAudio.state.publisher
    audiochannel: ->
      wsBoard.get 'audiochannel'
    userId: ->
      @$store.state.uid
    activeStream: ->
      boardAudio.state.stream
    activeSession: ->
      boardAudio.state.session
    activeChannel: ->
      boardAudio.state.channel
    audioDevices: ->
      boardAudio.state.audioDevices
    isMuted: ->
      boardAudio.state.isMuted
    userMoreCount: ->
      if @users?.size >= 6
        return 0
      else
        return @users?.size - 6
  watch:
    activeChannel: (value) ->
      if value and value != @channel
        @removeUser()
    audiochannel:
      immediate: true
      handler: (value) ->
        return unless value
        @users = @users.filter (u) => u == @userId

        Object.keys(value).forEach (key) =>
          @addUser value[key]
    publisher:
      immediate: true
      handler: (value) ->
        if value
          @users = @users.filter (u) => u != @userId
          @users.push @userId
        else
          @users = @users.filter (u) => u != @userId
  beforeDestroy: ->
    @leaveSession() if @activeSession
