











import { webjet, positionable, droppable, childable } from '@/webjets/mixins'

export default
  name: 'WebjetInventory'
  mixins: [ webjet, positionable, droppable, childable ]
  props:
    inventoryTrash:
      type: Boolean
      default: false
  listeners: ->
    click: @click
  firebind:
    connection: -> 'inventory'
    webjet: -> 'inventory'
    trash: -> @$store.getters['currentTrashSrc']
  data: ->
    childIsSelected: []
  computed:
    isContainer: -> true
    isExpanded: -> true
    childItems: ->
      if @inventoryTrash
        return [] unless @webjet?.connections?.trash
        return [] if @isNestedLoop
        items = Object.values @webjet.connections.trash
        items = items.filter (c)-> !c.trashClear
        items = items.sort (a, b)->
          parseFloat(a.sortKey) - parseFloat(b.sortKey)
      else
        return [] unless @webjet?.connections?.items
        return [] if @isNestedLoop
        items = Object.values @webjet.connections.items
        items = items.sort (a, b)->
          parseFloat(a.sortKey) - parseFloat(b.sortKey)
      items
  methods:
    click: ->
      if @webjet?.inventoryNew
        @$store.dispatch 'inventory/resetNew'
    droppableDragover: (e)->
      droppable.methods.droppableDragover.call this, e
      { top, left, right, bottom } = @droppablePoint(e)
      if @droppableMode == 'prepend'
        if top > 20 and @hasChilds
          @droppableMode = 'append'
      width = @$refs?.childs?.$el?.getElementsByClassName('webjet')[0]?.offsetWidth
      e.dropInWebjetWidth = width if width
      e.dragOverInvetory = true
