import { getRef } from '@/utils'
import { firebind } from '@/utils'
import { nowFirebase } from '@/utils'

export default

  INIT: (_, { uid, email, data })->
    for key, value of data
      getRef('/users').child(uid).child(key).set value
    getRef('/emails').child(email.replace(/\./g, ',')).child('uid').set uid
    # dummy team and project
    if FLAG.TEAMS
      team = "team_#{uid}"
      project = "project_#{uid}"
      getRef('/teamAccess').child(team).child('roles').child(uid).set 'owner'
      getRef('/teamAccess').child(team).child('openProjects').child(project).set 'edit'
      getRef('/projects').child(project).child('team').set team
      #getRef('/projects').child(project).child('title').set 'My project'
      #getRef('/teams').child(team).child('title').set 'My team'
      getRef('/teams').child(team).child('projects').child(project).set
        status: 'ok'
        sortKey: nowFirebase()
      getRef('/data').child(uid).child('teams').child(team).set
        status: 'ok'
        sortKey: nowFirebase()

  SET_LANG: (_, { path, lang })->
    getRef(path).child('lang').set lang

  SET_GRID: (_, { path, value })->
    getRef(path).child('grid').set value

  SET_GIZMO: (_, { path, value })->
    getRef(path).child('gizmo').set value

  SET_NOTIFY_SHARE: (_, { path, value })->
    getRef(path).child('notify_share').set value

  SET_FIRSTNAME: (_, { path, value })->
    getRef(path).child('firstName').set value

  SET_LASTNAME: (_, { path, value })->
    getRef(path).child('lastName').set value

  SET_AVATAR: (_, { path, filename, url })->
    getRef(path).child('avatar').set url
    getRef(path).child('avatarFile').set filename

  ADD_COUPON: (_, { path, coupon, type, uid })->
    getRef("/coupons").child(coupon).child('uid').set uid
    getRef("/coupons").child(coupon).child('available').set false
    getRef(path).child('coupons').child(coupon).set
      type: type||null
      addDate: new Date(nowFirebase()).toISOString()

  CREATE_REGUSER_TASK: (_, { uid, email })->
    getRef('/queue/tasks').push
      _state: 'registeruser'
      userId: uid
      subject: email
      createDate: nowFirebase()

  CREATE_INVITEBYCODE_TASK: (_, { uid, invitecode })->
    getRef('/queue/tasks').push
      _state: 'invitebycode'
      userId: uid
      subject: invitecode
      createDate: nowFirebase()
