export default
  data: ->
    animate: { startX: 0, startY: 0, x: 0, y: 0, startScale: 1, scale: 1, timestamp: 0 }

  computed:
    animateDependency: ->
      "#{@zoom.dx},#{@zoom.dy},#{@scale}"

    sceneNext: ->
      x1 = - @x / @scale
      y1 = - @y / @scale
      x2 = x1 + @$vuetify.breakpoint.width / @scale
      y2 = y1 + @$vuetify.breakpoint.height / @scale
      return @devSceneRect { x1, x2, y1, y2 } if FLAG.CANVAS_VISIBILITY_DEVELOPMENT
      return { x1, x2, y1, y2 }

    sceneNow: ->
      x1 = - (@position.x + @animate.x) / @animate.scale
      y1 = - (@position.y + @animate.y) / @animate.scale
      x2 = x1 + @$vuetify.breakpoint.width / @animate.scale
      y2 = y1 + @$vuetify.breakpoint.height / @animate.scale
      return @devSceneRect { x1, x2, y1, y2 } if FLAG.CANVAS_VISIBILITY_DEVELOPMENT
      return { x1, x2, y1, y2 }

    sceneFull: -> @scene

  methods:

    animateTick: ()->
      return unless @animation.active
      duration = 300
      duration = 1000 if FLAG.CANVAS_VISIBILITY_DEVELOPMENT


      dt = Date.now() - @animate.timestamp
      dt = duration if dt > duration
      progress = dt / duration
      @animate.x = @animate.startX + (@zoom.dx - @animate.startX) * progress
      @animate.y = @animate.startY + (@zoom.dy - @animate.startY) * progress
      @animate.scale = @animate.startScale + (@scale - @animate.startScale) * progress
      if progress == 1
        @animation.active = false
        @animation = { active: false, ...@scene }
      else
        requestAnimationFrame @animateTick

    animateStop: ()->
      @zoom.dx = @animate.x
      @zoom.dy = @animate.y
      @zoom.level = Math.log(@animate.scale) / Math.log(1.2)
      @animation.active = false

    animateFinish: ()->
      @animate.x = @zoom.dx
      @animate.y = @zoom.dy
      @animate.scale = @scale
      @animation.active = false

    withoutAnimation: (f)->
      f()
      @$nextTick => @animateFinish()

  watch:
    scene: ->
      # console.log '33'
      @$nextTick =>

        if @animation.active
          #console.log 'try set bigger'
          @animation.x1 = @scene.x1 if @scene.x1 < @animation.x1
          @animation.x2 = @scene.x2 if @scene.x2 > @animation.x2
          @animation.y1 = @scene.y1 if @scene.y1 < @animation.y1
          @animation.y2 = @scene.y2 if @scene.y2 > @animation.y2
        else
          #console.log 'set to scene'
          @animation.x1 = @scene.x1 if @animation.x1 != @scene.x1
          @animation.x2 = @scene.x2 if @animation.x2 != @scene.x2
          @animation.y1 = @scene.y1 if @animation.y1 != @scene.y1
          @animation.y2 = @scene.y2 if @animation.y2 != @scene.y2
          if FLAG.CANVAS_WILL_CHANGE
            requestAnimationFrame => requestAnimationFrame =>
              @willChange = 'transform'

    animateDependency: ->
      # console.log 'animateDependency'
      @animate.startX = @animate.x
      @animate.startY = @animate.y
      @animate.startScale = @animate.scale
      @animate.timestamp = Date.now()
      unless @animation.active
        @animation.active = true
        @willChange = '' if FLAG.CANVAS_WILL_CHANGE
        requestAnimationFrame @animateTick
