import { debounce } from '@/utils'
#import lzString from 'lz-string'

PREFIX = '_fbc_'
LIMIT = 2000000
TARGET = 1500000
#TARGET = 1000
debouncedWrite = debounce (=> LocalStorageCache._write()), 1000

class LocalStorageCache
  @toWrite = {}

  @size: ->
    result = 0
    for k, v of localStorage
      if k.startsWith PREFIX
        result += k.length + v.length
    result

  @gc: ->
    startTime = Date.now()
    nowSize = JSON.stringify(localStorage).length
    startSize = nowSize
    console.log 'call cache gc', nowSize
    if nowSize > TARGET
      elements = []
      for k, v of localStorage
        if k.startsWith PREFIX
          item = JSON.parse v
          unless Array.isArray item
            localStorage.removeItem k
          t = item[0]
          elements.push {key: k, size: k.length+v.length, t: t}
      elements = elements.sort (a,b)=> a.t - b.t
      i = 0
      while nowSize > TARGET and i < elements.length
        localStorage.removeItem(elements[i].key)
        nowSize = nowSize - elements[i].size - 10
        if nowSize <= TARGET
          nowSize = JSON.stringify(localStorage).length
        i += 1
      delta = Date.now() - startTime
      free = startSize - nowSize
      if i > 0
        console.log 'finish gc', {time: delta, deleted: i, free: free}



  @_write: ()->
    nowSize = JSON.stringify(localStorage).length
    for k, v of @toWrite
      path = "#{PREFIX}#{k}"
      value = JSON.stringify [Date.now(), v]
      size = path.length + value.length
      if (nowSize + size) > LIMIT
        @gc()
        nowSize = JSON.stringify(localStorage).length
      localStorage[path] = value
      nowSize += size
    @toWrite = {}

  @write: (path, value)->
    @toWrite[path] = value
    debouncedWrite()

  @exist: (path)->
    path = "#{PREFIX}#{path}"
    item = localStorage.getItem(path)
    return false if item == null
    Array.isArray(JSON.parse item)

  @read: (path)->
    path = "#{PREFIX}#{path}"
    item = localStorage.getItem(path)

    #window.lsreadsumtime += (Date.now() - t)
    #t = Date.now()
    ##lzString.compress String(item)
    #x = String item
    #cc = lzString.compressToUTF16 x
    #console.log x.length, cc.length

    return null if item == null
    item = JSON.parse item
    unless Array.isArray item
      localStorage.removeItem path
      return null
    return item[1]

if IS_DEVELOPMENT
  window.LocalStorageCache = LocalStorageCache

export default LocalStorageCache
