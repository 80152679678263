
























import ModalImgCropper from '@/components/ImgCropper/ModalImgCropper'
import { getOwnerUID } from '@/utils'
export default
  name:
    "TeamIcon"
  components:
    {
      ModalImgCropper
     }
  props:
    teamId: { default: false }
  data: ->
    openModalCropper: false
    imgFile: null
    availiableThemes: ['theme-board-default', 'theme-board-gray', 'theme-board-red', 'theme-board-yellow', 'theme-board-green', 'theme-board-purple', 'theme-board-blue', 'theme-board-lightblue']
    dragOverImg: false
  computed:
    roles: ->
      @firebind "/teamAccess/#{@teamId}/roles"
    dataTitle: ->
      t = @firebind("/teams/#{@teamId}/title")
      t?.$value
    title: ->
      return '' unless @teamId
      if @dataTitle
        @dataTitle
      else if @teamId.startsWith 'team_'
        uid = @teamId.split('_')[1]
        firstUserName = @firebind '/users/'+uid+'/firstName'
        lastUserName = @firebind '/users/'+uid+'/lastName'
        email = @firebind '/users/'+uid+'/email'
        "#{firstUserName.$value || lastUserName.$value || email.$value?.split('@')[0] }'s team"
    userCanEdit: ->
      userTeamRole = @roles[@$store.state.uid]
      return false unless userTeamRole
      return true if userTeamRole == 'admin' or userTeamRole == 'owner'

    boradImg: ->
      t = @firebind("/teams/#{@teamId}/icon")
      t?.$value
    initials: ->
      return '' unless @title
      result = ''
      words = @title.replace(/[^\p{L}\p{Z}^$\n]/gu, '').split(' ')
      result += words[0][0] if words[0]
      result += words[1][0] if words[1]
      result.toUpperCase()
  methods:
    drop: (e) ->
      e.preventDefault()
      e.stopPropagation()
      @dragOverImg = false
      file = e.dataTransfer?.files?[0]
      imageType = /image.*/
      isImg = file?.type.match imageType
      if file and isImg
        @imgFile = file
        @openModalCrop()
    dragover: (e) ->
      @dragOverImg = true
      e.preventDefault()
    dragleave: (e) ->
      @dragOverImg = false
    openModalCrop: ->
      @openModalCropper = true
    openUpload: ->
      input = @$el.querySelector 'input.boardimg-upload'
      if input
        input.click()
    processCrop: ->
      input = @$el.querySelector 'input.boardimg-upload'
      if input?.files?.length > 0
        @imgFile = input.files[0]
        @openModalCrop()
    uploadCroppedImg: (img) ->
      @$store.dispatch 'team/updateTeamIcon',
        path: "/teams/#{@teamId}"
        file: img
    deleteCover: ->
      @$store.dispatch 'team/deleteTeamIcon',
        path: "/teams/#{@teamId}"

  watch:
    openModalCropper: (val)->
      input = @$el.querySelector 'input.boardimg-upload'
      input.value= null

  firebind:
    connection: -> @src
    #board: -> @connection?.src
    shared: ->
      @connection?.src and "#{@connection.src}/shared"
    boardData: ->
      @connection?.src and "#{@connection.src}/data"
