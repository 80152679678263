import { getPath, getRef } from '@/utils'
import Vue from 'vue'
import LocalStorageCache from './localStorageCache'
import state from '@/store/state'
import { afterGrouped } from '@/utils'

data = {}
nrData = {} # non reactive

dbCallbacks = []
#dbOnValueCallbacks = []

permissionDeniedPaths = {}

setRecPath = (obj, path)->
  Object.defineProperty obj, '$path', {value: path}
  for key, value of obj
    if typeof value == 'object' and value != null
      setRecPath value, path+'/'+key
  Object.freeze obj

serialize = (path, value, options)->
  if (typeof value == 'object') and value != null
    if (('$path' of value) or ('$sync' of value) or ('$value' of value) or
    ('then' of value) or ('$ready' of value))
      console.warn 'collision in serialize'
      return {}
    result = value
  else
    result = {}
    Object.defineProperty result, '$value', { value: value }
  if options?.ready
    Object.defineProperty result, '$ready', { value: true }
  if options?.sync
    Object.defineProperty result, '$sync', { value: true }
  if options?.error
    Object.defineProperty result, '$error', { value: options.error }
  unless options?.sync
    Object.defineProperty result, 'then',
      value: (resolve)=>
        unless state.db
          await new Promise (resolveDB)->
            dbCallbacks.push ->
              resolveDB()
        await getRef(path).once('value').catch((e)=>)
        unless data[path].$sync
          # important. Wait for data. Infinity loop instead
          await new Promise (r)-> afterGrouped r
        resolve data[path]
  setRecPath result, path
  Object.freeze result
  result


module =
  state:
    data: data
    syncMode: false
  mutations:
    FIREBIND_SET_EMPTY: (state, { path })->
      serialized = serialize path, null
      Vue.set data, path, serialized
      nrData[path] = serialized
    FIREBIND_SET_PARENTS: (state, {path, value})->
      serialized = serialize path, value, { ready: true }
      Vue.set data, path, serialized
      #nrData[path] = serialized
    FIREBIND_SET_CACHE: (state, {path, value})->
      serialized = serialize path, value, { ready: true}
      Vue.set data, path, serialized
      nrData[path] = serialized
    FIREBIND_SET_FIREBASE: (state, {path, value})->
      serialized = serialize path, value, { ready: true, sync: true }
      Vue.set data, path, serialized
      nrData[path] = serialized
    FIREBIND_SET_FIREBASE_ERROR: (state, { path, value })->
      serialized = serialize path, null, { ready: true, error: value, sync: true }
      Vue.set data, path, serialized


  actions:
    bootstrapUser:
      root: true
      handler: ({ dispatch })->
        dispatch 'firebindInit'
    firebindInit: ()->
      f() for f in dbCallbacks
    firebindTryPermissionDenied: ({}, prefix)->
      for k, v of permissionDeniedPaths
        if k.startsWith prefix
          console.log 'firebind: try permission denied', k
          delete permissionDeniedPaths[k]
          v()

    firebindPath: ({state, rootState, commit}, key)-> #ttf 'firebindPath', =>
      throw 'uid not ready for firebind' unless rootState.uid
      path = getPath key
      if path.indexOf('webjet:') != -1
        error = { code: 'PERMISSION_DENIED' }
        commit 'FIREBIND_SET_FIREBASE_ERROR', { path, value: error }
        return
      if data[path] == undefined
        existInParents = false
        if FLAG.FIREBIND_PARENT_CHECK
          parentPath = path
          while parentPath.lastIndexOf('/') > 0 and !existInParents
            parentPath = parentPath.substring(0, parentPath.lastIndexOf('/'))
            if nrData[parentPath]?.$ready
              existInParentsValue = nrData[parentPath]
              way = path.split(parentPath+'/')[1].split('/')
              for k in way
                if existInParentsValue
                  existInParentsValue = existInParentsValue[k]
                else
                  existInParentsValue = null
              existInParents = true

        if existInParents
          if typeof existInParentsValue == 'object' and value != null
            value = Object.assign {}, existInParentsValue
          else
            value = existInParentsValue
          commit 'FIREBIND_SET_PARENTS', {path, value}
        else if FLAG.FIREBIND_LS_CACHE and LocalStorageCache.exist path
          value = LocalStorageCache.read(path)
          commit 'FIREBIND_SET_CACHE', {path, value}
        else
          commit 'FIREBIND_SET_EMPTY', {path}
        # check fast cache
        # check slow cache
        f = ->
          rootState.db.ref(path).on 'value', (snapshot)->
            if state.syncMode or !FLAG.FIREBIND_SYNC_ASYNC
              value = snapshot.val()
              commit 'FIREBIND_SET_FIREBASE', {path, value}
              if FLAG.FIREBIND_LS_CACHE and !existInParents
                LocalStorageCache.write(path, value)
            else
              afterGrouped =>
                value = snapshot.val()
                commit 'FIREBIND_SET_FIREBASE', {path, value}
                if FLAG.FIREBIND_LS_CACHE and !existInParents
                  LocalStorageCache.write(path, value)
          , (error)->
            commit 'FIREBIND_SET_FIREBASE_ERROR', { path, value: error }
            if error.code == 'PERMISSION_DENIED'
              permissionDeniedPaths[path] = f
              console.warn 'Firebind:', error.code, error.message
            else
              console.warn 'Firebind: Error from firebase', error.code
              console.warn error

        if rootState.db
          Vue.nextTick -> f()
        else
          dbCallbacks.push f
      else
        console.warn 'multiple firebindPath with same path'


export default module
export firebindData = data
