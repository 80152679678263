childable =
  classes: ->
    return false unless @webjet
    return
      'has-childs': @hasChilds

  computed:
    isChildable: -> true
    hasChilds: ->
      items = @webjet?.connections?.items
      return items and Object.keys(items).length > 0

    #childs: ->
    parentsSrc: ->
      if @provideWebjet
        result = @provideWebjet.parentsSrc.concat @provideWebjet.src
      else
        result = []
      result

    isNestedLoop: ->
      @src in @parentsSrc

    childItems: ->
      return [] unless @webjet?.connections?.items
      return [] if @isNestedLoop
      items = Object.values @webjet.connections.items
      items = items.sort (a, b)->
        parseFloat(a.sortKey) - parseFloat(b.sortKey)
      items

    # childConnections childItems
    # childPaths
    # childDatas
    # childWebjets

    # registrChild
    # unregistrChild




export default childable
export { childable }
