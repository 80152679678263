








import ContextBtn from './ContextBtn'
export default
  name: 'Group'
  components: { ContextBtn }
  inject: { provideCanvas: { default: null } }
  priority: 0

  if: (selection)->
    return false unless selection.length > 1
    return false unless selection[0].provideScene
    selection.every (w) => !w.canDropOnlyOnCanvas

  computed:
    selection: -> @$store.getters['webjet/selected']
    centerX: ->
      @provideCanvas.sceneX + @provideCanvas.sceneWidth / 2
    centerY: ->
      @provideCanvas.sceneY + @provideCanvas.sceneHeight / 2

  methods:
    action: ->
      items = @selection.map (w)-> { path: w.src }
      @$store.dispatch 'webjet/groupToList',
        items: items
        data:
          coordinates:
            x: @centerX - 150
            y: @centerY - 50
