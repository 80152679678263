export default
  needAuth: false
  showAuth: false
  uid: false
  connected: false
  signOut: false
  isRegisteredUser: false
  isAnonymousUser: false
  publicBoardLoading: false
  showWelcomeModal: false
  help: false
  activity: true
  visibility: document.visibilityState == 'visible'
