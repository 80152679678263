

















import { usualMixinPack } from './mixins'

export default
  name: 'WebjetEmbed'
  mixins: [...usualMixinPack]
  data: ->
    rectWidth: null
  classes: ->
    ["embed-#{@webjet?.data?.icon}"]: @webjet?.data?.icon?
  styles: ->
    # console.log 'heightFromProportion', @heightFromProportion
    # if @heightFromProportion
    # heightFromProportion = null
    # rectWidth = @elementSceneRect?.x2 - @elementSceneRect?.x1
    # if !rectWidth
    #   w = @baseWidth
    #   w = @connection.size.width if @connection.size.width
    #   h = @baseHeight
    #   h = @connection.size.height if @connection.size.height
    #   p = w / h
    #   heightFromProportion = rectWidth / p
    #   console.log '!!!!!!!!!!!!!!!', @elementSceneRect


      # if  @heightFromProportion
      #   console.log '!!!!!!!!!!!!!!!', @heightFromProportion
      #   return {'height': "#{@heightFromProportion}px"}
  computed:
    # st: ->
    #   if @heightFromProportion
    #     console.log '!!!!33333333333!!!!!!!!!!!', @heightFromProportion
    #     return {'height': "#{@connection.size.height}px"}
    heightFromProportion: ->
      if @rectWidth
        scale = 1
        scale = @provideCanvas.scale if @provideCanvas
        rectWidth = @elementSceneRect?.x2 - @elementSceneRect?.x1
        w = @baseWidth
        w = @connection.size.width if @connection.size.width
        h = @baseHeight
        h = @connection.size.height if @connection.size.height
        p = w / h
        heightFromProportion = rectWidth / p
        return heightFromProportion
    # proportionalnHeight222: ->
    #   # console.log '!!!!!!!!', @parentScenReact and @$el and @provideWebjet
    #   if @parentScenReact and @$el and @provideWebjet
    #     scale = @provideCanvas.scale
    #     rect = @$el.getBoundingClientRect()
    #     # w = rect.width / scale
    #     w = @baseWidth
    #     w = @connection.size.width if @connection.size.width
    #     h = @baseHeight
    #     h = @connection.size.height if @connection.size.height
    #     p = w / h

    #     output = rect.width / scale / p
    #     # console.log scale
    #     console.log 'aaaaaaa',output,rect.width,scale, p, w, h
    #     return output
    #   else
    #     null


    # parentScenReact: ->
    #   if @provideWebjet
    #     console.log '@2222222@@@@@@'
    #     @provideWebjet.elementSceneRect
    url: ->
      @webjet?.data?.url
    scrolling: ->
      return 'no' if @webjet?.data?.titleOrigin == "Disqus"
      return 'auto'
  # methods:
  #   setWidth: ->

  # watch:
  #   parentScenReact: (val, oldVal) ->
  #     if val and val != oldVal
  #       scale =  @provideCanvas.scale
  #       if @$el
  #         rect = @$el.getBoundingClientRect()
  #         w = @baseWidth
  #         w = @connection.size.width if @connection.size.width
  #         h = @baseHeight
  #         h = @connection.size.height if @connection.size.height
  #         p = w / h

  #         @heightFromProportion = rect.width / scale / p

        # console.log rect.width / scale

  # mounted: ->
  #   console.log 'isMounted', @$el.getBoundingClientRect()
  #   console.log "----------", @provideCanvas
  #   @rectWidth = @$el.getBoundingClientRect().width
    # console.log @$el, @provideWebjet
    # console.log
