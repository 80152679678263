import { firebind,getPath } from "@/utils"
import './css/themeable.sass'
import './css/colors.sass'

recRelations = (path, visited={})->
  return if !path
  webjet = firebind path
  return if !webjet.$ready
  return if visited[path]
  visited[path] = true
  result = []
  if webjet.connections?.relations
    for k, relation of webjet.connections.relations
      result.push relation.src
  if webjet.connections?.items
    for k, connection of webjet.connections.items
      subs = recRelations connection.src, visited
      result = result.concat(subs) if subs
  result


export default
  data: ->
    relationsComponentsBySrc: {}
    lastRelationConfig: {width: 3, style: 'line', backgroundId: null}
    arrowToolsPoint: null
    delayedRelations: true
    relationIsActive: false
    relationContextActiveSide: null
    activeContextRelation: null
    relationPlusPoint: { active: false, type: null, data: null, x: 0, y: 0, webjet: false, side: false, relation: null }

  created: ->
    @nrRelationsComponentsBySrc = @relationsComponentsBySrc

  computed:
    selectedRelation: ->
      @$store.getters['webjet/relation/selected']
    relations: ->
      if @delayedRelations
        requestAnimationFrame => @delayedRelations = false
        return []
      if @connection?.src
        result = recRelations(@connection.src)
        return [] unless result
        [...new Set result]
      else
        []

  methods:
    registerRelation: (component)->
      @$set @relationsComponentsBySrc, getPath(component.src), component

    unregisterRelation: (component)->
      for k, w of @relationsComponentsBySrc
        if w == component
          @$set @relationsComponentsBySrc, k, null

    getRelationComponentBySrc: (src)->
      src = getPath src
      unless @nrRelationsComponentsBySrc[src]
        @$set @nrRelationsComponentsBySrc, src, null
      @nrRelationsComponentsBySrc[src]