





































import mixin from './mixin'
import Childs from './Childs'
export default
  name: 'WejeShape'
  mixins: [ mixin ]
  components: { Childs }
  props: ['data']
  data: ->
    observer: null
    fontSize: 22
    fontNormalized: false
    textChanged: true
    maxTextLength: 150
  computed:
    shapeClass: ->
      return 'star-shape' if @type == 'star'
      return 'circle-shape' if @type == 'circle'
      return 'square-shape' if @type == 'square'
      return 'trapezoid-shape' if @type == 'trapezoid'
      return 'diamond-shape' if @type == 'diamond'
      return 'triangle-top-shape' if @type == 'triangle-top'
      return 'triangle-bottom-shape' if @type == 'triangle-bottom'
      return 'triangle-right-shape' if @type == 'triangle-right'
      return 'triangle-left-shape' if @type == 'triangle-left'
      return 'data-base-shape' if @type == 'data-base'
      return 'parallelogram-shape' if @type == 'parallelogram'
      return 'parallelogram-right-shape' if @type == 'parallelogram-right'
      return 'ellipse-shape' if @type == 'ellipse'
      return 'doc-shape' if @type == 'doc'
    textStyle: ->
      fontSize = @fontSize
      return
        'font-size': "#{fontSize}px"
    viewBox: ->
      return '0 0 320 220' if @type == 'doc'
      return '0 0 320 200' if @type == 'ellipse'
      return '0 0 320 296' if @type == 'star'
      return '0 0 320 224' if @type == 'trapezoid'
      return '0 0 320 160' if @type == 'parallelogram' or @type == 'parallelogram-right'
      return '0 0 320 320'
    foreignObjectAttr: ->
      if @type == 'star'
        return
          x: 100
          y: 110
          width: 120
          height: 100
      else if @type == 'circle'
        return
          x: 50
          y: 50
          width: 220
          height: 220
      else if @type == 'square'
        return
          x: 10
          y: 10
          width: 300
          height: 300
      else if @type == 'trapezoid'
        return
          x: 70
          y: 10
          width: 180
          height: 210
      else if @type == 'diamond'
        return
          x: 80
          y: 80
          width: 160
          height: 160
      else if @type == 'triangle-top'
        return
          x: 80
          y: 150
          width: 160
          height: 170
      else if @type == 'triangle-bottom'
        return
          x: 80
          y: 0
          width: 160
          height: 170
      else if @type == 'triangle-right'
        return
          x: 10
          y: 80
          width: 160
          height: 160
      else if @type == 'triangle-left'
        return
          x: 150
          y: 80
          width: 160
          height: 160
      else if @type == 'data-base'
        return
          x: 10
          y: 110
          width: 300
          height: 160
      else if @type == 'parallelogram'
        return
          x: 80
          y: 5
          width: 160
          height: 150
      else if @type == 'parallelogram-right'
        return
          x: 80
          y: 5
          width: 160
          height: 150
      else if @type == 'ellipse'
        return
          x: 80
          y: 5
          width: 160
          height: 190
      else if @type == 'doc'
        return
          x: 5
          y: 5
          width: 310
          height: 170
  methods:
    normalizeFont: ->
      defaultFont = 22
      oldFont = @fontSize
      @fontSize = defaultFont
      self = this
      initNormalize = () ->
        # console.log 'initNormalize', self.$refs.textWrap.offsetHeight, self.$refs.text.offsetHeight
        # console.log 'initNormalize', self.$refs.textWrap.scrollHeight, self.$refs.text.scrollHeight
        self.$nextTick ->
          if self.$refs.textWrap.offsetHeight <= self.$refs.text.offsetHeight and self.$refs.textWrap.offsetHeight > 0
          # if self.$refs.textWrap.scrollHeight <= self.$refs.text.scrollHeight and self.$refs.textWrap.offsetHeight > 0

            self.fontNormalized = true
            self.textChanged = false
            oldFont = self.fontSize
            self.fontSize  = oldFont - 0.5
            initNormalize()
      initNormalize()
  watch:
    type: (val)->
      @normalizeFont()
    text: (val) ->
      @textChanged = true
      @$refs.text.innerText = val
  mounted: ->
    @observer = new MutationObserver (mutationRecords)=>
      @normalizeFont()
    @observer.observe @$refs.text, {childList: true,characterData: true,subtree:true}

    if @text and @$refs.text
      @$refs.text.innerText = @text
      @normalizeFont()
  beforeDestroy: ->
    if @observer
      @observer.disconnect()
