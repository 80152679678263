





import { extremPointsCurves } from '@/utils'

export default
  name: 'RelationPath'
  inject: ['provideCanvas']
  props: ['points']
  computed:
    show: ->
      if @path
        return true
      false
    extremPoints: ->
      extremPointsCurves [@path]
    parentAttach: ->
      @$parent.cachedAttach
    attach: ->
      return false unless @parentAttach
      # return true
      { x1, y1, x2, y2 } = @extremPoints
      left = @provideCanvas.sceneX
      right = left + @provideCanvas.sceneWidth
      top = @provideCanvas.sceneY
      bottom = top + @provideCanvas.sceneHeight
      # console.count('attach')
      if x1 > right or x2 < left or y1 > bottom or y2 < top
        return false
      return true
    path: ->

      if @points
        return "M#{@points[0].x} #{@points[0].y} C#{@points[1].x} #{@points[1].y} #{@points[2].x} #{@points[2].y} #{@points[3].x} #{@points[3].y}"

      return '' if !@p1 or !@p2
      path = "M#{@p1.x} #{@p1.y} #{@p2.x} #{@p2.y}"
