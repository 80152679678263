










































import { wsBoard } from '@/websockets'
import { extremPointsCurves, isHoverableDevice } from '@/utils'
import RelationPlus from './relation/RelationPlus'
import RelationPath from './relation/RelationPath'
export default
  name: 'Relation'
  inject: ['provideCanvas']
  props: ['src', 'shared', 'context', 'activeContext']
  components: { RelationPlus,RelationPath }
  data: ->
    drag: { point: false, x: 0, y: 0, webjet: false, side: false }
    toDrop: false
    dropElements: []
    isTouch: false
    hoverPoint: 0
    hoverPath: false
    plus: null
    webjetPoint: null
    dragStart: {x: 0, y:0, point: false }
    isDestroyed: false,
    arrowTools: false,
    arrowToolsPoint: null
    cachedAttach: null
    pathIsRendered: false
    activeEditTitle: false
    tmpText: ''
    defaultPointOpacityNum: 0

  computed:
    defaultPointOpacity: ->
      # return null
      return null unless isHoverableDevice
      return null unless @context
      return 0.3 if @defaultPointOpacityNum == null
      return null if @drag.point
      # console.log @contextWebjet.selectEventType
      # return 0.3 if @contextWebjet.selectEventType == 'touch'
      @defaultPointOpacityNum

    segmentedPath: ->

      return [] unless @cachedAttach
      return [] unless @pathIsRendered
      count = Math.round (@$refs.path.getTotalLength() / 2 / 500)
      count = 4 if count > 4
      points = @pathPoints
      # i = 0
      # while i < curve.length
      #   points.push {x: curve[i], y: curve[i+1]}
      #   i = i+2
      if count < 2
        return [[points[0], points[1], points[2], points[3]], [points[3], points[4], points[5], points[6]]]

      splitCurveByCount = (p0, p1, p2, p3, level) =>
        result = []
        result.push ...@splitCurve p0, p1, p2, p3, 0.5
        i = level - 1
        while i > 0
          tmp = []
          for b in result
            tmp.push ...@splitCurve b[0], b[1], b[2], b[3], 0.5
          result = tmp
          i--

        return result

      lines1 = splitCurveByCount points[0], points[1], points[2], points[3], count
      lines2 = splitCurveByCount points[3], points[4], points[5], points[6], count

      allLines = []
      allLines.push(...lines1, ...lines2)

      return allLines

    webjet: ->
      @firebind @src
    collaborateDrawMode: -> @provideCanvas.collaborateDrawMode
    extremPoints: ->
      extremPointsCurves [@path]
    attach: ->
      # return true
      return true unless FLAG.RELATION_ATTACH
      return false unless @path
      { x1, y1, x2, y2 } = @extremPoints
      left = @provideCanvas.sceneX
      right = left + @provideCanvas.sceneWidth
      top = @provideCanvas.sceneY
      bottom = top + @provideCanvas.sceneHeight
      if x1 > right or x2 < left or y1 > bottom or y2 < top
        return false
      return true
    classes: ->
      return
        'no-animation': @drag.point or @dragStart.point
        'drag-start': @dragStart.point
        'no-event': @collaborateDrawMode
        'visible-relation': @selectedWebjetWithThisReleation
        'active-relation': @isSelected
        [@themeableClass]: @themeableClass
    backgroundId: ->
      @webjet?.data?.backgroundId
    isThemeable: -> true
    canTheme: -> true
    isThemed: ->
      @backgroundId and @backgroundId != '1'
    themeableClass: ->
      return false unless @isThemed
      switch @backgroundId
        when '2' then 'theme-default'
        when '3' then 'theme-gray'
        when '4' then 'theme-red'
        when '5' then 'theme-yellow'
        when '6' then 'theme-green'
        when '7' then 'theme-purple'
        when '8' then 'theme-blue'
        when '9' then 'theme-lightblue'
        else 'theme-unknown'
    textPointStyle: ->
      { x, y } = @pointsPosition[3]
      return
        transform: "translate(#{x}px, #{y}px)"
    textPointInStyle: ->
      color = @pathStrokeColor
      pathWidth = @pathWidth
      return
        'border-color': color
        'border-width': "#{pathWidth}px"
        'font-size': "#{11 + pathWidth}px"

    title: ->
      @webjet.data?.title if @src
    pathClass: ->
      if FLAG.RELATION_OPTIMIZATION_CSS
        opacityClass = 'no-opacity-path' if @deep <= 1
        opacityClass = 'normal-opacity-path' if @deep == 2
        opacityClass = 'big-opacity-path' if @deep == 3
        opacityClass = 'bigger-opacity-path' if @deep > 3
        # opacity = 0.6 / @deep if @deep > 1
        return
          [opacityClass]: opacityClass
          hovered: @hoverPath
          'visible-path': true

    pathStrokeColor: ->
      return false if FLAG.RELATION_OPTIMIZATION_CSS
      color = 'var(--r-dark)'
      color = 'var(--r-hover)' if @hoverPoint or @hoverPath
      color

    pathWidth: ->
      width = @webjet?.data?.width
      return 3 unless width
      width

    pathStyle: ->
      style = @webjet?.data?.style
      return 'line' unless style
      style

    dasharray: ->
      style = @pathStyle
      if !style or style == 'line'
        return ''
      else if style == 'dashed'
        width = @pathWidth
        style = "#{width * 1.5} #{width * 3}"
        return style
      else if style == 'dotted'
        width = @pathWidth
        style = "0 #{width * 2}"
        return style

    connectWebjets: ->
      point1 = null
      point2 = null
      if @drag.point == 1
        point1 = @webjetPoint
      else
        point1 = @points[1].originalWebjet

      if @drag.point == 2
        point2 = @webjetPoint
      else
        point2 = @points[2].originalWebjet
      [point1,point2]


    selectedWebjetWithThisReleation: ->
      return false unless @src
      @src in @provideCanvas.selectionRelationsAvtive
    relationPlusIsActive: ->
      @provideCanvas.relationPlusPoint.active and
      @provideCanvas.relationPlusPoint.relation == this
    isSelected: ->
      @$store.getters['webjet/relation/selected'] == this
    show: ->
      return false if @context and !@contextWebjet
      return false if !@pointsPosition[1] or !@pointsPosition[2]
      if @src and !@drag.point
        p1 = @points[1]
        p2 = @points[2]
        return (p1.originalWebjet or p1.webjet) and (p2.originalWebjet or p2.webjet)
      true
    showLine: ->
      return false if @pointData(1).webjet == @pointData(2).webjet
      true
    styleActiveDot: ->
      return opacity: 1 if @showLine
    visible: ->
      return @activeContext == @context or !@activeContext
    contextWebjet: ->
      return false unless @context
      selected = @$store.getters['webjet/selected']
      return false unless selected.length == 1 and selected[0].canRelationable
      selected[0]
    svgRect: ->
      for p in @pointsPosition
        if p
          x1 = p.x if !x1 or p.x < x1
          x2 = p.x if !x2 or p.x > x2
          y1 = p.y if !y1 or p.y < y1
          y2 = p.y if !y2 or p.y > y2
      x1 -= 1000 # add more if active state?
      x2 += 1000
      y1 -= 1000
      y2 += 1000
      { x1, y1, x2, y2 }

    pathPoints: ->
      p1 = @pointsPosition[1]
      p2 = @pointsPosition[2]
      p3 = @pointsPosition[3]

      self = @
      side1 = self.points[1].side
      side2 = self.points[2].side
      if self.context or self.shared
       if self.pointData(2).webjet != self.pointData(2).originalWebjet
          offsetN = Math.sqrt( Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2))
          offsetN = offsetN / 4
          p13 = p1
          p23 = p2
          switch side1
            when 'left'
              p13 = {x: p1.x - offsetN, y: p1.y }
              break
            when 'right'
              p13 = {x: p1.x + offsetN, y: p1.y }
              break
            when 'top'
              p13 = {x: p1.x, y: p1.y - offsetN}
              break
            when 'bottom'
              p13 = {x: p1.x, y: p1.y + offsetN}
              break


          switch side2
            when 'left'
              p23 = {x: p2.x - offsetN, y: p2.y }
              break
            when 'right'
              p23 = {x: p2.x + offsetN, y: p2.y }
              break
            when 'top'
              p23 = {x: p2.x, y: p2.y - offsetN}
              break
            when 'bottom'
              p23 = {x: p2.x, y: p2.y + offsetN}
              break

          p3 = {x: (p13.x + p23.x) / 2 , y: (p13.y + p23.y) / 2 }

      xC = (p1.x + p2.x) / 2
      yC = (p1.y + p2.y) / 2
      # p3 = {x: xC, y: yC}

      l1 = Math.sqrt( Math.pow(p3.x - p1.x, 2) + Math.pow(p3.y - p1.y, 2))
      l2 = Math.sqrt( Math.pow(p2.x - p3.x, 2) + Math.pow(p2.y - p3.y, 2))
      l3 = Math.sqrt( Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2))
      lf = l1 + l2

      cp = (point) ->
        p = self.pointsPosition[point]
        r = null
        side = self.pointData(point).side
        side = 'none' if !side
        offset1 = l3 / 3 * (l1 / l3)
        offset2 = l3 / 3 * (l2 / l3)
        offset = offset1 if point == 1
        if point == 2
          offset = offset2
          if self.context or self.shared
            offset = 0
          offset = offset2 if self.pointData(point).webjet and self.pointData(point).webjet != self.pointData(point).originalWebjet
        switch side
          when 'left'
            return r = {x: p.x - offset, y: p.y }
          when 'right'
            return r = {x: p.x + offset, y: p.y }
          when 'top'
            return r = {x: p.x, y: p.y - offset}
          when 'bottom'
            return r = {x: p.x, y: p.y + offset}
          when 'none'
            return p
      # path = "M#{p1.x} #{p1.y}"
      # Две кубических
      dAx = p1.x - p3.x
      dAy = p1.y - p3.y
      dBx = p1.x - p2.x
      dBy = p1.y - p2.y
      rp = 1
      angle = Math.atan2(dAx * dBy - dAy * dBx, dAx * dBx + dAy * dBy)

      dAx = p2.x - p3.x
      dAy = p2.y - p3.y
      dBx = p2.x - p1.x
      dBy = p2.y - p1.y
      rp = 1
      angle2 = Math.atan2(dAx * dBy - dAy * dBx, dAx * dBx + dAy * dBy)

      dAx = p3.x - p1.x
      dAy = p3.y - p1.y
      dBx = p3.x - p2.x
      dBy = p3.y - p2.y
      rp = 1
      angleM = Math.atan2(dAx * dBy - dAy * dBx, dAx * dBx + dAy * dBy)
      degree_angleMian = angleM * (180 / Math.PI)

      dm = 1
      dm = -1 if angleM < 0

      degree_angle = angle * (180 / Math.PI)
      d1 = 1
      d1 = -1 if degree_angle < 0
      degree_angle2 = angle2 * (180 / Math.PI)
      d2 = 1
      d2 = -1 if degree_angle2 < 0


      k1 = l1 / l3
      k2 = l2 / l3
      k3 = k1+k2

      sy = (90 + degree_angle/2) * Math.PI / 180

      ab = Math.sqrt(Math.pow(xC - p3.x, 2) + Math.pow(yC - p3.y, 2))

      ab = 1 if ab == 0

      alfa1 = 90
      alfa2 = 90

      angelTurn1 = (alfa1) * Math.PI / 180
      angelTurn2 = (alfa2) * Math.PI / 180

      v1 =
        {
          x: (p3.x - xC) / ab
          y: (p3.y - yC) / ab
        }
      v2 =
        {
          x: v1.x * Math.cos(angelTurn1) + -1 * v1.y * Math.sin(angelTurn1)
          y: v1.x * Math.sin(angelTurn1) + v1.y * Math.cos(angelTurn1)
        }
      v3 =
        {
          x: v1.x * Math.cos(angelTurn2) + v1.y * Math.sin(angelTurn2)
          y: -1 * v1.x * Math.sin(angelTurn2) + v1.y * Math.cos(angelTurn2)
        }

      vector = {
        x: (cp(2).x - cp(1).x) / l3
        y: (cp(2).y - cp(1).y) / l3
      }
      moduleAngelMain = Math.abs degree_angleMian

      if moduleAngelMain < 120 and moduleAngelMain > 70
        v = v3
        v = v2 if degree_angleMian < 0
        a = 120 - moduleAngelMain
        x = v.x - vector.x
        y = v.y - vector.y
        vector.x = Math.pow((vector.x + x * a / 50), 1)
        vector.y = Math.pow((vector.y + y * a / 50), 1)
      if moduleAngelMain < 70
        ys1 = p3.y + (l3 / 2 * k2) * v3.y * dm
        xs1 = p3.x + (l3 / 2 * k2) * v3.x * dm
        ys2 = p3.y + (l3 / 2 * k1) * v2.y * dm
        xs2 = p3.x + (l3 / 2 * k1) * v2.x * dm
      else
        ys1 = p3.y + (l3 / 2 * k2) * vector.y
        xs1 = p3.x + (l3 / 2 * k2) * vector.x
        ys2 = p3.y + (l3 / 2 * k1) * -vector.y
        xs2 = p3.x + (l3 / 2 * k1) * -vector.x
      return false unless vector.x or vector.y
      # return path = "M#{p1.x} #{p1.y} #{p2.x} #{p2.y}"
      return [
        { x: p1.x, y: p1.y}
        { x: cp(1).x, y: cp(1).y}
        { x: xs2, y: ys2}
        { x: p3.x, y: p3.y}
        { x: xs1, y: ys1}
        { x: cp(2).x, y: cp(2).y}
        { x: p2.x, y: p2.y}
      ]
      # return [ p1.x, p1.y, cp(1).x, cp(1).y, xs2, ys2, p3.x, p3.y, xs1, ys1, cp(2).x, cp(2).y, p2.x, p2.y]

      # return path += " C#{cp(1).x} #{cp(1).y} #{xs2} #{ys2} #{p3.x} #{p3.y} C#{xs1} #{ys1} #{cp(2).x} #{cp(2).y} #{p2.x} #{p2.y}"

      getCurvePoint = (t,points) ->
        return points[0] if points.length == 1
        newpoints = []
        i= 0
        j = 1
        while j < points.length
          newpoints[i] =
            {
              x: t * points[i].x + (1 - t) * points[j].x
              y: t * points[i].y + (1 - t) * points[j].y
            }
          i += 1
          j += 1
        return getCurvePoint(t,newpoints)
      centralPoint = (point) ->
        t = l1 / lf
        coordinate =
          {
            x: ((point.x / (6 * Math.pow(t,2)) * Math.pow((1 - t),2)) - (Math.pow(t,2)*p2.x) / (6 * Math.pow((1 - t),2)) - (2 * t * cp(2).x) / (3 * (1 - t)) - (2 * (1 - t) * cp(1).x) / (3 * t) - (Math.pow((1 - t),2) * p1.x) / (6 * Math.pow(t,2)))
            y: ((point.y / (6 * Math.pow(t,2)) * Math.pow((1 - t),2)) - (Math.pow(t,2)*p2.y) / (6 * Math.pow((1 - t),2)) - (2 * t * cp(2).y) / (3 * (1 - t)) - (2 * (1 - t) * cp(1).y) / (3 * t) - (Math.pow((1 - t),2) * p1.y) / (6 * Math.pow(t,2)))
          }

        return coordinate



      points =
        [
          p1
          cp(1)
          p3
          cp(2)
          p2
        ]
      t = 0.02
      step = 0.02
      while t <= 1
        cur = getCurvePoint(t,points)
        t += step
        t = +t.toFixed(2)
      # кубическая
      p1s = @pointData(1).originalSide
      p2s = @pointData(2).originalSide

      cp1 = cp(1)
      cp2 = cp(2)
      # path = "M#{p1.x} #{p1.y} C#{cp1.x} #{cp1.y} #{cp2.x} #{cp2.y} #{p2.x} #{p2.y}"
      # квадратичная

      t = (l1 * 100 / lf) / 100
      q =
        {
          x: (p3.x - Math.pow(t,2) * p1.x - p2.x * Math.pow(t,2)) / t
          y: (p3.y - Math.pow(t,2) * p1.y - p2.y * Math.pow(t,2)) / t
        }
      # path = "M#{p1.x} #{p1.y} Q#{q.x} #{q.y} #{p2.x} #{p2.y}"
      path
      #"M #{p1.x} #{p1.y} L #{p3.x} #{p3.y} L #{p2.x} #{p2.y}"
      # "M #{p1.x} #{p1.y} L #{p3.x} #{p3.y} L #{p2.x} #{p2.y} L #{p3.x} #{p3.y} L #{p1.x} #{p1.y}"
    pathsForMouse: ->
      return false unless @pathPoints
      pathOne = "M#{@pathPoints[0].x} #{@pathPoints[0].y} C#{@pathPoints[1].x} #{@pathPoints[1].y} #{@pathPoints[2].x} #{@pathPoints[2].y} #{@pathPoints[3].x} #{@pathPoints[3].y}"
      pathTwo = "M#{@pathPoints[3].x} #{@pathPoints[3].y} C#{@pathPoints[4].x} #{@pathPoints[4].y} #{@pathPoints[5].x} #{@pathPoints[5].y} #{@pathPoints[6].x} #{@pathPoints[6].y}"
      return [pathOne,pathTwo]
    path: ->
      return false unless @pathPoints
      return "M#{@pathPoints[0].x} #{@pathPoints[0].y} C#{@pathPoints[1].x} #{@pathPoints[1].y} #{@pathPoints[2].x} #{@pathPoints[2].y} #{@pathPoints[3].x} #{@pathPoints[3].y}
      C#{@pathPoints[4].x} #{@pathPoints[4].y} #{@pathPoints[5].x} #{@pathPoints[5].y} #{@pathPoints[6].x} #{@pathPoints[6].y}"
      # return "M#{@pathPoints[0]} #{@pathPoints[1]} C#{@pathPoints[2]} #{@pathPoints[3]} #{@pathPoints[4]} #{@pathPoints[5]} #{@pathPoints[6]} #{@pathPoints[7]}
      #  C#{@pathPoints[8]} #{@pathPoints[9]} #{@pathPoints[10]} #{@pathPoints[11]} #{@pathPoints[12]} #{@pathPoints[13]}"
    deep: ->
      result = 1
      p1 = @points[1]
      p2 = @points[2]
      result = p1.deep if p1.deep
      result = p2.deep if p2.deep and p2.deep > result
      result

    pathStyles: ->
      # rect = @svgRect
      return false if FLAG.RELATION_OPTIMIZATION_CSS
      opacity = 1 / @deep if @deep <= 1
      opacity = 0.6 / @deep if @deep > 1
      return
        # transform: "translate(#{-rect.x1}px, #{-rect.y1}px)"
        opacity: opacity
    svgStyles: ->
      return false
      # left: "#{rect.x1}px"
      # top: "#{rect.y1}px"
      # width: "#{rect.x2 - rect.x1}px"
      # height: "#{rect.y2 - rect.y1}px"

    menuStyles: ->
      p = @pointsPosition[3]
      scale = 1 / @provideCanvas.scale
      scale = 1 if scale < 1
      scale = 4 if scale > 4
      return
        left: "#{p.x}px"
        top: "#{p.y + 20 * scale}px"
        transform: "translateX(-50%) scale(#{scale})"

    points: ->
      [ null, @pointData(1), @pointData(2), @pointData(3) ]

    pointsPosition: ->
      [ null, @pointPosition(1), @pointPosition(2), @pointPosition(3) ]


  methods:
    onRelationPathDblclick: (e) ->
      return unless FLAG.GO_TO_WEBEJET_ON_RELATION_DBCLICK
      totalPoints = e.target.getTotalLength()
      point = null
      pointI = 0
      middlePoint = @pathPoints[3]
      middleT = null
      i = 0
      while i < totalPoints and !(point and middleT)

        p = e.target.getPointAtLength(i)
        #
        p.x = Math.round(p.x)
        p.y = Math.round(p.y)
        # console.log p, e.offsetX, e.offsetY
        offset = 12
        if p.x > e.offsetX - offset and p.x < e.offsetX + offset and
        p.y > e.offsetY - offset and p.y < e.offsetY + offset
          if !point
            point = p
            pointI = i
        if p.x > middlePoint.x - 2 and p.x < middlePoint.x + 2 and
        p.y > middlePoint.y - 2 and p.y < middlePoint.y + 2
          middleT = i
        i++
      nuberOfPart = if pointI < middleT then 1 else 0
      webjet = @connectWebjets[nuberOfPart]
      if webjet
        if @provideCanvas.userCanEdit
          @$store.dispatch 'webjet/setNowselected', { webjets: [webjet] }
          @$store.dispatch 'webjet/setPreselected', { webjets: [] }
          @$store.dispatch 'webjet/relation/setSelected', { webjet: null }
        unless @provideCanvas.presentationIsActive
          src = webjet?.webjet?.$path.split('/').slice(-2).join('/')
          @$router.replace path: @$router.path, query: w: src if src

    onInput: (e) ->
      oldText = @tmpText
      text = e.target.innerText
      if text.length > 25
        sel = window.getSelection()
        anchorOffset = sel.anchorOffset - 1
        e.target.innerText = oldText
        range = document.createRange()
        range.setStart(e.target.childNodes[0],anchorOffset)
        range.setEnd(e.target.childNodes[0], anchorOffset)
        range.collapse(false)
        sel.removeAllRanges()
        sel.addRange(range)
        e.target.focus()

      @tmpText = e.target.innerText
    onTitleDblClick: (e) ->
      return if @provideCanvas.isReadOnly
      return false unless @src
      @provideCanvas.arrowToolsPoint = null
      unless @isSelected
        @$store.dispatch 'webjet/relation/setSelected', { webjet: this }
        @$store.dispatch 'webjet/setNowselected', { webjets: [] }
      unless @activeEditTitle
        @activeEditTitle = true

    onPaste: (e) ->
      sel = window.getSelection()
      maxlength = 25 - e.target.innerText.length
      selOffset = sel.focusOffset - sel.anchorOffset
      # if selection from right to left (he goes from 0 to mines)
      selOffset = selOffset * -1 if selOffset < 0
      cutLength = maxlength + selOffset
      if cutLength > 0
        text = (e.originalEvent || e).clipboardData.getData 'text/plain'
        text = text.substring(0, cutLength)
        document.execCommand 'insertHTML', false, text
      e.preventDefault()
      e.stopPropagation()
    onEnter: (e) ->
      value = null
      value = e.target.innerText if e.target.innerText
      value = value.trim() if value
      @$store.dispatch 'webjet/relation/setTitle',
          path: @src
          title: value
      @activeEditTitle = false
      e.target.blur()
      text = @genTitle
    hotkeyBlocker: (e) ->
      # allow undo/redo
      if e.ctrlKey && e.keyCode in [ 89, 90 ]
        return
      # block other hotkeys
      e.stopPropagation()
      # block ctrl +/-/0
      if e.ctrlKey && e.keyCode in [ 187, 189, 48, 107, 109, 45, 43, 96 ]
        e.preventDefault()

    lerp: (a, b, t) ->
      s = 1 - t
      return {x:a.x*s + b.x*t, y:a.y*s + b.y*t}
    splitCurve: (p0,p1,p2,p3,t) ->
      p4 = @lerp(p0, p1, t)
      p5 = @lerp(p1, p2, t)
      p6 = @lerp(p2, p3, t)
      p7 = @lerp(p4, p5, t)
      p8 = @lerp(p5, p6, t)
      p9 = @lerp(p7, p8, t)
      firsthalf = [
        p0
        p4
        p7
        p9
      ]
      secondhalf = [
        p9
        p8
        p6
        p3
      ]
      return [firsthalf,secondhalf]
    segmentCurve: (x0, y0, x1, y1, x2, y2, x3, y3, d, result) ->
      px = (x3 - x0) / 3
      py = (y3 - y0) / 3
      mx1 = x1 - x0 - px
      my1 = y1 - y0 - py
      mx2 = x2 - x3 + px
      my2 = y2 - y3 + py
      d1 = Math.sqrt(Math.pow(mx1,2) + Math.pow(my1,2))
      d2 = Math.sqrt(Math.pow(mx2,2) + Math.pow(my2,2))
      if d1 < d and d2 < d
        result.push {x: x3, y: y3}
      else
        x01 = (x0 + x1) / 2
        y01 = (y0 + y1) / 2
        x12 = (x1 + x2) / 2
        y12 = (y1 + y2) / 2
        x23 = (x2 + x3) / 2
        y23 = (y2 + y3) / 2
        x012 = (x01 + x12) / 2
        y012 = (y01 + y12) / 2
        x123 = (x12 + x23) / 2
        y123 = (y12 + y23) / 2
        x0123 = (x012 + x123) / 2
        y0123 = (y012 + y123) / 2
        @segmentCurve(x0, y0, x01, y01, x012, y012, x0123, y0123, d, result)
        @segmentCurve(x0123, y0123, x123, y123, x23, y23, x3, y3, d, result)

      return result
    showArrowTools: (point) ->
      if @provideCanvas.arrowToolsPoint == point
        @provideCanvas.arrowToolsPoint = null
      else
        @provideCanvas.arrowToolsPoint = point


    sharedPoint: (point)->
      result = false
      data = wsBoard.get 'relationPoint'
      for k, p of data
        if p.src == @src and p.point == point
          result = p
      result

    pointData: (point)->
      result = {}
      if @src
        relationWebjet = @firebind @src
        if relationWebjet.$ready
          if point == 3 and relationWebjet.data?.x and relationWebjet.data?.y
            result = { x: relationWebjet.data.x, y: relationWebjet.data.y }
          else
            parents = relationWebjet.connections?.parents
            if parents
              for k, c of parents
                cid = c.binding.id
                connection = false
                src = c.src
                webjet = @firebind src
                if webjet.$ready and webjet.connections?.relations
                  for k, r of webjet.connections.relations
                    if r.id == cid
                      connection = r
                if connection and connection.point == point
                  result.originalSide = connection.side
                  result.originalSrc = src
                  originalWebjet = @provideCanvas.getWebjetComponentBySrc src
                  result.originalWebjet = originalWebjet if !originalWebjet?.isInTrash
                  result.relationConnectionPath = connection.$path
                  if result.originalWebjet?.provideWebjet
                    if connection.side == 'top'
                      result.originalSide = 'left'
                    else if connection.side == 'bottom'
                      result.originalSide = 'right'

      else if @shared
        if point == 1
          result.originalWebjet = @provideCanvas.getWebjetComponentBySrc @shared.contextSrc
          result.originalSrc = @shared.contextSrc
          result.originalSide = @shared.contextSide
        else if point == 2
          result.x = @shared.x
          result.y = @shared.y
          result.originalSide = @shared.side
          if @shared.webjetSrc
            result.originalSrc = @shared.webjetSrc
            result.originalWebjet = @provideCanvas.getWebjetComponentBySrc @shared.webjetSrc
        else
          console.warn 'strange shared point'

      else if @context
        result.originalWebjet = @contextWebjet
        result.originalSrc = @contextWebjet?.connection?.src
        result.originalSide = @context
      else
        console.warn 'relation: no webjet'
      result.webjet = result.originalWebjet
      result.side = result.originalSide
      result.src = result.originalSrc

      # point of real relation moving by another user
      if @src and @sharedPoint(point)
        #console.log 'finded'
        sharedPoint = @sharedPoint(point)
        result.x = sharedPoint.x
        result.y = sharedPoint.y
        if sharedPoint.webjetSrc
          result.webjet = @provideCanvas.getWebjetComponentBySrc sharedPoint.webjetSrc
        else
          result.webjet = false
        result.side = sharedPoint.side
        true

      # point moving by me
      if point == @drag.point
        result.webjet = @drag.webjet
        result.side = @drag.side
        result.src = result.webjet?.connection?.src
        result.x = @drag.x
        result.y = @drag.y

      # webjet inside another?
      if result.src and !result.webjet
        webjet = @firebind result.src
        if webjet.$ready
          parents = Object.values webjet.connections.parents
          if parents.length == 1
            webjet2 = @firebind parents[0].src
            if webjet2.$ready
              component = @provideCanvas.getWebjetComponentBySrc webjet2.$path
              if component and !component.isInTrash
                result.webjet = component
                result.deep = 2
              if !result.webjet
                parents = Object.values webjet2.connections.parents
                if parents.length == 1
                  webjet3 = @firebind parents[0].src
                  if webjet3.$ready
                    component = @provideCanvas.getWebjetComponentBySrc webjet3.$path
                    if component and !component.isInTrash
                      result.webjet = component
                      result.deep = 3
          else
            console.warn 'pointData: parents != 1'
      result

    pointPosition: (point)->
      data = @points[point]
      x = false
      y = false

      if data.webjet
        rect = data.webjet.elementSceneRect
        webjetCategory = data.webjet?.webjet?.category
        return false unless rect
        side = data.side
        borderOffset = 7 + @pathWidth / 2

        if side == 'left'
          x = rect.x1 - borderOffset
          if webjetCategory == '/webjets/content/webjets/shape'
            y = (rect.y2 + rect.y1) / 2
          else
            y = rect.y1 + 20
          # y = (rect.y2 + rect.y1) / 2
        else if side == 'right'
          x = rect.x2 + borderOffset
          if webjetCategory == '/webjets/content/webjets/shape'
            y = (rect.y2 + rect.y1) / 2
          else
            y = rect.y1 + 20
        else if side == 'top'
          x = (rect.x1 + rect.x2) / 2
          y = rect.y1 - borderOffset
        else if side == 'bottom'
          x = (rect.x1 + rect.x2) / 2
          y = rect.y2 + borderOffset
        else
          console.warn 'pointPosition: no side'
        if @context and !@drag.point and point == 2
          pad = 15 / @provideCanvas.scale
          pad = 15 if pad < 15
          pad = 100 if pad > 100
          x -= pad if @context == 'left'
          x += pad if @context == 'right'
          y -= pad if @context == 'top'
          y += pad if @context == 'bottom'
        # if @context or @shared
        #   if point == 1
      else

        x = data.x
        y = data.y
        if point == 3 and !x and !y
          p1 = @pointPosition 1
          p2 = @pointPosition 2
          l3 = Math.sqrt( Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2))
          side1 = @points[1].side
          side2 = @points[2].side
          offset = l3 / 4
          switch side1
            when 'left'
              p1 = {x: p1.x - offset, y: p1.y }
              break
            when 'right'
              p1 = {x: p1.x + offset, y: p1.y }
              break
            when 'top'
              p1 = {x: p1.x, y: p1.y - offset}
              break
            when 'bottom'
              p1 = {x: p1.x, y: p1.y + offset}
              break


          switch side2
            when 'left'
              p2 = {x: p2.x - offset, y: p2.y }
              break
            when 'right'
              p2 = {x: p2.x + offset, y: p2.y }
              break
            when 'top'
              p2 = {x: p2.x, y: p2.y - offset}
              break
            when 'bottom'
              p2 = {x: p2.x, y: p2.y + offset}
              break


          x = (p1.x + p2.x) / 2
          y = (p1.y + p2.y) / 2
      # console.log point, {x,y}
      { x, y }

    pointShow: (point)->
      # selected logic
      if @contextWebjet.provideWebjet
        if @context == 'top' or @context == 'bottom'
          return false
      return false if @context and !@drag.point and point == 1
      return false if @context and point == 3
      return false if @title and point == 3
      @isSelected or @context
      #true


    pointStyles: (point)->
      { x, y } = @pointsPosition[point]
      pathWidth = @pathWidth
      scale = 1.5 / @provideCanvas.scale
      scale = 1 if scale < 1
      scale = 4 if scale > 4
      scale *= 1.4 if @hoverPoint == point

      scale += pathWidth / 4
      data = @points[point]
      if @context == 'bottom' and @provideCanvas.plusIsActive and
      @provideCanvas.plusPoints[0]?.center? == false and !FLAG.WEBJET_PARENT
        parityColumn = @provideCanvas.plusPoints.length % 2 == 0
        y += 10 unless parityColumn
      scale = +scale.toFixed(1)
      opacity = 1 if @showLine
      opacity = @defaultPointOpacity if @context and @defaultPointOpacity != null and FLAG.RELATION_HIDDEN_POINT
      # console.log opacity
      return
        transform: "translate(#{x}px, #{y}px) scale(#{scale})"
        opacity: opacity if opacity or opacity == 0

    pointVisualStyle: (point) ->
      relationWebjet = @firebind @src
      style = relationWebjet?.data?[point]?.style
      return 'default' unless style
      style

    pointInStyle: (point) ->
      side = @points[point].side
      style = @pointVisualStyle point
      color = 'var(--r-dark)'
      color = 'var(--r-hover)' if @hoverPoint or @hoverPath or @isSelected

      if style == 'triangle'
        switch side
            when 'left'
              return
                'border-color': "transparent transparent transparent #{color}"
            when 'right'
              return
                'border-color': "transparent #{color} transparent transparent"
            when 'bottom'
              return
                'border-color': "transparent transparent #{color} transparent"
            when 'top'
              return
                'border-color': "#{color} transparent transparent transparent"
      else
        background: color




    pointClasses: (point)->
      side = @points[point].side
      style = @pointVisualStyle point
      return
        "#{style}": true
        "#{side}-side": side
        'default-point': !@src
        'active-r-tools': @relationPlusIsActive
        active: point == @drag.point and !@relationPlusIsActive or point == @dragStart.point and !@relationPlusIsActive
        'touch-active': point == @drag.point and @isTouch

    mousemove: (e)->

      if @context and @contextWebjet and FLAG.RELATION_HIDDEN_POINT
        cwRect = @contextWebjet.$el.getBoundingClientRect()
        d = (cwRect.x - e.x) / @provideCanvas.scale if @context == 'left'
        d = (cwRect.y - e.y) / @provideCanvas.scale if @context == 'top'
        d = ((cwRect.x + cwRect.width - e.x) / @provideCanvas.scale) * -1 if @context == 'right'
        d = ((cwRect.y + cwRect.height - e.y) / @provideCanvas.scale) * -1 if @context == 'bottom'
        # console.log e.x, cwRect.x + cwRect.width + 100
          # d = (cwRect.x - e.x) / @provideCanvas.scale
        if d <= 60 and d > -20 and e.x > cwRect.x - 60 and e.x < cwRect.x + cwRect.width + 60 and
        e.y > cwRect.y - 60 and e.y < cwRect.y + cwRect.height + 60
          if d > 25
            @defaultPointOpacityNum = (60 - d) / 35
          else
            @defaultPointOpacityNum = (d + 20) / 45
        else
          @defaultPointOpacityNum = 0
        # else if @context == 'right'
        #   if dx2 <= 60 and dx2 > -20
        #     if dx2 > 25
        #       @defaultPointOpacityNum = (60 - dx2) / 35
        #     else
        #       @defaultPointOpacityNum = (dx2 + 20) / 45
        #   else
        #     @defaultPointOpacityNum = 0
        # else if @context == 'top'
        #   if dy1 <= 60 and dy1 > -20
        #     if dy1 > 25
        #       @defaultPointOpacityNum = (60 - dy1) / 35
        #     else
        #       @defaultPointOpacityNum = (dy1 + 20) / 45
        #   else
        #     @defaultPointOpacityNum = 0
        # else if @context == 'bottom'
        #   if dy2 <= 60 and dy2 > -20
        #     if dy2 > 25
        #       @defaultPointOpacityNum = (60 - dy2) / 35
        #     else
        #       @defaultPointOpacityNum = (dy2 + 20) / 45
        #   else
        #     @defaultPointOpacityNum = 0

      # rectPoint = @$refs['default-point'].getBoundingClientRect()
      # rpx = rectPoint.x + rectPoint.width / 2
      # rpy = rectPoint.y + rectPoint.height / 2
      # d = Math.sqrt(Math.pow(rpx - e.x, 2) + Math.pow(rpy - e.y, 2))
      # console.log [rectPoint.x, rectPoint.y], [e.x,e.y]
      # if d < 50
      #   console.log d

      if @dragStart.point
        maxDelta = Math.floor(15 / @provideCanvas.scale)
        deltaX = Math.abs(@dragStart.x - (e.x / @provideCanvas.scale + @provideCanvas.scene.x1))
        deltaY = Math.abs(@dragStart.y - (e.y / @provideCanvas.scale + @provideCanvas.scene.y1))
        if @relationPlusIsActive
          @drag.point = false
          @dragStart.point = false
        else if deltaX > maxDelta or deltaY > maxDelta
          @drag.point = @dragStart.point
          @dragStart.point = false
        else
          @drag.point = false
      return if @relationPlusIsActive
      return unless @drag.point


      OffsetDragX = - @provideCanvas.$el.getBoundingClientRect().x
      OffsetDragX = 0 if window == e.target
      @drag.x = (e.x + OffsetDragX) / @provideCanvas.scale + @provideCanvas.scene.x1
      @drag.y = e.y / @provideCanvas.scale + @provideCanvas.scene.y1
      @resolveWebjet = false
      if @drag.point != 3
        event = new CustomEvent 'relation', { detail: { relation: this } } #, bubbles: true }

        elements = document.elementsFromPoint(e.x, e.y)
        # console.log elements
        elements = elements.filter (e)-> e.matches('.webjet') or e.matches('.svg-shape')

        connectEl = null
        for el in elements
          unless @resolveWebjet
            el.dispatchEvent event
            connectEl = el
        @webjetPoint = @resolveWebjet
      @drag.webjet = @resolveWebjet
      @drag.side = @resolveSide
      if @context
        @provideCanvas.relationContextActiveSide = @context
        @provideCanvas.activeContextRelation = this
      @provideCanvas.relationIsActive = true

      if @src
        wsBoard.share 'relationPoint',
          point: @drag.point
          src: @src
          webjetSrc: @drag.webjet?.connection?.src
          side: @drag.side
          x: @drag.x
          y: @drag.y
      else if @context
        wsBoard.share 'relation',
          contextSrc: @contextWebjet.connection.src
          contextSide: @context
          webjetSrc: @drag.webjet?.connection?.src
          side: @drag.side
          x: @drag.x
          y: @drag.y

    touchMove: (e)->
      if @dragStart.point
        maxDelta = Math.floor(15 / @provideCanvas.scale)
        deltaX = Math.abs(@dragStart.x - (e.changedTouches[0].clientX / @provideCanvas.scale + @provideCanvas.scene.x1))
        deltaY = Math.abs(@dragStart.y - (e.changedTouches[0].clientY / @provideCanvas.scale + @provideCanvas.scene.y1))
        if @relationPlusIsActive
          @drag.point = false
          @dragStart.point = false
        else if deltaX > maxDelta or deltaY > maxDelta
          @drag.point = @dragStart.point
          @dragStart.point = false
        else
          @drag.point = false
      return unless @drag.point
      return if @relationPlusIsActive
      e.stopPropagation()
      e.preventDefault()
      OffsetDragX = - @provideCanvas.$el.getBoundingClientRect().x
      OffsetDragX = 0 if window == e.target
      @drag.x = (e.changedTouches[0].clientX + OffsetDragX) / @provideCanvas.scale + @provideCanvas.scene.x1
      @drag.y = e.changedTouches[0].clientY / @provideCanvas.scale + @provideCanvas.scene.y1
      p = {
        x: e.changedTouches[0].clientX
        y: e.changedTouches[0].clientY
      }

      @resolveWebjet = false
      if @context
        @provideCanvas.relationContextActiveSide = @context
        @provideCanvas.activeContextRelation = this
      @provideCanvas.relationIsActive = true
      if @drag.point != 3

        event = new CustomEvent 'relation', { detail: { relation: this } } #, bubbles: true }
        elements = document.elementsFromPoint(p.x, p.y)

        elements = elements.filter (e)-> e.matches('.webjet') or e.matches('.svg-shape')
        @dropElements = elements unless @toDrop
        elements = @dropElements if @toDrop

        for el in elements
          unless @resolveWebjet
            el.dispatchEvent event

        @webjetPoint = @resolveWebjet
      @drag.webjet = @resolveWebjet
      @drag.side = @resolveSide
      if @src
        wsBoard.share 'relationPoint',
          point: @drag.point
          src: @src
          webjetSrc: @drag.webjet?.connection?.src
          side: @drag.side
          x: @drag.x
          y: @drag.y
      else if @context
        wsBoard.share 'relation',
          contextSrc: @contextWebjet.connection.src
          contextSide: @context
          webjetSrc: @drag.webjet?.connection?.src
          side: @drag.side
          x: @drag.x
          y: @drag.y

    resolve: (webjet, side)->
      return if @drag.point == 1 and @points[2].originalWebjet == webjet
      return if @drag.point == 2 and @points[1].originalWebjet == webjet
      @resolveWebjet = webjet
      @resolveSide = side

    pointDrag: (e, point)->
      return if e.which != 1 or e.touches?
      unless point != 3
        @provideCanvas.arrowToolsPoint = null
      @drag.point = point
      if point
        @dragStart.point = point
        @dragStart.x = e.x / @provideCanvas.scale + @provideCanvas.scene.x1
        @dragStart.y = e.y / @provideCanvas.scale + @provideCanvas.scene.y1
      @mousemove(e)

    pointDragTouch: (e, point)->
      e.preventGrabTouch = true
      setTimeout (=>
        @drag.point = point
        @isTouch = true
        if point != 3
          @dragStart.point = point
          @dragStart.x = e.canvasX / @provideCanvas.scale + @provideCanvas.scene.x1
          @dragStart.y = e.canvasY / @provideCanvas.scale + @provideCanvas.scene.y1
        @touchMove(e)
      ), 0
    pointDropTouch: (e)->
      if @dragStart.point
        @showArrowTools(@dragStart.point)
      @dragStart.point = false
      return unless @drag.point
      @toDrop = true
      @touchMove(e)
      if @context and @drag.webjet
        newRelation = await @$store.dispatch 'webjet/relation/create',
          boardPath: @provideCanvas.connection.src
          src1: @contextWebjet.connection.src
          src2: @drag.webjet.connection.src
          side1: @context
          side2: @drag.side
          data: {data: Object.assign {}, @provideCanvas.lastRelationConfig}
        newRelationComponent =  @provideCanvas.getRelationComponentBySrc newRelation.webjetPath
        @$store.dispatch 'webjet/relation/setSelected', { webjet: newRelationComponent }
        @$store.dispatch 'webjet/setNowselected', { webjets: [] }
        @drag.point = false
      else if !@context and @src and @drag.webjet
        data = @pointData(@drag.point)
        @$store.dispatch 'webjet/relation/move',
          path: data.relationConnectionPath
          dest: @drag.webjet.connection.src
          side: @drag.side
        @drag.point = false
      else if @src and @drag.point == 3
        @$store.dispatch 'webjet/relation/setPosition',
          path: @src
          x: @drag.x
          y: @drag.y
        @drag.point = false
      else if @context and !@drag.webjet and @drag.point != 3
        if @provideCanvas.relationPlusPoint.active
          @provideCanvas.relationPlusPoint.relation.drag.point  = false
        @provideCanvas.relationPlusPoint = {
            active: true
            x: @drag.x
            y: @drag.y
            type: 'create'
            webjet: @contextWebjet
            side: @activeContext
            relation: this
          }
      else if !@context and @src and !@drag.webjet and @drag.point != 3
        data = @pointData(@drag.point)
        @provideCanvas.relationPlusPoint = {
            active: true
            x: @drag.x
            y: @drag.y
            data: data
            type: 'move'
            side: data.originalSide
            relation: this
          }
      else
        @drag.point = false
      @toDrop = false
      @isTouch = false
      if @context
        @provideCanvas.relationContextActiveSide = null
        @provideCanvas.activeContextRelation = null
      @provideCanvas.relationIsActive = false
      wsBoard.share 'relationPoint', null
      wsBoard.share 'relation', null

    pointDrop: (e)->
      if @dragStart.point and @dragStart.point != 3
        @showArrowTools(@dragStart.point)
      if @dragStart.point == 3
        titleEdit = document.querySelector('.relation-tools .relation-title input')
        if titleEdit?
          titleEdit.focus()
      @dragStart.point = false
      return unless @drag.point
      return if @relationPlusIsActive
      @mousemove(e)

      @dragStart.point = false
      if @context and @drag.webjet
        newRelation = await @$store.dispatch 'webjet/relation/create',
          boardPath: @provideCanvas.connection.src
          src1: @contextWebjet.connection.src
          src2: @drag.webjet.connection.src
          side1: @context
          side2: @drag.side
          data: {data: Object.assign {}, @provideCanvas.lastRelationConfig}
        newRelationComponent =  @provideCanvas.getRelationComponentBySrc newRelation.webjetPath
        @$store.dispatch 'webjet/relation/setSelected', { webjet: newRelationComponent }
        @$store.dispatch 'webjet/setNowselected', { webjets: [] }
        @drag.point = false
      else if !@context and @src and @drag.webjet
        data = @pointData(@drag.point)
        @$store.dispatch 'webjet/relation/move',
          path: data.relationConnectionPath
          dest: @drag.webjet.connection.src
          side: @drag.side
        @drag.point = false
      else if @src and @drag.point == 3
        @$store.dispatch 'webjet/relation/setPosition',
          path: @src
          x: @drag.x
          y: @drag.y
        @drag.point = false
      else if @context and !@drag.webjet and @drag.point != 3

        if @provideCanvas.relationPlusPoint.active
          @provideCanvas.relationPlusPoint.relation.drag.point  = false
        @provideCanvas.relationPlusPoint = {
            active: true
            x: @drag.x
            y: @drag.y
            type: 'create'
            webjet: @contextWebjet
            side: @activeContext
            relation: this
          }
        # @drag.point = false
      else if !@context and @src and !@drag.webjet and @drag.point != 3
        data = @pointData(@drag.point)
        @provideCanvas.relationPlusPoint = {
            active: true
            x: @drag.x
            y: @drag.y
            data: data
            type: 'move'
            side: data.originalSide
            relation: this
          }

      else
        @drag.point = false


      if @context
        @provideCanvas.relationContextActiveSide = null
        @provideCanvas.activeContextRelation = null
      @provideCanvas.relationIsActive = false
      wsBoard.share 'relationPoint', null
      wsBoard.share 'relation', null
    pointsOrderRevers: ->
      p1 = @pointsPosition[1]
      p2 = @pointsPosition[2]
      if p1.x == p2.x
        y = p2.y - p1.y
        return true if y < 0
        return false
      else
        x = p2.x - p1.x
        return true if x < 0
        return false
    rejected: (e) ->
      if !e.target.matches('.relation *') and !e.target.matches('.relation-tools *') and !e.target.matches('.relation-arrow-tools *')
        @$store.dispatch 'webjet/relation/setSelected', { webjet: null  }
    select: ->
      return if @provideCanvas.isReadOnly
      return false unless @src
      @provideCanvas.arrowToolsPoint = null
      @$store.dispatch 'webjet/relation/setSelected', { webjet: this }
      @$store.dispatch 'webjet/setNowselected', { webjets: [] }

    remove: ->
      @$store.dispatch 'webjet/relation/remove', { path: @src }
      @$store.dispatch 'webjet/relation/setSelected', { webjet: null }
    keydown: (e) ->
      return unless @isSelected
      if e.which == 46 or e.which == 8
        @remove()
      else if e.which == 27
        @$store.dispatch 'webjet/relation/setSelected', { webjet: null  }
  watch:
    activeEditTitle: (val, oldVal) ->
      if val and val != oldVal
        @$nextTick =>
          range = document.createRange()
          sel = window.getSelection()
          sel.removeAllRanges()
          range.selectNodeContents(@$refs.title)
          sel.addRange(range)
          @$refs.title.focus()
    attach: ->
      @cachedAttach = @attach
    isSelected: (val,oldVal) ->
      if val
        window.addEventListener 'mousemove', @mousemove
        window.addEventListener 'mouseup', @pointDrop
        window.addEventListener 'touchmove', @touchMove, { passive: false }
        window.addEventListener 'touchend', @pointDropTouch, { passive: false }
      else
        @$refs.title.blur() if @$refs.title
        window.removeEventListener 'mousemove', @mousemove
        window.removeEventListener 'mouseup', @pointDrop
        window.removeEventListener 'touchmove', @touchMove
        window.removeEventListener 'touchend', @pointDropTouch
      if oldVal and !val
        @drag.point = false
        @provideCanvas.relationPlusPoint = false
  created: ->
    # document.addEventListener 'click', @rejected
    if @context
      window.addEventListener 'mousemove', @mousemove
      window.addEventListener 'mouseup', @pointDrop
      window.addEventListener 'touchmove', @touchMove, { passive: false }
      window.addEventListener 'touchend', @pointDropTouch, { passive: false }
    document.addEventListener 'keydown', @keydown

    if @src
      @provideCanvas.registerRelation this
  destroyed: ->
    # document.removeEventListener 'click', @rejected
    window.removeEventListener 'mousemove', @mousemove
    window.removeEventListener 'mouseup', @pointDrop
    window.removeEventListener 'touchmove', @touchMove
    window.removeEventListener 'touchend', @pointDropTouch
    document.removeEventListener 'keydown', @keydown
    if @provideCanvas.relationPlusPoint.relation == this
      @provideCanvas.relationPlusPoint.active = false
    if @src
      @provideCanvas.unregisterRelation this
  mounted: ->
    @cachedAttach = @attach
  updated: ->
    if @$refs.path
      @pathIsRendered = true
    else
      @pathIsRendered = false
