



















export default
  name: 'WHeader'
  inject: ['provideWebjet']
  props:
    icon: String
  data: ->
    editTitle: false
  computed:
    activeEditTitle: ->
      @provideWebjet.headEditTitle
    title: ->
      title = ''
      title = @provideWebjet.webjet?.data?.title if @provideWebjet.webjet?.data?.title
      title
    canOpenOriginal: ->
      category = true if @provideWebjet.webjet.category in [
        '/webjets/content/webjets/photo'
        '/webjets/content/webjets/html5video'
        '/webjets/content/webjets/audio'
        '/webjets/content/webjets/pdf'
        '/webjets/content/webjets/embed'
        '/webjets/content/webjets/link'
        'traidingview'
      ]
      return false unless category
      return false if @provideWebjet.webjet.meta?.isInStorage
      return false if @provideWebjet.webjet.meta?.uploadInProgress
      return false if @provideWebjet.webjet.meta?.uploadError
      return true
    originalUrl: ->
      return @provideWebjet.webjet.data?.origin || @provideWebjet.webjet.data?.url
    canOpenPDF: ->
      if @provideWebjet.webjet.category == '/webjets/content/webjets/pdf' and @provideWebjet.webjet.meta?.isInStorage
        return true
      else
        return false
    canOpenViewer: ->
      if @provideWebjet.webjet.category == '/webjets/content/webjets/embed' and @provideWebjet.webjet.meta?.isInStorage
        return true
      else
        return false
    styles: ->
      s = {}
      s['background-image'] = "url(#{@icon})" if @icon
      return s
    genTitle: ->
      if @provideWebjet.webjet.data?.title
        @provideWebjet.webjet.data.title
      else
        @provideWebjet.titlePlaceholder
    placeholder: ->
      @provideWebjet.titlePlaceholder
    isDataTitle: -> @provideWebjet.webjet?.data?.title

  methods:
    dragstartIcon: (e) ->
      e.webjetUid = @provideWebjet._uid
    onPaste: (e) ->
      text = (e.originalEvent || e).clipboardData.getData 'text/plain'
      document.execCommand 'insertHTML', false, text
      e.preventDefault()
      e.stopPropagation()
    blur: (e) ->
      if @provideWebjet.headEditTitle
        @onEnter(e)
    onEnter: (e) ->
      value = null
      value = e.target.innerText if e.target.innerText
      @$store.dispatch 'webjet/updateTitle',
        path: @provideWebjet.webjet.$path
        value: value
      @provideWebjet.headEditTitle = false
      e.target.blur()
      e.target.innerText = @genTitle
    mouseDownOutTitle: (e) ->
      if e.target != @$refs.title
        @$refs.title.blur()
      else
        @provideWebjet.preventDraggable = true
    touchStartOutTitle: (e) ->
      if e.target != @$refs.title
        @$refs.title.blur()
    onTitleDblClick: ->
      selection = @$store.getters['webjet/selected']
      if selection?.length == 1 and selection[0] == @provideWebjet
        titleEdit = document.querySelector('.contextmenu .cardtitle input')
        if titleEdit?
          titleEdit.focus()
    onTitleDblClickEdit: ->
      return if @provideWebjet.isReadOnly or @provideWebjet.isInTrash
      @provideWebjet.headEditTitle = true
    hotkeyBlocker: (e) ->
      # allow undo/redo
      if e.ctrlKey && e.keyCode in [ 89, 90 ]
        return
      # block other hotkeys
      e.stopPropagation()
      # block ctrl +/-/0
      if e.ctrlKey && e.keyCode in [ 187, 189, 48, 107, 109, 45, 43, 96 ]
        e.preventDefault()
  watch:
    activeEditTitle: (val, oldVal) ->
      if val and val != oldVal
        @$nextTick =>
          range = document.createRange()
          sel = window.getSelection()
          sel.removeAllRanges()
          range.selectNodeContents(@$refs.title)
          sel.addRange(range)
          @$refs.title.focus()
          document.addEventListener 'mousedown', @mouseDownOutTitle, {capture:true}
          document.addEventListener 'touchstart', @touchStartOutTitle
      else if !val and val != oldVal
        @provideWebjet.preventDraggable = false
        document.removeEventListener 'mousedown', @mouseDownOutTitle, {capture:true}
        document.removeEventListener 'touchstart', @touchStartOutTitle

