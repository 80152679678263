







export default
  name: 'WChilds'
  inject: ['provideWebjet']
  props:
    column: Number
  computed:
    childItems: ->
      if @column
        @provideWebjet.childItems.filter (c)=> c.columnKey == @column
      else if @column == 0
        @provideWebjet.childItems.filter (c)=> !c.columnKey
      else
        @provideWebjet.childItems
    isNestedLoop: -> @provideWebjet.isNestedLoop
    hasChilds: ->
      @childItems.length > 0

