import Vue from 'vue'

Vue.prototype.isDevelopment = IS_DEVELOPMENT
Vue.prototype.isStage = IS_STAGE
Vue.prototype.isProduction = IS_PRODUCTION

Vue.prototype.IS_DEVELOPMENT = IS_DEVELOPMENT
Vue.prototype.IS_STAGE = IS_STAGE
Vue.prototype.IS_PRODUCTION = IS_PRODUCTION
Vue.prototype.FLAG = FLAG

Vue.prototype.$pp = (obj)-> JSON.stringify(obj,0,2)
