
















import { usualMixinPack } from './mixins'

apiKey = "AIzaSyDqEDGny8cObvbLWn6iHX8P8kt1KSOGbAA"

export default
  name: 'WebjetYoutubeVideo'
  mixins: [...usualMixinPack]
  data: ->
    play: false
  computed:
    data: ->
      @webjet?.data||{}
    meta: ->
      @webjet?.meta||{}
    url: ->
      @data.url || "https://www.youtube.com/embed/#{@data.id}?enablejsapi=1&rel=0&showinfo=0"
      #"#{u}&autoplay=1&mute=1"
    icon: ->
      @data.icon
    thumbnail: ->
      @data.thumbnail
    thumbnailStyles: ->
      s = {}
      s['background-image'] = "url(#{@thumbnail})" if @thumbnail
      return s
  watch:
    isOnlySelected: (v) ->
      if v && @isExpanded
        @play = true
    isExpanded: (v) ->
      if v && @isOnlySelected
        @play = true
      else if !v
        @play = false
  methods:
    loadData: ->
      #console.log '!yt load data'
      fetch(
        "https://www.googleapis.com/youtube/v3/videos?part=snippet,statistics&id=#{@data.id}&key=#{apiKey}"
      ).then(
        (res) ->
          return unless res
          if res.ok
            return res.json()
          else
            console.error "youtube api error status", res.status, res.statusText
      ).then(
        (res) =>
          return unless res
          #console.log res.items[0]
          snippet = res.items[0].snippet
          update =
            titleOrigin:  snippet.title
            description:  snippet.description
            icon:         snippet.thumbnails.default.url
            thumbnail:    snippet.thumbnails.high.url
          #console.log '>', update
          @$store.dispatch 'webjet/refreshData',
            path: @connection.src
            data: update
      )

  mounted: ->
    #console.log '!yt mounted', @connection.src, @meta.refreshDate, @meta
    if (
      !@meta.refreshDate and
      not (@meta.isInStorage || @meta.uploadInProgress || @meta.uploadError) and
      (new Date @meta.createDate).getTime() > 1618185600000 and
      @meta.createUser == @$store.state.uid
    )
      # 1618185600000 is "2021-04-12T00:00:00.000Z", date of first weje release
      # links from old webjets app had no refreshDate, so we need exclude them this way
      setTimeout => @loadData()

