import { firebind } from '@/utils'

export default
  unfreshWebjet: (_, { boardId, webjetId })->
    fresh = await firebind "fresh/#{boardId}"
    freshCount = await firebind "freshCount"
    if fresh[webjetId]
      count = Object.keys(fresh).length - 1
      @commit 'fresh/UNFRESH_WEBJET', { boardId, webjetId, count }

  unfreshBoard: (_, { boardId })->
    @commit 'fresh/UNFRESH_BOARD', { boardId }
