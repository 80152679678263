






























































import ContextBtn from './ContextBtn'
export default
  name: 'GraffitiSizeType'
  components: { ContextBtn }
  inject: { provideCanvas: { default: null }, 'provideApp'}
  priority: 10000

  if: (selection)->
    return false unless FLAG.GRAFFITI_TOOLS_TO_CONTEXT_MENU
    selection.length == 1 and selection[0].$options.name == 'WebjetGraffiti'

  computed:
    selection: -> @$store.getters['webjet/selected']
    title: ->
      ''
    sizeType: ->
      type = @selection[0].webjet?.data?.sizeType
      return 0 unless type
      type
    themeDark: ->
      @provideApp.themeDark
  methods:
    action: (type) ->
      @selection[0].$refs.editor.graffitiTypes.pen.width = 10
      console.log @selection[0].$refs.editor.graffitiTypes.pen
      @$store.dispatch 'webjet/setGraffitiSizeType',
        path: @selection[0].webjet.$path
        value: type
