


















































































































export default
  name: 'GraffitiTools'
  inject: ['provideCanvas']
  data: ->
    rect: null

  computed:
    grid: ->
      grid = @selection[0].webjet?.data?.grid
      return true if grid == undefined
      grid
    width: ->
      @provideCanvas.graffitiWidth
    type: ->
      @provideCanvas.graffitiType
    color: ->
      @provideCanvas.graffitiColor
    sizeType: ->
      type = @selection[0].webjet?.data?.sizeType
      return 0 unless type
      type
    positionGraffitiTools: ->
      return { left: @left, top: @top,bottom: 'auto' }
    selection: ->
      @$store.getters['webjet/selected']
    indexSelection: ->
      if @selection[@selection.length - 1]?.genShow
        return @selection.length - 1
      index = 0
      for w,i in @selection
        break if !w.genShow
        index = i
      index
    isInInventory: ->
      return true if @selection[@indexSelection].provideSidebar
    left: ->
      if @isInInventory and @rect
        rectW = @rect?.width
        x = @$vuetify.breakpoint.width - 15
        return "#{x}px"
      if @provideCanvas.showGraffitiTools and @rect and @selection[@indexSelection].genShow
        cpx = @provideCanvas.position.x
        czx = @provideCanvas.zoom.dx
        ex1 = @selection[@indexSelection].elementSceneRect.x1
        ex2 = @selection[@indexSelection].elementSceneRect.x2
        scale = @provideCanvas.scale
        x = Math.round(cpx) + czx + ex1 * scale + (ex2 - ex1) / 2 * scale
        if x < (@rect.width + 40) / 2
          x = (@rect.width + 60) / 2
        if x > @$vuetify.breakpoint.width - (@rect.width + 40) / 2
          x = @$vuetify.breakpoint.width - (@rect.width + 60) / 2
        return "#{x}px"

    top: ->
      if @isInInventory and @rect
        rectH = @rect?.height
        x = @$vuetify.breakpoint.height - (rectH / 2) - 95
        return "#{x}px"
      if @provideCanvas.showGraffitiTools and @rect and @selection[@indexSelection].genShow
        cpy = @provideCanvas.position.y
        czy = @provideCanvas.zoom.dy
        ey1 = @selection[@indexSelection].elementSceneRect.y1
        ey2 = @selection[@indexSelection].elementSceneRect.y2
        scale = @provideCanvas.scale
        offset = 40 * scale
        offset = 30 if offset < 30
        offset = 170 if offset > 170
        y = cpy + czy + ey1 * scale
        y = Math.round(y) - @rect.height - offset
        y = y + @rect.height + offset * 2 + (ey2 - ey1) * scale
        if y > @$vuetify.breakpoint.height - @rect.height - offset
          # y = offset
          y = cpy + czy + ey1 * scale
          y = Math.round(y) - @rect.height - offset
        if y < 0
          y = 10


        return "#{y}px"
  methods:
    setSizeType: (type) ->
      @$store.dispatch 'webjet/setGraffitiSizeType',
          path: @selection[0].webjet.$path
          value: type
    setGrid: (grid) ->
      @$store.dispatch 'webjet/setGraffitiGrid',
          path: @selection[0].webjet.$path
          value: grid
    setWidth: (width) ->
      @$set @provideCanvas.graffitiTypes[@type], 'width', width
    setType: (type) ->
      @provideCanvas.graffitiType = type
    typeIcon: (icon) ->
      if icon == 'lastic'
        return '<svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.065 8.0514C18.6231 7.4933 18.6231 6.65615 18.065 6.09805L14.1583 2.12158C13.6002 1.56348 12.7631 1.56348 12.205 2.12158L2.01965 12.2372C1.46155 12.7953 1.46155 13.6324 2.01965 14.1905L4.67063 16.8415H9.27496L18.065 8.0514Z" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M7.25195 7.07471L13.112 12.9348" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M2.02026 21.5623H17.368" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>'
      else if icon == 'pen'
        return '<svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.70578 15.2684L7.96159 19.5622M3.70578 15.2684L13.7204 5.3713M3.70578 15.2684L1.78687 21.0632L7.96159 19.5622M7.96159 19.5622L18.1133 9.49989M13.7204 5.3713L16.6632 2.46301L20.919 6.71882L18.1133 9.49989M13.7204 5.3713L18.1133 9.49989" stroke="currentColor" stroke-width="2"/><path d="M7.08777 7.47875L13.0535 1.703L15.1814 3.86891" stroke="currentColor" stroke-width="2"/></svg>'
      else if icon == 'marker'
        return '<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.125 4.93746L17.0678 2.02917L22.8096 7.71832L20.0039 10.4994M14.125 4.93746L4.11035 14.8346L9.85221 20.5617L20.0039 10.4994M14.125 4.93746L20.0039 10.4994" stroke="currentColor" stroke-width="2"/><path d="M6.78128 21.8282L4.52608 19.4979M2.48437 17.2969L4.52608 19.4979M4.52608 19.4979L2.35501 21.6257" stroke="currentColor" stroke-width="2"/></svg>'
    setColor: (color) ->
      @$set @provideCanvas.graffitiTypes[@type], 'color', color
    setRect: ->
      if @selection[@indexSelection]?.genShow and @provideCanvas.showGraffitiTools
        @rect = @$refs.graffititools.getBoundingClientRect()

  mounted: ->
    requestAnimationFrame(@setRect)
