





import ContextBtn from './ContextBtn'
export default
  name: 'SelectionCount'
  components: { ContextBtn }
  priority: 100000

  if: (selection)->
    selection.length > 1

  computed:
    selection: -> @$store.getters['webjet/selected']

    selectionCount: ->
      @selection.length

  methods:
    action: ->
      # switch selection groups
