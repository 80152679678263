



















import ModalImgCropper from '@/components/ImgCropper/ModalImgCropper'
export default
  name:
    "AccountImg"
  data: ->
    openModalCropper: false
    imgFile: null
    dragOverImg: false
  #data: ->
  #  file: undefined
  components:
    {
      ModalImgCropper
     }
  firebind:
    userData: ->
      '/users/' + @$store.state.uid

  computed:
    avatar: -> @userData?.avatar
    avatarFile: -> @userData?.avatarFile
    #  if @file is null
    #    return undefined
    #  else if @file
    #    return URL.createObjectURL(@file)
    #  else
    #    return @userData.avatar
  watch:
    openModalCropper: (val) ->
      unless val
        @$refs.avatarUpload.value = null
  methods:
    drop: (e) ->
      e.preventDefault()
      e.stopPropagation()
      @dragOverImg = false
      file = e.dataTransfer?.files?[0]
      imageType = /image.*/
      isImg = file?.type.match imageType
      if file and isImg
        @imgFile = file
        @openModalCrop()
    dragover: (e) ->
      @dragOverImg = true
      e.preventDefault()
    dragleave: (e) ->
      @dragOverImg = false
    openModalCrop: ->
      @openModalCropper = true
    openUpload: ->
      input = @$el.querySelector 'input.avatar-upload'
      if input
        input.click()
    processCrop: ->
      input = @$el.querySelector 'input.avatar-upload'
      if input?.files?.length > 0
        @imgFile = input.files[0]
        @openModalCrop()
    uploadCroppedImg: (img) ->
      if img
        @$store.dispatch 'user/setAvatar', { file: img }
    processUpload: ->
      input = @$el.querySelector 'input.avatar-upload'
      if input?.files?.length > 0
        #@file = input.files[0]
        @$store.dispatch 'user/setAvatar', { file: input.files[0] }
    remove: ->
      #@file = null
      input = @$el.querySelector 'input.avatar-upload'
      if input
        input.value = null
      @$store.dispatch 'user/removeAvatar'
