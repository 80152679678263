import { operationActions } from '@/store/operation'

import mutations from './paint-mutations'
import operations from './paint-operations'

actions =
  setSelected: ({ state }, { webjet })->
    state.selected = webjet

export default
  namespaced: true
  state:
    selected: false
  getters:
    selected: (state)-> state.selected
  mutations: mutations
  actions: Object.assign {}, actions, operationActions operations