




import { userColor } from '@/utils'

export default
  name: 'SharedScene'
  inject: ['provideCanvas']
  props: ['scene']
  computed:
    styles: ->
      return
        left: @scene.x1 + 'px'
        top: @scene.y1 + 'px'
        width: @scene.x2 - @scene.x1 + 'px'
        height: @scene.y2 - @scene.y1 + 'px'
        'outline-color': userColor(@scene.$uid)
