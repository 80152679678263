








import ContextBtn from './ContextBtn'
import { getWebjetForText } from '@/utils'
import { generateSort } from '@/utils'
export default
  name: 'TranscribeVoice'
  components: { ContextBtn }
  inject: { provideCanvas: { default: null } }
  if: (selection)->
    voiceGroup = []
    getVoiceWebjet = (s) =>
      if s.webjet.category == 'voice'
        voiceGroup.push s

      if s.childWebjetComponents.length
        for w in s.childWebjetComponents
          getVoiceWebjet w

    selection.forEach (s) => getVoiceWebjet s
    FLAG.VOICE_TRANSCRIPTION and voiceGroup.length > 1
  data: ->
    voiceGroup: []
  mounted: ->
    @selection.forEach (s) => @getVoiceWebjet s
  computed:
    selection: -> @$store.getters['webjet/selected']
  methods:
    action: ->
      @voiceGroup.forEach (s) =>
        s.status = 'transcribing'
        if s.webjet.data.transcription
          @createCard s.webjet.data.transcription, s
        else
          @getTranscription s

    getVoiceWebjet: (s) ->
      if s.webjet.category == 'voice'
          @voiceGroup.push s

      if s.childWebjetComponents.length
        for w in s.childWebjetComponents
          @getVoiceWebjet w

    getTranscription: (webjet) ->
      fetch('https://service.speech.stage.weje.io/transcribe',
        method: 'POST'
        headers: 'Content-Type': 'application/json'
        body: JSON.stringify(url: webjet.webjet.data.url)
      ).then((res) ->
        res.json()
      ).then(({ transcription }) =>
        @$store.dispatch 'webjet/setVoiceTranscription',
          path: webjet.webjet?.$path,
          transcription: transcription
        @createCard transcription, webjet
      ).catch (error) =>
        console.warn error
        @provideCanvas.showCaution
          message: "Unable to transcribe audio"
          loader: false
        webjet.status = 'play'

    createCard: (transcription, webjet)->
      provideWebjet = webjet.provideWebjet
      hasCanvas = webjet?.provideCanvas
      inInventory = webjet?.provideBoardSrc == 'inventory'
      provideWebjetIsInventory = provideWebjet?.$options?.name == 'WebjetInventory' if provideWebjet
      boardPath = if inInventory then 'inventory' else hasCanvas.data?.$path
      dest = webjet.webjet?.$path
      if provideWebjet and !provideWebjetIsInventory and provideWebjet?.webjet?.category != '/webjets/containers/webjets/list'
        dest = provideWebjet?.webjet?.$path
        connections = []
        group = 'items'
        if provideWebjet.webjet.connections? and provideWebjet?.webjet?.connections[group]
          connections.push(c) for k, c of provideWebjet?.webjet?.connections[group]
        after = webjet?.connection?.sortKey
        before = false
        for c in connections
          if Number.isFinite(c.sortKey) and c.sortKey > after
            if (!before and before != 0) or c.sortKey < before
              before = c.sortKey
        sort = generateSort(provideWebjet.webjet, before)
      else if !provideWebjet and !provideWebjetIsInventory and provideWebjet?.webjet?.category != '/webjets/containers/webjets/list'
        connections = []
        group = 'items'
        if webjet.webjet.connections?
          connections.push(c) for k, c of webjet?.webjet?.connections[group]
        before = false
        for c in connections
          if Number.isFinite(c.sortKey) and c.sortKey <= 0
            if !before or c.sortKey < before
              before = c.sortKey
        sort = generateSort(webjet.webjet, before)
      if @provideCanvas
        connection =
          {
          coordinates: @provideCanvas.getEmptyCoordinates
            x: @provideCanvas.sceneX + @provideCanvas.sceneWidth / 2 - 150
            y: @provideCanvas.sceneY + @provideCanvas.sceneHeight / 2 - 50
          }
      else
        connection = {}
      connection = Object.assign(connection, sort) if sort
      result = await @$store.dispatch 'webjet/create',
        category: '/webjets/content/webjets/note'
        dest: dest
        webjet:
          data:
            text: transcription
        boardPath: boardPath
        connection: connection
      webjet.status = 'play'
