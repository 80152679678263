import { socketUsersOnBoards } from '@/websockets'
import Vue from 'vue'
import store from "@/store"

socketBoards =
  namespaced: true
  state:
    boards: {}
    firstLoaded: false
  mutations:
    UPDATE: (state, payload) ->
      Vue.set state.boards, payload.boardId, new Set(payload.users)
    REMOVE: (state, payload) ->
      Vue.delete state.boards, payload
    FIRSTLOAD: (state) ->
      state.firstLoaded = true
  actions:
    connect: ({state, commit}) ->
      if socketUsersOnBoards and socketUsersOnBoards.connected
        socketUsersOnBoards.disconnect()
      if socketUsersOnBoards and store.state.uid and store.getters.boardsReady
        socketUsersOnBoards.auth = { uid: store.state.uid }
        socketUsersOnBoards.connect()
    disconnect: ({state, commit}) ->
      socketUsersOnBoards.disconnect()
    firstLoad: ({commit}) ->
      commit 'FIRSTLOAD'
    joinListen: ({state}, boardId) ->
      # console.log 'joinListen ', boardId
      socketUsersOnBoards.emit "listen", boardId
    joinBoard: ({state}, boardId) ->
      socketUsersOnBoards.emit "joinBoard", boardId
    leaveBoard: ({state,commit}, boardId) ->
      socketUsersOnBoards.emit "leaveBoard", boardId
      commit 'REMOVE', boardId
    boardData: ({state, commit}, boardData) ->
      # console.log('boardData ACTION ', boardData)
      commit 'UPDATE', boardData
    reconnect: ({state, dispatch}) ->
      for b in store.getters.boards
        boardId = b.src.split('/').pop()
        dispatch 'joinListen' , boardId
      if store.getters.currentBoardId
        dispatch 'joinBoard' , store.getters.currentBoardId
      dispatch 'firstLoad'



export default socketBoards