

























import Plus from './Plus'

import SharedScene from './SharedScene'
import SharedCursor from './SharedCursor'
import SharedDraw from './SharedDraw'

import RelationOld from './Relation'
import RelationOptimization from './RelationOptimization'
if FLAG.RELATION_COMPONENT_OPTIMIZATION
  Relation = RelationOptimization
else
  Relation = RelationOld


import SceneWebjets from './SceneWebjets'
import MinimapWebjets from './MinimapWebjets'

import ScenePaints from './ScenePaints'

import { wsBoard } from '@/websockets'

export default
  name: 'Scene'
  components: { SharedScene, SharedCursor, SharedDraw, Relation, SceneWebjets, MinimapWebjets, Plus, ScenePaints }
  props:
    src: String
  provide: ->
    provideScene: this
  inject: ['provideCanvas']

  computed:
    sceneStyles: -> @provideCanvas.sceneStyles
    selectionStyles: -> @provideCanvas.selectionStyles
    relations: -> @provideCanvas.relations
    selected: -> @provideCanvas.selected
    selection: -> @provideCanvas.selection
    devSceneNow: -> @provideCanvas.devSceneStyles @provideCanvas.sceneNow
    devSceneNext: -> @provideCanvas.devSceneStyles @provideCanvas.sceneNext
    devSceneFull: -> @provideCanvas.devSceneStyles @provideCanvas.sceneFull
    relationContextActiveSide: -> @provideCanvas.relationContextActiveSide

    plusPoints: -> @provideCanvas.plusPoints
    plusIsActive: -> @provideCanvas.plusIsActive
    showContextRelations: -> @provideCanvas.showContextRelations
    activeContextRelations: -> @provideCanvas.activeContextRelations
    contextRelationsWebjet: -> @provideCanvas.contextRelationsWebjet
    relationPlusPoint: -> @provideCanvas.relationPlusPoint

    sharedScenes: -> wsBoard.get 'scene'
    sharedCursors: -> wsBoard.get('cursor')
    sharedDraws: -> wsBoard.get 'draw'
    selfDraw: -> wsBoard.getSelf 'draw'

    sharedRelations: -> wsBoard.get 'relation'

