







export default
  name: 'WResizer'
  inject:
    provideWebjet: { default: null }
    provideCanvas: { default: null }
  data: ->
    active: false
    clickWithoutMove: false
    start: 0
    resizeTouch: { hold: false, active: false, ready: false }
  computed:
    show: ->
      !@provideWebjet.provideWebjet and !@provideWebjet.isReadOnly
    classes: ->
      'w-resizer-active': @active || @resizeTouch.active
      'w-resizer-ready': @resizeTouch.ready
    styles: ->
      return false unless @resizeTouch.ready
      return
        '--scale': @provideCanvas.scale
  methods:
    mousedown: (e)->
      if !e.shiftKey and !e.ctrlKey
        @active = true
        @clickWithoutMove = true
        e.stopPropagation()
      return
    mousemove: (e)->
      if @active
        @clickWithoutMove = false
        mouseX = @provideCanvas.sceneX + e.x / @provideCanvas.scale
        width = mouseX - @provideWebjet.elementRect.x1
        @provideWebjet.resizeWidth = width
        if e.which != 1
          @mouseup(e)
      return
    mouseup: (e)->
      if @active
        @active = false
        if !@clickWithoutMove
          e.stopPropagation()
          @provideWebjet.resizeWidth = false
          mouseX = @provideCanvas.sceneX + e.x / @provideCanvas.scale
          width = mouseX - @provideWebjet.elementRect.x1
          baseWidth = @provideWebjet.baseWidth
          width = baseWidth if width < baseWidth
          @$store.dispatch 'webjet/updateConnection',
            path: @provideWebjet.src
            data:
              size:
                width: width
      return
    doubleclick: (e)->
      # reset width to default, called on double click and double tap
      e.stopPropagation()
      @$store.dispatch 'webjet/updateConnection',
        path: @provideWebjet.src
        data:
          size:
            width: null

    touchstart: (e)->
      if e.touches?.length == 1
        #console.log "!resize touch start"
        @resizeTouch = {
          hold: true, active: false, ready: false,
          bx: e.touches[0].pageX, by: e.touches[0].pageY,
          timestamp: new Date().getTime(),
          readyTimeout: setTimeout (=> @resizeTouch.ready = true), 500
        }

    touchmove: (e)->
      #console.log "!resize touch move", { ...@resizeTouch }
      distance = @touchDistance(e)
      @resizeTouch.lastX = e.touches[0].clientX
      @resizeTouch.lastY = e.touches[0].clientY
      if @resizeTouch.hold
        #console.log "!resize touch hold", { ...@resizeTouch }
        if e.touches?.length != 1
          @touchcancel(e)
          return
        if new Date().getTime() - @resizeTouch.timestamp < 500
          if distance > 20
            @touchcancel(e)
          return
        e.stopPropagation()
        @resizeTouch.active = true
        @resizeTouch.hold = false
        @resizeTouch.ready = true
      else if @resizeTouch.active
        #console.log "!resize touch active", { ...@resizeTouch }
        e.stopPropagation()
        e.preventDefault()
        if distance > 20
          @resizeTouch.ready = false
        dx = e.touches[0].pageX - @resizeTouch.bx
        baseWidth = @provideWebjet.baseWidth
        width = (@provideWebjet.connection?.size?.width || baseWidth) + dx / @provideCanvas.scale
        @provideWebjet.resizeWidth = width

    touchend: (e)->
      #console.log "!resize touch end", { ...@resizeTouch }
      if @resizeTouch.ready
        # not moved yet = taphold, reset width to default
        @$store.dispatch 'webjet/updateConnection',
          path: @provideWebjet.src
          data:
            size:
              width: null
        @touchcancel(e)
        e.stopPropagation()
        e.preventDefault()
      else if @resizeTouch.active
        @$store.dispatch 'webjet/updateConnection',
          path: @provideWebjet.src
          data:
            size:
              width: @provideWebjet.resizeWidth
        @touchcancel(e)
        e.stopPropagation()
        e.preventDefault()
      else if @resizeTouch.hold
        @touchcancel(e)

    touchcancel: (e)->
      #console.log "!resize touch cancel", { ...@resizeTouch }
      clearTimeout @resizeTouch.readyTimeout
      @resizeTouch = { hold: false, active: false, ready: false }
      @provideWebjet.resizeWidth = false

    touchDistance: (e)->
      distance = Math.sqrt(
        Math.pow(e.touches[0].pageX - @resizeTouch.bx, 2) + Math.pow(e.touches[0].pageY - @resizeTouch.by, 2)
      )
      return parseInt(distance, 10)

