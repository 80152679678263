










import Logo from '@/components/Logo'
export default
  name: 'InactiveLink'
  components: { Logo }
