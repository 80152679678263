










import { webjet, positionable, expandable, childable, draggable, selectable } from './mixins'

export default
  name: 'WebjetUnknown'
  mixins: [webjet, positionable, expandable, childable, draggable, selectable]
  computed:
    isCopyable: -> false
    childItems: ->
      return [] unless @webjet?.connections?
      return [] if @isNestedLoop
      items = []
      for groupKey, group of @webjet.connections
        if groupKey != 'parents' and groupKey != 'count' and groupKey != 'mindmap'
          items = items.concat Object.values group
      items = items.sort (a, b)->
        parseFloat(a.sortKey) - parseFloat(b.sortKey)
      items
    hasChilds: ->
      @childItems.length > 0

