



































































































































































































































export default
  name: 'ContextMenu'
  inject: ['provideCanvas']
  data: ->
    rect: null
    sideDropMenu: 'top'
  computed:
    showResetSelection: ->
      @selection.length > 0 and FLAG.RESET_SELECTION_BTN and @$vuetify.breakpoint.width >= 800
    selection: ->
      @$store.getters['webjet/selected']
    centerX: ->
      @provideCanvas.sceneX + @provideCanvas.sceneWidth / 2
    centerY: ->
      @provideCanvas.sceneY + @provideCanvas.sceneHeight / 2

    showAnchorType: ->
      @selection.length == 1 and @selection[0].webjet.category == 'anchor'

    anchorType: ->
      return unless @showAnchorType
      type = @selection[0].webjet.data?.type
      return 'iframe' unless type
      type

    showSelectionCount: ->
      @selection.length > 1
    selectionCount: ->
      @selection.length
    showCopyLink: ->
      @selection.length == 1 and !@isInInventory
    showShapeMenu: ->
      @selection.length == 1 and @selection[0].webjet.category == '/webjets/content/webjets/shape'
    tradingViewInterval: ->
      if @selection[0].webjet.data.interval
        return @selection[0].webjet.data.interval.split('/')[1]
      else
        return '1d'
    showTradingViewMenu: ->
      @selection.length == 1 and @selection[0].webjet.category == 'tradingview'
    shapeType: ->
      return unless @showShapeMenu
      @selection[0].webjet.data.type
    showTitle: ->
      @selection.length == 1 and @selection[0].webjet.category != '/webjets/content/webjets/shape'
    title:
      get: ->
        # at this point title is only title set by user, all other options goes to titlePlaceholder
        @selection[0].webjet?.data?.title
      set: (value)->
        @$store.dispatch 'webjet/updateTitle',
          path: @selection[0].webjet.$path
          value: value
    titlePlaceholder: ->
      @selection[0].titlePlaceholder
    indexSelection: ->
      if @selection[@selection.length - 1]?.genShow
        return @selection.length - 1
      index = 0
      for w,i in @selection
        break if !w.genShow
        index = i
      index
    show: ->
      return false unless @selection[@indexSelection].parentIsShow
      return false unless @selection[@indexSelection].genShow
      return !@selection[@indexSelection].dragInProgress
    isChild: ->
      return true if @selection[@indexSelection].parentsSrc.length
    isInSidebar: ->
       return true if @selection[0].provideSidebar
    isInInventory: ->
      return true if @selection[0].provideSidebar?.activeTab == 'inventory'
    left: ->
      if @isInInventory and @rect
        rectW = @rect?.width
        x = @$vuetify.breakpoint.width - 15
        return "#{x}px"
      if @provideCanvas.showContextMenu and @rect and @selection[@indexSelection].genShow
        cpx = @provideCanvas.position.x
        czx = @provideCanvas.zoom.dx
        ex1 = @selection[@indexSelection].elementSceneRect.x1
        ex2 = @selection[@indexSelection].elementSceneRect.x2
        scale = @provideCanvas.scale
        x = Math.round(cpx) + czx + ex1 * scale + (ex2 - ex1) / 2 * scale
        if x < (@rect.width + 40) / 2
          x = (@rect.width + 60) / 2
        if x > @$vuetify.breakpoint.width - (@rect.width + 40) / 2
          x = @$vuetify.breakpoint.width - (@rect.width + 60) / 2
        return "#{x}px"
    top: ->
      if @isInInventory and @rect
        rectH = @rect?.height
        x = @$vuetify.breakpoint.height - (rectH / 2) - 95
        return "#{x}px"
      if @provideCanvas.showContextMenu and @rect and @selection[@indexSelection].genShow
        cpy = @provideCanvas.position.y
        czy = @provideCanvas.zoom.dy
        ey1 = @selection[@indexSelection].elementSceneRect.y1
        ey2 = @selection[@indexSelection].elementSceneRect.y2
        scale = @provideCanvas.scale
        offset = 40 * scale
        offset = 30 if offset < 30
        offset = 170 if offset > 170
        y = cpy + czy + ey1 * scale
        y = Math.round(y) - @rect.height - offset
        y = y + @rect.height + offset * 2 + (ey2 - ey1) * scale
        @sideDropMenu = 'top'
        if y > @$vuetify.breakpoint.height - @rect.height - offset
          # y = offset

          y = cpy + czy + ey1 * scale
          y = Math.round(y) - @rect.height - offset
          if y > 50
            @sideDropMenu = 'top'
          else
            @sideDropMenu = 'bottom'
        if y < 0
          @sideDropMenu = 'bottom'
          y = 10

        if @showTradingViewMenu and y < 440
          @sideDropMenu = 'bottom'

        return "#{y}px"

    positionContextMenu: ->
      return { left: @left, top: @top, bottom: 'auto' }
    showTheme: ->
      @selection.every (w)-> w.isThemeable and w.canTheme
    themeClass: ->
      firstTheme = @selection[0].themeableClass
      allSame = @selection.every (w)-> w.themeableClass == firstTheme
      if allSame
        if firstTheme
          { [firstTheme]: true }
        else
          { }
      else
        { 'theme-different': true }

    themeClassName: ->
      Object.keys(@themeClass)[0]

    allCanExpand: -> @selection.every (w)-> w.isExpandable and w.canExpand
    showCollapse: ->
      @allCanExpand and @selection.some (w)-> w.isExpanded
    showExpand: ->
      @allCanExpand and @selection.some (w)-> !w.isExpanded

    showTrash: ->
      true

    showDuplicate: ->
      return false if @isInInventory
      return false if @selection.length == 1 and @selection[0].webjet.category == 'anchor'
      @selection.every (w)-> w.isCopyable

    showGroup: ->
      canGroup = @selection.every (w) =>
        return true unless w.webjet.category == 'anchor'
      @selection.length > 1 and canGroup

    showCreateAnchor: ->
      canShow = @selection.every (w) =>
        return true if w.webjet.category != 'anchor' and w.provideScene
      @selection.length > 0 and canShow

    showDownload: ->
      return false if @selection.length < 1
      canDownload = @selection.every (w) =>
        return true if w.webjet?.category in [
          '/webjets/content/webjets/photo'
          '/webjets/content/webjets/html5video'
          '/webjets/content/webjets/audio'
          '/webjets/content/webjets/pdf'
          '/webjets/content/webjets/link'
          '/webjets/content/webjets/embed'
        ] and w.webjet?.meta?.isInStorage
      return canDownload

    showTransparentTheme: ->
      canTransparent = @selection.every (w) =>
        w.webjet?.category in [
          '/webjets/content/webjets/photo'
          '/webjets/content/webjets/note'
        ]
      canTransparent

    showSite: ->
      return false unless @selection.length == 1
      return false if @selection[0].webjet?.category == 'anchor'
      return false unless @selection[0].provideScene
      true

    showRefreshLinks: ->
      return false if @selection.length < 1
      canRefresh = @selection.every (w) =>
        return true if w.webjet?.category in [
          '/webjets/content/webjets/link'
        ] and w.webjet?.meta?.refreshDate
      return canRefresh

  methods:
    createSite: ->
      { webjetPath } = await @$store.dispatch 'weje/create',
        dest: @provideCanvas.connection.src
        path: @selection[0].connection.$path
      @provideCanvas.openWejeSite webjetPath

    createAnchor: ->
      x1 = null
      x2 = null
      y1 = null
      y2 = null
      for w in @selection
        rect = w.elementSceneRect

        x1 = rect.x1 if rect.x1 < x1 or x1 == null
        x2 = rect.x2 if rect.x2 > x2 or x2 == null
        y1 = rect.y1 if rect.y1 < y1 or y1 == null
        y2 = rect.y2 if rect.y2 > y2 or y2 == null

      type = "iframe"


      title = "Frame "
      # if @provideCanvas.anchors.length > 0
        # title += @provideCanvas.anchors.length + 1

      items = @provideCanvas.connections?.items
      if items
        items = Object.values items
        items = items.filter (c)=>
          w = @firebind(c.src)
          category = w.category
          category == 'anchor'
        title += items.length + 1
      data = {
          title: title
          type: type
        }
      connection = {
        coordinates:
          x: x1 - 20
          y: y1 - 20
      }
      size = {
          size:
            width: x2 - x1 + 40
            height: y2 - y1 + 40
        }
      connection = Object.assign connection, size
      result = await @$store.dispatch 'webjet/create',
        category: 'anchor'
        dest: @provideCanvas.data.$path
        boardPath: @provideCanvas.connection.src
        webjet:
          data:
            title: title
            type: type
        connection: connection
      @provideCanvas.selectCreatedWebjets [ result ]
    changeAncorType: ->
      type = if @anchorType == 'bookmark' then 'iframe' else 'bookmark'
      path = @selection[0].src
      @$store.dispatch 'webjet/setAnchorType', { path: path, type: type }
    copyLink: ->
      if @selection[0].webjet.category == 'btnLink'
        link = @selection[0].webjet?.data?.url
      else
        src = @selection[0].webjet.$path.split('/').slice(-2).join('/')
        link = "#{window.location.origin}/b/#{@$route.params.boardId}/#{@provideCanvas.boardOwner}?w=#{src}"
      if window.clipboardData
        # should work in Internet Explorer
        window.clipboardData.setData 'Text', link
      else
        # all other browsers
        navigator.clipboard.writeText link
      @provideCanvas.showCaution
        message: @$t('canvas.message.linkCopied')
        loader: false
    changeShape: (e,type) ->
      path = @selection[0].src
      @$store.dispatch 'webjet/setShapeType', { path: path, type: type }
    setElement: ->
      el = @selection[@indexSelection].$el
      rect = @selection[@indexSelection].$el.getBoundingClientRect()
      x = Math.round rect.x
      y = Math.round rect.y
      width = Math.round rect.width
      height = Math.round rect.height
      @el = { x, y, width, height, el }
    download: ->
      items = @selection
      for w,i in items
        wait = (ms) ->
          return new Promise (resolve) =>
            setTimeout (->
              id = 'd' + i + 't' + Date.now()
              a = document.createElement "a"
              a.setAttribute 'href', w.webjet.data?.storage || w.webjet.data?.url
              a.setAttribute 'id', id
              a.setAttribute 'download', w.webjet.data?.titleOrigin
              a.setAttribute 'target', '_blank' if w.webjet.category in [
                '/webjets/content/webjets/pdf'
              ]
              document.body.appendChild a
              document.getElementById(id).click()
              document.getElementById(id).remove()
              resolve ''
            ), ms

        await wait(i * 500)

    refreshLinks: ->
      for w in @selection
        w.loadData(true)

    setTheme: (theme)->
      items = @selection.map (w)-> { path: w.src }
      @$store.dispatch 'webjet/theme', { items, theme }

    collapse: ->
      items = @selection.filter (w)-> w.isExpanded
      items = items.map (w)-> { path: w.src }
      @$store.dispatch 'webjet/collapse', { items }

    expand: ->
      items = @selection.filter (w)-> !w.isExpanded
      items = items.map (w)-> { path: w.src }
      @$store.dispatch 'webjet/expand', { items }

    trash: ->
      items = @selection.map (w)-> { path: w.src }
      @$store.dispatch 'webjet/trash', { items }

    duplicate: ->
      items = []
      for item, i in @selection
        items.push
          path: item.src
          data:
            coordinates: @provideCanvas.getEmptyCoordinates
              x: @centerX - 150 + i * 20
              y: @centerY - 50 + i * 20
      result = await @$store.dispatch 'webjet/copy',
        items: items
        dest: @$store.getters.currentBoardSrc
        boardPath: @provideCanvas.connection.src
      @provideCanvas.selectCreatedWebjets result, false, false

    group: ->
      items = @selection.map (w)-> { path: w.src }
      @$store.dispatch 'webjet/groupToList',
        items: items
        data:
          coordinates:
            x: @centerX - 150
            y: @centerY - 50

    hotkeyBlocker: (e) ->
      if e.type == 'keydown' && (e.keyCode == 13 || e.keyCode == 27)
        e.target.blur() # blur on enter and escape
      # so far we just block key events from title input to not interfere with app hotkeys
      # later some logic can be implemented
      e.stopPropagation()
      # block ctrl +/-/0
      if e.ctrlKey && e.keyCode in [ 187, 189, 48, 107, 109, 45, 43, 96 ]
        e.preventDefault()
    setRect: ->
      if @selection[@indexSelection]?.genShow and @provideCanvas.showContextMenu and @selection[@indexSelection]?.parentIsShow
        @rect = @$refs.contextmenu.getBoundingClientRect()
    resetSelection: ->
      @$store.dispatch 'webjet/resetSelection'
    setTradingViewInterval: (interval) ->
      @$store.dispatch 'webjet/setTradingViewInterval', { path: @selection[0].src, interval }
  mounted: ->
    requestAnimationFrame(@setRect)
  updated: ->
    unless @rect
      @setRect()
