


















export default
  name: 'SidebarContent'
  inject: ['provideSidebar']
  props:
    title: String
  computed:
    smallScreen: ->
      @provideSidebar.smallScreen
  methods:
    close: ->
      @provideSidebar.close()
