


























































































import { usualMixinPack } from './mixins'
import { getWebjetForText, getWebjetForWejeLink } from '@/utils'
import { selectCreatedWebjetsWithoutCanvas } from '@/utils'
import { generateSort } from '@/utils'
import ToolVoice from '@/canvas/ToolVoice.vue'
import Avatar from '@/components/Avatar.vue'

cardsItems = [
  {title: 'Doodle card', icon: 'tool-graffiti', keyWords: '', webjetChoiceFunction: 'createWebjetGraffiti', config: {}}
  {title: 'Checklist', icon: 'tool-checklist', keyWords: '', webjetChoiceFunction: 'createWebjetChecklist', config: {}}
  {title: 'Upload files', icon: 'tool-upload', keyWords: '', webjetChoiceFunction: 'openUpload', config: {}}
]

if FLAG.WEBJET_DATE
  cardsItems.push {title: 'Date', icon: 'tool-date', keyWords: '', webjetChoiceFunction: 'createWebjetDate', config: {}}
if FLAG.WEBJET_COMMENT
  cardsItems.push {title: 'Comments', icon: 'tool-comment', keyWords: '/', webjetChoiceFunction: 'createWebjetComments', config: {}}
export default
  name: 'WebjetCard'
  mixins: [...usualMixinPack]
  components: { ToolVoice, Avatar }
  inject: {provideApp: { default: false }}
  data: ->
    inputText: ''
    showShapes: false
    choiceSelectGroupNum: null
    choiceSelectNum: null
    editorIsActive: false
    oldDelta: null
    firstEnterPressed: false
    itemsForChoise: [
      {
        title: 'Text'
        items: [
          {title: 'Header 1', icon: 'tool-card', keyWords: '', webjetChoiceFunction: 'createWebjetCard', config: {format: 'line', type: 'header', value: 'H1', text: '' }}
          {title: 'Header 2', icon: 'tool-card', keyWords: '', webjetChoiceFunction: 'createWebjetCard', config: {format: 'line', type: 'header', value: 'H2', text: ''}}
          {title: 'Header 3', icon: 'tool-card', keyWords: 'word,key', webjetChoiceFunction: 'createWebjetCard', config: {format: 'line', type: 'header', value: 'H3', text: '' }}
        ]
      }
      {
        title: 'Cards'
        items: cardsItems
      }
      # {
      #   title: 'Containers'
      #   items: [
      #     {title: 'List', icon: 'tool-list', keyWords: 'list', webjetChoiceFunction: 'createWebjetList', config: {}}
      #   ]
      # }
    ]
  computed:
    inInventory:->
      @provideBoardSrc == 'inventory'
    showHints: ->
      @searchInChoice && @textForChoice && @filtredPointsForChoice.length && @isOnlySelected
    showMembers: ->
      @searchInMembers && @textForChoice && @filtredMembersForChoice.length && @isOnlySelected
    lastShapeType: -> @provideCanvas.lastShapeType
    lastShapeTitle: ->
      firstLetter = @lastShapeType.charAt(0).toUpperCase()
      shapeType = @lastShapeType.replace(/right|left|bottom|top|-/g,'')
      return @$t('canvas.action.create' + firstLetter + shapeType.slice(1))
    showChoiceBtns: ->
      return false unless @isOnlySelected
      return false unless @editorIsActive
      return true unless @webjet.data?.contentDelta
      return true if @textForChoice.length == 1
      return true if @filtredPointsForChoice.length
    textForChoice: ->
      html = @webjet.data?.contentDelta
      return false unless html
      return false if html.ops?.length != 1
      text = html.ops[0].insert
      textArrNewLine = text?.split '\n'
      return false if textArrNewLine?.length != 2
      # console.log text.split '\n'
      # line = @webjet.data?.contentHtml.match(/<p.*?>.*?<\/p>/ig)
      # return false if !matchLines or matchLines.length != 1
      text

    groupedPointArrForChoice: ->
      result = []
      for group in @itemsForChoise
        for item in group.items
          result.push item
      result
    searchInMembers: ->
      regSearch = /\@/
      return false unless @textForChoice
      return true if @textForChoice?.search(regSearch) == 0
      return false
    searchInChoice: ->
      regSearch = /\//
      return false unless @textForChoice
      return true if @textForChoice?.search(regSearch) == 0
      return false
    findString: ->
      return false unless @textForChoice
      findString = @textForChoice?.trim().substring 1
      findString

    filtredMembersForChoice: ->
      return [] unless @textForChoice
      return [] unless @textForChoice.length
      return [] unless @searchInMembers
      return [] unless @editorIsActive

      collaborators = @$store.getters['collaborators']
      findString = @findString.trim()
      findString.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&')

      filtredItems = collaborators.filter (c)->
        firstName = c.fname
        lastName = c.lname
        email = c.email

        return true if firstName and firstName.toLowerCase().includes findString.toLowerCase()
        return true if lastName and lastName.toLowerCase().includes findString.toLowerCase()
        return true if email and email.toLowerCase().includes findString.toLowerCase()

      if !@findString.length
        return collaborators.filter (c) -> c.fname and c.lname and c.email

      filtredItems

    filtredPointsForChoice: ->
      return [] unless @textForChoice
      return [] unless @textForChoice.length
      return [] unless @searchInChoice
      return [] unless @editorIsActive
      return @itemsForChoise unless @findString.length
      result = []
      findString = @findString.trim()
      findString.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&')
      for group in @itemsForChoise
        filtredItems = group.items.filter (c)=>
          # findRegex = new RegExp(findString)
          # findInTiltle = findRegex.test c.title
          findInTiltle = c.title.toLowerCase().indexOf findString.toLowerCase()
          return true if findInTiltle >= 0
          arrSearch = c.keyWords.split ','
          for a in arrSearch
            return true if a.toLowerCase().indexOf(findString.toLowerCase()) >= 0
          return false
        if filtredItems.length
          result.push {title: group.title, items: filtredItems}
      # console.log 'filtredPointsForChoice', result
      if result.length == 1
        @choiceSelectGroupNum = 0
        @choiceSelectNum = 0
      result
  watch:
    showHints: (val) ->
      @toolbarHandler val
    showMembers: (val) ->
      @toolbarHandler val
  methods:
    pasteFromEditor: (e) ->
      editorIsEmpty = @$refs.editor?.editor?.getLength() == 1
      type = null
      for t in e.clipboardData.types
        if t in @$store.getters['webjet/dataTransferTypes']
          type = t
          break

      webjetKey = @webjet.$path.split('/')
      webjetKey = webjetKey.pop()
      webjetPath = @webjet.$path
      boardPath = if @inInventory then 'inventory' else @provideCanvas.data.$path
      if @provideWebjet
        providePath = @provideWebjet.webjet.$path
      else
        providePath = boardPath
      if type == 'text/plain'
      # console.log type == 'Files' , type == 'text/plain'
        return true unless editorIsEmpty
        webjetForWejeLink = await getWebjetForWejeLink e.clipboardData.getData(type)
        if webjetForWejeLink
          category = webjetForWejeLink.category
          webjet = webjetForWejeLink.webjet
        else
          { category, webjet } = getWebjetForText e.clipboardData.getData(type)
        return true if category == '/webjets/content/webjets/note'
        return true if @hasChilds
        @$store.dispatch 'webjet/updateWebjetWithCategory',
          path: webjetPath
          category: category
          update: webjet
      else if type == 'Files'
        if editorIsEmpty
          result = await @$store.dispatch 'webjet/dataTransfer',
          dest: providePath #dest
          boardPath: boardPath
          dataTransfer:
            types: [ 'Files' ]
            files:  [ ...e.clipboardData.files ]
          parentCard:
            webjetKey: webjetKey
            webjetPath: webjetPath
            connectionPath: @connection.$path
        else
          result = await @$store.dispatch 'webjet/dataTransfer',
          dest: webjetPath #dest
          boardPath: boardPath
          dataTransfer:
            types: [ 'Files' ]
            files:  [ ...e.clipboardData.files ]

      e.preventDefault()
      e.stopPropagation()
      return false
    enterFromEditor: ->
      # console.log 'enter'
      if @filtredPointsForChoice.length and @choiceSelectGroupNum != null and @choiceSelectNum != null
        @choice @filtredPointsForChoice[@choiceSelectGroupNum].items[@choiceSelectNum]
        return false
      else if @filtredMembersForChoice.length or @searchInMembers
        if @choiceSelectNum or @choiceSelectNum == 0
          @createMention @filtredMembersForChoice[@choiceSelectNum]
        else
          member = @filtredMembersForChoice.find (el) => el.email == @findString
          @createMention member
        return false
      return true
    toolbarHandler: (val) ->
      toolbarElement = @$refs.editor?.editor?.getModule('toolbar')?.container
      return unless toolbarElement
      if val
        toolbarElement.style.display = 'none'
      else
        toolbarElement.style.display = 'block'
    textChange: (val) ->
      textArr = val.split '\n'
      # console.log '@$refs.text', @webjet.data?.text
      # console.log '@$refs.editor',@webjet.data ,  @webjet.data?.contentHtml.match(/<p.*?>.*?<\/p>/ig)
      if textArr.length != 2
        @inputText = null
      else
        @inputText = textArr[0]
      @choiceSelectGroupNum = null
      @choiceSelectNum = null
      # console.log 'emit!!!!!!!', textArr[0]
    hotkeyBlocker: (e) ->
      if e.ctrlKey && e.keyCode in [ 89, 90 ]
        return
      # so far we just block key events from editor to not interfere with app hotkeys
      # later some logic can be implemented
      e.stopPropagation()
      # block ctrl +/-/0
      if e.ctrlKey && e.keyCode in [ 187, 189, 48, 107, 109, 45, 43, 96 ]
        e.preventDefault()
    onEnter: (e) ->
      if @choiceSelectGroupNum and @choiceSelectNum != null and @filtredPointsForChoice[@choiceSelectGroupNum].items[@choiceSelectNum]
        @choice @filtredPointsForChoice[@choiceSelectGroupNum].items[@choiceSelectNum]

    downArrow: (e) ->
      if @filtredPointsForChoice.length
        if @choiceSelectGroupNum == null or @choiceSelectNum == null
          @choiceSelectGroupNum = 0
          @choiceSelectNum = 0
        else if @choiceSelectNum < @filtredPointsForChoice[@choiceSelectGroupNum].items.length - 1
          @choiceSelectNum += 1
        else if @choiceSelectNum >= @filtredPointsForChoice[@choiceSelectGroupNum].items.length - 1
          if @filtredPointsForChoice[@choiceSelectGroupNum + 1]
            @choiceSelectGroupNum +=1
            @choiceSelectNum = 0
        return false
      else if @filtredMembersForChoice.length
        if @choiceSelectNum == null
          @choiceSelectNum = 0
        else if @choiceSelectNum < @filtredMembersForChoice.length - 1
          @choiceSelectNum += 1
        else if @choiceSelectNum >= @filtredMembersForChoice.length - 1
          @choiceSelectNum = @filtredMembersForChoice.length - 1
        return false
      else
        l = @$refs.editor.editor.getLength()
        if @$refs.editor?.range.index == l - 1
            @provideApp.goToWebjetInStack false, true
        return true
      # e.preventDefault()
    upArrow: (e) ->
      if @filtredPointsForChoice.length
        if @choiceSelectNum != 0
          @choiceSelectNum -= 1
        else if @choiceSelectNum == 0 and @filtredPointsForChoice[@choiceSelectGroupNum - 1]
          prevIndexGroup = @choiceSelectGroupNum - 1
          @choiceSelectNum = @filtredPointsForChoice[prevIndexGroup].items.length - 1
          @choiceSelectGroupNum = prevIndexGroup
      else if @filtredMembersForChoice.length
        if @choiceSelectNum != 0
          @choiceSelectNum -= 1
        else if @choiceSelectNum == 0
          @choiceSelectNum = 0
      else if !@filtredPointsForChoice.length or !filtredMembersForChoice.length
        if @$refs.editor?.range.index  == 0
            @provideApp.goToWebjetInStack true, true
        return true

      # e.preventDefault()
    choice: (data) ->
      config = null
      config = data.config if data.config
      @[data.webjetChoiceFunction](config)
    createWebjetCard: (config = null) ->
      webjetKey = @webjet.$path.split('/')
      webjetKey = webjetKey.pop()
      editor = @$refs.editor.editor
      if config.format == 'line'
        text = ''
        text = config.text if config.text
        editor.setText(text)
        editor.formatLine(0, 0, config.type, config.value) # format line type  index: Number, length: Number, format: String, value: any
    createWebjetList: (config = null) ->
      webjetKey = @webjet.$path.split('/')
      webjetKey = webjetKey.pop()
      editor = @$refs.editor.editor
      editor.setText('')
      result = await @$store.dispatch 'webjet/updateCategoryWebjet',
        path: @webjet.$path
        category: '/webjets/containers/webjets/list'
      selectCreatedWebjetsWithoutCanvas @connection.$path
    createWebjetChecklist: (config = null) ->
      webjetKey = @webjet.$path.split('/')
      webjetKey = webjetKey.pop()
      @$store.dispatch 'webjet/updateCategoryWebjet',
        path: @webjet.$path
        category: 'checklist'
      selectCreatedWebjetsWithoutCanvas @connection.$path
    createWebjetGraffiti: (config = null) ->
      webjetKey = @webjet.$path.split('/')
      webjetKey = webjetKey.pop()
      result = await @$store.dispatch 'webjet/updateWebjetWithCategory',
        path: @webjet.$path
        category: '/webjets/content/webjets/graffiti'
        update:
          data:
            contentDelta: null

      selectCreatedWebjetsWithoutCanvas @connection.$path
    createWebjetFile: (config = null) ->
      webjetKey = @webjet.$path.split('/')
      webjetKey = webjetKey.pop()
      webjetPath = @webjet.$path
      boardPath = if @inInventory then 'inventory' else @provideCanvas.data.$path
      if @provideWebjet
        providePath = @provideWebjet.webjet.$path
      else
        providePath = boardPath
      results = await @$store.dispatch 'webjet/dataTransfer',
        dest: providePath #dest
        boardPath: boardPath
        dataTransfer:
          types: [ 'Files' ]
          files: input.files
        parentCard:
          webjetKey: webjetKey
          webjetPath: webjetPath
          connectionPath: @connection.$path
    createWebjetComments: ->
      webjetKey = @webjet.$path.split('/')
      webjetKey = webjetKey.pop()
      result = await @$store.dispatch 'webjet/updateWebjetWithCategory',
        path: @webjet.$path
        category: 'comments'
        update:
          data:
            contentDelta: null
      selectCreatedWebjetsWithoutCanvas @connection.$path
    createVoice: ({file, duration, author}) ->
      webjetKey = @webjet.$path.split('/')
      webjetKey = webjetKey.pop()
      webjetPath = @webjet.$path
      boardPath = if @inInventory then 'inventory' else @provideCanvas.data.$path
      if @provideWebjet
        providePath = @provideWebjet.webjet.$path
      else
        providePath = boardPath
      result = await @$store.dispatch 'webjet/dataTransfer',
        dest: providePath #dest
        boardPath: boardPath
        dataTransfer:
          types: [ 'Files' ]
          files: [ file ]
        data:
          duration: duration
          author: author
        parentCard:
          webjetKey: webjetKey
          webjetPath: webjetPath
          connectionPath: @connection.$path
      selectCreatedWebjetsWithoutCanvas result[0].connectionPath
    createWebjetShape: (config = null) ->
      webjetKey = @webjet.$path.split('/')
      webjetKey = webjetKey.pop()
      return unless config.type
      result = await @$store.dispatch 'webjet/updateWebjetWithCategory',
        path: @webjet.$path
        category: '/webjets/content/webjets/shape'
        update:
          data:
            type: config.type
      if result
        @provideCanvas.lastShapeType = config.type if @provideCanvas
        selectCreatedWebjetsWithoutCanvas @connection.$path


    clickOnShape: (e, type, canTouch = true) ->
      unless canTouch
        @showShapes = !@showShapes
        return unless e.pointerType == 'mouse'
      config = {type: type}
      @createWebjetShape config


    onInput: (e) ->
      @choiceSelectNum = null

    openUpload: ->
      input = @$refs.file
      if input
        input.click()
    createCardAfter: (delta, oldDelta, newDelta, oldHtml, newHtml) ->
      boardPath = if @inInventory then 'inventory' else @provideCanvas.data.$path
      provideWebjet = @provideWebjet
      if provideWebjet
          provideWebjetIsInventory = provideWebjet?.$options.name == 'WebjetInventory'
          provideWebjetIsList  = provideWebjet?.$options.name == 'WebjetList'
      if delta?
        webjet =
          data:
            contentDelta: delta
      if !provideWebjet or provideWebjetIsInventory or provideWebjetIsList
        sort = generateSort(@webjet, true)
        connection = Object.assign {}, sort
        result = await @$store.dispatch 'webjet/splitTextCard',
          split:
            path: @webjet.$path
            oldDelta: oldDelta
            newDelta: newDelta
            oldHtml: oldHtml
            newHtml: newHtml
          create:
            category: '/webjets/content/webjets/note'
            dest: @webjet.$path
            boardPath: boardPath
            connection: connection
            webjet: webjet
      else if provideWebjet
        connections = []
        group = 'items'
        if provideWebjet.webjet.connections? and provideWebjet.webjet.connections[group]
          connections.push(c) for k, c of provideWebjet.webjet.connections[group]
        after = @connection?.sortKey
        before = false
        for c in connections
          if Number.isFinite(c.sortKey) and c.sortKey > after
            if before == false or c.sortKey < before
              before = c.sortKey
        sort = generateSort(provideWebjet.webjet, before)
        connection = Object.assign {}, sort
        result = await @$store.dispatch 'webjet/splitTextCard',
          split:
            path: @webjet.$path
            oldDelta: oldDelta
            newDelta: newDelta
            oldHtml: oldHtml
            newHtml: newHtml
          create:
            category: '/webjets/content/webjets/note'
            dest: provideWebjet.webjet.$path
            boardPath: boardPath
            connection: connection
            webjet: webjet
      if result
        if provideWebjet
          provideWebjet.splitWebjet = result.webjetKey
        else
          @splitWebjet = result.webjetKey
        selectCreatedWebjetsWithoutCanvas result.connectionPath

    processUpload: ->
      input = @$refs.file
      if input?.files?.length > 0
        boardPath = if @inInventory then 'inventory' else @provideCanvas.data.$path
        if not (await @provideApp.checkFileSizeLimits input.files, boardPath)
          input.value = null
          return
        webjetKey = @webjet.$path.split('/')
        webjetKey = webjetKey.pop()
        webjetPath = @webjet.$path
        if @provideWebjet
          providePath = @provideWebjet.webjet.$path
        else
          providePath = boardPath
        results = await @$store.dispatch 'webjet/dataTransfer',
          dest: providePath #dest
          boardPath: boardPath
          dataTransfer:
            types: [ 'Files' ]
            files: input.files
          parentCard:
            webjetKey: webjetKey
            webjetPath: webjetPath
            connectionPath: @connection.$path
        input.value = null
        selectCreatedWebjetsWithoutCanvas results[0].connectionPath

    createWebjetDate: ->
      webjetKey = @webjet.$path.split('/')
      webjetKey = webjetKey.pop()
      @$store.dispatch 'webjet/updateCategoryWebjet',
        path: @webjet.$path
        category: 'date'
      selectCreatedWebjetsWithoutCanvas @connection.$path

    createMention: (member) ->
      if member
        mentioned =
          uid: member.uid
      else
        mentioned = null

      @$store.dispatch 'webjet/updateWebjetWithCategory',
        path: @webjet.$path
        category: 'mention'
        update:
          data:
            mentioned: mentioned
            referrer: @$store.state.uid
            active: true
      selectCreatedWebjetsWithoutCanvas @connection.$path
  # mounted: ->
  #   console.log @webjet
