import { firebind } from '@/utils'
import { getRef, nowFirebase } from '@/utils'
import store from '@/store'
import router from '@/router'
import { sendAnalytics } from '@/utils'

export default
  bootstrapUser:
    root: true
    handler: ({ }, { user })->
      #console.log state.route
      userData = await firebind '/users/'+user.uid
      if !user.isAnonymous and (
        !userData.createDate || !userData.email ||
        (!userData.firstName && !userData.lastName && user.displayName) ||
        (!userData.avatarFile && user.providerData[0].providerId == 'google.com' && user.providerData[0].photoURL && user.providerData[0].photoURL != userData.avatar)
      )
        await @dispatch 'user/init', { user }

      if !user.isAnonymous and !userData.settings?.welcome_modal_state?
        @state.showWelcomeModal = true

      if sessionStorage['langWithoutUser']
        await @dispatch 'user/initLang'

      firstLogin = false
      if !user.isAnonymous and !userData.createDate
        firstLogin = true
        await @dispatch 'user/registerUser', { user }
        await @dispatch 'user/initWelcomeBoard'

      invitecode = @state.route.query.invitecode
      if invitecode
        if !user.isAnonymous
          invite = await firebind "/inviteLinks/#{invitecode}"
          if invite.src?
            exists = false
            for k,v of store.getters.boards
              src = v.src
              src = src.substring(9) if src.startsWith 'firebase:'
              if src == invite.src
                exists = true
                break
            if exists
              await router.push { name: 'Board', params: { boardId: invite.src.split('/').pop() } }
            else
              router.push { name: 'Wait', query: @state.route.query } if @state.route.name != 'Wait'
              @dispatch 'user/processInviteCode', { user, invitecode }
          else
            await router.push { name: 'InactiveLink' }
        else
          router.push { name: 'AnonymousForbidden' }

      if @state.route.name in [ 'SignUp', 'Login' ] #|| invitecode
        # There shoule be no route changes prior this point!
        # This is to preserve utm_* and invitecode parameters in url despite Login/SignUp/ResetPassword navigation.
        await router.push { name: 'Home' }
      else if @state.route.name == 'Board'
        conn = store.getters['currentBoardConnectionSrc']
        if !conn
          @state.publicBoardLoading = true
          board = await firebind "/boards/#{@state.route.params.boardId}"
          if board and !board.$error
            await @dispatch 'webjet/createConnectionWithoutHistory',
              dest: 'desktop'
              group: 'items'
              src: board.$path
              data: { unread: true }
          else if @state.route.params.userId
            board = await firebind "/data/#{@state.route.params.userId}/#{@state.route.params.boardId}"
            if board and !board.$error
              await @dispatch 'webjet/createConnectionWithoutHistory',
                dest: 'desktop'
                group: 'items'
                src: board.$path
                data: { unread: true }
          @state.publicBoardLoading = false

      if firstLogin
        setTimeout =>
          if @state.route.name == 'Home'
            router.push { name: 'Board', params: { boardId: "welcome_#{@state.uid}" }, hash: '#o' }

  deleteAccount:
    handler: (_c, _p)->
      sendAnalytics 'delete_account'
      userData = await firebind "/users/#{@state.uid}"
      getRef('/queue/tasks').push
        _state: 'deleteprofile'
        userId: @state.uid
        subject: userData?.email
        createDate: nowFirebase()
      @state.auth().currentUser.delete().then =>
        @state.needAuth = true
        @state.signOut = true
        localStorage.removeItem 'firebaseCacheUser'
        for k in Object.keys(localStorage)
          if k.startsWith '_fbc_'
            localStorage.removeItem k
        router.push { name: 'DeletedAccount' }
        location.reload(true)

  closeWelcomeModal:
    handler: (_, { forever }, operation) ->
      @state.showWelcomeModal = false
      if forever
        getRef("/users/#{@state.uid}/settings/welcome_modal_state").set 1

  closeFridayBanner:
    handler: () ->
      getRef("/users/#{@state.uid}/settings/navbar_banner").set 1

  unsubscribePlan:
    handler: (_c, _p, _o) ->
      userData = await firebind "/users/#{@state.uid}"
      getRef('/queue/tasks').push
        _state: 'stripe_unsubscribe'
        userId: @state.uid
        subject: userData?.email
        createDate: nowFirebase()
