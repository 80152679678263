







export default
  name:
    "BoardName"
  props:
    value: String
    editable: Boolean
  methods:
    onPaste: (e) ->
      text = (e.originalEvent || e).clipboardData.getData 'text/plain'
      document.execCommand 'insertHTML', false, text
      e.preventDefault()
      e.stopPropagation()
    onInput: (e) ->
      @$emit 'input', e.target.innerText
