



































import ModalImgCropper from '@/components/ImgCropper/ModalImgCropper'
import { getOwnerUID } from '@/utils'
export default
  name:
    "BoardImg"
  components:
    {
      ModalImgCropper
     }
  props:
    src: String
    boardLicense: String
  data: ->
    openModalCropper: false
    imgFile: null
    availiableThemes: ['theme-board-default', 'theme-board-gray', 'theme-board-red', 'theme-board-yellow', 'theme-board-green', 'theme-board-purple', 'theme-board-blue', 'theme-board-lightblue']
    dragOverImg: false
  computed:
    title: ->
      @boardData?.title
    boradImg: ->
      @boardData?.url
    boardOwner: ->
      getOwnerUID @connection?.src
    initials: ->
      return '' unless @title
      result = ''
      words = @title.replace(/[^\p{L}\p{Z}^$\n]/gu, '').split(' ')
      result += words[0][0] if words[0]
      result += words[1][0] if words[1]
      result.toUpperCase()
    boardAccess: ->
      @$store.getters.boardAccess @src
    userCanEdit: ->
      if FLAG.NEW_LOAD
        @boardAccess.access in ['manage', 'edit']
      else
        return false unless @boardLicense
        return false unless @shared
        return true if @boardOwner == @$store.state.uid
        return true if @shared?['*']?.includes 'w'
        return true if @shared?[@$store.state.uid]?.includes 'w'
        false
    defaultTheme: -> 'theme-board-default'
    currentTheme: ->
      @boardData?.boardTheme or @defaultTheme
    starred: ->
      if @connection?.starred then true else false

    connection: ->
      if @src.includes('/')
        @firebind @src
      else
        { src: "/boards/#{@src}" }
  methods:
    drop: (e) ->
      e.preventDefault()
      e.stopPropagation()
      @dragOverImg = false
      file = e.dataTransfer?.files?[0]
      imageType = /image.*/
      isImg = file?.type.match imageType
      if file and isImg
        @imgFile = file
        @openModalCrop()
    dragover: (e) ->
      @dragOverImg = true
      e.preventDefault()
    dragleave: (e) ->
      @dragOverImg = false
    toggleStarred: ->
      @$store.dispatch 'webjet/setStarred',
        path: @connection.$path
        value: !@starred
    openModalCrop: ->
      @openModalCropper = true
    openUpload: ->
      return false if @boardLicense != 'rw'
      input = @$el.querySelector 'input.boardimg-upload'
      if input
        input.click()
    processCrop: ->
      input = @$el.querySelector 'input.boardimg-upload'
      if input?.files?.length > 0
        @imgFile = input.files[0]
        @openModalCrop()
    uploadCroppedImg: (img) ->
      @$store.dispatch 'webjet/updateBoardImg',
        path: @connection?.src #@board?.$path
        file: img
    setTheme: (theme)->
      theme = null if theme == @defaultTheme
      @$store.dispatch 'webjet/setBoardTheme',
        path: @connection?.src
        theme: theme
    deleteCover: ->
      @$store.dispatch 'webjet/deleteBoardImg',
        path: @connection?.src

  watch:
    openModalCropper: (val)->
      input = @$el.querySelector 'input.boardimg-upload'
      input.value= null

  firebind:
    #connection: -> @src
    #board: -> @connection?.src
    shared: ->
      @connection?.src and "#{@connection.src}/shared"
    boardData: ->
      @connection?.src and "#{@connection.src}/data"
