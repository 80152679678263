

















import { getRef } from '@/utils'

export default
  name: 'WChecklistItem'
  inject: ['provideWebjet']
  props:
    itemData: Object
    itemId: String
    draggable: Boolean
  data: ->
    taskTmpId: null
    activeEdit: false
    dragByBtn: false
  computed:
    canDrag: ->
      return true if @editable # for prevent drag on selection title
      @draggable and @dragByBtn
    classes: ->
      return
        'checked': @status == 1
        'edit': @editable
    editable: ->
      @provideWebjet.isOnlySelected and @activeEdit
    status: ->
      status = @itemData.status
      status = 0 if status == undefined
      status
    title: ->
      @itemData.title
    isOnlySelected: ->
      @provideWebjet.isOnlySelected
  watch:
    title: (val) ->
      if val != @$refs.title.innerText
        @$refs.title.innerText = val
  methods:
    onTitleChange: (e) ->

      # fix Gborad bug, when on Enter fire input event with type 'insertCompositionText'
      # but it is expected to press enter
      if e.inputType == 'insertCompositionText'
        l = e.target.childNodes.length
        if e.target.childNodes[l - 1].nodeName != '#text'
          e.target.childNodes[l - 1].remove()
          @onEnter(e)
      else
        @updateTitle(e)

    onPaste: (e) ->
      e.stopPropagation()
    mouseDownOnDragIcon: (e) ->
      @dragByBtn = true
    mouseUpOnDragIcon: (e) ->
      @dragByBtn = false
    mouseup: (e) ->
      if @provideWebjet.isOnlySelected
        e.stopPropagation()
    keydown: (e) ->
      range = document.getSelection().getRangeAt(0)
      l = range.startContainer.length
      if range.endOffset == l and range.startOffset == l
        @provideWebjet.focusItem 'next', @itemId, @itemData
        e.preventDefault()
        e.stopPropagation()
    keyup: (e) ->
      range = document.getSelection().getRangeAt(0)
      if range.endOffset == 0 and range.startOffset == 0
        @provideWebjet.focusItem 'prev', @itemId, @itemData
        e.preventDefault()
        e.stopPropagation()
    keyEsc: (e) ->
      @activeEdit = false
      @updateTitle(e)
    keyDelete: (e) ->
      if e.target.innerText.length == 0
        type = null
        type = 'next' if e.key == 'Delete'
        type = 'prev' if e.key == 'Backspace'
        e.preventDefault()
        e.target.blur()
        @provideWebjet.focusItem type, @itemId, @itemData if type
        @$nextTick =>
          @removeItem()
          return false
      else
        return true

    dragStart: (e) ->
      @dragByBtn = false
      if @editable
        e.preventDefault()
        e.stopPropagation()
        return
    blurTitle: (e) ->
      @activeEdit = false

    startEditTitle: (e) ->
      return if @activeEdit
      return unless @provideWebjet.isOnlySelected
      @activeEdit = true
      @$nextTick =>
        @$refs.title.focus()
        if window.getSelection && document.createRange
          range = document.createRange()
          range.selectNodeContents(@$refs.title)
          sel = window.getSelection()
          sel.removeAllRanges()
          sel.addRange(range)
        else if document.body.createTextRange
          range = document.body.createTextRange()
          range.moveToElementText(@$refs.title)
          range.select()
    carretToStart: ->
      @activeEdit = true
      @$nextTick =>
        @$refs.title.focus()
        if @$refs.title?.childNodes?.length
          range = document.createRange()
          sel = window.getSelection()
          range.setStart(@$refs.title.childNodes[0], 0)
          range.collapse(true)
          sel.removeAllRanges()
          sel.addRange(range)
    carretToEnd: ->
      @activeEdit = true
      @$nextTick =>
        @$refs.title.focus()
        range = document.createRange()
        sel = window.getSelection()
        range.setStart(@$refs.title.childNodes[0], @title.length)
        range.collapse(true)
        sel.removeAllRanges()
        sel.addRange(range)
    changeStatus: ->
      oldVal = @status
      val = 0 if oldVal == 1
      val = 1 if oldVal == 0
      data = {status:val}
      @updateItem data

    onEnter: (e) ->
      e.preventDefault()
      @provideWebjet.autoCreateItemBefor({key: @itemId, data: @itemData})

    updateTitle: (e) ->
      return if e.ctrlKey or e.shiftKey
      e.preventDefault()
      title = e.target.innerText
      data = {title: title}
      @updateItem data

    additionalData: ->
      date = new Date().getTime()
      user = @$store.state.uid
      lastModify = {date:date,user:user}
      return {lastModify}



    updateItem: (data) ->
      additionalData = @additionalData()
      Object.assign(data, additionalData)
      @$store.dispatch 'webjet/updateChecklistItem',
        path: @provideWebjet.webjet.$path
        id: @itemId
        data: data

    removeItem: ->
      @$store.dispatch 'webjet/removeCkecklistItem',
        path: @provideWebjet.webjet.$path
        id: @itemId

    hotkeyBlocker: (e) ->
      # console.log '111'
      if e.ctrlKey && e.keyCode in [ 89, 90 ]
        return
      # so far we just block key events from editor to not interfere with app hotkeys
      # later some logic can be implemented
      e.stopPropagation()
      # block ctrl +/-/0
      if e.ctrlKey && e.keyCode in [ 187, 189, 48, 107, 109, 45, 43, 96 ]
        e.preventDefault()
  mounted: ->
    @$refs.title.textContent = @title

