import { genFromMixins } from '@/utils'

webjet =
  props:
    src: String
  provide: ->
    provideWebjet: this
  inject:
    provideWebjet: { default: false }
    provideSidebar: { default: false }
    provideSidebarContent: { default: false }
    provideScene: { default: false }
    provideBoardSrc: { default: false }
    provideCanvas: { default: null }
    provideApp: { default: null }
  context:
    positionable: false
  data: ->
    childWebjetComponents: []
    isDestroyed: false
    outerHTML: ''
    contentLoaded: false
    headEditTitle: false

  classes: ->
    return
      'inventory-unread': @isInventoryUnread
      'wrs': FLAG.WEBJET_REMOVE_STYLES
      'fresh': @isFresh

  attrs: ->
    return
      id: @id
      contentloaded: @contentLoaded

  listeners: ->
    mousedown: @webjetMousedown
    mousemove: @webjetMousemove

  created: ->
    @provideCanvas.registerWebjet(this) if @provideScene
    if @provideWebjet
      @provideWebjet.registerChildWebjet this
    else
      @provideCanvas.registerChildWebjet(this) if @provideScene

  mounted: ->
    @$el.webjet = this

  destroyed: ->
    @provideCanvas.unregisterWebjet(this) if @provideScene
    if @provideWebjet
      @provideWebjet.unregisterChildWebjet this
    else
      @provideCanvas.unregisterChildWebjet(this) if @provideScene
    @isDestroyed = true

  computed:
    id: -> @src.split('/').pop()
    isContainer: -> false
    isCopyable: -> true
    isReadOnly: ->
      return true unless @$store.state.connected
      return true if !@connection or !@webjet
      return true if !@connection.$sync or !@webjet.$sync
      return false if @provideBoardSrc == 'inventory'
      return true unless @provideCanvas
      return true if @provideCanvas.isReadOnly and !(@provideSidebar?.activeTab == 'inventory')
      false
    x: -> @connection?.coordinates?.x || 0
    y: -> @connection?.coordinates?.y || 0
    width: -> @connection?.size?.width || @baseWidth
    height: -> @connection?.size?.height || @baseHeight

    isInTrash: ->
      if @provideWebjet
        @provideWebjet.isInTrash
      else
        @connection?.$path.includes '/connections/trash'

    genStyles: ->
      gens = genFromMixins this, 'styles'
      Object.assign {}, ...gens

    genClasses: ->
      gens = genFromMixins this, 'classes'
      Object.assign {}, ...gens

    genAttrs: ->
      gens = genFromMixins this, 'attrs'
      Object.assign {}, ...gens

    genListeners: ->
      gens = genFromMixins this, 'listeners'
      result = {}
      for gen in gens
        for k, v of gen
          result[k] = [] unless result[k]
          result[k].push v
      result

    genIf: ->
      gens = genFromMixins this, 'if', false
      gens.every (g) => g

    genShow: ->
      gens = genFromMixins this, 'show', false
      gens.every (g) => g

    parentWebjets: ->
      if @provideWebjet
        @provideWebjet.parentWebjets.concat @provideWebjet
      else
        []

    titlePlaceholder: ->
      if @webjet?.data?.titleOrigin
        # initial origin for content webjets
        # if user sets custom title and then removed it, then titleOrigin is restored as title
        @webjet?.data?.titleOrigin
      else
        # attempt to evaluate title from content
        # this could be removed when disappearing header is implemented
        content = @webjet?.data?.text
        if @webjet?.data?.contentDelta?
          content = null # do not use legacy text even if editor data is empty
          for op in @webjet.data.contentDelta.ops
            if typeof op.insert == 'string' and op.insert.trim().length>0
              content = op.insert
              break
        if content
          lines = content.split("\n")
          for line in lines
            if line.trim().length > 0
              title = line.trim().substr(0, 50)
              if line.trim().length > 50 then title += "..."
              return title
        return @$t("webjet.#{@$options.name.slice(6).toLowerCase()}.title") || 'none'

    isTextContentEmpty: ->
      if @webjet?.data?.contentDelta?
        for op in @webjet.data.contentDelta.ops
          if typeof op.insert == 'string' and op.insert.trim().length>0 or op.attributes?.list
            return false
      else if @webjet?.data?.text
        return false
      else if @webjet?.data?.html
        return false
      return true

    wejeSites: ->
      result = []
      return result unless @webjet.connections?.parents
      for k, c of @webjet.connections.parents
        if c.weak
          w = @firebind c.src
          if w?.category == 'weje/page'
            unless w.connections?.parents
              console.warn 'weje/page without parents'
              continue
            if Object.keys(w.connections.parents)>1
              console.warn 'weje/page with multiple parents'
              continue
            site = @firebind Object.values(w.connections.parents)[0].src
            if site?.$ready
              isStrong = Object.values(site.connections.parents).some (sw)-> !sw.weak
              if isStrong
                result.push site.$path
      result


    hasWejeSites: ->
      return false unless @webjet?.connections?.parents
      # very fast check. Webjet without weak connections
      return false unless Object.values(@webjet.connections.parents).some (c)-> c.weak
      # fast check. 'weje/page' in parents
      maybeHasSites = false
      for k, c of @webjet.connections.parents
        if c.weak
          w = @firebind c.src
          if w?.category == 'weje/page'
            maybeHasSites = true
      return false unless maybeHasSites
      # slow check. Get weje sites
      return @wejeSites.length > 0

    isInventoryUnread: ->
      return if @provideScene
      @connection?.inventoryUnread

    isFresh: ->
      return unless @provideCanvas
      return unless @webjet
      webjetId = @webjet.$path.split('/').pop()
      @provideCanvas.isFreshWebjet webjetId

  firebind:
    connection: -> @src
    webjet: ->
      if @connection
        if @connection.src
          @connection.src
        else
          @connection.$path
      else
        false

  methods:

    registerChildWebjet: (component)->
      @childWebjetComponents.push component
    unregisterChildWebjet: (component)->
      @childWebjetComponents = @childWebjetComponents.filter (c)-> c!=component
    tryUpdateOuterHTML: ()-> #ttf 'outerHTML', =>
      if @$el.outerHTML != @outerHTML
        @outerHTML = @$el.outerHTML
    contentLoadedUpdate: ->
      @contentLoaded = true
      setTimeout (=> @contentLoaded = false), 200

    webjetMousedown: (e)->
      return if e.which == 2
      if @provideCanvas
        if !@connection?.$sync or !@webjet?.$sync
          @provideCanvas.showCaution
            message: @$t('canvas.message.loading')
            loader: true
        else if !@$store.state.connected
          @provideCanvas.showCaution
            message: @$t('canvas.message.connection')
            loader: true
        #else if !@provideCanvas.userCanEdit
        #  @provideCanvas.showCaution
        #    message: @$t('canvas.message.access')
      if @isInventoryUnread
        @$store.dispatch 'inventory/markAsRead', { path: @connection.$path }
      if @isFresh
        @$store.dispatch 'fresh/unfreshWebjet',
          boardId: @provideCanvas.src
          webjetId: @webjet.$path.split('/').pop()

    webjetMousemove: (e)->
      #

export default webjet
export {webjet}
