








import ContextBtn from './ContextBtn'
export default
  name: 'Trash'
  components: { ContextBtn }
  priority: 1000

  if: (selection)->
    true

  computed:
    selection: -> @$store.getters['webjet/selected']

  methods:
    action: ->
      items = @selection.map (w)-> { path: w.src }
      @$store.dispatch 'webjet/trash', { items }
