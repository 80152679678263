








import SiteSharing from '@/weje/site/SiteSharing'
import DialogTemplate from '@/components/DialogTemplate'
export default
  name: 'DialogSiteShare'
  inject: ['provideCanvas']
  props: ['src']
  components: { DialogTemplate, SiteSharing }
  computed:
    show:
      get: -> true
      set: -> @provideCanvas.dialogSiteShareSrc = false
    siteData: ->
      @$store.getters['weje/getWejeSiteData']({src:@src})
  methods:
    hotkeyBlocker: (e) ->
      # allow undo/redo
      if e.ctrlKey && e.keyCode in [ 89, 90 ]
        return
      # block other hotkeys
      e.stopPropagation()
      # block ctrl +/-/0
      if e.ctrlKey && e.keyCode in [ 187, 189, 48, 107, 109, 45, 43, 96 ]
        e.preventDefault()
