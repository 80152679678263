




































import SidebarObject from "../SidebarObject"
import DialogSlide from '@/components/DialogSlide'
import DialogTemplate from '@/components/DialogTemplate'
import SiteSharing from '@/weje/site/SiteSharing'
export default
  name: 'Weje'
  props: ['src']
  inject: ['provideCanvas']
  components: { SidebarObject, DialogSlide, DialogTemplate, SiteSharing}

  data: ->
    sharing: false
  computed:
    show: ->
      @weje?.$ready
    title: ->
      @weje.data.title
    wPath: ->
      '#' +'s=' + @weje.$path.split('/').pop()
    userCanEdit: ->
      @provideCanvas.userCanEdit
    showTrash: ->
      @userCanEdit
    isPublic: ->
      @weje?.public
    board: ->
      for k, v of @weje?.connections?.parents
        if v.src
          boardCategory = @firebind "#{v.src}/category"
          if boardCategory?.$value == '/webjets/containers/webjets/canvas'
            return @firebind "#{v.src}"
    wejeSiteData: ->
      @$store.getters['weje/getWejeSiteData']({src:@weje.$path})
  methods:
    open: ->
      @$router.push @wPath

    trash: ->
      @$store.dispatch 'weje/trashSiteConnection', { path: @src, boardSrc: @board.$path }

    hotkeyBlocker: (e) ->
      # allow undo/redo
      if e.ctrlKey && e.keyCode in [ 89, 90 ]
        return
      # block other hotkeys
      e.stopPropagation()
      # block ctrl +/-/0
      if e.ctrlKey && e.keyCode in [ 187, 189, 48, 107, 109, 45, 43, 96 ]
        e.preventDefault()

  firebind:
    connection: -> @src
    weje: -> @connection and @connection.src

