















import DialogTemplate from '@/components/DialogTemplate'
export default
  name:
    "DeletePageDialog"
  props:
    value: Boolean
    src: String
  components: { DialogTemplate }
  computed:
    board: ->
      for k, v of @weje?.connections?.parents
        if v.src
          boardCategory = @firebind "#{v.src}/category"
          if boardCategory?.$value == '/webjets/containers/webjets/canvas'
            return @firebind "#{v.src}"
      return null
    show:
      get: ->
        @value
      set: ->
        @$emit 'input', null
  firebind:
    connection: -> @src
    weje: -> @connection and @connection.src
  watch:
    show: (val) ->
      @$store.dispatch 'undoredo/reset'
  methods:
    trash: ->
      @$store.dispatch 'weje/trashSiteConnection', { path: @src, boardSrc: @board.$path }
      @$emit 'trash'
      @close()
    close: ->
      @$emit 'input', null
