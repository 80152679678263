import Vue from 'vue'
import VueI18n from 'vue-i18n'
import baseMessages from '@/lang/base'
import en from '@/lang/en'

Object.freeze en
for k, v of baseMessages
  Object.freeze v

Vue.use VueI18n

defaultLocale = 'en'
fallbackLocale = 'en'
loadedLocales = ['en']

availableLocales =
  en: 'English'
  fr: 'Français'
  ar: 'العربية'
  ja: '日本語'
  pt: 'Português'
  ru: 'Русский'
  uk: 'Українська'
  zh: '中文'
  tw: '繁體'
  vi: 'Tiếng Việt'
  id: 'Bahasa'
  de: 'Deutsch'
  es: 'Español'
  pl: 'Polski'
  ro: 'Română'
  ms: 'Melayu'
  hi: 'हिन्दी'

export availableLocales = availableLocales

messages = {}

if FLAG.NEW_LANG_SYSTEM and FLAG.LANG_BASE_MESSAGES
  messages = baseMessages


if FLAG.NEW_LANG_SYSTEM
  messages.en = Object.assign {}, messages.en, en

loadLocale = (lang)->
  return unless FLAG.NEW_LANG_SYSTEM
  return if lang == 'en'
  return unless lang
  unless loadedLocales[lang]
    locale = await import(### webpackChunkName: "lang-[request].yml" ### "@/lang/generate/#{lang}")
    localeMessages = Object.assign {}, messages[lang], locale.default
    Object.freeze localeMessages
    #messages[lang] = localeMessages
    i18n.setLocaleMessage lang, localeMessages
    loadedLocales[lang] = true

export detectLocale = ()=>
  #return store.getters['user/lang'] if store.getters?['user/lang']
  nl = navigator.languages[0].split('-')[0]
  if availableLocales[nl]
    nl
  else
    defaultLocale

export syncUserLang = (store)->
  store.watch (_, getters)->
    getters['user/lang']
  , (lang)->
    i18n.locale = lang if lang
    loadLocale lang
  , { immediate: true }

i18n = new VueI18n
  locale: detectLocale()
  fallbackLocale: 'en'
  messages: messages
  silentFallbackWarn: true

export default i18n
