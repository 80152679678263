







export default
  name: 'GizmoPublic'
  props: ['data']
  computed:
    wejeSites: ()->
      src = @data.src
      webjet = @firebind src
      result = []
      return result unless webjet.connections?.parents
      for k, c of webjet.connections.parents
        if c.weak
          w = @firebind c.src
          if w?.category == 'weje/page'
            unless w.connections?.parents
              console.warn 'weje/page without parents'
              continue
            if Object.keys(w.connections.parents)>1
              console.warn 'weje/page with multiple parents'
              continue
            site = @firebind Object.values(w.connections.parents)[0].src
            if site?.$ready
              isStrong = Object.values(site.connections.parents).some (sw)-> !sw.weak
              if isStrong
                result.push site.$path
      result

    isOpen: ->
      sites = @wejeSites
      result = false
      for s in sites
        w = @firebind s
        result = true if w.public
      result
