







import { userColor, userDisplayName } from '@/utils'

export default
  name: 'Avatar'
  props:
    user: String
    border: Boolean
    tooltip:
      {
        type: Boolean,
        default: true
      }


  computed:
    userId: ->
      @user or @$store.state.uid
    hasAvatar: ->
      @avatar
    styles: ->
      if @user
        if @userId == @$store.state.uid
          color = '#005EFF'
        else
          color = userColor(@user)
        { '--user-color': color }
      else
        {}
    displayName: -> userDisplayName @userId
    firstName: ->
      @userFirstName?.$value
    lastName: ->
      @userLastName?.$value
    email: ->
      @userMail?.$value
    avatar: ->
      @userAvatar?.$value

    initials: ->
      if @firstName and @lastName
        @firstName.charAt(0) + @lastName.charAt(0)
      else
        (@firstName||@lastName||@email||'  ').substr(0,2)

  firebind:
    userFirstName: ->
      @userId and '/users/'+@userId+'/firstName'
    userLastName: ->
      @userId and '/users/'+@userId+'/lastName'
    userMail: ->
      @userId and '/users/'+@userId+'/email'
    userAvatar: ->
      @userId and '/users/'+@userId+'/avatar'
