import OpenviduClient from './OpenviduClient.coffee'
import store from '@/store'

if IS_PRODUCTION and !IS_STAGE
  URL = "https://service.openvidu.app.weje.io"
else
  URL = "https://service.openvidu.stage.weje.io"

boardAudio = new OpenviduClient URL

setTimeout ->
  store.watch (-> store.getters['currentBoardId']), (value)->
    boardAudio.setKey value
  , { immediate: true }

window.devAudioBoard = boardAudio if IS_DEVELOPMENT or IS_STAGE

export default boardAudio
export { boardAudio }
