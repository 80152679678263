




import { movingAverage, bezierQudrMinMax } from '@/utils'
import fitCurve from 'fit-curve'

export default
  name: 'PathLine'
  inject: ['provideCanvas']
  # point - watch point for live draw
  # points - completed curve points
  # draw - if true, live draw by use point param
  # width - width stroke
  # color - color stroke
  props: ['point','points','draw','width','color', 'type','relativePoints']
  data: ->
    curveByLivePoint: ''
    livePoints: []
    tmpPointsForScenRect: []
  methods:
    getQuadPoints: (points) ->
      l = points?.length
      result = []
      if l
        p = points.slice 0,1
        result.push {x:p[0][0],y:p[0][1]}
      if l and l > 3
        for p,i in points
          if i+3 <= l
            lastTwoPoints = points.slice i+1, i+3
            endPoint =
              {
                x: (lastTwoPoints[0][0] + lastTwoPoints[1][0]) / 2
                y: (lastTwoPoints[0][1] + lastTwoPoints[1][1]) / 2
              }
            result.push {x:lastTwoPoints[0][0],y:lastTwoPoints[0][1]}
            result.push {x:endPoint.x,y:endPoint.y}
      # console.log points,result
      result
    getElementSceneRect: ->
      points = @getQuadPoints(@tmpPointsForScenRect)
      l = points.length
      x1 = null
      y1 = null
      x2 = null
      y2 = null
      if l == 1
        x1 = points[0].x
        y1 = points[0].y
        x2 = points[0].x
        y2 = points[0].y
        return { x1, y1, x2, y2 }
      if l > 2
        i = 0
        while i <= l-2
          p1 = points[i]
          p2 = points[i+1]
          p3 = points[i+2]

          { mx1, my1, mx2, my2 } = bezierQudrMinMax(p1.x, p1.y, p2.x, p2.y, p3.x, p3.y)
          # console.log { mx1, my1, mx2, my2 }
          x1 = mx1 if x1 == null
          y1 = my1 if y1 == null
          x2 = mx2 if x2 == null
          y2 = my2 if y2 == null


          x1 = mx1 if mx1 < x1
          y1 = my1 if my1 < y1
          x2 = mx2 if mx2 > x2
          y2 = my2 if my2 > y2

          i = i + 2
      else
        p1 = @pointsForIntersection[0]
        x1 = p1.x
        y1 = p1.y
        x2 = p1.x
        y2 = p1.y
      return { x1, y1, x2, y2 }
  computed:
    strokeOpacity: ->
      if @type == 'marker'
        return 0.5
      return 1
    transformPoints: ->
      return null unless @points
      points = @points
      l = points.length

      tmpPoints = []
      i = 0
      while i < l
        tmpPoints.push [points[i],points[i+1]]
        i += 2

      points = tmpPoints
      # if relative numbers transform in absolute
      if @relativePoints
        result = []
        for p,index in points
          if index == 0
            result.push p
          else
            prevPoint = result[index - 1]
            result.push [p[0] + prevPoint[0], p[1] + prevPoint[1]]
        return result
      else
        return points


    pointsForIntersection: ->
      points = @transformPoints
      l = points?.length
      result = []
      if l
        p = points.slice 0,1
        result.push {x:p[0][0],y:p[0][1]}
      if l and l > 3
        for p,i in points
          if i+3 <= l
            lastTwoPoints = points.slice i+1, i+3
            endPoint =
              {
                x: (lastTwoPoints[0][0] + lastTwoPoints[1][0]) / 2
                y: (lastTwoPoints[0][1] + lastTwoPoints[1][1]) / 2
              }
            result.push {x:lastTwoPoints[0][0],y:lastTwoPoints[0][1]}
            result.push {x:endPoint.x,y:endPoint.y}
      # console.log points,result
      result

    curve: ->
      curve = ''
      if @transformPoints?.length
        curve = @curveByPoints
      else if @curveByLivePoint
        curve = @curveByLivePoint
      curve

    curveByPoints: ->
      l = @pointsForIntersection.length
      line = ''
      if l > 0 and l < 3
        p = @pointsForIntersection[0]
        line = "M#{p.x} #{p.y} L#{p.x} #{p.y}"
      else
        p = @pointsForIntersection[0]
        line += "M#{p.x} #{p.y}"
        i = 1

        while i <= l-1
          p1 = @pointsForIntersection[i]
          p2 = @pointsForIntersection[i+1]

          line += " Q#{p1.x} #{p1.y} #{p2.x} #{p2.y}"
          i = i + 2

      return line
  watch:
    point: (val) ->
      if @draw and val[0] and val[1]


        @livePoints.push val
        @tmpPointsForScenRect.push val

        if @livePoints.length > 3
          lastTwoPoints = @livePoints.slice -2

          endPoint =
            {
              x: (lastTwoPoints[0][0] + lastTwoPoints[1][0]) / 2
              y: (lastTwoPoints[0][1] + lastTwoPoints[1][1]) / 2
            }
          # console.log @livePoints.length, @curveByLivePoint, lastTwoPoints
          @curveByLivePoint += " Q#{lastTwoPoints[0][0]} #{lastTwoPoints[0][1]} #{endPoint.x} #{endPoint.y}"
        else if @livePoints.length
          p = @livePoints.slice 0,1
          # console.log p
          @curveByLivePoint += "M#{p[0][0]} #{p[0][1]}"
        if @livePoints.length > 20
          @livePoints = @livePoints.slice -10

    draw: (val) ->
      if !val
        @curveByLivePoint = ''
        @livePoints = []
        @tmpPointsForScenRect = []
