
























import DialogTemplate from '@/components/DialogTemplate'
import ProjectSettings from '@/components/Project/Settings'
import CollabUser from '@/components/Project/CollabUser.vue'
import Public from '@/components/Project/Public.vue'
import Name from '@/components/Project/Name.vue'

export default
  name: "DialogCreateProject"
  inject: ['provideApp']
  components: { DialogTemplate, ProjectSettings, CollabUser, Public, Name }
  data: ->
    projectAddUser: false
    projectTitle: ''
    openProjectRights: null
    projectUsers: {}
  computed:
    show:
      get: ->
        !!@provideApp.dialogCreateProjectTeamId
      set: (val) ->
        @provideApp.dialogCreateProjectTeamId = val
    team: ->
      @provideApp.dialogCreateProjectTeamId
    roles: ->
      @firebind "/teamAccess/#{@team}/roles"
    rolesWithoutOwner: ->
      result = {}
      for k,v of @roles
        result[k] = v if v != 'owner' and !@projectUsers[k]
      result
    projectsCount: ->
      teamProjects = @firebind "/teams/#{@team}/projects"
      count = Object.keys teamProjects
      count
  methods:
    createProject: ->
      @$store.dispatch 'project/createProject',
        data:
          team: @provideApp.dialogCreateProjectTeamId
          title: @projectTitle
          public: @openProjectRights
          users: @projectUsers
      @show = false
    publicChange: (val) ->
      rights = if val then val else null
      if rights == true
        rights = 'view'
      @openProjectRights = rights
    selectRights: ({ userId, rights})->
      @$set @projectUsers, userId, rights
    addToProject: (userId) ->
      @$set @projectUsers, userId, 'view'
    deleteFromProject: (userId) ->
      @$delete @projectUsers, userId

    hotkeyBlocker: (e) ->
      # allow undo/redo
      if e.ctrlKey && e.keyCode in [ 89, 90 ]
        return
      # block other hotkeys
      e.stopPropagation()
      # block ctrl +/-/0
      if e.ctrlKey && e.keyCode in [ 187, 189, 48, 107, 109, 45, 43, 96 ]
        e.preventDefault()

