








export default
  name: 'RelationPlus'
  inject: ['provideCanvas']
  props: ['point', 'side']
  data: ->
    isMounted: false
  computed:
    crossStyle: ->
      if @isMounted
        return
          transform: "rotate(45deg)"
    pluStyle: ->
      x = @point.x - 10
      y = @point.y - 10
      return
        transform: "translate(#{x}px, #{y}px)"
  methods:
    hidePlus: ->
      @provideCanvas.relationPlusPoint.active = false
      @provideCanvas.relationPlusPoint.relation.drag.point = false


  mounted: ->
    setTimeout (=> @isMounted = true), 100


