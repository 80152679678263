import { generateSort, getOwnerUID } from '@/utils'
import { firebind } from '@/utils'
import { generateConnectionId } from '@/utils'
import { sendAnalytics } from '@/utils'

genTitle = (path)->
  connection = await firebind path
  webjet = await firebind connection.src
  if webjet.data?.title
    return webjet.data.title
  else if webjet.data?.titleOrigin
    return webjet.data.titleOrigin
  else
    content = webjet?.data?.text
    if webjet?.data?.contentDelta?
      content = null # do not use legacy text even if editor data is empty
      for op in webjet.data.contentDelta.ops
        if op.insert?.trim().length>0
          content = op.insert
          break
    if content
      lines = content.split("\n")
      for line in lines
        if line.trim().length > 0
          title = line.trim().substr(0, 50)
          if line.trim().length > 50 then title += "..."
          return title
  return null

export default
  create: ({ rootState }, { dest, path, title }, { dispatch, redo, undo })->
    targetBoard = await firebind dest
    unless title
      if path and await genTitle(path)
        title = await genTitle(path)
      else
        title = 'New page' # translate
    sort = generateSort(targetBoard, true, 'wejes')
    { webjetPath } = await dispatch 'webjet/create',
      dest: dest
      boardPath: dest
      category: 'weje'
      group: 'wejes'
      connection: Object.assign {}, sort
      webjet:
        data:
          title: title
    id = webjetPath.split('/').pop()
    board = dest.split('/').pop()
    user = await getOwnerUID dest
    creator = rootState.uid
    redo 'weje/CREATE_WEJE_RECORD', { id, board, user, creator }
    undo 'weje/REMOVE_WEJE_RECORD', { id }
    if path
      await dispatch 'weje/createPage',
        dest: webjetPath
        boardPath: dest
        path: path
    sendAnalytics 'new_publication_created'
    return { webjetPath }

  createPage: (_, { dest, path, boardPath }, { dispatch })->
    sort = generateSort(dest, true)
    { webjetPath } = await dispatch 'webjet/create',
      dest: dest
      boardPath: boardPath
      category: 'weje/page'
      connection: Object.assign {}, sort
    if path
      await dispatch 'webjet/transfer',
        dest: webjetPath
        boardPath: boardPath
        items: [{path}]
        before: true
        mode: 'clone'

  trashSiteConnection: ({ rootGetters, rootState }, { path, data, boardSrc }, operation)->
    connection = await firebind path
    webjet = await firebind connection.src
    src = webjet.$path
    dest = rootGetters.currentTrashSrc
    dest = boardSrc unless dest
    unless dest
      throw 'trashConnection: without currentTrashSrc'
    newConnectionData = Object.assign {}, connection, data
    newConnectionData.id = generateConnectionId()
    await operation.dispatch 'webjet/createConnection',
      dest: dest
      src: src
      data: newConnectionData
      group: 'trashSites'
      weak: true
    await operation.dispatch 'webjet/removeConnection',
      path: path
      force: true
    sendAnalytics 'publication_deleted'

  clearTrash: ({ rootGetters }, {}, { dispatch })->
    trashPath = rootGetters.currentTrashSrc
    trash = await firebind trashPath
    if trash?.connections?.trashSites
      for k, connection of trash.connections.trashSites
        await dispatch 'webjet/updateConnection',
          path: connection.$path
          data:
            trashClear: true

  setPublic: (ctx, {path, value}, { redo, undo, dispatch })->
    currentUid = ctx.rootState.uid
    currentUser = await firebind '/users/'+currentUid
    webjet = await firebind path
    if webjet?.category == 'weje'
      redo 'weje/SET_PUBLIC', {path: path, value: value}
      undo 'weje/SET_PUBLIC', {path: path, value: webjet?.public||null}
      await dispatch 'webjet/onUpdated', { path: path }
      if value == true
        sendAnalytics 'publication_make_public'


  sharePrivate: (ctx, {path, uid, mode}, { redo, undo, dispatch })->
    currentUid = ctx.rootState.uid
    webjet = await firebind path
    boardSrc = Object.values(webjet.connections.parents)[0].src
    boardOwner = await getOwnerUID boardSrc
    if webjet?.category == 'weje'
      if mode && webjet.private?[uid] == mode or not mode && not webjet.private?[uid]
        return # already shared

      redo 'weje/SHARE_PRIVATE', { path: path, uid, mode: mode||null}
      undo 'weje/SHARE_PRIVATE', { path: path, uid, mode: webjet.private?[uid]||null }
      await dispatch 'webjet/onUpdated', { path: path }
      sendAnalytics 'user_invited_to_publication'
