



















import { userLimits } from '@/utils'

export default
  name: "FileSizeLimitDialog"
  inject: ['provideApp']
  computed:
    fileSizeLimit: ->
      10 # FIXME: add team-dependent value when it is needed
      #userLimits(@$store.state.uid)?.fileSize
    teamId: ->
      @provideApp.fileSizeDialogTeamId
  methods:
    upgradePlan: ->
      @provideApp.fileSizeDialog = false
      if @teamId
        @$router.push { name: 'TeamPlan', params: { teamId: @teamId } }

