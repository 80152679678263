












import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
#import Quill from 'quill'
import { debounce } from '@/utils'
import { isFirefox, isSafari, isIOSSafari } from '@/utils'
import { selectCreatedWebjetsWithoutCanvas } from '@/utils'

Quill = undefined

export default
  name: 'WEditor'
  inject: { provideWebjet: { default: false }, provideCanvas: { default: false }, provideScene: { default: false }, provideSidebar: { default: false }, provideApp: { default: false }}

  data: ->
    editor: null
    editorContainer: null
    editmode: false
    activateInProgress: false
    hideTooltip: false
    range: null
    caretToStart: false
    doubleBackspace: false

  computed:
    styleCloseEdit: ->
      if @provideSidebar
        scale = 1
      else
        scale = 1 / @provideCanvas.scale
        scale = 1 if scale < 1
        scale = 4 if scale > 4
        scale = +scale.toFixed(1)
      return
        transform: "translateX(-50%) scale(#{scale})"
    text: ->
      @provideWebjet.webjet.data?.contentHtml || @provideWebjet.webjet.data?.html || @provideWebjet.webjet.data?.text
    isRichText: ->
      @provideWebjet.webjet.data?.contentDelta || @provideWebjet.webjet.data?.html
    classes: ->
      return false if @provideWebjet.isReadOnly
      return
        'editmode': @editmode
        'empty': @provideWebjet.isTextContentEmpty
        'shift-pressed': @hideTooltip
    placeholder: ->
      if FLAG.WEBJET_PARENT
        @$t("webjet.parentCard.editorPlaceholder")
      else
        @$t("webjet.editorPlaceholder")
    mozDragBlock: -> isFirefox

  watch:
    # turn off editor when webjet is left
    'provideWebjet.isSelected': (v) ->
      if !v
        @deactivateEditor()
    # sync editor data when changed by other user/other device
    'provideWebjet.webjet': (v) ->
      if @editor and @editmode
        Delta = Quill.import('delta')
        newDelta = new Delta(@provideWebjet.webjet.data?.contentDelta)
        oldDelta = @editor.getContents()
        diff = oldDelta.diff(newDelta)
        #console.log '!', diff
        if diff?.ops?.length>0
          #sel = @editor.getSelection()
          @editor.updateContents(diff, 'db_update')
          #if document.activeElement?.classList.contains('ql-editor')
          #  @editor.setSelection sel
    'placeholder': (v) ->
      if @editmode
        @updatePlaceholder(v)
    editmode: (v) ->
      if FLAG.WEBJET_PARENT
        toolbarElement = @editor.getModule('toolbar').container
        if v and toolbarElement
          toolbarElement.style.display = 'block'
        else if !v and toolbarElement
          toolbarElement.style.display = 'none'
  beforeDestroy: ->
    @deactivateEditor()

  mounted: ->
    # process pasted/collected text with editor on load to make it formatted from very beginning
    await @firebind @provideWebjet.src
    if (
      not @provideWebjet.webjet.data?.contentHtml and
      (@provideWebjet.webjet.data?.html or @provideWebjet.webjet.data?.text or @provideWebjet.webjet.data?.contentDelta) and
      @provideWebjet.webjet.meta?.createUser == @$store.state.uid
    )
      setTimeout =>
        await @loadEditor()
        @initEditor()

  methods:
    onPaste: (e) ->
      @provideWebjet?.pasteFromEditor(e)
    tapActivateHandler: (e) ->
      if isIOSSafari and !@editmode
        @activateEditor(e)
    activateEditor: (e) ->
      return if !@provideWebjet.isSelected # selection on first click, editor activation on second
      return if e? and (e.shiftKey || e.ctrlKey || e.altKey || e.metaKey)
      return if @activateInProgress
      return if @provideWebjet.isReadOnly
      return if @provideWebjet.isInTrash
      if @editmode
        if e? and e.type!='tap'
          if !e.target.matches('.ql-editor *')
            @editor.focus()
          e.stopPropagation()
        return
      @activateInProgress = true
      if @editor
        delta = @provideWebjet.webjet.data?.contentDelta
        @editor.setContents(delta)
        @updatePlaceholder()
      else
        await @loadEditor()
        @initEditor()
      # turn editor on
      @editmode = true
      @provideWebjet.editorIsActive = true if @provideWebjet.editorIsActive?
      @provideCanvas.activeEditor = this if @provideCanvas
      @preventDraggable(true)
      @editorContainer.focus()
      @activateInProgress = false
      @hideTooltip = false
      # position curson in editor to end of line near user's click
      setTimeout ( =>
        # can not use e.target here, because e.target is in .card-text, and we need element in .editor
        if not e? || e.type=='tap' || !@focusNode( document.elementFromPoint(e.clientX, e.clientY) )
          # when click is behind last element in editor
          key = @provideWebjet.webjet?.$path?.split('/').pop()
          if @provideWebjet?.splitWebjet == key
            @provideWebjet.splitWebjet = null
          else if @provideWebjet?.provideWebjet?.splitWebjet == key
            @provideWebjet.provideWebjet.splitWebjet = null
          else
            @focusNode( @editorContainer.querySelector '.ql-editor > :last-child' )
        @editor.focus()
        # position webjet for edit if applicable
        if @provideCanvas and @provideScene
          @provideCanvas.scenePositionForEdit @provideWebjet
      ), 10 # some very small delay for browser to switch from .card-text to .editor

    focusNode: (node) ->
      if node?
        blot = Quill.find(node)
        if blot?
          unless @caretToStart
            @editor.setSelection(@editor.getIndex(blot) + blot.length() - 1)
          return true
      return false

    updatePlaceholder: (text = @placeholder) ->
      if @editor and @editor.options.placeholder != text
        @editor.options.placeholder = text
        editorNode = @editorContainer.querySelector '.ql-editor'
        if editorNode?
          editorNode.setAttribute 'data-placeholder', text

    loadEditor: ->
      if !Quill?
        quillModule = await import(### webpackChunkName: "quill" ###'quill')
        Quill = quillModule?.default
        await import(### webpackChunkName: "quill" ###'@sakrat91/quill-paste-smart')
        magicUrlModule = await import(### webpackChunkName: "quill" ###'quill-magic-url')
        if magicUrlModule?
          await Quill.register('modules/magicUrl', magicUrlModule.default)

    initEditor: ->
      # Init Editor
      # modules

      re = /(?:(?:https?|ftps?|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/igm

      modules = {
          toolbar: [
            [{ header: [1, 2, 3, false] }]
            [
              'bold', 'italic', 'blockquote',
              # 'link',
              # { 'size': ['small', false, 'large', 'huge'] },
              { list: 'ordered' }, { list: 'bullet' }, { 'align': [] }
            ]
            #[{ 'color': [] }, { 'background': [] }]

            ['clean']
            ['chek'] if FLAG.WEBJET_PARENT
          ]
          clipboard:
            magicPasteLinks: false
            substituteBlockElements: false
          magicUrl:
            urlRegularExpression: re
            globalRegularExpression: re
          keyboard:
            bindings:
              dblBaskspaceToMerge:
                key: 'Backspace'
                offset: 0
                handler: (range, context) =>
                  @handlerBackspace(range, context)
                  return true
      }
      # editor
      theme = 'bubble'
      theme = 'snow' if FLAG.WEBJET_PARENT
      @editorContainer = @$el.querySelector('.editor')
      @editor = new Quill(@editorContainer, {
        debug: 'error'
        modules: modules
        #bounds: editorContainer
        placeholder: @placeholder
        scrollingContainer: @$el.querySelector('content')
        theme: theme
        # theme: 'bubble'
      })
      if FLAG.WEBJET_PARENT

        self = this
        handlerShiftEnter = () ->
          return false
        handlerEnter = () ->
          self.provideWebjet.enterFromEditor()
        handlerDown = () ->
          self.provideWebjet.downArrow()
        handlerUp = () ->
          self.provideWebjet.upArrow()
        onSelectionChange = (range) ->
          toolbarElement = self.editor?.getModule('toolbar')?.container
          if range and range?.length == 0
            self.range = range
          else
            self.range = null
          # position editor menu on change cursor caret position
          if range and toolbarElement
            if range.length == 0
              if self.provideCanvas
                toolbarElement.style.top = "-50px"
                toolbarElement.style.bottom = "auto"
              else
                toolbarElement.style.bottom = "-50px"
                toolbarElement.style.top = "auto"
              toolbarElement.classList.remove 'select-text'
            else
              { bottom, left } = self.editor.getBounds(range)
              if self.provideCanvas
                bottom = bottom / self.provideCanvas.scale
              else
                bottom = bottom
                toolbarElement.style.bottom = "auto"
              toolbarElement.style.top = "#{bottom + 20}px"
              toolbarElement.classList.add 'select-text'


            # finde cursor caret position and move scene if cursor not visible
            selectBound = self.editor?.getBounds(range)
            if self.provideCanvas
              wRect = self.provideWebjet.elementSceneRect
              scale = self.provideCanvas.scale
              scene = self.provideCanvas.scene

              # console.log wRect,scene, selectBound
              bottomSelectionPoint = wRect.y1 + selectBound.bottom / scale
              topSelectionPoint = wRect.y1 + selectBound.top / scale
              bottomDiff = (scene.y2 - bottomSelectionPoint) * scale
              topDiff =  (topSelectionPoint - scene.y1) * scale
              offsetheigt = 20 * scale
              offsetheigt = selectBound.height if range.length == 0
              offsetBottom = 100 - bottomDiff + offsetheigt
              offsetTop = offsetheigt - topDiff
              if bottomDiff < 100
                self.provideCanvas.position.y -= offsetBottom
              else if topDiff < offsetheigt
                self.provideCanvas.position.y += offsetTop




        registerChekBtnFunction = (range) ->
          cancelEdit = (e) ->
            self.deactivateEditor()
            e.preventDefault()
            e.stopPropagation()
          mouseHandlercancelEdit = (e) ->
            e.preventDefault()
            e.stopPropagation()
          toolbarElement = self.editor.getModule('toolbar').container
          toolbarElement.getElementsByClassName("ql-chek")[0].addEventListener 'click', cancelEdit, {capture: true}
          toolbarElement.getElementsByClassName("ql-chek")[0].addEventListener 'mouseup', mouseHandlercancelEdit, {capture: true}
          toolbarElement.getElementsByClassName("ql-chek")[0].addEventListener 'mousemove', mouseHandlercancelEdit, true
          toolbarElement.setAttribute("draggable", true)
          preventDrag = (e) ->
            e.preventDefault()
            e.stopPropagation()
          toolbarElement.addEventListener 'dragstart', preventDrag, false
        handlerWditorChange = (eventName, ...args) ->
          if eventName == 'selection-change'
            range = args[0]
            onSelectionChange range
          if eventName == 'text-change'
            l = self.editor.getLength()
            insert = args[0]?.ops[1]?.insert == '\n'
            insertInEnd = args[0]?.ops[0]?.retain == l - 2
            insertInEmpty = args[0]?.ops[0]?.insert == '\n'
            if (insertInEmpty or insert) and args[2] == 'user'
              if self.provideWebjet.firstEnterPressed and self.provideWebjet.oldDelta
                if insert and insertInEnd
                  newDelta = null
                  replaceDelta = self.provideWebjet.oldDelta
                  oldDelta = self.provideWebjet.oldDelta
                  self.editor.setContents self.provideWebjet.oldDelta
                  newHtml = self.editor.root.innerHTML
                  oldHtml = newHtml
                else
                  replaceDelta = self.provideWebjet.oldDelta.slice(0, self.provideWebjet.oldDeltaRetain)
                  oldDelta = self.provideWebjet.oldDelta
                  oldHtml = self.provideWebjet.oldHtml
                  self.editor.setContents replaceDelta
                  newHtml = self.editor.root.innerHTML
                  newDelta = self.provideWebjet.oldDelta.slice(self.provideWebjet.oldDeltaRetain, self.provideWebjet.oldDelta.length())
                  while newDelta.ops?[0]?.insert?.startsWith('\n')
                    newDelta = newDelta.slice(1, newDelta.length())

                self.provideWebjet.firstEnterPressed = false
                self.provideWebjet.createCardAfter(newDelta, oldDelta, replaceDelta, oldHtml, newHtml)
              else
                self.provideWebjet.oldDelta = args[1]
                self.provideWebjet.oldHtml = self.provideWebjet.webjet?.data?.contentHtml
                self.provideWebjet.oldDeltaRetain = args[0]?.ops[0]?.retain||0
                self.provideWebjet.firstEnterPressed = true
            else
              self.provideWebjet.firstEnterPressed = false

        @editor.keyboard.bindings[13].unshift({ key: 13, shiftKey: true, handler: handlerShiftEnter})
        @editor.keyboard.bindings[13].unshift({ key: 13 , handler: handlerEnter})
        @editor.keyboard.addBinding({ key: 40 , handler: handlerDown})
        @editor.keyboard.addBinding({ key: 38 , handler: handlerUp})
        # @editor.on('selection-change', onSelectionChange)
        @editor.once('selection-change', registerChekBtnFunction)
        @editor.on('editor-change', handlerWditorChange)
        # @editor.keyboard.addBinding({ key: 'B' }, handler)

      @editor.clipboard.addMatcher Node.ELEMENT_NODE, (node, delta) ->
        # substitute href to link text if it's missed because Quill fails overwise
        if node.nodeName == 'A' and delta.ops.length==0
          delta.ops[0] = { insert: node.attributes?['href']?.value||' ' }
        delta

      # workaround to avoid data loss when using google translate
      # code from https://github.com/quilljs/quill/issues/2286#issuecomment-882705135
      updateScroll = @editor.scroll.update.bind @editor.scroll
      @editor.scroll.update = (mutations, context) =>
        return unless @editor.isEnabled()
        updateScroll mutations, context

      scrollEnable = @editor.scroll.enable.bind @editor.scroll;
      @editor.scroll.enable = (enabled = true) =>
        @editor.container.classList.toggle 'notranslate', enabled
        scrollEnable enabled

      @editor.container.classList.toggle 'notranslate', @editor.isEnabled()
      # workaround end

      # tooltip custom positioning
      prev_bounds = null
      @editor.theme.tooltip.position = (bounds) =>
        selection = @editor.getSelection()
        if selection
          bounds = @editor.getBounds(selection.index, selection.length)

        tooltip = @editor.theme.tooltip.root
        scale = @provideCanvas.scale
        webjetBounds = @provideWebjet.$el.getBoundingClientRect()
        tooltipHeight = 40*scale # WARN: 40px is a value from CSS, we cann't calculate it because tooltip can has "display:none" at this point
        top = bounds.bottom + @editor.root.scrollTop
        verticalShift = bounds.bottom - bounds.top + tooltipHeight

        if prev_bounds is null or Math.abs(prev_bounds.bottom-bounds.bottom)>0.01
          # new selection or selection from top to bottom
          if top + tooltipHeight + webjetBounds.top > window.innerHeight and (top - verticalShift + webjetBounds.top) >= 0
            tooltip.style.top = "#{(top - verticalShift) / scale}px"
            tooltip.classList.add 'ql-flip'
          else
            if top + tooltipHeight + webjetBounds.top > window.innerHeight
              top = window.innerHeight - webjetBounds.top - tooltipHeight
            tooltip.style.top = "#{top / scale}px"
            tooltip.classList.remove 'ql-flip'
        else if prev_bounds.bottom==bounds.bottom and prev_bounds.top!=bounds.top
          # selection from bottom to top
          if (top - verticalShift + webjetBounds.top) < 0 and top + tooltipHeight + webjetBounds.top <= window.innerHeight
            tooltip.style.top = "#{top / scale}px"
            tooltip.classList.remove 'ql-flip'
          else
            if (top - verticalShift + webjetBounds.top) < 0
              top = verticalShift - webjetBounds.top
            tooltip.style.top = "#{(top - verticalShift) / scale}px"
            tooltip.classList.add 'ql-flip'

        prev_bounds = bounds



        return 0 #shift
      # tooltip custom positioning end

      storeDelta = (v, o, source) =>
        return if @provideWebjet.isReadOnly
        @$emit 'text-change', @editor.getText()
        if source=='db_update' or o? && @editor.getContents().diff(o)?.ops.length==0
          return # skip unwanted and unnecessary updates
        delta = @editor.getContents()
        if source=='user' && typeof delta?.ops?[0]?.insert == 'string' && delta?.ops?[0]?.insert?.trim().length == 0 && delta?.ops?[0]?.attributes && not delta.ops[0].attributes.list
          delete delta.ops[0].attributes
          diff = @editor.getContents().diff(delta)
          @editor.updateContents(diff)
          return # because storeDelta is called again after updateContents
        @$store.dispatch 'webjet/updateEditorContent',
          path: @provideWebjet.webjet.$path
          contentDelta: @editor.getContents()
          contentHtml: @editor.root.innerHTML

      #debouncedStoreDelta = debounce storeDelta, 500

      # Load initial content
      delta = @provideWebjet.webjet.data?.contentDelta
      if delta?
        @editor.setContents(delta)
        unless @provideWebjet.webjet.data?.contentHtml
          storeDelta()
      else
        # Check if the is some legacy "text" available
        html = @provideWebjet.webjet.data?.html
        text = @provideWebjet.webjet.data?.text
        if html
          #console.log "Importing HTML:", html
          @editor.pasteHTML(html)
          await @firebind @provideWebjet.connection.src
          storeDelta()
        else if text?.length > 0
          Delta = Quill.import('delta')
          textDelta = new Delta().insert(text)
          #console.log "Legacy text: ", textDelta
          @editor.setContents(textDelta)
          await @firebind @provideWebjet.connection.src
          storeDelta()

      @editor.on "text-change", storeDelta #debouncedStoreDelta

      @editorElement = @editorContainer.querySelector('.ql-editor')
      @editorElement.addEventListener 'blur', (e) =>
        return if e.relatedTarget?.matches('.ql-tooltip *')
        return if e.relatedTarget?.matches('.ql-clipboard')
        return if e.relatedTarget?.matches('.ql-toolbar *')
        return if e.relatedTarget?.matches('.choice-btns')
        @deactivateEditor()
      @editorElement.addEventListener 'dragstart', (e) =>
        console.log e
      @editorElement.addEventListener 'paste', @onPaste, {capture: true}

    deactivateEditor: ->
      @editmode = false
      @editor.blur() if @editor?
      @preventDraggable(false)
      @provideWebjet.editorIsActive = false if @provideWebjet.editorIsActive?
      @provideCanvas.activeEditor = null if @provideCanvas

    handlerBackspace: (range, context) ->
      #console.log '!', range, context, @doubleBackspace
      if range.index==0 && range.length==0
        if @doubleBackspace
          #console.log '!here'
          if FLAG.WEBJET_PARENT && @provideWebjet?.provideWebjet
            # try merge this webjet into previous in stack
            destId = @provideWebjet.provideWebjet.id
            sortKey = @provideWebjet.connection.sortKey||0
            destSortKey = undefined
            #console.log '>', @provideWebjet.provideWebjet.webjet.connections?.items
            for id, conn of @provideWebjet.provideWebjet.webjet.connections?.items
              #console.log '?', id, conn, destId, destSortKey, sortKey
              #console.log '??', conn.sortKey < sortKey, !destSortKey, conn.sortKey >= destSortKey, conn.sortKey < sortKey && (!destSortKey || conn.sortKey >= destSortKey)
              if conn.sortKey < sortKey && (!destSortKey || conn.sortKey >= destSortKey)
                w = document.getElementById(id)?.webjet
                #console.log '???', w, w?.$options.name
                if w?.$options.name == 'WebjetCard'
                  destId = id
                  destSortKey = conn.sortKey
            destWebjet = document.getElementById(destId)?.webjet
            #console.log '!!!', destId, document.getElementById(destId), destWebjet, destWebjet?.$options.name, destWebjet?.connection?.$path, destWebjet?.webjet?.$path, @provideWebjet.webjet.$path, @provideWebjet.provideWebjet.webjet.$path
            if destWebjet?.$options.name == 'WebjetCard'
              # previous card is found, perform merge
              Delta = Quill.import('delta')
              destDelta = new Delta(destWebjet.webjet.data?.contentDelta)
              mergeDelta = new Delta(@provideWebjet.webjet.data?.contentDelta)
              resultDelta = destDelta.concat(mergeDelta)
              oldHtml = @provideWebjet.webjet.data?.contentHtml
              @editor.setContents resultDelta
              newHtml = @editor.root.innerHTML
              @editor.setContents mergeDelta

              @deactivateEditor()
              selectCreatedWebjetsWithoutCanvas destWebjet.connection.$path

              await @$store.dispatch 'webjet/mergeTextCard',
                dest:
                  path: destWebjet.webjet.$path
                  oldDelta: destWebjet.webjet.data?.contentDelta
                  oldHtml: destWebjet.webjet.data?.contentHtml
                  newDelta: resultDelta
                  newHtml: newHtml
                merge:
                  path: @provideWebjet.webjet.$path
                  oldDelta: mergeDelta #@provideWebjet.webjet.data?.contentDelta
                  oldHtml: oldHtml #@provideWebjet.webjet.data?.contentHtml
                parent:
                  path: @provideWebjet.provideWebjet.webjet.$path
                  connection: @provideWebjet.connection.$path

              # try set cursor to correct position
              setTimeout ( =>
                destEditor = destWebjet.$refs.editor.editor
                destEditor.setSelection destDelta.length()
              ), 200 # FIXME: this delay is necessary for destEditor is activated.
                     # This moment is quite undetectable.
          @doubleBackspace = false
        else
          @doubleBackspace = true
      else
        @doubleBackspace = false

      return true

    hotkeyBlocker: (e) ->
      #console.log '?' ,e.type, e.keyCode, e.key, e.shiftKey
      if e.type=='keydown' and e.key!='Backspace'
        @doubleBackspace = false
      if e.type=='keydown' and e.key!='Enter'
        @provideWebjet.firstEnterPressed = false
      if e.type=='keydown' and e.key=='Shift'
        #console.log "!shift down"
        @hideTooltip = true
      if e.type=='keyup' and e.key=='Shift'
        #console.log "!shift up"
        @hideTooltip = false
      if e.type=='keydown' and e.keyCode == 27
        @deactivateEditor()
      if e.type=='keydown' and (e.keyCode == 13 and e.ctrlKey)
        # turn editor off on ctrl+enter
        @deactivateEditor()

        if FLAG.WEBJET_PARENT
          event = new KeyboardEvent(e.type, {keyCode: 13, ctrlKey: e.ctrlKey})
          if @provideScene
            @provideScene.$el.dispatchEvent event
          return
      if e.type=='keydown' and (e.keyCode == 13 and e.shiftKey)
        if FLAG.WEBJET_PARENT
          # create parentCard on shift+enter
          @deactivateEditor()
          Object.defineProperty e, 'eventByHotKey', {value:true}
          return
      # so far we just block key events from editor to not interfere with app hotkeys
      # later some logic can be implemented
      e.stopPropagation()
      # block ctrl +/-/0
      if e.ctrlKey && e.keyCode in [ 187, 189, 48, 107, 109, 45, 43, 96 ]
        e.preventDefault()

    editorDragstart: (e)->
      e.preventDefault()
      e.stopPropagation()
      return

    preventDraggable: (v)->
      if @mozDragBlock
        @$el.dispatchEvent new CustomEvent 'preventdrag', { detail: { prevent: v }, bubbles: true, cancelable: true }

    editorMousedown: (e)->
      e.stopPropagation() # just in case for not triggering draggableMousedown, etc
      if isSafari and e.target.matches('.ql-tooltip *')
        e.preventDefault() # otherwise safari mystically fails to process clicks
      if not e.target.matches('.ql-tooltip *') and e.buttons==1
        @hideTooltip = true

    editorMouseup: (e)->
      @hideTooltip = false

    editorMouseleave: (e)->
      @hideTooltip = false
