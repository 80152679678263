





import { webjet, positionable } from './mixins'

export default
  name: 'WebjetErrorAccess'
  mixins: [webjet, positionable]


