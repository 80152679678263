































import { sendAnalytics,userLimits } from '@/utils'
import { mdiClose, mdiChevronDown, mdiCheck, mdiContentCopy  } from '@mdi/js'
import Vue from 'vue'

import CreateBoardCollabItem from './CreateBoardCollabItem.vue'
#import ModalUpgradePlan from '@/components/ModalUpgradePlan.vue'

export default
  name: 'CreateBoardCollaborations'
  inject:
    provideBoardCreateDialog: { default: false }
    provideApp: { default: false }
  provide: ->
    provideCreateBoardCollaborations: this
  #components: {ModalUpgradePlan,CreateBoardCollabItem}
  components: {CreateBoardCollabItem}
  data: ->
    #showDialogUpgradePlan: false
    uidForInvite: ''
    inviteLoading: false
    canSelect: 'edit'
    iconArrow: mdiChevronDown
    iconCheck: mdiCheck
    iconCopy: mdiContentCopy
    icon: mdiClose
    mail: ''
    name: ''
    errorEmail: false
    errorEmailText: ''
    selectedUser: []
    activeSelectUser:false
    editLicences: [ 'edit', 'manage', 'owner' ]
    viewLicences: [ 'view', 'comment' ]

  computed:
    countEditorsForBoard: ->
      count = 0
      for user in @selectedUser
        count++ if user.license in @editLicences
      count
    chekUidInvite: ->
      if @uidForInvite and
         @uidForInvite != @$store.state.uid
        return true
      else
        return false
    editorsLimit: -> userLimits(@myUid)?.boardEditors
    myUid: -> @$store.state.uid
    teamId: ->
      "team_#{@myUid}"
    roles: ->
      @firebind "/teamAccess/#{@teamId}/roles"
    boardsUsers: ->
      @firebind "/teamAccess/#{@teamId}/boards"
    membersInTeam: ->
      memebers = Object.keys @roles
      memebers
    teamSize: ->
      teamSizeData = @firebind("/teamBilling/#{@teamId}/teamSize")
      teamSize = teamSizeData?.$value
      return 3 if teamSize == null
      teamSize
    teamNoLimits: ->
      val = @firebind("/teamBilling/#{@teamId}/nolimits")
      val.$value
    limitIsOver: ->
      #if !@teamNoLimits and @allmembersInTeam >= @teamSize
      #  return true
      false
    guestWithEdit:->
      members = []
      for k,v of @boardsUsers
        if v and k != '*'
          for b,r of v
            if r == 'edit'
              members.push k
              break
      members
    allmembersInTeam: ->
      @membersInTeam.length + @guestWithEdit.length
    plan: ->
      plan = @firebind "/teamBilling/#{@teamId}/plan"
      if plan.$value == null
        return 'free'
      else
        plan.$value
    filteredCollaborators: ->
      collaborators = @collaborators
      text = @mail
      re = new RegExp text, 'i'
      filteredCollaborators = collaborators.filter (c) ->
        return true if c.email and re.test c.email
        return true if c.fname and re.test c.fname
        return true if c.lname and re.test c.lname
      if text.length < 1
        return collaborators
      filteredCollaborators
    collaborators: ->
      collaborators = @$store.getters.collaborators
      # return collaborators
      myUid = @myUid
      selectedUser = @selectedUser
      # filteredShared = @filteredCollaborators
      collaboratorsWithoutMe = collaborators.filter (c) ->
        return false if !c.email and !c.fname and !c.lname
        for sUser in selectedUser
          return false if c.uid == sUser.uid
        return true if c.uid != myUid
      collaboratorsWithoutMe
    titleLicense: ->
      title = @can.find (c) =>
        c.value == @canSelect
      return title?.text
    can: ->
      [
        {value: "view", text: @$t('board.share.canView')},
        {value: "edit", text: @$t('board.share.canEdit')}
      ]
  methods:
    onInputMail: ->
      @errorEmail = false
      @activeSelectUser = false
    downArrow: ->
      if @activeSelectUser == false
        @activeSelectUser = 0
      else if @activeSelectUser < @filteredCollaborators.length - 1
        @activeSelectUser += 1
    upArrow: ->
      if @activeSelectUser != 0
        @activeSelectUser -= 1
    enterInMail: ->
      if @activeSelectUser != false
        @mail = @filteredCollaborators[@activeSelectUser].email
        @showSelectUsers = false
        @activeSelectUser = false
      else
        @showSelectUsers = false
        @invite()
    invite: ->
      return unless @mail
      if not @mail.match /^\S+@\S+[\.][0-9a-z ]+$/g
        @errorEmail = true
        return

      @uidForInvite = (await @firebind "/emails/#{@mail.replace(/\./g, ',')}/uid")?.$value unless @uidForInvite
      if @uidForInvite and @chekUidInvite
        if @canSelect == 'edit' and @limitIsOver
          @provideApp.dialogMaxLimitTeamId = @teamId
          @provideApp.dialogMaxLimitTeam = true
          return
        @inviteLoading = true
        user = {mail: @mail, uid: @uidForInvite, license: @canSelect}
        # if @canSelect in @editLicences and @editorsLimit and @countEditorsForBoard >= @editorsLimit
        #   user.license = 'view'
        @selectedUser.push user
        @mail = ''
        @uidForInvite = ''
        @inviteLoading = false
    selectUser: (uid) ->
      user = @collaborators.find (u) -> u.uid == uid
      user.license = 'view'
      @selectedUser.push user if user
    changeLicenseSelectedUser: (uid,license) ->
      #if license in @editLicences and @countEditorsForBoard >= @editorsLimit
      #  @showDialogUpgradePlan = true
      #  sendAnalytics 'editors_limit_popup'
      #  return
      index = @selectedUser.findIndex (u) -> u.uid == uid
      user = Object.assign {}, @selectedUser[index]
      user.license = license
      Vue.set @selectedUser, index, user
    removeFromSelected: (uid) ->
      index = @selectedUser.findIndex (u) -> u.uid == uid
      @selectedUser.splice index, 1
    changeLicense: (license) ->
      @canSelect = license
