import { firebind, getPath } from '@/utils'
import router from '@/router'

export watchInbox = (store) ->
  store.watch (-> store.getters.inboxBoards), (value)->
    invite = null
    if store.state.route.name == 'Wait' && store.state.route.query.invitecode
      invite = await firebind "/inviteLinks/#{store.state.route.query.invitecode}"
    for item in value
      desktopItem = await firebind "desktop/connections/items/#{item.id}"
      #console.log '?', item, desktopItem
      if desktopItem.$value == null
        exists = false
        for k,v of store.getters.boards
          if v.src == item.src
            exists = true
            break
        if !exists
          await store.dispatch 'webjet/createConnectionWithoutHistory',
            dest: 'desktop'
            group: 'items'
            key: item.id
            src: item.src
            data: { unread: true }
          if invite?
            src = item.src
            src = src.substring(9) if src.startsWith 'firebase:'
            if src == invite.src
              router.push { name: 'Board', params: { boardId: invite.src.split('/').pop() } }
              invite = null

      store.dispatch 'webjet/removeInboxItem', { key: item.id }
