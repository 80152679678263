








import ContextBtn from './ContextBtn'
export default
  name: 'Collapse'
  components: { ContextBtn }
  priority: 0

  if: (selection)->
    everyCan = selection.every (w)-> w.isExpandable and w.canExpand
    someExpanded = selection.some (w)-> w.isExpanded
    everyCan and someExpanded

  computed:
    selection: -> @$store.getters['webjet/selected']

  methods:
    action: ->
      items = @selection.filter (w)-> w.isExpanded
      items = items.map (w)-> { path: w.src }
      @$store.dispatch 'webjet/collapse', { items }
