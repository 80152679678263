import { firebind } from '@/utils'

export teamTitle = (teamId) ->
  t = firebind "/teams/#{teamId}/title"
  if t?.$value
    tilte = t?.$value
  else if teamId.startsWith 'team_'
    uid = teamId.split('_')[1]
    firstUserName = firebind '/users/'+uid+'/firstName'
    lastUserName = firebind '/users/'+uid+'/lastName'
    email = firebind '/users/'+uid+'/email'
    tilte = "#{firstUserName.$value || lastUserName.$value || email.$value?.split('@')[0] }'s team"
  return tilte

export projectTitle = (projectId) ->
  t = firebind "/projects/#{projectId}/title"
  if !t?.$value and projectId.startsWith('project_')
    title = 'Project 1'
  else
    title = if t?.$value then t?.$value else 'Untitled'
  title

export teamProjectsLimitIsOver = (teamId) ->
  return false
  teamNoLimits = firebind("/teamBilling/#{teamId}/nolimits")
  return false if teamNoLimits?.$value
  plan = firebind "/teamBilling/#{teamId}/plan"
  if plan.$value == null
    plan = 'free'
  else
    plan = plan?.$value
  return false if plan in ['business', 'ltd']
  teamProjects = firebind "/teams/#{teamId}/projects"
  teamProjectsCount = Object.keys(teamProjects).length
  if plan == 'free'
    return true if teamProjectsCount >= 1
  else
    return true if teamProjectsCount >= 10
  return false
