
















import SidebarObject from '../SidebarObject'
export default
  name: 'DateItem'
  components: {SidebarObject}
  inject: ['provideCanvas']
  props:
    src: String
  data: ->
    picker: null,
    editmode: false
    done: false
    timeout: false
    datetime: null
  mounted: ->
    @tick()
    @formatPicker()
  beforeDestroy: ->
    clearTimeout @timeout
  computed:


    webjetTime: () ->
      @webjet?.data?.datetime

    pickerTime: () ->
      new Date(@picker).getTime() if @picker

    timeLeft: () ->
      @timeout
      @datetime - Date.now()

    daysLeft: () ->
      Math.floor(@timeLeft / (24 * 60 * 60 * 1000))

    minutesLeft: () ->
      Math.floor(@timeLeft / (60 * 1000))

    hoursLeft: () ->
      Math.floor(@timeLeft / (60 * 60 * 1000))

    tickDelay: ->
      return 1000 unless @datetime
      return 24 * 60 * 60 * 1000 if @timeLeft < 0
      delay = @timeLeft / 100
      delay = 1000 if @minutesLeft < 100
      delay

    pickActive: () ->
      return true if !@datetime
      return @isSelected and @editmode

    completed: () ->
      @webjet?.data?.completed || false

    status: () ->
      return 'done' if @completed
      return 'delay' if @daysLeft < 0 or @minutesLeft < 1
      return 'today' if @daysLeft == 0 and @minutesLeft >= 60
      return 'hours' if @minutesLeft < 60 and @minutesLeft >= 0
      return 'empty'

    statusText: () ->
      return 'Delay' if @status == 'delay'
      return 'Done' if @status == 'done'
      return 'Today' if @status == 'today'

      return "#{@daysLeft} days" if @daysLeft > 1
      return "#{@daysLeft} day" if @daysLeft == 1 and @minutesLeft > 60
      return "#{@minutesLeft} min" if @minutesLeft == 1
      return "#{@minutesLeft} mins" if @minutesLeft < 60

    dateString: () ->
      return @picker.split('T')[0] if @picker
      return new Date(@datetime).toLocaleDateString() if @datetime

    timeString: () ->
      return @picker.split('T')[1] if @picker

      if @datetime
        hours = @formatHours new Date(@datetime).getHours()
        minutes = @formatMinutes new Date(@datetime).getMinutes()
        return hours + ':' + minutes

    classes: ->
      return 'status-done' if @completed
      return
        'status-today': @status == 'hours' or @status == 'today'
        'status-delay': @status == 'delay'
        'status-empty': @status != 'delay' and @status != 'today'

  methods:
    click: ->
      src = @webjet.$path.split('/').slice(-2).join('/')
      path = @$route.path
      @provideCanvas.goToWebjet(src)
      if @provideCanvas.smallScreen
        @provideCanvas.$refs.sidebarContent.openClose()


    tick: ->
      clearTimeout @timeout
      @timeout = setTimeout @tick, @tickDelay


    formatMinutes: (minutes) ->
      if minutes < 10
        return '0' + minutes
      minutes

    formatHours: (hours) ->
      if hours < 10
        return '0' + hours
      hours

    formatPicker: ->
      return if !@datetime

      year = new Date(@datetime).getFullYear()
      month = new Date(@datetime).getMonth() + 1
      day = new Date(@datetime).getDate()

      day = '0' + day if day < 10
      month = '0' + month if month < 10

      @picker = year + '-' + month + '-' + day + 'T' + @timeString

    setCompleted: () ->
      @$store.dispatch 'webjet/setDateCompleted',
        path: @webjet?.$path,
        completed: !@completed

    setDateTime: () ->
      return if @keyEvent or !@datetime

      @$store.dispatch 'webjet/setDateTime',
        path: @webjet?.$path,
        datetime: @datetime

      @$refs?.picker?.blur()
      @editmode = false




  watch:
    tickDelay: ->
      @tick()

    isSelected: (v) ->
      if v and @editmode
        @$nextTick =>
          @$refs?.picker?.focus()
      else
        @editmode = false
        @$refs?.picker?.blur()

    pickActive:
      immediate: true
      handler: ->
        if @isSelected
          @$nextTick =>
            @$refs?.picker?.focus()
    webjetTime:
      immediate: true
      handler: (v) ->
        @datetime = v
        @formatPicker()
    pickerTime:
      immediate: true
      handler: (v) ->
        @datetime = v
        @formatPicker()



  firebind:
    connection: -> @src
    webjet: -> @connection.src
