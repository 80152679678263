































import NavbarContent from './NavbarContent.vue'
import Avatar from '@/components/Avatar.vue'
export default
  name: 'NavbarAccount'
  inject: ['provideApp']
  components:
    { NavbarContent, Avatar }

  firebind:
    user: -> '/users/'+@$store.state.uid

  computed:
    displayName: ->
      (@user?.firstName||'')+' '+(@user?.lastName||'')

  methods:
    toUnsorted: ->
      @provideApp.navbarClose() if @$vuetify.breakpoint.width < 500
      @$router.push { name: 'Unsorted' }
    toSettings: ->
      @provideApp.navbarClose() if @$vuetify.breakpoint.width < 500
      @$router.push { name: 'Account' }
    logout: ->
      @$store.dispatch 'signOut'
