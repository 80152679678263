import Vue from 'vue'
import Vuex from 'vuex'
import firebind from './firebind'
import undoredo from './undoredo'
import webjet from './webjet'
import user from './user'
import weje from './weje'
import team from './team'
import project from './project'
import inventory from './inventory'
import fresh from './fresh'
import socketBoards from './socket'
import router from '@/router'
import { sync } from 'vuex-router-sync'

plugins = []

# for vue deps analysis
#if IS_DEVELOPMENT
#  VuePursue = require '@/dev/vuePursue.js'
#  plugins.push VuePursue

Vue.use Vuex

import state from './state'
import getters from './getters'
import oldGetters from './oldGetters'
import mutations from './mutations'
import actions from './actions'

import { watchInbox } from './watch-inbox'
import { watchSocket } from './socket/watch-socket'


plugins.push watchInbox


plugins.push watchSocket

store = new Vuex.Store
  state: state
  getters: if FLAG.NEW_LOAD then getters else oldGetters
  mutations: mutations
  actions: actions
  modules: { firebind, undoredo, webjet, user, socketBoards, weje, inventory, team, fresh, project }
  plugins: plugins

sync store, router

window.devStore = store if IS_DEVELOPMENT
export default store
