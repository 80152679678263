export bezierCubicMinMax = (x0, y0, x1, y1, x2, y2, x3, y3) =>
      tvalues = []
      xvalues = []
      yvalues = []
      a = undefined
      b = undefined
      c = undefined
      t = undefined
      t1 = undefined
      t2 = undefined
      b2ac = undefined
      sqrtb2ac = undefined
      i = 0
      while i < 2
        if i == 0
          b = 6 * x0 - (12 * x1) + 6 * x2
          a = -3 * x0 + 9 * x1 - (9 * x2) + 3 * x3
          c = 3 * x1 - (3 * x0)
        else
          b = 6 * y0 - (12 * y1) + 6 * y2
          a = -3 * y0 + 9 * y1 - (9 * y2) + 3 * y3
          c = 3 * y1 - (3 * y0)
        if Math.abs(a) < 1e-12
          if Math.abs(b) < 1e-12
            ++i
            continue
          t = -c / b
          if 0 < t and t < 1
            tvalues.push t
            ++i
            continue
        b2ac = b * b - (4 * c * a)
        if b2ac < 0
          ++i
          continue
        sqrtb2ac = Math.sqrt(b2ac)
        t1 = (-b + sqrtb2ac) / (2 * a)
        if 0 < t1 and t1 < 1
          tvalues.push t1
        t2 = (-b - sqrtb2ac) / (2 * a)
        if 0 < t2 and t2 < 1
          tvalues.push t2
        ++i
      j = tvalues.length
      mt = undefined
      while j--
        t = tvalues[j]
        mt = 1 - t
        xvalues[j] = mt * mt * mt * x0 + 3 * mt * mt * t * x1 + 3 * mt * t * t * x2 + t * t * t * x3
        yvalues[j] = mt * mt * mt * y0 + 3 * mt * mt * t * y1 + 3 * mt * t * t * y2 + t * t * t * y3
      xvalues.push x0, x3
      yvalues.push y0, y3
      {
        min:
          x: Math.min.apply(0, xvalues)
          y: Math.min.apply(0, yvalues)
        max:
          x: Math.max.apply(0, xvalues)
          y: Math.max.apply(0, yvalues)
      }

export extremPointsCurves = (lines) =>
  re = new RegExp('M| C?')
  x1 = null
  y1 = null
  x2 = null
  y2 = null
  for l in lines
    curve = l.split re
    curve.shift()
    i = 0
    for point in curve
      curve[i] = parseFloat point
      i++

    m = { x: curve[0], y: curve[1] }

    i = 2

    while (i + 5) < curve.length
      minMax = bezierCubicMinMax m.x, m.y, curve[i], curve[i + 1], curve[i + 2], curve[i + 3], curve[i+ 4], curve[i + 5]
      m.x = curve[i + 4]
      m.y = curve[i + 5]
      x1 = minMax.min.x if x1 == null
      y1 = minMax.min.y if y1 == null
      x2 = minMax.max.x if x2 == null
      y2 = minMax.max.y if y2 == null

      x1 = minMax.min.x if minMax.min.x < x1
      y1 = minMax.min.y if minMax.min.y < y1
      x2 = minMax.max.x if minMax.max.x > x2
      y2 = minMax.max.y if minMax.max.y > y2
      i += 6

  return { x1, x2, y1, y2 }

export movingAverage = (arr, range) ->
  step = arr.length - range
  result = []
  i = 0
  while i < step
    limit = i + range
    x = 0
    y = 0
    j = i
    while j < limit
      x += arr[j][0]
      y += arr[j][1]
      j += 1
    x = x / range
    y = y / range
    x = +x.toFixed(2)
    y = +y.toFixed(2)
    result.push [x,y]
    i += 1
  return result

export bezierQudrMinMax = (pax, pay, pbx, pby, pcx, pcy) ->
  P = (x, y) ->
    @x = x
    @y = y
    return
  pointOnCurve = (P1, P2, P3, t) ->
    if t <= 0 or 1 <= t or isNaN(t)
      return false
    c1 = new P(P1.x + (P2.x - (P1.x)) * t, P1.y + (P2.y - (P1.y)) * t)
    c2 = new P(P2.x + (P3.x - (P2.x)) * t, P2.y + (P3.y - (P2.y)) * t)
    new P(c1.x + (c2.x - (c1.x)) * t, c1.y + (c2.y - (c1.y)) * t)
  P1 = new P(pax, pay)
  P2 = new P(pbx, pby)
  P3 = new P(pcx, pcy)

  tx = (P1.x - (P2.x)) / (P1.x - (2 * P2.x) + P3.x)
  ty = (P1.y - (P2.y)) / (P1.y - (2 * P2.y) + P3.y)
  Ex = pointOnCurve(P1, P2, P3, tx)

  xMin = if Ex then Math.min(P1.x, P3.x, Ex.x) else Math.min(P1.x, P3.x)
  xMax = if Ex then Math.max(P1.x, P3.x, Ex.x) else Math.max(P1.x, P3.x)
  Ey = pointOnCurve(P1, P2, P3, ty)
  yMin = if Ey then Math.min(P1.y, P3.y, Ey.y) else Math.min(P1.y, P3.y)
  yMax = if Ey then Math.max(P1.y, P3.y, Ey.y) else Math.max(P1.y, P3.y)
  # console.log xMin, xMax
  return {
      mx1: xMin
      my1: yMin
      mx2: xMax
      my2: yMax
    }
