import { firebind } from '@/utils'
import { getRef, getPath } from '@/utils'
import { nowFirebase } from '@/utils'

export default
  createProject: (_, { data }) ->
    projectkey = getRef('/projects').push().key
    @commit 'project/CREATE_PROJECT',
      key: projectkey
      data: data

  deleteProject: ({ rootState }, { projectId }, { dispatch }) ->
    if !projectId
      console.warn 'No project for delete'
      return
    uid = rootState.uid
    team = await firebind "/projects/#{projectId}/team"
    teamId = team?.$value
    projectRights = await firebind "/teamAccess/#{teamId}/projects/#{uid}/#{projectId}"
    teamRole = await firebind "/teamAccess/#{teamId}/roles/#{uid}"
    unless teamRole?.$value in ['owner','admin','manager']
      console.warn 'Cant delete project'
      return
    if teamRole?.$value == 'manager' and projectRights?.$value != 'manage'
      console.warn 'Cant delete project'
      return
    boards = await firebind "/projects/#{projectId}/boards"
    if boards and Object.keys(boards).length
      for k,v of boards
        await dispatch 'webjet/deleteBoard', { boardId: k }

    @commit 'project/DELETE_PROJECT_FROM_PROJECTS',
      projectId: projectId

    isOpenProject = await firebind "/teamAccess/#{teamId}/openProjects/#{projectId}"
    if isOpenProject?.$value
      @commit 'project/DELETE_PROJECT_FROM_TEAM_OPEN_PROJECT',
        projectId: projectId
        teamId: teamId

    teanAccessProjects = await firebind "/teamAccess/#{teamId}/projects"
    if Object.keys(teanAccessProjects)?.length
      for k,v of teanAccessProjects
        if v[projectId]
          @commit 'project/DELETE_PROJECT_FROM_TEAM_ACCESS',
            teamId: teamId
            userId: k
            projectId: projectId

    @commit 'project/DELETE_PROJECT_FROM_TEAM',
      projectId: projectId
      teamId: teamId


  updateProjectTitle: (_, { id , title})->
    @commit 'project/UPDATE_PROJECT_TITLE',
      id: id
      title: title

  updateProjectUserRole: (_, {projectId, teamId, userId, role}, { undo, redo })->
    oldData = await firebind "/teamAccess/#{teamId}/projects/#{userId}/#{projectId}"
    oldRole = oldData.$value
    oldRole = null unless oldRole
    redo 'project/UPDATE_PROJECT_ROLE', { teamId, projectId, userId, role }
    undo 'project/UPDATE_PROJECT_ROLE', { teamId, projectId, userId, role: oldRole}

  updateOpenProjectRights: (_, {projectId, teamId, rights}, { undo, redo })->
    oldData = await firebind "/teamAccess/#{teamId}/openProjects/#{projectId}"
    oldRights = oldData.$value
    oldRights = null unless oldRights
    redo 'project/UPDATE_OPEN_PROJECT_RIGHTS', { teamId, projectId, rights }
    undo 'project/UPDATE_OPEN_PROJECT_RIGHTS', { teamId, projectId, rights: oldRights}