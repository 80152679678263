








import ContextBtn from './ContextBtn'
export default
  name: 'Download'
  components: { ContextBtn }
  priority: 0

  if: (selection)->
    return false if selection.length < 1
    canDownload = selection.every (w) =>
      return true if w.webjet?.category in [
        '/webjets/content/webjets/photo'
        '/webjets/content/webjets/html5video'
        '/webjets/content/webjets/audio'
        '/webjets/content/webjets/pdf'
        '/webjets/content/webjets/link'
        '/webjets/content/webjets/embed'
      ] and w.webjet?.meta?.isInStorage
    return canDownload

  computed:
    selection: -> @$store.getters['webjet/selected']

  methods:
    action: ->
      items = @selection
      for w,i in items
        wait = (ms) ->
          return new Promise (resolve) =>
            setTimeout (->
              id = 'd' + i + 't' + Date.now()
              a = document.createElement "a"
              a.setAttribute 'href', w.webjet.data?.storage || w.webjet.data?.url
              a.setAttribute 'id', id
              a.setAttribute 'download', w.webjet.data?.titleOrigin
              a.setAttribute 'target', '_blank' if w.webjet.category in [
                '/webjets/content/webjets/pdf'
              ]
              document.body.appendChild a
              document.getElementById(id).click()
              document.getElementById(id).remove()
              resolve ''
            ), ms

        await wait(i * 500)
