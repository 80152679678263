import { firebind, gtmRegistration } from '@/utils'
import { getBeforeUnloadHandler } from '@/utils'
import { getRef, getFileRef, getWebjetStubForFile, getWebjetDataForUpload } from '@/utils'
import { nowFirebase } from '@/utils'
import i18n from '@/plugins/i18n'
import { sendAnalytics } from '@/utils'

export default
  create: ({ rootState }, { title }, op)->
    team = getRef('/teams').push().key
    project = getRef('/projects').push().key
    @commit 'team/CREATE',
      team: team
      project: project
      uid: rootState.uid
      title: title
    return { team, project }

  delete: ({ rootState }, { teamId }, { dispatch })->
    #console.log '!!! team/delete'
    projects = await firebind "/teams/#{teamId}/projects"
    #console.log '! projects:', projects
    for projectId of projects
      await dispatch 'project/deleteProject',
        projectId: projectId
    @commit 'team/DELETE',
      team: teamId

  updateTeamIcon: (_, { path, file})->
    teamData = await firebind path
    ext = file.name.split('.')
    ext = ext[ext.length - 1]
    teamKey = path.split('/')
    teamKey = teamKey.pop()
    fileRef = getFileRef(teamKey, ext)
    beforeunload = getBeforeUnloadHandler()
    window.addEventListener 'beforeunload', beforeunload
    uploadTask = fileRef.put file, { cacheControl: 'private, max-age=8640000' }
    uploadTask.on(
      'state_changed'
      (progressData) =>
        # do nothing
      (error) =>
        console.error error
        window.removeEventListener 'beforeunload', beforeunload
      (complete) =>
        try
          url = await fileRef.getDownloadURL()
          if teamData.iconFileName and fileRef.name != teamData.iconFileName
            oldFileRef = getFileRef(teamData.iconFileName)
            await oldFileRef.delete().catch (e) -> console.error 'WARN: error delete old file', e
          @commit 'team/UPDATE_TEAM_ICON',
            path: path
            fileName: fileRef.name
            url: url
        finally
          window.removeEventListener 'beforeunload', beforeunload
    )
  deleteTeamIcon: (_, { path }) ->
    @commit 'team/UPDATE_TEAM_ICON',
      path: path
      fileName: null
      url: null
  updateTeamTitle: (_, { path , title})->
    @commit 'team/UPDATE_TEAM_TITLE',
      path: path
      title: title

  updateBoardRole: (_, {teamId, boardId, userId, role}, { undo, redo })->
    oldData = await firebind "/teamAccess/#{teamId}/boards/#{userId}/#{boardId}"
    oldRole =  oldData.$value
    oldRole = null unless oldRole
    redo 'team/UPDATE_BOARD_ROLE', { teamId, boardId, userId, role }
    undo 'team/UPDATE_BOARD_ROLE', { teamId, boardId, userId, role: oldRole}

  removeUserFromBoard: (_, {teamId, boardId, userId}, { undo, redo })->
    oldData = await firebind "/teamAccess/#{teamId}/boards/#{userId}/#{boardId}"
    oldRole =  oldData.$value
    oldRole = null unless oldRole
    redo 'team/REMOVE_USER_FROM_BOARD', { teamId, boardId, userId }
    undo 'team/UPDATE_BOARD_ROLE', { teamId, boardId, userId, role: oldRole}

  updateTeamRole: (_, {teamId, userId, role}, { undo, redo, dispatch })->
    oldData = await firebind "/teamAccess/#{teamId}/roles/#{userId}"
    oldRole =  oldData.$value
    oldRole = null unless oldRole
    redo 'team/UPDATE_USER_TEAM_ROLE', { teamId, userId, role }
    undo 'team/UPDATE_USER_TEAM_ROLE', { teamId, userId, role: oldRole }
    await dispatch 'team/inviteUserToTeam', { teamId, userId }

  inviteUserToTeam: (_, {teamId, userId})->
    userTeamPathStatus = await firebind "/data/#{userId}/teams/#{teamId}/status"
    status = userTeamPathStatus.$value
    if not status
      @commit 'team/INVITE_USER_TO_TEAM', { teamId, userId }

  removeUserFromTeam: (_, {teamId, userId}, { undo, redo })->
    oldData = await firebind "/teamAccess/#{teamId}/roles/#{userId}"
    oldRole =  oldData.$value
    oldRole = null unless oldRole
    redo 'team/REMOVE_USER_FROM_TEAM', { teamId, userId }
    undo 'team/UPDATE_USER_TEAM_ROLE', { teamId, userId, role: oldRole }

  leaveTeam: ({ rootState }, { teamId }, op) ->
    @commit 'team/LEAVE_TEAM',
      teamId: teamId
      userId: rootState.uid

  expandTeam: ({ rootState }, {teamId, size}, { undo, redo })->
    @commit 'team/WRITE_EXPAND_TEAM_TASK',
      userId: rootState.uid
      teamId: teamId
      size: size
