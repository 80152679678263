

















import SidebarObject from "../SidebarObject"


import Weje from './Weje'
export default
  name: 'WejesContent'
  inject: ['provideBoardSrc']
  components: { SidebarObject, Weje}
  data: ->

    spoilerIsOpen: false

  computed:
    wejes: ->
      connectionsWejes = @firebind "#{@provideBoardSrc}/connections/wejes"
      items = Object.values connectionsWejes
      items = items.sort (a, b)->
        parseFloat(a.sortKey) - parseFloat(b.sortKey)
      items




  firebind:
    connection: -> @src
    weje: -> @connection and @connection.src

  methods:



    spoiler: ->
      @spoilerIsOpen = !@spoilerIsOpen


