













import AvatarTeam from '@/components/AvatarTeam.vue'
export default
  name: 'TeamPreview'
  props:
    team: String
  components: { AvatarTeam }
  computed:
    show: ->
      return true unless FLAG.NEW_LOAD
      return true if @teamRole?.$ready and @teamRole.$value
      return true if @$store.getters.teamBoards(@team).length
      false
    to: ->
      { name: 'Team', params: { teamId: @team } }
    classes: ->
      return
        'router-link-exact-active': @team == @$store.getters.currentTeamId
        'small-screen':  @smallScreen
    teamData: ->
      @firebind "/teams/#{@team}"

    dataTitle: ->
      t = @firebind("/teams/#{@team}/title")
      t?.$value

    title: ->
      if @dataTitle
        @dataTitle
      else if @team.startsWith 'team_'
        uid = @team.split('_')[1]
        firstUserName = @firebind '/users/'+uid+'/firstName'
        lastUserName = @firebind '/users/'+uid+'/lastName'
        email = @firebind '/users/'+uid+'/email'
        "#{firstUserName.$value || lastUserName.$value || email.$value?.split('@')[0] }'s team"

    smallScreen: ->
      @$vuetify.breakpoint.width < 500

    isFresh: ->
      return unless FLAG.FRESH
      @freshCount > 0

    freshCount: ->
      freshCount = @firebind 'freshCount'
      freshDate = @firebind 'freshDate'
      #return unless Object.keys(freshCount).length
      teamBoards = @$store.getters.teamBoards @team
      result = 0
      for b in teamBoards
        result += 1 if freshCount[b]
        result += 1 if freshDate.$ready and !freshDate[b]
      result
  firebind:
    teamRole: -> "/teamAccess/#{@team}/roles/#{@$store.state.uid}"
