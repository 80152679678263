import { firebind } from '@/utils'
import i18n from '@/plugins/i18n'

export date = (date)->
  return '' unless date
  MONTH_NAMES =
    [
      i18n.t('common.month.m1'), i18n.t('common.month.m2'), i18n.t('common.month.m3'), i18n.t('common.month.m4'),
      i18n.t('common.month.m5'), i18n.t('common.month.m6'), i18n.t('common.month.m7'), i18n.t('common.month.m8'),
      i18n.t('common.month.m9'), i18n.t('common.month.m10'), i18n.t('common.month.m11'), i18n.t('common.month.m12')
    ]
  date = new Date date
  seconds = Math.floor (new Date - date) / 1000
  addZero = (num) ->
    l = num.length
    if l > 1
      return num
    else
      return '0' + num
  month = MONTH_NAMES[date.getMonth()].slice(0, 3)
  day = addZero date.getDate().toString().slice(-2)
  year = date.getFullYear()
  interval = seconds / 31536
  if Math.abs(interval) > 1
    return result = month + ' ' + day + ', ' + year
  interval = seconds / 86400 * 2
  if interval > 1
    return result = month + ' ' + day
  interval = seconds / 86400
  if interval > 1
    return result = i18n.t('common.yesterday')
  return result = i18n.t('common.today')

export nowFirebase = ()->
  Date.now() + (firebind("/.info/serverTimeOffset").$value or 0)
