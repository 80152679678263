import Vue from 'vue'

parentNodeGetter = Object.getOwnPropertyDescriptor(Node.prototype, 'parentNode').get
nextSiblingGetter = Object.getOwnPropertyDescriptor(Node.prototype, 'nextSibling').get
parentNode = (node)->
  parentNodeGetter.apply node
nextSibling = (node)->
  nextSiblingGetter.apply node
insertBefore = (newNode, rNode)->
  rNode = rNode.attachCommentNode unless parentNode(rNode)
  Node.prototype.insertBefore.apply this, [newNode, rNode]
removeChild = (child)->
  child = child.attachCommentNode unless parentNode(child)
  Node.prototype.removeChild.apply this, [child]

detach = (el)=>
  unless el.attachCommentNode
    el.attachCommentNode = document.createComment 'v-attach'
  if el.parentNode
    el.replaceWith el.attachCommentNode
  else
    console.warn 'v-attach: without parent'

attach = (el)=>
  if el.attachCommentNode.parentNode
    el.attachCommentNode.replaceWith el
  else
    console.warn 'v-attach: comment node without parent'

Vue.directive 'attach',
  inserted: (el, { value, oldValue, arg }, vnode)->
    unless parentNode(el)
      console.warn 'v-attach: no parent on init'

    parentNode(el).insertBefore = insertBefore
    parentNode(el).removeChild = removeChild
    Object.defineProperty el, 'nextSibling',
      configurable: true
      enumerable: true
      get: ->
        target = this
        target = this.attachCommentNode unless parentNode(this)
        nextSibling target
    Object.defineProperty el, 'parentNode',
      configurable: true
      enumerable: true
      get: -> parentNode(el) or el.attachCommentNode.parentNode

    if arg
      # Fast mode with watch on context getter. Work without vue rerender.
      # example: component(v-attach:something)
      value = vnode.context[arg]
      unless value
        detach el
      el.attachUnwatch = vnode.context.$watch arg, (value, oldValue)=>
        if value != oldValue
          if value
            attach el
          else
            detach el
    else
      # Slow mode with expression. Work with vue rerender
      # example: component(v-attach="something")
      unless value
        detach el

  update: (el, { value, oldValue, arg }, vnode)->
    return if arg
    # only for slow mode
    if value != oldValue
      if value
        attach el
      else
        detach el

  unbind: (el)->
    if el.attachUnwatch
      el.attachUnwatch()
