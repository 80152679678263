









































import { usualMixinPack } from './mixins'
import { decodeDataTransfer, encodeDataTransfer, generateSort, firebind, anchorSortKey, generateAnchorSort } from '@/utils'
import { getRef } from '@/utils'

export default
  name: 'WebjetChecklist'
  mixins: [...usualMixinPack]
  data: ->
    showAddNewItem: false
    droppableCounter: 0
    dragItemId: null
    dragOverItemId: null
    dragOverItemWebjetPath: null
  computed:
    editable: -> true
    items: ->
      items = @webjet?.data?.items
      return [] unless items
      itemsArr = []
      for k,v of items
        itemsArr.push {key: k, data: v}
      itemsSort = itemsArr.sort (a, b)=>
        sortKeyA = @itemSortKey a.data
        sortKeyB = @itemSortKey b.data
        parseFloat(sortKeyA) - parseFloat(sortKeyB)
      itemsArr
    checkedItems: ->
      result = []
      items = @items
      for item in items
        result.push item if item?.data?.status
      result
    notCheckedItems: ->
      result = []
      items = @items
      for item in items
        result.push item unless item?.data?.status
      result
    checklistType: ->
      type = @webjet?.data?.type
      type = 0 if @webjet?.data?.type == undefined
      type
    canDrag: ->
      return true if @isOnlySelected
    activeDragOverAnchor: ->
      return false unless @dragOverItemId
      return false unless @droppableCounter > 0
      return false unless @dragOverItemWebjetPath == @webjet.$path
      # console.log 'activeDragOverAnchor', @droppableCounter
      @dragOverItemId
    noAnchorOnDragOverLastIndex: ->
      if @droppableCounter > 0 and !@dragOverItemId and @dragOverItemWebjetPath == @webjet.$path
        # console.log 'noAnchorOnDragOverLastIndex', @droppableCounter
        if @checklistType == 0
          return @items.length - 1
        else if @checklistType == 1
          return @notCheckedItems.length - 1
      else
        return false
    dragOverItem: ->
      return false unless @droppableCounter > 0
      @dragOverItemId
    itemsAllCount: ->
      @items.length
    itemsChekedCount: ->
      items = @items
      items = items.filter (item)=>
        item.data.status == 1
      items.length
    hideChecked: ->
      hideChecked = @webjet?.data?.hideChecked
      hideChecked = 1 if @webjet?.data?.hideChecked == undefined
      hideChecked
    itemsAllIsCheked: ->
      @itemsAllCount == @itemsChekedCount
  methods:
    mouseup: (e) ->
      if @isOnlySelected
        e.stopPropagation()
    focusItem: (type, id, item) ->
      items = @items
      checkedItems = @checkedItems
      notCheckedItems = @notCheckedItems
      if type == 'next'
        if @checklistType == 1
          nextItem = null
          arr = if item.status then checkedItems else notCheckedItems
          index = arr.findIndex (el) ->
            el.key == id
          nextItem = arr[index + 1]
          if !nextItem and item.status == 0 and !@hideChecked
            nextItem = checkedItems[0]
        else if @checklistType == 0
          index = items.findIndex (el) ->
            el.key == id
          nextItem = items[index + 1]
        if nextItem
          @$refs[nextItem.key]?[0]?.carretToStart()
      else if type == "prev"
        prevItem = null
        if @checklistType == 1
          arr = if item.status then checkedItems else notCheckedItems
          index = arr.findIndex (el) ->
            el.key == id
          prevItem = arr[index - 1]
          if !prevItem and item.status
            prevItem = notCheckedItems[notCheckedItems.length - 1]
        else if @checklistType == 0
          index = items.findIndex (el) ->
            el.key == id
          prevItem = items[index - 1]
        if prevItem
          @$refs[prevItem.key]?[0]?.carretToEnd()

    generateItemSort: (item, before, after) ->
      if before == undefined or before == null
        return {}
      result = false
      if before == true
        sortKey = new Date().getTime()
        result = { sortKey: sortKey }
      else if before == false
        max = -1
        for t in @items
          sortKey = @itemSortKey(t.data)
          if Number.isFinite(sortKey) and sortKey > max
            max = sortKey
        result = { sortKey: max+1 }
      else
        closest = false
        if after
          closest = @itemSortKey(after)
        closest = before - 2 unless closest
        result = { sortKey: (before + closest) / 2 }
      return result


    itemSortKey: (item) ->
      sortKey = item.sortKey
      unless sortKey
        sortKey = item.createDate
      sortKey

    getItemComponent: (e) ->
      cPath = e.composedPath()
      item = null
      for node in cPath
        item = node if node?.classList?.contains 'webjet-checklist-item'
        break if item
      return false unless item
      itemComponent = null
      for v in @items
        break if itemComponent
        if @$refs[v.key]?[0]?.$el == item
          itemComponent = @$refs[v.key][0]
      return false if itemComponent?.status == 1 and @checklistType == 1
      itemComponent
    setHideChecked: ->
      hideChecked = @hideChecked
      if hideChecked
        val = 0
      else
        val = 1
      @$store.dispatch 'webjet/setHideCheckedInList',
        path: @webjet.$path
        val: val
    dragStart: (e) ->

      @droppableCounter = 0
      item = @getItemComponent(e)
      if item
        @dragItemId = item.itemId
      else
        @dragItemId = null

      data =
        id: @getItemComponent(e).itemId
        webjetPath: @webjet.$path
      d = encodeDataTransfer(data)
      e.dataTransfer.clearData()
      e.dataTransfer.setData "cheklist/item/#{encodeDataTransfer(data)}", {}
      e.stopPropagation()
    dragOver: (e) ->
      dragOverComponent = @getItemComponent(e)
      type = null
      for t in e.dataTransfer.types
        if t.split('/')[0] == 'cheklist' and t.split('/')[1]
          type = t
          break
      return unless type
      data = null
      if type
        data = decodeDataTransfer type.substring(14)
      @dragOverItemWebjetPath = data?.webjetPath
      if @dragOverItemWebjetPath == @webjet.$path and dragOverComponent
        @dragOverItemId = dragOverComponent.itemId
      else
        @dragOverItemId = null
      e.preventDefault()
      e.stopPropagation()

    droppableDragleave: (e)->
      @droppableCounter -= 1
      e.preventDefault()
      e.stopPropagation()
      return
    droppableDragenter: (e)->
      @droppableCounter += 1
      e.preventDefault()
      e.stopPropagation()
      return
    dragLeave: (e)->
      @droppableDragleave(e)
    dragEnter: (e)->
      @droppableDragenter(e)

    # Пока drop только преобразует дататрнсфер в объект и сбрасывает драг внутри карточки
    # Нужно сделать сортировку при дропе
    # - получить нужный sortKey
    # - отправить sortKey в базу (операция updateChecklistItem в data только sortKey)
    drop: (e)->

      dragId = null
      type = undefined
      for t in e.dataTransfer.types
        if t.split('/')[0] == 'cheklist' and t.split('/')[1]
          type = t
          break
      if type
        data = decodeDataTransfer type.substring(14)
        dragId = data.id
      dragItem = @items.find (a)->
        dragId == a.key
      return unless dragItem
      prevDragOverItem = null
      dragOverItem = @getItemComponent(e)
      beforeIndex = 0
      dragOverItemSortKey = null
      if dragOverItem

        dragOverItemSortKey = @itemSortKey(dragOverItem.itemData)
        beforeIndex = @items.findIndex (a)->
          a.key == dragOverItem.itemId
      else
        dragOverItemSortKey = true
      if beforeIndex > 0
        prevDragOverItem = @items[beforeIndex - 1]
      drpoItemSortKey = @itemSortKey(dragItem.data)

      sort = @generateItemSort(dragItem?.data,dragOverItemSortKey,prevDragOverItem?.data)
      @updateItem dragId, sort
      @droppableCounter = 0
      @dragItemId = null
      @dragOverItemId = null
      e.stopPropagation()

    setShowAddInput: ->
      @showAddNewItem = true
      @$nextTick =>
        @$refs.addInput.focus()

    changeStatus: (key, oldVal) ->
      val = 0 if oldVal == 1
      val = 1 if oldVal == 0
      data = {status:val}
      @updateItem key, data

    updateTitle: (e,key) ->
      return if e.ctrlKey or e.shiftKey
      e.preventDefault()
      title = e.target.innerText.trim()
      data = {title: title}
      e.target.blur()
      @updateItem key, data

    additionalData: ->
      date = new Date().getTime()
      user = @$store.state.uid
      lastModify = {date:date,user:user}
      return {lastModify}

    clickAdd: () ->
      additionalData = @additionalData()
      createDate = new Date().getTime()
      title = ''
      data = {status:0, title: title, createDate: createDate}
      Object.assign(data, additionalData)
      key = getRef("#{@webjet.$path}").child("data").child("items").push().key
      result = await @$store.dispatch 'webjet/createCheklistItem',
        path: @webjet.$path
        id: key
        data: data
      if result
        @$refs[key]?[0]?.carretToStart()

    createItem: (e) ->
      return if e.ctrlKey or e.shiftKey
      e.preventDefault()
      title = e.target.innerText.trim()
      @showAddNewItem = false unless title
      e.target.innerText = ''
      return unless title

      additionalData = @additionalData()
      createDate = new Date().getTime()
      data = {status:0, title: title, createDate: createDate}
      Object.assign(data, additionalData)
      # console.log 'createTask', data, e

      key = getRef("#{@webjet.$path}").child("data").child("items").push().key
      @$store.dispatch 'webjet/createCheklistItem',
        path: @webjet.$path
        id: key
        data: data

    createItemWithoutEvent: () ->
      title = ''
      additionalData = @additionalData()
      createDate = new Date().getTime()
      data = {status:0, title: title, createDate: createDate}
      Object.assign(data, additionalData)
      # console.log 'createTask', data, e

      key = getRef("#{@webjet.$path}").child("data").child("items").push().key
      result = await @$store.dispatch 'webjet/createCheklistItem',
        path: @webjet.$path
        id: key
        data: data
      @$refs[key]?[0]?.carretToStart()

    autoCreateItemBefor: (item) ->
      return if item.data.status == 1 and @checklistType == 1
      prevDragOverItem = null
      afterIndex = @items.findIndex (a)->
        a.key == item.key
      nextItem = @items[afterIndex + 1]
      if nextItem
        sk = @itemSortKey(nextItem.data)
        sort = @generateItemSort({},sk,item?.data)
      else
        sort = @generateItemSort({},true,false)
      # console.log sort, nextItem, item?.data
      additionalData = @additionalData()
      createDate = new Date().getTime()
      data = {status:0, createDate: createDate, sortKey: sort.sortKey}
      Object.assign(data, additionalData)
      key = getRef("#{@webjet.$path}").child("data").child("items").push().key
      result = await @$store.dispatch 'webjet/createCheklistItem',
        path: @webjet.$path
        id: key
        data: data
      if result
        @$refs[key]?[0]?.carretToStart()
    updateItem: (key, data) ->
      additionalData = @additionalData()
      Object.assign(data, additionalData)
      # console.log 'updateTask', key, data
      @$store.dispatch 'webjet/updateChecklistItem',
        path: @webjet.$path
        id: key
        data: data

    removeItem: (key) ->
      @$store.dispatch 'webjet/removeCkecklistItem',
        path: @webjet.$path
        id: key

    hotkeyBlocker: (e) ->
      if e.ctrlKey && e.keyCode in [ 89, 90 ]
        return
      # so far we just block key events from editor to not interfere with app hotkeys
      # later some logic can be implemented
      e.stopPropagation()
      # block ctrl +/-/0
      if e.ctrlKey && e.keyCode in [ 187, 189, 48, 107, 109, 45, 43, 96 ]
        e.preventDefault()
