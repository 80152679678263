import { getRef } from '@/utils'
import { firebind } from '@/utils'
import { nowFirebase } from '@/utils'
import { pathEmpty } from '@/utils'

export default

  SET_EXPAND: (_, { path, value })->
    return if pathEmpty(path)
    getRef(path).child('presentation').set value

  SET_COORDINATES: (_, { path, value })->
    return if pathEmpty(path)
    getRef(path).child('coordinates').set value

  SET_THEME: (_, { path, id, classes })->
    getRef(path).child('data').child('backgroundId').set id
    getRef(path).child('data').child('classes').set classes

  SET_TRADINGVIEW_INTERVAL: (_, {path, interval})->
    getRef(path).child('data').child('interval').set interval

  SET_SHAPE_TYPE: (_, { path, type })->
    getRef(path).child('data').child('type').set type

  SET_ANCHOR_TYPE: (_, { path, type })->
    getRef(path).child('data').child('type').set type

  SET_HIDE_IN_PRESENTATION: (_, { path, val })->
    getRef(path).child('data').child('hideInPresentation').set val

  UPDATE_WEBJET_CATEGORY: (_, { path, category })->
    getRef(path).child('category').set category

  CREATE_WEBJET: (_, { path, data })->
    possibleWebjet = firebind path
    if possibleWebjet.$value == null
      getRef(path).set data
    else
      console.warn 'CREATE_WEBJET: already created'

  REMOVE_EMPTY_WEBJET: (_, { path })->
    webjet = firebind path
    if webjet and webjet.$sync and !webjet.connections?.items
      getRef(path).set null
    else
      console.warn 'REMOVE_EMPTY_WEBJET: has connections or not sync'

  CREATE_CONNECTION: (_, { path, data, parentPath, parentData })->
    getRef(path).set data
    firebind path # keep this connection in sync with firebase for complicated undo
    if parentPath
      getRef(parentPath).set parentData
    else
      if data.src
        console.warn 'CREATE_CONNECTION: without parent'

  UPDATE_CONNECTION: (_, { path, data })->
    return if pathEmpty(path)
    getRef(path).set data

  REMOVE_CONNECTION: (_, { path, parentPath, force })->
    connection = firebind path
    if connection and !connection.src and !connection.$error
      getRef(path).set null
      return
    webjet = firebind connection.src
    safeDelete = false
    if connection.$sync and webjet.$sync
      strongParents = false
      if webjet.connections?.parents
        for k, v of webjet.connections.parents
          if !v.weak and v.$path != parentPath
            strongParents = true
          if v.weak and v.binding.name == 'trash'
            strongParents = true
      safeDelete = true if strongParents
      unless safeDelete
        if !webjet.connections?.items
          safeDelete = true
    if safeDelete or force
      getRef(path).set null
      if parentPath
        getRef(parentPath).set null
      else
        console.warn 'REMOVE_CONNECTION: without parent'
    else
      console.warn 'REMOVE_CONNECTION: not safe, skip'

  UPDATE_EDITOR_CONTENT: (_, { path, contentDelta, contentHtml })->
    getRef(path).child('data').child('contentDelta').set contentDelta
    getRef(path).child('data').child('contentHtml').set contentHtml

  CREATE_CHEKLIST_ITEM: (_, {path, id, data})->
    items = getRef(path).child('data').child('items')
    items.child(id).set data

  REMOVE_CHEKLIST_ITEM: (_, {path, id})->
    items = getRef(path).child('data').child('items')
    items.child(id).remove()

  UPDATE_CHEKLIST_ITEM: (_, {path, id, data, fullUpdate = false})->
    items = getRef(path).child('data').child('items')
    if fullUpdate
      items.child(id).set data
    else
      items.child(id).update data

  SET_CHEKLIST_TYPE: (_, {path, val}) ->
    getRef(path).child('data').child('type').set val

  SET_HIDE_CHECKED_IN_LIST: (_, {path, val}) ->
    getRef(path).child('data').child('hideChecked').set val

  SEND_COMMENTS_MASSAGE: (_,{ path, key, data })->
    getRef(path).child('data').child('comments').child(key).set data

  SET_GRAFFITI_LINE: (_, {path, id, data})->
    lines = getRef(path).child('data').child('lines')
    lines.child(id).set data

  REMOVE_GRAFFITI_LINE: (_, {path, id})->
    lines = getRef(path).child('data').child('lines')
    lines.child(id).remove()

  SET_GRAFFITI_GRID: (_, {path, value}) ->
    getRef(path).child('data').child('grid').set value

  SET_GRAFFITI_SIZE_TYPE: (_, {path, value}) ->
    getRef(path).child('data').child('sizeType').set value

  UPDATE_SHAPE_TEXT: (_,{ path, value })->
    getRef(path).child('data').child('text').set value

  UPDATE_TITLE: (_, { path, value })->
    getRef(path).child('data').child('title').set value

  SET_ACTIVE_COLUMN: (_, { path, value })->
    getRef(path).child('activeColumn').set value

  SET_ANCHOR_SORT_KEY: (_, { path, anchorSortKey })->
    getRef(path).child('anchorSortKey').set anchorSortKey

  UPDATE_WEBJET: (_, { path, update })->
    for item of update.data
      ref = getRef(path).child('data').child(item).set update.data[item]
    for item of update.meta
      ref = getRef(path).child('meta').child(item).set update.meta[item]

  UPDATE_WEBJET_WITH_CATEGORY: (_, { path, update, category })->
    getRef(path).child('category').set category
    for item of update.data
      ref = getRef(path).child('data').child(item).set update.data[item]
    for item of update.meta
      ref = getRef(path).child('meta').child(item).set update.meta[item]

  UPDATE_SHARE: (_, { path, uid, mode })->
    getRef(path).child('shared').child(uid).set mode

  SET_BOARD_DELETED_FLAG: (_, { path, value })->
    getRef(path).child('boardDeleted').set value

  REMOVE_INBOX_ITEM: (_, { key })->
    getRef('inbox/inbox').child(key).set null

  PUSH_INBOX_ITEM: (_, { uid, src })->
    inbox = getRef("/data/#{uid}/inbox/inbox")
    key = inbox.push().key
    inbox.child(key).set { id: key, src: src }

  MARK_AS_READ: (_, { path })->
    getRef(path).child('unread').set null

  SET_STARRED: (_, { path, value })->
    getRef(path).child('starred').set value

  ON_UPDATED: (_, { path, uid })->
    webjet = firebind path
    #console.log '?', webjet, new Date().getTime(), new Date(webjet.meta.updateDate).getTime()
    #console.log '??', !webjet.$ready || !webjet.meta?.updateDate || uid != webjet.meta?.updateUser || new Date().getTime() - new Date(webjet.meta.updateDate).getTime() > 10
    if !webjet.$ready || !webjet.meta?.updateDate || uid != webjet.meta?.updateUser || new Date(nowFirebase()).getTime() - new Date(webjet.meta.updateDate).getTime() > 10
      unless webjet.id? or webjet.$value == null
        getRef(path).child('meta').child('updateDate').set new Date(nowFirebase()).toISOString()
        getRef(path).child('meta').child('updateUser').set uid

  SET_BOARD_THEME: (_, { path, value })->
    getRef(path).child('data').child('boardTheme').set value

  REMOVE_DELAYED_COPY_FLAG: (_, { path })->
    getRef(path).child('meta').child('delayedCopy').set null

  CREATE_INVITE_LINK: (_, { code, mode, src })->
    getRef(src).child('meta').child('inviteLink').set { code, mode }
    getRef("/inviteLinks/#{code}").set { src }

  REMOVE_INVITE_LINK: (_, { code, src })->
    getRef("/inviteLinks/#{code}").set null
    getRef(src).child('meta').child('inviteLink').set null

  UPDATE_INVITE_LINK: (_, { mode, src })->
    getRef(src).child('meta').child('inviteLink').child('mode').set mode

  WRITE_SEND_INVITE_LINK_TASK: (_, { uid, code, email })->
    getRef('/queue/tasks').push
      _state: 'sendinvitelink'
      userId: uid
      to_email: email
      subject: code
      createDate: nowFirebase()

  PURGE_BOARD_CONNECTION: (_, { path })->
    connection = firebind path
    getRef(connection.src).child('connections').child('parents').set null
    getRef(path).set null

  CREATE_PURGE_BOARD_TASK: (_, { uid, path })->
    getRef('/queue/tasks').push
      _state: 'purgeboard'
      userId: uid
      subject: path
      createDate: nowFirebase()

  WRITE_SEND_NOTIFY_TASK: (_, { uid, to_uid, src })->
    getRef('/queue/tasks').push
      _state: 'sendsharenotify'
      userId: uid
      to_userId: to_uid
      subject: src
      createDate: nowFirebase()

  ADD_BOARD_TO_PROJECT: (_, { board, project, sort })->
    getRef('/projects').child(project).child('boards').child(board).set
      status: 'ok'
      sortKey: sort||nowFirebase()

  REMOVE_BOARD_FROM_PROJECT: (_, { board, project })->
    getRef('/projects').child(project).child('boards').child(board).set null

  SET_VOICE_TRANSCRIPTION: (_, { path, transcription })->
    getRef(path).child('data').child('transcription').set transcription

  SET_VOICE_GRAPH_PEAKS: (_, { path, image, peaks })->
    getRef(path).child('data').child('peaks').set peaks

  SET_DATE_TIME: (_, { path, datetime })->
    getRef(path).child('data').child('datetime').set datetime

  SET_DATE_COMPLETED: (_, { path, completed })->
    getRef(path).child('data').child('completed').set completed

  UPDATE_TEAM_ACCESS: (_, { path, mode })->
    getRef(path).set mode

  ADD_TEAM_TO_USER: (_, { uid, team })->
    getRef('/data').child(uid).child('teams').child(team).set
      status: 'pending'
      sortKey: nowFirebase()

  SET_BOARD_PIN: (_, { path, value })->
    getRef(path).set value

  REPLACE_BTN_URL: (_, { path, url })->
    getRef(path).child('data').child('url').set url

  SET_MENTIONED_STATUS: (_, { path, active })->
    getRef(path).child('data').child('active').set active

  DELETE_BOARD: (_, { boardId, teamId, projectId }) ->
    getRef('/boards').child(boardId).child('boardPurged').set true
    if projectId
      getRef('/projects').child(projectId).child('boards').child(boardId).remove()
    if teamId
      boardsInTeam = firebind "/teamAccess/#{teamId}/boards"
      if boardsInTeam and Object.keys(boardsInTeam)?.length
        for k,v of boardsInTeam
          getRef('/teamAccess').child(teamId).child('boards').child(k).child(boardId).remove()

  MOVE_BOARD_TO_PROJECT: (_, { boardId, projectId }) ->
    oldProjectData = firebind "/boards/#{boardId}/project"
    oldProject = oldProjectData?.$value
    getRef('/boards').child(boardId).child('project').set projectId
    boardData = firebind "/projects/#{oldProject}/boards/#{boardId}"
    getRef('/projects').child(oldProject).child('boards').child(boardId).remove()
    getRef('/projects').child(projectId).child('boards').child(boardId).set boardData
