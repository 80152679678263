









































































































































import { date,getOwnerUID,teamTitle,projectTitle } from '@/utils'
import { mdiClose  } from '@mdi/js'
import BoardName from './BoardName'
import BoardImg from './BoardImg'
import CreateBoardImg from '@/views/navbar/TeamSpaces/CreateBoardImg.vue'
import DialogTemplate from '@/components/DialogTemplate'
import router from '@/router'

export default
  name:
    "BoardSettings"
  inject: ['provideApp']
  components:
    {
      BoardName,
      BoardImg,
      DialogTemplate,
      CreateBoardImg
    }
  props:
    src: String
  data: ->
    icon: mdiClose
    copyBoardDialog: false
    deleteBoardDialog: false
    leaveBoardDialog: false
    deleteCompletelyBoardDialog: false
    copyDialog: false
    teamForCopy: null
    projectForCopy: null
    titleForCopy: ''
    boardImgForCopy: ''

  computed:
    teams: ->
      result = []
      teams = @$store.getters.teams
      uid = @$store.state.uid
      for team in teams
        role = @firebind "/teamAccess/#{team}/roles/#{uid}"
        if role?.$value in ['owner','admin','manager']
          title = teamTitle(team)
          result.push { value: team, text: title}
      result
    projectsForBoard: ->
      result = []
      boardTeam = @firebind "/boards/#{@src}/team"
      team = boardTeam?.$value
      projects =  @$store.getters.availableEditProjects team
      for p in projects
        title = projectTitle(p)
        result.push { value: p, text: title }
      result
    boardProject:
      get: ->
        boardProject = @firebind "/boards/#{@src}/project"
        project = boardProject?.$value
        return project
      set: (val) ->
        if val != @boardProject
          @$store.dispatch 'webjet/moveBoardToProject', {boardId: @src, projectId: val}
    projectItems: ->
      result = []
      return [] unless @teamForCopy
      uid = @$store.state.uid
      role = @firebind "/teamAccess/#{@teamForCopy}/roles/#{uid}"
      projects = @firebind "/teams/#{@teamForCopy}/projects"
      for k,v of projects
        title = projectTitle(k)
        if role?.$value in ['owner','admin']
          result.push { value: k, text: title }
        else if role?.$value == 'manager'
          projectRights = @firebind "/teamAccess/#{@teamForCopy}/projects/#{uid}/#{k}"
          if projectRights?.$value == 'manage'
            result.push { value: k, text: title }
      result
    isRegisteredUser: ->
      @$store.state.isRegisteredUser
    isAnonymousUser: ->
      @$store.state.isAnonymousUser
    boardAccess: ->
      @$store.getters.boardAccess @src
    userIsManageBoard: ->
      @boardAccess.access == 'manage'
    userDirectRights: ->
      return !@boardAccess.role
    userCanEdit: ->
      if FLAG.NEW_LOAD
        @boardAccess.access in ['manage', 'edit']
      else
        return false unless @shared
        return true if @boardOwner == @$store.state.uid
        return true if @shared?['*']?.includes 'w'
        return true if @shared?[@$store.state.uid]?.includes 'w'
        false
    boardLicense: ->
      license = null
      return license if @inTrash
      if @myBoard
        return license = 'rw'
      else if @shared?[@$store.state.uid]
        return license = @shared?[@$store.state.uid]
      else
        return license
    myBoard: ->
      @boardOwner == @$store.state.uid
    boardOwner: ->
      getOwnerUID @connection?.src
    ownerName: ->
      if @myBoard
        return @$t('common.me')
      else
        name = ''
        name += @ownerFirstName?.$value + ' ' if @ownerFirstName?.$value
        name += @ownerLastName?.$value if @ownerLastName?.$value
        name = @ownerMail?.$value unless name
        return name
    boardModified: ->
      date @boardMeta?.updateDate
    boaerdCreated: ->
      date @boardMeta?.createDate
    boardInfo: ->
      [
        {name: @$t('board.config.modified'), data: @boardModified},
        {name: @$t('board.config.created'), data: @boaerdCreated},
        {name: @$t('board.config.owner'), data: @ownerName}
      ]
    title:
      get: ->
        @boardData?.title
      set: (value)->
        @$store.dispatch 'webjet/updateTitle',
          path: @connection?.src
          value: value

    inTrash: ->
      return @boardDeleted?.$value

    connection: ->
      if @src.includes('/')
        @firebind @src
      else
        { src: "/boards/#{@src}" }

    plan: ->
      return 'legacy' if @nolimits
      plan = @firebind "/teamBilling/#{@teamId}/plan"
      if plan.$value == null
        return 'free'
      else
        plan.$value
    teamId: ->
      teamId = @firebind "/boards/#{@src}/team"
      teamId.$value
    nolimits: ->
      val = @firebind("/teamBilling/#{@teamId}/nolimits")
      val.$value
    boardLimit: ->
      #return 3 if @plan == 'free'
      false
    boardCount: ->
      @$store.getters.allTeamBoards(@teamId).length


  watch:
    show: (val) ->
      @$store.dispatch 'undoredo/reset'
    copyDialog: (val,oldVal) ->
      if !oldVal and val
        @titleForCopy = @title
        @boardImgForCopy = @boardData.icon
        @teamForCopy = @teams[0].value
        @projectForCopy = @projectItems[0].value
  firebind:
    #connection: -> @src
    boardDeleted: -> @connection?.src and "#{@connection.src}/boardDeleted"
    boardData: ->
      @connection?.src and "#{@connection.src}/data"
    boardMeta: ->
      @connection?.src and "#{@connection.src}/meta"
    shared: ->
      @connection?.src and "#{@connection.src}/shared"
    ownerFirstName: ->
      @connection?.src and '/users/'+@boardOwner+'/firstName'
    ownerLastName: ->
      @connection?.src and '/users/'+@boardOwner+'/lastName'
    ownerMail: ->
      @connection?.src and '/users/'+@boardOwner+'/email'

  methods:
    copyBoardToProject: ->
      if @boardLimit and @boardCount >= @boardLimit
        @deactivateCopyBoardDialog()
        @provideApp.dialogMaxLimitTeamId = @teamId
        @provideApp.dialogMaxLimitTeamBoards = true
        return
      if !@teamForCopy or !@projectForCopy
        return

      { webjetKey, webjetPath } = await @$store.dispatch 'webjet/createBoard',
        board:
          data:
            title: "#{@$t('board.copyPrefix')} #{@titleForCopy || @$t('board.untitled')}"
          team: @teamForCopy
          project: @projectForCopy
      img = @$refs?.boardImg?.imgFile
      if img
        uploadTask = await @$store.dispatch 'webjet/updateBoardImg',
          path: webjetPath
          file: img
      else
        uploadTask = true
      if uploadTask
        #console.log { uid, @name, @defaultTitle, @value }
        await @$store.dispatch 'webjet/copyBoard',
          path: @connection.src
          title: "#{@$t('board.copyPrefix')} #{@titleForCopy || @$t('board.untitled')}"
          src: webjetPath
          key: webjetKey
        setTimeout (-> document.querySelector('#board-page > .canvas').__vue__.zoomToFit())

    copyBoard: ->
      #console.log '!copyBoard', @teamId, @boardLimit, @boardCount
      if @boardLimit and @boardCount >= @boardLimit
        @deactivateCopyBoardDialog()
        @provideApp.dialogMaxLimitTeamId = @teamId
        @provideApp.dialogMaxLimitTeamBoards = true
        return
      await @$store.dispatch 'webjet/copyBoard',
        path: @connection.src
        title: "#{@$t('board.copyPrefix')} #{@title || @$t('board.untitled')}"
        team: "team_#{@$store.state.uid}"
        project: "project_#{@$store.state.uid}"
    deleteBoard: ->
      @$store.dispatch 'webjet/deleteMyBoard', { path: @connection.src }
      if @$store.state.route.name == 'Board' and @$store.state.route?.params?.boardId == @src
        router.push { name: 'Home' }
      @close()
    restoreBoard: ->
      if @boardLimit and @boardCount >= @boardLimit
        @provideApp.dialogMaxLimitTeamId = @teamId
        @provideApp.dialogMaxLimitTeamBoards = true
        return
      @$store.dispatch 'webjet/restoreMyBoard', { path: @connection.src }
      if @$store.state.route.name == 'Board'
        router.push { name: 'Home' }
      @close()
    leaveBoard: ->
      #console.log 'leaveBoard', @connection.$path
      if FLAG.NEW_LOAD
        await @$store.dispatch 'webjet/leaveBoard', { boardId: @src }
      else
        await @$store.dispatch 'webjet/deleteSharedBoard', { path: @connection.$path }
      @$store.dispatch 'undoredo/reset'
      if @$store.state.route.name == 'Board'
        router.push { name: 'Home' }
      @close()
    deleteBoardCompletely: ->
      #console.log 'deleteBoardCompletely', @connection.$path, @connection.src
      await @$store.dispatch 'webjet/purgeBoard', { path: @connection.$path }
      @$store.dispatch 'undoredo/reset'
      @deactivateDeleteCompletelyBoardDialog()
    activateCopyBoardDialog: ->
      @copyBoardDialog = true
    deactivateCopyBoardDialog: ->
      @copyBoardDialog = false
    activateDeleteBoardDialog: ->
      @deleteBoardDialog = true
    deactivateDeleteBoardDialog: ->
      @deleteBoardDialog = false
    activateLeaveBoardDialog: ->
      @leaveBoardDialog = true
    deactivateLeaveBoardDialog: ->
      @leaveBoardDialog = false
    activateDeleteCompletelyBoardDialog: ->
      @deleteCompletelyBoardDialog = true
    deactivateDeleteCompletelyBoardDialog: ->
      @deleteCompletelyBoardDialog = false
    close: ->
      @$emit 'input', false
    hotkeyBlocker: (e) ->
      # allow undo/redo
      if e.ctrlKey && e.keyCode in [ 89, 90 ]
        return
      # block other hotkeys
      e.stopPropagation()
      # block ctrl +/-/0
      if e.ctrlKey && e.keyCode in [ 187, 189, 48, 107, 109, 45, 43, 96 ]
        e.preventDefault()
