import { operationActions } from '@/store/operation'
#import getters from './relation-getters'
import mutations from './relation-mutations'
import operations from './relation-operations'
import Vue from 'vue'

actions =
  setSelected: ({ state }, { webjet })->
    state.selected = webjet

export default
  namespaced: true
  state:
    selected: false
    activeRelatonSelectedWebjets: {}
    selectedRelationBySrc: {}
  getters:
    selected: (state)-> state.selected
    activeRelationWebjets: (state) ->
      state.nrActiveRelatonSelectedWebjets = state.activeRelatonSelectedWebjets unless state.nrActiveRelatonSelectedWebjets
      nrActiveRelatonSelectedWebjets = state.nrActiveRelatonSelectedWebjets
      return (webjet)->
        src = webjet.src
        Vue.set(nrActiveRelatonSelectedWebjets, src, false) if nrActiveRelatonSelectedWebjets[src] == undefined
        nrActiveRelatonSelectedWebjets[src]

    isSelected: (state)->
      state.nrSelectedRelationBySrc = state.selectedRelationBySrc unless state.nrSelectedRelationBySrc
      nrSelectedRelationBySrc = state.nrSelectedRelationBySrc
      return (relation)->
        src = relation.src
        Vue.set(nrSelectedRelationBySrc, src, false) if nrSelectedRelationBySrc[src] == undefined
        nrSelectedRelationBySrc[src]


  mutations: mutations
  actions: Object.assign {
    bootstrap:
      root: true
      handler: (context) ->
        selectedRelationBySrc = context.state.selectedRelationBySrc
        @watch (=> @getters['webjet/relation/selected']), (value) ->
          if value
            srcs = [value.src]
          else
            srcs = []
          for k, v of selectedRelationBySrc
            selectedRelationBySrc[k] = false if !(k in srcs)
          for k in srcs
            Vue.set(selectedRelationBySrc, k, true) if !selectedRelationBySrc[k]


        activeRelatonSelectedWebjets = context.state.activeRelatonSelectedWebjets
        @watch (=> @getters['webjet/relation/selected']), (value) ->
          if value
            srcs = value.connectWebjets.map (w) -> w.src
          else
            srcs = []
          for k, v of activeRelatonSelectedWebjets
            activeRelatonSelectedWebjets[k] = false if !(k in srcs)
          for k in srcs
            Vue.set(activeRelatonSelectedWebjets, k, true) if !activeRelatonSelectedWebjets[k]

  }, actions, operationActions operations
