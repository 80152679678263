






























import { usualMixinPack } from './mixins'
export default
  name: 'WebjetTable'
  mixins: [...usualMixinPack]
  data: ->
    isResizeActive: false
    isClickedWithoutMove: false
    isDragActive: false
    resizer: null
    ghost: null
    indicator: null
    dragType: null
    dragPoint: null
    startPositionIndex: null
    targetPositionIndex: null
    previousPositionIndex: null
    currentCell: null
    currentCellWidth: null
    currentCellHeight: null
    tableHeight: 0
    tableWidth: 0
    tableCoords: null
    deltaX: 0
    deltaY: 0
    pageX: 0
    pageY: 0
    positionX: 0
    positionY: 0
    rows: null
    cols: null
  mounted: ->
    @initTable()
    @setCellOptions()
  methods:
    initTable: ->
      @isResizeActive = false
      @isClickedWithoutMove = false
      @isDragActive = false
      @dragType = null
      @dragPoint = null
      @startPositionIndex = null
      @targetPositionIndex = null
      @previousPositionIndex = null
      @ghost = null

      @tableHeight = @$refs.table.offsetHeight
      @tableCoords = @$refs.table.getBoundingClientRect()
      @$refs.ghostBlock.innerHTML = ""

      @rows = @$el.querySelectorAll(".table-row")
      @cols = @rows[0].querySelectorAll(".cell")

      @dragPoint?.style.visibility = "visible"
      @resizer?.style.right = 0 + "px"

      @$el.querySelectorAll('.resizer').forEach (el) =>
        el.style.height = @$el.offsetHeight + 'px'
        el.style.borderColor = 'transparent'
        el.addEventListener("mousedown", @mousedown)

    setCellOptions: ->
      @rows.forEach (el, idx) =>

        el.querySelectorAll('.drag-point').forEach (el) =>
          el.remove()

        el.querySelectorAll('.resizer').forEach (el) =>
          el.remove()

        indicator = el.querySelector('.indicator')
        cells = el.querySelectorAll('.cell')
        dragPoint = cells[0].querySelector('.drag-point-row')

        if !dragPoint
          dragPoint = document.createElement 'div'
          dragPoint.dataset.type = 'row'
          dragPoint.classList.add("drag-point", "drag-point-row")
          cells[0].append dragPoint
        dragPoint.dataset.order = idx

        if !indicator
          indicator = document.createElement('div')
          indicator.className = 'indicator'
          el.append(indicator)

        if idx == 0
          cells.forEach (c, idx) =>
            resizer = c.querySelector('.resizer')
            dragPoint = c.querySelector('.drag-point-col')

            if !dragPoint
              dragPoint = document.createElement 'div'
              dragPoint.dataset.type = 'col'
              dragPoint.classList.add("drag-point", "drag-point-col")
              c.append dragPoint
            dragPoint.dataset.order = idx

            if !resizer
              resizer = document.createElement 'div'
              resizer.className = 'resizer'
              c.append resizer
            c.classList.add 'cell-first'

      @$el.querySelectorAll('.resizer').forEach (el) =>
        el.style.height = @$el.offsetHeight + 'px'
        el.style.borderColor = 'transparent'
        el.addEventListener("mousedown", @mousedown)

      @$el.querySelectorAll('.drag-point').forEach (el) =>
        el.addEventListener("mousedown", @mousedown)

      @$el.querySelectorAll(".indicator").forEach (el) =>
        el.style.visibility = "hidden"

    mousedown: (e) ->
      document.addEventListener("mousemove", @mousemove)
      document.addEventListener("mouseup", @mouseup)

      @pageX = e.pageX
      @pageY = e.pageY

      if e.target.classList.contains("resizer")
        @isResizeActive = true
        @isClickedWithoutMove = true
        @resizer = e.target
        @currentCell = e.target.parentElement
        @currentCellWidth = @currentCell.offsetWidth
        @currentCellHeight = @currentCell.offsetHeight
        @$el.dispatchEvent new CustomEvent 'preventdrag', { detail: { prevent: true }, bubbles: true, cancelable: true }
      else if !@isDragActive and !@isResizeActive
        @isDragActive = true
        @isClickedWithoutMove = true
        @dragType = e.target.dataset.type
        @dragger = e.target
        @startPositionIndex = +e.target.dataset.order
        @currentCellHeight = e.target.parentNode.offsetHeight
        @currentCellWidth = e.target.parentNode.offsetWidth
        @$el.dispatchEvent new CustomEvent 'preventdrag', { detail: { prevent: true }, bubbles: true, cancelable: true }
        @createGhost(e.target.parentNode)

      e.stopPropagation()

    mousemove: (e) ->
      if @isResizeActive and @currentCell
        @isClickedWithoutMove = false
        @deltaX = e.pageX - @pageX
        @resizer.style.right = -@deltaX + "px"
        @resizer.style.borderRight = "6px solid rgb(111, 178, 255)"

      if @isDragActive and @dragType == "row"
        @isClickedWithoutMove = false
        @deltaY = e.pageY - @pageY
        @ghost.style.top = @deltaY + @positionY + "px"
        @targetPositionIndex = Math.round (@positionY - -@deltaY) / @currentCellHeight
        @showIndicator()

      else if @isDragActive and @dragType == "col"
        @isClickedWithoutMove = false
        @deltaX = e.pageX - @pageX
        @ghost.style.left = @deltaX + @positionX + "px"
        @targetPositionIndex = Math.round (@positionX - -@deltaX) / @currentCellWidth
        @showIndicator()

    mouseup: ->
      if @isResizeActive and !@isClickedWithoutMove
        @tableWidth = @$refs.table.offsetWidth unless 0
        @currentCell.style.width = @currentCellWidth + @deltaX + "px"
        @$el.style.width = @tableWidth + @deltaX + "px"
        @tableWidth+= @deltaX

      if @isDragActive and !@isClickedWithoutMove and !@isStepOver
        @updateTable()

      @$el.dispatchEvent new CustomEvent 'preventdrag', { detail: { prevent: false }, bubbles: true, cancelable: true }
      document.removeEventListener("mousemove", @mousemove)
      document.removeEventListener("mouseup", @mouseup)
      @initTable()

    createGhost: (target) ->
      coords = target.getBoundingClientRect()
      ghostDragPoint = document.createElement("div")
      ghostDragPoint.classList.add("drag-point")
      ghostDragPoint.style.backgroundColor = "red"
      ghostDragPoint.style.visibility = "visible"

      @ghost = document.createElement("div")
      @ghost.classList.add("ghost")

      if @dragType == "col"
        @positionX = Math.abs @tableCoords.x - coords.x
        @ghost.style.width = coords.width + "px"
        @ghost.style.left = @positionX + "px"
        @ghost.style.bottom = 0
        @ghost.style.top = 0
        ghostDragPoint.classList.add("drag-point-col")
      else
        @positionY = Math.abs @tableCoords.y - coords.y
        @ghost.style.height = coords.height + "px"
        @ghost.style.top = @positionY + "px"
        @ghost.style.left = 0
        @ghost.style.right = 0
        ghostDragPoint.classList.add("drag-point-row")

      @ghost.append ghostDragPoint
      @$refs.ghostBlock.append @ghost

    showIndicator: ->
      if @previousPositionIndex != @targetPositionIndex and !@isStepOver
        @previousPositionIndex = @targetPositionIndex

        @indicator?.style.visibility = "hidden"
        @resizer?.style.borderRight = "none"

        if @dragType == "row"
          @indicator = @rows[@targetPositionIndex].querySelector(".indicator")
          @indicator.style.visibility = "visible"
        else if @dragType == "col"
          @resizer = @rows[0].querySelectorAll(".resizer")[@targetPositionIndex]
          @resizer.style.borderRight = "  6px solid rgb(111, 178, 255)"

    updateTable: ->
      clone = @$refs.table.cloneNode(true)
      clonedRows = [...clone.querySelectorAll(".table-row")]

      if @dragType == "col"
        mapped = clonedRows.map((el, index, arr) =>
          row = el.cloneNode()
          cells = el.querySelectorAll(".cell")

          cells.forEach (c, idx) =>
            if idx == @startPositionIndex
              return row.append(cells[@targetPositionIndex])
            else if idx == @targetPositionIndex
              return row.append(cells[@startPositionIndex])
            return row.append cells[idx]
          row
        )
      else if @dragType == "row"
        currentRow = clonedRows[@startPositionIndex]
        targetRow = clonedRows[@targetPositionIndex]

        mapped = clonedRows.map((el, idx, arr) =>
          if idx == @startPositionIndex
            return targetRow
          else if idx == @targetPositionIndex
            return currentRow
          return el
        )

      @rows.forEach (r, idx) =>
        @$refs.table.replaceChild(mapped[idx], r)

      @initTable()
      @setCellOptions()


  computed:
    isStepOver: ->
      @targetPositionIndex >= @rows.length or @targetPositionIndex >= @cols.length or @targetPositionIndex < 0
