




















export default
  name: 'ToolVoice'
  data: ->
    status: 'start'
    audioBlobs: []
    mediaRecorder: null
    streamBeingCaptured: null
    durationStart: null
    duration: 0
    elapsedInterval: null
    hoverCancel: false
    helding: false
    holdTimeout: null
  computed:
    recording: ->
      @status == 'stop'
    elapsedTime: () ->
      if @duration < 10
        seconds =  '0' + @duration
      else if @duration == 60
        seconds = '00'
      else
        seconds = @duration

      minutes = if @duration < 60 then '0' else '1'
      minutes + ':' + seconds
  methods:
    startRecord: ->
      if @status == 'start'
        stream = await navigator.mediaDevices.getUserMedia audio: true
        @streamBeingCaptured = stream
        @mediaRecorder = new MediaRecorder(stream)
        @audioBlobs = []
        @durationStart = new Date()

        @mediaRecorder.addEventListener 'dataavailable', (event) =>
          @audioBlobs.push event.data

        @mediaRecorder.start()
        @handleElapsedTime()
        @status = 'stop'

        if !@helding
          @mediaRecorder.addEventListener 'start', () =>
            @cancelRecord()

    stopRecord: ->
      if @status == 'stop'
        mimeType = @mediaRecorder.mimeType

        @mediaRecorder.addEventListener 'stop', =>
          return if @duration < 1
          audioBlob = new Blob @audioBlobs, type: 'audio/webm'
          file = new File [audioBlob], 'record.webm', { type: 'voice/' + mimeType }
          @$emit 'createVoice', { file, duration: @elapsedTime, author: @$store.state.uid }
          @duration = 0

        @cancelRecord()

    stopStream: ->
      @streamBeingCaptured.getTracks().forEach (track) ->
        track.stop()

    cancelRecord: ->
      @mediaRecorder.stop()
      @status = 'start'
      @hoverCancel = false
      @stopStream()
      clearInterval @elapsedInterval

    mousedown: ->
      @handleStart()
      document.addEventListener 'mouseup', @mouseup, {capture: true, passive: false}

    mouseup: ->
      @handleStop()
      document.removeEventListener 'mouseup', @mouseup, {capture: true, passive: false}

    touchstart: (e) ->
      e.preventDefault()
      e.stopPropagation()
      @handleStart()

      document.addEventListener 'touchend', @touchend, {capture: true, passive: false}
      document.addEventListener 'touchmove', @touchmove, {capture: true, passive: false}

    touchmove: (e) ->
      myLocation = e.touches[0];
      realTarget = document.elementFromPoint(myLocation.clientX, myLocation.clientY);

      if realTarget.classList.contains 'close-item'
        @hoverCancel = true
      else
        @hoverCancel = false

    touchend: ->
      @handleStop()
      document.removeEventListener 'touchend', @touchend, {capture: true, passive: false}
      document.removeEventListener 'touchmove', @touchmove, {capture: true, passive: false}

    handleElapsedTime: ->
      @elapsedInterval = setInterval =>
        timeDiff = (new Date() - @durationStart) / 1000
        @duration = Math.floor timeDiff
      , 1000

    handleStart: ->
      @helding = true
      @holdTimeout = setTimeout =>
        @startRecord() if @helding
      , 200

    handleStop: ->
      @helding = false
      clearTimeout @holdTimeout

      if @hoverCancel
        @duration = 0
        @cancelRecord()
      else
        @stopRecord()
  watch:
    duration: (v) ->
      if v == 60
        @stopRecord()
        clearInterval @elapsedInterval
