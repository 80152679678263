









import mixin from './mixin'
import Childs from './Childs'
export default
  name: 'WejeVideo'
  mixins: [ mixin ]
  components: { Childs }
  props: ['data']
  data: ->
    aspectRatio: null
  computed:
    videoStyles: ->
      if @aspectRatio
        return
          position: 'absolute'
          width: '100%'
          height: '100%'
          top: '0'
    videoContainerStyles: ->
      if @aspectRatio
        return
          position: 'relative'
          width: '100%'
          height: '0px'
          'padding-top': @aspectRatio + '%'
  methods:
    onVideoLoaded: (e)->
      @setAspectRatio(e.target) unless @aspectRatio

    setAspectRatio: (el) ->
      @aspectRatio = (el.clientHeight / el.clientWidth) * 100
      # force refresh topmost container
      topParent = @provideWebjet
      if topParent
        while topParent.provideWebjet
          topParent = topParent.provideWebjet
        topParent.contentLoadedUpdate()

  watch:
    isTheoreticallyVisible: (v) ->
      if v and not @aspectRatio
        setTimeout =>
          @setAspectRatio(@$refs.video)
