









































import NavbarContent from './NavbarContent.vue'
import AvatarTeam from '@/components/AvatarTeam.vue'
export default
  name: 'NavbarTeamSettings'
  inject: ['provideApp']
  props:
    teamId: String
  components:
    { NavbarContent, AvatarTeam }

  computed:
    userRole: ->
      uid = @$store.state.uid
      role = @firebind "/teamAccess/#{@teamId}/roles/#{uid}"
      role.$value
    team: ->
      if @teamId
        @teamId
      else if @boardId
        boardTeam = @firebind("/boards/#{@boardId}/team")
        if boardTeam.$ready
          boardTeam.$value
        else
          false
      else if @$route.path == '/'
        "team_#{@$store.state.uid}"
      else
        false
    dataTitle: ->
      t = @firebind("/teams/#{@team}/title")
      t?.$value

    title: ->
      return '' unless @team
      if @dataTitle
        @dataTitle
      else if @team.startsWith 'team_'
        uid = @team.split('_')[1]
        firstUserName = @firebind '/users/'+uid+'/firstName'
        lastUserName = @firebind '/users/'+uid+'/lastName'
        email = @firebind '/users/'+uid+'/email'
        "#{firstUserName.$value || lastUserName.$value || email.$value?.split('@')[0] }'s team"
  methods:
    toSettings: ->
      @$router.push { name: 'TeamSettings', params: { teamId: @teamId } }
    toAccess: ->
      @$router.push { name: 'TeamAccess', params: { teamId: @teamId } }
    toPlan: ->
      @$router.push { name: 'TeamPlan', params: { teamId: @teamId } }
    toTrash: ->
      @$router.push { name: 'TeamTrash', params: { teamId: @teamId } }
