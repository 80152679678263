export default
  computed:
    title: ->
      return @data.data?.titleOrigin unless @data.data?.title
      @data.data?.title
    url: ->
      @data.data?.url
    textHtml: ->
      @data.data?.contentHtml
    text: ->
      @data.data?.text
    childs: ->
      @data.childs
    type: ->
      @data.data?.type
    description: ->
      @data.data?.description
    isThemed: ->
      @data.data?.backgroundId and @data.data?.backgroundId != '1'
    themeableClass: ->
      return false unless @isThemed
      switch @data.data.backgroundId
        when '2' then 'theme-default'
        when '3' then 'theme-gray'
        when '4' then 'theme-red'
        when '5' then 'theme-yellow'
        when '6' then 'theme-green'
        when '7' then 'theme-purple'
        when '8' then 'theme-blue'
        when '9' then 'theme-lightblue'
        else 'theme-unknown'