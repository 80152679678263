import state from '@/store/state'
import { firebind } from '@/utils'

export getDataUserRef = (boardPath)->
  throw 'Firebase not initialized' unless state.db
  if boardPath? and (boardPath.startsWith '/boards/' or boardPath.startsWith 'firebase:/boards/')
    boardPath = boardPath.substring(9) if boardPath.startsWith 'firebase:'
    boardId = boardPath.substring(8)
    return state.db.ref "/boardsData/#{boardId}"
  else
    return state.db.ref "/data/#{state.uid}"

export getBoardsRef = ()->
  throw 'Firebase not initialized' unless state.db
  if FLAG.NEW_DATA
    state.db.ref('/boards')
  else
    getDataUserRef()

export getRef = (location, boardPath)=>
  throw 'Firebase not initialized' unless state.db
  path = location
  path = location.substring(9) if location.startsWith 'firebase:'
  db = state.db
  if path.startsWith '/'
    db.ref "#{path}"
  else if boardPath? and (boardPath.startsWith('/boards/') or boardPath.startsWith('firebase:/boards/'))
    boardPath = boardPath.substring(9) if boardPath.startsWith 'firebase:'
    boardId = boardPath.substring(8)
    db.ref "/boardsData/#{boardId}/#{path}"
  else
    db.ref "/data/#{state.uid}/#{path}"

export getPath = (location, boardPath)=>
  unless typeof location == 'string' or location instanceof String
    console.error 'only string', location
  path = location
  path = location.substring(9) if location.startsWith 'firebase:'
  if path.startsWith '/'
    path
  else if boardPath? and (boardPath.startsWith('/boards/') or boardPath.startsWith('firebase:/boards/'))
    boardPath = boardPath.substring(9) if boardPath.startsWith 'firebase:'
    boardId = boardPath.substring(8)
    "/boardsData/#{boardId}/#{path}"
  else
    "/data/#{state.uid}/#{path}"

export getBoardPath = (location)=>
  unless typeof location == 'string' or location instanceof String
    console.error 'only string', location
  path = location
  path = location.substring(9) if location.startsWith 'firebase:'
  if path.startsWith '/'
    path
  else if FLAG.NEW_DATA
    "/boards/#{path}"
  else
    "/data/#{state.uid}/#{path}"

export pathEmpty = (location)->
  unless typeof location == 'string' or location instanceof String
    console.error 'only string', location
  path = location
  path = location.substring(9) if location.startsWith 'firebase:'
  snapshot = firebind path
  return !snapshot.$ready || snapshot.$value==null
