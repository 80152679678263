












import mixin from './mixin'
import $tradingview from '../../plugins/tradingview.coffee'

export default
  name: 'WejeLink'
  mixins: [ mixin ]
  props: ['data']
  data: ->
    containerId: 'traindingview_' + Date.now() + Math.floor Math.random() * 10 ** 6
    timeout: null
    options:
      autosize: true,
      interval: "D",
      timezone: "Etc/UTC",
      theme: "light",
      style: "1",
      locale: "en",
      toolbar_bg: "#f1f3f6",
      enable_publishing: false,
      hide_side_toolbar: false,
  mounted: ->
    await $tradingview()
    @generateChartData()
  computed:
    currency: ->
      return @data.data?.currency
    symbolUrl: ->
      return @data.data?.origin
  methods:
    generateChartData: ->
      if (@data.data?.interval)
        @options.interval = @data.data?.interval.split('/')[0]
      @options.container_id = @containerId
      @options.symbol = @currency
      if document.getElementById(@containerId)
        new TradingView.widget(@options)
