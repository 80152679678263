































import SpaceCreateDialog from './SpaceCreateDialog'
import SpaceCreateFromTemplateDialog from './SpaceCreateFromTemplateDialog'
export default
  name: 'SpaceCreate'
  inject: ['provideApp']
  props:
    defaultProject: String
  components:
    { SpaceCreateFromTemplateDialog, SpaceCreateDialog }

  data: ->
    shown: false
    BCKey: 0
    BCFTKey: 0
    showCreateBoard: false
    selectedTemplate: null
    templates: [
      {
        title: @$t('board.template.group.pm')
        items: [
          {
            id: '-MfDEQu5uisUvSoaLp8i'
            title: @$t('board.template.workflow')
            desc: @$t('board.template.workflowDesc')
            lang: 'en'
            class: 'workflow'
          }
          {
            id: '-MfN42cJXQrzgMwgHvyt'
            title: @$t('board.template.retrospective')
            desc: @$t('board.template.retrospectiveDesc')
            lang: 'en'
            class: 'retrospective'
          }
        ]
      }

      {
        title: @$t('board.template.group.research')
        items: [
          {
            id: '-MfDZdzHYPN9hm8ttkpq'
            title: @$t('board.template.swot')
            desc: @$t('board.template.swotDesc')
            lang: 'en'
            class: 'swot'
          }
          {
            id: '-MfMvqbjYHuCU_zgEdN3'
            title: @$t('board.template.prosCons')
            desc: @$t('board.template.prosConsDesc')
            lang: 'en'
            class: 'pros_cons'
          }
          {
            id: '-MfN0VnPNRH_ovW1iDIn'
            title: @$t('board.template.mscw')
            desc: @$t('board.template.mscwDesc')
            lang: 'en'
            class: 'mscw'
          }
          {
            id: '-MhtJLlTcKKCHuWDW9jR'
            title: @$t('board.template.pestle')
            desc: @$t('board.template.pestleDesc')
            lang: 'en'
            class: 'pestle'
          }
          {
            id: '-MjcuwWgWmetzzjcqzvX'
            title: @$t('board.template.action')
            desc: @$t('board.template.actionDesc')
            lang: 'en'
            class: 'action'
          }
          {
            id: '-Ms5a3BSXD_SPD3xi7Yp'
            title: @$t('board.template.bowTie')
            desc: @$t('board.template.bowTieDesc')
            lang: 'en'
            class: 'bow_tie'
          }
          {
            id: '-Ms5abKEtiweVBZBD9j2'
            title: @$t('board.template.fishbone')
            desc: @$t('board.template.fishboneDesc')
            lang: 'en'
            class: 'fishbone'
          }
          {
            id: '-Mtbzd6zBTsz-0INJLJY'
            title: @$t('board.template.scamper')
            desc: @$t('board.template.scamperDesc')
            lang: 'en'
            class: 'scamper'
          }
          {
            id: '-MvNTGp1ybWpqdp4Fb1X'
            title: @$t('board.template.sailboat')
            desc: @$t('board.template.sailboatDesc')
            lang: 'en'
            class: 'boat'
          }
          {
            id: '-MvNBepjNd7q1RPhsStZ'
            title: @$t('board.template.madSadGlad')
            desc: @$t('board.template.madSadGladDesc')
            lang: 'en'
            class: 'madsadglad'
          }
          {
            id: '-MvN5JJQitst6wcHUAfk'
            title: @$t('board.template.plusDelta')
            desc: @$t('board.template.plusDeltaDesc')
            lang: 'en'
            class: 'plusdelta'
          }
          {
            id: '-N09oQAkPi0J1TPFg028'
            title: @$t('board.template.empathyMap')
            desc: @$t('board.template.empathyMapDesc')
            lang: 'en'
            class: 'empathy_map'
          }
          {
            id: '-MyM2pJZy_IpE0XJHe-B'
            title: @$t('board.template.brandIdentityPrism')
            desc: @$t('board.template.brandIdentityPrismDesc')
            lang: 'en'
            class: 'brand_identity_prism'
          }
        ]
      }

      {
        title: @$t('board.template.group.education')
        items: [
          {
            id: '-MfO3-0Kocde1iVv_f1m'
            title: @$t('board.template.kwl')
            desc: @$t('board.template.kwlDesc')
            lang: 'en'
            class: 'kwl'
          }
          {
            id: '-Mj9KVeXKGPSkc8Gw8dQ'
            title: @$t('board.template.lesson')
            desc: @$t('board.template.lessonDesc')
            lang: 'en'
            class: 'lesson'
          }
        ]
      }

      {
        title: @$t('board.template.group.presentation')
        items: [
          {
            id: '-MfTE8eYwh4eWtCofc_P'
            title: @$t('board.template.startup')
            desc: @$t('board.template.startupDesc')
            lang: 'en'
            class: 'startup'
          }
          {
            id: '-MpvhA0XHewP6il7n2CE'
            title: @$t('board.template.orgChart')
            desc: @$t('board.template.orgChartDesc')
            lang: 'en'
            class: 'org_chart'
          }
          {
            id: '-Ms0sDc7MO45Mk-ELyGB'
            title: @$t('board.template.userStory')
            desc: @$t('board.template.userStoryDesc')
            lang: 'en'
            class: 'user_story'
          }

          {
            id: '-MtvcMdvAhsvI4-PDwPk'
            title: @$t('board.template.strategyMap')
            desc: @$t('board.template.strategyMapDesc')
            lang: 'en'
            class: 'strategy_map'
          }
          {
            id: '-MuUS2dKIqP9IRSbvJHq'
            title: @$t('board.template.decisionTree')
            desc: @$t('board.template.decisionTreeDesc')
            lang: 'en'
            class: 'tree'
          }
          {
            id: '-MwQusauAFxG3Dojum2n'
            title: @$t('board.template.history')
            desc: @$t('board.template.historyDesc')
            lang: 'en'
            class: 'history'
          }
          {
            id: '-MyLv_FS5fhxIS_at-Tm'
            title: @$t('board.template.lotusDiagram')
            desc: @$t('board.template.lotusDiagramDesc')
            lang: 'en'
            class: 'lotus_diagram'
          }
        ]
      }

      {
        title: @$t('board.template.group.planning')
        items: [
          {
            id: '-MfDEQu5uisUvSoaLp8i'
            title: @$t('board.template.workflow')
            desc: @$t('board.template.workflowDesc')
            lang: 'en'
            class: 'workflow'
          }
          {
            id: '-MfN42cJXQrzgMwgHvyt'
            title: @$t('board.template.retrospective')
            desc: @$t('board.template.retrospectiveDesc')
            lang: 'en'
            class: 'retrospective'
          }
          {
            id: '-MfTECAsTQwm2fK1m-ZG'
            title: @$t('board.template.tax')
            desc: @$t('board.template.taxDesc')
            lang: 'en'
            class: 'tax'
          }
          {
            id: '-Mljc77tUYFqQoJEgjdu'
            title: @$t('board.template.lean')
            desc: @$t('board.template.leanDesc')
            lang: 'en'
            class: 'lean'
          }
          {
            id: '-MmsNJWSuNlPsk6ArUn7'
            title: @$t('board.template.checklist')
            desc: @$t('board.template.checklistDesc')
            lang: 'en'
            class: 'checklist'
          }
          {
            id: '-MtvV6UKr7cBcEpaNtiS'
            title: @$t('board.template.balancedScorecard')
            desc: @$t('board.template.balancedScorecardDesc')
            lang: 'en'
            class: 'balanced_scorecard'
          }
          {
            id: '-MwQZFtlsiWUOyj3_Elx'
            title: @$t('board.template.mothlyPlanner')
            desc: @$t('board.template.mothlyPlannerDesc')
            lang: 'en'
            class: 'month'
          }
          {
            id: '-Mw5SjbuBLmhooY7xs-x'
            title: @$t('board.template.weeklyPlanner')
            desc: @$t('board.template.weeklyPlannerDesc')
            lang: 'en'
            class: 'week'
          }
        ]
      }
    ]

  methods:
    selectTemplate: (e,template) ->
      t = Object.assign {},template
      node = e.target.closest(".templates-item").getElementsByClassName(template.class)[0]
      img = window.getComputedStyle(node).backgroundImage.slice(4, -1).replace(/"/g, "")
      t.img = img
      @selectedTemplate = t

    checkLimits: ->
      #console.log '!checkLimits', @boardLimit, @boardCount
      if @boardLimit and @boardCount >= @boardLimit
        setTimeout =>
          @shown = false
          @provideApp.dialogMaxLimitTeamId = @context.teamId
          @provideApp.dialogMaxLimitTeamBoards = true

  computed:
    plan: ->
      return 'legacy' if @nolimits
      plan = @firebind "/teamBilling/#{@teamId}/plan"
      if plan.$value == null
        return 'free'
      else
        plan.$value
    teamId: ->
      @context.teamId
    nolimits: ->
      val = @firebind("/teamBilling/#{@teamId}/nolimits")
      val.$value
    boardLimit: ->
      #return 3 if @plan == 'free'
      return false
    boardCount: ->
      @$store.getters.allTeamBoards(@teamId).length

  watch:
    selectedTemplate: (val) ->
      @BCFTKey++ if val
    showCreateBoard: (val) ->
      @BCKey++ if val

