

































import ContextBtn from './ContextBtn'
export default
  name: 'TradingViewInterval'
  components: { ContextBtn }
  priority: 10000
  if: (selection)->
    selection.length == 1 and selection[0].webjet.category == 'tradingview'

  computed:
    selection: -> @$store.getters['webjet/selected']
    interval: ->
      if @selection[0].webjet.data.interval
        return @selection[0].webjet.data.interval.split('/')[1]
      else
        return '1d'

  methods:
    action: (interval)->
      @$store.dispatch 'webjet/setTradingViewInterval',
        path: @selection[0].src
        interval: interval
