import { firebind } from '@/utils'

export preloadNode = (path, options={}, nodes={})->
  webjet = await firebind path
  return if nodes[webjet.$path]
  nodes[webjet.$path] = true
  if webjet.connections
    for gKey, gValue of webjet.connections
      unless gKey in ['parents', 'count', 'mindmap'] # 'count' is a nasty legacy
        unless gKey in ['trash', 'trashSites', 'wejes']
          for cPath, cValue of gValue
            if cValue.src
              preloadNode(cValue.src, options, nodes)
