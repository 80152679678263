












import { Cropper, CircleStencil, RectangleStencil } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import { scaleImg } from '@/utils'

export default
  name: 'ModalImgCropper'
  components:
    {
      Cropper
      CircleStencil
      RectangleStencil
     }
  props:
    value: Boolean
    file:
      type: [Object, File]
      default: null
    circle:
      type: Boolean
      default: false
    maxWidth:
      type: Number
      default: 200
    maxHeight:
      type: Number
      default: 200
  data: ->
    handlersClasses:
      default: 'handler-crop'
  computed:
    imgSrc: ->
      return false unless @file
      return URL.createObjectURL @file
    stencil: ->
      return CircleStencil if @circle
      return RectangleStencil
    show:
      get: ->
        @value
      set: ->
         @$emit 'input', false
  methods:
    hide: ->
      @show = false
    crop: ->
      { canvas } = @$refs.cropper.getResult()
      cropedImgSrc = canvas.toDataURL()
      fetch(cropedImgSrc).then (res) =>
        res.blob().then (blob) =>
          cropedFile = new File([blob], @file.name, blob)
          cropFile = await scaleImg cropedFile, @maxWidth, @maxHeight
          @$emit 'crop', cropFile
          @show = false
