

















export default
  name: 'SidebarLeftContent'
  inject: ['provideSidebarLeft']
  props:
    title: String
  methods:
    close: ->
      @provideSidebarLeft.close()
    mini: ->
      @provideSidebarLeft.mini()
