







export default
  name:
    "Name"
  props:
    value: String

  methods:
    onInput: (val) ->
      @$emit 'input', val
