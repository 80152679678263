import './css/relationable.sass'
pointInTriangle = (p, p0, p1, p2)->
  (((p1.y - p0.y) * (p.x - p0.x) - (p1.x - p0.x) * (p.y - p0.y)) | ((p2.y - p1.y) * (p.x - p1.x) - (p2.x - p1.x) * (p.y - p1.y)) | ((p0.y - p2.y) * (p.x - p2.x) - (p0.x - p2.x) * (p.y - p2.y))) >= 0

pointInRect = (p,rect)->
  return p.x >= rect.x1 and p.x <= rect.x2 and p.y >= rect.y1 and p.y <= rect.y2

relationable =
  data: ->
    relationableResolve: false
  listeners: ->
    relation: @relationableRelation
  classes: ->
    return
      "relationable-resolve": @relationableResolve and @isRelationableResolve
      "selected-by-relations": @activeRelationWebjets
  styles: ->
    return unless @provideScene
    return if !@isRelationableResolve and !@activeRelationWebjets
    return
      '--scale': @provideCanvas.scale
  computed:
    isRelationable: -> true
    canRelationable: ->
      return false if @isReadOnly
      true
    selectedRelation: ->
      if @provideCanvas?.activeContextRelation
        @provideCanvas.activeContextRelation
      else if @$store.getters['webjet/relation/selected']
        @$store.getters['webjet/relation/selected']
      else
        false

    activeRelationWebjets: ->
      # console.log 'activeRelationWebjets'
      # return unless @selectedRelation
      @$store.getters['webjet/relation/activeRelationWebjets'](this)
      # return this in @selectedRelation.connectWebjets
    isRelationableResolve: ->
      return false unless @relationableResolve
      unless @relationableResolve.drag.point
        @relationableResolve = false
        return false
      @relationableResolve.drag.webjet == this

    relationableHasRelations: ->
      return false unless @provideScene
      relations = @webjet?.connections?.relations
      for k, r of relations
        relation = @firebind r.src
        rParents = relation?.connections?.parents

        if rParents and Object.keys(rParents).length > 1
          for wk, w of rParents
            webjetComponent = @provideCanvas.getWebjetComponentBySrc w.src
            if webjetComponent and webjetComponent != @
              return true

      return false
  methods:
    relationableRelation: (e)->
      return if @isReadOnly
      return unless @provideScene
      relation = e.detail.relation
      x = relation.drag.x
      y = relation.drag.y
      rect = @elementSceneRect
      width = rect.x2 - rect.x1
      height = rect.y2 - rect.y1
      left =
        x1:rect.x1
        x2: rect.x1 + (width * 0.3)
        y1: rect.y1
        y2: rect.y2
      right =
        x1:rect.x2 - (width * 0.3)
        x2: rect.x2
        y1: rect.y1
        y2: rect.y2
      bottom =
        x1: rect.x1 + (width * 0.3)
        x2: rect.x1 + (width * 0.7)
        y1: rect.y2 - (height * 0.5)
        y2: rect.y2
      top =
        x1: rect.x1 + (width * 0.3)
        x2: rect.x1 + (width * 0.7)
        y1: rect.y1
        y2: rect.y1 + (height * 0.5)
      if pointInRect { x, y }, left
        side = 'left'
      else if pointInRect { x, y }, top
        side = 'top'
      else if pointInRect { x, y }, right
        side = 'right'
      else if pointInRect { x, y }, bottom
        side = 'bottom'
      else
        side = 'right' # WResizer?
      if @provideWebjet
        return if side == 'top' or side == 'bottom'


      @relationableResolve = relation
      relation.resolve this, side

    # for remove
    relationableRelationOld: (e)->
      relation = e.detail.relation
      x = relation.drag.x
      y = relation.drag.y
      rect = @elementSceneRect
      center =
        x: (rect.x1 + rect.x2) / 2
        y: (rect.y1 + rect.y2) / 2
      lefttop = { x: rect.x1, y: rect.y1 }
      righttop = { x: rect.x2, y: rect.y1 }
      leftbottom = { x: rect.x1, y: rect.y2 }
      rightbottom = { x: rect.x2, y: rect.y2 }
      if pointInTriangle { x, y }, lefttop, leftbottom, center
        side = 'left'
      else if pointInTriangle { x, y }, righttop, lefttop, center
        side = 'top'
      else if pointInTriangle { x, y }, rightbottom, righttop, center
        side = 'right'
      else if pointInTriangle { x, y }, leftbottom, rightbottom, center
        side = 'bottom'
      else
        side = 'right' # WResizer?
        console.warn 'relationable: resolve no side'
      # return if @provideWebjet
      @relationableResolve = relation
      relation.resolve this, side


export default relationable
export { relationable }
