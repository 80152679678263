export debounce = (fn, delay)->
  timeoutId = 0
  return (...args)->
    clearTimeout timeoutId
    timeoutId = setTimeout((()=> fn.call(this, ...args)), delay)


export throttle = (fn, limit)->
  throttling = false
  lastArgs = false
  return (...args)->
    if throttling
      lastArgs = args
    if (!throttling)
      lastArgs = false
      throttling = true
      setTimeout =>
        throttling = false
        if lastArgs
          fn.call(this, ...lastArgs)
      , limit
      return fn.call(this, ...args)

export after = (delay, fn)->
  unless fn
    fn = delay
    delay = 0
  setTimeout fn, delay

export genFromMixins = (component, prop, skipFalse = true)->
  functions = []
  for mixin in component.$options.mixins
    functions.push mixin[prop] if mixin[prop]
  if component.$options[prop] and !(component.$options[prop] in functions)
    functions.push component.$options[prop]
  result = []
  for f in functions
    if typeof f == 'function'
      r = f.call(component)
      result.push r if r or !skipFalse
    else
      console.warn "genFromMixins: not a function. Skipped", prop, f
  result

# Call all in one setTimeout. Its important in case of microtasks
groupTimeout = false
groupCallbacks = []
export afterGrouped = (fn)->
  unless groupTimeout
    groupTimeout = true
    groupCallbacks = []
    after fireAfterGrouped
  groupCallbacks.push fn
fireAfterGrouped = ()->
  callbacks = [...groupCallbacks]
  groupCallbacks = []
  groupTimeout = false
  f() for f in callbacks
