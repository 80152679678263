
















import { usualMixinPack } from './mixins'

export default
  name: 'WebjetPicture'
  mixins: [...usualMixinPack]
  data: ->
    aspectRatio: null
  classes: ->
    'transparent-webjet': !@webjet?.data?.title
    'has-relations': @relationableHasRelations
  computed:
    baseWidth: ->
      if (@resizeWidth or @connection?.size?.width) and !@hasChilds
        80
      else
        320
    url: ->
      @webjet?.data?.url
    icon: ->
      @webjet?.data?.icon
    imageStyles: ->
      if @aspectRatio
        return
          position: 'absolute'
          width: '100%'
          height: '100%'
          top: '0'
    imageContainerStyles: ->
      if @aspectRatio
        return
          position: 'relative'
          width: '100%'
          height: '0px'
          'padding-top': @aspectRatio + '%'


  methods:
    onImageLoaded: (e)->
      @setAspectRatio(e.target) unless @aspectRatio

    setAspectRatio: (el) ->
      @aspectRatio = (el.height / el.width) * 100
      # force refresh topmost container
      topParent = @provideWebjet
      if topParent
        while topParent.provideWebjet
          topParent = topParent.provideWebjet
        topParent.contentLoadedUpdate()

  watch:
    isTheoreticallyVisible: (v) ->
      if v and not @aspectRatio and @$refs?.image?
        setTimeout =>
          @setAspectRatio(@$refs.image)

