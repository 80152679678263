














import mixin from './mixin'
import Childs from './Childs'
import { extremPointsCurves } from '@/utils'
export default
  name: 'WejeGraffiti'
  mixins: [ mixin ]
  components: { Childs }
  props: ['data']
  data: ->
    line: ''
    points: {}
    startPostition: { x: -250, y: -250, active: false}
    zoom: {level: 0}
  computed:
    viewBoxData: ->
      minX = @minMax.min.x
      minY = @minMax.min.y
      maxX = @minMax.max.x
      maxY = @minMax.max.y
      if !@editMode

        if @lines
          {x1, y1, x2, y2} = @extremPoints



          x1 = minX if x1 < minX
          y1 = minY if y1 < minY
          x2 = maxX if x2 > maxX
          y2 = maxY if y2 > maxY

          log = (a,b) ->
            return Math.log(a) / Math.log(b)
          w = x2 - x1
          h = y2 - y1
          center = {x: x1 + w / 2, y:  y1 + h / 2  }

          zoom = -10
          while w > @baseWidth * Math.pow(1.1,zoom) or h > @baseHeight * Math.pow(1.1,zoom)
            zoom += 1

          zoom = @maxZoom if zoom > @maxZoom

          offsetX = 0
          offsetY = 0
          center.x = center.x + offsetX
          center.y = center.y + offsetY
          boxWidth = (@baseWidth * Math.pow(1.1,zoom))
          boxHeight = (@baseHeight * Math.pow(1.1,zoom))
          spX = center.x - boxWidth / 2
          spY = center.y - boxHeight / 2

          if spX < minX
            spX = minX
          else if spX + boxWidth > maxX
            offset = spX + boxWidth - maxX
            spX = spX - offset

          if spY < minY
            spY = minY
          else if spY + boxHeight > maxY
            offset = spY + boxHeight - maxY
            spY = spY - offset

          @startPostition.x = spX
          @startPostition.y = spY

          @zoom.level = zoom

        else
          @zoom.level = 0
          @startPostition.x = @baseWidth / 2 * -1
          @startPostition.y = @baseHeight / 2 * -1
      i = @zoom.level

      width = Math.round(@baseWidth * Math.pow 1.1,i)
      height = Math.round(@baseHeight * Math.pow 1.1,i)

      left = @startPostition.x
      top = @startPostition.y
      # console.log {left: left, top: top, width: width, height: height}


      return {left: left, top: top, width: width, height: height}
    viewBox: ->
      return "#{@viewBoxData.left} #{@viewBoxData.top} #{@viewBoxData.width} #{@viewBoxData.height}"
    scale: ->
      if @provideCanvas
        @provideCanvas.scale
      else
        1

    currentZoom: ->
      i = @zoom.level
      scale = Math.pow 1.1,i
      return  Math.round 100 / scale

    extremPoints: ->
      if @lines
        lines = Object.values @lines
        curvs = []
        for l in lines
          if l.curve
            curvs.push l.curve
        { x1, y1, x2, y2 } = extremPointsCurves curvs
        return { x1, x2, y1, y2 }
      else
        return null
    styleCloseEdit: ->
      if @provideSidebar
        scale = 1
      else
        scale = 1 / @scale
        scale = 1 if scale < 1
        scale = 4 if scale > 4
        scale = +scale.toFixed(1)
      return
        transform: "translateX(-50%) scale(#{scale})"
    gridPath: ->
      width = @maxWidth
      height = @maxHeight
      # side width of grid react in px (50)
      rect = 50
      linesX = width / rect
      linesY = height / rect
      iX = 0
      iY = 0
      path = ""
      x = @minMax.min.x
      y = @minMax.min.y
      while iX < linesX - 1
        x += rect
        path += " M #{x} #{@minMax.min.y} L #{x} #{@minMax.max.y}"
        iX += 1

      while iY < linesY - 1
        y += rect
        path += " M #{@minMax.min.x} #{y} L #{@minMax.max.x} #{y} "
        iY += 1
      return path
    maxZoom: ->
      log = (a,b) ->
        return Math.log(a) / Math.log(b)
      ix = log(@maxWidth / @baseWidth, 1.1)
      iy = log(@maxHeight / @baseHeight, 1.1)
      i = if ix <= iy then ix else iy
      i
    grid: ->
      grid = @data?.data?.grid
      return true if grid == undefined
      grid
    lines: ->
      @data.data?.lines
    sizeType: ->
      type = @data.data?.sizeType
      return 0 unless type
      type
    isEmpty: ->
      return true unless @lines
      lines = Object.keys(@lines)
      return false if lines.length
      true
    xLineStyle: ->
      i = @zoom.level
      width = Math.round((@baseWidth * Math.pow 1.1,i) / @maxWidth * 100)
      left = Math.round(@startPostition.x / @maxWidth * 100)
      height = Math.round(5 / @scale)
      height = 10 if height > 10
      return
        width: "#{width - 1}%"
        height: "#{height}px"
        left: "#{left + 50}%"
    yLineStyle: ->
      i = @zoom.level
      height = Math.round((@baseHeight * Math.pow 1.1,i) / @maxHeight * 100)
      top = Math.round(@startPostition.y / @maxHeight * 100)
      width = Math.round(5 / @scale)
      width = 10 if width > 10

      return
        width: "#{width}px"
        height: "#{height - 1}%"
        top: "#{top + 50}%"
    zoomTextStyle: ->
      scale = @scale
      fontSize = 14 / scale
      fontSize = 6 if fontSize < 6
      fontSize = 80 if fontSize > 80
      return
        'font-size': "#{fontSize}px"
    proportion: ->
      switch @sizeType
        when 0 then 1/1
        when 1 then 4/3
        when 2 then 3/4
        when 3 then 16/9
        when 4 then 9/16
        else 1/1
    baseWidth: ->
      switch @sizeType
        when 0 then 500
        when 1 then 500
        when 2 then 375
        when 3 then 500
        when 4 then 281
        else 500

    baseHeight: ->
      return Math.round @baseWidth / @proportion

    maxWidth: ->
      return @baseWidth * 4
    maxHeight: ->
      return @baseHeight * 4

    minMax: ->
      return
        min: { x: @maxWidth / 2 * -1, y: @maxHeight / 2 * -1 }
        max: { x: @maxWidth / 2, y: @maxHeight / 2 }

  watch:
    viewBox: (val) ->
      minX = @minMax.min.x
      minY = @minMax.min.y
      maxX = @minMax.max.x
      maxY = @minMax.max.y
      width = @viewBoxData.width
      height = @viewBoxData.height

      @startPostition.x = minX if @startPostition.x < minX
      @startPostition.y = minY if @startPostition.y < minY

      @startPostition.x = maxX - width if @startPostition.x + width > maxX
      @startPostition.y = maxY - height if @startPostition.y + height > maxY

    lines: (val) ->
      lines = {}
      for key,value of @points
        unless val.hasOwnProperty key
          @$set lines, key, value
       @points = lines
