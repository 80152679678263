




















import { userLimits } from '@/utils'
export default
  name:
    "ModalUpgradePlan"
  props:
    value: Boolean
    boardOwner: String
  computed:
    boardEditorsLimit: ->
      userLimits(@boardOwner)?.boardEditors
    show:
      get: ->
        @value
      set: ->
        @$emit 'input', false
