import { firebind, getPath } from '@/utils'
import { userInfo } from '@/utils'
import { getOwnerUID } from '@/utils'

recBoards = (path, finded=[])->
  items = firebind(if path=='inbox/inbox' then path else "#{path}/connections/items")
  return [] if !items?.$ready
  result = []
  for v in Object.values items
    if v and v.src and !finded[v.src]
      finded[v.src] = true
      category = firebind "#{v.src}/category"
      onboarding = firebind "#{v.src}/meta/onboarding"
      if category.$ready and onboarding.$ready
        if !onboarding.$value and category.$value in ["/webjets/containers/webjets/canvas", "/webjets/containers/webjets/mindmap"]
          result.push v
        else if category.$value == '/webjets/containers/webjets/navigator'
          result = result.concat recBoards(v.src, finded)
  result

recBoardsReady = (path, finded=[])->
  items = firebind "#{path}/connections/items"
  return false unless items?.$ready
  result = true
  for v in Object.values items
    if v and v.src and !finded[v.src]
      finded[v.src] = true
      category = firebind "#{v.src}/category"
      onboarding = firebind "#{v.src}/meta/onboarding"
      result = false unless category.$ready
      result = false unless onboarding.$ready
      if category.$ready
        if category.$value == '/webjets/containers/webjets/navigator'
          result = false unless recBoardsReady(v.src, finded)
  result

export default
  boardAccess: ({ uid }, { boardsReady, currentBoardSrc })->
    return (id)->
      result = {}
      result.loading = true
      if boardsReady and currentBoardSrc
        result.exist = true
        result.loading = undefined
      else
        result.denied = true
        if boardsReady
          result.loading = false
      result.access = true
      result

  currentBoardConnectionSrc: (state, { boards })->
    if state.route.name == 'Board'
      id = state.route.params.boardId
      items = boards
      connection = items.find (b)=>
        b.src.split('/').pop() == id
      return false unless connection
      return connection.$path
    else
      false

  currentBoardSrc: (state, { currentBoardConnectionSrc })->
    cbcs = currentBoardConnectionSrc
    if cbcs
      getPath firebind(cbcs + '/src').$value
    else
      false

  currentBoardId: (state, { currentBoardSrc })->
    if currentBoardSrc
      currentBoardSrc.split('/').pop()
    else
      false

  currentTrashSrc: (_, { currentBoardSrc })->
    if currentBoardSrc
      "#{currentBoardSrc}"
    else
      false

  boards: (state)->
    items = recBoards 'desktop'
    items = items.sort (a, b)->
      return -1 if a.unread and !b.unread
      return 1 if b.unread and !a.unread
      (parseFloat(a.sortKey) or 0) - (parseFloat(b.sortKey) or 0)
    items

  boardsReady: (state)->
    result = recBoardsReady 'desktop'
    result = false if state.publicBoardLoading
    result

  allBoards: (state, { boards })->
    items = boards.filter (v)->
      deletedFlag = firebind "#{v.src}/boardDeleted"
      !deletedFlag.$value
    items.map (v)-> v.$path

  myBoards: (state, { boards })->
    items = boards.filter (v)->
      v.src and state.uid == getOwnerUID v.src
    # filter out deleted boards
    items = items.filter (v)->
      deletedFlag = firebind "#{v.src}/boardDeleted"
      !deletedFlag.$value
    items.map (v)-> v.$path

  sharedBoards: (state, { boards })->
    items = boards.filter (v)->
      v.src and state.uid != getOwnerUID v.src
    items.map (v)-> v.$path

  trashBoards: (state, { boards })->
    # select own boards
    items = boards.filter (v)->
      v.src and state.uid == getOwnerUID v.src
    # select deleted boards
    items = items.filter (v)->
      deletedFlag = firebind "#{v.src}/boardDeleted"
      deletedFlag.$value
    items.map (v)-> v.$path

  starredBoards: (state, { boards })->
    items = boards.filter (v)->
      v.starred
    items = items.filter (v)->
      deletedFlag = firebind "#{v.src}/boardDeleted"
      !deletedFlag.$value
    items.map (v)-> v.$path

  unreadBoards: (state, { boards })->
    items = boards.filter (v)->
      v.unread
    items = items.filter (v)->
      deletedFlag = firebind "#{v.src}/boardDeleted"
      !deletedFlag.$value
    items.map (v)-> v.$path

  inboxBoards: (state)->
    recBoards 'inbox/inbox'

  collaborators: ({ uid }, { boards })->
    users = {}
    for b in boards
      shared = firebind "#{b.src}/shared"
      if shared.$ready and shared[uid]
        for k, v of shared
          if k!='*'
            users[k] = 0 unless users[k]
            users[k] += 1
    result = []
    for u, k of users
      info = userInfo(u)
      info['count'] = k
      result.push info
    result

  teams: (state, getters)->
    # temporary way
    result = []
    for k, v of getters.boards
      team = firebind "#{v.src}/team"
      if team.$ready and team.$value
        result.push team.$value unless result.includes team.$value
    result

  teamBoards: (state, getters)->
    return (id)->
      return false unless id
      items = getters.boards.filter (v)->
        deletedFlag = firebind "#{v.src}/boardDeleted"
        !deletedFlag.$value
      result = []
      srcs = []
      for k, v of items
        team = firebind "#{v.src}/team"
        if team.$ready and team.$value
          if team.$value == id
            src = v.src
            src = src.substring(9) if src.startsWith 'firebase:'
            unless srcs.includes src
              result.push v
              srcs.push src
      result.sort (a, b)=>
        return -1 if a.starred and !b.starred
        return 1 if !a.starred and b.starred
        if a.starred and b.starred
          return -1 if a.$path > b.$path
          return 1 if a.$path < b.$path
        adate = firebind "#{a.src}/meta/updateDate"
        bdate = firebind "#{b.src}/meta/updateDate"
        if adate.$ready and bdate.$ready
          new Date(bdate.$value).getTime() - new Date(adate.$value).getTime()
        else
          0
      result.map (v)-> v.$path

  currentTeamId: ({ route, uid })->
    # FLAG.NEW_ROUTING
    if route.name == 'Team'
      route.params.teamId
    else if route.name == 'Board'
      boardTeam = firebind("/boards/#{route.params.boardId}/team")
      if boardTeam.$ready
        boardTeam.$value
      else
        false
    else if route.path == '/'
      "team_#{uid}"
    else
      false

  currentTeamBoards: ({}, { currentTeamId, teamBoards })->
    teamBoards currentTeamId
