










import ContextBtn from './ContextBtn'
export default
  name: 'AnchorType'
  components: { ContextBtn }
  priority: 10000

  if: (selection)->
    selection.length == 1 and selection[0].webjet.category == 'anchor'

  computed:
    selection: -> @$store.getters['webjet/selected']

    anchorType: ->
      type = @selection[0].webjet.data?.type
      return 'iframe' unless type
      type

  methods:
    action: ->
      type = if @anchorType == 'bookmark' then 'iframe' else 'bookmark'
      path = @selection[0].src
      @$store.dispatch 'webjet/setAnchorType', { path: path, type: type }
