








import { anchorSortKey } from '@/utils'
import SidebarObject from '../SidebarObject'
export default
  name: 'Navigation'
  inject: ['provideCanvas', 'provideApp']
  components: {SidebarObject}

  computed:
    anchors: ->
      items = @provideCanvas.connections?.items
      return [] unless items
      items = Object.values items
      items = items.filter (c)=>
        w = @firebind(c.src)
        category = w.category if w
        if category == 'anchor'
          d = Date.parse(w.meta.createDate)
        category == 'anchor'
      itemsSort = items.sort (a, b)=>
        navSortKeyA = anchorSortKey(a)
        navSortKeyB = anchorSortKey(b)
        parseFloat(navSortKeyA) - parseFloat(navSortKeyB)
      items
    activeAnchors: ->
      @provideCanvas.anchors

  methods:
    startPresentation: ->
      return unless @provideCanvas.anchors.length
      src = @anchors[0].src.split('/').slice(-2).join('/')
      @$router.push path: @$router.path, query: {p: '1'}
      @provideApp.navbarClose()

    copyLink: ->
      if @provideBoardSrc?.includes '/boards/'
        link = "#{window.location.origin}/b/#{@$route.params.boardId}?p=1"
      else
        link = "#{window.location.origin}/b/#{@$route.params.boardId}/#{@provideCanvas.boardOwner}?p=1"
      if window.clipboardData
        # should work in Internet Explorer
        window.clipboardData.setData 'Text', link
      else
        # all other browsers
        navigator.clipboard.writeText link
      @provideCanvas.showCaution
        message: @$t('canvas.message.linkCopied')
        loader: false
