import { encodeDataTransfer, decodeDataTransfer, getDataForCopyFromWebjets } from '@/utils'

export default
  mounted: ->
    document.addEventListener 'copy', @copy
  beforeDestroy: ->
    document.removeEventListener 'copy', @copy

  methods:
    copy: (e)->
      return unless e.target.nodeName=='BODY'
      toCopy = @$store.getters['webjet/selected']?.filter (w)-> w.canDraggable
      return if !toCopy or toCopy.length == 0
      pathArr = @$store.getters['webjet/selected']?.map (s) -> s.webjet.$path
      dataForCopy = getDataForCopyFromWebjets pathArr
      data = @copyGetData(toCopy)
      e.clipboardData.clearData()
      e.clipboardData.setData "webjets/#{encodeDataTransfer(data)}", {}
      e.clipboardData.setData("text/plain", dataForCopy.text) if dataForCopy?.text
      e.clipboardData.setData("text/html", dataForCopy.html) if dataForCopy?.html
      e.preventDefault()

    copyGetData: (toCopy)->
      data =
        mode: 'copy'
        items: []
        hasContainer: false
        canDropOnlyOnCanvas: false
        boardSrc: @connection.src
      for w, i in toCopy
        data.hasContainer = true if w.isContainer
        data.canDropOnlyOnCanvas = true if w.canDropOnlyOnCanvas
        data.items.push @copyGetItemData w, i, toCopy.length
      data

    copyGetItemData: (w, i, n)->
      data =
        path: w.src
      if w.provideScene and n>1
        # copy set of webjets in scene, using coordinates offset to center of scene
        data.offsetX = (@sceneX + @sceneWidth / 2 - 150) - w.elementSceneRect.x1
        data.offsetY = (@sceneY + @sceneHeight / 2 - 50) - w.elementSceneRect.y1
        #console.log 'item in scene:', i, n, data
      else
        # one webjet or webjet off scene (in sidebar), we just use half width/height offset to place it in center on paste
        data.offsetX = w.$el.getBoundingClientRect().width / 2 + 40*i
        data.offsetY = w.$el.getBoundingClientRect().height / 2 + 40*i
        #console.log 'ONE item or item OFF scene:', i, n, data
      data
