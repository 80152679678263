






import { mdiCloudAlert } from '@mdi/js'
export default
  name: 'ConnectedStatus'
  data: ->
    icon: mdiCloudAlert
    timeout: false
  computed:
    show: ->
      result = false
      if @$store.state.connected
        @timeout = false
        result = false
      else
        if @timeout
          result = true
        else
          setTimeout (=> @timeout = true), 2000
      result

