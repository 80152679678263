






























import { userColor, userDisplayName, nowFirebase } from '@/utils'
import { mdiBellOutline, mdiBellOffOutline, mdiChatRemoveOutline } from '@mdi/js'
import msgMp3 from '@/assets/audio/msg.mp3'

export default
  name: 'Chat'
  inject: ['provideCanvas']
  props: ['chat']
  data: ->
    msg: ''
    messages: []
    timeFirstLoaded: null
    focus: false
    iconChatHide: mdiChatRemoveOutline
    iconSound: mdiBellOutline
    iconSoundDisallow: mdiBellOffOutline
    showChat: true
    showInput: false
    sound: true

  watch:
    chatArr: (newValue, oldValue) ->
      results = newValue.filter (n) ->
        equal = oldValue.filter (o) ->
          for k, v of o
            if n[k] != v
              return true
          return false
        if equal.length == oldValue.length
          return true
        return false
      if results.length
        @addMessage results
  computed:
    keymap: ->
      'ctrl+/': @showHideInput
    chatArr: ->
      msgs = []
      for item of @chat
        if @timeFirstLoaded? and @timeFirstLoaded < @chat[item].$ts
          obj = {}
          now = nowFirebase()
          Object.assign obj, @chat[item]
          Object.defineProperty(obj, '$ts', { enumerable: true, value: now })
          Object.defineProperty(obj, 'uid', {enumerable: true, value: @chat[item].$uid })
          msgs.push obj
      msgs
  methods:
    allowDisallowSound: ->
      @sound = !@sound
      localStorage.chatSound = @sound
    showHideInput: ->
      @showInput = !@showInput
    showHideInputCntrl: (e) ->
      if e.ctrlKey && e.keyCode == 191
        @showHideInput()
        e.stopPropagation()

    openCloseChat: (e) ->
      if e.ctrlKey && e.keyCode in [191] || !e.ctrlKey
        @showChat = !@showChat
        if @showChat
          @$refs.input.focus()
        else
          @$refs.input.blur()
    blur: ->
      @$refs.input.blur()
    color: (uid) ->
      return userColor(uid)
    displayName: (uid) ->
      return userDisplayName uid
    send: ->
      @msg.trim()
      return unless @msg
      now = nowFirebase()
      msg = {}
      msg.msg = @msg
      msg.uid = @$store.state.uid
      msg.$ts = now
      msg.my = true
      @addMessage [msg], true
      @provideCanvas.sendChatMsg { msg: @msg }
      @msg = ''
    addMessage: (msgs, myMsg = false) ->
      if !myMsg and @sound
        msgAudio = new Audio msgMp3
        msgAudio.play()
      chat = @$refs.chat
      needScroll = false
      # html escape
      entityMap = {
        '<': '&lt;'
        '>': '&gt;'
        '"': '&quot;'
        "'": '&#39;'
        '`': '&#x60;'
        '=': '&#x3D;'
      }
      re = /[<>"'`=]/g
      text = String(msgs[0]['msg']).replace re , (s)->
        return entityMap[s]
      # TODO disable all html
      reUrl = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g
      text = text.replace reUrl, '<a href="$&" target="_blank">$&</a>'
      msgs[0].msg = text
      msgs[0].opacity = 1
      len = @messages.length
      # Хак для моментального скрола
      promise = new Promise (resolve) =>
        setTimeout =>
          @messages.push ...msgs
          resolve('result')
        , 0
      promise.then (result) =>
        chat.scrollTop = chat.scrollHeight
      setTimeout(@cleanMessage, 100) if len == 0
    cleanMessage: ->
      now = nowFirebase()
      delay = 30000
      @messages = @messages.filter (m)->
        (m.$ts + delay) > now
      for m in @messages
        opacity = (m.$ts + delay - now) / delay
        m.opacity = opacity.toFixed 2
      if @messages.length
        setTimeout @cleanMessage, 100
    hotkeyBlocker: (e) ->
      if e.ctrlKey && e.keyCode in [191]
        return
      # allow undo/redo
      if e.ctrlKey && e.keyCode in [ 89, 90 ]
        return
      # block other hotkeys
      e.stopPropagation()
      # block ctrl +/-/0
      if e.ctrlKey && e.keyCode in [ 187, 189, 48, 107, 109, 45, 43, 96 ]
        e.preventDefault()
    clickOutsideChat: (e) ->
      unless e.target.closest '.chat'
        @showInput = false
  mounted: ->
    @timeFirstLoaded = nowFirebase()
    if localStorage.chatSound
      try
        @sound = JSON.parse localStorage.chatSound
      catch err
        localStorage.removeItem 'chatSound'
  created: ->
    document.addEventListener 'mousedown', @clickOutsideChat
  destroyed: ->
    document.removeEventListener 'mousedown', @clickOutsideChat
