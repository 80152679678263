import Vue from 'vue'
component = false

proxy = new Proxy {},
  get: (target, name)->
    return target[name] if name of target
    parent = component.$parent
    while parent
      if parent.$options.context and parent.$options.context[name] != undefined
        #result = parent.$options.context[name].call(parent)
        result = parent.$options.context[name]
        result = result.call(parent) if typeof result == 'function'
        if result != undefined
          return result
      parent = parent.$parent
    result

Object.defineProperty Vue.prototype, 'context',
  get: (a,b)->
    component = this
    proxy
