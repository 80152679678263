














































import { mdiChevronDown, mdiCheck, mdiClose, mdiContentCopy } from '@mdi/js'
import { firebind, getOwnerUID } from '@/utils'
import Avatar from '@/components/Avatar.vue'
import SiteCollaborationUser from './SiteSettings/SiteCollaborationUser.vue'
export default
  name: 'SiteSharing'
  props: ['data']
  components:
    {
      Avatar
      SiteCollaborationUser
    }
  inject:
    provideCanvas: { default: null }
    provideSidebar: { default: null }
  data: ->
    activeSelectUser: false
    showSelectUsers: false
    errorEmail: false
    errorEmailText: ''
    mail: ''
    showSelectUsers: false
    iconArrow: mdiChevronDown
    iconCheck: mdiCheck
    iconRemove: mdiClose
    iconCopy: mdiContentCopy
    inviteLoading: false
  methods:
    copySiteUrl: ->
      link = "#{window.location.origin}#{@siteUrl}"
      if window.clipboardData
        # should work in Internet Explorer
        window.clipboardData.setData 'Text', link
      else
        # all other browsers
        navigator.clipboard.writeText link
      @provideCanvas.showCaution
        message: @$t('canvas.message.linkCopied')
        loader: false
    setPublic: (val) ->
      await @$store.dispatch 'weje/setPublic',
        path: @data?.webjetPath
        value: val
    clickMailInput: ->
      @showSelectUsers = true
      @activeSelectUser = false
    chekEmailOnInput: ->
      @mail = @mail.toLowerCase()
      @showSelectUsers = true
      @activeSelectUser = false
      @sendInviteLinkPopup = false
      @errorEmail = false
      @errorEmailText = ''
      if @mail.match /^\S+@\S+[\.][0-9a-z ]+$/g
        uid = (await firebind "/emails/#{@mail.replace(/\./g, ',')}/uid")?.$value
        if uid
          @uidForInvite = uid
        else
          @uidForInvite = ''
      else
        @uidForInvite = ''
    enterInMail: ->
      if @activeSelectUser != false
        @mail = @filteredCollaborators[@activeSelectUser].email
        @showSelectUsers = false
        @activeSelectUser = false
      else
        @showSelectUsers = false
        @invite()
    downArrow: ->
      if @activeSelectUser == false
        @activeSelectUser = 0
      else if @activeSelectUser < @filteredCollaborators.length - 1
        @activeSelectUser += 1
    upArrow: ->
      if @activeSelectUser != 0
        @activeSelectUser -= 1
    selectUser: (e,email) ->
      @mail = email
      @showSelectUsers = false
      e.preventDefault()
      e.stopPropagation()
    invite: ->
      return unless @mail
      if not @mail.match /^\S+@\S+[\.][0-9a-z ]+$/g
        @errorEmail = true
        return

      @uidForInvite = (await firebind "/emails/#{@mail.replace(/\./g, ',')}/uid")?.$value unless @uidForInvite
      if @uidForInvite and @chekUidInvite
        @inviteLoading = true
        await @$store.dispatch 'weje/sharePrivate',
          path: @data?.webjetPath
          uid: @uidForInvite
          mode: true
        @mail = ''
        @uidForInvite = ''
        @inviteLoading = false
      else if !@uidForInvite
        @sendInviteLinkPopup = true
        @errorEmail = true
        @errorEmailText = @$t('board.share.errorNotFound')
      else
        @errorEmail = true
        @errorEmailText = @$t('board.share.errorAlreadyHasAccess')
  computed:
    siteUrl: ->
      "/w/#{@data.webjetPath.split('/').pop()}"
    canEdit: ->
      return true if @provideCanvas?.userCanEdit

    isPublic:
      set: (v) ->
        @setPublic v
      get: ->
        if @data?.public?
          return @data?.public
        else
          return null
    siteOwner: ->
      getOwnerUID @data?.webjetPath
    privateUsers: ->
      result = {}
      if @data?.private
        for k,v of @data?.private
          result[k] = v if v
      result
    chekUidInvite: ->
      if @uidForInvite and
         @uidForInvite != @siteOwner and
         @uidForInvite != @$store.state.uid and
         !@data?.private?[@uidForInvite]
        return true
      else
        return false
    collaborators: ->
      collaborators = @$store.getters.collaborators
      myUid = @$store.state.uid
      filteredShared = @filteredShared
      collaboratorsWithoutMe = collaborators.filter (c) ->
        return false if !c.email and !c.fname and !c.lname
        for key, value of filteredShared
          if key == c.uid
            return false
        return true if c.uid != myUid
      collaboratorsWithoutMe
    filteredCollaborators: ->
      collaborators = @collaborators
      text = @mail
      re = new RegExp text, 'i'
      filteredCollaborators = collaborators.filter (c) ->
        return true if c.email and re.test c.email
        return true if c.fname and re.test c.fname
        return true if c.lname and re.test c.lname

      if text.length < 1
        return collaborators
      filteredCollaborators
