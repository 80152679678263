









import { generateSort, getWebjetForText, getWebjetForWejeLink } from '@/utils'
export default
  name:
    "ShareDataProcessing"
  computed:
    route: ->
      return this.$route
  mounted: ->
    if @route.query?.share

      cache = await caches.open('share')
      # options = { headers: { 'Content-Type': 'application/json' }, status: 200 }
      # response = new Response(JSON.stringify({title:'ff32311', text: 'https://www.youtube.com/watch?v=6g1o6SMec2M', url: ''}), options)
      # await cache.put('/2222', response)

      cachesKeys = await cache.keys()

      # @testData += JSON.stringify cachesKeys

      # console.log 'cache keys', cachesKeys
      results = null
      for request of cachesKeys
        # console.log 'request', cachesKeys[request]
        res = await cache.match cachesKeys[request], {ignoreVary: true, ignoreSearch: true, gnoreMethod: true}
        # res.then( (resp) => console.log 'resp', resp)
        # console.log 'res', res
        contentType = res.headers.get('Content-Type')
        if contentType == 'application/json'
          respons = await res.json()
          # console.log 'response json', respons
          # @testData += JSON.stringify respons
          # @testData += '|'

          if respons
            if respons?.title or respons?.text or respons?.url
              if respons?.url
                webjetForWejeLink = await getWebjetForWejeLink respons?.url
                if webjetForWejeLink
                  category = webjetForWejeLink.category
                  webjet = webjetForWejeLink.webjet
                else

                  { category, webjet } = getWebjetForText respons?.url

              if !category or category == '/webjets/content/webjets/note'
                { category, webjet } = getWebjetForText respons?.text if respons?.text
              webjet.data.title = respons?.title  if respons.title

              result = await @$store.dispatch 'webjet/create',
                category: category
                dest: 'inventory' #dest
                webjet: webjet
                connection: generateSort(@firebind('inventory'), true)
              cache.delete cachesKeys[request] if result
        else
          formData = await res.formData()
          files = await formData.getAll('media')
          results = await @$store.dispatch 'webjet/dataTransfer',
            dest: 'inventory' #dest
            dataTransfer:
              types: [ 'Files' ]
              files: files
            data: generateSort(@firebind('inventory'), true)

          cache.delete cachesKeys[request] if results
    @$router.replace path: '/unsorted'
