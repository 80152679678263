



























import DialogTemplate from '@/components/DialogTemplate'
import Collab from './Collab.vue'
import Public from './Public.vue'
import Name from './Name.vue'
export default
  name:
    "Settings"
  components:
    { DialogTemplate, Collab, Public, Name }
  data: ->
    projectAddUserDialog: false
    projectDeleteDialog: false
