# if change COLOR_COUNT all users colors changes
COLOR_COUNT = 21

export userColors =
[
  '#FC9F09'
  '#EA6600'
  '#EA2F2F'
  '#E81663'
  '#EC28AE'
  '#AD17D4'
  '#5E35B1'
  '#3949AB'
  '#0096DF'
  '#757575'
  '#00ACC1'
  '#009989'
  '#388E3C'
  '#5F9E27'
  '#9D9C1D'
  '#90C100'
  '#4DC700'
  '#00C866'
  '#B22222'
  '#AA5519'
  '#826961'
]

if userColors.length != COLOR_COUNT
  console.warn 'color count mismatch', COLOR_COUNT, userColors.length

hashCode = (str)->
  str = String str
  hash = 0
  return hash if str.length == 0
  for v, i in str
    chr   = str.charCodeAt(i)
    hash  = ((hash << 5) - hash) + chr
    hash |= 0
  Math.abs hash

export userColorIndex = (id)->
  hashCode(id) % COLOR_COUNT

export userColor = (id)->
  userColors[userColorIndex(id)]
