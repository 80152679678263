













# import { usualMixinPack } from './mixins'
import { webjet, positionable, childable, draggable, selectable, themeable, relationable, plusable, resizable } from './mixins'

export default
  name: 'WebjetBtnLink'
  mixins: [ webjet, positionable, childable, draggable, selectable, themeable, relationable, plusable, resizable ]
  computed:
    urlData: ->
      url = new URL(@url)
      return url

    url: ->
      @webjet?.data?.url
    linkOnWejePage: ->
      if @urlData?.pathname
        pnArr = @urlData.pathname.split '/'
        if pnArr[1] == 'w'
          return pnArr[2]
      false
    link: ->
      link = ''
      link += @urlData?.pathname if @urlData?.pathname
      link += @urlData?.search if @urlData?.search
      if @linkOnWejePage and FLAG.DIALOG_SITE
        link = "#s=#{@linkOnWejePage}"
      # console.log 'link', link
      return link

    boardId: ->
      pathname = @urlData?.pathname.split('/')
      boardId = null
      boardId = pathname[2] if pathname[2] and pathname[1] == 'b'
      return boardId
      # @webjet?.data?.boardId
    href: ->
      return @link

    titlePlaceholder: ->
      return @url unless @webjet?.data?.title
      @webjet?.data?.title

    linkToThisBoard: ->
      return true if @linkOnWejePage and FLAG.DIALOG_SITE
      @boardId == @$route.params.boardId
