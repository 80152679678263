








import ContextBtn from './ContextBtn'
export default
  name: 'CopyLink'
  components: { ContextBtn }
  inject: { provideCanvas: { default: null } }

  if: (selection)->
    selection.length == 1 and selection[0].provideScene and selection[0].$options.name != 'WebjetPaint'

  computed:
    selection: -> @$store.getters['webjet/selected']

  methods:
    action: ->
      if @selection[0].webjet.category == 'btnLink'
        link = @selection[0].webjet?.data?.url
      else
        src = @selection[0].webjet.$path.split('/').slice(-2).join('/')
        link = "#{window.location.origin}/b/#{@$route.params.boardId}/#{@provideCanvas.boardOwner}?w=#{src}"
      if window.clipboardData
        # should work in Internet Explorer
        window.clipboardData.setData 'Text', link
      else
        # all other browsers
        navigator.clipboard.writeText link
      @provideCanvas.showCaution
        message: @$t('canvas.message.linkCopied')
        loader: false
