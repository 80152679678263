




























































import { generateSort, selectCreatedWebjetsWithoutCanvas } from '@/utils'

export default
  name: 'InventoryTools'
  inject: ['provideApp', 'provideCanvas']
  inject: { provideApp: { default: null }, provideCanvas: { default: null } }
  methods:
    selectCreatedWebjet: (path)->
      setTimeout =>
        element = document.querySelector "##{path.split('/').pop()}"
        webjet = element.webjet
        await @$store.dispatch 'webjet/setNowselected', { webjets: [webjet] }
        await @$store.dispatch 'webjet/setPreselected', { webjets: [] }
        await @$store.dispatch 'webjet/relation/setSelected', { webjet: null }
        if webjet.$options.name=='WebjetCard'
          webjet.$refs?.editor?.activateEditor()
        if webjet.$options.name=='WebjetGraffiti'
          webjet.$refs?.editor?.activateEditor()
    scrollToTop: ->
      content = @$parent.$el.getElementsByClassName('content')[0]
      content = @$parent.$parent.$el.getElementsByClassName('scroll-content')[0]
      if content
        content.scrollTo {top:1, behavior: 'auto'}
    createCard: (e) ->
      result = await @$store.dispatch 'webjet/create',
        category: '/webjets/content/webjets/note'
        dest: 'inventory' #dest
        connection: generateSort(@firebind('inventory'), true)
      if result
        @scrollToTop()
        @selectCreatedWebjet result.connectionPath

    createGraffiti: (e) ->
      result = await @$store.dispatch 'webjet/create',
        category: '/webjets/content/webjets/graffiti'
        dest: 'inventory' #dest
        connection: generateSort(@firebind('inventory'), true)
      if result
        @scrollToTop()
        @selectCreatedWebjet result.connectionPath
    createList: (e) ->
      result = await @$store.dispatch 'webjet/create',
        category: '/webjets/containers/webjets/list'
        dest: 'inventory' #dest
        connection: generateSort(@firebind('inventory'), true)
      if result
        @scrollToTop()
        @selectCreatedWebjet result.connectionPath
    createChecklist: (e)->
      result = await @$store.dispatch 'webjet/create',
        category: 'checklist'
        dest: 'inventory' #dest
        connection: generateSort(@firebind('inventory'), true)
      if result
        @scrollToTop()
        selectCreatedWebjetsWithoutCanvas result.connectionPath
    openUpload: ->
      input = @$el.querySelector 'input.input-upload'
      if input
        input.click()
    processUpload: ->
      input = @$el.querySelector 'input.input-upload'
      if input?.files?.length > 0
        if not (await @provideApp.checkFileSizeLimits input.files, 'inventory')
          input.value = null
          return
        #if @selected?.length == 1 and @selected[0].canDroppable and @selected[0].isExpanded
        #  dest = @selected[0].webjet.$path
        #  @selectionCentralize()
        #else
        #  dest = @boardPath
        # Object passed as dataTransfer parameter should mimic ClipboardEvent.clipboardData and DragEvent.dataTransfer objects
        # in extent enough for webjet/dataTransfer operation to work. So far this operation uses types[0] and files array only.
        results = await @$store.dispatch 'webjet/dataTransfer',
          dest: 'inventory' #dest
          dataTransfer:
            types: [ 'Files' ]
            files: input.files
          data: generateSort(@firebind('inventory'), true)
        input.value = null
        if results?.length
          @scrollToTop()
        # @provideCanvas.selectCreatedWebjets results, true
