







































import mixin from './mixin'

import Childs from './Childs'
export default
  name: 'WejeChecklist'
  mixins: [ mixin ]
  components: { Childs }
  props: ['data']
  computed:
    items: ->
      items = @data?.data?.items
      return [] unless items
      itemsArr = []
      for k,v of items
        itemsArr.push {key: k, data: v}
      itemsSort = itemsArr.sort (a, b)=>
        sortKeyA = @itemSortKey a.data
        sortKeyB = @itemSortKey b.data
        parseFloat(sortKeyA) - parseFloat(sortKeyB)
      itemsArr
    checkedItems: ->
      result = []
      items = @items
      for item in items
        result.push item if item?.data?.status
      result
    notCheckedItems: ->
      result = []
      items = @items
      for item in items
        result.push item unless item?.data?.status
      result
    checklistType: ->
      type = @data?.data?.type
      type = 0 if @data?.data?.type == undefined
      type
    itemsAllCount: ->
      @items.length
    itemsChekedCount: ->
      items = @items
      items = items.filter (item)=>
        item.data.status == 1
      items.length
    itemsAllIsCheked: ->
      @itemsAllCount == @itemsChekedCount
    hideChecked: ->
      hideChecked = @data?.data?.hideChecked
      hideChecked = 1 if @data?.data?.hideChecked == undefined
      hideChecked


  methods:
    itemSortKey: (item) ->
      sortKey = item.sortKey
      unless sortKey
        sortKey = item.createDate
      sortKey
