import { firebind } from '@/utils'
import store from '@/store'
import router from '@/router'
import { getRef } from '@/utils'
import { sendAnalytics } from '@/utils'

export default
  bootstrapCacheUser: ({commit})->
    if localStorage['firebaseCacheUser']
      commit 'INIT_CACHE_USER', JSON.parse(localStorage['firebaseCacheUser'])
    else
      store.state.needAuth = true
    store.subscribe (mutation)=>
      if mutation.type == 'INIT_USER'
        localStorage['firebaseCacheUser'] = JSON.stringify
          uid: store.state.uid
          isRegisteredUser: store.state.isRegisteredUser
          isAnonymousUser: store.state.isAnonymousUser

    visibility = document.visibilityState == 'visible'
    if @state.visibility != visibility
      @state.visibility = visibility
    document.addEventListener 'visibilitychange', (e)=>
      @state.visibility = document.visibilityState == 'visible'

    idleTime = 3 * 60 * 1000
    activityTimestamp = Date.now()
    userIdle = =>
      @state.activity = false
    updateActivity = =>
      if (Date.now() - activityTimestamp) > 10000
        @state.activity = true unless @state.activity
        activityTimestamp = Date.now()
        clearTimeout activityTimeout
        activityTimeout = setTimeout userIdle, idleTime
    activityTimeout = setTimeout userIdle, idleTime
    ['pointermove', 'pointerdown', 'keydown', 'wheel'].forEach (eventName)=>
      document.addEventListener eventName, updateActivity, true

  bootstrapUser: ({ commit, dispatch }, { user })->
    await commit 'INIT_USER', user
    if FLAG.WEB_CLIPPER_EXTENSION
      dispatch 'sendTokenToWebClipper'

  bootstrap: ({commit, dispatch, state})->
    { db, auth, storage } = await import(### webpackChunkName: "db" ###'@/store/db')
    commit 'INIT_DB', { db, storage, auth }
    auth().getRedirectResult().then( (result) =>
      if IS_DEVELOPMENT or IS_STAGE
        console.log 'auth redirect', result
    ).catch (err) =>
      console.log 'auth redirect error', err
    auth().onAuthStateChanged (user)=>
      if user
        if FLAG.LOGOUT_ANONYMOUS and user.isAnonymous and not (state.route.name in ['Board', 'WejeSite'])
          dispatch 'signOut'
          return
        dispatch 'bootstrapUser', { user }
      else
        if (
          state.route.name == 'Board' and (state.route.params.userId or FLAG.NEW_DATA) or
          state.route.name == 'WejeSite'
        ) and !store.state.signOut and !store.state.uid
          auth().signInAnonymously()
          return
        store.state.needAuth = true
        store.state.showAuth = !store.state.signOut
        if !store.state.signOut and store.state.uid
          # signOut in other browser tab
          router.push { name: 'Home' } unless state.route.name=='Home'
          localStorage.removeItem 'firebaseCacheUser'
          for k in Object.keys(localStorage)
            if k.startsWith '_fbc_'
              localStorage.removeItem k
          router.push { name: 'Home' } unless state.route.name=='Home'
          location.reload(true)
    tokenChangedTimestamp = 0
    auth().onIdTokenChanged (user)=>
      if user
        if Date.now() - tokenChangedTimestamp >= 86400000 # 24h
          tokenChangedTimestamp = Date.now()
          sendAnalytics 'sign_in'
      else
        tokenChangedTimestamp = 0

  signIn: ({state}, payload)->
    { auth } = await import(### webpackChunkName: "db" ###'@/store/db')
    try
      await auth().signInWithEmailAndPassword payload.email, payload.password
      state.needAuth = false
      return null
    catch error
      console.error error
      return error.message

  signUp: ({state}, payload)->
    { auth } = await import(### webpackChunkName: "db" ###'@/store/db')
    try
      await auth().createUserWithEmailAndPassword payload.email, payload.password
      state.needAuth = false
      #router.push { name: 'Home' }
      return null
    catch error
      console.error error
      return error.message

  resetPassword: ({state}, payload)->
    { auth } = await import(### webpackChunkName: "db" ###'@/store/db')
    try
      await auth().sendPasswordResetEmail payload.email, { url: location.origin }
      return null
    catch error
      console.error error
      return error.message

  signOut: ({state})->
    { auth } = await import(### webpackChunkName: "db" ###'@/store/db')
    auth().signOut().then =>
      state.needAuth = true
      state.signOut = true
      localStorage.removeItem 'firebaseCacheUser'
      for k in Object.keys(localStorage)
        if k.startsWith '_fbc_'
          localStorage.removeItem k
      router.push { name: 'Home' } unless state.route.name=='Home'
      location.reload(true)

  googleAuth: ({state})->
    { auth } = await import(### webpackChunkName: "db" ###'@/store/db')
    googleAuth = new auth.GoogleAuthProvider()
    googleAuth.addScope("profile")
    googleAuth.addScope("email")
    auth().signInWithPopup(googleAuth).then =>
      router.push { name: 'Home' } unless state.route.name=='Home'

  sendTokenToWebClipper: ({state}) ->
    setTimeout (->
      if (window.CLIPPER_EXTENSION_ID)
        keyRef = getRef("/users/#{state.uid}/apiKeys/webclipper/apiKey")

        if !('randomUUID' in crypto)
          crypto.randomUUID = ->
            ([ 1e7 ] + -1e3 + -4e3 + -8e3 + -1e11).replace /[018]/g, (c) ->
              (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString 16

        keyRef.once 'value', (res) ->
          apiKey = res.$value
          if not apiKey
            apiKey = crypto.randomUUID()
            keyRef.set apiKey
          window.postMessage({apiKey: apiKey, uid: state.uid, extId: window.CLIPPER_EXTENSION_ID}, window.origin)
    ), 1000

  closeClipperBanner: ({state}, {forever}) ->
      state.showClipperBanner = false
      time = new Date().getTime() + 1000 * 60 * 60 * 24
      getRef("/users/#{state.uid}/settings/clipper_banner_time").set time
      if forever
        getRef("/users/#{state.uid}/settings/clipper_banner_state").set 1

  closeDonateBanner: ({state}) ->
      time = new Date().getTime()
      getRef("/users/#{state.uid}/settings/donate_banner_time").set time
