export watchSocket = (store) ->
  store.watch (-> store.getters.boardsReady), (value) ->
    if value
      store.dispatch 'socketBoards/connect'
  store.watch (-> store.getters.boards), (value) ->
    if store.getters.boardsReady and store.state.socketBoards.firstLoaded
      connectionSocketBoards = Object.keys store.state.socketBoards.boards
      dataBoards = []

      for b in value
        boardId = b.src.split('/').pop()
        dataBoards.push boardId

      for b in dataBoards
        if !connectionSocketBoards.includes b
          store.dispatch 'socketBoards/joinListen' , b

      for b in connectionSocketBoards
        if !dataBoards.includes b
          store.dispatch 'socketBoards/leaveBoard' , b
  store.watch (-> store.state.uid), (value) ->
    if value
      store.dispatch 'socketBoards/connect'
    else
      store.dispatch 'socketBoards/disconnect'