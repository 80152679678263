

















import DateItem from "./DateItem"
import SidebarObject from "../SidebarObject"
export default
  name: 'DateContent'
  components: { DateItem, SidebarObject }
  data: ->
    spoilerIsOpen: false
  inject: ['provideCanvas']
  computed:
    items: ->
      items = @provideCanvas.connections?.items
      return [] unless items
      items = Object.values items
      items = items.filter (c)=>
        w = @firebind(c.src)
        category = w.category if w
        category == 'date'
      items
  methods:
    spoiler: ->
      @spoilerIsOpen = !@spoilerIsOpen

