







export default
  name: 'SidebarTab'
  inject: ['provideCanvas', 'provideSidebar']
  props:
    id: String
    component: Object
    label: String
    notify: Boolean
    #active: Boolean
    dropzone: Boolean
    drop: Boolean
    menu: Boolean
    #svgIconUrl: String
  #data: ->
  created: ->
    @provideSidebar.registerTab @id, @component
  computed:
    classes: ->
      return
        notify: @notify
        active: @isActive
        dropzone: @dropzone
        drop: @drop
        menu: @menu
        "sidebar-tab-#{@id}": @id
    isActive: ->
      @provideSidebar.activeTab == @id and @provideSidebar.mode == 'full'
  methods:
    click: ->
      if !@id and @provideSidebar.mode == 'full'
        @provideSidebar.close()
        return
      @provideSidebar.toggle @id

