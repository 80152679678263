









































































import SidebarObject from "./SidebarObject"
import DialogTemplate from '@/components/DialogTemplate'
import { SitePreview } from '@/weje'
export default
  name: 'Trash'
  components: { SidebarObject, DialogTemplate, SitePreview}
  inject: ['provideCanvas']
  context:
    positionable: false
  data: ->
    open: false
    clearDialog: false
    dialog: false
    trashTabs: null
    showWebjets: true
  computed:
    items: ->
      return [] unless @trash?.connections?.trash
      items = Object.values @trash.connections.trash
      items = items.filter (c)-> !c.trashClear
      items = items.reverse()
      # will be sort by meta last update?
      items
    siteItems: ->
      return [] unless @trash?.connections?.trashSites
      items = Object.values @trash.connections.trashSites
      items = items.filter (c)-> !c.trashClear
      items = items.reverse()
      # will be sort by meta last update?
      items
    showClear: ->
      @items.length or @siteItems.length
    smallScreen: ->
      @provideCanvas.smallScreen
  firebind:
    trash: -> @$store.getters['currentTrashSrc']

  methods:
    clear: ->
      await @$store.dispatch 'webjet/clearTrash'
      await @$store.dispatch 'weje/clearTrash'
      @$store.dispatch 'undoredo/reset'
      @clearDialog = false

