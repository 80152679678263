







import mixin from './mixin'
export default
  name: 'WejeBtnLink'
  mixins: [ mixin ]
  props: ['data']
  computed:
    urlData: ->
      url = new URL(@url)
      return url
    link: ->
      link = ''
      link += @urlData?.pathname if @urlData?.pathname
      link += @urlData?.search if @urlData?.search
      return link
    boardId: ->
      pathname = @urlData?.pathname.split('/')
      boardId = null
      boardId = pathname[2] if pathname[2] and pathname[1] == 'b'
      return boardId
    href: ->
      return @link
    linkTitle: ->
      return @url unless @data?.data?.title
      @data?.data?.title
    linkToThisBoard: ->
      @boardId == @$route.params.boardId
