import { register } from 'register-service-worker'

if process.env.NODE_ENV == 'production'
  register "#{process.env.BASE_URL}service-worker.js",
    ready: ->
      console.log 'App is being served from cache by a service worker.\n' + 'For more details, visit https://goo.gl/AFskqB'
      return
    registered: ->
      console.log 'Service worker has been registered.'
      return
    cached: ->
      console.log 'Content has been cached for offline use.'
      return
    updatefound: ->
      console.log 'New content is downloading.'
      return
    updated: ->
      console.log 'New content is available; please refresh.'
      location.reload true
      #setTimeout (->
      #  location.reload true
      #), 1000
      return
    offline: ->
      console.log 'No internet connection found. App is running in offline mode.'
      return
    error: (error) ->
      console.error 'Error during service worker registration:', error
      return
