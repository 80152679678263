










import { usualMixinPack } from './mixins'

export default
  name: 'WebjetGraffiti'
  mixins: [...usualMixinPack]
  # data: ->
  #   aspectRatio: null
  computed:
    component: ->
      return 'WGraffitiImg' if FLAG.GRAFFITI_NEW
      return 'WGraffiti'
  #   url: ->
  #     @webjet?.data?.url
  #   icon: ->
  #     @webjet?.data?.icon
  #   imageStyles: ->
  #     if @aspectRatio
  #       return
  #         position: 'absolute'
  #         width: '100%'
  #         height: '100%'
  #         top: '0'
  #   imageContainerStyles: ->
  #     if @aspectRatio
  #       return
  #         position: 'relative'
  #         width: '100%'
  #         height: '0px'
  #         'padding-top': @aspectRatio + '%'

  # methods:
  #   onImageLoaded: (e)->
  #     @aspectRatio = (e.target.clientHeight / e.target.clientWidth) * 100

