






export default
  name: 'WUploader'
  inject: ['provideWebjet']

  computed:
    isLoading: ->
      @provideWebjet.webjet.meta?.uploadInProgress
    #total: ->
    #  @provideWebjet.webjet.meta?.fileSize||0
    #current: ->
    #  @provideWebjet.webjet.meta?.uploadedSize||0
    progress: ->
      Math.floor( (@provideWebjet.webjet.meta?.uploadedSize||0) / @provideWebjet.webjet.meta?.fileSize * 100 ) || 0
    error: ->
      @provideWebjet.webjet.meta?.uploadError
    errorText: ->
      if !@error
        null
      else if @error.copyError
        t = @$t('webjet.copyError')
      else
        t = @$t('webjet.uploadError')
      if @error.message
        t+':'
      else
        t+"."

