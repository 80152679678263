











import { userColor } from '@/utils'
import { nowFirebase } from '@/utils'
import { wsBoard } from '@/websockets'

export default
  name: 'SharedDraw'
  inject: ['provideCanvas']
  props: ['draw']
  data: ->
    points: []
  watch:
    draw: (value)->
      len = @points.length
      @points.push value
      setTimeout(@cleanPoints, 100) if len == 0
  computed:
    show: ->
      @points.length
    userColor: ->
      if @draw.$uid
        userColor(@draw.$uid)
      else
        '#005eff'
    showPencill: ->
      if @userDrawing and @draw.$uid
        return true
    pancillStyle: ->
      if @points.length
        { x1, x2, y1, y2 } = @rect
        lastPoint = @points[@points.length - 1]
        return
          transform: "translateX(#{lastPoint.x - x1}px ) translateY(#{lastPoint.y - y1 - 12}px) scale(0.5)"
    userDrawing: ->
      wsBoard.get('cursor') and wsBoard.get('cursor')[@draw.$id]?.drawing
    path: ->
      return unless @points.length
      { x1, x2, y1, y2 } = @rect
      result = "M #{@points[0].x - x1},#{@points[0].y - y1}"
      for p in @points
        result += " L#{p.x - x1},#{p.y - y1}"
      result
    quadraticDraw: ->
      return unless @points.length
      { x1, x2, y1, y2 } = @rect
      result = "M#{@points[0].x - x1} #{@points[0].y - y1}"
      for p, i in @points
        if p.newLine?
          result += " M#{p.x - x1} #{p.y - y1}"
        else if i > 2 and !@points[i - 1].newLine?
          lastTwoPoints = @points.slice i-2
          endPoint =
            {
              x: (lastTwoPoints[0].x + lastTwoPoints[1].x) / 2
              y: (lastTwoPoints[0].y + lastTwoPoints[1].y) / 2
            }
          result += " Q#{lastTwoPoints[0].x - x1} #{lastTwoPoints[0].y - y1} #{endPoint.x - x1} #{endPoint.y - y1}"

      result

    quadraticDrawLines: ->
      return unless @points.length
      { x1, x2, y1, y2 } = @rect
      lines = []
      index = 0
      lines.push []
      lines[index] = "M#{@points[0].x - x1} #{@points[0].y - y1}"
      for p, i in @points
        if p.newLine? and i != 0
          index += 1
          lines.push []
          lines[index] = "M#{p.x - x1} #{p.y - y1}"
        else if i > 2 and !@points[i - 1].newLine?
          lastTwoPoints = @points.slice i-2
          endPoint =
            {
              x: (lastTwoPoints[0].x + lastTwoPoints[1].x) / 2
              y: (lastTwoPoints[0].y + lastTwoPoints[1].y) / 2
            }
          lines[index] += " Q#{lastTwoPoints[0].x - x1} #{lastTwoPoints[0].y - y1} #{endPoint.x - x1} #{endPoint.y - y1}"

      lines

    cubicDraw: ->
      return unless @points.length

      { x1, x2, y1, y2 } = @rect
      result = "M#{@points[0].x - x1} #{@points[0].y - y1}"
      # console.log @points
      for p, i in @points
        if i > 0
          if p.newLine?
            result += " M#{p.x - x1} #{p.y - y1}"
          else if !@points[i - 1].newLine?
            result +=  " #{@bezierCommand p, i }"
      result

    rect: ->
      x1 = @points[0].x
      x2 = @points[0].x
      y1 = @points[0].y
      y2 = @points[0].y
      for p in @points
        x1 = p.x if p.x < x1
        x2 = p.x if p.x > x2
        y1 = p.y if p.y < y1
        y2 = p.y if p.y > y2
      x1 -= 25
      y1 -= 25
      x2 += 25
      y2 += 25
      x1 = Math.round x1
      y1 = Math.round y1
      x2 = Math.round x2
      y2 = Math.round y2
      { x1, x2, y1, y2 }
    styles: ->
      return unless @points.length
      { x1, x2, y1, y2 } = @rect
      return
        left: x1 + 'px'
        top: y1 + 'px'
        width: x2 - x1 + 'px'
        height: y2 - y1 + 'px'
  methods:
    bezierCommand: (point, i) ->
      { x1, x2, y1, y2 } = @rect
      cps = @controlPoint(@points[i - 1], @points[i - 2], point)
      cpe = @controlPoint(point,@points[i - 1], @points[i + 1], true)
      return "C #{cps[0] - x1},#{cps[1]- y1} #{cpe[0] - x1},#{cpe[1] - y1} #{point.x - x1},#{point.y - y1}"
    controlPoint: (current, previous, next, reverse) ->
      p = if previous?.newLine? then current else previous || current
      n = if next?.newLine? then current else next || current
      o = @line p, n
      pi = if reverse then Math.PI else 0
      angle = o.angle + pi
      smoothing = 0.2
      length = o.length * smoothing
      x = current.x  + Math.cos(angle) * length
      y = current.y  + Math.sin(angle) * length
      return [x , y]
    line: (a,b) ->
      lengthX = b.x - a.x
      lengthY = b.y - a.y
      return
        {
          length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2))
          angle: Math.atan2(lengthY, lengthX)
        }
    cleanPoints: ->
      now = nowFirebase()
      @points = @points.filter (p)->
        (p.$ts + 10000) > now
      if @points.length
        setTimeout @cleanPoints, 100
