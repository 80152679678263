













import mixin from './mixin'
import Childs from './Childs'
export default
  name: 'WejeLink'
  mixins: [ mixin ]
  components: { Childs }
  props: ['data']
  computed:
    image: ->
      @data.data.image
    icon: ->
      @data.data?.icon
    iconStyles: ->
      result = {}
      result['background-image'] = "url(#{@icon})" if @icon
      result


