


















export default
  name: 'DialogSlide'
  methods:
    confirm: ->
      @$emit 'confirm'
