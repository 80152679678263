

















import CollabUser from './CollabUser.vue'
export default
  name:
    "Collab"

  components:
    { CollabUser }
