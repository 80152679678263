import UserPlan from './UserPlan'
import store from '@/store'
import { firebind } from '@/utils'

uPlan = new UserPlan({})

setTimeout ->
  return unless FLAG.USER_PLAN
  store.watch (-> store.getters['user/plan']), (value)  =>
      uPlan.setPlan value
    , { immediate: true }

  store.watch (-> store.getters['myBoards']), (value)  =>
      collaborations = new Set()
      for b in value
        connection = firebind b
        shared = firebind "#{connection.src}/shared" if connection
        for uid, rights of shared
          if rights == 'rw'
            collaborations.add uid
      uPlan.setUserData {name: 'collaborations' , value: collaborations}
    , { immediate: true }

  store.watch (-> store.getters.myBoards), (value)  =>
      boardsCount = value.length
      uPlan.setUserData {name: 'boardsCount', value: boardsCount}
    , { immediate: true }

export default uPlan
export { uPlan }