import './css/presentation.sass'
import { firebind, anchorSortKey } from '@/utils'

export default
  data: ->
    presentationIsActive: false
  watch:
    presentationIsActive: (val) ->
      board = document.querySelector "#board-page"
      if val
        board.classList.add "board-presentation-active"
        @$refs.sidebar.close()
        @$refs.sidebarleft?.close()
      else
        board.classList.remove "board-presentation-active"
  computed:
    anchors: ->
      items = @connections?.items
      return [] unless items
      items = Object.values items
      items = items.filter (c)=>
        w = @firebind(c.src)
        category = w.category if w and !w.data?.hideInPresentation
        category == 'anchor'
      itemsSort = items.sort (a, b)=>
        navSortKeyA = anchorSortKey(a)
        navSortKeyB = anchorSortKey(b)
        parseFloat(navSortKeyA) - parseFloat(navSortKeyB)
      items

    activeIndexInPresentation: ->
      # console.log @webjetAnchorSrc
      # console.log @anchors
      return unless @webjetAnchorSrc
      activeWebjetSrc = @webjetAnchorSrc.split('/').slice(-2).join('/')
      index = @anchors.findIndex (el) ->
          activeWebjetSrc == el.src.split('/').slice(-2).join('/')
      if index < 0
        @goToFirstWbjetInPresentation(true)
        return
      return index
  methods:
    goToFirstWbjetInPresentation: (replace = false) ->
      unless @anchors[0]
        @presentationCancel()
        return
      src = @anchors[0].src.split('/').slice(-2).join('/')
      @goToWebjet(src, replace)
    presentationCancel: ->
      @presentationIsActive = false
      @webjetAnchorSrc = null
    presentationInit: ->
      unless @anchors.length
        path = @$route.path
        @$router.replace path: path
        return
      @presentationIsActive = true
      # wSrcInRout = this.$route.query.w
      @$store.dispatch 'webjet/setNowselected', { webjets: [] }
      unless @$route.query.w
        @goToFirstWbjetInPresentation(true)

    goToIndexWebjet: (index) ->
      src = @anchors[index].src.split('/').slice(-2).join('/')
      @withoutAnimation =>
        @goToWebjet(src)

    prevWebjet: ->
      index = @activeIndexInPresentation - 1
      return if index < 0
      src = @anchors[index].src.split('/').slice(-2).join('/')
      @withoutAnimation =>
        @goToWebjet(src)

    nextWebjet: ->
      index = @activeIndexInPresentation + 1
      return if index >= @anchors.length
      src = @anchors[index].src.split('/').slice(-2).join('/')
      @withoutAnimation =>
        @goToWebjet(src)
