import './css/themeable.sass'

themeable =
  classes: ->
    return unless @isThemed
    return
      [@themeableClass]: true

  computed:
    isThemeable: -> true
    canTheme: -> true
    isThemed: ->
      @webjet?.data?.backgroundId and @webjet?.data?.backgroundId != '1'
    themeableClass: ->
      return false unless @isThemed
      switch @webjet.data.backgroundId
        when '2' then 'theme-default'
        when '3' then 'theme-gray'
        when '4' then 'theme-red'
        when '5' then 'theme-yellow'
        when '6' then 'theme-green'
        when '7' then 'theme-purple'
        when '8' then 'theme-blue'
        when '9' then 'theme-lightblue'
        when '10' then 'theme-transparent'
        else 'theme-unknown'


export default themeable
export { themeable }
