import { firebind } from '@/utils'

export default
  resetNew: ()->
    inventoryNew = await firebind 'inventory/inventoryNew'
    if inventoryNew.$value
      @commit 'inventory/RESET_NEW'

  markAsRead: (_, { path })->
    connection = await firebind path
    if connection.inventoryUnread
      @commit 'inventory/MARK_AS_READ', { path: path }
