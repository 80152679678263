









































import { encodeDataTransfer, decodeDataTransfer } from '@/utils'
export default
  name: 'Anchor'
  inject: ['provideCanvas']
  props:
    src: String
  data: ->
    editTitle: false
    droppableCounter: 0
  computed:
    title:
      get: ->
        @webjet?.data?.title
      set: (val) ->
        @$store.dispatch 'webjet/updateTitle',
          path: @webjet.$path
          value: val
    userCanEdit: -> @provideCanvas.userCanEdit
    type: ->
      @webjet?.data?.type
    hideInPresentation: ->
      return false unless @webjet?.data?.hideInPresentation
      return @webjet?.data?.hideInPresentation
    classes: ->
      return
        'anchor-iframe': @type == 'iframe'
        'anchor-bookmark': @type == 'bookmark'
        'hide-in-presentation': @hideInPresentation
    placeholder: ->
      if @type == 'bookmark'
        @$t("webjet.anchor.titleBookmark")
      else
        @$t("webjet.anchor.titleFrame")
  methods:
    hideShowInPresentation: ->
      return unless @userCanEdit
      path = @src
      val = !@hideInPresentation
      @$store.dispatch 'webjet/setAnchorHideInPresentation', { path: path, val: val }
    activationEditTitle: ->
      @editTitle = true
    disactivationEditTitle: ->
      @editTitle = false
    click: ->
      src = @webjet.$path.split('/').slice(-2).join('/')
      path = @$route.path
      @provideCanvas.goToWebjet(src)
      # @$router.replace path: path, query: {w: src}
    trash: ->
      items = [{ path: @src }]
      @$store.dispatch 'webjet/trash', { items }
    copyLink: ->
      src = @webjet.$path.split('/').slice(-2).join('/')
      link = "#{window.location.origin}/b/#{@$route.params.boardId}/#{@provideCanvas.boardOwner}?w=#{src}"
      if window.clipboardData
        # should work in Internet Explorer
        window.clipboardData.setData 'Text', link
      else
        # all other browsers
        navigator.clipboard.writeText link
      @provideCanvas.showCaution
        message: @$t('canvas.message.linkCopied')
        loader: false
    hotkeyBlocker: (e) ->
      # allow undo/redo
      if e.ctrlKey && e.keyCode in [ 89, 90 ]
        return
      # block other hotkeys
      e.stopPropagation()
      # block ctrl +/-/0
      if e.ctrlKey && e.keyCode in [ 187, 189, 48, 107, 109, 45, 43, 96 ]
        e.preventDefault()
  firebind:
    connection: -> @src
    webjet: -> @connection.src
