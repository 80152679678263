








export default
  name: 'Caution'
  inject: ['provideCanvas']
  computed:
    caution: -> @provideCanvas.caution
