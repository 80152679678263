import { getPath } from '@/utils'
import store from '@/store'
import state from '@/store/state'
import { firebindData } from '@/store/firebind'

# no this if call with func
export firebind = (path)->
  return unless state.uid
  if typeof path == 'function'
    #console.log this
    if !this and path.hasOwnProperty 'prototype'
      console.warn 'firebind: function with this'
    path = path.call this
  if path and (typeof path == 'string' or path instanceof String)
    path = getPath path
    if firebindData[path] == undefined
      store.dispatch 'firebindPath', path
    return firebindData[path]
  else
    console.error 'firebind: unsupport type', typeof path if path and state.uid
  return null

# no name in this case!
export mapFirebind = (f)->
  result = mappedFirebind = ->
    return unless state.uid
    path = f.call this
    if path and (typeof path == 'string' or path instanceof String)
      path = getPath path
      if firebindData[path] == undefined
        store.dispatch 'firebindPath', path
      data = firebindData[path]
      return null unless data.$ready or data.$sync
      return data
    else
      console.error 'firebind: unsupport type' if path and state.uid
    return null
