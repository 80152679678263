











import ContextBtn from './ContextBtn'
import DialogSlide from '../DialogSlide'
export default
  name: 'GraffitiLastic'
  components: { ContextBtn, DialogSlide }
  inject: { provideCanvas: { default: null } }
  priority: 10000

  if: (selection)->
    return false unless FLAG.GRAFFITI_TOOLS_TO_CONTEXT_MENU
    selection.length == 1 and selection[0].$options.name == 'WebjetGraffiti' and selection[0].$refs.editor?.editMode

  computed:
    selection: -> @$store.getters['webjet/selected']
    title: ->
      @$t('canvas.drawOnCanvasTools.eraser')
    isActive: ->
      @selection[0].$refs.editor.graffitiData.graffitiType == 'lastic'


  methods:
    action: ->
      @selection[0].$refs.editor.graffitiData.graffitiType = 'lastic'
    deletePaints: ->
      # TODO: delete paints function
