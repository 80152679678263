import { operationActions } from '@/store/operation'
import getters from './inventory-getters'
import mutations from './inventory-mutations'
import operations from './inventory-operations'


export default
  namespaced: true
  getters: getters
  mutations: mutations
  actions: operationActions operations
