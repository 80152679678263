
































import ContextBtn from './ContextBtn'
export default
  name: 'AlignSelection'
  components: { ContextBtn }
  priority: 10000
  inject: { provideCanvas: { default: null } }
  if: (selection)->
    return false unless FLAG.ALIGN_SELECTION
    return true if selection.length > 1
  computed:
    selection: -> @$store.getters['webjet/selected']
    selectionSorted: ->
      @selection.sort (a, b) ->
        return a.elementSceneRect.y1 - b.elementSceneRect.y1 if a.elementSceneRect.x1 - b.elementSceneRect.x1 == 0
        return a.elementSceneRect.x1 - b.elementSceneRect.x1
  methods:
    alignItems: (mode) ->
      items = []
      distY = distX = 0

      { x, y, target } = @getTargetOptions mode

      for item in @selection
        if mode == 'right'
          distX = target.offsetWidth - item.$el.offsetWidth
        else if mode == 'down'
          distY = target.offsetHeight - item.$el.offsetHeight

        items.push
          x: (x or item.elementSceneRect.x1) + distX
          y: (y or item.elementSceneRect.y1) + distY
          path: item.src

      @action items

    getTargetOptions: (mode) ->
      for item in @selection
        switch mode
          when 'right'
            x1 = Math.max x1 or @selection[0].elementSceneRect.x1, item.elementSceneRect.x1
            if x1 == item.elementSceneRect.x1
              x = item.elementSceneRect.x1
              target = item.$el
            break
          when 'left'
            x1 = Math.min x1 or @selection[0].elementSceneRect.x1, item.elementSceneRect.x1
            if x1 == item.elementSceneRect.x1
              x = item.elementSceneRect.x1
              target = item.$el
            break
          when 'down'
            y1 = Math.max y1 or @selection[0].elementSceneRect.y1, item.elementSceneRect.y1
            if y1 == item.elementSceneRect.y1
              y = item.elementSceneRect.y1
              target = item.$el
            break
          when 'up'
            y1 = Math.min y1 or @selection[0].elementSceneRect.y1, item.elementSceneRect.y1
            if y1 == item.elementSceneRect.y1
              y = item.elementSceneRect.y1
              target = item.$el
            break

      return { x, y, target }

    tidyUp: (mode) ->
      items = []
      stepDistHeight = stepDistWidth = 0

      for item, i in @selectionSorted
        if mode == 'vertical'
          x = @selectionSorted[0].elementSceneRect.x1
          y = @selectionSorted[0].elementSceneRect.y1 + stepDistHeight
        else
          x = @selectionSorted[0].elementSceneRect.x1 + stepDistWidth
          y = @selectionSorted[0].elementSceneRect.y1

        stepDistWidth += item.$el.offsetWidth + 80
        stepDistHeight += item.$el.offsetHeight + 80

        items.push
          x: x
          y: y
          path: item.src

      @action items

    action: (items) ->
      @$store.dispatch 'webjet/alignItems',
        items: items
