resizable =
  data: ->
    resizeActive: false
    resizableLineX: null
    resizableLineY: null
    resizableProportion: null
    resizableActiveData: { x: null, y: null }
    resizableClickWithoutMove: false
    resizableTmpHeight: null
    resizableTmpWidth: null
    resizablesSart: 0
    resizableRound: true
    resizablesTouch: { hold: false, active: false, ready: false, fired: false }
    resizableTmpMultiplicity: 0

  classes: ->
    return false if @provideWebjet
    return
      "resize-line-#{@resizableLineX}": @resizableLineX
      "resize-line-#{@resizableLineY}": @resizableLineY and @resizableParams.vertical
      "resize-is-active": @resizableLineX or @resizableLineY
      "resize-space": @resizableSelected

  listeners: ->
    if FLAG.WEBJET_RESIZER and @resizableEnable and @resizableParams.enable
      mousedown: @resizeMouseDown
      mousemove: @resizeMouseMove
      mouseleave: @resizeMouseLeave
      touchstart: @resizeTouchStart

  computed:
    resizableSelected: ->
      return false unless FLAG.RESIZE_SPACE_WHEN_SELECTED
      @isSelected
    resizablesIsExpanded: ->
      if !@isExpandable
        return true
      else
        @isExpanded
    resizableEnable: ->
      return false if @isReadOnly
      return false if @provideWebjet
      return false unless @provideScene
      true
    resizableParams: ->
      switch @webjet.category
        when '/webjets/content/webjets/photo'
          return
            enable: true
            proportional: true
            vertical: true
            saveProportion: false
        when '/webjets/content/webjets/html5video'
          return
            enable: true
            proportional: true
            vertical: true
            saveProportion: false
        when '/services/youtube/webjets/video'
          return
            enable: true
            proportional: true
            vertical: true
            saveProportion: false
        when '/webjets/content/webjets/graffiti'
          return
            enable: true
            proportional: true
            vertical: true
            saveProportion: false
        when '/webjets/content/webjets/shape'
          return
            enable: true
            proportional: true
            vertical: true
            saveProportion: false
        when '/webjets/content/webjets/note'
          return
            enable: true
            proportional: false
            vertical: false
            saveProportion: false
        when '/webjets/content/webjets/audio'
          return
            enable: true
            proportional: false
            vertical: false
            saveProportion: false
        when '/webjets/content/webjets/link'
          return
            enable: true
            proportional: false
            vertical: false
            saveProportion: false
        when '/webjets/containers/webjets/list'
          return
            enable: true
            proportional: false
            vertical: false
            saveProportion: false
        when '/webjets/content/webjets/embed'
          return
            enable: true
            proportional: true
            vertical: true
            saveProportion: false
        when '/webjets/content/webjets/pdf'
          return
            enable: true
            proportional: true
            vertical: true
            saveProportion: false
        when 'tradingview'
          return
            enable: true
            proportional: true
            vertical: true
            saveProportion: false
        when 'anchor'
          return
            enable: false
            proportional: false
            vertical: false
            saveProportion: false
        when 'btnLink'
          return
            enable: true
            proportional: false
            vertical: false
            saveProportion: false
        when 'checklist'
          return
            enable: true
            proportional: false
            vertical: false
            saveProportion: false
        when 'voice'
          return
            enable: true
            proportional: false
            vertical: false
            saveProportion: false
        when 'date'
          return
            enable: true
            proportional: false
            vertical: false
            saveProportion: false
        when 'mention'
          return
            enable: true
            proportional: false
            vertical: false
            saveProportion: false
        when 'embedcode'
          return
            enable: true
            proportional: false
            vertical: false
            saveProportion: false
        when 'comments'
          return
            enable: true
            proportional: false
            vertical: false
            saveProportion: false
        else
          return
            enable: true
            proportional: false
            vertical: true
            saveProportion: false

  methods:
    # if resize zone return true
    resizeChekIResizeZone: (e) ->
      return false unless FLAG.WEBJET_RESIZER
      return false if @provideWebjet
      x = @provideCanvas.sceneX + e.touches[0].pageX / @provideCanvas.scale
      y = @provideCanvas.sceneY + e.touches[0].pageY / @provideCanvas.scale
      { lineX, lineY } = @resizeFindSide(x,y)
      return true if lineX or lineY
      return false
    resizeTouchStart: (e) ->
      # console.log 'resizeTouchStart'
      return if !@resizablesIsExpanded
      if e.touches?.length == 1
        x = @provideCanvas.sceneX + e.touches[0].pageX / @provideCanvas.scale
        y = @provideCanvas.sceneY + e.touches[0].pageY / @provideCanvas.scale
        { lineX, lineY } = @resizeFindSide(x,y)
        return if !@resizableParams.vertical and !lineX
        if lineX or lineY
          @resizableTmpWidth = @elementRect.x2 - @elementRect.x1
          @resizableTmpHeight = @elementRect.y2 - @elementRect.y1
          @resizableProportion = @resizableTmpWidth / @resizableTmpHeight
          @resizablesTouch = {
            hold: true, active: false, ready: false,
            bx: e.touches[0].pageX, by: e.touches[0].pageY,
            timestamp: new Date().getTime(),
            readyTimeout: setTimeout (=>
              @resizableLineX = lineX
              @resizableLineY = lineY
              @resizableActiveData = {x:lineX, y:lineY}
              @resizablesTouch.ready = true
            ), 500
          }
          # console.log 'resizeTouchStart'
          # e.stopPropagation()
          # e.preventDefault()
          document.addEventListener 'touchmove', @touchmove,{capture: true,passive: false}
          document.addEventListener 'touchend', @touchend, true


    touchmove: (e) ->
      # console.log 'touchmove'
      e.preventDefault()
      e.stopPropagation()
      distance = @touchDistance(e)
      if @resizablesTouch.hold
        if e.touches?.length != 1
          @touchcancel(e)
          return
        if new Date().getTime() - @resizablesTouch.timestamp < 500
          if distance > 20
            @touchcancel(e)
          return

        @resizablesTouch.active = true
        @resizablesTouch.hold = false
        @resizablesTouch.ready = true
      else if @resizablesTouch.active
        # e.preventDefault()
        # e.stopPropagation()
        rect = @provideCanvas.screen
        if distance > 20
          @resizablesTouch.ready = false
        touchX = @provideCanvas.sceneX + (e.touches[0].pageX - rect.x1) / @provideCanvas.scale
        touchY = @provideCanvas.sceneY + (e.touches[0].pageY - rect.y1) / @provideCanvas.scale

        { width, height, resizeX, resizeY } = @getResizeData(e, touchX, touchY)
        # console.log { width, height, resizeX, resizeY }
        @resizeWidth = width if width
        @resizeHeight = height if height
        @resizeX = resizeX if resizeX
        @resizeY = resizeY if resizeY
    touchend: (e) ->
      # console.log 'touchend'
      if @resizablesTouch.hold
        @resizablesTouch = { hold: false, active: false, ready: false, fired: true }
        if e.touches?.length != 1
          @touchcancel(e)
          return
        if new Date().getTime() - @resizablesTouch.timestamp < 500
          return

      else if @resizablesTouch.active
        rect = @provideCanvas.screen
        data = {
            size:
              width: if @connection?.size?.width then @connection.size.width else @baseWidth
            coordinates:
              x: @x
              y: @y
          }
        if @connection?.size?.height
          data.size.height = @connection?.size?.height
        # console.log data,@connection?.size?.width
        touchX = @provideCanvas.sceneX + (e.changedTouches[0].pageX- rect.x1) / @provideCanvas.scale
        touchY = @provideCanvas.sceneY + (e.changedTouches[0].pageY - rect.y1) / @provideCanvas.scale
        { width, height, resizeX, resizeY } = @getResizeData(e, touchX, touchY)
        data.coordinates.x = resizeX if resizeX
        data.coordinates.y = resizeY if resizeY
        data.size.width = width if width
        data.size.height = height if height


        @$store.dispatch 'webjet/updateConnection',
          path: @src
          data: data
        @resizablesTouch = { hold: false, active: false, ready: false, fired: false }
      @touchcancel(e)
    touchcancel: (e)->
      # console.log "!resize touch cancel", @resizablesTouch
      if @resizablesTouch.active
        e.stopPropagation()
        e.preventDefault()
      clearTimeout @resizablesTouch.readyTimeout
      document.removeEventListener 'touchmove', @touchmove,{capture: true,passive: false}
      document.removeEventListener 'touchend', @touchend, true
      @resizableLineX = null
      @resizableLineY = null
      # @resizeTouch = { hold: false, active: false, ready: false, fired: true }

      @resizeX = false
      @resizeY = false
      @resizableTmpHeight = null
      @resizableTmpWidth = null
    touchDistance: (e)->
      distance = Math.sqrt(
        Math.pow(e.touches[0].pageX - @resizablesTouch.bx, 2) + Math.pow(e.touches[0].pageY - @resizablesTouch.by, 2)
      )
      return parseInt(distance, 10)
    resizeFindSide: ( x, y ) ->
      rect = @elementRect
      lineX = null
      lineY = null
      lessHorizontalSide = false
      if @$options.name == 'WebjetCard'
        lessHorizontalSide = @$refs.editor.editmode
      if lessHorizontalSide
        if x < rect.x1
          lineX = 'left'
        if x > rect.x2
          lineX = 'right'
      else
        if x < rect.x1
          lineX = 'left'
        if x > rect.x2
          lineX = 'right'
      if @resizableParams.vertical
        if y < rect.y1 + 15
          lineY = 'top'
        if y > rect.y2 - 15
          lineY = 'bottom'
      { lineX, lineY }

    resizeMouseDown: (e) ->
      # console.log 'resizeMouseDown'
      return if !@resizablesIsExpanded
      return if !@resizableLineX and !@resizableLineY
      return if !@resizableParams.vertical and !@resizableLineX
      return if @resizablesTouch.fired
      if !e.shiftKey and !e.ctrlKey and e.buttons == 1
        e.preventDefault()
        e.stopPropagation()
        @resizeActive = true
        @resizableClickWithoutMove = true
        @resizableActiveData.x = @resizableLineX
        @resizableActiveData.y = @resizableLineY
        @resizableTmpWidth = @elementRect.x2 - @elementRect.x1
        @resizableTmpHeight = @elementRect.y2 - @elementRect.y1
        @resizableProportion = @resizableTmpWidth / @resizableTmpHeight
        document.addEventListener 'mousemove', @mousemove
        document.addEventListener 'mouseup', @mouseup, true

    resizeMouseMove: (e) ->
      # console.log 'resizeMouseMove'
      return if !@resizablesIsExpanded
      scale = @provideCanvas.scale
      x = e.sceneX
      y = e.sceneY
      rect = @elementRect
      { lineX, lineY } = @resizeFindSide(x,y)
      @resizableLineX = lineX
      @resizableLineY = lineY

    resizeMouseLeave: (e) ->
      @resizableLineX = null
      @resizableLineY = null

      # console.log 'leave! '

    getResizeData: (e, mouseX, mouseY) ->
      # console.log 'getResizeData', e
      # mouseX = @provideCanvas.sceneX + e.x / @provideCanvas.scale
      # mouseY = @provideCanvas.sceneY + e.y / @provideCanvas.scale
      if @resizableRound
        mouseX = Math.round(mouseX / 20) * 20
        mouseY = Math.round(mouseY / 20) * 20
      width = null
      height = null
      resizeX = null
      resizeY = null
      baseWidth = @baseWidth
      baseHeight = @baseHeight
      proportion = @resizableProportion


      if @resizableActiveData.x == 'right'
        width = mouseX - @elementRect.x1
      if @resizableActiveData.x == 'left'
        width = ( @x - mouseX) + @resizableTmpWidth
        resizeX = mouseX


      if @resizableActiveData.y == 'bottom'
        height = mouseY - @elementRect.y1
      if @resizableActiveData.y == 'top'
        height = (@y - mouseY) + @resizableTmpHeight
        resizeY = mouseY




      if @resizableParams.proportional

        # tmp width
        if @resizableRound
            withByProportion = Math.round(height * proportion / 20) * 20
        else
          withByProportion = height * proportion

        if @resizableActiveData.x and @resizableActiveData.y == 'bottom'
          resizeY = null
          height = null
        else if @resizableActiveData.x == 'left' and @resizableActiveData.y == 'top'
          diffX = @x - mouseX
          diffY = @y - mouseY
          diff = Math.max diffX , diffY
          # width = withByProportion if withByProportion > width
          resizeX = @x - diff
          resizeY = @y - diff / proportion
          height =null
          width = @resizableTmpWidth + diff
        else if @resizableActiveData.x == 'right' and @resizableActiveData.y == 'top'
          width = withByProportion
          resizeY = mouseY
          height = null
        else if @resizableActiveData.y
          width = withByProportion
          multiplicity = ~~(width/40)
          width = 40 * multiplicity
          width = baseWidth if width < baseWidth
          resizeX = @x + (@resizableTmpWidth - width) / 2
          if @resizableActiveData.y == 'bottom'
            # width = withByProportion
            height= null
          else if @resizableActiveData.y == 'top'
            # width = withByProportion
            # resizeY = mouseY
            resizeY = @y + (@resizableTmpHeight - width / proportion)
            height = null
          @resizableTmpMultiplicity = multiplicity



      # chek min width
      if width != null and width <= baseWidth
        width = baseWidth
        if @resizableActiveData.x == 'left'
          resizeX = (@x + @resizableTmpWidth) - baseWidth

      # chek min height
      if height != null and height <= baseHeight
        height = baseHeight
        if @resizableActiveData.y == 'top'
          resizeY = @elementRect.y2 - baseHeight
      else if @resizableActiveData.y == 'top' and resizeY >= (@y + @resizableTmpHeight) - baseWidth / proportion
        resizeY = (@y + @resizableTmpHeight) - baseWidth / proportion


      unless @resizableParams.vertical
        if @resizableActiveData.x and @resizableActiveData.y
          resizeY = null
        height = null
        resizeY = null

      return { width, height, resizeX, resizeY }

    mousemove: (e)->
      if @resizeActive
        # console.log @getResizeData(e)
        @resizableClickWithoutMove = false

        rect = @provideCanvas.screen
        mouseX = @provideCanvas.sceneX + (e.x - rect.x1) / @provideCanvas.scale
        mouseY = @provideCanvas.sceneY + (e.y - rect.y1) / @provideCanvas.scale
        { width, height, resizeX, resizeY } = @getResizeData(e, mouseX, mouseY)
        @resizeWidth = width if width
        @resizeHeight = height if height
        @resizeX = resizeX if resizeX
        @resizeY = resizeY if resizeY

        if e.which != 1
          @mouseup(e)


      return
    mouseup: (e)->
      document.removeEventListener 'mousemove', @mousemove
      document.removeEventListener 'mouseup', @mouseup, true
      if @resizeActive
        @resizeActive = false

        if !@resizableClickWithoutMove

          e.stopPropagation()

          data = {
              size:
                width: if @connection?.size?.width then @connection.size.width else @baseWidth
              coordinates:
                x: @x
                y: @y
            }
          if @connection?.size?.height
            data.size.height = @connection?.size?.height
          rect = @provideCanvas.screen
          mouseX = @provideCanvas.sceneX + (e.x - rect.x1) / @provideCanvas.scale
          mouseY = @provideCanvas.sceneY + (e.y - rect.y1) / @provideCanvas.scale
          { width, height, resizeX, resizeY } = @getResizeData(e, mouseX, mouseY)
          data.coordinates.x = resizeX if resizeX
          data.coordinates.y = resizeY if resizeY
          data.size.width = width if width
          data.size.height = height if height

          @$store.dispatch 'webjet/updateConnection',
            path: @src
            data: data

          @resizeWidth = false
          @resizeHeight = false

        @resizableActiveData = { x: null, y: null }
        @resizeX = false
        @resizeY = false
        @resizableTmpHeight = null
        @resizableTmpWidth = null
      return
export default resizable
export {resizable}
