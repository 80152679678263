# Google Tag Manager support, helpers, etc

export getQueryParameter = (name) ->
  #decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(window.location.search) || [null, ''])[1].replace(/\+/g, '%20')) || null
  p = new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(window.location.search)
  if p?[1]
    return decodeURIComponent(p[1].replace(/\+/g, '%20'))
  else
    return undefined

export getUTMParameters = -> {
  utm_source: getQueryParameter('utm_source')
  utm_medium: getQueryParameter('utm_medium')
  utm_campaign: getQueryParameter('utm_campaign')
}

export gtmRegistration = (email, uid) ->
  event = Object.assign {
    'event' : 'registration',
    'email' : email,
    'userId' : uid.substring(0, 15)
  }, getUTMParameters()
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push event
