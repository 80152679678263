
























import ModalImgCropper from '@/components/ImgCropper/ModalImgCropper'
import { getOwnerUID } from '@/utils'
export default
  name:
    "CreateBoardImg"
  components:
    {
      ModalImgCropper
     }
  props:
    title: String
    srcFile: String
  data: ->
    openModalCropper: false
    imgFile: null
    boradImg: null
    dragOverImg: false
  computed:
    initials: ->
      return '' unless @title
      result = ''
      words = @title.replace(/[^\p{L}\p{Z}^$\n]/gu, '').split(' ')
      result += words[0][0] if words[0]
      result += words[1][0] if words[1]
      result.toUpperCase()
  methods:
    drop: (e) ->
      e.preventDefault()
      e.stopPropagation()
      @dragOverImg = false
      file = e.dataTransfer?.files?[0]
      imageType = /image.*/
      isImg = file?.type.match imageType
      if file and isImg
        @imgFile = file
        @openModalCrop()
    dragover: (e) ->
      @dragOverImg = true
      e.preventDefault()
    dragleave: (e) ->
      @dragOverImg = false
    openModalCrop: ->
      @openModalCropper = true
    openUpload: ->
      input = @$el.querySelector 'input.boardimg-upload'
      if input
        input.click()
    processCrop: ->
      input = @$el.querySelector 'input.boardimg-upload'
      if input?.files?.length > 0
        @imgFile = input.files[0]
        @openModalCrop()
    uploadCroppedImg: (img) ->
      if (FileReader and img)
        self = this
        fr = new FileReader()
        fr.onload = () ->
          self.imgFile = img
          self.boradImg = fr.result
        fr.readAsDataURL(img)
      else
        @imgFile = null
        @boradImg = null
      # @$store.dispatch 'webjet/updateBoardImg',
      #   path: @connection?.src #@board?.$path
      #   file: img
    deleteCover: ->
      @imgFile = null
      @boradImg = null

  watch:
    openModalCropper: (val)->
      input = @$el.querySelector 'input.boardimg-upload'
      input.value= null
    srcFile: (val) ->
      if val
        src = @srcFile
        urlImg = new URL @srcFile
        lastPath = urlImg.pathname.split('/').slice -1
        name = lastPath[0].split('.').slice -2
        fetch(src).then((res) => res.blob()).then (blob) =>
          file = new File([blob], name)
          @imgFile = file
          @boradImg = src
  mounted: ->
    if @srcFile
      src = @srcFile
      urlImg = new URL @srcFile
      lastPath = urlImg.pathname.split('/').slice -1
      name = lastPath[0].split('.').slice -2
      fetch(src).then((res) => res.blob()).then (blob) =>
        file = new File([blob], name)
        @imgFile = file
        @boradImg = src
