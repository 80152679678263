import scene from './scene' # required

import webjets from './webjets'
import touch from './touch'
import pinch from './pinch'
import wheel from './wheel'
import wasd from './wasd'
import dnd from './dnd'
import paste from './paste'
import hotkey from './hotkey'
import selection from './selection'
import collaborate from './collaborate'
import relations from './relations'
import pluses from './pluses'
import graffiti from './graffiti'
import glide from './glide'
import presentation from './presentation'
import copy from './copy'
import animate from './animate'
import fresh from './fresh'

export default [
  scene
  webjets
  touch
  pinch
  wheel
  wasd
  dnd
  paste
  copy
  hotkey
  selection
  collaborate
  relations
  pluses
  graffiti
  glide
  presentation
  animate
  fresh
]
