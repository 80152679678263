























export default
  name: 'SidebarObject'
  props: ['caption', 'icon']
  methods:
    event: ->
      @$emit 'event'
