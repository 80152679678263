import { operationActions } from '@/store/operation'
import getters from './weje-getters'
import mutations from './weje-mutations'
import operations from './weje-operations'


export default
  namespaced: true
  getters: getters
  mutations: mutations
  actions: operationActions operations
