






















































































import Logo from '@/components/Logo'
import LocaleSwitch from '@/components/LocaleSwitch'
export default
  name: 'Auth'
  data: ->
    checkbox: true
    if IS_DEVELOPMENT
      {
        email: 'gipayih127@yektara.com'
        password: '12345678'
        signInError: null
        resetSuccess: false
      }
    else
      {
        email: null
        password: null
        signInError: null
        resetSuccess: false
      }

  computed:
    queryParameters: -> @$store.state.route.query

  methods:
    submit: ->
      @signInError = await @$store.dispatch 'signIn', {email: @email?.trim(), password: @password}
      true
    submitSignUp: ->
      @signInError = await @$store.dispatch 'signUp', {email: @email?.trim(), password: @password}
      true
    submitResetPassword: ->
      @signInError = await @$store.dispatch 'resetPassword', {email: @email?.trim()}
      if !@signInError
        @resetSuccess = true
    googleAuth: ->
      @$store.dispatch 'googleAuth'

  components: { Logo, LocaleSwitch }
