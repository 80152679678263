if IS_PRODUCTION and !IS_STAGE
  stripeKey = 'pk_live_51HftFnDbibRhxhzYHrX9DYzfIoutYTVpuGwP6k87ckOTigmCUw8KAyACNzUaIDbRF3rxJttOjP7OkrgUOXmkp15500SwnP2lmy'
else
  stripeKey = 'pk_test_51HftFnDbibRhxhzYfEqlVc9uyxPmCZFdjoRjNnj95AcB0YvRnd4m9AGDNHlVexqf9W6WBEDzJulB1hoM3PFpc33m0063RIAV5i'

stripe = null

window.$stripe = ->
  new Promise (rs, rj) ->
    if stripe?
      rs stripe
    else
      d = document
      s = d.createElement 'script'
      s.src = 'https://js.stripe.com/v3/'
      s.async = true
      s.addEventListener 'load', ->
        #console.log '!stripe loaded'
        stripe = Stripe(stripeKey)
        rs stripe
      d.getElementsByTagName('head')[0].appendChild(s)
