



















import { mdiChevronDown, mdiClose, mdiCheck } from '@mdi/js'
import { getOwnerUID,userDisplayName,userCheсkData } from '@/utils'
import Avatar from '@/components/Avatar.vue'
import { sendAnalytics } from '@/utils'
export default
  name:
    "BoardCollaborationsItem"
  inject:
    provideBoardCollaborations: { default: false }
  props:
    boardId: String
    uid: String
    force:
      default: false

  components:
    { Avatar }
  data: ->
    iconArrow: mdiChevronDown
    iconRemove: mdiClose
    iconCheck: mdiCheck
  computed:
    license: ->
      if @force
        @force
      else
        @userDirectAccess

    boardCollaborators: ->
      @$store.getters.boardCollaborators(@boardId)
    access: ->
      @boardCollaborators[@$store.state.uid]?.access
    userRole: ->
      @boardCollaborators[@uid].role
    userAccess: ->
      @boardCollaborators[@uid].access
    userDirectAccess: ->
      @boardCollaborators[@uid].board

    showUser: ->
      userCheсkData @userId

    titles: ->
      view: @$t 'board.share.canView'
      edit: @$t 'board.share.canEdit'
      manage: @$t 'board.share.canManage'
    allowed: ->
      result = []
      return result if @force
      result.push 'view' if @access in ['edit', 'manage']
      result.push 'edit' if @access == 'manage' or (@access == 'edit' and @userRole)
      result.push 'manage' if @access == 'manage' and @userRole
      result
    canEdit: ->
      !@force and @access == 'manage'

    titleLicense: ->
      @titles[@license]
    boardAccess: ->
      @$store.getters.boardAccess @boardId
    userName: ->
      if @userId == @$store.state.uid
        return @$t('common.you')
      else
        userDisplayName @userId
    userEmail: ->
      mail = @userMail?.$value
      if mail
        return mail
    userId: ->
      @uid

  methods:
    changeLicense: (license) ->
      #console.log '!', @uid, license, @license, @provideBoardCollaborations.membersInTeam, @uid not in @provideBoardCollaborations.membersInTeam
      if @uid and license != @license
        if @provideBoardCollaborations.limitIsOver and license == 'edit' and @uid not in @provideBoardCollaborations.membersInTeam
          @provideBoardCollaborations.provideApp.dialogMaxLimitBoardId = @provideBoardCollaborations.src
          @provideBoardCollaborations.provideApp.dialogMaxLimitBoardUserId = @uid
          sendAnalytics 'editors_limit_popup'
          @provideBoardCollaborations.provideApp.dialogMaxLimitBoard = true
          return
        await @$store.dispatch 'webjet/shareBoard',
          src: "/boards/#{@boardId}"
          uid: @uid
          mode: license
    remove: ->
      if @uid
        await @$store.dispatch 'webjet/shareBoard',
          src: "/boards/#{@boardId}"
          uid: @uid
          mode: false

  firebind:
    userMail: ->
      '/users/'+@userId+'/email'
