




















import ContextBtn from './ContextBtn'
import Avatar from '@/components/Avatar.vue'
import { generateSort } from '@/utils'

export default
  name: 'Mention'
  components: { ContextBtn, Avatar }
  priority: 10000
  data: ->
    filter: ''
    choiceSelectNum: null
    menu: false
  if: (selection)->
    FLAG.MENTION_IN_CONTEXT and selection.length > 0 and selection.some (el)-> (el.$options.name != 'WebjetShape' and el.$options.name != 'WebjetBtnLink' and el.$options.name != 'WebjetAnchor' and el.$options.name != 'WebjetPaint')
  computed:
    selection: -> @$store.getters['webjet/selected']
    filtredMembersForChoice: ->
      collaborators = @$store.getters['collaborators']

      filtredItems = collaborators.filter (c)=>
        firstName = c.fname
        lastName = c.lname
        email = c.email

        return true if firstName and firstName.toLowerCase().includes @filter.toLowerCase()
        return true if lastName and lastName.toLowerCase().includes @filter.toLowerCase()
        return true if email and email.toLowerCase().includes @filter.toLowerCase()

      if !@filter.length
        return collaborators.filter (c) -> c.fname and c.lname and c.email

      filtredItems
  methods:
    action: (mentioned) ->
      @selection.forEach (s) =>
        return if s.$options.name == 'WebjetShape'
        return if s.$options.name == 'WebjetBtnLink'
        return if s.$options.name == 'WebjetAnchor'
        return if s.$options.name == 'WebjetPaint'
        @createMention s, mentioned

    keydown: (e) ->
      if e.keyCode == 38
        @upArrow()
      else if e.keyCode == 40
        @downArrow()
      else if e.keyCode == 13
        if @choiceSelectNum or @choiceSelectNum == 0
          @action @filtredMembersForChoice[@choiceSelectNum]
        else
          member = @filtredMembersForChoice.find (el) => el.email == @filter
          @action member
        @menu = false

    downArrow: (e) ->
      if @filtredMembersForChoice.length
        if @choiceSelectNum == null
          @choiceSelectNum = 0
        else if @choiceSelectNum < @filtredMembersForChoice.length - 1
          @choiceSelectNum += 1
        else if @choiceSelectNum >= @filtredMembersForChoice.length - 1
          @choiceSelectNum = @filtredMembersForChoice.length - 1
        return false

    upArrow: (e) ->
      if @filtredMembersForChoice.length
        if @choiceSelectNum != 0
          @choiceSelectNum -= 1
        else if @choiceSelectNum == 0
          @choiceSelectNum = 0

    createMention: (webjet, member) ->
      provideWebjet = webjet.provideWebjet
      hasCanvas = webjet?.provideCanvas
      inInventory = @provideBoardSrc == 'inventory'
      provideWebjetIsInventory = provideWebjet?.$options?.name == 'WebjetInventory' if provideWebjet
      boardPath = if inInventory then 'inventory' else hasCanvas.data?.$path
      dest = webjet.webjet?.$path
      if provideWebjet and !provideWebjetIsInventory and provideWebjet?.webjet?.category != '/webjets/containers/webjets/list'
        dest = provideWebjet?.webjet?.$path
        sort = generateSort(provideWebjet.webjet, false)
      else if !provideWebjet and !provideWebjetIsInventory and provideWebjet?.webjet?.category != '/webjets/containers/webjets/list'
        sort = generateSort(webjet.webjet, false)
      if @provideCanvas
        connection =
          {
          coordinates: @provideCanvas.getEmptyCoordinates
            x: @provideCanvas.sceneX + @provideCanvas.sceneWidth / 2 - 150
            y: @provideCanvas.sceneY + @provideCanvas.sceneHeight / 2 - 50
          }
      else
        connection = {}
      connection = Object.assign(connection, sort) if sort

      if member
        mentioned =
          uid: member.uid
      else
        mentioned = null

      result = await @$store.dispatch 'webjet/create',
        category: 'mention'
        dest: dest
        webjet:
          data:
            mentioned: mentioned
            referrer: @$store.state.uid
            active: true
        boardPath: boardPath
        connection: connection
  watch:
    menu: (v) ->
      if v
        @$nextTick =>
          #TODO: find properly solution without timeout

          setTimeout (=>
            @$refs.filter.focus()
          ), 100
