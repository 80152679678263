









import { usualMixinPack } from './mixins'

export default
  name: 'WebjetCard'
  mixins: [...usualMixinPack]

