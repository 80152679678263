















import { movingAverage, calcQLintersects, iters } from '@/utils'
import fitCurve from 'fit-curve'
import {quadBezierLine} from 'bezier-intersect'

import PathLine from './Path'
import SceneShadowPaint from './SceneShadowPaint'


export default
  name: 'ScenePaints'
  provide: ->
    provideScenePaints: this
  inject: ['provideCanvas']
  components: { PathLine, SceneShadowPaint }
  data: ->
    selectedPaints: []
    showPalette: false
    points: []
    averegeLimit: 5
    point:
      x: null
      y: null
    curvePoints: []
    curve: ''
    width: 20
    path: ''
    eraserTimer: null
    completCurves: []
    tmpPoints: []
    intersectPoints: []
    draw: false
    userConfig:
      pen:
        1:
          color: '#555555'
          width: 4
        2:
          color: '#ff0000'
          width: 10
        3:
          color: '#ffcc00'
          width: 20
      marker:
        1:
          color: '#ffa1a1'
          width: 20
        2:
          color: '#97fa74'
          width: 20
        3:
          color: '#98a2ff'
          width: 20
      lastic:
        0: null
      empty:
        0: null
    showAllPaint: true
    selectedUserType: 'pen'
    subSelectedUserType:
      pen: 1
      marker: 1
      lastic: 0
      empty: 0
    drawIsActive: false
    drawToolsHelper:
      points: []
      pointsFiltred: []
      filterPoints: true
      pointsFiltredRoundOnScale: []
      filterRoundOnScale: true
      relativePoints: []
      filterRelativePoints: true
      flatPoints: []
      filteredFlat: true
      showPoints: false
      showSpace: false

  computed:
    paints: -> @provideCanvas.paintWebjets
    attach: -> !@provideCanvas.showMinimap
    boardPath: -> @provideCanvas.data.$path
    userCanEdit: -> @provideCanvas.userCanEdit
    keymap: ->
      'esc': @escHotKey
      'p': @pHotKey
      'h': @hHotKey
      'e': @eHotKey
    activePathConfig: ->
      type = @subSelectedUserType[@selectedUserType]
      return @userConfig[@selectedUserType][type]
    pointData: ->
      return if @selectedUserType == 'lastic' or @selectedUserType == 'empty'
      left = 0
      top = 0
      width = @activePathConfig.width
      color = @activePathConfig.color
      return unless @point.x and @point.y
      if @point.x and @point.y
        left = @point.x
        top = @point.y

      return
        transform:  "translate(#{left}px, #{top}px)"
        margin: "-#{width/2}px"
        width: "#{width}px"
        height: "#{width}px"
        border: "2px solid #{color}"
        background: "#{color}" if @selectedUserType == 'pen'
    eraserPath: ->
      return false unless @selectedUserType == 'lastic'
      return false unless @tmpPoints.length
      path = ''
      for p,index in @tmpPoints
        if index == 0
          path += "M#{p[0]} #{p[1]} "
        else
          path += "L#{p[0]} #{p[1]} "
      return path
    collaborateDrawMode: -> @provideCanvas.collaborateDrawMode
  methods:
    escHotKey: ->
      @drawIsActive = false if @drawIsActive
    pHotKey: -> @hotkeyActivatePaint 'pen'

    hHotKey: -> @hotkeyActivatePaint 'marker'

    eHotKey: -> @hotkeyActivatePaint 'lastic'

    hotkeyActivatePaint: (type) ->
      @drawIsActive = true unless @drawIsActive
      @showAllPaint = true unless @showAllPaint
      @selectedUserType = type

    deletePaint: (paint, all = false) ->
      if all
        items = []
        for k,v of @paints
          items.push { path: v.$path }
      else
        items = [{ path: paint.$path }]
      @$store.dispatch 'webjet/trash', { items }
    mouseOverLine: (e,paint) ->
      return unless @draw
      return unless e.buttons == 1
      return unless @selectedUserType == 'lastic'
      @deletePaint paint
    pointsForDots: (p,isRelative = true) ->
      points = p.data.points
      coordinates = p.coordinates
      result = []
      # console.log isRelative
      i = 0
      while i < points.length
        result.push [points[i], points[i+1]]
        i += 2
      if isRelative
        tmpResult = []
        for p,index in result
          if index == 0
            tmpResult.push [p[0] + coordinates.x, p[1] + coordinates.y]
          else
            prevPoint = tmpResult[index - 1]
            tmpResult.push [p[0] + prevPoint[0], p[1] + prevPoint[1]]
        result = tmpResult

      result
    filterPoints: (points) ->
       # фильтрация по углу (прямой выкидываем)
      if @drawToolsHelper.filterPoints
        l = @tmpPoints.length
        filtredPoints = []

        filtredPoints.push @tmpPoints[0]
        i = 1
        while i < l-1
          tp1 = @tmpPoints[i-1]
          tp2 = @tmpPoints[i]
          tp3 = @tmpPoints[i+1]
          AB = Math.sqrt(Math.pow(tp2[0]-tp1[0],2)+ Math.pow(tp2[1]-tp1[1],2))
          BC = Math.sqrt(Math.pow(tp2[0]-tp3[0],2)+ Math.pow(tp2[1]-tp3[1],2))
          AC = Math.sqrt(Math.pow(tp3[0]-tp1[0],2)+ Math.pow(tp3[1]-tp1[1],2))
          angle = Math.acos((BC*BC+AB*AB-AC*AC)/(2*BC*AB)) * (180/Math.PI)
          unless angle == NaN or angle>179
            filtredPoints.push @tmpPoints[i]
          i++

        filtredPoints.push @tmpPoints[l-1] if @tmpPoints[l-1]
        filtredPoints.push @tmpPoints[l-1] if @tmpPoints[l-1]
        @drawToolsHelper.pointsFiltred = filtredPoints
        points = filtredPoints
      else
        @drawToolsHelper.pointsFiltred = []


      # filtering numbers depending on scale
      if @drawToolsHelper.filterRoundOnScale
        if @provideCanvas.scale < 1
          filtredPoints = points.map (p) ->
            p = [Math.round(p[0]), Math.round(p[1])]
        else

          filtredPoints = points.map (p) ->

            p = [+p[0].toFixed(1), +p[1].toFixed(1)]

        # срабатывает если scale меньше 1
        @drawToolsHelper.pointsFiltredRoundOnScale = filtredPoints
        points = filtredPoints
      else
        @drawToolsHelper.pointsFiltredRoundOnScale = []


      # filtering numbers from absolute to relative
      if @drawToolsHelper.filterRelativePoints
        filtredPoints = []
        for p,index in points
          if index == 0
            filtredPoints.push p
          else
            prevPoint = points[index - 1]
            point = [p[0] - prevPoint[0], p[1] - prevPoint[1]]
            if @provideCanvas.scale >= 1
              point = [+point[0].toFixed(1), +point[1].toFixed(1)]
            filtredPoints.push point
        @drawToolsHelper.relativePoints = filtredPoints
        points = filtredPoints
      else
        @drawToolsHelper.relativePoints = []


      filtredPoints = points.flat()
      points = filtredPoints
      @drawToolsHelper.flatPoints = filtredPoints



      points
    mouseMove: (e) ->
      return unless @showAllPaint
      # console.log '1222211 s', @provideCanvas.scene.x1, @provideCanvas.x - @provideCanvas.animate.x

      x = e.canvasX / @provideCanvas.scale + @provideCanvas.scene.x1
      y = e.canvasY / @provideCanvas.scale + @provideCanvas.scene.y1


      # get point on scene when animation is active
      if @provideCanvas.animation.active
        animSX = - (@provideCanvas.position.x + @provideCanvas.animate.x) / @provideCanvas.animate.scale
        animSY = - (@provideCanvas.position.y + @provideCanvas.animate.y) / @provideCanvas.animate.scale
        x = e.canvasX / @provideCanvas.animate.scale + animSX
        y = e.canvasY / @provideCanvas.animate.scale + animSY

      @points.unshift [x, y]
      # @averegeLimit = +1

      if @points.length > @averegeLimit
        @points = @points.splice 0, @averegeLimit
      # console.log @averegeLimit, @points.length
      point = movingAverage @points, @averegeLimit - 1
      if point[0]
        @point.x = point[0][0]
        @point.y = point[0][1]
      if @draw and @selectedUserType != 'lastic'
        if point.length

          @tmpPoints.push point[0]
          @curvePoints.push point[0]

          if @curvePoints.length > 20
            @curvePoints = @curvePoints.slice -10



      if @draw and @selectedUserType == 'lastic'

        # setTimeout (=> @tmpPoints.shift()), 500
        @tmpPoints.push point[0]

        if @tmpPoints.length > 20
            @tmpPoints.shift()

        if @points.length > 1

          for key,val of @paints

            pfi = @$refs[key][0].pointsForIntersection

            i = 0
            while i < pfi.length - 2

              pl1 = {x:@points[0][0],y:@points[0][1]}
              pl2 = {x:@points[1][0],y:@points[1][1]}

              res=[]
              inter = quadBezierLine(pfi[i].x, pfi[i].y, pfi[i+1].x,pfi[i+1].y, pfi[i+2].x,pfi[i+2].y,pl1.x,pl1.y,pl2.x,pl2.y,res)

              if inter
                @deletePaint val

              i += 2

    mouseUp: (e) ->
      return unless @userCanEdit
      return unless @draw
      return unless e.which == 1
      return unless @showAllPaint

      if @selectedUserType == 'lastic'
        @draw = false
        @tmpPoints = []
        return
      x = e.canvasX / @provideCanvas.scale + @provideCanvas.scene.x1
      y = e.canvasY / @provideCanvas.scale + @provideCanvas.scene.y1
      if @tmpPoints.length < 3
        @tmpPoints = [[x,y]]
      # точки для хедпера
      @drawToolsHelper.points = @tmpPoints
      @tmpPoints = @filterPoints(@tmpPoints,e)

      esr = @$refs.drawLine.getElementSceneRect()

      @tmpPoints[0] = @tmpPoints[0] - esr.x1
      @tmpPoints[1] = @tmpPoints[1] - esr.y1

      # console.log '@tmpPoints', @tmpPoints

      if @selectedUserType != 'lastic'
        result = await @$store.dispatch 'webjet/paint/create',
          dest: @boardPath
          data:
            coordinates:
              x: esr.x1
              y: esr.y1
            data:
              type: @selectedUserType
              points: @tmpPoints
              width: @activePathConfig.width
              color: @activePathConfig.color
              relative: @drawToolsHelper.filterRelativePoints


      @path = []
      @points = []
      @curvePoints = []
      @curve = ''
      @draw = false
      @tmpPoints = []

    mouseDown: (e) ->
      return unless @showAllPaint
      return if @selectedUserType == 'empty'
      zoneForDraw = null
      cPath = e.composedPath()
      # console.log cPath
      for node in cPath
        break if zoneForDraw
        if node?.classList?.contains('scene') or node?.classList?.contains('paste-layer') or node?.id == 'drag-please-drag'
          zoneForDraw = node
      if zoneForDraw
        @showPalette = false
        if e.buttons == 1
          @draw = true
          @tmpPoints.push [@point.x,@point.y]
          e.preventDefault()
          e.stopPropagation()
    touchStart: (e) ->
      # console.log 'touchStart'
      return unless @userCanEdit
      return unless @showAllPaint
      return if @selectedUserType == 'empty'
      zoneForDraw = null
      cPath = e.composedPath()
      for node in cPath
        break if zoneForDraw
        if node?.classList?.contains('scene') or node?.classList?.contains('paste-layer')
          zoneForDraw = node
      if zoneForDraw
        @showPalette = false
        if e.touches?.length == 1
          @draw = true
          x = e.canvasX / @provideCanvas.scale + @provideCanvas.scene.x1
          y = e.canvasY / @provideCanvas.scale + @provideCanvas.scene.y1
          p = [x,y]
          @tmpPoints = []
          @tmpPoints.push p
          @point.x = x
          @point.y = y

          @points = []
          e.stopPropagation()
    touchMove: (e) ->
      # console.log 'touchMove'
      return unless @draw
      x = e.canvasX / @provideCanvas.scale + @provideCanvas.scene.x1
      y = e.canvasY / @provideCanvas.scale + @provideCanvas.scene.y1
      @points.unshift [x, y]
      if @points.length > @averegeLimit
        @points = @points.splice 0, @averegeLimit

      point = movingAverage @points, @averegeLimit - 1

      if point[0]

        @point.x = point[0][0]
        @point.y = point[0][1]

      if @selectedUserType != 'lastic' and point.length
        @tmpPoints.push point[0]
        @curvePoints.push point[0]
        if @curvePoints.length > 20
          @curvePoints = @curvePoints.slice -10
      if @selectedUserType == 'lastic' and @points.length > 1
        if point[0]
          @tmpPoints.push point[0]
          if @tmpPoints.length > 20
              @tmpPoints.shift()

        el = document.elementFromPoint(e.changedTouches[0].clientX, e.changedTouches[0].clientY)
        elForDelete = null
        if el.classList.contains('webjet') and el.nodeName == 'path'
          elForDelete = el

        for key,val of @paints

          if elForDelete and @$refs[key][0].$el == elForDelete
            @deletePaint val

          pfi = @$refs[key][0].pointsForIntersection


          i = 0
          while i < pfi.length - 2

            pl1 = {x:@points[0][0],y:@points[0][1]}
            pl2 = {x:@points[1][0],y:@points[1][1]}

            res=[]
            inter = quadBezierLine(pfi[i].x, pfi[i].y, pfi[i+1].x,pfi[i+1].y, pfi[i+2].x,pfi[i+2].y,pl1.x,pl1.y,pl2.x,pl2.y,res)

            if inter
              @deletePaint val

            i += 2
      e.stopPropagation()
      e.preventDefault()
    touchEnd: (e) ->
      # console.log 'touchEnd'
      return unless @draw
      return unless @showAllPaint
      if @selectedUserType == 'lastic'
        @draw = false
        @tmpPoints = []
        return
      e.stopPropagation()
      e.preventDefault()
      x = e.canvasX / @provideCanvas.scale + @provideCanvas.scene.x1
      y = e.canvasY / @provideCanvas.scale + @provideCanvas.scene.y1

      # if @tmpPoints.length < 3
      #   @tmpPoints = [[x,y]]

      @tmpPoints = @filterPoints(@tmpPoints)

      esr = @$refs.drawLine.getElementSceneRect()

      @tmpPoints[0] = @tmpPoints[0] - esr.x1
      @tmpPoints[1] = @tmpPoints[1] - esr.y1


      if @selectedUserType != 'lastic'
        result = await @$store.dispatch 'webjet/paint/create',
          dest: @boardPath
          data:
            coordinates:
              x: esr.x1
              y: esr.y1
            data:
              type: @selectedUserType
              points: @tmpPoints
              width: @activePathConfig.width
              color: @activePathConfig.color
              relative: @drawToolsHelper.filterRelativePoints

      @path = []
      @points = []
      @curvePoints = []
      @curve = ''
      @draw = false
      @point = {x:null,y:null}
      @tmpPoints = []

    eraserTimerShift: ->
      if @tmpPoints.length
        @tmpPoints.shift()
      @eraserTimer = setTimeout (=> @eraserTimerShift()), 20

    eraserTimerOn: ->
      unless @eraserTimer
        @eraserTimer = setTimeout (=> @eraserTimerShift()), 20
    eraserTimerOff: ->
      clearTimeout(@eraserTimer)
      @eraserTimer = null
  mounted: ->
    if localStorage.userScenePaintConfig
      try
        @userConfig = Object.assign @userConfig, JSON.parse localStorage.userScenePaintConfig
      catch err
        localStorage.removeItem 'userScenePaintConfig'
  watch:
    'provideCanvas.isReadOnly' : (val) ->
      @drawIsActive = false if val
    selectedUserType: (val,oldVal) ->
      if val != 'empty' and oldVal == 'empty'
        @$store.dispatch 'webjet/resetSelection'
        @$store.dispatch 'webjet/relation/setSelected', { webjet: null  }
      @provideCanvas.drawPaintIsActive = val
      if val == 'lastic' and val != oldVal
        @eraserTimerOn()
      else if oldVal == 'lastic'
        @eraserTimerOff()
    'provideCanvas.collaborateDrawMode': (val) ->
      if val
        @drawIsActive = false
    'provideCanvas.selected': (val) ->
      if val.length and @drawIsActive
        @drawIsActive = false
    userConfig:
      deep: true
      handler: (val) ->
        localStorage.userScenePaintConfig = JSON.stringify val
    drawIsActive: (val) ->
      if val
        if @selectedUserType != 'empty'
          @$store.dispatch 'webjet/resetSelection'
          @$store.dispatch 'webjet/relation/setSelected', { webjet: null  }
        # console.log @selectedUserType
        # @selectedUserType = 'pen'
        @provideCanvas.drawPaintIsActive = @selectedUserType
        @provideCanvas.collaborateDrawMode = false
        document.addEventListener 'mousemove', @mouseMove, {capture: true, passive: false}
        document.addEventListener 'mouseup', @mouseUp, {capture: true, passive: false}
        document.addEventListener 'mousedown', @mouseDown, true

        document.addEventListener 'touchstart', @touchStart, true
        document.addEventListener 'touchmove', @touchMove, {capture: true, passive: false}
        document.addEventListener 'touchcancel', @touchEnd, true
        document.addEventListener 'touchend', @touchEnd, true
      else
        @provideCanvas.drawPaintIsActive = false
        document.removeEventListener 'mousemove', @mouseMove, {capture: true, passive: false}
        document.removeEventListener 'mouseup', @mouseUp, {capture: true, passive: false}
        document.removeEventListener 'mousedown', @mouseDown, true

        document.removeEventListener 'touchstart', @touchStart, true
        document.removeEventListener 'touchmove', @touchMove, {capture: true, passive: false}
        document.removeEventListener 'touchcancel', @touchEnd, true
        document.removeEventListener 'touchend', @touchEnd, true

  # created: ->
  #   window.addEventListener 'mousemove', @mouseMove, {capture: true, passive: false}
  #   window.addEventListener 'mouseup', @mouseUp, {capture: true, passive: false}
  beforeDestroy: ->
    @drawIsActive = false
    @provideCanvas.drawPaintIsActive = false
    document.removeEventListener 'mousemove', @mouseMove, {capture: true, passive: false}
    document.removeEventListener 'mouseup', @mouseUp, {capture: true, passive: false}
