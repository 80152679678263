






































import Scene from './scene/Scene.vue'
import PasteLayer from './PasteLayer'
import Grid from './Grid'
import ContextMenu from './ContextMenu'
import FlyContextMenu from './FlyContextMenu'
import Control from './Control'
import Tools from './Tools'
import ToolsMobile from './ToolsMobile'
import PlusTools from './PlusTools'
import RelationPlusTools from './RelationPlusTools'
import Caution from './Caution'
import GraffitiTools from './GraffitiTols'
import RelationTools from './RelationTools'
import RelationArrowTools from './RelationArrowTools'
import ModalWindow from '@/components/ModalWindow'
import DropZoneCancel from './DropZoneCancel'
import Presentation from './Presentation'
import HelpButton from '@/components/HelpButton'
import DrawOnCanvasTools from './DrawOnCanvasTools'
import BoardHeaderMobile from './BoardHeaderMobile'
import UndoRedo from './UndoRedo'
import Chat from './Chat'
import DrawTool from './DrawTool'
import BoardUsers from './BoardUsers'
import Freshbar from './Freshbar'
import Gizmos from './Gizmos'
import Sidebar from './sidebar/Sidebar'
import SidebarLeft from './sidebarLeft/SidebarLeft'
import SidebarContent from './sidebarContent/SidebarContent'

import DialogSiteShare from '@/canvas/DialogSiteShare'

import mixins from './mixins'

import { genFromMixins, getOwnerUID, getRef } from '@/utils'
import { wsBoard } from '@/websockets'


export default
  name: 'Canvas'
  mixins: [ ...mixins ]
  components: { Scene, Grid, ContextMenu, FlyContextMenu, Control, Tools, ToolsMobile, Chat, DrawTool,
    BoardUsers, PlusTools, RelationPlusTools, Caution, GraffitiTools, RelationTools,
    RelationArrowTools, Sidebar, ModalWindow, DropZoneCancel, PasteLayer, Presentation,
    SidebarLeft, SidebarContent, HelpButton, DrawOnCanvasTools, BoardHeaderMobile,
    UndoRedo, DialogSiteShare, Freshbar, Gizmos }
  props:
    src: String
  provide: ->
    provideCanvas: this
    provideBoardSrc: @dataSrc.$value
  inject: ['provideApp']
  context:
    canvas: -> this
    positionable: true
  data: ->
    activeEditor: null # currently active WEditor component is stored here, null if none
    caution: false
    webjetAnchorChords: null
    webjetAnchorSrc: null
    anchorHaveData: false
    lastShapeType: 'star'
    presentationLoader: false
    drawPaintIsActive: false
    initDate: Date.now() # add firebase offset, when connected
    screen: { width: @$vuetify.breakpoint.width, height: @$vuetify.breakpoint.height, x1: 0, y1: 0, x2: 0, y2: 0 }
    dialogSiteShareSrc: false

  computed:
    classes: ->
      return
        'user-can-edit': @userCanEdit
        'active-draw': @activeDraw
        'active-draw-on-canvas': @drawPaintIsActive
        'safari_bug': FLAG.SAFARI_BUG
        'grabbing': @touch.active
        'dragenter': @dndCounter>0
        'small-screen': @smallScreen
        'scene-center': FLAG.SCENE_CENTER && @showGizmo
    isTrashEmpty: ->
      if @connections?.trash
        for k, v of @connections.trash
          return false if !v.trashClear
      if @connections?.trashSites
        for k, v of @connections.trashSites
          return false if !v.trashClear
      true
    hasWejeSites: ->
      @connections?.wejes
    route: ->
      return this.$route
    ifMinimap: ->
      return false unless FLAG.SCENE_MINIMAP
      return true if @ifMinimapWas
      if @showMinimap
        @ifMinimapWas = true
        true
      else
        false
    showMinimap: ->
      return false unless FLAG.SCENE_MINIMAP
      @scale < 0.1

    boardOwner: ->
      getOwnerUID @dataSrc?.$value

    boardAccess: ->
      @$store.getters.boardAccess @src
    isReadOnly: ->
      !@$store.state.connected or !@userCanEdit
    userCanEdit: ->
      if FLAG.NEW_LOAD
        return false if @presentationIsActive
        return false if @boardAccess.deleted
        @boardAccess.access in ['manage', 'edit']
      else
        return false if @presentationIsActive
        return false unless @shared
        return true if @boardOwner == @$store.state.uid
        return true if @shared?['*'] == 'edit'
        return true if @shared?[@$store.state.uid] in [ 'edit', 'manage', 'owner' ]
        false

    userCanManage: ->
      if FLAG.NEW_LOAD
        @boardAccess.access == 'manage'
      else
        false
    plusPoints: ->
      return false unless @selected.length == 1
      @selected[0].plusPoints
    plusIsActive: ->
      if FLAG.GRAFFITI_TOOLS_TO_CONTEXT_MENU
        @selected.length == 1 and !@activeEditor and !@isReadOnly and !@selected[0].dragInProgress and
        !@touch.active and !@animation.active and @selected[0].isExpanded and !@relationIsActive and
        !@relationPlusToolsIsActive and !@selected[0].$refs.editor?.editMode and @selected[0].provideScene
      else
        @selected.length == 1 and !@activeEditor and !@isReadOnly and !@selected[0].dragInProgress and
        !@touch.active and !@animation.active and @selected[0].isExpanded and !@relationIsActive and
        !@relationPlusToolsIsActive and !@activeGraffitiEdit and @selected[0].provideScene
    plusToolsIsActive: ->
      !@isReadOnly and !@touch.active and !@animation.active and !@selection.active and @selected.length == 1 and
      !@selected[0].dragInProgress and @plusPoint and !@relationIsActive and !@activeEditor and !@activeGraffitiEdit
    relationPlusToolsIsActive: ->
      @relationPlusPoint.active and !@isReadOnly and !@touch.active and !@animation.active and !@activeEditor and !@relationIsActive and !@activeGraffitiEdit
    showContextMenu: ->
      return !@touch.active and !@animation.active and !@selection.active and !@activeGraffitiEdit and
      @selected.length > 0 and !@isReadOnly and !@plusToolsIsActive and !@relationIsActive and !@activeEditor and !@relationPlusToolsIsActive
    showGraffitiTools: ->
      if @selected.length == 1 and !@isReadOnly and @activeGraffitiEdit and !@animation.active and
      !@touch.active and !@selection.active and @selected[0]?.webjet?.category == '/webjets/content/webjets/graffiti'
        return true
    activeContextRelations: ->
      @selected.length == 1 and !@isReadOnly and !@activeEditor and !@selection.active and
      !@selected[0].dragInProgress and !@plusToolsIsActive and !@activeGraffitiEdit and @selected[0].provideScene
    showContextRelations: ->
      !@touch.active and !@animation.active
    contextRelationsWebjet: ->
      @selected[0].src
    showRelationTools: ->
      if @selectedRelation and @selectedRelation.src and !@isReadOnly and !@touch.active and !@animation.active and
      !@selectedRelation.drag.point and !@showRelationArrowTools
        return true
    showRelationArrowTools: ->
      if @selectedRelation and @arrowToolsPoint and !@isReadOnly and !@touch.active and !@animation.active and
      !@selectedRelation.drag.point
        return true
    showPresentation: ->
      return true if @presentationIsActive
    genListeners: ->
      gens = genFromMixins this, 'listeners'
      result = {}
      for gen in gens
        for k, v of gen
          result[k] = [] unless result[k]
          result[k].push v
      result
    showGrid: ->
      return false if @presentationIsActive
      @$store.getters['user/grid']

    showGizmo: ->
      return false if @presentationIsActive
      @$store.getters['user/gizmo']

    chat: -> wsBoard.get 'chat'
    activeDraw: ->
      @collaborateDrawMode

    anchorWebjet: ->
      if @webjetAnchorSrc
        @getWebjetComponentBySrc(@webjetAnchorSrc)
      else
        false

    showDrawOnCanvas: ->
      FLAG.DRAW_ON_CANVAS and @userCanEdit
    smallScreen: ->
      @$vuetify.breakpoint.width < 500

    lastUpdateDate: ->
      @meta?.updateDate

    boardId: ->
      @dataSrc?.$value?.split('/').pop()

    shared: ->
      return unless @teamAccess?.$ready
      u = {}
      for uid, mode of @legacyShared when uid!=@boardOwner
        u[uid] = if mode=='rw' then 'edit' else 'view'
      for uid, boards of @teamAccess.boards when uid!=@boardOwner
        if boards[@boardId]?
          u[uid] = boards[@boardId]
      for uid, role of @teamAccess.roles when uid!=@boardOwner
        u[uid] = role
      u

    connection: ->
      if @src.includes('/')
        @firebind @src
      else
        { src: "/boards/#{@src}" }

    dataSrc: ->
      if @src.includes('/')
        @firebind "#{@src}/src"
      else
        { $value: "/boards/#{@src}" }

    teamAccess: ->
      if @dataSrc?.$value
        dataTeam = @firebind "#{@dataSrc.$value}/team"
        if dataTeam.$ready
          @firebind "/teamAccess/#{dataTeam.$value}"

  watch:
    plusToolsIsActive: (val) ->
      @plusPoint = null unless val
    anchorWebjet: (val) ->
      if val
        @achorSetChords(val)
    webjetAnchorChords: (val) ->
      # console.log 'webjetAnchorChords', val, oldVal
      if val
        # offset screen width if sidebar is open
        sidebarWidth = 0
        if @$refs.sidebar
          if @$refs.sidebar.mode == 'full'
            if @$vuetify.breakpoint.width < 800
              @$refs.sidebar.close()
            else
              contentComponent = @$refs?.sidebar?.$refs?.content
              if contentComponent
                sidebarWidth = @$refs.sidebar.$refs.content.$el.getBoundingClientRect().width
              else
                sidebarWidth = document.getElementsByClassName("sidebar-content")[0].getBoundingClientRect().width


        # offset screen width if left sidebar is open
        sidebarLeftWidth = 0
        if @$refs.sidebarContent
          mode = @$refs.sidebarContent.mode
          if mode == 'full'
            if @$vuetify.breakpoint.width < 800
              @$refs.sidebarContent.openClose()
            else
              sidebarLeftRect = @$refs.sidebarContent.$el.getBoundingClientRect()
              sidebarLeftWidth = sidebarLeftRect.width

        width = val.rect.x2 - val.rect.x1
        height = val.rect.y2 - val.rect.y1

        # no offset if presentationis active

        if @presentationIsActive
          sWidth = @screen.width
          sHeight = @screen.height
        else
          sWidth = @screen.width - sidebarWidth - sidebarLeftWidth - 140
          sHeight = @screen.height - 160
        # for bookmark set new chords widthout font scale
        # use height propotion to find out default chords
        if @anchorWebjet.webjet?.category == 'anchor' and @anchorWebjet.webjet.data?.type == 'bookmark'
          defaultHeight = 26
          propotion = defaultHeight / height
          propWidth = width * propotion
          val.x = val.rect.x1 + (propWidth / 2)
          val.y = val.rect.y1 + (defaultHeight / 2)


        i = 0
        # scale from 300% when move to webjet anchor
        # if type iframe
        i = 10 if @anchorWebjet.webjet.category == 'anchor' and @anchorWebjet.webjet.data?.type != 'bookmark'

        log = (a,b) ->
          return Math.log(a) / Math.log(b)


        if @presentationIsActive
          if @anchorWebjet.webjet.category == 'anchor' and @anchorWebjet.webjet.data?.type == 'bookmark'
             width = 1000
             height = 1000
          ix = log((sWidth / width), 1.2)
          iy = log((sHeight / height), 1.2)

          i = if ix <= iy then ix else iy
        else
          while (width * Math.pow(1.2, i)) > sWidth or (height * Math.pow(1.2, i)) > sHeight
            i -= 1
        @withoutAnimation =>
          @zoomToLevel i
          # shift move center if sidebar is open
          # sidebar divided by scale
          @sceneMoveCenterTo(val.x - sidebarLeftWidth / Math.pow(1.2, i) / 2 , val.y)
        if @anchorHaveData
          @anchorHaveData = false
          @webjetAnchorSrc = null


    route: (val) ->
      if val?.query.p and val?.query.p == '1'
        @presentationInit()
      else if @presentationIsActive
        @presentationCancel()
      if val?.query.w
        @anchorInit val?.query.w
  firebind:
    #connection: -> @src
    #dataSrc: -> @src and "#{@src}/src"
    data: -> @dataSrc?.$value
    legacyShared: -> @dataSrc?.$value and "#{@dataSrc.$value}/shared"
    #teamAccess: -> @dataSrc?.$value and @data.team? and "/teamAccess/#{@data.team}"
    connections: -> @dataSrc?.$value and "#{@dataSrc.$value}/connections"
    paints: -> @dataSrc?.$value and "#{@dataSrc.$value}/paints"
    meta: -> @dataSrc?.$value and "#{@dataSrc.$value}/meta"
  methods:
    showCaution: (caution)->
      @caution = caution
    goToWebjet: (src, replace = false) ->
      query = Object.assign {}, @$route.query

      if query.w != src
        query.w = src
        path = @$route.path

        if replace
          @$router.replace path: path, query: query
          return
        @$router.push path: path, query: query
    anchorInit: (src) ->
      wSrc = '/boardsData/' + src
      @webjetAnchorSrc = wSrc
      dataFromFb = await @firebind wSrc

      if dataFromFb.$value == null
        wSrc = '/data/' + src
        @webjetAnchorSrc = wSrc
        dataFromFb = await @firebind wSrc

      if dataFromFb.$value == null

        if @presentationIsActive
          @goToFirstWbjetInPresentation()
        else
          @achorSetChords(null,true)
      else
        w = @webjetComponentsBySrc[wSrc]
        if @presentationIsActive
          wInAnchors = false
          for c in @anchors
            if w.src == c.$path
              wInAnchors = true
              break
          @goToFirstWbjetInPresentation() unless wInAnchors
        if w
          @achorSetChords(w,true)
        else
          @achorSetChords(null,false,true)

    achorSetChords: (w, endData = false, haveData = false ) ->
      if w
        if w.isInTrash
          @webjetAnchorChords = null
          @webjetAnchorSrc = null
          return
        rect = w.elementSceneRect
        if rect
          x = rect.x1 + ((rect.x2 - rect.x1) / 2)
          y = rect.y1 + ((rect.y2 - rect.y1) / 2)
          @webjetAnchorChords = {x: x, y: y, rect: rect}
          w.anchorSelectShow() unless @presentationIsActive
      if haveData
        unless @presentationIsActive
          @$router.replace path: @$router.path
          @anchorHaveData = true
      if endData
          unless @webjetAnchorChords
            @showCaution
              message: 'Object not found'
              loader: false
          @webjetAnchorChords = null
          unless @presentationIsActive
            @webjetAnchorSrc = null
            @$router.replace path: @$router.path

    openWejeSite: (src, tab = null)->
      @$refs.sidebar.open 'weje'
      setTimeout =>
        @$refs.sidebar.$refs.content.open src
        if tab
          setTimeout =>
            @$refs.sidebar.$refs.content.$refs.site.wejeTabs = tab


    openWejeSiteInit: (src, tab) ->
      @$router.replace path: @$router.path
      if @$vuetify.breakpoint.width > 800
        wsd = @$store.getters['weje/getWejeSiteData']({src:src})
        wsdChild = wsd?.childs[0]?.childs[0]
        if wsdChild
          wsdSrc = wsd.childs[0].childs[0].webjetPath.split('/').slice(-2).join('/')
          @goToWebjet wsdSrc, true
        else
          console.warn 'No webjet in weje page'
      @openWejeSite(src, tab)

    screenChanged: ()->
      rect = @$el.getBoundingClientRect()
      @screen.x1 = rect.x unless @screen.x1 == rect.x
      @screen.y1 = rect.y unless @screen.y1 == rect.y
      @screen.x2 = rect.right unless @screen.x2 == rect.right
      @screen.y2 = rect.bottom unless @screen.y2 == rect.bottom
      @screen.width = rect.width unless @screen.width == rect.width
      @screen.height = rect.height unless @screen.height == rect.height

    checkFreshFirstVisit: ()->
      return unless FLAG.FRESH
      freshDate = await @firebind 'freshDate'
      return if Object.keys(freshDate).length == 0
      unless freshDate[@src]
        @$store.dispatch 'fresh/firstBoardVisit', { boardId: @src }


  created: ->
    do ()=>
      offset = await @firebind("/.info/serverTimeOffset")
      @initDate += offset.$value
  mounted: ->
    setTimeout @checkFreshFirstVisit, 100
    @screenChanged()
    @observer = new ResizeObserver (entries)=>
      @screenChanged()
    @observer.observe @$el

    setTimeout (=> @hotKeyEnableDblclick = true ), 300
    if @route.query.w
      # 10 ms for wait render sidebar components
      setTimeout (=> @anchorInit(@route.query.w)), 10

    if @route.query.p and @route.query.p == '1'
      @presentationLoader = true
      unless @anchors.length
        items = @connections?.items
        items = Object.values items
        for c in items
          c = await @firebind c.src
          # console.log 'mounted', c
      @withoutAnimation =>
        @presentationInit()
      if @presentationLoader
        setTimeout (=> @presentationLoader = false), 500
    if @route.query.s
      tab = null
      tab = @route.query.t if @route.query.t
      setTimeout (=> @openWejeSiteInit(@route.query.s, tab)), 1

    if @connection.$path
      connection = await @firebind @src
      if connection.unread
        @$store.dispatch 'webjet/markAsRead', { path: @src }
    else
      connection = @connection
    setTimeout =>
      delayedCopy = await @firebind "#{connection.src}/meta/delayedCopy"
      #console.log '!', delayedCopy
      if delayedCopy?.$value
        getRef("#{connection.src}/meta/delayedCopy").transaction(
          (currentData) =>
            #console.log '!TR', currentData
            return null if currentData
          ,
          (error, committed, snapshot) =>
            #console.log '!!!', error, committed, snapshot, snapshot.val()
            if error
              console.error "delayedCopy transaction error", error
              return
            if committed
              copySrc = await @firebind "#{connection.src}/meta/copySrc"
              if copySrc?.$value
                await @$store.dispatch 'webjet/completeDelayedCopy',
                  path: copySrc.$value
                  dest: connection.src
            @sceneInitPosition()
        )
    if @route.hash == '#o'
      newRoute = Object.assign {}, @route
      delete newRoute.hash
      @$router.replace newRoute
      @provideApp.navbarOpen()

