

















import NavigationContent from './navigation/NavigationContent'
import HelpArrow from '@/components/HelpArrow'
import { anchorSortKey } from '@/utils'
export default
  name: 'SidebarLeft'
  inject: ['provideCanvas']
  components: {NavigationContent, HelpArrow}
  provide: ->
    provideSidebarLeft: this
  data: ->
    mode: 'hide'
  computed:
    x: ->
      if @mode == 'hide'
        return 0
      else if @mode == 'mini'
        return 60
      else if @mode == 'full'
        return 350
    styles: ->
      return
        transform: "translateX(#{@x}px)"
    anchors: ->
      items = @provideCanvas.connections?.items
      return [] unless items
      items = Object.values items
      items = items.filter (c)=>
        w = @firebind(c.src)
        category = w.category if w
        if category == 'anchor'
          d = Date.parse(w.meta.createDate)
        category == 'anchor'
      itemsSort = items.sort (a, b)=>
        navSortKeyA = anchorSortKey(a)
        navSortKeyB = anchorSortKey(b)
        parseFloat(navSortKeyA) - parseFloat(navSortKeyB)
      items
    activeAnchors: ->
      @provideCanvas.anchors
  watch:
    mode: ->
      boardEl = document.querySelector '#board-page'
      if @mode == 'full'
        boardEl.classList.add 'sidebar-left-open'
        sessionStorage['presentation_navigation_mode'] = 'full'
      else
        boardEl.classList.remove 'sidebar-left-open'
      if @mode == 'mini'
        boardEl.classList.add 'sidebar-left-open-mini'
        sessionStorage['presentation_navigation_mode'] = 'mini'
      else
        boardEl.classList.remove 'sidebar-left-open-mini'
      if @mode == 'hide'
        sessionStorage.removeItem 'presentation_navigation_mode'

  methods:
    openClose: ->
      if @mode == 'hide' or @mode == 'mini'
        @mode = 'full'
      else
        @mode = 'hide'
    close: ->
      @mode = 'hide'
    mini: ->
      if @mode == 'full'
        @mode = 'mini'
      else
        @mode = 'full'
    startPresentation: ->
      return unless @anchors.length
      src = @anchors[0].src.split('/').slice(-2).join('/')
      @$router.push path: @$router.path, query: {p: '1'}
  mounted: ->
    if FLAG.SESSION_PRESENTATION_NAVIGATION and sessionStorage['presentation_navigation_mode']
      boardEl = document.querySelector '#board-page'
      boardEl.classList.add 'notransition'
      @mode = sessionStorage['presentation_navigation_mode']
      @$nextTick => boardEl.classList.remove 'notransition'

