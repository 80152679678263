import './css/selectable.sass'
import { userColor, userDisplayName } from '@/utils'
import { isIOSSafari } from '@/utils'

selectable =
  data: ->
    childIsSelected: []
    selectByAnchor: false
    selectAnchorByWebjetInit: false
  classes: ->
    return if !@isSelected and !@isWsSelected and !@isChildeSelected and !@selectAnchor and !@selectAnchorByWebjetInit
    return
      selected: @isSelected
      "only-selected" : @isOnlySelected
      "selected-other": @isWsSelected
      "child-selected": @isChildeSelected
      "selected-by-anchor": @selectAnchorByWebjetInit
      "in-frame": @selectAnchor
      "editing": @editWebjetIsActive

  styles: ->
    return false if !@isSelected and !@isWsSelected
    if @isWsSelected
      '--selectbyother': userColor @wsSelected[0]
      '--selectname': '"' + @wsSelectedName + '"'
      '--scale': @provideCanvas?.scale
      'z-index': 2
    else
      '--scale': @provideCanvas?.scale or 1

  listeners: ->
    return false if @isReadOnly
    if isIOSSafari
      return
        tap: @selectableTap
        taphold: @selectableTaphold
    else
      return
        mousedown: @selectableMousedown
        mousemove: @selectableMousemove
        mouseup: @selectableMouseup
        taphold: @selectableTaphold

  computed:
    editWebjetIsActive: ->
      return false unless @isOnlySelected
      graffitiEditIsActive = false
      if FLAG.GRAFFITI_TOOLS_TO_CONTEXT_MENU
        graffitiEditIsActive = @$refs.editor?.graffitiData?.activeGraffitiEdit
      else
        graffitiEditIsActive = @provideCanvas?.activeGraffitiEdit
      return true if @provideCanvas?.activeEditor or graffitiEditIsActive
    selectAnchor: ->
      return false unless @provideCanvas
      @provideCanvas.getWebjetComponentInAnchor(@src) == this
    selectedChild: ->
      @childItems

    selectionRelations: ->
      return unless @isChildable
      return unless @provideScene
      if @isSelected and !@parentWebjets[0] or @childIsSelected.length > 0
        childItems = [...@childItems]
        itemsSrc = []
        webjet = @firebind @src
        itemsSrc.push webjet.src
        for c in childItems
          webjet = @firebind c.src
          itemsSrc.push c.src

          items = webjet?.connections?.items

          if items
            for k,v of items
              itemsSrc.push v.src

        selectionWebjetsRelations = []
        for r in @provideCanvas.relations
          relationWebjet = @firebind r
          for k,v of relationWebjet?.connections?.parents
            if v.src in itemsSrc
              selectionWebjetsRelations.push r
        return selectionWebjetsRelations
    isSelectable: -> true
    canSelect: -> true
    isSelected: ->
      if FLAG.SELECTION_PERF
        @$store.getters['webjet/isSelected'](this)
      else
        this in @$store.getters['webjet/selected']
    isOnlySelected: ->
      if FLAG.SELECTION_PERF
        @isSelected and @$store.getters['webjet/isOneWebjetSelected']
      else
        this in @$store.getters['webjet/selected'] and @$store.getters['webjet/selected'].length==1
    wsSelected: ->
      @$store.getters['webjet/wsSelected'][@src]
    isWsSelected: ->
      @wsSelected
    wsSelectedName: ->
      userDisplayName @wsSelected[0]
    isChildeSelected: ->
      # return false if @parentWebjets[0]
      childIsSelected = @childIsSelected
      return false unless childIsSelected.length and @childItems.length
      for child, index in childIsSelected
        if child.isDestroyed
          @childIsSelected.splice index, 1
      return false unless @childIsSelected.length
      return true

  watch:
    selectionRelations: (val) ->
      @provideCanvas.selectionRelations = val if val
    isSelected: (val) ->
      return false unless @parentWebjets[0]
      for parent in @parentWebjets
        if val
          parent.childIsSelected.push this
        else
          self = this
          index = parent.childIsSelected.findIndex (el) ->
            el == self
          if index != -1
            parent.childIsSelected.splice index,1

    isWsSelected: (val) ->
      return false unless @parentWebjets[0]
      for parent in @parentWebjets
        if val
          parent.childIsSelected.push this
        else
          self = this
          index = parent.childIsSelected.findIndex (el) ->
            el == self
          if index != -1
            parent.childIsSelected.splice index,1
  methods:
    # Unfortunately browsers send click event upon mouseup even if mouse were moved or click started in other webjet.
    # So we track mouse events and call click handler on our own.
    selectableMousedown: (e)->
      if e.shiftKey and !@provideScene
        e.stopPropagation()
      @selectableMouseClick = true
      @selectableMouseClickX = e.clientX
      @selectableMouseClickY = e.clientY
      if FLAG.WEBJETS_MOVING_ICON
        cPath = e.composedPath()
        clickOnMovingIcon = false
        for node in cPath
          if node?.classList?.contains 'w-moving'
            clickOnMovingIcon = true
            break if clickOnMovingIcon
        @selectableMouseClickOnMovinIcon = clickOnMovingIcon
    selectableMousemove: (e)->
      if @selectableMouseClick and (e.clientX != @selectableMouseClickX or e.clientY != @selectableMouseClickY)
        @selectableMouseClick = false
    selectableMouseup: (e)->
      if @selectableMouseClick and e.which == 1 and !e.shiftKey and !e.ctrlKey and !@isInTrash
        e.stopPropagation()
        if FLAG.PAINT_SELECTION_AREA and @isSelected
          drag = document.querySelector('#drag-please-drag')
          rtIsDrag = e.target?.id == 'drag-please-drag'
          if drag and rtIsDrag
            drag.parentNode.removeChild(drag)
            paint = document.elementFromPoint(e.x, e.y)
            paint.style.setProperty('pointer-events', 'none', 'important')
            elementUnderPaint = document.elementFromPoint(e.x, e.y)
            paint.style.pointerEvents = null
            mouseEventUp = new MouseEvent 'mouseup', {view: window, bubbles: true,cancelable: true,clientX: e.clientX,clientY: e.clientY, x: e.x,y:e.y}
            mouseEventDown = new MouseEvent 'mousedown', {view: window, bubbles: true,cancelable: true,clientX: e.clientX,clientY: e.clientY, x: e.x,y:e.y}
            elementUnderPaint.dispatchEvent(mouseEventDown)
            elementUnderPaint.dispatchEvent(mouseEventUp)
            @selectableMouseClick = false
            return
        webjetForSelect = this
        activeEditAfterSelect = false
        if FLAG.WEBJETS_MOVING_ICON
          selected = if @$store.getters['webjet/selected'].length == 1 then @$store.getters['webjet/selected'][0] else null
          if @provideWebjet
            provideWebjetIsInventory = @provideWebjet?.$options.name == 'WebjetInventory'
          if !@provideWebjet or @provideWebjet.webjet.category == '/webjets/containers/webjets/list' or provideWebjetIsInventory
            stackWebejt = this
          else
            cPath = e.composedPath()
            clickOnMovingIcon = false
            for node in cPath
              if node?.classList?.contains 'w-moving'
                clickOnMovingIcon = true
                break if clickOnMovingIcon
            if clickOnMovingIcon
              stackWebejt = this
            else
              if selected == @provideWebjet or @provideWebjet.isChildeSelected
                stackWebejt = this
                activeEditAfterSelect = true
              else
                stackWebejt = @provideWebjet

          webjetForSelect = stackWebejt
        setTimeout => #@selectableClick(e)
          # This is executed with timeout to not interfere with WEditor.activateEditor.
          # On first click webjet should be selected, on second one editor should be activated.
          # Executing it immediately leads to editor is activated by same mouse event.
          @$store.dispatch 'webjet/setNowselected', { webjets: [webjetForSelect] }
          @$store.dispatch 'webjet/setPreselected', { webjets: [] }
          @$store.dispatch 'webjet/relation/setSelected', { webjet: null }
          if activeEditAfterSelect
            @$nextTick =>
              if webjetForSelect.$options.name=='WebjetCard'
                webjetForSelect.$refs?.editor?.activateEditor()
              if webjetForSelect.$options.name=='WebjetGraffiti'
                webjetForSelect.$refs?.editor?.activateEditor()
      if !@provideScene and @selectableMouseClick and e.which == 1 and e.shiftKey and !e.ctrlKey and !@isInTrash
        e.stopPropagation()
        setTimeout =>
          if @isSelected
            @$store.dispatch 'webjet/removeNowselected', { webjets: [this] }
          else
            @$store.dispatch 'webjet/addNowselected', { webjets: [this] }
          @$store.dispatch 'webjet/relation/setSelected', { webjet: null }
      @selectableMouseClick = false
    # Handle tap. Used on iOS/Safari only where mouse-methods above are not.
    selectableTap: (e)->
      @$store.dispatch 'webjet/setNowselected', { webjets: [this] }
      @$store.dispatch 'webjet/setPreselected', { webjets: [] }
      @$store.dispatch 'webjet/relation/setSelected', { webjet: null }
      e.stopPropagation()

    selectableTaphold: (e)->
      if @isSelected
        @$store.dispatch 'webjet/removeNowselected', { webjets: [this] }
      else if !@isInTrash
        @$store.dispatch 'webjet/addNowselected', { webjets: [this] }
      e.stopPropagation()

    anchorSelectShow: () ->
      @selectAnchorByWebjetInit = true
      setTimeout (=> @selectAnchorByWebjetInit = false), 3500

export default selectable
export { selectable }
