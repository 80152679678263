

















import DialogTemplate from '@/components/DialogTemplate'
import CreateTeamIcon from '@/components/Team/CreateTeamIcon'
export default
  name: 'TeamCreate'
  components: { DialogTemplate, CreateTeamIcon }
  data: ->
    show: false
    title: ''
  methods:
    createTeam:  ->
      #console.log '!createTeam', @title
      result = await @$store.dispatch 'team/create',
        title: @title
      #console.log '>', result
      img = @$refs?.teamImg?.imgFile
      if img
        await @$store.dispatch 'team/updateTeamIcon',
          path: "/teams/#{result.team}"
          file: img
      @show = false
      setTimeout =>
        @$router.push { Name: 'Team', params: { teamId: result.team } }
    hotkeyBlocker: (e) ->
      # allow undo/redo
      if e.ctrlKey && e.keyCode in [ 89, 90 ]
        return
      # block other hotkeys
      e.stopPropagation()
      # block ctrl +/-/0
      if e.ctrlKey && e.keyCode in [ 187, 189, 48, 107, 109, 45, 43, 96 ]
        e.preventDefault()
