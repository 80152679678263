





export default
  name: 'SceneShadowPaint'
  inject: ['provideScenePaints']
  props: ['paint']
  computed:
    listeners: ->
      @paint.genListeners
    x: ->
      Math.round @paint.elementSceneRect.x1
    y: ->
      Math.round @paint.elementSceneRect.y1
    width: ->
      Math.round(@paint.elementSceneRect.x2 - @paint.elementSceneRect.x1)
    height: ->
      Math.round(@paint.elementSceneRect.y2 - @paint.elementSceneRect.y1)


