

















































































import HelpArrow from '@/components/HelpArrow'
import ToolVoice from './ToolVoice'
export default
  name: 'Tools'
  inject: ['provideApp', 'provideCanvas']
  data: ->
    showShapes: false
  components: { HelpArrow, ToolVoice }
  computed:
    boardPath: -> @provideCanvas.data.$path
    selected: -> @provideCanvas.selected
    lastShapeType: -> @provideCanvas.lastShapeType
    lastShapeTitle: ->
      firstLetter = @lastShapeType.charAt(0).toUpperCase()
      shapeType = @lastShapeType.replace(/right|left|bottom|top|-/g,'')
      return @$t('canvas.action.create' + firstLetter + shapeType.slice(1))
  methods:
    selectionCentralize: -> @provideCanvas.selectionCentralize()

    createGraffiti: (e) ->
      type = 1
      type = 2 if @$vuetify.breakpoint.height > @$vuetify.breakpoint.width
      category = '/webjets/content/webjets/graffiti'
      if FLAG.CREATE_IN_SELECTION
        { dest, boardPath, connection} = @provideCanvas.selectDataForCreateInSelection(category)
      else
        dest = @boardPath
        boardPath = @boardPath
        connection =
          coordinates: @provideCanvas.getEmptyCoordinates
            x: @provideCanvas.sceneX + @provideCanvas.sceneWidth / 2 - 150
            y: @provideCanvas.sceneY + @provideCanvas.sceneHeight / 2 - 50
      result = await @$store.dispatch 'webjet/create',
        category: category
        dest: dest #dest
        boardPath: boardPath
        webjet:
          data:
            sizeType: type
        connection: connection
      @provideCanvas.selectCreatedWebjets [ result ], true

    createCard: (e)->
      #if @selected?.length == 1 and @selected[0].canDroppable and @selected[0].isExpanded
      #  dest = @selected[0].webjet.$path
      #  @selectionCentralize()
      #else
      #  dest = @boardPath

      category = '/webjets/content/webjets/note'
      if FLAG.CREATE_IN_SELECTION
        { dest, boardPath, connection} = @provideCanvas.selectDataForCreateInSelection(category)
      else
        dest = @boardPath
        boardPath = @boardPath
        connection =
          coordinates: @provideCanvas.getEmptyCoordinates
            x: @provideCanvas.sceneX + @provideCanvas.sceneWidth / 2 - 150
            y: @provideCanvas.sceneY + @provideCanvas.sceneHeight / 2 - 50
      # console.log @provideCanvas.selectDataForCreateInSelection(category)
      result = await @$store.dispatch 'webjet/create',
        category: category
        dest: dest #dest
        boardPath: boardPath
        connection: connection
      @provideCanvas.selectCreatedWebjets [ result ], true

    createList: (e)->
      #if @selected?.length == 1 and @selected[0].canDroppable and @selected[0].isExpanded
      #  dest = @selected[0].webjet.$path
      #  @selectionCentralize()
      #else
      #  dest = @boardPath
      category = '/webjets/containers/webjets/list'
      if FLAG.CREATE_IN_SELECTION
        { dest, boardPath, connection} = @provideCanvas.selectDataForCreateInSelection(category)
      else
        dest = @boardPath
        boardPath = @boardPath
        connection =
          coordinates: @provideCanvas.getEmptyCoordinates
            x: @provideCanvas.sceneX + @provideCanvas.sceneWidth / 2 - 150
            y: @provideCanvas.sceneY + @provideCanvas.sceneHeight / 2 - 50
      result = await @$store.dispatch 'webjet/create',
        category: category
        dest: dest #dest
        boardPath: boardPath
        connection: connection
      @provideCanvas.selectCreatedWebjets [ result ], true
    createShape: (e, type, canTouch = true) ->
      unless canTouch
        @showShapes = !@showShapes
        return unless e.pointerType == 'mouse'
      category = '/webjets/content/webjets/shape'
      if FLAG.CREATE_IN_SELECTION
        { dest, boardPath, connection} = @provideCanvas.selectDataForCreateInSelection(category)
      else
        dest = @boardPath
        boardPath = @boardPath
        connection =
          coordinates: @provideCanvas.getEmptyCoordinates
            x: @provideCanvas.sceneX + @provideCanvas.sceneWidth / 2 - 150
            y: @provideCanvas.sceneY + @provideCanvas.sceneHeight / 2 - 50
      result = await @$store.dispatch 'webjet/create',
        category: category
        dest: dest #dest
        boardPath: boardPath
        webjet:
          data:
            type: type
            text: ''
        connection: connection
      @provideCanvas.selectCreatedWebjets [ result ], true
      @provideCanvas.lastShapeType = type

    createTable: (e)->
      category = 'table'
      if FLAG.CREATE_IN_SELECTION
        { dest, boardPath, connection} = @provideCanvas.selectDataForCreateInSelection(category)
      else
        dest = @boardPath
        boardPath = @boardPath
        connection =
          coordinates: @provideCanvas.getEmptyCoordinates
            x: @provideCanvas.sceneX + @provideCanvas.sceneWidth / 2 - 150
            y: @provideCanvas.sceneY + @provideCanvas.sceneHeight / 2 - 50
      result = await @$store.dispatch 'webjet/create',
        category: category
        dest: dest #dest
        boardPath: boardPath
        connection: connection
      @provideCanvas.selectCreatedWebjets [ result ], true

    createVoice: ({file, duration, author})->
      category = 'voice'
      if FLAG.CREATE_IN_SELECTION
        { dest, boardPath, connection} = @provideCanvas.selectDataForCreateInSelection(category)
      else
        dest = @boardPath
        boardPath = @boardPath
        connection =
          before: false
          coordinates: @provideCanvas.getEmptyCoordinates
            x: @provideCanvas.sceneX + @provideCanvas.sceneWidth / 2 - 150
            y: @provideCanvas.sceneY + @provideCanvas.sceneHeight / 2 - 50
      result = await @$store.dispatch 'webjet/dataTransfer',
        dest: dest #dest
        boardPath: boardPath
        before: connection.sortKey
        dataTransfer:
          types: [ 'Files' ]
          files: [ file ]
        data:
          duration: duration
          author: author
          coordinates: @provideCanvas.getEmptyCoordinates
            x: @provideCanvas.sceneX + @provideCanvas.sceneWidth / 2 - 150
            y: @provideCanvas.sceneY + @provideCanvas.sceneHeight / 2 - 50
      @provideCanvas.selectCreatedWebjets [ result ], true

    createChecklist: (e)->
      category = 'checklist'
      if FLAG.CREATE_IN_SELECTION
        { dest, boardPath, connection} = @provideCanvas.selectDataForCreateInSelection(category)
      else
        dest = @boardPath
        boardPath = @boardPath
        connection =
          coordinates: @provideCanvas.getEmptyCoordinates
            x: @provideCanvas.sceneX + @provideCanvas.sceneWidth / 2 - 150
            y: @provideCanvas.sceneY + @provideCanvas.sceneHeight / 2 - 50
      result = await @$store.dispatch 'webjet/create',
        category: category
        dest: dest #dest
        boardPath: boardPath
        connection: connection
      @provideCanvas.selectCreatedWebjets [ result ], true

    createParentCard: (e)->
      category = 'parentCard'
      if FLAG.CREATE_IN_SELECTION
        { dest, boardPath, connection} = @provideCanvas.selectDataForCreateInSelection(category)
      else
        dest = @boardPath
        boardPath = @boardPath
        connection =
          coordinates: @provideCanvas.getEmptyCoordinates
            x: @provideCanvas.sceneX + @provideCanvas.sceneWidth / 2 - 150
            y: @provideCanvas.sceneY + @provideCanvas.sceneHeight / 2 - 50
      result = await @$store.dispatch 'webjet/create',
        category: category
        dest: dest #dest
        boardPath: boardPath
        connection: connection
      @provideCanvas.selectCreatedWebjets [ result ], true

    dargGraffiti: (e)->
      e.dataTransfer.clearData()
      e.dataTransfer.setData 'create/graffiti', {}
      e.stopPropagation()
      return

    dragCard: (e)->
      e.dataTransfer.clearData()
      e.dataTransfer.setData 'create/card', {}
      e.stopPropagation()
      return
    dragList: (e)->
      e.dataTransfer.clearData()
      e.dataTransfer.setData 'create/list', {}
      e.stopPropagation()
      return

    openUpload: ->
      input = @$el.querySelector 'input.input-upload'
      if input
        input.click()
    processUpload: ->
      input = @$el.querySelector 'input.input-upload'
      if input?.files?.length > 0
        if not (await @provideApp.checkFileSizeLimits input.files, @boardPath)
          input.value = null
          return
        #if @selected?.length == 1 and @selected[0].canDroppable and @selected[0].isExpanded
        #  dest = @selected[0].webjet.$path
        #  @selectionCentralize()
        #else
        #  dest = @boardPath
        # Object passed as dataTransfer parameter should mimic ClipboardEvent.clipboardData and DragEvent.dataTransfer objects
        # in extent enough for webjet/dataTransfer operation to work. So far this operation uses types[0] and files array only.
        category = 'file'
        if FLAG.CREATE_IN_SELECTION
          { dest, boardPath, connection} = @provideCanvas.selectDataForCreateInSelection(category)
        else
          dest = @boardPath
          boardPath = @boardPath
          connection =
            before: false
            coordinates: @provideCanvas.getEmptyCoordinates
              x: @provideCanvas.sceneX + @provideCanvas.sceneWidth / 2 - 150
              y: @provideCanvas.sceneY + @provideCanvas.sceneHeight / 2 - 50
        results = await @$store.dispatch 'webjet/dataTransfer',
          dest: dest #dest
          boardPath: boardPath
          before: connection.sortKey
          dataTransfer:
            types: [ 'Files' ]
            files: input.files
          data:
            coordinates: @provideCanvas.getEmptyCoordinates
              x: @provideCanvas.sceneX + @provideCanvas.sceneWidth / 2 - 150
              y: @provideCanvas.sceneY + @provideCanvas.sceneHeight / 2 - 50
        input.value = null
        @provideCanvas.selectCreatedWebjets results, true
