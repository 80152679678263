expandable =
  classes: ->
    return false unless @connection
    return
      expanded: @isExpanded

  computed:
    isExpandable: -> true
    canExpand: ->
      category = @webjet.category
      if category == '/webjets/content/webjets/shape'
        if @provideWebjet or @isInTrash
          return true
        else
          return false
      true
    isExpanded: ->
      category = @webjet.category
      if category == '/webjets/content/webjets/shape'
        return true if !@provideWebjet and !@isInTrash
      if @connection?.presentation
        @connection.presentation != 'row'
      else
        true #!@provideWebjet

  methods:
    toggleExpand: ->
      @$store.dispatch 'webjet/setExpand',
        path: @src
        value: !@isExpanded
        withoutHistory: @isInTrash

export default expandable
export { expandable }
