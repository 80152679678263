
















import { usualMixinPack } from './mixins'

export default
  name: 'WebjetEmbedCode'
  mixins: [...usualMixinPack]
  computed:
    url: ->
      @webjet?.data?.url
    scrolling: ->
      @webjet?.data?.scrolling or 'auto'
