import { operationActions } from '@/store/operation'
import getters from './team-getters'
import mutations from './team-mutations'
import operations from './team-operations'

export default
  namespaced: true
  getters: getters
  mutations: mutations
  actions: operationActions operations
  #modules: {  }