export default
  data: ->
    activeGraffitiEdit: false
    graffitiType: 'pen'
    graffitiTypes:
      pen:
        color: 'black'
        width: 5
      marker:
        color: 'black'
        width: 5
      lastic:
        color: null
        width: null

  computed:
    graffitiColor: ->
      @graffitiTypes[@graffitiType].color
    graffitiWidth: ->
      @graffitiTypes[@graffitiType].width

