import store from '@/store'
import { firebind } from '@/utils'

export generateConnectionId = ()->
  timestamp = new Date().valueOf()
  salt = (((1+Math.random())*0x10000)|0).toString(16).substring(1)
  return "#{timestamp}-#{salt}"

export generateSort = ( webjet, before, group='items' )->
  if before == undefined or before == null
    return {}
  connections = []
  if webjet.connections? and webjet.connections[group]
    connections.push(c) for k, c of webjet.connections[group]
  result = false
  if before == true
    min = 1
    for c in connections
      if Number.isFinite(c.sortKey) and c.sortKey < min
        min = c.sortKey
    result = { sortKey: min-1 }
  else if before == false
    max = -1
    for c in connections
      if Number.isFinite(c.sortKey) and c.sortKey > max
        max = c.sortKey
    result = { sortKey: max+1 }
  else
    closest = false
    for c in connections
      if Number.isFinite(c.sortKey) and c.sortKey < before
        if (!closest and closest != 0) or c.sortKey > closest
          closest = c.sortKey
    closest = before - 2 if closest == false
    result = { sortKey: (before + closest) / 2 }
  return result

export generateAnchorSort = ( webjet, before, after )->
  if before == undefined or before == null
    return {}
  result = false

  if before == true
    sortKey = new Date().getTime()
    result = { anchorSortKey: sortKey }
  else if before == false
    max = -1
    for c in connections
      sortKey = anchorSortKey(c)
      if Number.isFinite(sortKey) and sortKey > max
        max = sortKey
    result = { anchorSortKey: max+1 }
  else
    closest = false
    # for c in connections
    #   sortKey = anchorSortKey(c)
    #   if Number.isFinite(sortKey) and sortKey < before
    #     if !closest or sortKey > closest
    #       closest = sortKey
    # afterSortKey = false
    if after
      closest = anchorSortKey(after)
    # console.log  'cccccccc',after,closest
    closest = before - 2 unless closest

    result = { anchorSortKey: (before + closest) / 2 }
    # result = { anchorSortKey: 16451153130130 }

  return result


export anchorSortKey = (connection)->
  w = firebind(connection.src)

  sortKey = connection.anchorSortKey
  unless sortKey
    sortKey = Date.parse(w.meta.createDate)
  # console.log sortKey, w, connection
  return sortKey

export getOwnerUID = (src)->
  return undefined unless src
  path = src
  path = src.substring(9) if src.startsWith 'firebase:'
  if path.indexOf('/data/')==0
    pathArr = path.split '/'
    if pathArr.length>3
      return pathArr[2]
    else
      return undefined
  else if path.indexOf('/boards/')==0 or path.indexOf('/boardsData/')==0
    pathArr = path.split '/'
    if pathArr.length>=3
      return (firebind "/boards/#{pathArr[2]}/owner").$value
    else
      return undefined
  else # short src (desktop, trash, etc) assumed
    return store.state.uid # current user

export selectCreatedWebjetsWithoutCanvas = (path) ->
  setTimeout =>
    element = document.querySelector "##{path.split('/').pop()}"
    webjet = element.webjet
    await store.dispatch 'webjet/setNowselected', { webjets: [webjet] }
    await store.dispatch 'webjet/setPreselected', { webjets: [] }
    await store.dispatch 'webjet/relation/setSelected', { webjet: null }
    if webjet.$options.name=='WebjetCard'
      webjet.$refs?.editor?.activateEditor()
    if webjet.$options.name=='WebjetGraffiti'
      webjet.$refs?.editor?.activateEditor()
    if webjet.$options.name=='WebjetChecklist'
      webjet.createItemWithoutEvent()
    if webjet.$options.name=='WebjetComments'
      webjet.active = true