




















import { mdiClose  } from '@mdi/js'
export default
  name: 'ModalWindow'
  data: ->
    dialog: true
    icon: mdiClose
    foreverCheckbox: false
  methods:
    close: ->
      @dialog = false
      @$store.dispatch 'user/closeWelcomeModal', { forever: @foreverCheckbox }
    closeByEscape: ->
      @dialog = false
      @$store.dispatch 'user/closeWelcomeModal', { forever: false }
