







































































import { generateSort } from '@/utils'

export default
  name: 'PlusTools'
  inject: ['provideApp', 'provideCanvas']
  props: ['plusPoint']
  data: ->
    rect: null
    showShapes: false
  computed:
    boardPath: -> @provideCanvas.data.$path
    selected: -> @provideCanvas.selected
    dest: -> @webjetForPlus.webjet.$path
    webjetForPlus: ->
      if FLAG.WEBJET_PARENT and @plusPoint.webjet.provideWebjet and !@plusPoint.webjet.provideWebjet.isContainer
        @plusPoint.webjet.provideWebjet
      else
        @plusPoint.webjet
    lastShapeType: -> @provideCanvas.lastShapeType
    lastShapeTitle: ->
      firstLetter = @lastShapeType.charAt(0).toUpperCase()
      shapeType = @lastShapeType.replace(/right|left|bottom|top|-/g,'')
      return @$t('canvas.action.create' + firstLetter + shapeType.slice(1))
    showCreateList: ->
      return true if @plusPoint.webjet.webjet.category == '/webjets/containers/webjets/list'

    style: ->
      if @plusPoint and @rect
        y = 0
        x = 0
        cpy = @provideCanvas.position.y
        czy = @provideCanvas.zoom.dy
        cpx = @provideCanvas.position.x
        czx = @provideCanvas.zoom.dx
        ppx = @plusPoint.point.x
        ppy = @plusPoint.point.y
        scale = @provideCanvas.scale
        dy = cpy + czy + ppy * scale
        dx = cpx + czx + ppx * scale
        offset = 40 * scale
        offset = 30 if offset < 30
        offset = 170 if offset > 170
        # position Y
        if @plusPoint.point.before
          y = dy - @rect.height - offset
          if y < 0
            y = dy + offset
        else
          y = dy + offset
          if y > @$vuetify.breakpoint.height - @rect.height - offset
            y = dy - @rect.height - offset
        # position X
        x = dx
        if x < (@rect.width + 40) / 2
          x = (@rect.width + 60) / 2
        if x > @$vuetify.breakpoint.width - (@rect.width + 40) /2
          x = @$vuetify.breakpoint.width - (@rect.width + 60) /2
        return
          left: "#{x}px"
          top: "#{y}px"

  watch:
    selected: (val) ->
      @provideCanvas.removePlusTools()
  methods:
    selectionCentralize: -> @provideCanvas.selectionCentralize()
    createCard: (e)->
      dest = @dest
      sort = generateSort(@webjetForPlus.webjet, @plusPoint.point.before)
      connection = Object.assign {}, sort
      if @plusPoint.point.columnKey
        connection.columnKey = @plusPoint.point.columnKey
      connection.coordinates = {
        x: @provideCanvas.sceneX + @provideCanvas.sceneWidth / 2 - 150
        y: @provideCanvas.sceneY + @provideCanvas.sceneHeight / 2 - 50
      }
      result = await @$store.dispatch 'webjet/create',
        category: '/webjets/content/webjets/note'
        boardPath: @provideCanvas.connection.src
        dest: dest
        connection: connection
      @provideCanvas.selectCreatedWebjets [ result ]

    createGraffiti: (e)->
      dest = @dest
      sort = generateSort(@webjetForPlus.webjet, @plusPoint.point.before)
      connection = Object.assign {}, sort
      if @plusPoint.point.columnKey
        connection.columnKey = @plusPoint.point.columnKey
      connection.coordinates = {
        x: @provideCanvas.sceneX + @provideCanvas.sceneWidth / 2 - 150
        y: @provideCanvas.sceneY + @provideCanvas.sceneHeight / 2 - 50
      }
      result = await @$store.dispatch 'webjet/create',
        category: '/webjets/content/webjets/graffiti'
        dest: dest
        boardPath: @provideCanvas.connection.src
        connection: connection
      @provideCanvas.selectCreatedWebjets [ result ]

    createShape: (e, type, canTouch = true)->
      unless canTouch
        @showShapes = !@showShapes
        return unless e.pointerType == 'mouse'
      dest = @dest
      sort = generateSort(@webjetForPlus.webjet, @plusPoint.point.before)
      connection = Object.assign {}, sort
      if @plusPoint.point.columnKey
        connection.columnKey = @plusPoint.point.columnKey
      connection.coordinates = {
        x: @provideCanvas.sceneX + @provideCanvas.sceneWidth / 2 - 150
        y: @provideCanvas.sceneY + @provideCanvas.sceneHeight / 2 - 50
      }
      result = await @$store.dispatch 'webjet/create',
        category: '/webjets/content/webjets/shape'
        dest: dest
        boardPath: @provideCanvas.connection.src
        webjet:
          data:
            type: type
            text: ''
        connection: connection
      @provideCanvas.selectCreatedWebjets [ result ]
      @provideCanvas.lastShapeType = type

    createList: (e)->
      dest = @dest
      sort = generateSort(@webjetForPlus.webjet, @plusPoint.point.before)
      connection = Object.assign {}, sort
      if @plusPoint.point.columnKey
        connection.columnKey = @plusPoint.point.columnKey
      connection.coordinates = {
        x: @provideCanvas.sceneX + @provideCanvas.sceneWidth / 2 - 150
        y: @provideCanvas.sceneY + @provideCanvas.sceneHeight / 2 - 50
      }
      result = await @$store.dispatch 'webjet/create',
        category: '/webjets/containers/webjets/list'
        dest: dest
        boardPath: @provideCanvas.connection.src
        connection: connection
      @provideCanvas.selectCreatedWebjets [ result ]

    dragCard: (e)->
      e.dataTransfer.clearData()
      e.dataTransfer.setData 'create/card', {}
      e.stopPropagation()
      return
    dargGraffiti: (e)->
      e.dataTransfer.clearData()
      e.dataTransfer.setData 'create/graffiti', {}
      e.stopPropagation()
      return
    dragList: (e)->
      e.dataTransfer.clearData()
      e.dataTransfer.setData 'create/list', {}
      e.stopPropagation()
      return

    openUpload: ->
      input = @$refs.plusToolsUpload
      if input
        input.click()
    processUpload: ->
      input = @$refs.plusToolsUpload
      if input?.files?.length > 0
        if not (await @provideApp.checkFileSizeLimits input.files, @provideCanvas.connection.src)
          input.value = null
          return
        dest = @dest
        sort = generateSort(@webjetForPlus.webjet, @plusPoint.point.before)
        connection = Object.assign {}, sort
        if @plusPoint.point.columnKey
          connection.columnKey = @plusPoint.point.columnKey
        connection.coordinates = {
          x: @provideCanvas.sceneX + @provideCanvas.sceneWidth / 2 - 150
          y: @provideCanvas.sceneY + @provideCanvas.sceneHeight / 2 - 50
        }
        # Object passed as dataTransfer parameter should mimic ClipboardEvent.clipboardData and DragEvent.dataTransfer objects
        # in extent enough for webjet/dataTransfer operation to work. So far this operation uses types[0] and files array only.
        results = await @$store.dispatch 'webjet/dataTransfer',
          dest: dest
          boardPath: @provideCanvas.connection.src
          dataTransfer:
            types: [ 'Files' ]
            files: input.files
          data: connection
        @provideCanvas.selectCreatedWebjets results
        input.value = null

    # clickOutsideChat: (e) ->
    #   console.log "clickOutsideChat"
    #   unless e.target.closest '.canvas-plus-tools'
    #     @provideCanvas.removePlusTools()

    setRect: ->
      @rect = @$refs.plusTools.getBoundingClientRect()

  mounted: ->
    requestAnimationFrame(@setRect)

  # created: ->
  #   document.addEventListener 'mousedown', @clickOutsideChat
  # destroyed: ->
  #   document.removeEventListener 'mousedown', @clickOutsideChat
