




















import { usualMixinPack } from './mixins'
import $tradingview from '../plugins/tradingview.coffee'

export default
  name: 'WebjetTrading'
  mixins: [...usualMixinPack]
  data: ->
    timeout: null
    containerId: 'traindingview_' + Date.now() + Math.floor Math.random() * 10 ** 6
    options:
      autosize: true,
      interval: "D",
      timezone: "Etc/UTC",
      theme: "light",
      style: "1",
      locale: "en",
      toolbar_bg: "#f1f3f6",
      enable_publishing: false,
      hide_side_toolbar: false,
  mounted: ->
    await $tradingview()
    @generateChartData()

  computed:
    interval: ->
      if (@webjet.data.interval)
        return @webjet.data.interval.split('/')[0]
    isVisible: ->
      if @provideWebjet
        rootWebjet = @provideWebjet
        while rootWebjet.provideWebjet
          rootWebjet = rootWebjet.provideWebjet
        return rootWebjet.isTheoreticallyVisible and @isExpanded
      else
        return @isTheoreticallyVisible and @isExpanded
    currency: ->
      return @webjet.data?.currency
    symbolUrl: ->
      return 'https://www.tradingview.com/symbols/' + @currency
  methods:
    generateChartData: ->
      if @interval
        @options.interval = @interval
      @options.container_id = @containerId
      @options.symbol = @currency
      if @isVisible
        new TradingView.widget(@options)
    updateChartData: (key, value) ->
      @options[key] = value.toUpperCase()
      if @isVisible
        new TradingView.widget(@options)
  watch:
    interval: (v) ->
      if v
        @updateChartData('interval', v)
    isVisible: (v) ->
      if v
        @timeout = setTimeout =>
          @generateChartData()
        , 0
      else
        clearTimeout @timeout
