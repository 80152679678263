






import ContextMenu from '@/components/ContextMenu'

export default
  name: 'FlyContextMenu'
  inject: ['provideCanvas', 'provideBoardSrc']
  data: ->
    rect: null
    sideDropMenu: 'top'
  components: { ContextMenu }
  computed:
    screen: -> @provideCanvas.screen
    classes: ->
      result = {}
      if @sideDropMenu == 'bottom'
        result['drop-menu-bottom'] = true
      result
    selection: ->
      @$store.getters['webjet/selected']

    indexSelection: ->
      if @selection[@selection.length - 1]?.genShow
        return @selection.length - 1
      index = 0
      for w,i in @selection
        break if !w.genShow
        index = i
      index
    show: ->
      return false unless @selection.length
      return false unless @selection[0].provideBoardSrc == @provideBoardSrc
      return false unless @selection[@indexSelection].parentIsShow
      return false unless @selection[@indexSelection].genShow
      return !@selection[@indexSelection].dragInProgress

    left: ->
      if @provideCanvas.showContextMenu and @rect and @selection[@indexSelection].genShow
        cpx = @provideCanvas.position.x
        czx = @provideCanvas.zoom.dx
        ex1 = @selection[@indexSelection].elementSceneRect.x1
        ex2 = @selection[@indexSelection].elementSceneRect.x2
        scale = @provideCanvas.scale
        x = Math.round(cpx) + czx + ex1 * scale + (ex2 - ex1) / 2 * scale
        if x < (@rect.width + 40) / 2
          x = (@rect.width + 60) / 2
        if x > @screen.width - (@rect.width + 40) / 2
          x = @screen.width - (@rect.width + 60) / 2
        return "#{x}px"
    top: ->
      if @provideCanvas.showContextMenu and @rect and @selection[@indexSelection].genShow
        cpy = @provideCanvas.position.y
        czy = @provideCanvas.zoom.dy
        ey1 = @selection[@indexSelection].elementSceneRect.y1
        ey2 = @selection[@indexSelection].elementSceneRect.y2
        scale = @provideCanvas.scale
        offset = 40 * scale
        offset = 30 if offset < 30
        offset = 170 if offset > 170
        y = cpy + czy + ey1 * scale
        y = Math.round(y) - @rect.height - offset
        y = y + @rect.height + offset * 2 + (ey2 - ey1) * scale
        if y > @screen.height - @rect.height - offset
          # y = offset

          y = cpy + czy + ey1 * scale
          y = Math.round(y) - @rect.height - offset
        if y < 0
          y = 10


        if y > (@screen.height / 3)
          @sideDropMenu = 'top'
        else
          @sideDropMenu = 'bottom'

        return "#{y}px"

    positionContextMenu: ->
      return { left: @left, top: @top, bottom: 'auto' }

  methods:
    setElement: ->
      el = @selection[@indexSelection].$el
      rect = @selection[@indexSelection].$el.getBoundingClientRect()
      x = Math.round rect.x
      y = Math.round rect.y
      width = Math.round rect.width
      height = Math.round rect.height
      @el = { x, y, width, height, el }

    setRect: ->
      if @show and @selection[@indexSelection]?.genShow and @provideCanvas.showContextMenu and @selection[@indexSelection]?.parentIsShow
        @rect = @$refs.contextmenu.getBoundingClientRect()

    resetSelection: ->
      @$store.dispatch 'webjet/resetSelection'

  mounted: ->
    requestAnimationFrame(@setRect)

  updated: ->
    unless @rect
      @setRect()
