








































import { mdiChevronDown, mdiClose, mdiCheck } from '@mdi/js'
import { getOwnerUID,userDisplayName,userCheсkData } from '@/utils'
import Avatar from '@/components/Avatar.vue'
import BoardCover from '@/components/Board/BoardCover.vue'
import TeamGuestBoardItem from './TeamGuestBoardItem'
export default
  name:
    "TeamCollaborationsItem"

  props:
    uid:
      {
        type: String,
        default: ''
      }
    role: String
    type: String
    boards: Object
  components:
    { Avatar, BoardCover, TeamGuestBoardItem }
  data: ->
    iconArrow: mdiChevronDown
    iconRemove: mdiClose
    iconCheck: mdiCheck
  computed:
    plan: ->
      plan = @firebind "/teamAccess/#{@context.teamId}/plan"
      if plan.$value == null
        return 'free'
      else
        plan.$value
    userName: ->
      if @uid == @$store.state.uid
        return @$t('common.you')
      else
        userDisplayName @uid
    userEmail: ->
      mail = @firebind "/users/#{@uid}/email"
      mail = mail?.$value
      if mail
        return mail
    user: ->
      @firebind "/users/#{@uid}/"
    owner: ->
      @role == "owner"
    canEdit: ->
      if @role in ['admin', 'member'] and @uid != @$store.state.uid and !@owner
        return true
      else
        return false
    roles: ->
      @firebind "/teamAccess/#{@context.teamId}/roles"
    guestBoardsLength: ->
      Object.keys @boards
    boardsUsers: ->
      @firebind "/teamAccess/#{@context.teamId}/boards"
    guests: ->
      result = {}
      for k,v of @boardsUsers
        if k != '*' and !@roles?[k]
          result[k] = v
      return result
    guestWithEdit:->
      members = []
      for k,v of @boardsUsers
        if v and k != '*'
          for b,r of v
            if r == 'edit'
              members.push k
              break
      members
    membersInTeam: ->
      memebers = Object.keys @roles
      memebers
    allmembersInTeam: ->
      @membersInTeam.length + @guestWithEdit.length
    teamSize: ->
      teamSizeData = @firebind("/teamBilling/#{@context.teamId}/teamSize")
      teamSize = teamSizeData?.$value
      return 3 if teamSize == null
      teamSize
    limitIsOver: ->
      #if !@teamNoLimits and @allmembersInTeam >= @teamSize
      #  return true
      false
    teamNoLimits: ->
      val = @firebind("/teamBilling/#{@context.teamId}/nolimits")
      val.$value
  methods:
    removeFromAllBoards: ->
      for k, v of @boards
        @$store.dispatch 'team/removeUserFromBoard',
          teamId: @context.teamId
          boardId: k
          userId: @uid
    changeTeamRole: (role) ->
      @$store.dispatch 'team/updateTeamRole',
        teamId: @context.teamId
        userId: @uid
        role: role
    removeFromTeam: ->
      @$store.dispatch 'team/removeUserFromTeam',
        teamId: @context.teamId
        userId: @uid
