




import { debounce } from '@/utils'
export default
  name: 'DropZoneCancel'
  inject: ['provideCanvas']
  data: ->
    active: false
  created: ->
    @debouncedDisable = debounce @disable, 200
  methods:
    disable: ->
      @active = false
    dragover: (e)->
      @active = true
      @debouncedDisable()
      e.preventDefault()
      e.stopPropagation()
    drop: (e)->
      e.preventDefault()

