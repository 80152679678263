





























import { usualMixinPack } from './mixins'
import { generateSort } from '@/utils'

import Avatar from '@/components/Avatar.vue'

export default
  name: 'WebjetVoice'
  components: { Avatar }
  mixins: [...usualMixinPack]
  mounted: ->
    @Surfer = await import(### webpackChunkName: "wavesurfer" ###'wavesurfer.js')
    @initSerfer() if @isVisible
  data: ->
    Surfer: null
    wavesurfer: null
    isLoaded: false
    status: 'play'
    currentTime: 0
  computed:
    peaks: ->
      @webjet?.data?.peaks
    transcription: ->
      @webjet?.data?.transcription
    url: ->
      @webjet?.data?.url
    duration: ->
      @webjet?.data?.duration
    author: ->
      @webjet?.data?.author
    titlePlaceholder: ->
      @$t("webjet.voice.title")
    timeline: ->
      if @currentTime < 10
          seconds =  '0' + @currentTime
        else if @currentTime == 60
          seconds = '00'
        else
          seconds = @currentTime

        minutes = if @currentTime < 60 then '0' else '1'
        minutes + ':' + seconds
    isVisible: ->
      if @provideWebjet
        rootWebjet = @provideWebjet
        while rootWebjet.provideWebjet
          rootWebjet = rootWebjet.provideWebjet
        return rootWebjet.isTheoreticallyVisible and @isExpanded
      else
        return @isTheoreticallyVisible and @isExpanded
  methods:
    initSerfer: ->
      @wavesurfer = @Surfer.default.create
        container: @$el.querySelector '.wavesurfer'
        height: 40
        barRadius: 2
        barWidth: 2
        barMinHeight: 4
        cursorWidth: 0
        waveColor: '#FFF'
        progressColor: '#0a0a0a'

      @wavesurfer.backend.setPeaks(JSON.parse @peaks or null, @duration);
      @wavesurfer.drawBuffer()

      @wavesurfer.on 'ready', =>
        if not @peaks
          @$store.dispatch 'webjet/setVoiceGraphPeaks',
            path: @webjet.$path
            peaks: JSON.stringify @wavesurfer.backend.mergedPeaks
        @play()

      @wavesurfer.on 'finish', => @stop()
      @wavesurfer.on 'audioprocess', =>
        @currentTime = Math.floor @wavesurfer.getCurrentTime()
    togglePlay: ->
      return if !@url
      return @load() unless @isLoaded
      if @status == 'play' then @play() else @stop()
    play: ->
      @wavesurfer.play()
      @status = 'stop'
    stop: ->
      @wavesurfer.stop()
      @status = 'play'
    load: ->
      @status = 'loading'
      @wavesurfer.load @url
      @isLoaded = true
    transcribe: ->
      @status = 'transcribing'

      if @transcription
        @createCard @transcription
      else
        fetch('https://service.speech.stage.weje.io/transcribe',
          method: 'POST'
          headers: 'Content-Type': 'application/json'
          body: JSON.stringify(url: @url)
        ).then((res) ->
          res.json()
        ).then(({ transcription }) =>
          @$store.dispatch 'webjet/setVoiceTranscription',
            path: @webjet?.$path,
            transcription: transcription
          @createCard transcription
        ).catch (error) =>
          console.warn error
          if @provideBoardSrc == 'inventory'
            @createCard "Unable to transcribe audio"
          else
            @provideCanvas.showCaution
              message: "Unable to transcribe audio"
              loader: false

          @status = 'play'

    createCard: (transcription) ->
      provideWebjet = @provideWebjet
      webjetComponent = @webjet
      hasCanvas = @provideCanvas
      inInventory = @provideBoardSrc == 'inventory'
      provideWebjetIsInventory = provideWebjet?.$options?.name == 'WebjetInventory' if provideWebjet
      boardPath = if inInventory then 'inventory' else hasCanvas.data?.$path
      dest = @webjet?.$path
      if provideWebjet and !provideWebjetIsInventory and provideWebjet?.webjet?.category != '/webjets/containers/webjets/list'
        dest = provideWebjet?.webjet?.$path
        connections = []
        group = 'items'
        if provideWebjet.webjet.connections? and provideWebjet?.webjet?.connections[group]
          connections.push(c) for k, c of provideWebjet?.webjet?.connections[group]

        for c in connections
          if c.src.includes @webjet.$path
            after = c.sortKey

        # after = webjetComponent?.connection?.sortKey
        before = false
        for c in connections
          if Number.isFinite(c.sortKey) and c.sortKey > after
            if !before or c.sortKey < before
              before = c.sortKey
        sort = generateSort(provideWebjet.webjet, before)
      else if !provideWebjet and !provideWebjetIsInventory and provideWebjet?.webjet?.category != '/webjets/containers/webjets/list'
        connections = []
        group = 'items'
        if webjetComponent.connections?
          connections.push(c) for k, c of webjetComponent?.connections[group]
        before = false
        for c in connections
          if Number.isFinite(c.sortKey) and c.sortKey <= 0
            if !before or c.sortKey < before
              before = c.sortKey
        sort = generateSort(webjetComponent, before)
      if @provideCanvas
        connection =
          {
          coordinates: @provideCanvas.getEmptyCoordinates
            x: @provideCanvas.sceneX + @provideCanvas.sceneWidth / 2 - 150
            y: @provideCanvas.sceneY + @provideCanvas.sceneHeight / 2 - 50
          }
      else
        connection = {}
      connection = Object.assign(connection, sort) if sort
      result = await @$store.dispatch 'webjet/create',
        category: '/webjets/content/webjets/note'
        dest: dest
        webjet:
          data:
            text: transcription
        boardPath: boardPath
        connection: connection
      @status = 'play'
  beforeDestroy:->
    @stop() if @status == 'stop'
  watch:
    isVisible: (v) ->
      if v and @Surfer
        @$nextTick =>
          @initSerfer()
      else
        @stop() if @status == 'stop'
        @wavesurfer?.destroy()
        @isLoaded = false
    status: (v) ->
      if v == 'transcribing' or v == 'loading'
        @wavesurfer.setHeight 0
      else
        @wavesurfer.setHeight 40
