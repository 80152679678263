import { generateSort } from '@/utils'

export default
  data: ->
    selection: { active: false, x1: 0, y1: 0, x2: 0, y2: 0 }
    selectionRelations: false

  listeners: ->
    mousedown: @selectionMousedown
    mousemove: @selectionMousemove
    mouseup: @selectionMouseup
    tap: @selectionTap

  computed:
    selectionRelationsAvtive: ->
      return false unless @selectionRelations and @selected.length
      return @selectionRelations

    selectionStyles: ->
      { x1, y1, x2, y2 } = @normalizeSelection()
      return
        left: "#{x1}px"
        top: "#{y1}px"
        width: "#{x2 - x1}px"
        height: "#{y2 - y1}px"

    selected: -> @$store.getters['webjet/selected']

  methods:
    normalizeSelection: ->
      if @selection.x1 <= @selection.x2
        x1 = @selection.x1
        x2 = @selection.x2
      else
        x1 = @selection.x2
        x2 = @selection.x1
      if @selection.y1 <= @selection.y2
        y1 = @selection.y1
        y2 = @selection.y2
      else
        y1 = @selection.y2
        y2 = @selection.y1
      return { x1, y1, x2, y2 }

    isSelectionInsideWebjet: (webjet, selection)->
      { x1, y1, x2, y2 } = selection
      rect = webjet.elementRect
      result = false
      if x1 > rect.x1 and x2 < rect.x2 and y1 > rect.y1 and y2 < rect.y2
        result = true
      result

    isSelectionIntersectWebjet: (webjet, selection)->
      { x1, y1, x2, y2 } = selection
      rect = webjet.elementRect
      result = false
      if !(rect.x1 > x2 or rect.x2 < x1 or rect.y1 > y2 or rect.y2 < y1)
        result = true
      if result and webjet.isIntersectSceneRect
        result = webjet.isIntersectSceneRect(selection)
      result

    selectionCalcContext: (context, selection)->
      webjets = context.childWebjetComponents
      if context == this
        webjets = webjets.filter (w)-> w.isTheoreticallyVisible

      # disable selection on anchor webjet type iframe
      webjets = webjets.filter (w)->
        if w.webjet.category == 'anchor' and w.type == 'iframe'
          return false
        return true
      insideWebjets = []
      intersectWebjets = []
      for w in webjets
        insideWebjets.push w if @isSelectionInsideWebjet w, selection
        intersectWebjets.push w if @isSelectionIntersectWebjet w, selection
      result = []
      if insideWebjets.length == 1 and intersectWebjets.length == 1
        contextWebjet = intersectWebjets[0]
        rect = contextWebjet.elementRect
        newSelection =
          x1: selection.x1 - rect.x1
          x2: selection.x2 - rect.x1
          y1: selection.y1 - rect.y1
          y2: selection.y2 - rect.y1
        result = @selectionCalcContext contextWebjet, newSelection
      else
        if intersectWebjets.length == 0 and context != this
          result = [context]
        else
          result = intersectWebjets
      result = result.filter (w)-> w.isSelectable and w.canSelect
      result

    selectionCalc: ()->
      selection = @normalizeSelection()
      result = @selectionCalcContext this, selection
      result

    selectionMousedown: (e)->
      @clickWithoutMove = true
      @selectionMouseClickX = e.clientX
      @selectionMouseClickY = e.clientY
      if e.shiftKey
        x = e.sceneX #@scene.x1 + e.x / @scale
        y = e.sceneY #@scene.y1 + e.y / @scale
        @selection = { active: true, x1: x, x2: x, y1: y, y2: y }
        webjets = @selectionCalc()
        @$store.dispatch 'webjet/relation/setSelected', { webjet: null  }
        @$store.dispatch 'webjet/setPreselected', { webjets }
      return

    selectionMousemove: (e)->
      @clickWithoutMove = false if e.clientX != @selectionMouseClickX or e.clientY != @selectionMouseClickY
      if @selection.active
        if e.which == 0
          # Mouseup was outside window or similar
          @selectionMouseup(e)
          return
        @selection.x2 = e.sceneX #@scene.x1 + e.x / @scale
        @selection.y2 = e.sceneY #@scene.y1 + e.y / @scale
        webjets = @selectionCalc()
        @$store.dispatch 'webjet/setPreselected', { webjets }
      return

    selectionMouseup: (e)->
      if @selection.active
        @$store.dispatch 'webjet/preselectedToNowselected',
          invert: @clickWithoutMove
        @$store.dispatch 'webjet/relation/setSelected', { webjet: null  }
        @selection = { active: false, x1: 0, y1: 0, x2: 0, y2: 0 }
      else
        if @clickWithoutMove and e.which == 1
          if e.target.matches('.canvas,.scene,.paste-layer')
            @$store.dispatch 'webjet/resetSelection'
            @$store.dispatch 'webjet/relation/setSelected', { webjet: null  }
            if @relationPlusPoint.active
              @relationPlusPoint.active = false
      return

    selectionCentralize: ()->
      if @$store.getters['webjet/selected'].length == 1
        webjet = @$store.getters['webjet/selected'][0]
        rect = webjet.elementSceneRect
        x = (rect.x2 + rect.x1) / 2
        y = (rect.y2 + rect.y1) / 2
        @sceneMoveCenterTo(x, y)
      else if @$store.getters['webjet/selected'].length > 1
        console.warn 'selectionCentralize: multiple selected not implemented'
      else
        console.warn 'selectionCentralize: no selected'

    selectionTap: (e)->
      if e.target.matches('.canvas,.scene,.paste-layer')
        @$store.dispatch 'webjet/resetSelection'
        @$store.dispatch 'webjet/relation/setSelected', { webjet: null  }
        if @relationPlusPoint.active
          @relationPlusPoint.active = false
        e.stopPropagation()

    selectCreatedWebjets: (createResults, centralize, activateEditor = true)->
      setTimeout =>
        webjets = []
        for item in createResults
          if item?.webjetPath?
            w = @getWebjetComponentBySrc item.webjetPath
            webjets.push w if w?
        await @$store.dispatch 'webjet/setNowselected', { webjets: webjets }
        await @$store.dispatch 'webjet/setPreselected', { webjets: [] }
        await @$store.dispatch 'webjet/relation/setSelected', { webjet: null }

        if centralize
          @selectionCentralize()

        if FLAG.EDIT_ON_CREATE and activateEditor and createResults?.length == 1 and webjets.length == 1 and webjets[0].$options.name=='WebjetCard'
          webjets[0].$refs?.editor?.activateEditor()

        if createResults?.length == 1 and webjets.length == 1 and webjets[0].$options.name=='WebjetChecklist'
          webjets[0]?.createItemWithoutEvent()
        if createResults?.length == 1 and webjets.length == 1 and webjets[0].$options.name=='WebjetGraffiti'
          webjets[0]?.$refs.editor.activateEditor()

    selectDataForCreateInSelection: (category) ->
      boardPath = @data.$path
      connection =
        coordinates: @getEmptyCoordinates
          x: @sceneX + @sceneWidth / 2 - 150
          y: @sceneY + @sceneHeight / 2 - 150
      defaultData =
        dest: @data.$path
        boardPath: @data.$path
        connection: connection

      if @selected.length != 1 or !category
        dest = boardPath = @data.$path
        return defaultData
      else
        webjetComponent = @selected[0]
        return defaultData unless webjetComponent?.canDroppable
        provideWebjet = webjetComponent.provideWebjet
        if webjetComponent.$options.name == 'WebjetList'
          connections = []
          group = 'items'
          if webjetComponent.webjet.connections? and webjetComponent.webjet.connections[group]
            connections.push(c) for k, c of webjetComponent.webjet.connections[group]
          columnKey = null
          for c in connections
            connectionColumnKey = 0
            connectionColumnKey = c.columnKey if c.columnKey
            if columnKey == null or connectionColumnKey < columnKey
              columnKey = connectionColumnKey
          sort = generateSort(webjetComponent.webjet, false)
          connection = Object.assign connection, sort
          connection.columnKey = columnKey if columnKey

          return {dest: webjetComponent.webjet.$path, boardPath, connection}
        else if provideWebjet
          if provideWebjet.isContainer
            if provideWebjet.$options.name == 'WebjetList'
              columnKey = null
              if webjetComponent.connection.columnKey
                columnKey = webjetComponent.connection?.columnKey

              connections = []
              group = 'items'
              if provideWebjet.webjet.connections? and provideWebjet.webjet.connections[group]
                for k, c of provideWebjet.webjet.connections[group]
                  if columnKey and c.columnKey == columnKey
                    connections.push(c)
                  else
                    connections.push(c)
              after = webjetComponent?.connection?.sortKey
              before = false
              for c in connections
                if Number.isFinite(c.sortKey) and c.sortKey > after
                  if !before or c.sortKey < before
                    before = c.sortKey
              sort = generateSort(provideWebjet.webjet, before)
              connection = Object.assign {}, sort
              connection.columnKey = columnKey
              return {dest: provideWebjet.webjet.$path, boardPath, connection}

          else
            return defaultData if category == '/webjets/containers/webjets/list'
            connections = []
            group = 'items'
            if provideWebjet.webjet.connections? and provideWebjet.webjet.connections[group]
              connections.push(c) for k, c of provideWebjet.webjet.connections[group]
            after = webjetComponent?.connection?.sortKey
            before = false
            for c in connections
              if Number.isFinite(c.sortKey) and c.sortKey > after
                if !before or c.sortKey < before
                  before = c.sortKey
            sort = generateSort(provideWebjet.webjet, before)
            connection = Object.assign {}, sort
            return {dest: provideWebjet.webjet.$path, boardPath, connection}

        else
          return defaultData if category == '/webjets/containers/webjets/list'
          sort = generateSort(webjetComponent.webjet, false)
          connection = Object.assign {}, sort
          return {dest: webjetComponent.webjet.$path, boardPath, connection}