





















import { usualMixinPack } from './mixins'

OPENGRAPH_KEY = "59ca7a4a8452ed6e76bef7c0"

export default
  name: 'WebjetLink'
  mixins: [...usualMixinPack]
  data: ->
    imageLoaded: false
    imageError: false
  computed:
    data: ->
      @webjet?.data||{}
    meta: ->
      @webjet?.meta||{}
    icon: ->
      @webjet?.data?.icon
    genTitle: ->
      @webjet.data?.title || @titlePlaceholder

  methods:
    loadData: (refresh = false) ->
      #console.log '!link load data'
      fetch(
        "https://opengraph.io/api/1.1/site/#{encodeURIComponent(@data.url)}?app_id=#{OPENGRAPH_KEY}&full_render=true&cache_ok=#{!refresh}"
      ).then(
        (res) =>
          #console.log res
          return unless res
          if res.ok
            return res.json()
          else
            console.error "opengraph error status", res.status, res.statusText
      ).then(
        (res) =>
          #console.log res
          return unless res
          if res.hybridGraph.url?.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i)
            url = res.hybridGraph.url
          else
            url = @data.url
          site = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i)[2]
          icon = res.hybridGraph.favicon || "https://www.google.com/s2/favicons?domain=#{site}"
          update =
            titleOrigin: res.hybridGraph.title||null
            image:       res.hybridGraph.image||null
            description: res.hybridGraph.description||null
            site:        site
            icon:        icon
          #console.log '>', @data, update
          @$store.dispatch 'webjet/refreshData',
            path: @connection.src
            data: update
      )

    onImageLoaded: (e)->
      @imageLoaded = true
      @imageError = false

    onImageError: (e)->
      #console.log '!image error', e
      # In case of image load error try refresh image via opengraph.io call to obtain fresh access token, etc.
      # Attempt refresh once per owning user session (@imageError flag), and not early than hour (3600000ms) since @meta.refreshDate
      if (
        !@imageError and @meta.refreshDate and
        not (@meta.isInStorage || @meta.uploadInProgress || @meta.uploadError) and
        (new Date()).getTime() - (new Date @meta.refreshDate).getTime() > 3600000 and
        @meta.createUser == @$store.state.uid
      )
        #console.log '!refresh'
        @imageError = true
        @loadData(true)

  mounted: ->
    #console.log '!link mounted', @connection.src, @meta.refreshDate, @meta
    if (
      !@meta.refreshDate and
      not (@meta.isInStorage || @meta.uploadInProgress || @meta.uploadError) and
      (new Date @meta.createDate).getTime() > 1618185600000 and
      @meta.createUser == @$store.state.uid
    )
      # 1618185600000 is "2021-04-12T00:00:00.000Z", date of first weje release
      # links from old webjets app had no refreshDate, so we need exclude them this way
      setTimeout => @loadData()
