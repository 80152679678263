






import { userColor } from '@/utils'

export default
  name: 'AvatarTeam'
  props:
    team: String

  computed:
    teamData: ->
      @firebind "/teams/#{@team}"

    dataTitle: ->
      return unless @team
      t = @firebind("/teams/#{@team}/title")
      t?.$value

    title: ->
      if @dataTitle
        @dataTitle
      else if @team and @team.startsWith 'team_'
        uid = @team.split('_')[1]
        firstUserName = @firebind '/users/'+uid+'/firstName'
        lastUserName = @firebind '/users/'+uid+'/lastName'
        email = @firebind '/users/'+uid+'/email'
        "#{firstUserName.$value || lastUserName.$value || email.$value?.split('@')[0] }'s team"
      else
        '  '

    hasAvatar: ->
      if @team
        icon = @firebind "/teams/#{@team}/icon"
        url = icon.$value
        return url if url
        if @team.startsWith 'team_'
          uid = @team.split('_')[1]
          avatar = @firebind "/users/#{uid}/avatar"
          if avatar.$ready and avatar.$value
            return avatar.$value
      false

    avatar: ->
      @hasAvatar

    styles: ->
      color = userColor(@team)
      return
        '--user-color': color


    initials: ->
      a = @title.split(' ')[0]
      b = @title.split(' ')[1]
      if a and b
        a.charAt(0) + b.charAt(0)
      else
        (a||b||'  ').substr(0,2)

