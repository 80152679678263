

















import { usualMixinPack } from './mixins'

export default
  name: 'WebjetVideo'
  mixins: [...usualMixinPack]
  data: ->
    aspectRatio: null
  computed:
    url: ->
      @webjet?.data?.url
    icon: ->
      @webjet?.data?.icon
    videoStyles: ->
      if @aspectRatio
        return
          position: 'absolute'
          width: '100%'
          height: '100%'
          top: '0'
    videoContainerStyles: ->
      if @aspectRatio
        return
          position: 'relative'
          width: '100%'
          height: '0px'
          'padding-top': @aspectRatio + '%'

  methods:
    onVideoLoaded: (e)->
      @setAspectRatio(e.target) unless @aspectRatio

    setAspectRatio: (el) ->
      @aspectRatio = (el.clientHeight / el.clientWidth) * 100
      # force refresh topmost container
      topParent = @provideWebjet
      if topParent
        while topParent.provideWebjet
          topParent = topParent.provideWebjet
        topParent.contentLoadedUpdate()

  watch:
    isTheoreticallyVisible: (v) ->
      if v and not @aspectRatio
        setTimeout =>
          @setAspectRatio(@$refs.video)
