import { getPath } from '@/utils'
export default
  data: ->
    delayedInit: true
    allowWebjetInit: {}
    childWebjetComponents: []
    #webjetComponents: []
    #webjetComponentsById: {}
    webjetComponentsBySrc: {}
    webjetComponentInAnchor: {}


  created: ->
    @nrWebjetComponentsBySrc = @webjetComponentsBySrc # for non reactive access
    @nrWebjetComponentInAnchor = @webjetComponentInAnchor
    setTimeout (=> @delayedInit = false), 1
    @nrAllowWebjetInit = @allowWebjetInit # for non reactive access
    @runWebjetInit()

  computed:
    paintWebjets: ->
      return unless @paints?.items
      @paints.items
    webjets: ->
      return unless @connections?.items
      if !FLAG.CANVAS_WEBJET_INIT and @delayedInit
        result = []
        for key, item of @connections.items
          x = item.coordinates?.x || 0
          y = item.coordinates?.y || 0
          if x < ( @sceneX + @sceneWidth ) and y < ( @sceneY + @sceneHeight )
            result.push item
      else
        result = @connections.items
      result

  methods:
    isAllowWebjetInit: (path)->
      result = @nrAllowWebjetInit[path]
      if result == undefined
        initValue = false
        connection = @firebind path
        x = connection.coordinates?.x || 0
        y = connection.coordinates?.y || 0
        if x < @scene.x2 and y < @scene.y2 and x > (@scene.x1 - 2000) and y > (@scene.y1 - 2000)
          initValue = true
        # better check width
        # check relations?
        @$set @nrAllowWebjetInit, path, initValue
        result = @nrAllowWebjetInit[path]
        if !initValue and !@webjetInitInProgress
          @runWebjetInit()
      result

    tickWebjetInit: ()->
      # TODO: check if scene changed from last tick. If yes force check false values
      # or init closest webjets to scene
      @webjetInitInProgress = true
      for k, v of @allowWebjetInit
        unless v
          @allowWebjetInit[k] = true
          setTimeout @tickWebjetInit, 0
          return
      @webjetInitInProgress = false

    runWebjetInit: ()->
      unless @webjetInitInProgress
        @webjetInitInProgress = true
        setTimeout @tickWebjetInit

    registerChildWebjet: (component)->
      @childWebjetComponents.push component

    unregisterChildWebjet: (component)->
      @childWebjetComponents = @childWebjetComponents.filter (c)-> c!=component

    registerWebjet: (component)->
      #@webjetComponents.push component
      connection = component.connection
      unless connection.$ready
        await connection
      #@$set @webjetComponentsById, connection.id, component
      src = connection.src or connection.$path
      @$set @webjetComponentsBySrc, getPath(src), component

    unregisterWebjet: (component)->
      #@webjetComponents = @webjetComponents.filter (c)-> c!=component
      #@$set @webjetComponentsById, connection.id, null
      for k, w of @webjetComponentsBySrc
        if w == component
          @$set @webjetComponentsBySrc, k, null

    #getWebjetComponentById: (id)->
    #  unless @webjetComponentsById[id]
    #    @$set @webjetComponentsById, id, null
    #  @webjetComponentsById[id]

    getWebjetComponentBySrc: (src)->
      src = getPath src
      unless @nrWebjetComponentsBySrc[src]
        @$set @nrWebjetComponentsBySrc, src, null
      @nrWebjetComponentsBySrc[src]


    setWebjetsComponentInAnchor: (webjets) ->

      for k, w of @webjetComponentInAnchor

        unless w in webjets
          @$set @webjetComponentInAnchor, k, null
      for w in webjets
        connection = w.connection
        unless connection.$ready
          await connection
        @$set @webjetComponentInAnchor, getPath(w.src), w


    getWebjetComponentInAnchor: (src) ->
      src = getPath src
      unless @nrWebjetComponentInAnchor[src]
        @$set @nrWebjetComponentInAnchor, src, null
      @nrWebjetComponentInAnchor[src]

    # checks coordinates for a new webjet, returns same or better coordinates
    # to avoid lot of new webjets created at same central point of the screen
    getEmptyCoordinates: (coordinates)->
      x = Math.round(coordinates.x / 20) * 20
      y = Math.round(coordinates.y / 20) * 20
      for w in @childWebjetComponents
        wx = Math.round(w.x / 20) * 20
        wy = Math.round(w.y / 20) * 20
        if x == wx and y == wy
          return @getEmptyCoordinates
            x: coordinates.x + 40
            y: coordinates.y + 40
        else if y == wy and x > wx and x < w.elementRect.x2
          return @getEmptyCoordinates
            x: coordinates.x + 40
            y: coordinates.y + 40
        else if x == wx and y > wy and y < w.elementRect.y2
          return @getEmptyCoordinates
            x: coordinates.x + 40
            y: coordinates.y + 40
      return coordinates
