





































































































































































export default
  name: 'RelationTools'
  inject: ['provideCanvas']
  data: ->
    rect: null
    sideDropMenu: 'top'
    hiddenBlocks: []
  computed:
    relation: ->
      @$store.getters['webjet/relation/selected']
    relationSrc: ->
      @relation.src
    width: ->
      @relation.pathWidth
    style: ->
      @relation.pathStyle
    themeClass: ->
      @relation.themeableClass
    toolsPosition: ->
      relationCentralPoint = @relation.pointPosition(3)
      scale = @provideCanvas.scale
      cp = @provideCanvas.position
      cz = @provideCanvas.zoom
      offset = (30 + @width) * scale
      rcpX =  relationCentralPoint.x * scale + cp.x + cz.dx
      rcpY =  relationCentralPoint.y * scale + cp.y + cz.dy + offset

      p1 = @relation.pointPosition(1)
      p1.x = p1.x * scale + cp.x + cz.dx
      p1.y = p1.y * scale + cp.y + cz.dy
      p2 = @relation.pointPosition(2)
      p2.x = p2.x * scale + cp.x + cz.dx
      p2.y = p2.y * scale + cp.y + cz.dy
      p3 = @relation.pointPosition(3)
      p3.x = p3.x * scale + cp.x + cz.dx
      p3.y = p3.y * scale + cp.y + cz.dy

      if @rect
        rectWidth = @rect.width
        rectCenterX = (rectWidth + 40) / 2
        if rcpX < rectCenterX
          rcpX = rectCenterX
        if rcpX > @$vuetify.breakpoint.width - rectCenterX
          rcpX = @$vuetify.breakpoint.width - rectCenterX

        rectHeight = @rect.height

        if rcpY > @$vuetify.breakpoint.height - @rect.height - offset

          rcpY = relationCentralPoint.y * scale + cp.y + cz.dy - offset - rectHeight

          if rcpY > @$vuetify.breakpoint.height - 70
            rcpY = @$vuetify.breakpoint.height - 70
        if rcpY < 0
          @sideDropMenu = 'bottom'
          rcpY = 20

        if rcpY > 150
          @sideDropMenu = 'top'
        else
          @sideDropMenu = 'bottom'

        ci = @chekImposition rectWidth + 40,rectHeight,rcpX,rcpY,p1,p2,p3
        if ci.chek and ci.p1 or ci.p2 and ci.chek
          minY = Math.min(p1.y, p2.y, p3.y)
          maxY = Math.max(p1.y, p2.y, p3.y)
          centerY = p1.y + p2.y + p3.y - minY - maxY
          if ci.p1 and ci.p2
            if rcpY > @$vuetify.breakpoint.height - @rect.height - 100
              rcpY = minY - 60
            else
              rcpY = maxY + 20
          else
            if centerY >= @$vuetify.breakpoint.height / 2
              rcpY = centerY - 60
              ci = @chekImposition rectWidth + 40,rectHeight,rcpX,rcpY,p1,p2,p3
              if ci.chek or rcpY > @$vuetify.breakpoint.height - @rect.height - 100
                rcpY = minY - 60

            else
              rcpY = centerY + 20
              ci = @chekImposition rectWidth + 40,rectHeight,rcpX,rcpY,p1,p2,p3
              if ci.chek or rcpY < 80
                rcpY = maxY + 20

      rcpX = "#{rcpX}px"
      rcpY = "#{rcpY}px"
      return { left: rcpX, top: rcpY, bottom: 'auto' }

    title:
      get: ->
        # at this point title is only title set by user, all other options goes to titlePlaceholder
        @relation.title
      set: (value)->
        return if value.length > 25
        @$store.dispatch 'webjet/relation/setTitle',
          path: @relationSrc
          title: value
    firstPointVisualStyle: ->
      if FLAG.RELATION_COMPONENT_OPTIMIZATION
        return @relation.pointVisualStyle1
      else
        @relation.pointVisualStyle 1
    secondPointVisualStyle: ->
      if FLAG.RELATION_COMPONENT_OPTIMIZATION
        return @relation.pointVisualStyle2
      else
        @relation.pointVisualStyle 2
    pointsOrderRevers: ->
      @relation.pointsOrderRevers()
  methods:
    editTitle: ->
      relationCentralPoint = @relation.pointPosition(3)
      if (
        relationCentralPoint.x < @provideCanvas.scene.x1+20 || relationCentralPoint.x > @provideCanvas.scene.x2-20 ||
        relationCentralPoint.y < @provideCanvas.scene.y1+5 || relationCentralPoint.y > @provideCanvas.scene.y2-80
      )
        @provideCanvas.sceneMoveCenterTo relationCentralPoint.x, relationCentralPoint.y
      @relation.activeEditTitle = true
    chekImposition: (width,height,x,y,p1,p2,p3) ->
      scale = @provideCanvas.scale
      cp = @provideCanvas.position
      cz = @provideCanvas.zoom
      result = {chek: false, p1: false, p2:false, p3: false}

      if p1.x > x - width/2 and p1.x < x + width/2
          if p1.y > y - 20 and p1.y < y + height + 20
            result.chek = true
            result.p1 = true
      if p2.x > x - width/2 and p2.x < x + width/2
        if p2.y > y - 20  and p2.y < y + height + 20
          result.chek = true
          result.p2 = true
      if p3.x > x - width/2 and p3.x < x + width/2
        if p3.y > y - 20  and p3.y < y + height + 20
          result.chek = true
          result.p3 = true
      return result
    setTheme: (theme) ->
      @$store.dispatch 'webjet/relation/setTheme',
        path: @relationSrc
        theme: theme
      @$set @provideCanvas.lastRelationConfig, 'backgroundId', String(theme)
    setWidth: (width) ->
      @$store.dispatch 'webjet/relation/setWidth',
        path: @relationSrc
        width: width
      @$set @provideCanvas.lastRelationConfig, 'width', width
    setStyle: (style) ->
      @$store.dispatch 'webjet/relation/setStyle',
        path: @relationSrc
        style: style
      @$set @provideCanvas.lastRelationConfig, 'style', style
    setPointStyle: (point, style) ->
      @$store.dispatch 'webjet/relation/setPointStyle',
        path: @relationSrc
        point: point
        style: style
      @$set @provideCanvas.lastRelationConfig, '2', {style: style}
    remove: ->
      @$store.dispatch 'webjet/relation/remove', { path: @relationSrc }
      @$store.dispatch 'webjet/relation/setSelected', { webjet: null }

    hotkeyBlocker: (e) ->
      if e.type == 'keydown' && (e.keyCode == 13 || e.keyCode == 27)
        e.target.blur() # blur on enter and escape
      # so far we just block key events from title input to not interfere with app hotkeys
      # later some logic can be implemented
      e.stopPropagation()
      # block ctrl +/-/0
      if e.ctrlKey && e.keyCode in [ 187, 189, 48, 107, 109, 45, 43, 96 ]
        e.preventDefault()
    setRect: ->
      if @provideCanvas.showRelationTools
        @rect = @$refs.relationTools.getBoundingClientRect()
        @chekWidth()

    chekWidth: () ->
      blocksForHide = ['remove','style','width']
      width = @$refs.relationTools.clientWidth + 40
      windowWidth = @$vuetify.breakpoint.width
      tmp = []
      for el in blocksForHide
        width -= 40
        if width > windowWidth * 0.7
          tmp.push el
        else
          break
      @hiddenBlocks.splice 0, 0, ...tmp
  mounted: ->
    requestAnimationFrame(@setRect)

