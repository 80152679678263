












import { mdiChevronDown, mdiClose, mdiCheck } from '@mdi/js'
import { getOwnerUID,userDisplayName,userCheсkData } from '@/utils'
import Avatar from '@/components/Avatar.vue'
export default
  name:
    "SiteCollaborationUser"
  props:
    path: String
    uid:
      {
        type: String,
        default: ''
      }
    canEdit: Boolean
    owner:
      {
        type: Boolean,
        default: false
      }
  components:
    { Avatar }
  data: ->
    iconArrow: mdiChevronDown
    iconRemove: mdiClose
    iconCheck: mdiCheck
  methods:
    remove: ->
      if @uid
        await @$store.dispatch 'weje/sharePrivate',
          path: @path
          uid: @uid
          mode: false
  computed:
    showUser: ->
      userCheсkData @userId
    siteOwner: ->
      getOwnerUID @path
    userName: ->
      if @userId == @$store.state.uid
        return @$t('common.you')
      else
        userDisplayName @userId
    userEmail: ->
      mail = @userMail?.$value
      if mail
        return mail
    userId: ->
      id = if @owner then @siteOwner else @uid
      return id
  firebind:
    mYlicense: ->
      @path and !@owner and "#{@path}/public/#{@$store.state.uid}"
    userMail: ->
      @path and '/users/'+@userId+'/email'
