






























import { mdiClose, mdiChevronDown, mdiCheck, mdiContentCopy  } from '@mdi/js'
import { firebind, generateSort } from '@/utils'
import { sendAnalytics,userLimits } from '@/utils'
import Avatar from '@/components/Avatar.vue'
import DialogTemplate from '@/components/DialogTemplate'
import CreateBoardImg from './CreateBoardImg.vue'
import CreateBoardCollaborations from './CreateBoardCollaborations.vue'


export default
  name:
    "SpaceCreateDialog"
  props:
    value: Boolean
    defaultProject: String
  components: {Avatar, CreateBoardCollaborations, CreateBoardImg, DialogTemplate}
  provide: ->
    provideBoardCreateDialog: this
  data: ->
    iconArrow: mdiChevronDown
    iconCheck: mdiCheck
    iconCopy: mdiContentCopy
    icon: mdiClose
    name: ''
    loading: false
    selectProject: ''
  computed:
    projectItems: ->
      result = []
      projects = @$store.getters.availableEditProjects @context.teamId
      for p in projects
        projectsData = @firebind "/projects/#{p}"
        if !projectsData.title and p.startsWith('project_')
          title = 'Project 1'
        else
          title = if projectsData.title then projectsData.title else 'Untitled'
        result.push {text: title, value: p}
      result
    teamTitle: -> @$store.getters.teamTitle @context.teamId
    show:
      get: ->
        @value
      set: ->
        @$emit 'input', false
  watch:
    show: (val, oldVal) ->
      @$store.dispatch 'undoredo/reset'
  methods:
    createBoard: ->
      if FLAG.PROJECT
        return unless @selectProject
      boardName = @name.trim()
      boardName = @$t('board.placeholderNew') unless boardName
      @loading = true
      teamId = @context.teamId
      if FLAG.PROJECT
        projectId = @selectProject
      else
        projectId = @$store.getters.availableEditProjects(teamId)[0]
      { webjetKey, webjetPath } = await @$store.dispatch 'webjet/createBoard',
        board:
          data:
            title: boardName
          team: teamId
          project: projectId
      # console.log webjetKey, webjetPath
      if @$refs.collaborations?.selectedUser?.length
        for user in @$refs.collaborations.selectedUser
          await @$store.dispatch 'webjet/shareBoard',
            src: webjetPath
            uid: user.uid
            mode: user.license
          sendAnalytics 'user_invited_to_board',
            invited_user_id : user.uid.substring(0, 15)
            access_type: user.license
      img = @$refs?.boardImg?.imgFile
      if img
        uploadTask = await @$store.dispatch 'webjet/updateBoardImg',
          path: webjetPath
          file: img
      else
        uploadTask = true
      if uploadTask
        @loading = false
        @name = ''
        @close()
        @$router.push { path: "/b/#{webjetKey}" }
        sendAnalytics 'new_board_created',
          create_mode: 'empty'
    close: ->
      @$emit 'input', false

    hotkeyBlocker: (e) ->
      # allow undo/redo
      if e.ctrlKey && e.keyCode in [ 89, 90 ]
        return
      # block other hotkeys
      e.stopPropagation()
      # block ctrl +/-/0
      if e.ctrlKey && e.keyCode in [ 187, 189, 48, 107, 109, 45, 43, 96 ]
        e.preventDefault()
  mounted: ->
    if @defaultProject
      @selectProject = @defaultProject
    else if @projectItems[0]?.value
      @selectProject = @projectItems[0]?.value
