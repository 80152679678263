import { getRef, firebind } from '@/utils'

export default
  SET_FRESH_DATE: (state, { boardId, date })->
    console.log 'SET_FRESH_DATE', boardId, date
    getRef("freshDate/#{boardId}").set date

  UPDATE_FRESH: (state, { boardId, fresh, count, date })->
    #console.log 'FRESH: + ', boardId, count, date, fresh
    count = null if count == 0
    currentFresh = firebind "fresh/#{boardId}"
    currentFreshStr = Object.keys(currentFresh).sort().join(':')
    newFreshStr = Object.keys(fresh).sort().join(':')
    if !currentFresh.$sync or currentFreshStr != newFreshStr
      console.log 'FRESH: UPDATE', count, boardId
      getRef("fresh/#{boardId}").set fresh
      getRef("freshCount/#{boardId}").set count
    getRef("freshDate/#{boardId}").set date

  UNFRESH_WEBJET: (state, { boardId, webjetId, count })->
    count = null if count == 0
    getRef("fresh").child(boardId).child(webjetId).set null
    getRef("freshCount").child(boardId).set count

  UNFRESH_BOARD: (state, { boardId })->
    getRef("fresh").child(boardId).set null
    getRef("freshCount").child(boardId).set null

  DELETE_BOARD: (state, { boardId })->
    getRef("fresh").child(boardId).set null
    getRef("freshDate").child(boardId).set null
    getRef("freshCount").child(boardId).set null
