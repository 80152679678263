import { firebind } from '@/utils'
import { preloadBoard, findFreshWebjets, findAllWebjets } from './fresh-functions'

export default
  bootstrap:
    root: true
    handler: (context)->
      return unless FLAG.FRESH
      await firebind 'freshDate'
      await new Promise((resolve)=> setTimeout(resolve, 10))
      @watch (=> @getters.allBoards), (=> @dispatch 'fresh/watchBoards'), { immediate: true }
      @watch (=> @state.webjet.selection.nowselected), (selected)=>
        for w in selected
          toFresh = []
          if w.provideCanvas
            if w.isFresh
              toFresh.push w
            if !w.isContainer
              for sub in w.childWebjetComponents
                if sub.isFresh
                  toFresh.push sub
            ids = toFresh.map (w)-> w.webjet.$path.split('/').pop()
            for id in ids
              boardId = toFresh[0].provideCanvas.src
              @dispatch 'fresh/unfreshWebjet', { boardId, webjetId: id }

  watchBoards: ({ state })->
    console.log 'FRESH: watchBoards'
    return unless @getters.boardsSync
    if firebind('freshDate')?.$value == null
      console.log 'first fresh'
      await @dispatch 'fresh/firstRun'
    for board in @getters.allBoards
      unless state.boardWatchers[board]
        @dispatch 'fresh/addBoard', { boardId: board }

    # remove watchers for boards
    for k, v of state.boardWatchers
      unless k in @getters.allBoards
        console.log "FRESH: unsub board"
        state.boardWatchers[k]()
        delete state.boardWatchers[k]

    # Romove data for boards without access
    freshDate = firebind 'freshDate'
    if freshDate?.$sync
      for k, v of freshDate
        unless @getters.allBoards.includes k
          @commit 'fresh/DELETE_BOARD', { boardId: k }

  addBoard: ({ state }, { boardId })->
    dep = =>
      freshDate = firebind("freshDate")[boardId]
      updateDate = firebind("/boards/#{boardId}/meta/updateDate").$value
      "#{@state.connected},#{freshDate},#{updateDate}"
    fire = =>
      @dispatch 'fresh/fireBoard', { boardId }
    state.boardWatchers[boardId] = @watch dep, fire, { immediate: true }

  fireBoard: ({ state }, { boardId })->
    unless state.boardTimers[boardId]
      delay = 100
      delay = 1000 + Math.random() * 3000 if !@state.visibility or !@state.activity
      state.boardTimers[boardId] = setTimeout (=> @dispatch('fresh/watchBoard', { boardId })), delay

  watchBoard: ({ state }, { boardId })->
    #console.count 'watchBoard'
    state.boardTimers[boardId] = false
    return unless @state.connected
    freshDate = firebind("freshDate")[boardId]
    updateDate = firebind("/boards/#{boardId}/meta/updateDate").$value
    return unless freshDate
    return unless updateDate
    return if freshDate == updateDate
    # need compile
    fresh = firebind "fresh/#{boardId}"
    freshCount = firebind "freshCount"
    boardPreloaded = preloadBoard boardId
    if fresh.$sync and freshCount.$sync and boardPreloaded
      @dispatch 'fresh/compileBoard', { boardId, updateDate }
    else
      console.log 'FRESH: loading for compile', boardId
      @dispatch 'fresh/fireBoard', { boardId }

  compileBoard: ({}, { boardId, updateDate })->
    freshDate = firebind 'freshDate'
    fresh = firebind "fresh/#{boardId}"
    freshCount = firebind "freshCount"
    allWebjets = findAllWebjets "/boards/#{boardId}", true
    freshWebjets = findFreshWebjets "/boards/#{boardId}", freshDate[boardId], true
    newFresh = {}
    for k, v of fresh
      if allWebjets.includes k
        newFresh[k] = true
    for w in freshWebjets
      newFresh[w] = true
    newCount = Object.keys(newFresh).length
    newCount = null if newCount == 0
    @commit 'fresh/UPDATE_FRESH',
      boardId: boardId
      fresh: newFresh
      count: newCount
      date: updateDate



  firstRun: ()->
    console.log 'firstRun'
    freshDate = {}
    boards = @getters.allBoards
    for b in boards
      firebind "/boards/#{b}/meta/updateDate"
    for b in boards
      await firebind "/boards/#{b}/meta/updateDate"
    for b in boards
      updateDate = firebind("/boards/#{b}/meta/updateDate").$value
      @commit 'fresh/SET_FRESH_DATE', { boardId: b, date: updateDate }

  firstBoardVisit: ({}, { boardId })->
    updateDate = await firebind "/boards/#{boardId}/meta/updateDate"
    @commit 'fresh/SET_FRESH_DATE', { boardId, date: updateDate.$value }
