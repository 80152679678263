import { isSafari, isIOS } from '@/utils'

export default
  data: ->
    pinchInitialDistance: 0
    pinchInitialScale: 0
    pinchActive: false
  listeners: ->
    if isSafari
      {
        gesturestart: @pinchGesturestart
        gesturechange: @pinchGesturechange
        gestureend: @pinchGestureend
      }
    else
      {
        touchstart: @pinсhTouchstart
        touchmove: @pinсhTouchmove
        touchend: @pinchTouchend
        touchcancel: @pinchTouchend
      }

  methods:
    pinсhTouchstart: (e) ->
      pinch = e.touches?.length == 2
      if pinch
        e.preventDefault()
        @pinchActive = true
        @pinchInitialDistance = @pinchDistance(e)
        @pinchInitialScale = @scale
        @willChange = ''
        @transition = 'unset'
        #console.log '!pinch start', e, @pinchInitialDistance, @pinchInitialScale

    pinchTouchend: (e) ->
      if @pinchActive
        requestAnimationFrame => requestAnimationFrame =>
          @pinchActive = false
          #console.log '!pinch end', e
          e.preventDefault() if e.cancelable
          @willChange = 'transform'
          @transition = ''

    pinсhTouchmove: (e) ->
      pinch = e.touches?.length == 2
      if pinch
        e.preventDefault() if e.cancelable

        distance = @pinchDistance(e)
        scale = (distance / @pinchInitialDistance) * @pinchInitialScale
        level = Math.log(scale) / Math.log(1.2) # because `@scale: -> Math.pow 1.2, @zoom.level`
        level = -10 if level < -10
        level = 10 if level > 10
        #console.log '!pinch move', e, distance, 's:', @scale, scale, 'z:', @zoom.level, level

        scale = @scale
        ex = (e.touches[0].clientX + e.touches[1].clientX) / 2
        ey = (e.touches[0].clientY + e.touches[1].clientY) / 2
        x = ex - @x
        y = ey - @y
        @zoom.level = level # this leads to new @scale calculated
        @zoom.dx += (x / @scale - x / scale) * @scale
        @zoom.dy += (y / @scale - y / scale) * @scale

    pinchDistance: (e) ->
      distance = Math.sqrt(
        Math.pow(e.touches[0].pageX - e.touches[1].pageX, 2) + Math.pow(e.touches[0].pageY - e.touches[1].pageY, 2)
      )
      return parseInt(distance, 10)

    pinchGesturestart: (e) ->
      #console.log '!pinch gesture start', e.scale
      if e.scale
        e.preventDefault()
        @pinchInitialScale = @scale
        @willChange = ''
        @transition = 'unset'

    pinchGesturechange: (e) ->
      #console.log '!pinch gesture change', e.scale
      if e.scale
        e.preventDefault()

        level = Math.log(@pinchInitialScale * e.scale) / Math.log(1.2) # because `@scale: -> Math.pow 1.2, @zoom.level`
        level = -10 if level < -10
        level = 10 if level > 10

        scale = @scale
        x = e.clientX - @x
        y = e.clientY - @y
        @zoom.level = level # this leads to new @scale calculated
        @zoom.dx += (x / @scale - x / scale) * @scale
        @zoom.dy += (y / @scale - y / scale) * @scale

    pinchGestureend: (e) ->
      #console.log '!pinch gesture end', e.scale
      requestAnimationFrame => requestAnimationFrame =>
        if e.scale
          e.preventDefault()
        @willChange = 'transform'
        @transition = ''
