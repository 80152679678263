






















export default
  name: 'NavbarContent'
  inject: ['provideApp']
  computed:
    smallScreen: ->
      @provideApp.smallScreen
