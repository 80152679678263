import Vue from 'vue'
import { DRAGTOUCH_THRESHOLD, DRAGTOUCH_TIMEOUT } from '@/utils'

class TouchDragHandler
  constructor: (@el, value) ->
    #console.log '!touchdrag init', @el
    @dragTouch = { hold: false, active: false, ready: false }
    @attached = false
    @attach() if value

  attach: ->
    return if @attached
    @el.addEventListener 'touchstart', @touchstart
    @el.addEventListener 'touchmove', @touchmove
    @el.addEventListener 'touchend', @touchend
    @el.addEventListener 'touchcancel', @touchcancel
    @attached = true

  detach: ->
    return unless @attached
    @el.removeEventListener 'touchstart', @touchstart
    @el.removeEventListener 'touchmove', @touchmove
    @el.removeEventListener 'touchend', @touchend
    @el.removeEventListener 'touchcancel', @touchcancel
    @attached = false

  touchstart: (e) =>
    if e.touches?.length == 1 && !e.target.matches('.w-resizer') && !e.dragHold
      # console.log '!touchdrag start', e
      @dragTouch = {
        hold: true, active: false, ready: false,
        bx: e.touches[0].pageX, by: e.touches[0].pageY,
        timestamp: new Date().getTime()
      }
      e.dragHold = true
      @dragTouch.readyTimeout = setTimeout (=>
        @dragTouch.ready = true
        @el.classList.toggle 'drag-touch-ready', true
      ), DRAGTOUCH_TIMEOUT

  touchmove: (e) =>
    clearInterval @dragTouch.dragoverRepeater if @dragTouch.dragoverRepeater
    distance = @touchDistance(e)
    #console.log '!touchdrag move', e, @dragTouch, distance
    if @dragTouch.hold
      if e.touches?.length != 1
        @touchcancel(e)
        return
      if new Date().getTime() - @dragTouch.timestamp < DRAGTOUCH_TIMEOUT
        if distance > DRAGTOUCH_THRESHOLD
          @touchcancel(e)
        return
      @dragTouch.active = true
      @dragTouch.hold = false
      @dragTouch.ready = true
      @el.classList.toggle 'drag-touch-ready', true
      # dragstart
      @dragTouch.lastX = e.touches[0].clientX
      @dragTouch.lastY = e.touches[0].clientY
      #console.log '!touchdrag active'
      @dragTouch.data = new DataTransfer()
      @simulateDrag 'dragstart', @el
    if @dragTouch.active
      e.stopPropagation()
      e.preventDefault()
      if distance > DRAGTOUCH_THRESHOLD
        @dragTouch.ready = false
        @el.classList.toggle 'drag-touch-ready', false
      target = document.elementFromPoint e.touches[0].clientX, e.touches[0].clientY
      #while target && !target.classList.contains('droppable') && !target.classList.contains('canvas') && !target.classList.contains('v-menu__content') && !target.matches('.trash-content *')
      #  target = target.parentElement
      if target && !target.matches('.v-menu__content *') && !target.matches('.trash-content *')
        if target != @dragTouch.target
          if @dragTouch.target
            @simulateDrag 'dragleave'
          @dragTouch.target = target
          @simulateDrag 'dragenter'
        @dragTouch.lastX = e.touches[0].clientX
        @dragTouch.lastY = e.touches[0].clientY
        @simulateDrag 'dragover'
        @dragTouch.dragoverRepeater = setInterval (=> @simulateDrag 'dragover'), 100
      else if @dragTouch.target
        @simulateDrag 'dragleave'
        @dragTouch.target = null

  touchend: (e) =>
    #console.log '!touchdrag end', e, @dragTouch
    clearInterval @dragTouch.dragoverRepeater if @dragTouch.dragoverRepeater
    if @dragTouch.ready
      if e.target.matches('.editor *') # skip tap+hold processing in editor to not block copy/paste menu
        @touchcancel(e)
        return
      # not moved yet = tap+hold
      taphold = new UIEvent 'taphold', { bubbles: true, cancelable: true }
      e.x = @dragTouch.lastX
      e.y = @dragTouch.lastY
      @el.dispatchEvent(taphold)
      @touchcancel(e)
      #e.stopPropagation()
      e.preventDefault()
    else if @dragTouch.active
      #console.log '!drop', @dragTouch.target
      @simulateDrag 'drop'
      @touchcancel(e)
      setTimeout =>
        @simulateDrag 'dragend', @el
      e.stopPropagation()
      e.preventDefault()
    else if @dragTouch.hold
      @touchcancel(e)

  touchcancel: (e) =>
    #console.log '!touchdrag cancel', e, @dragTouch
    clearTimeout @dragTouch.readyTimeout
    clearInterval @dragTouch.dragoverRepeater if @dragTouch.dragoverRepeater
    @dragTouch = { hold: false, active: false, ready: false }
    @el.classList.toggle 'drag-touch-ready', false

  touchDistance: (e)->
    distance = Math.sqrt(
      Math.pow(e.touches[0].pageX - @dragTouch.bx, 2) + Math.pow(e.touches[0].pageY - @dragTouch.by, 2)
    )
    return parseInt(distance, 10)

  simulateDrag: (type, target = @dragTouch.target)->
    # console.log '!touchdrag sim', type, target, @dragTouch.data
    return unless target
    e = new UIEvent type, { bubbles: true, cancelable: true }
    e.x = @dragTouch.lastX
    e.y = @dragTouch.lastY
    e.clientX = e.x
    e.clientY = e.y
    e.dataTransfer = @dragTouch.data
    target.dispatchEvent(e)


touchdragMap = {}
idSeed = 10000

touchdragBind = (el, value) ->
  touchdragId = idSeed++
  el.dataset.touchdrag = touchdragId
  touchdragMap[touchdragId] = new TouchDragHandler(el, value)

touchdragGet = (el) ->
  touchdragId = el.dataset?.touchdrag
  if touchdragId
    touchdragMap[touchdragId]
  else
    null

touchdragUnbind = (el) ->
  touchdrag = touchdragGet el
  if touchdrag
    touchdrag.detach()


Vue.directive 'touchdrag',
  inserted: (el, { value, oldValue, arg }, vnode) ->
    return unless FLAG.DIE_GHOST_DIE
    if value
      touchdragBind el, value

  updated: (el, { value, oldValue, arg }, vnode) ->
    return unless FLAG.DIE_GHOST_DIE
    return if value == oldValue
    touchdrag = touchdragGet el
    if value and touchdrag
      touchdrag.attach()
    else if value
      touchdragBind el, value
    else if touchdrag
      touchdrag.detach()

  unbind: (el) ->
    touchdragUnbind el
