




















import mixin from './mixin'
import Childs from './Childs'
import Avatar from '@/components/Avatar.vue'
export default
  name: 'WejeVoice'
  mixins: [ mixin ]
  components: { Childs, Avatar }
  props: ['data']
  mounted: ->
    @Surfer = await import(### webpackChunkName: "wavesurfer" ###'wavesurfer.js')
    @initSerfer()
  data: ->
    Surfer: null
    wavesurfer: null
    isLoaded: false
    status: 'play'
    currentTime: 0
  computed:
    title: ->
      return null unless @data.data?.title
      @data.data?.title
    peaks: ->
      @data?.data?.peaks
    transcription: ->
      @data?.data?.transcription
    url: ->
      @data?.data?.url
    duration: ->
      @data?.data?.duration
    author: ->
      @data?.data?.author
    titlePlaceholder: ->
      @$t("webjet.voice.title")
    timeline: ->
      if @currentTime < 10
          seconds =  '0' + @currentTime
        else if @currentTime == 60
          seconds = '00'
        else
          seconds = @currentTime

        minutes = if @currentTime < 60 then '0' else '1'
        minutes + ':' + seconds
    isVisible: ->
      if @provideWebjet
        rootWebjet = @provideWebjet
        while rootWebjet.provideWebjet
          rootWebjet = rootWebjet.provideWebjet
        return rootWebjet.isTheoreticallyVisible and @isExpanded
      else
        return @isTheoreticallyVisible and @isExpanded
  methods:
    initSerfer: ->
      @wavesurfer = @Surfer.default.create
        container: @$el.querySelector '.wavesurfer'
        height: 40
        barRadius: 2
        barWidth: 2
        barMinHeight: 4
        cursorWidth: 0
        waveColor: '#FFF'
        progressColor: '#0a0a0a'

      @wavesurfer.backend.setPeaks JSON.parse @peaks or null, @duration
      @wavesurfer.drawBuffer()

      @wavesurfer.on 'ready', =>
        if not @peaks
          @$store.dispatch 'webjet/setVoiceGraphPeaks',
            path: @webjet.$path
            peaks: JSON.stringify @wavesurfer.backend.mergedPeaks
        @play()

      @wavesurfer.on 'finish', => @stop()
      @wavesurfer.on 'audioprocess', =>
        @currentTime = Math.floor @wavesurfer.getCurrentTime()
    togglePlay: ->
      return @load() unless @isLoaded
      if @status == 'play' then @play() else @stop()
    play: ->
      @wavesurfer.play()
      @status = 'stop'
    stop: ->
      @wavesurfer.stop()
      @status = 'play'
    load: ->
      @status = 'loading'
      @wavesurfer.load @url
      @isLoaded = true
  beforeDestroy:->
    @stop() if @status == 'stop'
