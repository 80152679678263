import { firebind } from '@/utils'


isStrongWebjet = (src)->
  return false unless src
  webjetCategory = firebind "#{src}/category"
  webjetParents = firebind "#{src}/connections/parents"
  return false unless webjetCategory.$ready
  if webjetCategory.$value == '/webjets/containers/webjets/canvas'
    boardDeleted = firebind "#{src}/boardDeleted"
    return false unless boardDeleted.$ready
    if boardDeleted.$value
      return false
    else
      return true
  return false unless webjetParents.$ready
  for k, v of webjetParents
    return true if !v.weak and isStrongWebjet(v.src)
  return false


getWebjetData = ({ path, src })->
  result = {}
  if src
    connection = {}
    webjet = firebind src
  else
    result.connectionPath = path
    connection = firebind path
    result.id = connection.id
    result.sortKey = connection.sortKey
    return result unless connection?.src
    webjet = firebind connection.src
  result.category = webjet.category
  result.webjetPath = webjet.$path
  result.meta = webjet.meta
  result.data = webjet.data
  result.public = webjet.public
  result.private = webjet.private
  result.starred = webjet.starred
  result.columnKey = connection.columnKey if connection.columnKey
  result.activeColumn = connection.activeColumn if connection.activeColumn
  result.childs = []
  if webjet?.connections?.items?
    for k, v of webjet.connections.items
      if webjet.category != 'weje/page' or isStrongWebjet(v.src)
        result.childs.push getWebjetData { path: v.$path }
    result.childs = result.childs.sort (a, b)->
      parseFloat(a.sortKey) - parseFloat(b.sortKey)
  result


export default
  getWejeSiteData: ()->
    return ({ path, src })->
      result = getWebjetData({ path, src })
      result
