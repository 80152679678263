













import mixin from './mixin'
import Childs from './Childs'
export default
  name: 'WejeYoutubeVideo'
  mixins: [ mixin ]
  components: { Childs }
  props: ['data']
  data: ->
    play: true
  computed:
    videoUrl: ->
      @url || "https://www.youtube.com/embed/#{@data.data.id}?enablejsapi=1&rel=0&showinfo=0"
    thumbnail: ->
      @data.data?.thumbnail
    thumbnailStyles: ->
      s = {}
      s['background-image'] = "url(#{@thumbnail})" if @thumbnail
      return s
