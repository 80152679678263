








import { generateSort, isHoverableDevice } from '@/utils'


export default
  name: 'Plus'
  inject: ['provideCanvas']
  props: ['webjet','point']
  data: ->
    opacity: 0
  methods:
    selectionCentralize: -> @provideCanvas.selectionCentralize()
    activeMenu: ->
      if FLAG.WEBJET_PARENT
        webjet = null
        if @webjet.provideWebjet and !@webjet.provideWebjet.isContainer
          webjet = @webjet.provideWebjet
        else
          webjet = @webjet
        dest = webjet.webjet.$path
        sort = generateSort(webjet.webjet, @point.before)
        connection = Object.assign {}, sort
        if @point.columnKey
          connection.columnKey = @point.columnKey
        connection.coordinates = {
          x: @provideCanvas.sceneX + @provideCanvas.sceneWidth / 2 - 150
          y: @provideCanvas.sceneY + @provideCanvas.sceneHeight / 2 - 50
        }
        result = await @$store.dispatch 'webjet/create',
          category: '/webjets/content/webjets/note'
          boardPath: @provideCanvas.connection.src
          dest: dest
          connection: connection
        @provideCanvas.selectCreatedWebjets [ result ]
      else if @thisPlusIsActive
        @provideCanvas.activatePlusTools(null)
      else
        @provideCanvas.activatePlusTools(@)
    mousemove: (e) ->
      pX = @point.x + 22
      pY = @point.y + 4
      mouseX = @provideCanvas.sceneX + (e.x - @provideCanvas.$el.getBoundingClientRect().x) / @provideCanvas.scale
      mouseY = @provideCanvas.sceneY + (e.y - @provideCanvas.$el.getBoundingClientRect().y) / @provideCanvas.scale
      d = Math.sqrt(Math.pow((pX-mouseX),2) + Math.pow((pY-mouseY),2))
      if d < 40
        @opacity = 1
      else if d > 40 and d < 70
        @opacity = 1 - ((d - 40) / 30)
      else
        @opacity = 0
    touchstart: (e) ->
      @opacity = 1
  computed:
    # showAllTime: ->
    #   return true if @$store.getters['webjet/selected']?.length == 1
    plusStyle: ->
      point = @point
      scale = 1 / @provideCanvas.scale
      scale = 1 if scale < 1
      scale = 4 if scale > 4
      scale = +scale.toFixed(1)
      # if @showAllTime
      #   opacity = 1
      if isHoverableDevice
        opacity = @opacity
      else
        opacity = 1
      return
        transform: "translate(#{point.x}px, #{point.y}px) scale(#{scale})"
        left: '22px' if FLAG.WEBJET_PARENT and @webjet.webjet.category != "/webjets/containers/webjets/list"
        opacity: opacity
    thisPlusIsActive: ->
      @provideCanvas.plusPoint == @
  mounted: ->
    window.addEventListener 'mousemove', @mousemove
    window.addEventListener 'touchstart', @touchstart
  destroyed: ->
    window.removeEventListener 'mousemove', @mousemove
    window.removeEventListener 'touchstart', @touchstart
