import './registerServiceWorker'
import './runtimeFlag'

import "@fontsource/noto-sans/400.css"
import "@fontsource/noto-sans/500.css"
import "@fontsource/noto-sans/400-italic.css"
import "@fontsource/noto-sans/500-italic.css"

import '@/assets/css/main.sass'

import Vue from 'vue'
import store from './store'
import router from './router/index.coffee'
import vuetify from './plugins/vuetify'
import i18n from '@/plugins/i18n'
import { syncUserLang } from '@/plugins/i18n'

import App from './App.vue'

import '@/plugins/v-hotkey'
import '@/plugins/v-input-autowidth'
import '@/plugins/v-attach'
import '@/plugins/v-iframe-autofocusless'
import '@/plugins/v-touchdrag'
import '@/mixins/common'
import '@/mixins/canvas'
import '@/mixins/firebind'
require '@/plugins/tradingview' if FLAG.TRAIDING_WEBJET
import '@/plugins/clipper'
import '@/plugins/manifest'
require '@/plugins/update-worker' if IS_DEVELOPMENT or IS_STAGE
import '@/mixins/context'
import '@/webjets/all'

import PageTitle from '@/components/PageTitle'

Vue.component 'PageTitle', PageTitle

Vue.config.productionTip = false
Vue.config.devtools = false
window.ttf = (n, f)=> f()

if IS_DEVELOPMENT or IS_STAGE
  Vue.config.performance = true
  Vue.component 'DevToolsTrigger', require("@/dev/DevToolsTrigger").default
  require("@/dev/ttf")
  require("@/dev/firebaseWS")

if IS_DEVELOPMENT
  require("@/dev/calcDeps")

store.dispatch 'bootstrapCacheUser'

syncUserLang(store) # After bootstrapCacheUser and before first paint

ttf 'init', => new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h)-> h(App)
}).$mount('#app')

setTimeout (=> store.dispatch 'bootstrap'), 1

#require '@/plugins/crisp' unless IS_DEVELOPMENT
require '@/plugins/chats13' unless IS_DEVELOPMENT
require '@/plugins/google-tag-manager' if IS_PRODUCTION and !IS_STAGE
require '@/plugins/stripe' if FLAG.UPGRADE_PLAN
