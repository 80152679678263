





export default
  name: 'MinimapWebjet'
  props: ['src']
  inject: ['provideCanvas']
  computed:
    attach: ->
      return unless @webjet
      @webjet.genShow
    webjet: ->
      @provideCanvas.getWebjetComponentBySrc @connection.src
    styles: ->
      return unless @webjet
      # return if !genshow with last value?
      result = Object.assign {}, @webjet.genStyles
      unless result.width
        result.width = @webjet.baseWidth + 'px'
      return result unless @webjet.$el
      return result if @webjet.$el?.nodeType == 8
      return result unless @webjet.elementSceneRect
      result.height = (@webjet.elementSceneRect.y2 - @webjet.elementSceneRect.y1) + 'px'
      result
    classes: ->
      return unless @webjet
      result = Object.assign {}, @webjet.genClasses
      name = @webjet.$options.name.replace(/([a-zA-Z])(?=[A-Z])/g, '$1-').toLowerCase()
      result[name] = true
      result
  firebind:
    connection: -> @src
