plusable =
  computed:
    canPlusable: ->
      return false if @isReadOnly
      true
    plusPoints: ->
      return false unless @webjet
      points = []
      if FLAG.WEBJET_PARENT and !@provideWebjet.isContainer and @provideWebjet
        points.push {x: @elementSceneRect.x1, y: @elementSceneRect.y1 - 5.5, before: @connection.sortKey }
        connections = []
        group = 'items'
        if @provideWebjet.webjet.connections? and @provideWebjet.webjet.connections[group]
          connections.push(c) for k, c of @provideWebjet.webjet.connections[group]
        after = @connection.sortKey
        before = false
        for c in connections
          if Number.isFinite(c.sortKey) and c.sortKey > after
            if !before or c.sortKey < before
              before = c.sortKey
        if FLAG.WEBJETS_REDESIGN
          points.push {x: @elementSceneRect.x1, y: @elementSceneRect.y2 - 4.5, before: before }
        else
          points.push {x: @elementSceneRect.x1, y: @elementSceneRect.y2, before: before }
      return points if !@provideWebjet.isContainer and @provideWebjet
      return points if @webjet.category == '/webjets/content/webjets/shape'

      if @webjet.category == '/webjets/containers/webjets/list'
        rect = @elementSceneRect

        columns = @columns
        wByColumns = {}

        childItems = @childItems
        childWebjetComponents = @childWebjetComponents
        listInList = if @provideWebjet then true else false

        unless childWebjetComponents.length
          x = (rect.x1 + rect.x2) / 2
          y = (rect.y1 + rect.y2) / 2
          before = true
          center = true
          points.push { x, y, before, center }
          return points

        if listInList
          wByColumns[@activeColumn] = []
          childItems = childItems.filter (item) =>
            if !item.columnKey
              columnKey = 0
            else
              columnKey = item.columnKey
            columnKey == @activeColumn
        else
          for c in columns
            wByColumns[c] = []
        for c in childItems

          if !c.columnKey
            columnKey = 0
          else
            columnKey = c.columnKey
          webjet = childWebjetComponents.find (w) =>
            w.src == c.$path
          wByColumns[columnKey].push webjet if webjet

        for k,v of wByColumns

          break unless v.length

          rectFirst = v[0].elementSceneRect
          columnKey = Number k
          if listInList
            columnKey = @activeColumn
          x = (rectFirst.x1 + rectFirst.x2) / 2

          # Plus in top
          # y = rect.y1
          # y += 36
          # before = true
          # points.push { x, y, before, columnKey }

          y = rect.y1

          for w in v
            wRect = w.elementSceneRect
            wH = wRect.y2 - wRect.y1
            y += wH
          # plus list header
          if FLAG.WEBJETS_REDESIGN
            y += 70
          else
            y += 51
          # plus margins
          if FLAG.WEBJETS_REDESIGN
            y += 20 * (v.length - 1)
          else
            y += 10 * (v.length - 1)

          if listInList
            if columns.length == 1
              if FLAG.WEBJETS_REDESIGN
                y -= 0
              else
                y -= 11
            else if columns.length > 1
              y += 25

          before = false
          points.push { x, y, before, columnKey }
      else
        rect = @elementSceneRect
        x = (rect.x1 + rect.x2) / 2
        y = rect.y2
        if FLAG.WEBJETS_REDESIGN
          y = y - 4.5
        before = false
        if FLAG.WEBJET_PARENT
          points.push { x: rect.x1, y, before }
        else
          points.push { x, y, before }
        if @hasChilds
          y = rect.y2
          for w in @childWebjetComponents
            # console.log w
            wRect = w.elementSceneRect
            if FLAG.WEBJET_PARENT
              points.push { x: wRect.x1, y: wRect.y1 - 4.5, before: w.connection.sortKey }
            wH = wRect.y2 - wRect.y1
            y -= wH
          y -= 5
          x = (rect.x1 + rect.x2) / 2
          before = true
          points.push { x, y, before } unless FLAG.WEBJET_PARENT
      points


export default plusable
export {plusable}