import { operationActions } from '@/store/operation'
import getters from './user-getters'
import mutations from './user-mutations'
import operations from './user-operations'
import actions from './user-actions'

export default
  namespaced: true
  getters: getters
  mutations: mutations
  actions: Object.assign {}, operationActions(operations), actions
  #modules: {  }
