import { decodeDataTransfer } from '@/utils'

export default
  data: ->
    dndCounter: 0

  listeners: ->
    drop: @dndDrop
    dragover: @dndDragover
    dragenter: @dndDragenter
    dragleave: @dndDragleave

  methods:
    dndDrop: (e)->
      @dndCounter = 0
      return if e.defaultPrevented
      return if @isReadOnly
      type = undefined
      for t in e.dataTransfer.types
        if t.split('/')[0] == 'webjets' or t in @$store.getters['webjet/dataTransferTypes']
          type = t
          break
      #console.log '!drop', type, e.dataTransfer.types
      return unless type
      dest = @data.$path
      if type=='Files' and not (await @provideApp.checkFileSizeLimits e.dataTransfer.files, dest)
        e.preventDefault()
        return
      if type.split('/')[0] == 'webjets'
        e.preventDefault()
        transferWebjets = decodeDataTransfer type.substring(8)
        mode = transferWebjets.mode
        items = transferWebjets.items.map (v)=>
          path: v.path
          data:
            coordinates:
              x: e.sceneX - v.offsetX
              y: e.sceneY - v.offsetY
        mode = 'copymove' if mode == 'move' and transferWebjets.boardSrc != @connection.src
        results = await @$store.dispatch 'webjet/transfer', { dest, items, mode, boardPath: @connection.src }
        if mode == 'copymove'
          @$store.dispatch 'undoredo/reset'
        if mode == 'copy'
          @selectCreatedWebjets results, false, false
      else if type in @$store.getters['webjet/dataTransferTypes']
        e.preventDefault()
        results = await @$store.dispatch 'webjet/dataTransfer',
          dest: dest
          boardPath: dest
          dataTransfer: e.dataTransfer
          data:
            coordinates:
              x: e.sceneX
              y: e.sceneY
        @selectCreatedWebjets results, false, false
      e.preventDefault()
      #e.stopPropagation()

    dndDragover: (e)->
      e.scale = @scale unless e.scale
      return if @isReadOnly
      return if e.defaultPrevented
      for t in e.dataTransfer.types
        if t.split('/')[0] == 'webjets' or t in @$store.getters['webjet/dataTransferTypes']
          e.preventDefault()
          break
      return

    dndDragenter: (e)->
      e.preventDefault()
      @dndCounter += 1
      return
    dndDragleave: (e)->
      e.preventDefault()
      @dndCounter -= 1
      return
