import { render, staticRenderFns } from "./RelationTools.vue?vue&type=template&id=052873ac&lang=pug&"
import script from "./RelationTools.vue?vue&type=script&lang=coffee&"
export * from "./RelationTools.vue?vue&type=script&lang=coffee&"
import style0 from "./RelationTools.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VList } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VBtn,VList,VMenu})
