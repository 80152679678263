








import ContextBtn from './ContextBtn'
import { hasDataForCopy, getDataForCopyFromWebjets } from '@/utils'
export default
  name: 'CopyText'
  components: { ContextBtn }
  inject: { provideCanvas: { default: null } }

  if: (selection)->
    pathArr = selection.map (s) -> s.webjet.$path
    showBtn = hasDataForCopy pathArr
    FLAG.BTN_COPY_CARD_TEXT and showBtn

  computed:
    selection: -> @$store.getters['webjet/selected']

  methods:
    action: ->
      pathArr = @selection.map (s) -> s.webjet.$path
      dataForCopy = getDataForCopyFromWebjets pathArr
      if window.clipboardData
        # should work in Internet Explorer
        text = if dataForCopy.text then dataForCopy.text else dataForCopy.html
        window.clipboardData.setData('Text', text) if text
      else
        # all other browsers
        clipboardItems = {}
        clipboardItem = null
        for k,v of dataForCopy
          if k == 'text'
            blobText = new Blob([v], {type: 'text/plain'})
            clipboardItems['text/plain'] = blobText

          else if k == 'html'
            blobHtml = new Blob([v], {type: 'text/html'})
            clipboardItems['text/html'] = blobHtml

        if Object.keys(clipboardItems).length == 2
          clipboardItem = new ClipboardItem({
            'text/plain': clipboardItems['text/plain']
            'text/html': clipboardItems['text/html']
          })
        else if Object.keys(clipboardItems).length == 1
          for k,v of clipboardItems
            clipboardItem = new ClipboardItem({
              k: v
            })
        else
          @provideCanvas.showCaution
            message: 'No text for copy'
            loader: false
          return
        if clipboardItem
          await navigator.clipboard.write([clipboardItem])
          @provideCanvas.showCaution
            message: @$t('canvas.message.textCopied')
            loader: false
