export default
  dataTransferTypes: ->
    types = [
        "text/plain"
        "create/card"
        "create/list"
        "create/graffiti"
        "Files"
      ]
    if FLAG.PAST_HTML
      types.unshift "text/html"
    types