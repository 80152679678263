UPPERCASE_PREFIX = '^{'
UPPERCASE_SUFFIX = '}^'

export encodeUpperCase = (str)->
  str.replace(/([A-Z]+)/g, "#{UPPERCASE_PREFIX}$1#{UPPERCASE_SUFFIX}")

export decodeUpperCase = (str)->
  escapeRegExp = (escape) => ['', ...escape.split('')].join('\\')
  return str.replace(
    new RegExp("#{escapeRegExp(UPPERCASE_PREFIX)}(.*?)#{escapeRegExp(UPPERCASE_SUFFIX)}", 'g'),
    (_, p1) => p1.toUpperCase()
  )

export encodeDataTransfer = (obj)->
  encodeUpperCase JSON.stringify obj

export decodeDataTransfer = (str)->
  JSON.parse decodeUpperCase str
