import { wsBoard } from '@/websockets'
import { debounce, throttle, isMobileOrTablet } from '@/utils'

export default
  data: ->
    preventContextMenu: false
    collaborateDrawMode: false
    collaborateDrawInProgress: false
    collaborateDrawPointsAverege: []
    collaborateTouchActive: false
  created: ->
    @throttledShareScene = throttle @shareScene, 100
    @throttledShareCursor = throttle @shareCursor, 100
    @throttledShareScene()
    document.addEventListener "visibilitychange", @collaborateVisibilitychange

  beforeDestroy: ->
    document.removeEventListener 'visibilitychange', @collaborateVisibilitychange

  listeners: ->
    mousemove: @collaborateMousemove
    mouseleave: @collaborateMouseleave
    contextmenu: @collaborateContextmenu
    touchmove: @collaborateTouchmove
    touchstart: @collaborateTouchstart

  watch:
    scene: ()->
      @throttledShareScene()

  methods:
    movingAverage: (arr, range) ->
      # return arr
      step = arr.length - range
      result = []
      i = 0
      while i < step
        limit = i + range
        x = 0
        y = 0
        j = i
        while j < limit
          x += arr[j][0]
          y += arr[j][1]
          j += 1
        x = x / range
        y = y / range
        result.push [x,y]
        i += 1
      result

    collaborateToggleDrawMode: ->
      @collaborateDrawMode = !@collaborateDrawMode

    collaborateVisibilitychange: ->
      @throttledShareScene()
      @throttledShareCursor(null) if document.visibilityState != 'visible'

    shareScene: ->
      return unless isMobileOrTablet
      if document.visibilityState == 'visible'
        wsBoard.share 'scene', Object.assign {}, @scene
      else
        wsBoard.share 'scene', null

    shareCursor: (value)->
      if value and document.visibilityState == 'visible'
        wsBoard.share 'cursor', value
      else
        wsBoard.share 'cursor', null

    sendChatMsg: (msg) ->
      wsBoard.share 'chat', msg, true

    collaborateMousemove: (e)->
      x = e.sceneX
      y = e.sceneY
      if e.buttons == 2 or (@collaborateDrawMode and e.buttons == 1)
        data = if !@collaborateDrawInProgress then { x, y, newLine: true }
        else { x, y }
        if !@collaborateDrawInProgress
          @collaborateDrawPointsAverege = []
          @collaborateDrawPointsAverege.push [x,y]
          data = { x, y, newLine: true }
        else
          @collaborateDrawPointsAverege.push [x,y]
          range = 5
          if @collaborateDrawPointsAverege.length > range
            points = @movingAverage @collaborateDrawPointsAverege, range
            lastPoint = points[points.length - 1]

            data = {x: lastPoint[0], y: lastPoint[1]}
          else
            data = null

        wsBoard.share 'draw', data if data
        wsBoard.share 'cursor', { drawing: true }
        @collaborateDrawInProgress = true

        @preventContextMenu = true
      else
        @preventContextMenu = false
        @collaborateDrawInProgress = false
        # fix bug on share cursor on touch
        if !@collaborateTouchActive
          @throttledShareCursor { x, y }
        else
          @collaborateTouchActive = false

    collaborateTouchstart: (e)->
      @collaborateTouchActive = true
      @collaborateDrawInProgress = true
    collaborateTouchmove: (e)->
      if @collaborateDrawMode and e.touches?.length == 1 and !e.target.matches('.v-menu__content *') and !e.target.matches('.contextmenu *') and !e.target.matches('.fly-context-menu *')
        x = @scene.x1 + e.touches[0].clientX / @scale
        y = @scene.y1 + e.touches[0].clientY / @scale
        data = { x, y }
        if @collaborateDrawInProgress
          data.newLine = true
        wsBoard.share 'draw', data
        #wsBoard.share 'cursor', { drawing: true }
        @collaborateDrawInProgress = false

    collaborateContextmenu: (e)->
      e.preventDefault() if @preventContextMenu
    collaborateMouseleave: (e)->
      @throttledShareCursor null
